import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import TextareaAutosize from 'react-autosize-textarea';
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import { getData } from '../api/api';
import $ from "jquery";
import swal from "sweetalert/dist/sweetalert.min.js";

class Scrumboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor2: "#404040",
            bgcolor1: "transparent",
            txtcolor2: "white",
            txtcolor1: "black",
            hide1: 0,
            reply: 0,
            helpDeskData: [],
            totalQueue: [],
            totalProgress: [],
            totalDone: [],
            viewDetails: [],
            helpdetails: [],
            selectStatus: ""

        };
        this.viewDetails = this.viewDetails.bind(this);
        this.changeHelpStatus = this.changeHelpStatus.bind(this);
    }

    helpDeskData() {
        getData("helpdesk/getHelpDeskData", localStorage.getItem('u')).then(res => {

            this.setState({ helpDeskData: res });

            let queue = [];
            let progress = [];
            let done = [];

            for (let i = 0; i < res.length; i++) {
                if (res[i].help_status === "Open") {
                    queue.push(res[i]);
                } else if (res[i].help_status === "Progress") {
                    progress.push(res[i]);
                } else if (res[i].help_status === "Closed") {
                    done.push(res[i]);
                }
            }

            this.setState({ totalDone: done });
            this.setState({ totalProgress: progress });
            this.setState({ totalQueue: queue });
         
        })

    }

    viewDetails(details) {
        let data = []
        data.push(details);
        this.setState({ helpdetails: data, selectStatus: "" })
      
    }

    changeHelpStatus(data) {
        var data = { help_id: data.help_id, status: this.state.selectStatus }

        if(this.state.selectStatus !== ""){
            getData("helpdesk/changeHelpStatus", data).then(response => {
                if (response.response = true) {
                    this.helpDeskData();
                    $('#close-modal').click();
                    swal("Status Updated Successfully", "", "success");
    
                } else {
                    swal("Status Updated Failed!", "", "error");
                   
                }
            })
        }
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }


    componentDidMount() {
        this.helpDeskData();
    }
    render() {

        return (
            <div id="content" className="content">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header">Administrator</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Help Desk Admin</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Scrumboard</a></li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav">
                                <div className="attendnav">
                                    <a href="#/helpdeskadmin" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}
                                        onClick={() => {
                                            this.setState({ bgcolor1: "#404040", txtcolor1: "white" });
                                            this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                                            this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                        }}>Tickets</a>
                                </div>
                                <div className="attendnav  m-r-30">
                                    <a href="#/scrumboard" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}
                                        onClick={() => {
                                            this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                                            this.setState({ bgcolor2: "#404040", txtcolor2: "white" });
                                            this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                        }}>Scrumboard</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="panel-body">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <select class="form-control">
                                    <option selected disabled>Select Ticket Type</option>
                                    <option >All</option>
                                    <option >1</option>
                                    <option >2</option>
                                    <option >3</option>
                                </select>
                            </div>
                            <div>
                                <div className="input-group pull-left" style={{ marginBottom: 5 }}>
                                    <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" />
                                    <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                </div>
                            </div>

                        </div>

                    </div> */}
                </div>

                <div className="row">
                    <div className="col-xl-4 col-lg-6">
                        <div className="panel" style={{ boxShadow: "3px 5px 8px 0px rgba(128,128,128,1)", backgroundColor: "rgb(21, 27, 38)" }}>
                            <div className="panel-heading">
                                <h4 className="panel-title text-orange-lighter">Status: Open ({this.state.totalQueue.length})</h4>
                            </div>
                            {this.state.totalQueue.map(details =>
                                <div className="list-group panel-body p-0">
                                    <div className="list-group-item list-group-item-action d-flex" onClick={() => { this.viewDetails(details) }} data-target="#demo-modal-tickets" data-toggle="modal" >
                                        <div className="mr-3 f-s-16">
                                            <i className="far fa-hourglass text-orange-transparent-9"></i>
                                        </div>
                                        <div className="flex-fill">
                                            <div className="f-s-13 f-w-600 text-inverse">{details.help_subject.toUpperCase()}</div>
                                            <div className="m-b-1">
                                                <div className="text-inverse-lighter flex-1">handled by {details.handler_fname.toUpperCase() + " " + details.handler_lname.toUpperCase()}</div>
                                            </div>
                                            <div className="m-b-1">
                                                <span className="label label-info">{details.help_type.toUpperCase()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="panel" style={{ boxShadow: "3px 5px 8px 0px rgba(128,128,128,1)", backgroundColor: "rgb(21, 27, 38)" }}>
                            <div className="panel-heading">
                                <h4 className="panel-title text-blue-lighter">Status: In Progress ({this.state.totalProgress.length})</h4>
                            </div>
                            {this.state.totalProgress.map(details =>
                                <div className="list-group panel-body p-0">
                                    <div className="list-group-item list-group-item-action d-flex" onClick={() => { this.viewDetails(details) }} data-target="#demo-modal-tickets" data-toggle="modal" >
                                        <div className="mr-3 f-s-16">
                                            <i className="fas fa-sync-alt text-blue"></i>
                                        </div>
                                        <div className="flex-fill">
                                            <div className="f-s-13 f-w-600 text-inverse">{details.help_subject.toUpperCase()}</div>
                                            <div className="m-b-1">
                                                <div className="text-inverse-lighter flex-1">handled by {details.handler_fname.toUpperCase() + " " + details.handler_lname.toUpperCase()}</div>
                                            </div>
                                            <div className="m-b-1">
                                                <span className="label label-info">{details.help_type.toUpperCase()}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            )}

                        </div>



                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="panel" style={{ boxShadow: "3px 5px 8px 0px rgba(128,128,128,1)", backgroundColor: "rgb(21, 27, 38)" }}>
                            <div className="panel-heading">
                                <h4 className="panel-title text-green-lighter">Status: Closed ({this.state.totalDone.length})</h4>
                            </div>
                            {this.state.totalDone.map(details =>
                                <div className="list-group panel-body p-0">
                                    <div className="list-group-item list-group-item-action d-flex" onClick={() => { this.viewDetails(details) }} data-target="#demo-modal-tickets" data-toggle="modal" >
                                        <div className="mr-3 f-s-16">
                                            <i className="far fa-check-circle text-green"></i>
                                        </div>
                                        <div className="flex-fill">
                                            <div className="f-s-13 f-w-600 text-inverse">{details.help_subject.toUpperCase()}</div>
                                            <div className="m-b-1">
                                                <div className="text-inverse-lighter flex-1">handled by {details.handler_fname.toUpperCase() + " " + details.handler_lname.toUpperCase()}</div>
                                            </div>
                                            <div className="m-b-1">
                                                <span className="label label-info">{details.help_type.toUpperCase()}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>



                    </div>
                </div>
                <div id="demo-modal-tickets" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>UpdateTicket Status</h4>
                                <button id="close-modal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.helpdetails.map(data => {
                                    return <div>
                                        <div class="form-group">
                                            <label>Subject:</label>
                                            <input className="form-control" value={data.help_subject} readOnly />
                                        </div>
                                        <div class="form-group">
                                            <label>Select Status:</label>
                                            {this.state.helpdetails.map(data => {
                                                if (data.help_status === 'Open') {
                                                    return <div>
                                                        <select onChange={this.textchange} value={this.state.selectStatus} name="selectStatus" className="default-select2 form-control" style={{ width: "100%" }} required>
                                                            <option disabled value="" selected>{data.help_status}</option>
                                                            <option value="Open">Open</option>
                                                            <option value="Progress">Progress</option>
                                                            <option value="Closed">Closed</option>
                                                        </select>
                                                        <button className="btttn bg-green-transparent-9 text-white fas fa-cog pull-right m-15" onClick={() => { this.changeHelpStatus(data) }}>&nbsp;Update</button>

                                                    </div>

                                                } else if (data.help_status === 'Progress') {
                                                    return <div>
                                                        <select onChange={this.textchange} value={this.state.selectStatus} name="selectStatus" className="default-select2 form-control" style={{ width: "100%" }} required>
                                                            <option disabled value="" selected>{data.help_status}</option>
                                                            <option value="Closed">Closed</option>
                                                        </select>
                                                        <button className="btttn bg-green-transparent-9 text-white fas fa-cog pull-right m-15" onClick={() => { this.changeHelpStatus(data) }}>&nbsp;Update</button>

                                                    </div>

                                                } else if (data.help_status === 'Closed') {
                                                    return <input className="form-control" value={data.help_status} readOnly/>
                                                }
                                            })}

                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Scrumboard;