import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import 'intro.js/introjs.css';
import moment from 'moment';
import { getData } from '../../api/api';
import $ from "jquery";


class Department extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor2: "#404040",
            bgcolor1: "transparent",
            bgcolor3: "transparent",
            txtcolor2: "white",
            txtcolor1: "black",
            txtcolor3: "black",
            navbar: "navigation2",
            departments: [],
            getUserDetails: [],
            searchDriver: ""
        };
        this.textchange = this.textchange.bind(this);
        this.submitDept = this.submitDept.bind(this);
        this.searchDriver = this.searchDriver.bind(this);
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    submitDept(e) {
        e.preventDefault();
        let user = this.state.getUserDetails
        var data = { company_id: user[0].company_id, dept_name: this.state.dept_name, dept_suffix: this.state.dept_suffix, dept_code: this.state.dept_code }

        getData("department/addDepartmentbyID", data).then(response => {
            // console.log(response.response)
            if (response.response === true) {
                alert("Succesfully Added")
                this.getDepartment()
                $('#closeModal').click();

            } else {
                alert("Failed to add Department!")
            }
        })

    }

    getDepartment() {
        getData("helpdesk/getUserDetails", localStorage.getItem('u')).then(res => {
            this.setState({ getUserDetails: res });
            let user = this.state.getUserDetails
            var data = { company_id: user[0].company_id }
            console.log(data)
            getData("department/getDepartmentbyID", data).then(res => {
                this.setState({ departments: res });
                // console.log(res)
            })
        })
    }
    componentDidMount() {
        this.getDepartment();

    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    render() {
        let Departmentsearch = this.state.departments.filter(
            (files) => {
                return files.dept_name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        console.log(this.state.departments)
        return (
            <div className="content" id="content">

                <div className="panel" style={{ boxShadow: " 0px 0px 10px 0px rgba(140,140,140,0.50)" }}>
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i className="far fa-building"></i>&nbsp;Department List</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Department&nbsp;List</a></li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav">
                                <div className="attendnav">
                                    <a href="#/department">Department</a>
                                </div>
                                <div className="attendnav">
                                    <a href="#/position">Position</a>
                                </div>
                                <div className="attendnav" >
                                    <a href="#/matrix">Salary&nbsp;Matrix</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-green-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;New Department</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search" placeholder="Search Department" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Scrollbars style={{ height: 350 }}>
                            <table className="table table-bordered">
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>Department ID</th>
                                        <th>Department Name</th>
                                        <th>Department Suffix</th>
                                        <th>Department Code</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {Departmentsearch.map(data => {
                                        return <tr>
                                            <td>{data.dept_id}</td>
                                            <td>{data.dept_name}</td>
                                            <td>{data.dept_suffix}</td>
                                            <td>{data.dept_code}</td>
                                        </tr>
                                    })}
                                </tbody>

                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete User</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.submitDept}>
                                    <fieldset>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Department Name</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="dept_name" placeholder="Name" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Department Suffix</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="dept_suffix" placeholder="Suffix" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Department Code</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="dept_code" placeholder="Code" required />
                                        </div>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                            &nbsp;Create
                                    </button>
                                    </fieldset>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Department;