import React, { Component } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";
import Dashboard from "./dashboard/index";
// import Meter from "./meter/index";
class Routes extends Component {
    state = {}
    render() {
        return (
            <Router>
                <div>
                    <Route path="/maynilad/dashboard" component={Dashboard} />
                    {/* <Route path="/mis/meter" component={Meter}/> */}
                </div>
            </Router>
        );
    }
}
export default Routes;