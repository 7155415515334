import React, { Component } from 'react';
import readXlsxFile from 'read-excel-file'
import {getData211} from "../../../api/api";
import DatePicker from "react-datepicker";
// import { lightFormat } from 'date-fns';
import swal from "sweetalert/dist/sweetalert.min.js";
class Assign extends Component {
    constructor(props){
        super(props);
        this.state = {
            excelData: [],
            startDate: new Date(),
            branch: [], 
            selectedBranch: '',
            uploadData: [],
            totalCount: 0,
            fieldman: [],
            selectedFieldman: '',
            search: '',
            totalAssign: 0,

        }
        this.convertExcel = this.convertExcel.bind(this);
        this.uploadAssign = this.uploadAssign.bind(this);
        this.removeAssign = this.removeAssign.bind(this);
    }
    _isMounted = true;

    convertExcel(e){
       readXlsxFile(e.target.files[0]).then((row) =>{
           let data = [];
           let data2 = []
           let count = row.length - 1;

          this.setState({totalCount: count});
          for(let i=1; i<row.length; i++){
              data.push({'jo': row[i][0], 'type': row[i][1], 'ssn': row[i][2],
                        'name': row[i][3], 'address': row[i][4]+" "+row[i][5]+" "+row[i][6]+" "+row[i][7]+" "+row[i][8], 
            });
          }
          this.setState({excelData: data})
       })
    }
    handleChangeStart = e => {
        this.setState({
            startDate: e
        });
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    updateSearch(event) {
        this.setState({ search: event.target.value.substr(0, 20) });
    }

    branch(){
        getData211("field_audit/getMISbranch").then(result =>{
            if(result.response === "404"){
                result = [];
                this.setState({branch : result});
            }else{
                this.setState({branch: result.elf})
          
            }
        })
    }

    uploadExcel(e){
        let data = this.state.excelData;
        var date = this.state.startDate;
        var convert = date.toISOString();
        var splitDate = convert.split("T");


        let save = {
            data: data,
            date: splitDate[0],
            branch: this.state.selectedBranch,
            app: 10
        }

        let get = {
            date: splitDate[0],
            branch: this.state.selectedBranch,
            app: 10
        }
        getData211("field_audit/uploadExcel",save).then(result =>{
            if(result.res === "Success"){
                getData211("field_audit/updatedFieldman", this.state.selectedBranch).then(man =>{
                    let data = [];
                    for(let i =0; i<man.length; i++){
                        data.push({'fieldman': man[i], 'data': []});
                    }
                    this.setState({fieldman: data});
                })
                getData211("field_audit/getUploadedExcel", get).then(row => {
                    let data = [];
                    let x = this.state.fieldman;
      
                    for(let i=0; i<row.length; i++){
                        if(row[i].status === "Pending"){
                            data.push(row[i]);
                        }else{
                            for(let b=0; b<x.length; b++){
                                if(x[b].fieldman.user_id === row[i].fieldman){
                                    x[b].data.push(row[i]);
                                }
                            }
                        }
                    }
                   this.setState({fieldman: x});

                  this.setState({uploadData: data});
                  swal("Successfully Uploaded File!", "", "success");
                })
                
            }else{
             
            }
        })

       
    }

    getExcelRow(e){
        let fieldman = this.state.selectedFieldman;
        let upload = this.state.uploadData;
        let fieldmanData = this.state.fieldman;
        if(fieldman !== ''){
            const index = upload.indexOf(e);
            for(let i=0; i<fieldmanData.length; i++){
                if(fieldmanData[i].fieldman.user_id === fieldman){
                    fieldmanData[i].data.push(e);
                }
            }

            upload.splice(index, 1);
            this.setState({uploadData: upload});
            this.setState({fieldman: fieldmanData});

        }else{
            console.log("no")
        }
    }

    selectedFieldman(e){
        this.setState({selectedFieldman: e});
    }

    uploadAssign(){
        let assign = this.state.fieldman;
        var date = this.state.startDate;
        var convert = date.toISOString();
        var splitDate = convert.split("T");

      
        getData211("field_audit/uploadAssign", assign).then(result =>{
           
        })
        let data = this.state.excelData;
        var date = this.state.startDate;
        var convert = date.toISOString();
        var splitDate = convert.split("T");


        let save = {
            data: data,
            date: splitDate[0],
            branch: this.state.selectedBranch,
            app: 10
        }

        let get = {
            date: splitDate[0],
            branch: this.state.selectedBranch,
            app: 10
        }
        getData211("field_audit/uploadExcel",save).then(result =>{
            if(result.res === "Success"){
                getData211("field_audit/updatedFieldman", this.state.selectedBranch).then(man =>{
                    let data = [];
                    for(let i =0; i<man.length; i++){
                        data.push({'fieldman': man[i], 'data': []});
                    }
                    this.setState({fieldman: data});
                })
                getData211("field_audit/getUploadedExcel", get).then(row => {
                    let data = [];
                    let x = this.state.fieldman;
      
                    for(let i=0; i<row.length; i++){
                        if(row[i].status === "Pending"){
                            data.push(row[i]);
                        }else{
                            for(let b=0; b<x.length; b++){
                                if(x[b].fieldman.user_id === row[i].fieldman){
                                    x[b].data.push(row[i]);
                                }
                            }
                        }
                    }
                   this.setState({fieldman: x});

                  this.setState({uploadData: data});
                  swal("Successfully Assigned!", "", "success");
                })
                
            }else{
             
            }
        })

                
        // swal("Successfully Assigned!", "", "success");

    }

    removeAssign(e){
       if(e.status === "Assigned"){
        let f = this.state.fieldman;
        let fData = "";
        let remove = "";
        remove = e.jo;
        for(let i=0; i<f.length; i++){
            if(f[i].fieldman.user_id === this.state.selectedFieldman){
                fData = f[i].fieldman.user_lname+", "+f[i].fieldman.user_fname;
            }
        }

        swal({
            title: "Are you sure?",
            text: "You want to remove JO: "+remove+" to "+fData,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                let x = this.state.fieldman;
                let upload = this.state.uploadData;

                for(let i=0; i<x.length; i++){
                    if(x[i].fieldman.user_id === this.state.selectedFieldman){
                        const index = x[i].data.indexOf(e);
                        let list = [];
                        x[i].data.splice(index, 1);
                        list.push(e);
                        list.forEach(element => {
                            element['status'] = "Pending";
                            element['group'] = "";
                        });
                        upload.push(list[0]);
                        getData211("field_audit/removeAssign", list[0].id).then(result =>{
                        })

                    }
                }
                

                this.setState({uploadData: upload});
                this.setState({fieldman: x});

            } else {
             
            }
          });

       }else{
           let x = this.state.fieldman;
           let upload = this.state.uploadData;

           for(let i=0; i<x.length; i++){
               if(x[i].fieldman.user_id === this.state.selectedFieldman){
                   const index = x[i].data.indexOf(e);
                   x[i].data.splice(index, 1);
                   upload.push(e);
               }
           }
           this.setState({uploadData: upload});
           this.setState({fieldman: x});
       }
    }


    componentDidMount(){
        this.branch();
        this.update();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "assign"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "assign"){
            setTimeout(() => {
                this.update();
            }, 5000);
        }else{
            clearTimeout(this.update);
        }
      }

    render() { 
        localStorage.setItem("pass", "assign");
        let filterUpload = this.state.uploadData.filter(
            (audit) => {
                return audit.jo.toLowerCase().indexOf(
                    this.state.search.toLowerCase()) !== -1 || audit.name.toLowerCase().indexOf(
                        this.state.search.toLowerCase()) !== -1 || audit.address.toLowerCase().indexOf(
                            this.state.search.toLowerCase()) !== -1 || audit.type.toLowerCase().indexOf(
                                this.state.search.toLowerCase()) !== -1;
            }
        )

        return ( 
            <div id="content" className="content">
                <div className="row text-center" style={{ marginTop: "-20px" }}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="#/payroll"><i className="fa fa-hand-holding-usd p-r-5 fa-lg text-success"></i><label>Navbar 1</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="#/holidays"><i className="fa fa-gift text-success p-r-5 fa-lg"></i><label>Navbar 2</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-briefcase fa-lg text-success p-r-5"></i><label>Navbar 3</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-user-slash fa-lg text-success p-r-5"></i><label>Navbar 4</label></a>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">Assigning</h1>
                    </div>
                    <div className="col-sm-6 ">
                       
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-9">
                        <div className="panel">
                            <div className="form-inline p-t-20 p-b-20">
                                <div className="form-group m-r-10 m-l-10" >
                                    <DatePicker
                                        selected={this.state.startDate}
                                        selectsStart
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        onChange={this.handleChangeStart}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group m-r-10" >
                                    <select onChange={this.textchange} className="form-control" name="selectedBranch">
                                        <option value="">---Select branch---</option>
                                        {this.state.branch.map(branch =>
                                            <option value={branch.id}>{branch.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group m-r-10" >
                                    <input type="file" className="form-control" id="input" onChange={this.convertExcel} style={{ borderStyle: 'none' }} />
                                </div>
                                <div className="form-group m-r-10" >
                                    <button className="btn btn-blocked bg-green-lighter text-white" onClick={this.uploadExcel.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>

                        <div className="panel p-t-15 p-b-15">
                            <input type="text" style={{ width: 250, marginLeft: 15 }} className="form-control" placeholder="Search..." name='search' value={this.state.search} onChange={this.updateSearch.bind(this)} />
                        </div>
                        <div className="panel">
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{ minHeight: 170, maxHeight: 550, overflow: 'auto' }}>
                                        <table className="table-hover">
                                            <thead className="tableFixHead table table-condensed text-center">
                                                <tr>
                                                    <th className="bg-light" style={{ padding: 15 }}>JO#</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Type</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>SSN</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Customer Name</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table table-bordered">
                                                {filterUpload.map((data, i) =>
                                                    <tr onClick={() => this.getExcelRow(data)}>
                                                        <td>{data.jo}</td>
                                                        <td>{data.type}</td>
                                                        <td>{data.ssn}</td>
                                                        <td>{data.name}</td>
                                                        <td>{data.address}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                
                    <div className="col-md-3 d-block d-md-block">
                     
                        <div className="card text-center border-0">
                            <div className="card-header f-w-600 f-s-15 bg-orange-lighter text-white">
                                Un-assigned Job Order
                        </div>
                            <div className="card-body">
                                <h4 className="text-orange-lighter">{this.state.uploadData.length}</h4>
                                <p className="card-text">Job Order</p>

                            </div>
                        </div>
                        <div id="accordion" className="accordion">
                            <div className="card text-center border-0">
                                <div className="card-header f-w-600 f-s-15  bg-blue-lighter text-white">
                                    Assigned Job Order
                                </div>
                                <div className="card-body">
                                    <h4 className=" text-blue-lighter">{this.state.totalAssign}</h4>
                                    <p className="card-text">Job Order</p>
                                    <button className="btn btn-block bg-blue-lighter text-white" onClick={this.uploadAssign}>Assign</button>
                                </div>

                                <div className="card">
                                    {this.state.fieldman.map((man, i) =>
                                        <div key={i}>
                                            <div className="card-header pointer-cursor d-flex align-items-center " data-toggle="collapse" data-target={"#collapseOne" + i} onClick={() => this.selectedFieldman(man.fieldman.user_id)}>
                                                <p >{man.fieldman.user_fname} ({man.data.length})</p>
                                            </div>
                                            <div id={"collapseOne" + i} className="collapse" data-parent="#accordion">
                                                <ul className="card-body list-group list-group-flush bg-grey-transparent-5">
                                                   {man.data.map(x =>{
                                                       if(x.status === "Assigned")
                                                        return <li className="list-group-item ulliot" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.removeAssign(x)}>{x.jo}</li>
                                                        return <li className="list-group-item ulliot" onClick={() => this.removeAssign(x)}>{x.jo}</li>
                                                    } 
                                                    )}
                                               </ul>
                                              
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                
            </div>
         );
    }
}
 
export default Assign;