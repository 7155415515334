import React from 'react'
import { createSelectable } from 'react-selectable-fast'
import Label from './Label'

const Album = ({
  selectableRef, selected, selecting, title, year,brancharea
}) => (
  <div
    ref={selectableRef}
    className={`
      ${(year === 10 || year === 27) && 'not-selectable'}
      item
      ${selecting && 'selecting'}
      ${selected && 'selected'}
    `}
  >
   
      <div className="row">
        <div className="col-md-2">

          <h2>{title}</h2>
          {/* <small style={{textAlign:"right"}}>{year}</small> */}
        </div>
        <div className="col-md-7"></div>
        <div className="col-md-3">
          <div className="tick">&oplus;</div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-md-2">
          <h2>{brancharea}</h2>
        </div>
        <div className="col-md-10"></div>
      </div>
       <p   style={{textAlign:"center",fontSize:30,position:"relative"}}>10</p>    
       <p style={{textAlign:"center",fontSize:11,position:"relative"}}><strong>Priority</strong></p>    
       {/* <hr/>  */}
          <p style={{fontSize:11,textAlign:"left"}}><strong>No. of Accounts: 10</strong></p>
      

    {/* <Label selected={selected} selecting={selecting} /> */}
  </div>
)

export default createSelectable(Album)