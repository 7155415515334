import React, { Component } from 'react';
import {getMISuser} from "../../../api/api";

import "../css/style.css";
import {Loader} from "react-overlay-loader"



class Profile extends Component {
    constructor(props){
        super(props);
        this.state = {
            u: localStorage.getItem("u"),
            user: [],
            isToggleOn: true,
            // isToggleOn: "fa fa-2x fa-chevron-down iconFloat",
            filing: '',
            ot: [],
            ob: [],
            leave: [],
            ut: [],
            details: false,
            request: [],
            labelRequest: 'Pending',
            loading: "false",
        }
        this.toggle = this.toggle.bind(this);
        this.filing = this.filing.bind(this);
        this.changeLabelRequest = this.changeLabelRequest.bind(this);
    }

    toggle(){
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));
    }

    filing(e){
        this.setState({
            filing: e.target.name,
        });
    }

    details(){
        this.setState({
			details: !this.state.details
		});
    }

    changeLabelRequest(e){
        this.setState({labelRequest: e.target.name})
    }
 

    

    user(){
        const u = this.state.u;
        getMISuser("field_audit/HRuser", u).then(result =>{
            this.setState({user: result});
            // var user_id = "";
            // var branch_id = "";
            // var app_id = "";
            const save = {
                user_id: 454,
                branch_id: 29,
                app_id: 7
            }

            getMISuser("HumanResource/getRequest", save).then(result2 => {
                var request = [];

                for(let i=0; i<result2.requests.length; i++){
                    request.push(result2.requests[i]);
                }

                console.log(request);

                this.setState({request: request});

                
                // for(let i=0; i<result2.requests.length; i++){
                //     if(result2.requests[i].req_type === "Overtime"){
                //         ot.push(result2.requests[i]);
                //     }else if(result2.requests[i].req_type === "Official Business"){
                //         ob.push(result2.requests[i]);
                //     }else if(result2.requests[i].req_type === "Leave"){
                //         leave.push(result2.requests[i]);
                //     }else if(result2.requests[i].req_type === "Undertime"){
                //         ut.push(result2.requests[i]);
                //     }
                // }
                // this.setState({ot: ot});
                // this.setState({ut: ut});
                // this.setState({ob: ob});
                // this.setState({leave: leave});
            })
        })
    }

    componentDidMount(){
     this.user();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "profile"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "profile"){
            setTimeout(() => {
             
            }, 500000);
        }else{
            clearTimeout(this.update);
        }
      }



      
    render() { 
        localStorage.setItem("pass", "profile");
       
        var details = {
			display: this.state.details ? "none" : "block"
        }
      
        return ( 
            <div id="content" className="content">
            
            {(this.state.loading === "false" &&
                <Loader fullPage loading />
            )}

                <div className="row">
                    <div className="col-md-12">
                        {/* <div className="panel">
                            <div className="panel-body"> */}
                                <div className="row">
                                    {/* <div class="profile-left"> */}
                                        {/* <div class="profile-image">
                                            {this.state.user.map(user =>
                                              <img src={user.user_pic} style={{width: "100%", height: "100%"}}/>
                                            )}
                                          
                                            <i class="fa fa-user hide"></i>
                                        </div>
                                        <div class="m-b-10">
                                            <a href="#" class="btn btn-warning btn-block btn-sm">Change Picture</a>
                                        </div>

                                       <div class="panel-group" id="accordion">
                                            <div class="panel panel-inverse overflow-hidden">
                                                <div class="panel-heading">
                                                    <h3 class="panel-title">
                                                        <a class="accordion-toggle accordion-toggle-styled collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="fasle">
                                                            <i class="fa fa-plus-circle pull-right"></i> 
                                                            Personal Info
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseOne" class="panel-collapse collapse" aria-expanded="fasle">
                                                    <div class="panel-body">
                                                        {this.state.user.map(user =>
                                                            <div>
                                                                <p>{user.user_civilstatus}</p>
                                                                <p>{user.user_gender}</p>
                                                                <p>{user.user_bday}</p>
                                                                <p>{user.user_email}</p>
                                                                <p>{user.user_mobile}</p>
                                                                <p>{user.user_address}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="panel panel-inverse overflow-hidden">
                                                <div class="panel-heading">
                                                    <h3 class="panel-title">
                                                        <a class="accordion-toggle accordion-toggle-styled collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false">
                                                            <i class="fa fa-plus-circle pull-right"></i> 
                                                            Company Info
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseTwo" class="panel-collapse collapse" aria-expanded="false">
                                                    <div class="panel-body">
                                                    <p>asdasdasd</p>
                                               
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>

                                    </div> */}

                                    <div className="col-md-3">
                                    <div className="panel">
                                            <div className="panel-body">
                                        <div style={{height: "175px", width: "100%"}}>
                                            {this.state.user.map(user =>
                                              <img src={user.user_pic} style={{width: "100%", height: "100%"}}/>
                                            )}
                                        </div>
                                        <div class="m-b-10">
                                            <a href="#" class="btn btn-warning btn-block btn-sm">Change Picture</a>
                                        </div> 

                                        <div class="profile-highlight">
                                        {this.state.user.map(user =>
                                            <div>
                                           <h4><center>{user.user_fname+" "+user.user_mname+" "+user.user_lname}</center></h4>
                                           <h4><center>{user.user_jobposition}</center></h4>
                                           <h4><center>{user.company_name}</center></h4>
                                           </div>
                                            )}
                                    
                                            {/* <div class="checkbox m-b-5 m-t-0">
                                            {this.state.user.map(user =>
                                                <label>{user.user_fname+" "+user.user_mname+" "+user.user_lname}</label>
                                            )}
                                            
                                            </div>
                                            <div class="checkbox m-b-0">
                                                <label>Show i have 14 contacts</label>
                                            </div> */}
                                        </div>
                                        
                                        {/* {this.state.user.map(user =>
                                          <p>{user.user_fname+" "+user.user_mname+" "+user.user_lname}</p>
                                        )} */}
                                        <div class="m-b-10">
                                            <a class="btn btn-warning btn-block btn-sm" onClick={this.details.bind(this)} name="Details">Details</a>
                                        </div>
                                        </div>
                                        </div>
                                      



                                    </div>
                                   
                                    <div className="col-md-9">
                                       

                                    <div class="row">
                                    
                                        <div class="col-md-3 col-sm-6">
                                            <div class="widget widget-stats bg-green">
                                                <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                <div class="stats-info">
                                                    <h4>Overtime</h4>
                                                    <p>0</p>	
                                                </div>
                                                <div class="stats-link">
                                                    <a href="javascript:;"  name="Overtime" data-target="#demo-lg-modal" data-toggle="modal">View Details <i class="fa fa-arrow-circle-o-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-md-3 col-sm-6">
                                            <div class="widget widget-stats bg-blue">
                                                <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                <div class="stats-info">
                                                    <h4>Leave</h4>
                                                    <p>0</p>	
                                                </div>
                                                <div class="stats-link">
                                                    <a href="javascript:;"  name="Leave">View Details <i class="fa fa-arrow-circle-o-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-md-3 col-sm-6">
                                            <div class="widget widget-stats bg-purple">
                                                <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                <div class="stats-info">
                                                    <h4>Official Business</h4>
                                                    <p>0</p>	
                                                </div>
                                                <div class="stats-link">
                                                    <a href="javascript:;"  name="Official Business">View Details <i class="fa fa-arrow-circle-o-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-md-3 col-sm-6">
                                            <div class="widget widget-stats bg-red">
                                                <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                <div class="stats-info">
                                                    <h4>Undertime</h4>
                                                    <p>0</p>	
                                                </div>
                                                <div class="stats-link">
                                                    <a href="javascript:;"  name="Undertime">View Details <i class="fa fa-arrow-circle-o-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                           
                                        
                                        {/* <label>
                                            <input type="checkbox" onClick={this.toggle}/>
                                            <div className="accordionHeader">
                                               
                                                <i className={this.state.isToggleOn ? 'fa fa-2x fa-chevron-down iconFloat2' : 'fa fa-2x fa-chevron-down iconFloat'} ></i>
                                                <h3>Personal Info</h3>
                                            </div>
                                          
                                           <div className="accordionInfo">
                                                asdasdasdasdasdasdaasd;ashd;lashd'lajs'ldkjsadflksldkfas;kd
                                                asd;'fl;asd'lfjas
                                                pldkf'asl;df'aslkdf
                                                askdf
                                                aksdf;laksd'fjas'dfja
                                                spoodfj'la;sdf'alsdmfos
                                                DAFAS
                                                DF
                                                AS;LDFJAS
                                                DOF
                                                AS;DLFssdasssdasdasdasda
                                           </div>
                                        </label> */}


                                        <div class="panel" style={details}>
                                            <div class="panel-heading">
                                                <div class="panel-heading-btn">
                                                    <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse" data-original-title="" title="" data-init="true"><center><i class="fa fa-minus"></i></center></a>
                                                </div>
                                                <h4 class="panel-title">Personal Info</h4>
                                            </div>
                                            <div class="panel-body p-0" style={{display: "block"}}>
                                                <div class="vertical-box">
                                                    <div class="vertical-box-column p-15 bg-silver width-200">
                                                        <div class="table-responsive">
                                                            <table class="table table-profile">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="field">Fullname</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_lname+", "+user.user_fname+" "+user.user_lname}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Marital Status</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_civilstatus}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Gender</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_gender}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Birthdate</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_bday}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">E mail</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_email}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Contact Number</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_mobile}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Address</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_address}</td>
                                                                        )}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                         <div class="panel" style={details}>
                                            <div class="panel-heading">
                                                <div class="panel-heading-btn">
                                                    <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse" data-original-title="" title="" data-init="true"><center><i class="fa fa-minus"></i></center></a>
                                                </div>
                                                <h4 class="panel-title">Company Info</h4>
                                            </div>
                                            <div class="panel-body p-0" style={{display: "block"}}>
                                                <div class="vertical-box">
                                                    <div class="vertical-box-column p-15 bg-silver width-200">
                                                        <div class="table-responsive">
                                                            <table class="table table-profile">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="field">Company</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.company_name}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Branch</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.branch_name}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Employee Status</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_status}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Gcash Number</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>000000</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Pagibig</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>00000/[</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Philhealth</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>000000</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">SSS</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>000000</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">T.I.N.</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>000000</td>
                                                                        )}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="panel" style={details}>
                                            <div class="panel-heading">
                                                <div class="panel-heading-btn">
                                                    <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse" data-original-title="" title="" data-init="true"><center><i class="fa fa-minus"></i></center></a>
                                                </div>
                                                <h4 class="panel-title">Contact Info</h4>
                                            </div>
                                            <div class="panel-body p-0" style={{display: "block"}}>
                                                <div class="vertical-box">
                                                    <div class="vertical-box-column p-15 bg-silver width-200">
                                                        <div class="table-responsive">
                                                            <table class="table table-profile">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="field">Contact Person</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_lname+", "+user.user_fname+" "+user.user_lname}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Contact Relationship</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_civilstatus}</td>
                                                                        )}
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="field">Contact Number</td>
                                                                        {this.state.user.map(user =>
                                                                        <td>{user.user_gender}</td>
                                                                        )}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                            {/* <div className="panel">
                                <div className="panel-body">
                                   
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h3 className="text-left">Overtime</h3>
                                            </div>
                                            <div className="col-md-6">
                                                <h3 className="text-left"></h3>
                                            </div>
                                            <div className="col-md-3">
                                                <i class="fa fa-plus pointer" data-target="#demo-lg-modal" data-toggle="modal" style={{position: "absolute",right: "5px", top: "12px", fontSize: "31px", color: "green"}}></i>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>

                                        

 */}


                                       

                                    </div>
                                </div>
                                


                                   <div id="demo-lg-modal" className="modal fade" tabindex="-1">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div class="modal-body">
                                                    <div className="row">
                                                        {/* <div className="col-md-6">
                                                                <legend>Employee Details</legend>
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Fullname</label>
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" />
                                                                </div>
                                                                <div class="form-group">
                                                                    <label for="exampleInputPassword1">Postion</label>
                                                                    <input type="password" class="form-control" id="exampleInputPassword1" />
                                                                </div>
                                                              
                                                                    <div class="form-group">
                                                                    <label for="exampleInputPassword1">Company</label>
                                                                    <input type="password" class="form-control" id="exampleInputPassword1" />
                                                                </div>
                                                                    <div class="form-group">
                                                                    <label for="exampleInputPassword1">Branch</label>
                                                                    <input type="password" class="form-control" id="exampleInputPassword1" />
                                                                </div>   
                                                        </div>
                                                        <div className="col-md-6">
                                                                <legend>Overtime Form</legend>
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Date</label>
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email"/>
                                                                </div>
                                                                
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputPassword1">Start</label>
                                                                            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputPassword1">End</label>
                                                                            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="form-group">
                                                                    <label for="exampleInputPassword1">Justification</label>
                                                                    <textarea class="form-control" placeholder="Textarea" rows="6"></textarea>
                                                                </div>
                                                        </div> */}
                                                           
                                                            <div class="col-md-4 col-sm-4">
                                                                <div class="widget widget-stats bg-green">
                                                                    <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                                    <div class="stats-info">
                                                                        <h4>Pending</h4>
                                                                        <p>0</p>	
                                                                    </div>
                                                                    <div class="stats-link">
                                                                        <a href="javascript:;"  name="Pending" onClick={this.changeLabelRequest}>View Details<i class="fa fa-arrow-circle-o-right"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div class="col-md-4 col-sm-4">
                                                                <div class="widget widget-stats bg-blue">
                                                                    <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                                    <div class="stats-info">
                                                                        <h4>Approved</h4>
                                                                        <p>0</p>	
                                                                    </div>
                                                                    <div class="stats-link">
                                                                        <a href="javascript:;"  name="Approved" onClick={this.changeLabelRequest}>View Details <i class="fa fa-arrow-circle-o-right"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div class="col-md-4 col-sm-4">
                                                                <div class="widget widget-stats bg-purple">
                                                                    <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                                    <div class="stats-info">
                                                                        <h4>Denied & Cancelled</h4>
                                                                        <p>0</p>	
                                                                    </div>
                                                                    <div class="stats-link">
                                                                        <a href="javascript:;"  name="Denied & Request" onClick={this.changeLabelRequest}>View Details <i class="fa fa-arrow-circle-o-right"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <fieldset>
                                                        <legend>{this.state.labelRequest}</legend>
                                                            <div class="tableFixHead">
                                                                <table class="table table-bordered">
                                                                    <thead>
                                                                    <tr>
                                                
                                                                        <th> </th>
                                                                        <th>Date of Overtime</th>
                                                                        <th>Time Start</th>
                                                                        <th>Time End</th>
                                                                        <th>Aprroved Hour/s</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.request.map(request =>{

                                                                            if(this.state.labelRequest === "Denied & Request"){
                                                                                if(request.status === "Denied" || request.status === "Cancelled"){
                                                                                    return <tr>
                                                                                        <td></td>
                                                                                    <td>{request.req_date_added}</td>
                                                                                    <td>{request.req_date_start}</td>
                                                                                    <td>{request.req_date_end}</td>
                                                                                    <td>{request.req_hours_approved}</td>
                                                                                </tr>

                                                                                }

                                                                            }else{
                                                                                if(request.status === this.state.labelRequest){
                                                                                    return <tr>
                                                                                            <td></td>
                                                                                      <td>{request.req_date_added}</td>
                                                                                    <td>{request.req_date_start}</td>
                                                                                    <td>{request.req_date_end}</td>
                                                                                    <td>{request.req_hours_approved}</td>
                                                                                </tr>
                                                                                }
                                                                            }
                                                                            // if(request.status === this.state.labelRequest){
                                                                            //     if(this.state.labelRequest === "Denied & Request"){
                                                                            //         if(request.status === "Denied" || request.status === "Cancelled"){
                                                                            //             return <tr>
                                                                            //             <td>{request.status}</td>
                                                                            //         </tr>
    
                                                                            //         }

                                                                            //     }else{
                                                                            //         return <tr>
                                                                            //         <td>{request.status}</td>
                                                                            //     </tr>

                                                                            //     }
                                                                            // }
                                                                        }
                                                                        )}
                                                                      
                                                                    
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                    </fieldset>
                                                </div>
                                                <div class="modal-footer">
                                                    <a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                   
                            </div>
                        {/* </div>
                    </div> */}
                </div>
            </div>
         );
    }
}
 
export default Profile;