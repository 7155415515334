import axios from "axios";
// import swal from "sweetalert/dist/sweetalert.min.js";
// let serverurl = "https://api.workflow.gzonetechph.com/";
let serverurl = "https://workflowapi.usiphil.com/";
// let serverurl = "http://beta.api.pacificweb.com.ph/";
let key = "?key=PocketHR@20190208&type=web";
let key2 = "?key=12345ABCFleet";

export const apiurl = serverurl;
export const serverImageMeter = serverurl + "assets/img/meter/";
export const loginurl = "https://login.usiphil.com/";
export const serverProfile =
  "https://images.usiphil.com/pockethr/profilepic/";
export const serverImage =
  "https://images.usiphil.com/";


export function loginUser(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function getData(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function approveLoadRequest(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function driverStatus(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function saveDriverChanges(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function getFieldAudit(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function getMISbranch(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function MISDashboard(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function getMISuser(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function MISActiveFieldman(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}
export function MISCategories(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function Zipdownload(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function checking(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}
export function downloadImages(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}
export function getAccomplishment(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function updateValidation(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function getDataFleet(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key2, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function postDataFleet(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + key2 + method, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}

export function getData211(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}
