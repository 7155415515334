import React, { Component } from 'react';
// import AmCharts from "@amcharts/amcharts3-react";
import Accomplishments from './charts/accomplishments'
import Camanava from './charts/camanava'
import Manila from './charts/manila'
import Quezon from './charts/quezon'
import Perba from './charts/perba'
class MayniladDashboard extends Component {
    state = {  }
    render() { 
        return ( 
            <div id="content" className="content">           
            <div id="demo-lg-modal" className="modal fade" tabindex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><i className="pci-cross pci-circle"></i></button>
                        <h4 className="modal-title" id="myLargeModalLabel">Per BA</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label for="exampleSelect"><strong> Select BA: </strong></label>
                                    <select type="select" name="select" id="exampleSelect" className="form-control">
                                            <option>--</option>
                                            <option>cas</option>
                                            <option>man</option>
                                    </select>
                                </div>
                            </div>
                            <Perba/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <label for="fromdate">From:</label>
                    <input type="date" className="form-control" name="fromdate" dateFormat="YYYY-MM-DD" required />
                    <label for="todate">To:</label>
                    <input type="date" className="form-control" name="todate" dateFormat="YYYY-MM-DD" required />
                    <label for="todate">&nbsp;</label>
                    <button className="btn btn-block btn-info">SUBMIT</button>
                </div>
                <div className="col-md-8">
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="panel-heading-btn"></div>
                                <h4>ACCOMPLISHMENTS VS DOWNLOAD</h4>  
                        </div>
                        <div className="panel-body">
                            <Accomplishments/>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                        <div className="card card-primary card-colorful">
                            <div className="pad-all text-center">
                                <span className="text-3x text-thin">22</span>
                                <p>Total Employees</p>
                                <i className="demo-pli-shopping-bag icon-lg"></i>
                            </div>
                        </div>
                        <div className="card card-mint card-colorful">
                            <div className="pad-all text-center">
                                <span className="text-3x text-thin">22</span>
                                <p>Total Employees</p>
                                <i className="demo-pli-shopping-bag icon-lg"></i>
                            </div>
                        </div>
                        <div className="card card-success card-colorful">
                            <div className="pad-all text-center">
                                <span className="text-3x text-thin">22</span>
                                <p>Total Employees</p>
                                <i className="demo-pli-shopping-bag icon-lg"></i>
                            </div>
                        </div>
                        <div className="card card-danger card-colorful">
                            <div className="pad-all text-center">
                                <span className="text-3x text-thin">22</span>
                                <p>Total Employees</p>
                                <i className="demo-pli-shopping-bag icon-lg"></i>
                            </div>
                        </div>
                    {/* <div class="list-group">
                        <a class="list-group-item" href="#"><i className="fa fa-th-large"></i> Fieldman Reports</a>
                        <a class="list-group-item" href="#"><i className="fa fa-th-large"></i> Map</a>
                        <a class="list-group-item" href="#"><i className="fa fa-th-large"></i> Vestibulum at eros</a>
                        <a class="list-group-item" href="#"><i className="fa fa-th-large"></i> Vestibulum at eros</a>
                        <a class="list-group-item" href="#"><i className="fa fa-th-large"></i> Vestibulum at eros</a>
					</div> */}
                <hr className="divider"/>
                </div>
             </div>
             <button className="btn btn-pink mar-ver" data-target="#demo-lg-modal" data-toggle="modal" style={{textAlign:"right"}}>View Details</button>
             <div className="row">
                <div className="col-md-4">
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="panel-heading-btn"></div>
                            <h4 style={{textAlign:"center"}}>CAMANAVA</h4>
                        </div>
                        <div className="panel-body">
                            <Camanava/>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="panel-heading-btn"></div>
                            <h4 style={{textAlign:"center"}}>MANILA</h4>
                        </div>
                        <div className="panel-body">
                            <Manila/>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="panel-heading-btn"></div>
                            <h4 style={{textAlign:"center"}}>QUEZON</h4>
                        </div>
                        <div className="panel-body">
                            <Quezon/>
                        </div>
                    </div>
                </div>
             </div>

        </div>

         );
    }
}
 
export default MayniladDashboard;