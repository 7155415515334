import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Scrollbars } from "react-custom-scrollbars";
import { Redirect } from "react-router-dom";
import { getData } from "../../api/api";
import moment from 'moment';

import swal from "sweetalert/dist/sweetalert.min.js";
// import src from '*.jpeg';
class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideBranch: true,
      hideBranch2: true,
      hideBranch3: true,
      hideBranch4: true,
      hideCompany: true,
      hideCompany2: true,
      hideCompany3: true,
      hideCompany4: true,
      today: true,
      timeshit: true,
      officialbusiness: true,
      ob: true,
      leave: true,
      validation: true,
      dateToday: new Date(),
      Company: [],
      company_id: "",
      branch_id: "",
      branch_id_today: "",
      DATA: [],
      Branches: [],
      AttendanceToday: [],
      in: [],
      timeSheetHeader: [],
      obsheetHeader: [],
      timeSheet: [],
      obSheet: [],
      leaveSheet: [],
      timeSheetAttendace: [],
      timeSheetAttendaceHeader: [],
      obSheetAttendace: [],
      leaveSheetAttendace: [],
      refresh: false,
      from_date: new Date(),
      to_date: new Date(),
      from: "",
      to: "",
      time_type: ["Time", "time", "time", "time", "time", "time"],
      holdvalue: "",
      daterange: true,
      exportSHOW: true,
      exportOB: true,
      exportLeave: true,
      todaysPresent: 0,
      todaysLate: 0,
      todaysAbsent: 0,
      countTotal: 0,
      breabcrumbs: "Today",
      border1: "",
      user_id: "",
      title: "Today's Data Report",
      searchDriver: "",
      OBheader: [],
      branchBreakdown: [],
      onCLickBranch: [],
      bgcolor1: "#404040",
      bgcolor2: "transparent",
      bgcolor3: "transparent",
      bgcolor4: "transparent",
      bgcolor5: "transparent",
      bgcolor6: "transparent",
      txtcolor1: "white",
      txtcolor2: "black",
      txtcolor3: "black",
      txtcolor4: "black",
      txtcolor5: "black",
      txtcolor6: "black",
      navbar: "navigation1",
      needValidation: [],
      doneValidation: [],
      validationDetails: [],
      validationPending: "#404040",
      validationValidated: "transparent",
      vcolor: "white",
      vcolor2: "black",
      vhide: true,
      vsearch: "",
      vfrom_date: new Date(),
      vto_date: new Date(),
      validated: true,
      needValidation2: [],
      validationSelectedBranch: "",
    };
    this.passBranch = this.passBranch.bind();
    this.searchDriver = this.searchDriver.bind(this);
    this.approveAttendance = this.approveAttendance.bind(this);
    this.deniedAttendance = this.deniedAttendance.bind(this);
    this.validationDetails = this.validationDetails.bind(this);
    this.selectValidation = this.selectValidation.bind(this);
  }

  filterDateStart = e => {
    setTimeout(() => {
      this.setState({
        from_date: e
      });

      var from = String(e);
      var split_from = from.split(" ");
      var from_date_spit =
        split_from[3] + "-" + split_from[1] + "-" + split_from[2];
      this.setState({ from: from_date_spit });
    }, 100);
  };
  filterDateEnd = e => {
    setTimeout(() => {
      this.setState({
        to_date: e
      });
      var to = String(e);
      var split_to = to.split(" ");
      var to_date_spit = split_to[3] + "-" + split_to[1] + "-" + split_to[2];
      this.setState({ to: to_date_spit });
    }, 100);
  };

  passCompany = e => {
    this.setState({ Branches: [] });

    var options = e.target.options;
    var value = [];
    var company = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        company = options[i].value;
        value.push(options[i].value);
      }
    }
    this.setState({ company_id: company });
    var data = [];
    for (let index = 0; index < this.state.DATA.length; index++) {
      var data2 = [];
      if (this.state.DATA[index].company_id == company) {
        data2 = {
          companyName: this.state.DATA[index].company_name,
          company_id: this.state.DATA[index].company_id,
          branch_id: this.state.DATA[index].branch_id,
          branch_name: this.state.DATA[index].branch_name
        };
        data.push(data2);
      }
    }
    setTimeout(() => {
      this.setState({ Branches: data });
    }, 300);
    this.setState({ hideBranch: false });
    this.setState({ refresh: true });
  };

  passCompany2 = e => {
    this.setState({ Branches: [] });

    var options = e.target.options;
    var value = [];
    var company = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        company = options[i].value;
        value.push(options[i].value);
      }
    }
    this.setState({ company_id: company });
    var data = [];
    for (let index = 0; index < this.state.DATA.length; index++) {
      var data2 = [];
      if (this.state.DATA[index].company_id == company) {
        data2 = {
          companyName: this.state.DATA[index].company_name,
          company_id: this.state.DATA[index].company_id,
          branch_id: this.state.DATA[index].branch_id,
          branch_name: this.state.DATA[index].branch_name
        };
        data.push(data2);
      }
    }
    setTimeout(() => {
      this.setState({ Branches: data });
    }, 300);
    this.setState({ hideBranch2: false });
    this.setState({ refresh: true });
  };

  passCompany3 = e => {
    this.setState({ Branches: [] });
    var options = e.target.options;
    var value = [];
    var company = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        company = options[i].value;
        value.push(options[i].value);
      }
    }
    this.setState({ company_id: company });
    var data = [];
    for (let index = 0; index < this.state.DATA.length; index++) {
      var data2 = [];
      if (this.state.DATA[index].company_id == company) {
        data2 = {
          companyName: this.state.DATA[index].company_name,
          company_id: this.state.DATA[index].company_id,
          branch_id: this.state.DATA[index].branch_id,
          branch_name: this.state.DATA[index].branch_name
        };
        data.push(data2);
      }
    }
    setTimeout(() => {
      this.setState({ Branches: data });
    }, 300);
    this.setState({ hideBranch3: false });
    this.setState({ refresh: true });
  };

  passCompany4 = e => {
    this.setState({ Branches: [] });

    var options = e.target.options;
    var value = [];
    var company = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        company = options[i].value;
        value.push(options[i].value);
      }
    }
    this.setState({ company_id: company });
    var data = [];
    for (let index = 0; index < this.state.DATA.length; index++) {
      var data2 = [];
      if (this.state.DATA[index].company_id == company) {
        data2 = {
          companyName: this.state.DATA[index].company_name,
          company_id: this.state.DATA[index].company_id,
          branch_id: this.state.DATA[index].branch_id,
          branch_name: this.state.DATA[index].branch_name
        };
        data.push(data2);
      }
    }
    setTimeout(() => {
      this.setState({ Branches: data });
    }, 300);
    this.setState({ hideBranch4: false });
    this.setState({ refresh: true });
  };

  passCompanyValidation = e => {
    this.setState({ Branches: [] });

    var options = e.target.options;
    var value = [];
    var company = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        company = options[i].value;
        value.push(options[i].value);
      }
    }
    this.setState({ company_id: company });
    var data = [];
    for (let index = 0; index < this.state.DATA.length; index++) {
      var data2 = [];
      if (this.state.DATA[index].company_id == company) {
        data2 = {
          companyName: this.state.DATA[index].company_name,
          company_id: this.state.DATA[index].company_id,
          branch_id: this.state.DATA[index].branch_id,
          branch_name: this.state.DATA[index].branch_name
        };
        data.push(data2);
      }
    }
    setTimeout(() => {
      this.setState({ Branches: data });
    }, 300);
  }

  passBranchValidation = e => {
    var options = e.target.options;
    var branch = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        branch = options[i].value;
      }
    }

    this.setState({validationSelectedBranch: branch});

    let data = [];
    let need = this.state.needValidation;
    for(let i = 0; i<need.length; i++){
      if(branch === need[i].branch_id){
        data.push(need[i]);
      }
    }

    this.setState({needValidation2: data});
  }

  passBranch = e => {
    var options = e.target.options;
    var data = [];
    var branch = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        branch = options[i].value;
      }
    }

    this.attendance_today(branch);
  };
  attendance_today(branch) {
    var data = [];
    data = {
      company_id: this.state.company_id,
      branch_id: branch,
      dateToday: this.state.dateToday,
      user_id: localStorage.getItem("u")
    };
    getData("users/GetCurrentAttendance", data).then(response => {
      this.setState({ AttendanceToday: response.details });
      console.log(this.state.AttendanceToday)
      var arrayD = [];
      var value1 = 0;
      var Arrays = [];
      var Attendance = 0;
      var countpresent = 0;
      var countlate = 0;
      var countabsent = 0;
      var branchBreakdown = [];
      for (let index = 0; index < response.details.length; index++) {
        var matchBranch = false;
        for (let b = 0; b < branchBreakdown.length; b++) {
          if (branchBreakdown[b].name == response.details[index].branch_name) {
            var presentDetails = [];
            if (response.details[index]["status"] == "PRESENT") {
              presentDetails.push({
                name: response.details[index].branch_name
              });
              branchBreakdown[b].presentCount.push(presentDetails);
            }
            if (response.details[index]["status"] == "LATE") {
              branchBreakdown[b].latecount.push({
                name: response.details[index].branch_name
              });
            }
            if (response.details[index]["status"] == "ABSENT") {
              branchBreakdown[b].absentCount.push({
                name: response.details[index].branch_name
              });
            }
            matchBranch = true;
            branchBreakdown[b].count.push({
              name: response.details[index].branch_name
            });
          }
        }
        var b_details = {
          name: "",
          count: [],
          presentCount: [],
          latecount: [],
          absentCount: []
        };
        // var b_details=[]
        if (matchBranch == false) {
          var presentDetails = [];
          if (
            response.details[index]["status"] == "PRESENT" ||
            response.details[index]["status"] == "LATE"
          ) {
            b_details.presentCount.push({
              name: response.details[index].branch_name
            });
          }
          if (response.details[index]["status"] == "LATE") {
            b_details.latecount.push({
              name: response.details[index].branch_name
            });
          }
          if (response.details[index]["status"] == "ABSENT") {
            b_details.absentCount.push({
              name: response.details[index].branch_name
            });
          }
          b_details.name = response.details[index].branch_name;
          b_details.count.push({ name: response.details[index].branch_name });

          branchBreakdown.push(b_details);
        }

        if (response.details[index]["status"] == "PRESENT") {
          countpresent++;
          this.setState({ todaysPresent: countpresent });
        }
        if (response.details[index]["status"] == "LATE") {
          countlate++;
          this.setState({ todaysLate: countlate });
        }
        if (response.details[index]["status"] == "ABSENT") {
          countabsent++;
          this.setState({ todaysAbsent: countabsent });
        }
        this.setState({ countTotal: response.details.length });

        for (
          let i = 0;
          i < response.details[index].details["timeSheet"].length;
          i++
        ) {
          var data = [];
          if (
            response.details[index].details["timeSheet"][i]["fields"].length >
            value1
          ) {
            value1 =
              response.details[index].details["timeSheet"][i]["fields"].length;
            arrayD = response.details[index].details["timeSheet"][i]["fields"];
          }
          Arrays =
            response.details[index].details["timeSheet"][i]["attendance"];
        }
      }

      this.setState({ branchBreakdown: branchBreakdown });
      var dataDetaiils = [];
      for (let index = 0; index < arrayD.length; index++) {
        var name = { fields: arrayD[index] };
        dataDetaiils.push(name);
        this.setState({ timeSheetHeader: dataDetaiils });
      }
    });
  }
  passBranch2 = e => {
    var options = e.target.options;
    var data = [];
    var branch = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        branch = options[i].value;
      }
    }
    this.setState({ branch_id: branch });
    data = {
      company_id: this.state.company_id,
      branch_id: branch
    };
    getData("users/get_Timesheet", data).then(response => {
      this.setState({ timeSheet: response.user_name });
    });
    this.setState({ exportSHOW: false });
  };

  passBranch3 = e => {
    var options = e.target.options;
    var data = [];
    var branch = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        branch = options[i].value;
      }
    }
    this.setState({ branch_id: branch });
    data = {
      company_id: this.state.company_id,
      branch_id: branch
    };
    getData("users/get_Timesheet", data).then(response => {
      this.setState({ obSheet: response.user_name });
    });
    this.setState({ exportOB: false });
  };

  passBranch4 = e => {
    var options = e.target.options;
    var data = [];
    var branch = "";
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        branch = options[i].value;
      }
    }
    this.setState({ branch_id: branch });
    data = {
      company_id: this.state.company_id,
      branch_id: branch
    };
    getData("users/get_Timesheet", data).then(response => {
      this.setState({ leaveSheet: response.user_name });
    });
    this.setState({ exportLeave: false });
  };

  getCurrentDay() {
    getData("users/Timeshit", localStorage.getItem("u")).then(response => {
      this.setState({ timeSheet: response });
      this.setState({ obSheet: response });
      this.setState({ leaveSheet: response });
    });
  }

  getTimeshit(user_data) {
    this.setState({
      timeSheetAttendace: [],
      user_id: user_data.user_id
    });
    var data = [];
    data = {
      company_id: user_data.company_id,
      branch_id: user_data.branch_id,
      user_id: user_data.user_id,
      from_date: this.state.from_date,
      to_date: this.state.to_date
    };
    getData("users/fetch_TimesheetDetails", data).then(response => {
      console.log(response)
      var OBheader = [];
      for (let i = 0; i < response.data.length; i++) {
        for (let b = 0; b < response.data[i].timesheet["details"].length; b++) {
          this.setState({
            timeSheetAttendace: response.data[i].timesheet["details"]
          });
          this.setState({ refresh: true });
          if (
            response.data[i].timesheet["details"][b].OB_Attendance != undefined
          ) {
            var countOB = [];
            for (
              let l = 0;
              l < response.data[i].timesheet["details"][b].OB_Attendance.length;
              l++
            ) {
              if (countOB.length == 0) {
                var headerOB = { headerOB: "OB-Start" };
                countOB.push(headerOB);
              } else if (countOB[countOB.length - 1].headerOB == "OB-Start") {
                var headerOB = { headerOB: "OB-End" };
                countOB.push(headerOB);
              } else if (countOB[countOB.length - 1].headerOB == "OB-End") {
                var headerOB = { headerOB: "OB-Start" };
                countOB.push(headerOB);
              }
            }
            if (countOB.length > OBheader.length) {
              OBheader = countOB;
            }
          }
        }
      }

      this.setState({ OBheader: OBheader });
    });
  }

  getObshit(user_data) {
    this.setState({ obSheetAttendace: [] });
    var data = [];
    data = {
      company_id: user_data.company_id,
      branch_id: user_data.branch_id,
      user_id: user_data.user_id,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      type: this.state.breabcrumbs
    };
    getData("users/GetOfficialBusiness", data).then(response => {
      // console.log(response)
      this.setState({ obSheetAttendace: response.data });
    });
  }

  getLeaveshit(user_data) {
    //get single user leave sheet
    this.setState({ leaveSheetAttendace: [] });
    var data = [];
    data = {
      company_id: user_data.company_id,
      branch_id: user_data.branch_id,
      user_id: user_data.user_id,
      from_date: this.state.from_date,
      to_date: this.state.to_date
    };
    console.log(data)

    getData("users/get_Leave", data).then(response => {
      this.setState({ leaveSheetAttendace: response.leave });
    });
  }

  getValidation() {
    getData(
      "HumanResource/attendance_validation",
      localStorage.getItem("u")
    ).then(result => {
      this.setState({ needValidation: result });



      if(this.state.validationSelectedBranch !== ""){
        let branch = this.state.validationSelectedBranch
        let data = [];
        for(let i = 0; i<result.length; i++){
          if(branch === result[i].branch_id){
            data.push(result[i]);
          }
        }
        this.setState({ needValidation2: data });
      }else{
        this.setState({ needValidation2: result });
      }
      // console.log(result)

    });
    let news = this.state.vfrom_date;
    let date = news.toISOString();
    let split = date.split("T");

    let data = {
      u: localStorage.getItem("u"),
      date: split[0]
    };
    getData("HumanResource/getAttendanceValidated", data).then(result => {
      this.setState({ doneValidation: result });
    });
  }

  componentDidMount() {
    // this.TodayAttendance();
    var branch = "";
    this.attendance_today(branch);
    getData("users/getCompanies", localStorage.getItem("u")).then(response => {
      this.setState({ DATA: response.company });
      var data = [];
      for (let i = 0; i < response.company.length; i++) {
        var match = false;
        for (let index = 0; index < data.length; index++) {
          if (data[index].companyName == response.company[i].company_name) {
            match = true;
          }
        }
        var data2 = [];
        if (match == false) {
          data2 = {
            companyName: response.company[i].company_name,
            company_id: response.company[i].company_id
          };
          data.push(data2);
        }
      }
      console.log(data);
      this.setState({ Company: data });
    });

    var from = String(this.state.from_date);
    var split_from = from.split(" ");
    var from_date_spit =
      split_from[3] + "-" + split_from[1] + "-" + split_from[2];
    this.setState({ from: from_date_spit });

    var to = String(this.state.to_date);
    var split_to = to.split(" ");
    var to_date_spit = split_to[3] + "-" + split_to[1] + "-" + split_to[2];
    this.setState({ to: to_date_spit });

    this.getValidation();
    this.update();
  }

  update() {
    setTimeout(() => {
      this.update();
      this.getValidation();
    }, 50000);
  }

  searchDriver(event) {
    this.setState({ searchDriver: event.target.value.substr(0, 20) });
  }

  approveAttendance(e) {
    let datas = {
      id: e,
      u: localStorage.getItem("u")
    };
    let id = e;
    getData("HumanResource/approve_attendance", datas).then(result => {
      if (result.res === "Success") {
        swal("Successfully approved attendace", "", "success");
        var data = this.state.needValidation;

        var index = data.indexOf(id);
        if (index !== -1) {
          data.splice(index, 1);
          this.setState({ needValidation: data });
        }
        this.getValidation();
      } else {
        swal("Failed to connect to server!", "", "error");
      }
    });
  }

  deniedAttendance(e) {
    let datas = {
      id: e,
      u: localStorage.getItem("u")
    };
    let id = e;
    getData("HumanResource/denied_attendance", datas).then(result => {
      if (result.res === "Success") {
        swal("Successfully denied attendace", "", "success");
        var data = this.state.needValidation;

        var index = data.indexOf(id);
        if (index !== -1) {
          data.splice(index, 1);
          this.setState({ needValidation: data });
        }

        this.getValidation();
      } else {
        swal("Failed to connect to server!", "", "error");
      }
    });
  }

  validationDetails(e) {
    let result = [];
    result.push(e);

    this.setState({ validationDetails: result });
    // console.log(e)
  }

  selectValidation(e) { }
  vsearch(e) {
    this.setState({ vsearch: e.target.value.substr(0, 20) });
  }

  vfilter = e => {
    this.setState({ vfrom_date: e });
    let date = e.toISOString();
    let split = date.split("T");

    let data = {
      u: localStorage.getItem("u"),
      date: split[0]
    };

    getData("HumanResource/getAttendanceValidated", data).then(result => {
      this.setState({ doneValidation: result });
    });
    // setTimeout(() => {
    //   this.setState({
    //     from_date: e
    //   });

    // var from = String(e);
    // var split_from = from.split(" ");
    // var from_date_spit =
    //   split_from[3] + "-" + split_from[1] + "-" + split_from[2];
    // this.setState({ from: from_date_spit });
  };

  render() {
    let AttendanceToday = this.state.AttendanceToday.filter(files => {
      return (
        files.name
          .toLowerCase()
          .indexOf(this.state.searchDriver.toLocaleLowerCase()) !== -1 ||
        files.branch_name
          .toLowerCase()
          .indexOf(this.state.searchDriver.toLocaleLowerCase()) !== -1
      );
    })
      // let timeSheet = this.state.timeSheet.filter(files => {
      //   return (
      //     files.name
      //       .toLowerCase()
      //       .indexOf(this.state.searchDriver.toLocaleLowerCase()) !== -1 ||
      //     files.branch_name
      //       .toLowerCase()
      //       .indexOf(this.state.searchDriver.toLocaleLowerCase()) !== -1
      //   );
      // })


      ;

    if (this.state.navbar === "navigation6") {
      var hidden = {
        display: ""
      };
    } else {
      var hidden = {
        display: "none"
      };
    }

    var vhide = {
      display: this.state.vhide ? "none" : "block"
    };

    var vhide2 = {
      display: this.state.vhide ? "block" : "none"
    }

    let vsearch = this.state.doneValidation.filter(v => {
      return (
        v.user_fname
          .toLowerCase()
          .indexOf(this.state.vsearch.toLocaleLowerCase()) !== -1 ||
        v.user_lname
          .toLowerCase()
          .indexOf(this.state.vsearch.toLocaleLowerCase()) !== -1 ||
        v.status
          .toLowerCase()
          .indexOf(this.state.vsearch.toLocaleLowerCase()) !== -1 ||
        v.att_type
          .toLowerCase()
          .indexOf(this.state.vsearch.toLocaleLowerCase()) !== -1
      );
    });
    return (
      <div id="content" className="content">
        <div className="panel">
          <div className="panel-heading">
            <div className="navmedia">
              <div className="navtitle panel-title" >
                <div>
                  {this.state.navbar === "navigation1" && (
                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Today's Attendance</h4>
                  )}
                  {this.state.navbar === "navigation2" && (
                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Employees' Leave</h4>
                  )}
                  {this.state.navbar === "navigation3" && (
                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Employees Official Business</h4>
                  )}
                  {this.state.navbar === "navigation4" && (
                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Undertime</h4>
                  )}
                  {this.state.navbar === "navigation5" && (
                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Timesheet</h4>
                  )}
                  {this.state.navbar === "navigation6" && (
                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Validation</h4>
                  )}
                </div>
                <div>
                  <nav aria-label="breadcrumb bg-white">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }}>PocketHR</a>
                      </li>
                      {this.state.navbar === "navigation1" && (
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }} >Today's Attendance</a></li>
                      )}
                      {this.state.navbar === "navigation2" && (
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }}>Employees' Leave</a></li>
                      )}
                      {this.state.navbar === "navigation3" && (
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }} >Employees Official Business</a></li>
                      )}
                      {this.state.navbar === "navigation4" && (
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }}>Undertime</a></li>
                      )}
                      {this.state.navbar === "navigation5" && (
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }}>Timesheet</a></li>
                      )}
                      {this.state.navbar === "navigation6" && (
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: "none", cursor: "default" }}>Validation</a></li>
                      )}
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="navnav m-r-20">
                <div
                  name="navigation1"
                  className="attendnav"
                  onClick={() => {
                    this.setState({ navbar: "navigation1" });
                    this.setState({ bgcolor1: "#404040", txtcolor1: "white" });
                    this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                    this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                    this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                    this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                    this.setState({ bgcolor6: "transparent", txtcolor6: "black" });
                    var branch = "";
                    this.attendance_today(branch);
                    this.setState({
                      dateToday: new Date(),
                      // borderbottom1: 'green',
                      hideCompany: true,
                      hideCompany2: true,
                      hideCompany3: true,
                      hideCompany4: true,
                      hideBranch: true,
                      hideBranch2: true,
                      hideBranch3: true,
                      hideBranch4: true,
                      today: true,
                      timeshit: true,
                      officialbusiness: true,
                      ob: true,
                      leave: true,
                      validation: true,
                      exportSHOW: true,
                      daterange: true,
                      exportOB: true,
                      exportLeave: true,
                      vhide: true,
                      breabcrumbs: "Today",
                      title: "Today's Data Report"
                    });
                  }}
                >
                  <a href="javascript:;" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}>
                    TODAY
                </a>
                </div>
                <div
                  name="navigation2"
                  className="attendnav"
                  onClick={() => {
                    this.setState({ navbar: "navigation2" });
                    this.setState({ bgcolor2: "#404040", txtcolor2: "white" });
                    this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                    this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                    this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                    this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                    this.setState({ bgcolor6: "transparent", txtcolor6: "black" });
                    this.getCurrentDay();
                    this.setState({
                      officialbusiness: true,
                      today: false,
                      timeshit: true,
                      leave: false,
                      validation: true,
                      hideCompany: false,
                      hideCompany2: true,
                      hideCompany3: true,
                      hideCompany4: false,
                      hideBranch: true,
                      hideBranch2: true,
                      hideBranch3: true,
                      hideBranch4: true,
                      exportSHOW: true,
                      daterange: false,
                      exportOB: true,
                      exportLeave: true,
                      vhide: true,
                      breabcrumbs: "Leave",
                      title: "Leave Data Report"
                    });
                  }}
                >
                  <a href="javascript:;" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>
                    LEAVE
                </a>
                </div>
                <div
                  name="navigation3"
                  className="attendnav"
                  onClick={() => {
                    this.setState({ navbar: "navigation3" });
                    this.setState({ bgcolor3: "#404040", txtcolor3: "white" });
                    this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                    this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                    this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                    this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                    this.setState({ bgcolor6: "transparent", txtcolor6: "black" });
                    this.getCurrentDay();
                    this.setState({
                      // border1: '#0f9e3e',
                      hideCompany: false,
                      hideCompany2: true,
                      hideCompany3: false,
                      hideCompany4: true,
                      hideBranch: true,
                      hideBranch2: true,
                      hideBranch3: true,
                      hideBranch4: true,
                      officialbusiness: false,
                      today: false,
                      timeshit: true,
                      leave: true,
                      vhide: true,
                      validation: true,
                      exportSHOW: true,
                      daterange: false,
                      exportOB: true,
                      exportLeave: true,
                      breabcrumbs: "Official-Business",
                      title: "Official Business Data Report"
                    });
                  }}
                >
                  <a href="javascript:;" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>
                    O.B
                </a>
                </div>
                <div
                  name="navigation4"
                  className="attendnav"
                  onClick={() => {
                    this.setState({ navbar: "navigation4" });
                    this.setState({ bgcolor4: "#404040", txtcolor4: "white" });
                    this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                    this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                    this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                    this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                    this.setState({ bgcolor6: "transparent", txtcolor6: "black" });
                    this.getCurrentDay();
                    this.setState({
                      // border1: '#0f9e3e',
                      hideCompany: false,
                      hideCompany2: true,
                      hideCompany3: false,
                      hideCompany4: true,
                      hideBranch: true,
                      hideBranch2: true,
                      hideBranch3: true,
                      hideBranch4: true,
                      officialbusiness: false,
                      today: false,
                      timeshit: true,
                      leave: true,
                      vhide: true,
                      validation: true,
                      exportSHOW: true,
                      daterange: false,
                      exportOB: true,
                      exportLeave: true,
                      breabcrumbs: "Undertime",
                      title: "Undertime Data Report"
                    });
                  }}
                >
                  <a href="javascript:;" style={{ color: this.state.txtcolor4, backgroundColor: this.state.bgcolor4 }}>
                    UNDERTIME
                </a>
                </div>
                <div
                  name="navigation5"
                  className="attendnav"
                  onClick={() => {
                    this.setState({ navbar: "navigation5" });
                    this.setState({ bgcolor5: "#404040", txtcolor5: "white" });
                    this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                    this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                    this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                    this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                    this.setState({ bgcolor6: "transparent", txtcolor6: "black" });
                    this.getCurrentDay();
                    this.setState({
                      hideCompany: false,
                      hideCompany2: false,
                      hideCompany3: true,
                      hideCompany4: true,
                      hideBranch: true,
                      hideBranch2: true,
                      hideBranch3: true,
                      hideBranch4: true,
                      today: false,
                      officialbusiness: true,
                      timeshit: false,
                      leave: true,
                      validation: true,
                      vhide: true,
                      AttendanceToday: [],
                      timeSheetHeader: [],
                      daterange: false,
                      exportOB: true,
                      exportLeave: true,
                      breabcrumbs: "Timesheet",
                      title: "Timesheet Data Report"
                    });
                  }}
                >
                  <a href="javascript:;" style={{ color: this.state.txtcolor5, backgroundColor: this.state.bgcolor5 }}>
                    TIMESHEET
                </a>
                </div>

                <div
                  name="navigation6"
                  className="attendnav"
                  onClick={() => {
                    this.setState({ navbar: "navigation6" });
                    this.setState({ bgcolor6: "#404040", txtcolor6: "white" });
                    this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                    this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                    this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                    this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                    this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                    this.getCurrentDay();
                    this.setState({
                      hideCompany: true,
                      hideCompany2: true,
                      hideCompany3: true,
                      hideCompany4: true,
                      hideBranch: true,
                      hideBranch2: true,
                      hideBranch3: true,
                      hideBranch4: true,
                      today: false,
                      officialbusiness: true,
                      timeshit: true,
                      leave: true,
                      validation: false,
                      validationValidated: "transparent",
                      vcolor2: "black",
                      vcolor: "white",
                      validationPending: "#404040",
                      vhide: true,
                      validated: true,
                      AttendanceToday: [],
                      timeSheetHeader: [],
                      daterange: true,
                      exportOB: true,
                      exportLeave: true,
                      breabcrumbs: "Validation",
                      title: "Attendance Validation"
                    });
                  }}
                >
                  <a href="javascript:;" style={{ color: this.state.txtcolor6, backgroundColor: this.state.bgcolor6 }}>
                    VALIDATION
                </a>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-green">
                  <div className="stats-icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="stats-info text-white">
                    <h4>TOTAL EMPLOYEES</h4>
                    <p>{this.state.countTotal}</p>
                  </div>
                  <div className="stats-link">
                    <a
                      href="javascript:;"
                      data-target="#demo-lg-modal5"
                      data-toggle="modal"
                    >
                      View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-blue">
                  <div className="stats-icon">
                    <i className="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info text-white">
                    <h4>PRESENT EMPLOYEES</h4>
                    <p>{this.state.todaysPresent}</p>
                  </div>
                  <div className="stats-link">
                    <a
                      href="javascript:;"
                      data-target="#demo-lg-modal6"
                      data-toggle="modal"
                    >
                      View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-orange">
                  <div className="stats-icon">
                    <i className="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info text-white">
                    <h4>LATE EMPLOYEES</h4>
                    <p>{this.state.todaysLate}</p>
                  </div>
                  <div className="stats-link">
                    <a
                      href="javascript:;"
                      data-target="#demo-lg-modal7"
                      data-toggle="modal"
                    >
                      View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-red">
                  <div className="stats-icon">
                    <i className="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info text-white">
                    <h4>ABSENT EMPLOYEES</h4>
                    <p>{this.state.todaysAbsent}</p>
                  </div>
                  <div className="stats-link">
                    <a
                      href="javascript:;"
                      data-target="#demo-lg-modal8"
                      data-toggle="modal"
                    >
                      View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {this.state.navbar === "navigation6" ? (
                  <div></div>
                ) : (
                    <button className="btttn bg-green-transparent-9 m-b-30 fas fa-filter" data-target="#demo-lg-select" data-toggle="modal">&nbsp;Table Filter</button>
                  )}
                {this.state.exportSHOW === false && (
                  <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt m-l-5">
                    <a style={{ textDecoration: "none", color: "white" }} href={"http://api.pacificweb.com.ph/Users/exporting/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id}>&nbsp;Timesheet</a>
                  </button>
                )}
                {this.state.exportSHOW === false && (
                  <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt m-l-5">
                    <a style={{ textDecoration: "none", color: "white" }} href={"http://api.pacificweb.com.ph/Users/exportingOB_timesheet/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id}>&nbsp;OB&nbsp;Attendance</a>
                  </button>
                )}
                {this.state.exportSHOW === false && (
                  <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt m-l-5">
                    <a style={{ textDecoration: "none", color: "white" }} href={"http://api.pacificweb.com.ph/Users/exportingOT/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id}>&nbsp;Overtime</a>
                  </button>
                )}
                {this.state.exportOB === false && (
                  <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt m-l-5">
                    <a style={{ textDecoration: "none", color: "white" }} href={"http://api.pacificweb.com.ph/Users/exporting_OB/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id + "/" + this.state.breabcrumbs }>
                      OB Filed
                </a>
                  </button>

                )}
                {this.state.exportLeave === false && (
                  <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt m-l-5">
                    <a style={{ textDecoration: "none", color: "white" }} href={"http://api.pacificweb.com.ph/Users/exporting_Leave/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id}>
                      &nbsp;Leave Filed
                </a>
                  </button>

                )}
              </div>
              <div className="col-md-6">
                {this.state.navbar === "navigation6" ? (
                  <div></div>
                ) : (
                    <div className="form-group pull-right m-t-5">
                      <div className="input-group" style={{ width: 330 }}>
                        <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                        <span class="input-group-addon"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div style={hidden} className="m-b-15">
              <div className="navmedia">
                <div className="navnav">
                  <div name="navigationPending"
                    className="attendnav"
                    style={{}}
                    onClick={() => {
                      this.setState({
                        validationValidated: "transparent",
                        vcolor2: "black",
                        vcolor: "white",
                        validationPending: "#404040",
                        vhide: true,
                        validation: false,
                        validated: true
                      });
                    }}
                  >
                    <a href="javascript:;" style={{ color: this.state.vcolor, backgroundColor: this.state.validationPending }}>PENDING</a>
                  </div>
                  <div name="navigationValidated"
                    className="attendnav"
                    onClick={() => {
                      this.setState({
                        validationPending: "transparent",
                        vcolor: "black",
                        vcolor2: "white",
                        validationValidated: "#404040",
                        vhide: false,
                        validation: true,
                        validated: false
                      });
                    }}
                  >
                    <a href="javascript:;" style={{ color: this.state.vcolor2, backgroundColor: this.state.validationValidated }}>VALIDATED</a>
                  </div>
                </div>

                <div style={vhide2}>
                <select className="form-control" onChange={this.passCompanyValidation} required>
                            <option>SELECT COMPANY</option>
                            {this.state.Company.map((val, index) => (
                              <option value={val.company_id} key={index}>
                                {val.companyName}
                              </option>
                            ))}
                          </select>
                </div>
                <div style={vhide2}>
                <select className="form-control" onChange={this.passBranchValidation} required>
                            <option>--SELECT BRANCH--</option>
                            {this.state.Branches.map((val, index) => (
                              <option value={val.branch_id} key={index}>
                                {val.branch_name}
                              </option>
                            ))}
                          </select>
                </div>

                <div style={vhide} className="pull-right">
                  <div className="input-group" style={{ width: 300 }}>
                    <input
                      type="text"
                      className="form-control"
                      id="demo-input-search2"
                      placeholder="Search Name"
                      autocomplete="off"
                      value={this.state.vsearch}
                      onChange={this.vsearch.bind(this)}
                    />
                    <span class="input-group-addon">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <div style={vhide} className="m-b-20">
              <DatePicker
                selected={this.state.vfrom_date}
                startDate={this.state.vfrom_date}
                onChange={this.vfilter}
                className="form-control"
              />
            </div>
            <Scrollbars style={{ height: 350 }}>
              {this.state.today === true && (
                <table className="table table-td-valign-middle">
                  <thead className="tableFixHead text-center">
                    <tr>
                      <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Name</th>
                      {/* <th>Company</th> */}
                      <th style={{ backgroundColor: "#f5f6fa" }}>Branch</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Class</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Status Attendance</th>
                      {this.state.timeSheetHeader.map((val, index1) => {
                        // console.log(val)
                        return (
                          <th
                            style={{ backgroundColor: "#f5f6fa" }}
                            key={index1}
                          >
                            {val.fields}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {AttendanceToday.map((val, index1) => {
                      return (
                        <tr style={{ textAlign: "center" }}>
                          <td>{index1 + 1}</td>
                          <td>{val.name}</td>
                          <td>{val.branch_name}</td>
                          <td>{val.class_type}</td>
                          <td>{val.status}</td>
                          {this.state.timeSheetHeader.map((value, index) => {
                            var data_value = "--:--";
                            {
                              this.state.AttendanceToday.map((val1, index1) => {
                                if (val.user_id == val1.user_id) {
                                  for (
                                    let i = 0;
                                    i < val1.details["timeSheet"].length;
                                    i++
                                  ) {
                                    if (
                                      val1.details["timeSheet"][i].attendance[
                                      index
                                      ] != undefined
                                    ) {
                                      data_value =
                                        val1.details["timeSheet"][i].attendance[
                                        index
                                        ];
                                    } else {
                                      data_value = "--:--";
                                    }
                                  }
                                }
                              });
                            }
                            return <td key={index}>{data_value}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {this.state.timeshit === false && (
                <table className="table">
                  <thead className="tableFixHead">
                    <tr>
                      <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>ID</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Name</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>
                        Company Name
                        </th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Branch</th>
                      <th style={{ backgroundColor: "#f5f6fa", zIndex: 2 }}>
                        Action
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.timeSheet.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{value.user_id}</td>
                          <td>{value.COMPLETENAME}</td>
                          <td>{value.company_name}</td>
                          <td>{value.branch_name}</td>
                          <td>
                            <button className="btttn bg-blue-transparent-9 fas fa-info-circle" data-target="#demo-lg-modal2" data-toggle="modal" onClick={() => this.getTimeshit(value)}>
                              &nbsp;Information
                              </button>

                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {this.state.officialbusiness === false && (
                <table className="table">
                  <thead className="tableFixHead">
                    <tr>
                      <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Name</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>
                        Company Name
                        </th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Branch</th>
                      <th style={{ backgroundColor: "#f5f6fa", zIndex: 2 }}>
                        Action
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.obSheet.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{value.COMPLETENAME}</td>
                          <td>{value.company_name}</td>
                          <td>{value.branch_name}</td>
                          <td>
                            <button className="btttn bg-blue-transparent-9 fas fa-info-circle" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.getObshit(value)}>
                              &nbsp;Information
                              </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {this.state.leave === false && (
                <table className="table">
                  <thead className="tableFixHead">
                    <tr>
                      <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>ID</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Name</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>
                        Company Name
                        </th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Branch</th>
                      <th style={{ backgroundColor: "#f5f6fa", zIndex: 2 }}>
                        Action
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.leaveSheet.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{value.user_id}</td>
                          <td>{value.COMPLETENAME}</td>
                          <td>{value.company_name}</td>
                          <td>{value.branch_name}</td>
                          <td>
                            <button className="btttn bg-blue-transparent-9 fas fa-info-circle" data-target="#demo-lg-modal4" data-toggle="modal" onClick={() => this.getLeaveshit(value)}>
                              &nbsp;Information
                              </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {this.state.validation === false && (
                <table className="table">
                  <thead className="tableFixHead">
                    <tr>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Name</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Type</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Branch</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Time</th>
                      <th style={{ backgroundColor: "#f5f6fa", zIndex: 2 }}>View</th>
                      {/* <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                                <th style={{ backgroundColor: '#f5f6fa', zIndex: 2 }}>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.needValidation2.map(val => (
                      <tr>
                        <td>{val.user_fname + " " + val.user_lname}</td>
                        <td>{val.att_type}</td>
                        <td>{val.branch_name}</td>
                        <td>{val.timelogs}</td>
                        {/* <td><img style={{width: "55px", height: "55px" }} src={"http://images.pacificweb.com.ph/pockethr/profilepic/"+val.attendance_image_path}/></td> */}

                        <td>
                          <button className="btttn bg-green-transparent-9 fas fa-check-circle" data-target="#demo-lg-demo" data-toggle="modal" onClick={() => this.validationDetails(val)}>
                            &nbsp;Validate
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {this.state.validated === false && (
                <table className="table">
                  <thead className="tableFixHead">
                    <tr>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Name</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Type</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Branch</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Time</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>Status</th>
                      <th style={{ backgroundColor: "#f5f6fa" }}>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vsearch.map(val => (
                      <tr>
                        <td>{val.user_fname + " " + val.user_lname}</td>
                        <td>{val.att_type}</td>
                        <td>{val.branch_name}</td>
                        <td>{val.timelogs}</td>
                        {val.status === "Approved" ? (
                          <td style={{ color: "green" }}>{val.status}</td>
                        ) : (
                            <td style={{ color: "red" }}>{val.status}</td>
                          )}
                        <td>
                          <button className="btttn bg-blue-transparent-9 fas fa-info-circle" data-target="#demo-lg-demo" data-toggle="modal" onClick={() => this.validationDetails(val)}>
                            &nbsp;Information
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Scrollbars>



          </div>
        </div>



        <div id="demo-lg-modal2" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">TIMESHEET</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>


              <div className="modal-body">
                <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt pull-right m-b-10">
                  <a style={{ textDecoration: "none", color: "white" }} href={"http://api.pacificweb.com.ph/Users/export_singleTimesheet/" + this.state.from + "/" + this.state.to + "/" + this.state.user_id}>&nbsp;Download Attendance</a>
                </button>

                <Scrollbars style={{ height: 400 }} >
                  <table className="table">
                    <thead className="tableFixHead">
                      <tr>
                        <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Date</th>
                        {/* <th style={{ backgroundColor: '#f5f6fa' }}>Status</th> */}
                        <th style={{ backgroundColor: "#f5f6fa" }}>Time-in</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Time-out</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Time-in</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Time-out</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Time-in</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Time-out</th>
                        {this.state.OBheader.map((val, index) => {
                          return (
                            <th
                              key={index}
                              style={{ backgroundColor: "#f5f6fa" }}
                            >
                              {val.headerOB}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.timeSheetAttendace.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{val.date_Attendance}</td>
                            {/* <td>present</td> */}
                            {this.state.time_type.map((val3, index3) => {
                              var value = "";
                              {
                                this.state.timeSheetAttendace.map(
                                  (val2, indx2) => {
                                    if (
                                      val2.date_Attendance ==
                                      val.date_Attendance
                                    ) {
                                      // if ((index3 === 2 || index3 === 1) && val2.time_attendance.length === 2) {
                                      //     value = "";
                                      // } else {
                                      //     if (val2.time_attendance.length === 2 && index3 === 5)
                                      //         value = val2.time_attendance[1]
                                      //     else
                                      value = val2.time_attendance[index3];
                                      // }
                                    }
                                  }
                                );
                              }

                              var split_date = "";
                              var splitting = "";
                              var attendance = "";
                              var location = "";
                              if (value === undefined || value === "") {
                                split_date = ":-:";
                                splitting = split_date.split("-");
                                attendance = splitting[0];
                                location = splitting[1];
                              } else {
                                split_date = String(value);
                                splitting = split_date.split("-");
                                attendance = splitting[0];
                                location = splitting[1];
                              }
                              return (
                                <td key={index3}>
                                  <p>
                                    {attendance} <br />{" "}
                                    <i style={{ color: "#B53471" }}>
                                      {location}
                                    </i>
                                  </p>
                                </td>
                              );
                            })}
                            {val.OB_Attendance != undefined &&
                              val.OB_Attendance.map((valOB, indexOB) => {
                                var splitting = valOB.date.split("-");
                                var attendance = splitting[0];
                                var location = splitting[1];
                                return (
                                  <td key={indexOB}>
                                    <p>
                                      {attendance} <br />{" "}
                                      <i style={{ color: "#B53471" }}>
                                        {location}
                                      </i>
                                    </p>
                                  </td>
                                );
                              })}
                            {val.OB_Attendance == undefined &&
                              this.state.OBheader.map((valOB, indexOB) => {
                                return (
                                  <td key={indexOB}>
                                    <p>
                                      : <br />{" "}
                                      <i style={{ color: "#B53471" }}>:</i>
                                    </p>
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>

        <div id="demo-lg-modal3" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4>{this.state.breabcrumbs.toUpperCase()}</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                <Scrollbars style={{ height: 400 }}>
                  <table className="table">
                    <thead className="tableFixHead">
                      <tr>
                        <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Date File
                        </th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Date of Ob Start
                        </th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Date of Ob End
                        </th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Justification
                        </th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.obSheetAttendace.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{val.req_date_added}</td>
                            <td>{val.req_date_start}</td>
                            <td>{val.req_date_end}</td>
                            <td>{val.req_justification}</td>
                            <td>{val.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
        <div id="demo-lg-modal4" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4>LEAVE</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                <Scrollbars style={{ height: 400 }}>
                  <table className="table">
                    <thead className="tableFixHead">
                      <tr>
                        <th style={{ backgroundColor: "#f5f6fa" }}>No.</th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Request Date
                        </th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Leave Start
                        </th>
                        <th style={{ backgroundColor: "#f5f6fa" }}>
                          Leave End
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.leaveSheetAttendace.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{val.req_date_added}</td>
                            <td>{val.req_date_start}</td>
                            <td>{val.req_date_end}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
        <div id="demo-lg-modal5" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4>TOTAL EMPLOYEES PER BRANCH</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                <Scrollbars style={{ height: 300 }}>
                  {this.state.branchBreakdown.map((val, index) => {
                    var percent =
                      (val.count.length / this.state.countTotal) * 100;
                    return (
                      <div key={index} className="widget-list-item rounded-0 p-t-3">
                        <div className="widget-list-media icon">
                          <i className="fa fa-circle text-gradient bg-gradient-green"></i>
                        </div>
                        <div className="widget-list-content">
                          <div className="widget-list-title">{val.name}</div>
                        </div>
                        <div className="widget-list-action text-nowrap text-black-transparent-7">
                          <span className="fa fa-user" data-animation="number" data-value="262">
                            &nbsp;{val.count.length} - {percent.toFixed(2)} %
                            </span>
                        </div>
                      </div>
                    );
                  })}
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
        <div id="demo-lg-modal6" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4>PRESENT EMPLOYEES PER BRANCH</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                <Scrollbars style={{ height: 300 }}>
                  {this.state.branchBreakdown.map((val, index) => {
                    var percent =
                      (val.presentCount.length / val.count.length) * 100;
                    return (
                      <div key={index} className="widget-list-item rounded-0 p-t-3">
                        <div className="widget-list-media icon">
                          <i className="fa fa-circle text-gradient bg-gradient-blue"></i>
                        </div>
                        <div className="widget-list-content">
                          <div className="widget-list-title">{val.name}</div>
                        </div>
                        <div className="widget-list-action text-nowrap text-black-transparent-7">
                          <span className="fa fa-user" data-animation="number" data-value="262">
                            &nbsp;{val.presentCount.length} - {percent.toFixed(2)} %
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
        <div id="demo-lg-modal7" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4>LATE EMPLOYEES PER BRANCH</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                <Scrollbars style={{ height: 300 }}>
                  {this.state.branchBreakdown.map((val, index) => {
                    var percent =
                      (val.latecount.length / val.count.length) * 100;
                    return (
                      <div key={index} className="widget-list-item rounded-0 p-t-3">
                        <div className="widget-list-media icon">
                          <i className="fa fa-circle text-gradient bg-gradient-orange"></i>
                        </div>
                        <div className="widget-list-content">
                          <div className="widget-list-title">{val.name}</div>
                        </div>
                        <div className="widget-list-action text-nowrap text-black-transparent-7">
                          <span className="fa fa-user" data-animation="number" data-value="262">
                            &nbsp;{val.latecount.length} - {percent.toFixed(2)}%
                            </span>
                        </div>
                      </div>
                    );
                  })}
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
        <div id="demo-lg-modal8" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4>ABSENT EMPLOYEES PER BRANCH</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                <Scrollbars style={{ height: 300 }}>
                  {this.state.branchBreakdown.map((val, index) => {
                    var percent =
                      (val.absentCount.length / val.count.length) * 100;
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-media icon">
                          <i className="fa fa-circle text-gradient bg-gradient-red"></i>
                        </div>
                        <div className="widget-list-content">
                          <div className="widget-list-title">{val.name}</div>
                        </div>

                        <div className="widget-list-action text-nowrap text-black-transparent-7">
                          <span className="fa fa-user" data-animation="number" data-value="262">
                            &nbsp;{val.absentCount.length} - {percent.toFixed(2)}%
                            </span>
                        </div>
                      </div>
                    );
                  })}
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="demo-lg-validation" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4>Attendance Validation</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                {this.state.validationDetails.map(val => {
                  return (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <h4>Profile</h4>
                        </div>
                        <div className="row">
                          <img
                            style={{ width: "352px", height: "322px" }}
                            src={
                              "http://images.pacificweb.com.ph/pockethr/profilepic/" +
                              val.user_pic
                            }
                          />
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-12">
                            <center>
                              <h4>{val.user_fname + " " + val.user_lname}</h4>
                            </center>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <center>
                              <h4>{val.att_type + ": " + val.date_added}</h4>
                            </center>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <h4>Current</h4>
                        </div>
                        <div className="row">
                          <img
                            style={{ width: "352px", height: "322px" }}
                            src={
                              "http://images.pacificweb.com.ph/pockethr/attendance/" +
                              val.attendance_image_path
                            }
                          />
                        </div>
                        <br />
                        {this.state.validated === true ? (
                          <div className="row">
                            <div className="col-md-12">
                              <center>
                                <div className="btn-group">
                                  <button
                                    className="btttn  bg-green-transparent-9 fa fa-check"
                                    onClick={() =>
                                      this.approveAttendance(val.attendance_id)
                                    }
                                    data-toggle="modal"
                                  >
                                    &nbsp;Approve&nbsp;
                                  </button>
                                  <div class="m-l-3 or m-r-3"></div>
                                  <button
                                    data-target="#demo-lg-modal-DELETE"
                                    data-toggle="modal"
                                    onClick={() =>
                                      this.deniedAttendance(val.attendance_id)
                                    }
                                    className="btttn bg-red-transparent-9 fas fa-times"
                                  >
                                    &nbsp;Deny&nbsp;
                                  </button>
                                </div>
                              </center>
                            </div>
                          </div>
                        ) : (
                            <div>
                              <div className="row">
                                <div className="col-md-12">
                                  <center>
                                    {val.status === "Approved" ? (
                                      <h4 style={{ color: "green" }}>
                                        {val.status}
                                      </h4>
                                    ) : (
                                        <h4 style={{ color: "red" }}>
                                          {val.status}
                                        </h4>
                                      )}
                                  </center>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <center>
                                    <h4>Date approved: {val.date_validated}</h4>
                                  </center>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div> */}

        <div id="demo-lg-demo" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4>Attendance Validation</h4>
                <button className="btttn bg-red-transparent-9 pull-right fa fa-times-circle" data-dismiss="modal">
                </button>
              </div>
              <div className="modal-body">
                {this.state.validationDetails.map(val => {
                  return (
                    <div className="row">
                      <div className="col-md-6 border-right">
                        <center>
                          {/* <h4>{val.user_jobposition}</h4> */}
                          <img style={{ width: 150, height: 150 }} className="rounded-circle border border-secondary m-t-10" src={"http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic} />
                          <div className="m-t-15">
                            <h4>{val.user_fname + " " + val.user_lname}</h4>
                            <h4>{val.branch_name}</h4>
                          </div>

                        </center>

                      </div>
                      <div className="col-md-6">
                        <center>
                          <h4>{val.att_type}&nbsp;:&nbsp;{moment(val.timelogs).format('LLLL')}</h4>
                          <img style={{ width: 150, height: 150 }} className="rounded border border-secondary" src={"http://images.pacificweb.com.ph/pockethr/attendance/" + val.attendance_image_path} />
                        </center>
                        {this.state.validated === true ? (
                          <center>
                            <div className="btn-group m-t-15">
                              <button className="btttn  bg-green-transparent-9 fa fa-check" onClick={() => this.approveAttendance(val.attendance_id)} data-toggle="modal">
                                &nbsp;Approve
                            </button>
                              <div class="m-l-3 or m-r-3" />
                              <button data-target="#demo-lg-modal-DELETE" data-toggle="modal" onClick={() => this.deniedAttendance(val.attendance_id)} className="btttn bg-red-transparent-9 fas fa-times">
                                &nbsp;Deny
                            </button>
                            </div>
                          </center>

                        ) : (
                            <center>
                              {val.status === "Approved" ? (
                                <div>
                                <h4 style={{ color: "green" }}>
                                  {val.status}
                                </h4>
                                  <h4>Date approved: {val.date_validated}</h4>
                                  </div>
                              ) : (
                                <div>
                                  <h4 style={{ color: "red" }}>
                                    {val.status}
                                  </h4>
                                  <h4>Date denied: {val.date_validated}</h4>
                                  </div>
                                )}
                            
                            </center>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div id="demo-lg-select" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4>Table Filter</h4>
              </div>
              <div className="modal-body">
                <div>
                  {this.state.daterange === false && (
                    <div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">From date :</label>
                        <div class="col-md-9">
                          <DatePicker
                            selected={this.state.from_date}
                            selectsStart
                            startDate={this.state.from_date}
                            endDate={this.state.to_date}
                            onChange={this.filterDateStart}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">To Date:</label>
                        <div class="col-md-9">
                          <DatePicker
                            selected={this.state.to_date}
                            selectsEnd
                            startDate={this.state.from_date}
                            endDate={this.state.to_date}
                            onChange={this.filterDateEnd}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.hideCompany === true && (
                    <div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Company:</label>
                        <div class="col-md-9">
                          <select className="form-control" onChange={this.passCompany} required>
                            <option>SELECT COMPANY</option>
                            {this.state.Company.map((val, index) => (
                              <option value={val.company_id} key={index}>
                                {val.companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Branch:</label>
                        <div class="col-md-9">
                          <select className="form-control" onChange={this.passBranch} required>
                            <option>--SELECT BRANCH--</option>
                            {this.state.Branches.map((val, index) => (
                              <option value={val.branch_id} key={index}>
                                {val.branch_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                    </div>
                  )}
                  {this.state.hideCompany2 === false && (
                    <div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Company:</label>
                        <div class="col-md-9">
                          <select className="form-control" onChange={this.passCompany2}>
                            <option>--SELECT COMPANY2--</option>
                            <option></option>
                            {this.state.Company.map((val, index) => (
                              <option value={val.company_id} key={index}>
                                {val.companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Company:</label>
                        <div class="col-md-9">
                          <select className="form-control" onChange={this.passBranch2} >
                            <option>--SELECT BRANCH2--</option>
                            <option></option>
                            {this.state.Branches.map((val, index) => (
                              <option value={val.branch_id} key={index}>
                                {val.branch_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.hideCompany3 === false && (
                    <div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Company:</label>
                        <div class="col-md-9">
                          <select
                            className="form-control"
                            onChange={this.passCompany3}
                          >
                            <option>--SELECT COMPANY3--</option>
                            <option></option>
                            {this.state.Company.map((val, index) => (
                              <option value={val.company_id} key={index}>
                                {val.companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Branch</label>
                        <div class="col-md-9">
                          <select
                            className="form-control"
                            onChange={this.passBranch3}
                          >
                            <option>--SELECT BRANCH3--</option>
                            <option></option>
                            {this.state.Branches.map((val, index) => (
                              <option value={val.branch_id} key={index}>
                                {val.branch_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.hideCompany4 === false && (
                    <div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Company:</label>
                        <div class="col-md-9">
                          <select
                            className="form-control"
                            onChange={this.passCompany4}
                          >
                            <option>--SELECT COMPANY4--</option>
                            <option></option>
                            {this.state.Company.map((val, index) => (
                              <option value={val.company_id} key={index}>
                                {val.companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Select Branch</label>
                        <div class="col-md-9">
                          <select
                            className="form-control"
                            onChange={this.passBranch4}
                          >
                            <option>--SELECT BRANCH4--</option>
                            <option></option>
                            {this.state.Branches.map((val, index) => (
                              <option value={val.branch_id} key={index}>
                                {val.branch_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="pull-right">
                  <button className="btttn bg-green-transparent-9 fas fa-plus-circle" data-dismiss="modal">&nbsp;Submit</button>
                  <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none  m-l-5" data-dismiss="modal">&nbsp;Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Attendance;
