import React, { Component } from 'react';
import { getData,apiurl } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
// import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup, } from '@progress/kendo-react-excel-export';
class AddNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: [],
            parent: [],
            groups: "",
            parents: "",
            nav_name: "",
            icon: "",
            route: "",
            nav: [],
            apps: [],
            projects: [],
            team: [],
            humanresourcces: [],
            reports: [],
            setting: [],
            edits: false,
            page: "",
            users: [],
            select: "navigation",
            allApps: [],
            app_name: "",
            picture: "",
            app_route: "",
            app_id: "",
            company_id: "",
            editApps: false,
            file: [],
            category_id: 0,
            appName: "",
            app_nav: [],
            check_app_nav: [],
            redirect: false,
            parentss: [],
            navapp: "List of Navigations",



        }
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeParent = this.onChangeParent.bind(this);
        this.textchange = this.textchange.bind(this);
        this.Submit_Nav = this.Submit_Nav.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    allpictureupload(file) {
        var data1 = []
        var withPic = false
        // console.log(this.state.app_name + "/" + this.state.app_id + "/" + this.state.company_id + "/" + this.state.editApps + "/" + this.state.category_id + "/" + withPic)
        if (this.state.file.length != 0) {
            withPic = true
        }
        //     var datasplit = this.state.app_route.split("/");
        //    this.state.app_route.replace('/','-');
        console.log(this.state.app_name + "/" + this.state.app_id + "/" + this.state.company_id + "/" + this.state.editApps + "/" + this.state.category_id + "/" + withPic + "/" + this.state.app_route);
        data1 = { app_name: this.state.app_name, app_route: this.state.app_route, app_id: this.state.app_id, company_id: this.state.company_id, category_id: this.state.category_id, edit: this.state.editApps, withPic: withPic }
        const url = apiurl+ "users/AddApps/"
        const formData = new FormData();

        for (let i = 0; i < file.length; i++) {
            formData.append('picture', file[i]);
            formData.append('picture2', JSON.stringify(data1));
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return post(url, formData, config);
    }
    onFormSubmit(e) {
        e.preventDefault();
        this.allpictureupload(this.state.file)
            .then(response => {
                console.log(response);
                alert("Successfully Added");
                this.getNav();
                this.setState({ app_name: "", app_route: "", app_id: "", company_id: "" })
            })
    }
    onChange(e) {
        this.setState({ file: e.target.files });
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    app_textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    checkPagePrivilege() {
        var route = "#/addNavigation";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    componentDidMount() {
        this.checkPagePrivilege();
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "superadmin" }).then(response => {
            this.setState({ group: response.groups })

        })
        this.getNav();
    }
    getNav() {
        getData("users/Get_nav").then(response => {
            console.log(response);
            this.setState({ app_nav: response.app_nav, allApps: response.all_apps, nav: response.nav, apps: response.apps, projects: response.projects, team: response.team, humanresourcces: response.humanresource, reports: response.report, setting: response.setting, users: response.users })
        })
    }
    getApps() {
        getData("users/get_Apps").then(response => {
            // console.log(response);

        })

    }
    onChangeGroup(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({ groups: options[i].value })
                this.filter_group(options[i].value);
            }
        }
    }
    onChangeParent(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({ parents: options[i].value })
            }
        }
    }
    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ select: options[i].value })
            }
        }
    }
    filter_group(id) {

        getData("users/Get_group_elements", id).then(response => {
            console.log(response)
            this.setState({ parentss: response.elements })

        })

    }
    Submit_Nav = e => {
        e.preventDefault();
        var data = [];
        if (this.state.nav_name == "" || this.state.icon == "" || this.state.route == "" || this.state.groups == "" || this.state.parents == "") {
            alert("Please complete the details.")
        }
        else {
            data = { nav_name: this.state.nav_name, nav_icon: this.state.icon, nav_route: this.state.route, group: this.state.groups, parent: this.state.parents }
            getData("users/AddNav", data).then(response => {

                this.setState({ nav_name: '', icon: '', route: '', groups: '', parents: '' })
                this.getNav();
            })
        }

    }
    // Submit_Apps = e => {
    //     e.preventDefault();
    //     var data = [];
    //     if (this.state.app_name == "" || this.state.picture == "" || this.state.app_route == "" || this.state.app_id == "" || this.state.company_id == "") {
    //         alert("Please complete the details.")
    //     } else {
    //         data = { app_name: this.state.app_name, picture: this.state.picture, app_id: this.state.app_id, company_id: this.state.company_id, app_route: this.state.app_route}
    //         getData("users/AddApps", data).then(response => {
    //             console.log(response);
    //         })
    //     }

    // }
    remove = (data) => {
        getData("users/removeNav", data).then(response => {

            this.getNav();
        })

    }
    reCover = (data) => {
        getData("users/recoverNav", data).then(response => {

            this.getNav();
        })

    }
    edit = (data, edit) => {

        this.setState({ nav_name: data.page_name, icon: data.icon, route: data.href, groups: data.group_id, parents: data.parent_id, page: data.page_id, edits: edit })
        this.filter_group(data.group_id);

    }
    editNav = () => {

        var details = [];

        details = { nav_name: this.state.nav_name, icon: this.state.icon, route: this.state.route, groups: this.state.groups, parents: this.state.parents, page: this.state.page, edits: this.state.edits }
        console.log(details)

        getData("users/editNav", details).then(response => {
            this.getNav();
        })
    }
    selectMODAL = (data) => {

        this.setState({ nav_name: "", icon: "", route: "", groups: "", parents: "", edits: false, select: data })
        this.setState({ app_name: "", app_route: "", app_id: "", company_id: "", editApps: false })
    }
    appsEdit = (data, edit) => {
        console.log(data);
        this.setState({ app_name: data.category_details, app_route: data.c_href, app_id: data.app_id, company_id: data.company_id, editApps: edit, category_id: data.category_id })
    }
    cancelApps = (data) => {
        this.setState({ app_name: "", app_route: "", app_id: "", company_id: "", editApps: data })
    }
    addNav = (data) => {

        console.log(data);
        var details = []
        details = { app_id: this.state.app_id, company_id: this.state.company_id }
        getData("users/addAppNavigation", { data_details: data, app_com: details }).then(response => {
            this.app_id({ app_id: this.state.app_id, company_id: this.state.company_id });
        })


    }
    app_id = (data) => {
        this.setState({ appName: data.category_details })
        getData("users/getApp_Nav", { app_id: data.app_id, company_id: data.company_id }).then(response => {
            //    console.log(response);
            this.setState({ check_app_nav: response.data })
        })

        this.setState({ app_id: data.app_id, company_id: data.company_id })

    }
    removeAppNav(data) {

        getData("users/deleteAppNav", { details: data, app_id: this.state.app_id }).then(response => {
            console.log(response);
            this.app_id({ app_id: this.state.app_id, company_id: this.state.company_id });

        })

    }
    selectTab = (data) => {
        this.setState({ select: data })
    }

    render() {
        console.log(this.state.editNav)
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        return (

            <div id="content " className="content ">
                <div className="row text-center" style={{ marginTop: "-40px" }}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item1</label>
                        </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item2</label>
                        </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item3</label>
                        </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>

                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item4</label>
                        </a>

                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">{this.state.navapp}</h1>
                    </div>
                    <div className="col-sm-6">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Settings</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Navigation Option</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>{this.state.navapp}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <button class="bttnlnav bg-blue-lighter" onClick={() => { this.selectTab("navigation"); this.setState({ navapp: "List of Navigations" }) }}>
                        <span className="fa fa-drafting-compass"></span>
                    </button>


                    <button class="bttnlapp bg-blue-lighter" onClick={() => { this.selectTab("apps"); this.setState({ navapp: "List of Application" }) }}>
                        <span className="fa fa-book"></span>
                    </button>
                </div><br />


                {/* <hr style={{ height: "2px", border: 10}}></hr> */}
                <div className="row">

                    {this.state.select == "navigation" &&
                        <div className="col-md-12">
                            <div className="panel panel-default" >
                                <div className="panel-body">
                                    <button className="bttnlnav pull-right bg-green-lighter" onClick={() => this.selectMODAL("navigation")} data-target="#demo-lg-modal3" data-toggle="modal">
                                        <span className="fa fa-plus"></span>
                                    </button>

                                    <Scrollbars style={{ height: 390 }}>
                                        <hr style={{ height: "2px", border: 10 }} />

                                        <div className=" ">
                                            <span style={{ fontSize: 9.5 }}>Navigation</span>
                                            <table class="table">
                                                {this.state.nav.map((val, index) => {
                                                    return <tbody>

                                                        <tr key={index} style={{ height: 10 }}>
                                                            <td width="33%">  {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }<i className={val.icon}></i>&nbsp;&nbsp;
                                                                <span >{val.page_name}</span></td>
                                                            <td width="33%">{val.href}</td>
                                                            <td width="33%">
                                                                <div className="btn-group pull-right">
                                                                    <button className="bttnrecover bg-green-lighter" onClick={() => this.reCover(val)}>
                                                                        <span className="fa fa-heartbeat"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttndelete bg-red-lighter" onClick={() => this.remove(val)}>
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnedit bg-blue-lighter" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}>
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </div>
                                                                {/* <a tooltip="EDIT" style={{ backgroundColor: "white" }} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}> <i className="fa fa- fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a> */}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                })}
                                            </table>
                                            {/* <ul style={{ marginLeft: '3%' }}>

                                            <li style={{ listStyle: "none" }} className="nav-header"><h6>Navigation</h6></li>
                                            {this.state.nav.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }

                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                        <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa-2x fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa-2x fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa-2x fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                            <li style={{ listStyle: "none" }} className="nav-header">
                                                <span style={{ fontSize: 9.5 }}>Apps</span>
                                            </li>
                                            {this.state.apps.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                        <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa-2x fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa-2x fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa-2x fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </ul> */}
                                            <hr className="divider" />

                                            <span style={{ fontSize: 9.5 }}>Applications</span>
                                            <table class="table">
                                                {this.state.apps.map((val, index) => {
                                                    return <tbody>

                                                        <tr key={index}>
                                                            <td width="33%">  {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }<i className={val.icon}></i>&nbsp;&nbsp;
                                                                <span >{val.page_name}</span></td>
                                                            <td width="33%">{val.href}</td>
                                                            <td width="33%">
                                                                <div className="btn-group pull-right">
                                                                    <button className="bttnrecover bg-green-lighter" onClick={() => this.reCover(val)}>
                                                                        <span className="fa fa-heartbeat"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttndelete bg-red-lighter" onClick={() => this.remove(val)}>
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnedit bg-blue-lighter" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}>
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </div>
                                                                {/* <p>
                                                                    <a tooltip="EDIT" style={{ backgroundColor: "white" }} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>
                                                                </p> */}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                })}
                                            </table>
                                            <hr className="divider" />

                                            <span style={{ fontSize: 9.5 }}>Department</span>
                                            <table class="table">
                                                {this.state.projects.map((val, index) => {
                                                    return <tbody>

                                                        <tr key={index}>
                                                            <td width="33%">  {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }<i className={val.icon}></i>&nbsp;&nbsp;
                                                                <span >{val.page_name}</span></td>
                                                            <td width="33%">{val.href}</td>
                                                            <td width="33%">
                                                                <div className="btn-group pull-right">
                                                                    <button className="bttnrecover bg-green-lighter" onClick={() => this.reCover(val)}>
                                                                        <span className="fa fa-heartbeat"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttndelete bg-red-lighter" onClick={() => this.remove(val)}>
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnedit bg-blue-lighter" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}>
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </div>
                                                                {/* <p>
                                                                <a tooltip="EDIT" style={{ backgroundColor: "white" }} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>
                                                            </p> */}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                })}
                                            </table>
                                            {/* <hr className="divider" />
                                        <ul style={{ marginLeft: '3%', }}>

                                            <li style={{ listStyle: "none", marginTop: '4%' }} className="nav-header"><h6>Department</h6></li>
                                            <li style={{ listStyle: "none", marginTop: '2%' }} className="nav-header">
                                                <span style={{ fontSize: 9.5 }}>Projects</span>
                                            </li>
                                            {this.state.projects.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}


                                            <li style={{ listStyle: "none", marginTop: '2%' }} className="nav-header">
                                                <span style={{ fontSize: 9.5 }}>Team</span>
                                            </li>

                                            {this.state.team.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                 <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </ul> */}
                                            <hr className="divider" />

                                            <span style={{ fontSize: 9.5 }}>Administrative</span>
                                            <table class="table">
                                                {this.state.humanresourcces.map((val, index) => {
                                                    return <tbody>

                                                        <tr key={index}>
                                                            <td width="33%">  {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }<i className={val.icon}></i>&nbsp;&nbsp;
                                                                <span >{val.page_name}</span></td>
                                                            <td width="33%">{val.href}</td>
                                                            <td width="33%">
                                                                <div className="btn-group pull-right">
                                                                    <button className="bttnrecover bg-green-lighter" onClick={() => this.reCover(val)}>
                                                                        <span className="fa fa-heartbeat"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttndelete bg-red-lighter" onClick={() => this.remove(val)}>
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnedit bg-blue-lighter" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}>
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </div>
                                                                {/* <p>
                                                                <a tooltip="EDIT" style={{ backgroundColor: "white" }} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>
                                                            </p> */}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                })}
                                            </table>

                                            {/* 
                                        <hr className="divider" />
                                        <ul style={{ marginLeft: '3%' }}>

                                            <li style={{ listStyle: "none" }} className="nav-header"><h6>Administrative</h6></li>
                                            <li style={{ listStyle: "none", marginTop: '2%' }} className="nav-header">
                                                <span style={{ fontSize: 9.5 }}>Human Resource</span>
                                            </li>
                                            {this.state.humanresourcces.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                 <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>

                                                    </div>
                                                </li>
                                            )}
                                            <li style={{ listStyle: "none", marginTop: '2%' }} className="nav-header">
                                                <span style={{ fontSize: 9.5 }}>Users</span>
                                            </li>
                                            {this.state.users.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                 <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}

                                            <li style={{ listStyle: "none", marginTop: '2%' }} className="nav-header">
                                                <span style={{ fontSize: 9.5 }}>Reports</span>
                                            </li>

                                            {this.state.reports.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                 <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                              <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>   
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </ul> */}
                                            <hr className="divider" />


                                            <span style={{ fontSize: 9.5 }}>Settings</span>
                                            <table class="table">
                                                {this.state.setting.map((val, index) => {
                                                    return <tbody>

                                                        <tr key={index}>
                                                            <td width="33%">  {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }<i className={val.icon}></i>&nbsp;&nbsp;
                                                                <span >{val.page_name}</span></td>
                                                            <td width="33%">{val.href}</td>
                                                            <td width="33%">
                                                                <div className="btn-group pull-right">
                                                                    <button className="bttnrecover bg-green-lighter" onClick={() => this.reCover(val)}>
                                                                        <span className="fa fa-heartbeat"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttndelete bg-red-lighter" onClick={() => this.remove(val)}>
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnedit bg-blue-lighter" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}>
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </div>
                                                                {/* <p>
                                                                <a tooltip="EDIT" style={{ backgroundColor: "white" }} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>
                                                            </p> */}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                })}
                                            </table>


                                            <hr className="divider" />

                                            <span style={{ fontSize: 9.5 }}>Navigation for Apps</span>
                                            <table class="table">
                                                {this.state.app_nav.map((val, index) => {
                                                    return <tbody>

                                                        <tr key={index}>
                                                            <td width="33%">  {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }<i className={val.icon}></i>&nbsp;&nbsp;
                                                                <span >{val.page_name}</span></td>
                                                            <td width="33%">{val.href}</td>
                                                            <td width="33%">
                                                                <div className="btn-group pull-right">
                                                                    <button className="bttnrecover bg-green-lighter" onClick={() => this.reCover(val)}>
                                                                        <span className="fa fa-heartbeat"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttndelete bg-red-lighter" onClick={() => this.remove(val)}>
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnedit bg-blue-lighter" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}>
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </div>
                                                                {/* <p>
                                                                <a tooltip="EDIT" style={{ backgroundColor: "white" }} className="btn btn-xs  pull-right" style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.edit(val, true)}> <i className="fa fa fa-edit " style={{ color: "#7f8c8d", marginLeft: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="REMOVE" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                            <a tooltip="RECOVER" className="btn btn-xs  pull-right" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa fa-check-circle" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>
                                                            </p> */}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                })}
                                            </table>
                                            {/* <hr className="divider" />
                                        <ul style={{ marginLeft: '3%' }}>

                                            <li style={{ listStyle: "none" }} className="nav-header"><h6>Navigation for Apps</h6></li>
                                            {this.state.app_nav.map((val) =>
                                                <li style={{ listStyle: "none", marginLeft: '3%' }} className="nav-header">
                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            {val.delete_id == 1 &&

                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>
                                                            }
                                                            <i className={val.icon}></i>&nbsp;&nbsp;
                                                 <span >{val.page_name}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <span >{val.c_href}</span>
                                                        </div>
                                                        <div className="col-md-5">
                                                          <p>
                                                             <a tooltip="EDIT"style={{backgroundColor:"white"}} className="btn btn-xs " style={{ color: "white" }} data-target="#demo-lg-modal3" data-toggle="modal"  onClick={() => this.edit(val, true)}> <i className="fa fa-2x fa-edit " style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                                <a tooltip="REMOVE" className="btn btn-xs" style={{ color: "white" }} onClick={() => this.remove(val)}> <i className="fa fa-2x fa-trash-alt" style={{ color: "#7f8c8d", marginRight: "5%" }}></i></a>&nbsp;
                                                                <a tooltip="RECOVER" className="btn btn-xs" style={{ color: "white" }} onClick={() => this.reCover(val)}><i className="fa fa-2x fa-check-circle" style={{ color: "#009432", marginRight: "5%" }}></i></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}


                                        </ul> */}
                                        </div>

                                    </Scrollbars>
                                </div>

                            </div>
                        </div>
                    }
                    {this.state.select == "apps" &&
                        <div className="col-md-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <button className="bttnlapp pull-right bg-green-lighter" onClick={() => this.selectMODAL("apps")} data-target="#demo-lg-modal3" data-toggle="modal">
                                        <span className="fa fa-plus"></span>
                                    </button>
                                </div>
                                <div className="panel-body" >
                                    <hr style={{ height: "2px", border: 10 }} />
                                    <Scrollbars style={{ height: 390, backgroundColor: "#ecf0f1" }}>
                                        <div className="row ">
                                            {this.state.allApps.map((val, index) =>
                                                <div className="col-md-3  w3-animate-zoom" key={index} style={{ paddingTop: "4%", paddingLeft: "3%", paddingRight: "3%" }}>
                                                    <div className="panel panel-colorful" >
                                                        <div className="pad-all text-center">
                                                            <img src={"http://api.pacificweb.com.ph/assets/img/apps/" + val.category_attribute} style={{ width: 80, height: 80, paddingBottom: 10 }} /><br />
                                                            <span className="text-color">{val.category_details}</span><br />
                                                            <div className="btn-group">
                                                                <button className="bttnview2 bg-blue-lighter" data-target="#demo-lg-modal2" data-toggle="modal" onClick={() => this.app_id(val)}>
                                                                    <span className="fa fa-hands-helping"></span>
                                                                </button>
                                                                <div class="or"></div>
                                                                <button className="bttnedit bg-blue-lighter" onClick={() => this.appsEdit(val, true)} data-target="#demo-lg-modal3" data-toggle="modal">
                                                                    <span className="fa fa-cogs"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}



                                        </div>
                                    </Scrollbars>
                                </div>




                            </div>
                        </div>
                    }
                </div>
                <div id="demo-lg-modal2" className="modal w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Add Privilege</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 400 }}>
                                    <div className="panel-body">
                                        <h6>List of Privilege</h6>
                                        <table class="table">

                                            {this.state.app_nav.map((val) => {
                                                var hide = false;
                                                for (let i = 0; i < this.state.check_app_nav.length; i++) {
                                                    if (this.state.check_app_nav[i].nav_parent_id == val.parent_id) {
                                                        hide = true;
                                                    }
                                                }

                                                return <tbody>
                                                    <tr>
                                                        <td width="33%">  {hide == true &&
                                                            <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>
                                                        } {hide == false &&
                                                            <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                            } {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>}<i className={val.icon}>&nbsp;&nbsp;</i><span >{val.page_name}</span>
                                                        </td>
                                                        <td width="33%"><span >{val.href}</span></td>
                                                        {hide == true &&
                                                            <td width="33%">
                                                                <button className="bttnundo pull-right bg-red-lighter" onClick={() => this.removeAppNav(val)}>
                                                                    <span className="fa fa-ban"></span>
                                                                </button>
                                                            </td>
                                                        }
                                                        {hide == false &&
                                                            <td width="33%">
                                                                <button className="bttnimport pull-right bg-green-lighter" onClick={() => this.addNav(val)}>
                                                                    <span className="fa fa-cloud-download-alt"></span>
                                                                </button>
                                                            </td>
                                                        }
                                                    </tr>

                                                </tbody>
                                            })}
                                        </table>


                                    </div>

                                </Scrollbars>
                                <hr />



                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal3" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Settings</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="panel">

                                    <div className="panel-body">

                                        <div className="row">
                                            <div className="col-md-12">

                                                {/* <h5>Select</h5> */}

                                                {/* <select value={this.state.select} className="form-control" onChange={this.onChangeSelect}>

                                            <option value="navigation">Navigation</option>
                                            <option value="apps">Apps</option>
                                        </select> */}
                                                {this.state.select == "navigation" &&

                                                    <div>

                                                        <h6>Navigation Name</h6>
                                                        <input value={this.state.nav_name} onChange={this.textchange} type="text" name="nav_name" id="exampleCity" className="form-control" required />
                                                        <div style={{ paddingTop: "20px" }}>
                                                            <h6>Icon Code:</h6>
                                                            <input value={this.state.icon} onChange={this.textchange} type="text" name="icon" id="exampleCity" className="form-control" required />
                                                        </div>
                                                        <div style={{ paddingTop: "20px" }}>
                                                            <h6>Route:</h6>
                                                            <input value={this.state.route} onChange={this.textchange} type="text" name="route" id="exampleCity" className="form-control" required />
                                                        </div>

                                                        <div className="row">
                                                            {this.state.edits == false &&
                                                                <div className="col-md-6">
                                                                    <div style={{ paddingTop: "20px" }}>
                                                                        <h6>Select Group:</h6>
                                                                        <select value={this.state.groups} className="form-control" onChange={this.onChangeGroup}>
                                                                            <option></option>
                                                                            {this.state.group.map((val) =>
                                                                                <option value={val.group_id}>{val.group_name}</option>
                                                                            )}

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {this.state.edits == false &&
                                                                <div className="col-md-6">
                                                                    <div style={{ paddingTop: "20px" }}>
                                                                        <h6>Select Parent:</h6>
                                                                        <select value={this.state.parents} className="form-control" onChange={this.onChangeParent}>
                                                                            <option></option>
                                                                            {(this.state.groups == 1 || this.state.groups == 4 || this.state.groups == 5) &&
                                                                                <option value="0" >None</option>
                                                                            }
                                                                            {this.state.parentss.map((val) => {
                                                                                console.log(this.state.parentss)
                                                                                if (val.href == null)
                                                                                    return <option value={val.parent_id}>{val.parent_name}</option>
                                                                            }

                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div style={{ paddingTop: "20px" }}>
                                                            <hr />
                                                            {this.state.edits == false &&
                                                                <button className="bttnsub bg-green-lighter pull-right" onClick={this.Submit_Nav}>
                                                                    <span className="fa fa-paper-plane"></span>
                                                                </button>
                                                            }
                                                            {this.state.edits == true &&
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-md-8">

                                                                        </div>

                                                                        <div className="col-md-4">
                                                                            <button className="bttnsub bg-green-lighter pull-right" onClick={() => this.editNav()}>
                                                                                <span className="fa fa-paper-plane"></span>
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.select == "apps" &&
                                                    <form onSubmit={this.onFormSubmit} >

                                                        <div style={{ paddingBottom: "20px" }}>
                                                            {this.state.editApps == false &&
                                                                <h6>Add Image:</h6>
                                                            }
                                                            {this.state.editApps == true &&
                                                                <h6>Change Image:</h6>
                                                            }
                                                            <input type="file" name="myImage" className="form-control" onChange={this.onChange} />

                                                        </div>
                                                        <h6>Application Name:</h6>
                                                        <input value={this.state.app_name} onChange={this.app_textchange} type="text" name="app_name" id="exampleCity" className="form-control" required />

                                                        <view><br />
                                                            <h6>Route:</h6>
                                                            <input value={this.state.app_route} onChange={this.app_textchange} type="text" name="app_route" id="exampleCity" className="form-control" required />
                                                        </view>
                                                        {/* } */}<br />
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6>App ID</h6>
                                                                <input value={this.state.app_id} onChange={this.app_textchange} type="text" name="app_id" id="exampleCity" className="form-control" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h6>Company ID</h6>
                                                                <input value={this.state.company_id} onChange={this.app_textchange} type="text" name="company_id" id="exampleCity" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr />
                                                            {this.state.editApps == false &&
                                                                <button className="bttnsub pull-right bg-green-lighter" type="submit" >
                                                                    <span className="fa fa-paper-plane"></span>
                                                                </button>
                                                            }
                                                            {this.state.editApps == true &&
                                                                <div>

                                                                    <button className="bttnupdate pull-right bg-green-lighter" type="submit">
                                                                        <span className="fa fa-save"></span>
                                                                    </button>


                                                                    {/* <div className="col-md-6">
                                                                    <a  className="btn  btn-xs pull-right" style={{ color: "white" ,backgroundColor:"#34495e",width:55}} onClick={() => this.cancelApps(false)}>Cancel</a>
                                                                </div> */}

                                                                </div>
                                                            }
                                                        </div>
                                                    </form>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}

export default AddNav;