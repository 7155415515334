import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigation: 1,
            // bgcolor1: "#404040",
            // bgcolor2: "#ededed",
            // bgcolor3: "#ededed",
            // bgcolor4: "#ededed",
            // bgcolor5: "#ededed",
            // txtcolor1: "white",
            // txtcolor2: "black",
            // txtcolor3: "black",
            // txtcolor4: "black",
            // txtcolor5: "black",
            // navbar: "navigation1",
        };
    }
    navHandler = () => {
        this.setState({ navigation: !this.state.navigation })
    }
    render() {


        let bgcolor = localStorage.getItem("bgcolor");
        let txtcolor = localStorage.getItem("txtcolor")
        return (
            <div>
                {/* HEADER START */}
                <div className="row">
                    {/* PAGE HEADER & BREADCRUMBS START */}
                    <div className="col-md-6">
                        {localStorage.getItem("navbar") === "navigation1" &&
                            <h4 className="page-header"><i className="fa fa-tachometer-alt text-dark" />&nbsp;Dashboard</h4>
                        }
                        {localStorage.getItem("navbar") === "navigation2" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Header2</h4>
                        }
                        {localStorage.getItem("navbar") === "navigation3" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Header3</h4>
                        }
                        {localStorage.getItem("navbar") === "navigation4" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Header4</h4>
                        }
                        {localStorage.getItem("navbar") === "navigation5" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Header5</h4>
                        }
                        <nav aria-label="breadcrumb bg-white" style={{ marginTop: "-15px" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Asset Management</a></li>
                                {localStorage.getItem("navbar") === "navigation1" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Dashboard</a></li>
                                }
                                {localStorage.getItem("navbar") === "navigation2" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Breadcrumb2</a></li>
                                }
                                {localStorage.getItem("navbar") === "navigation3" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Breadcrumb3</a></li>
                                }
                                {localStorage.getItem("navbar") === "navigation4" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Breadcrumb4</a></li>
                                }
                                {localStorage.getItem("navbar") === "navigation5" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Breadcrumb5</a></li>
                                }
                            </ol>
                        </nav>
                    </div>
                    {/* PAGE HEADER & BREADCRUMBS END */}

                    {/* NAVIGATION BUTTON START */}
                    <div className="col-md-6">
                        <div className="flex-container pull-right text-center m-t-5" >

                            <div name="navigation1" id="1" className={localStorage.getItem("page") == "1" ? "activenav mapnav" : "inactivenav mapnav"}
                                onClick={() => {
                                    localStorage.setItem("page","1");
                                }}>
                                <a href="#/samplepage/">Dashboard</a>
                            </div>


                            <div name="navigation2" id="2" className={localStorage.getItem("page") == "2" ? "activenav mapnav" : "inactivenav mapnav"}
                                onClick={() => {
                                    localStorage.setItem("page","2");
                                }}
                            >
                                <a href="#/samplepage2/">List</a>
                            </div>


                        </div>
                    </div>
                    {/* NAVIGATION BUTTON END */}
                </div>
                {/* HEADER END */}
            </div>
        );
    }
}
export default Header;