import React, { Component } from 'react';
import { getData } from '../../api/api';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from "jquery";

class Matrix extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bgcolor1: "#404040",
            bgcolor2: " transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            navbar: "navigation1",
            searchDriver: "",
            salaries: [],
            matrix: [],
            id_sal: "",
            id_name: "",
            id_amount: "",
            id_code: ""
        };
        this.searchDriver = this.searchDriver.bind(this);
        this.submitSal = this.submitSal.bind(this);
        this.updateSal = this.updateSal.bind(this);
    }
    componentDidMount() {
        this.getSalaries()
    };
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    getSalaries() {
        getData("position/getAllSalary").then(res => {
            console.log(res)
            this.setState({ salaries: res })
        })
    }
    submitSal(e) {
        e.preventDefault();
        var data = { sal_name: this.state.sal_name, sal_amount: this.state.sal_amount, sal_code: this.state.sal_code }
        //    console.log(data)
        getData("position/addSalary", data).then(response => {
            console.log(response)

            if (response.response = 'success') {
                this.getSalaries();
                alert("Salary Added Successfully")
            } else {
                alert("Failed to Add Salary")

            }
            $("#closeModal").click();

        })
    }
    updateSal(e) {
        e.preventDefault();
        var data = {
            sal_id: this.state.id_sal,
            sal_name: this.state.id_name,
            sal_amount: this.state.id_amount,
            sal_code: this.state.id_code
        }
        console.log(data)
        getData("position/updateSalary", data).then(response => {
            console.log(response)
            if (response.res == "Success") {
                this.getSalaries();
                alert("Salary Updated Successfully")
            } else {
                alert("Failed to Update Salary")
            }
            $("#closeModal2").click();

        })
    }
    render() {
        let SalSearch = this.state.salaries.filter(
            (files) => {
                return files.sal_name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.sal_amount.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.sal_code.toLowerCase().indexOf(
                            this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (
            <div id="content" className="content">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i class="fas fa-coins"></i>&nbsp;Salary Matrix</h4>

                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Salary Matix</a></li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav" >
                                <div className="attendnav">
                                    <a href="#/department">Department</a>
                                </div>
                                <div className="attendnav">
                                    <a href="#/position">Position</a>
                                </div>
                                <div className="attendnav" >
                                    <a href="#/matrix">Salary&nbsp;Matrix</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-green-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;New&nbsp;Salary&nbsp;Option</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search" placeholder="Search Salary" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Scrollbars style={{ height: 350 }}>
                            <table className="table table-bordered">
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>Salary ID</th>
                                        <th>Salary Description</th>
                                        <th>Amount</th>
                                        <th>Salary Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SalSearch.map(data => {
                                        return <tr data-target="#demo-lg-modal-2" data-toggle="modal" onClick={() => {
                                            this.setState({
                                                id_sal: data.sal_id,
                                                id_name: data.sal_name,
                                                id_amount: data.sal_amount,
                                                id_code: data.sal_code
                                            })
                                        }}>
                                            <td>{data.sal_id}</td>
                                            <td>{data.sal_name}</td>
                                            <td>{data.sal_amount}</td>
                                            <td>{data.sal_code}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Create Salary</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.submitSal}>
                                    <fieldset>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Salary Description</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="sal_name" placeholder="Name" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Amount</label>
                                            <input type="number" className="form-control" onChange={this.textchange} name="sal_amount" placeholder="Amount" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Salary Code</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="sal_code" placeholder="Code" required />
                                        </div>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                            &nbsp;Create
                                    </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-2" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Salary</h4>
                                <button id="closeModal2" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.updateSal}>
                                    <fieldset>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Salary Description</label>
                                            <input value={this.state.id_name} type="text" className="form-control" onChange={this.textchange} name="id_name" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Amount</label>
                                            <input value={this.state.id_amount} type="number" className="form-control" onChange={this.textchange} name="id_amount" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Salary Code</label>
                                            <input value={this.state.id_code} type="text" className="form-control" onChange={this.textchange} name="id_code" />
                                        </div>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                            &nbsp;Update
                                    </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Matrix;