import React, { Component } from 'react';
import readXlsxFile from 'read-excel-file'
// import {getData} from "../../../api/api";
import { getData } from '../../api/api';
import swal from "sweetalert/dist/sweetalert.min.js";
class UploadEmployee extends Component {
    constructor(props){
        super(props);
        this.state = {
            excelData: [],
            totalCount: 0,
            branches: [],
            companies: [],
            company: "",
            branch_id: "",
        }
        this.convertExcel = this.convertExcel.bind(this);
        this.onChangeSelectCompany = this.onChangeSelectCompany.bind(this);
        this.onChangeSelectBranch = this.onChangeSelectBranch.bind(this);

    }
    convertExcel(e){
        readXlsxFile(e.target.files[0]).then((row) =>{
            let data = [];
            let data2 = []
            let count = row.length - 1;

           this.setState({totalCount: count});
           for(let i=1; i<row.length; i++){
                // data.push(row[i][2]);
                let dataString = String(row[i][0]);
                let splitData = dataString.split(",");
                data2.push({'user_id': row[i][0], 'user_jobposition': row[i][12]})
            //    data2.push({'user_pic': '', 'user_fname': splitData[1], 'user_mname': "", 'user_lname': splitData[0], 'user_suffix': row[i][3], 'user_bday': row[i][4],
            // 'user_nationality': row[i][24], 'user_civilstatus': row[i][36], 'user_gender': row[i][5], 'user_address': row[i][6], 'user_email': row[i][9], 'user_mobile': row[i][8],
            // 'company_id': this.state.company, 'user_device_id': "", 'user_status': row[i][15], 'user_load_balance': '', 'user_employment_status': row[i][14], 'user_jobposition': row[i][10],
            // 'user_console_access': 0, 'user_console_api': '', 'branch_id': this.state.branch_id, 'restriction_id': 1, 'team_id': 0, 'user_sname': '', 'salary_rate': 0, 'type_rate': '',
            // 'user_request_hierarchy': '', 'sched_id': 0, 'hierarchy_id': 0, 'comment_enable': 0, 'admin_id': 0, 'user_DAR_hierarchy': '', 'user_assign_area': 0, 'user_quota': 0, 'user_quota_type': '',
            // 'user_department': 0, 'user_track_enabled': 0, 'user_print_enable': 0, 'allowance': 0, 'user_leave': '', 'fixed_allowance': 0, 'sss': row[i][20], 'philHealth': row[i][21], 'pagIbig': row[i][22], 'tin': row[i][23],
            // })
           }
        
           this.setState({excelData: data2})
        })
     }

     uploadExcel(e){
        let data = this.state.excelData;
        
        console.log(data);

        getData('field_audit/uploadExcelEmployee',data).then(result =>{
            console.log(result);
        })

        // let save = {
        //     data: data,
        //     date: splitDate[0],
        //     branch: this.state.selectedBranch,
        //     app: 10
        // }

        // let get = {
        //     date: splitDate[0],
        //     branch: this.state.selectedBranch,
        //     app: 10
        // }
        // getData("field_audit/uploadExcelEmployee",data).then(result =>{
        //     if(result.res === "Success"){
        //         // getData("field_audit/updatedFieldman", this.state.selectedBranch).then(man =>{
        //         //     let data = [];
        //         //     for(let i =0; i<man.length; i++){
        //         //         data.push({'fieldman': man[i], 'data': []});
        //         //     }
        //         //     this.setState({fieldman: data});
        //         // })
        //         // getData("field_audit/getUploadedExcel", get).then(row => {
        //         //     let data = [];
        //         //     let x = this.state.fieldman;
      
        //         //     for(let i=0; i<row.length; i++){
        //         //         if(row[i].status === "Pending"){
        //         //             data.push(row[i]);
        //         //         }else{
        //         //             for(let b=0; b<x.length; b++){
        //         //                 if(x[b].fieldman.user_id === row[i].fieldman){
        //         //                     x[b].data.push(row[i]);
        //         //                 }
        //         //             }
        //         //         }
        //         //     }
        //         //    this.setState({fieldman: x});

        //         //   this.setState({uploadData: data});
        //         //   swal("Successfully Uploaded File!", "", "success");
        //         // })
                
        //     }else{
             
        //     }
        // })  
    }

    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                this.setState({ userData: response.users, companies: response.companies, req_type: response.request_type, departments: response.departments, hierarchy_structures: response.hierarchy_structures })
            })
    }
    onChangeSelectCompany(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ company: options[i].value })
            }
        }
        getData("users/getBranch", company)
            .then(result => {
                this.setState({ branches: result })
            });

    }
    onChangeSelectBranch(e) {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ branch_id: options[i].value })
            }
        }
    }

    componentDidMount(){
        this.getUser();
    }
    render() { 

        return ( 
            <div id="content" className="content">
                  {/* <div className="col-sm-12 m-b-10">
                        <label for="exampleZip" style={{ color: "#130f40" }}>Company*</label>
                        <select className="form-control" onChange={this.onChangeSelectCompany} required>
                            <option value="">--select company--</option>
                            {this.state.companies.map((val, index) =>
                                <option value={val.company_id}>{val.company_name}</option>
                            )}
                        </select>
                    </div>
                    <select className="form-control" onChange={this.onChangeSelectBranch}>
                        <option value="">--select branch--</option>
                        {this.state.branches.map((val, index) =>
                            <option value={val.bid}>{val.bname}</option>
                        )}
                    </select> */}

                <div className="form-group m-r-10" >
                    <input type="file" className="form-control" id="input" onChange={this.convertExcel} style={{ borderStyle: 'none' }} />
                </div>
                <div className="form-group m-r-10" >
                    <button className="btn btn-blocked bg-green-lighter text-white" onClick={this.uploadExcel.bind(this)}>Submit</button>
                </div>

                <div className="panel">
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{ minHeight: 170, maxHeight: 550, overflow: 'auto' }}>
                                        <table className="table-hover">
                                            <thead className="tableFixHead table table-condensed text-center">
                                                <tr>
                                                    <th className="bg-light" style={{ padding: 15 }}>JO#</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Type</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>SSN</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Customer Name</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table table-bordered">
                                                {/* {filterUpload.map((data, i) =>
                                                    <tr onClick={() => this.getExcelRow(data)}>
                                                        <td>{data.jo}</td>
                                                        <td>{data.type}</td>
                                                        <td>{data.ssn}</td>
                                                        <td>{data.name}</td>
                                                        <td>{data.address}</td>
                                                    </tr>
                                                )} */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
            </div>
         );
    }
}
 
export default UploadEmployee;