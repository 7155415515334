import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";

class JobForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterStartDate: new Date(),
            dependents: [],
            siblings: [],
            refresh: false,
            firstrow:false,
            secondrow: true,
            thirdrow: false,
            fourthrow: false,
            fifthrow: false,
            sixthrow: false,
            tab1: "1",
            tab2: "2",
            tab3: "3",
            tab4: "4",
            tab5: "5",
            tab6: "6",
            tab7: "7",
            tabcolor1: "#2ecc71",
            tabcolor2: "",
            tabcolor3: "",
            tabcolor4: "",
            tabcolor5: "",
            tabcolor6: "",
            tabcolor7: "",
            breadcrumb: "",
            position: "",
            datehired: "",
            salary: "",
            Fname: "",
            Mname: "",
            Lname: "",
            gender: "",
            EmpAge: "",
            Empbday: "",
            Eheight: "",
            Eweight: "",
            mstatus: "",
            nationality: "",
            sss: "",
            pagibig: "",
            philhealth: "",
            Emptin: "",
            email: "",
            Mnumber: "",
            Pnumber: "",
            presentAdd: "",
            permaAdd: "",
            Sname: "",
            Snumber: "",
            Stin: "",
            Soccupation: "",
            Saddress: "",
            Cname: "",
            Cnumber: "",
            Crelation: "",
            Dname: "",
            Dbday: "",
            Dage: "",
            Father: "",
            Mother: "",
            Foccupation: "",
            Moccupation: "",
            Fage: "",
            Mage: "",
            Sibname: "",
            Sibbday: "",
            Sibnoccupation: "",
            HSname: "",
            HScourse: "",
            Hsyear: "",
            Vocname: "",
            Voccourse: "",
            Vocyear: "",
            Colname: "",
            Colcourse: "",
            Colyear: "",
            Postname: "",
            Postcourse: "",
            Postyear: "",
            check: "",
            position_href: "javascript:;",
            // tabClass1: "0",
            // tabClass2: "1",
            // tabClass3: "0",
            // tabClass4: "0",
            // tabClass5: "0",
            // tabClass6: "0",
            // tabClass7: "0",
            // tabs: "disable"
        }
        this.textchange = this.textchange.bind(this);
        this.onChangeSelectGender = this.onChangeSelectGender.bind(this);
        this.onChangeSelectMaritalStatus = this.onChangeSelectMaritalStatus.bind(this)

    }

    filterDateStart = e => {
        // console.log(e)
        this.setState({
            filterStartDate: e
        });
    }
    appendsiblings() {
        this.state.siblings.push(<form className="col-md-12"><div className="form-group row m-b-15"><label className="col-form-label col-md-1">Sibling Name</label><div className="col-md-3"><input type="text" className="form-control m-b-5" placeholder="Full Name" /></div><label className="col-form-label col-md-1">Birthdate </label><div className="col-md-3"><input type="date" className="form-control m-b-5" placeholder="Birthday" /></div><label className="col-form-label col-md-1">Occupation </label><div className="col-md-3"><input type="number" className="form-control m-b-5" placeholder="Occupation" /></div></div></form>)
        this.setState({ refresh: true })
    }
    appenddependent() {
        this.state.dependents.push(<div className="form-group row m-b-15"><label className="col-form-label col-md-1">Dependent</label><div className="col-md-3"><input type="text" className="form-control m-b-5" placeholder="Full Name" /></div><label className="col-form-label col-md-1">Birthdate </label><div className="col-md-3"><input type="date" className="form-control m-b-5" placeholder="Birthday" /></div><label className="col-form-label col-md-1">Age </label><div className="col-md-3"><input type="number" className="form-control m-b-5" placeholder="Age" /></div></div>)

        this.setState({ refresh: true })


    }

    AddSiblings() {
        console.log(this.state.siblings);
        // return this.state.append

        var array = []
        for (let index = 0; index < this.state.siblings.length; index++) {
            array.push(this.state.siblings[index])
        }
        return array
    }
    AddDependent() {
        console.log(this.state.dependents);
        // return this.state.append

        var array = []
        for (let index = 0; index < this.state.dependents.length; index++) {
            array.push(this.state.dependents[index])
        }
        return array

    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (
            this.state.position != "" &&
            this.state.datehired != "" &&
            this.state.salary != ""
        ) {
            this.setState({
                position_href: "#personal",
            })
        }
        if (
            this.state.Fname != "" &&
            this.state.Mname != "" &&
            this.state.Lname != "" &&
            this.state.gender != "" &&
            this.state.EmpAge != "" &&
            this.state.Empbday != "" &&
            this.state.Eheight != "" &&
            this.state.Eweight != "" &&
            this.state.mstatus != "" &&
            this.state.nationality != "" &&
            this.state.sss != "" &&
            this.state.pagibig != "" &&
            this.state.philhealth != "" &&
            this.state.Emptin != ""
        ) {
            this.setState({
                position_href: "#contact",
            })
        }
        if (
            this.state.email != "" &&
            this.state.Pnumber != "" &&
            this.state.Mnumber != "" &&
            this.state.presentAdd != "" &&
            this.state.permaAdd != ""
        ) {
            this.setState({
                position_href: "#other",
            })
        }
        if (
            this.state.Sname != "" &&
            this.state.Snumber != "" &&
            this.state.Stin != "" &&
            this.state.Soccupation != "" &&
            this.state.Saddress != "" &&
            this.state.Cname != "" &&
            this.state.Cnumber != "" &&
            this.state.Crelation != ""

        ) {
            this.setState({
                position_href: "#dependents",
            })
        }
        if (
            this.state.Dname != "" &&
            this.state.Dbday != "" &&
            this.state.Dage != ""


        ) {
            this.setState({
                position_href: "#family",
            })
        }
        if (
            this.state.Father != "" &&
            this.state.Foccupation != "" &&
            this.state.Fage != "" &&
            this.state.Mother != "" &&
            this.state.Moccupation != "" &&
            this.state.Mage != "" &&
            this.state.Sibname != "" &&
            this.state.Sibbday != "" &&
            this.state.Sibnoccupation != ""

        ) {
            this.setState({
                position_href: "#educ",
            })
        }
    }
    onChangeSelectGender(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ gender: options[i].value })
            }
        }
    }

    onChangeSelectMaritalStatus(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ mstatus: options[i].value })
            }
        }


    }

    render() {

        return (
            <div id="content" className="content">
                <div className="row text-center" style={{marginTop: "-20px"}}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{padding: 10}}>
                    <a href="javascript:;">
                        <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label style={{ color: this.state.Color2 }}>Nav item1</label>
                    </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{padding: 10}}>
                    <a href="javascript:;">
                        <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label style={{ color: this.state.Color2 }}>Nav item2</label>
                    </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{padding: 10}}>
                    <a href="javascript:;">
                        <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label style={{ color: this.state.Color2 }}>Nav item3</label>
                    </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{padding: 10}}>

                    <a href="javascript:;">
                        <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label style={{ color: this.state.Color2 }}>Nav item4</label>
                    </a>

                    </div>
                </div>
                <br />
                <div className="pull-left">
                    <h4>Employee Information Form</h4>
                </div>
                <nav aria-label="breadcrumb" className="pull-right">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item "><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Route:</a></li>
                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Form</a></li>
                        <li className="breadcrumb-item active"><a href="JAVASCRIPT:;">Employee Form: {this.state.position_href}</a></li>
                    </ol>
                </nav>
                <br />
                <br />
                <br />
                <ul className="nav nav-tabs ">
                    <li className="nav-item">
                        <a style={{ backgroundColor: this.state.tabcolor1, textDecoration: "none", }} onClick={() => {
                            this.setState({
                                tabClass1: 0, 
                                tabcolor1: "#2ecc71",
                                tabcolor2: "#ecf0f1",
                                tabcolor3: "#ecf0f1",
                                tabcolor4: "#ecf0f1",
                                tabcolor5: "#ecf0f1",
                                tabcolor6: "#ecf0f1",
                                tabcolor7: "#ecf0f1",
                                firstrow:true
                            })
                        }} href="#PEI" data-toggle="tab" className="nav-link">
                            <span className="d-sm-none">Tab 1</span>
                            <span className="d-sm-block d-none" >
                                {this.state.tab1 != 1 &&
                                    <i className={this.state.tab1}> </i>
                                }
                                {this.state.tab1 == 1 &&
                                    this.state.tab1
                                }

                                &nbsp;Employee Info.</span>
                        </a>
                    </li>
                    <li className="nav-item disable">
                        <a style={{ backgroundColor: this.state.tabcolor2 }} onClick={() => {
                            this.setState({
                                tabClass2: 0, 
                                tabcolor1: "#ecf0f1",
                                tabcolor2: "#2ecc71",
                                tabcolor3: "#ecf0f1",
                                tabcolor4: "#ecf0f1",
                                tabcolor5: "#ecf0f1",
                                tabcolor6: "#ecf0f1",
                                tabcolor7: "#ecf0f1",
                                secondrow: false

                            })
                        }} href="#personal" data-toggle="tab" className="nav-link deactive">
                            <span className="d-sm-none">Tab 2</span>
                            <span className="d-sm-block d-none">
                                {this.state.tab2 != 2 &&
                                    <i className={this.state.tab2}> </i>
                                }
                                {this.state.tab2 == 2 &&
                                    this.state.tab2
                                }
                                &nbsp;Personal Info.</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a style={{ backgroundColor: this.state.tabcolor3 }} className="nav-link disable">
                            <span className="d-sm-none">Tab 3</span>
                            <span className="d-sm-block d-none">
                                {this.state.tab3 != 3 &&
                                    <i className={this.state.tab3}> </i>
                                }
                                {this.state.tab3 == 3 &&
                                    this.state.tab3
                                }
                                &nbsp;Personal Contact Info.</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a style={{ backgroundColor: this.state.tabcolor4 }} className="nav-link disable">
                            <span className="d-sm-none">Tab 4</span>
                            <span className="d-sm-block d-none">
                                {this.state.tab4 != 4 &&
                                    <i className={this.state.tab4}> </i>
                                }
                                {this.state.tab4 == 4 &&
                                    this.state.tab4
                                }
                                &nbsp;Other Info.</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a style={{ backgroundColor: this.state.tabcolor5 }} className="nav-link disable">
                            <span className="d-sm-none">Tab 5</span>
                            <span className="d-sm-block d-none">
                                {this.state.tab5 != 5 &&
                                    <i className={this.state.tab5}> </i>
                                }
                                {this.state.tab5 == 5 &&
                                    this.state.tab5
                                }
                                &nbsp;Add Dependent/s</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a style={{ backgroundColor: this.state.tabcolor6 }} className="nav-link disable">
                            <span className="d-sm-none">Tab 6</span>
                            <span className="d-sm-block d-none">
                                {this.state.tab6 != 6 &&
                                    <i className={this.state.tab6}> </i>
                                }
                                {this.state.tab6 == 6 &&
                                    this.state.tab6
                                }
                                &nbsp;Family Background</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a style={{ backgroundColor: this.state.tabcolor7 }} className="nav-link disable">
                            <span className="d-sm-none">Tab 7</span>
                            <span className="d-sm-block d-none">
                                {this.state.tab7 != 7 &&
                                    <i className={this.state.tab7}> </i>
                                }
                                {this.state.tab7 == 7 &&
                                    this.state.tab7
                                }
                                &nbsp;Educational Background</span>
                        </a>
                    </li>
                </ul>
                {/* ====================================================================================== */}
                {/* ====================================================================================== */}
                {/* ====================================================================================== */}
                {/* ====================================================================================== */}
                <div className="tab-content">
                    {this.state.tabClass1 == 0 &&
                        <div id="PEI" >
                            <form>
                           
                                <div className="row">

                                    <div className="col-md-6">

                                        <div className="panel panel-inverse" data-sortable-id="form-stuff-1">

                                            <div className="panel-body">

                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Position</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="position" className="form-control m-b-5" placeholder="Company Position" value={this.state.position} required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-labe col-md-3">Date Hired:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="date" name="datehired" className="form-control m-b-5" value={this.state.datehired} placeholder="Select Date" required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Salary</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="salary" className="form-control m-b-5 salary" value={this.state.salary} data-parsley-type="digits" required placeholder="salary rate" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <center> <img src="../assets/img/user/user.jpg" alt="Avatar" style={{ borderRadius: "50%", width: "150px", color: "green" }} /></center>
                                        <center>
                                            {/* <button className="bttnadd" type="submit">
                            <span className="fa fa-plus fa-sm"></span>
                        </button> */}
                                        </center>
                                    </div>

                                </div>
                            
                                
                                <div className="row">
                                    <div className="col-md-6">

                                    </div>
                                    <div className="col-md-6">

                                        <a className="bttnnxt pull-right" type="submit" type="button" href={this.state.position_href} data-toggle="tab"
                                            onClick={() => {
                                                alert(this.state.position_href + "," + this.state.position + "," + this.state.datehired + "," + this.state.salary)
                                                if (this.state.position != "" && this.state.datehired != "" && this.state.salary != "") {
                                                    this.setState({
                                                        tab1: "fa fa-check-circle fa-md",
                                                        // tab2:"",
                                                        // tab3:"",
                                                        // tab4:"",
                                                        // tab5:"",
                                                        // tab6:"",
                                                        // tab7:"",
                                                        tabcolor1: "#ecf0f1",
                                                        tabcolor2: "#2ecc71",
                                                        tabcolor3: "#ecf0f1",
                                                        tabcolor4: "#ecf0f1",
                                                        tabcolor5: "#ecf0f1",
                                                        tabcolor6: "#ecf0f1",
                                                        tabcolor7: "#ecf0f1",
                                                        tabClass1: "1",
                                                        // position_href:"#PEI"
                                                        tabClass2: "0",
                                                        firstrow: false
                                                    }
                                                    )
                                                }

                                            }

                                            } >
                                            <span className="fa fa-arrow-circle-right" ></span>
                                        </a>


                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {this.state.tabClass2 == 0 &&
                        <div id="personal" className="tab-pane fade">
                             {this.state.firstrow === false &&
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="panel panel-inverse" data-sortable-id="form-stuff-2">
                                            <div className="panel-body">

                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">First Name:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Fname" className="form-control m-b-5" placeholder="First Name" required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Middle Name:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Mname" className="form-control m-b-5" placeholder="Middle Name" required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Last Name:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Lname" className="form-control m-b-5" placeholder="Last Name" required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Sex:</label>

                                                    <div className="col-md-9">
                                                        <select value={this.state.gender} name="gender" className="form-control selectpicker m-b-5" onChange={this.onChangeSelectGender} required>
                                                            <option selected>-Select Option-</option>
                                                            <option name="male">Male</option>
                                                            <option name="female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Age:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="EmpAge" className="form-control m-b-5" placeholder="Age" required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-labe col-md-3">Birthday:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="date" name="Empbday" className="form-control m-b-5" id="datepicker-default" placeholder="Select Date" required />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Height:</label>
                                                    <div className="col-md-3">
                                                        <input onChange={this.textchange} type="number" name="Eheight" className="form-control m-b-5" placeholder="heigth" data-parsley-required="true" />
                                                    </div>
                                                    <label className="col-form-label col-md-3">Weight:</label>
                                                    <div className="col-md-3">
                                                        <input onChange={this.textchange} type="number" name="Eweight" className="form-control m-b-5" placeholder="weight" data-parsley-required="true" />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="panel panel-inverse" data-sortable-id="form-stuff-4">

                                            <div className="panel-body">
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Marital Status:</label>

                                                    <div className="col-md-9">
                                                        <select value={this.state.mstatus} name="mstatus" className="form-control selectpicker m-b-5" onChange={this.onChangeSelectMaritalStatus} required>
                                                            <option selected>-Select Status-</option>
                                                            <option name="married">Married </option>
                                                            <option name="widowed">Widowed </option>
                                                            <option name="seperated">Separated </option>
                                                            <option name="divorced">Divorced </option>
                                                            <option name="single">Single </option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Nationality:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="nationality" className="form-control m-b-5" required placeholder="Nationality" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">SSS No.</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="sss" className="form-control m-b-5" required placeholder="ID No." />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">PAG-IBIG No.</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="pagibig" className="form-control m-b-5" required placeholder="ID No." />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">PHILHEALTH No.</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="philhealth" className="form-control m-b-5" required placeholder="ID No." />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">TIN No.</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="Emptin" className="form-control m-b-5" required placeholder="ID No." />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">

                                    </div>
                                    <div className="col-md-6">
                                        <a className="bttnnxt pull-right" type="submit" type="button" href={this.state.position_href} data-toggle="tab"
                                            onClick={() => {
                                                alert(this.state.position_href + "," + this.state.Fname + "," + this.state.Mname + "," + this.state.Lname + "," + this.state.gender + "," + this.state.EmpAge + "," + this.state.Empbday + "," + this.state.Eheight + "," + this.state.Eweight + "," + this.state.mstatus + "," + this.state.nationality + "," + this.state.sss + "," + this.state.pagibig + "," + this.state.philhealth + "," + this.state.Emptin)
                                                if (this.state.Fname != "" &&
                                                    this.state.Mname != "" &&
                                                    this.state.Lname != "" &&
                                                    this.state.gender != "" &&
                                                    this.state.EmpAge != "" &&
                                                    this.state.Empbday != "" &&
                                                    this.state.Eheight != "" &&
                                                    this.state.Eweight != "" &&
                                                    this.state.mstatus != "" &&
                                                    this.state.nationality != "" &&
                                                    this.state.sss != "" &&
                                                    this.state.pagibig != "" &&
                                                    this.state.philhealth != "" &&
                                                    this.state.Emptin != "") {
                                                    this.setState({
                                                        tab1: "fa fa-check-circle fa-md",
                                                        tab2: "fa fa-check-circle fa-md",
                                                        // tab3:"",
                                                        // tab4:"",
                                                        // tab5:"",
                                                        // tab6:"",
                                                        // tab7:"",
                                                        tabcolor1: "#ecf0f1",
                                                        tabcolor2: "#ecf0f1",
                                                        tabcolor3: "#2ecc71",
                                                        tabcolor4: "#ecf0f1",
                                                        tabcolor5: "#ecf0f1",
                                                        tabcolor6: "#ecf0f1",
                                                        tabcolor7: "#ecf0f1",
                                                        tabClass2: 1,
                                                        secondrow: false

                                                    }
                                                    )
                                                }
                                            }
                                            }>
                                            <span className="fa fa-arrow-circle-right"></span>
                                        </a>

                                    </div>

                                </div>
                            </form>
                             }
                        </div>
                    }

                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {this.setState.tabClass3 == 0 &&
                    <div id="contact" className="tab-pane fade">
                              {this.state.secondrow === false &&
                                <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="panel panel-inverse" data-sortable-id="form-stuff-4">
                                    <div className="panel-body">

                                        <div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Email:</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="email" name="email" className="form-control m-b-5" placeholder="example@email.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Mobile No</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="number" name="Mnumber" className="form-control m-b-5" id="masked-input-phone" placeholder="09XX-XXX-XXXX" />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Phone No.</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="number" name="Pnumber" className="form-control m-b-5" placeholder="XXX-XXXX" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="panel panel-inverse" data-sortable-id="form-stuff-4">
                                    <div className="panel-body">

                                        <div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Present Address</label>
                                                <div className="col-md-9">
                                                    <textarea onChange={this.textchange} type="text" name="presentAdd" className="form-control" rows="3" placeholder="Address"></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Permanent Address</label>
                                                <div className="col-md-9">
                                                    <textarea onChange={this.textchange} type="text" name="permaAdd" className="form-control" rows="3" placeholder="Address"></textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6">
                                <a className="bttnnxt pull-right" type="submit" type="button" href={this.state.position_href} data-toggle="tab"
                                    onClick={() => {
                                        alert(this.state.position_href + "," + this.state.email + "," + this.state.Mnumber + "," + this.state.Pnumber + "," + this.state.presentAdd + "," + this.state.permaAdd)
                                        if (this.state.email != "" &&
                                            this.state.Pnumber != "" &&
                                            this.state.Mnumber != "" &&
                                            this.state.presentAdd != "" &&
                                            this.state.permaAdd != ""
                                        ) {
                                            this.setState({
                                                tab1: "fa fa-check-circle fa-md",
                                                tab2: "fa fa-check-circle fa-md",
                                                tab3: "fa fa-check-circle fa-md",
                                                // tab4:"",
                                                // tab5:"",
                                                // tab6:"",
                                                // tab7:"",
                                                tabcolor1: "#ecf0f1",
                                                tabcolor2: "#ecf0f1",
                                                tabcolor3: "#ecf0f1",
                                                tabcolor4: "#2ecc71",
                                                tabcolor5: "#ecf0f1",
                                                tabcolor6: "#ecf0f1",
                                                tabcolor7: "#ecf0f1",
                                            }
                                            )
                                        }
                                    }
                                    }>
                                    <span className="fa fa-arrow-circle-right"></span>
                                </a>
                            </div>

                        </div>
                    </form>
                             }
                    
                </div>
                    }
                    
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {this.setState.tabClass3 == 0 &&
                        <div id="other" className="tab-pane fade in active">
                        <form>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="panel">

                                        <div className="panel-body row">
                                            <div className="col-md-6">
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Spouse's Name: </label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="email" name="Sname" className="form-control m-b-5" placeholder="Spouse's Name" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Contact No. :</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="Snumber" className="form-control m-b-5" placeholder="Spouse's number" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Tin No. :</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="Stin" className="form-control m-b-5" placeholder="Spouse's TIN no." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Occupation:</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Soccupation" className="form-control m-b-5" placeholder="Spouse's Occupation" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Address:</label>
                                                    <div className="col-md-9">
                                                        <textarea onChange={this.textchange} type="text" name="Saddress" className="form-control m-b-5" rows="5"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="panel-body row">
                                            <label>Contact Person</label>
                                            <div className="col-md-12">
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-1">Name</label>
                                                    <div className="col-md-3">
                                                        <input onChange={this.textchange} type="text" name="Cname" className="form-control m-b-5" placeholder="Full Name" />
                                                    </div>
                                                    <label className="col-form-label col-md-1">Contact: </label>
                                                    <div className="col-md-3">
                                                        <input onChange={this.textchange} type="number" name="Cnumber" className="form-control m-b-5" placeholder="number" />
                                                    </div>
                                                    <label className="col-form-label col-md-1">Relationship </label>
                                                    <div className="col-md-3">
                                                        <input onChange={this.textchange} type="text" name="Crelation" className="form-control m-b-5" placeholder="relation" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">

                                </div>
                                <div className="col-md-6">
                                    <a className="bttnnxt pull-right" type="submit" type="button" href={this.state.position_href} data-toggle="tab"
                                        onClick={() => {
                                            alert(this.state.position_href + "," + this.state.Sname + "," + this.state.Snumber + "," + this.state.Soccupation + "," + this.state.Stin + "," + this.state.Saddress + "," + this.state.Cname + "," + this.state.Cnumber + "," + this.state.Crelation)
                                            if (this.state.Sname != "" &&
                                                this.state.Snumber != "" &&
                                                this.state.Soccupation != "" &&
                                                this.state.Stin != "" &&
                                                this.state.Saddress != "" &&
                                                this.state.Cname != "" &&
                                                this.state.Cnumber != "" &&
                                                this.state.Crelation != ""

                                            ) {
                                                this.setState({
                                                    tab1: "fa fa-check-circle fa-md",
                                                    tab2: "fa fa-check-circle fa-md",
                                                    tab3: "fa fa-check-circle fa-md",
                                                    tab4: "fa fa-check-circle fa-md",
                                                    // tab5:"",
                                                    // tab6:"",
                                                    // tab7:"",
                                                    tabcolor1: "#ecf0f1",
                                                    tabcolor2: "#ecf0f1",
                                                    tabcolor3: "#ecf0f1",
                                                    tabcolor4: "#ecf0f1",
                                                    tabcolor4: "#ecf0f1",
                                                    tabcolor5: "#2ecc71",
                                                    tabcolor6: "#ecf0f1",
                                                    tabcolor7: "#ecf0f1",
                                                })
                                            }
                                        }
                                        }>
                                        <span className="fa fa-arrow-circle-right"></span>
                                    </a>

                                </div>

                            </div>
                        </form>
                    </div>
                    }                    
                    
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {this.setState.tabClass3 == 0 &&
                    <div id="dependents" className="tab-pane fade in active">
                    <form>
                        <div className="row">
                            <div className="col-xl-12">

                                <div className="panel panel-inverse" data-sortable-id="form-stuff-1">

                                    <div className="panel-body">
                                        <div className="panel">

                                            <div className="panel-body row">

                                                <div className="col-md-12">
                                                    <div className="form-group row m-b-15">
                                                        <label className="col-form-label col-md-1">Dependent</label>
                                                        <div className="col-md-3">
                                                            <input onChange={this.textchange} type="text" name="Dname" className="form-control m-b-5" placeholder="Full Name" />
                                                        </div>
                                                        <label className="col-form-label col-md-1">Birthdate </label>
                                                        <div className="col-md-3">
                                                            <input onChange={this.textchange} type="date" name="Dbday" className="form-control m-b-5" placeholder="Birthday" />
                                                        </div>
                                                        <label className="col-form-label col-md-1">Age </label>
                                                        <div className="col-md-3">
                                                            <input onChange={this.textchange} type="number" name="Dage" className="form-control m-b-5" placeholder="Age" />
                                                        </div>
                                                    </div>
                                                    {this.AddDependent()}

                                                </div>

                                            </div>


                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <button onClick={() => this.appenddependent()} className="bttndependent pull-left">
                                    <span className="fa fa-plus fa-sm"></span>
                                </button>
                            </div>
                            <div className="col-md-6">
                                <a className="bttnnxt pull-right" type="submit" type="button" href={this.state.position_href} data-toggle="tab"
                                    onClick={() => {
                                        alert(this.state.position_href + "," + this.state.Dname + "," + this.state.Dbday + "," + this.state.Dage)
                                        if (this.state.Dname != "" &&
                                            this.state.Dbday != "" &&
                                            this.state.Dage != ""

                                        ) {
                                            this.setState({
                                                tab1: "fa fa-check-circle fa-md",
                                                tab2: "fa fa-check-circle fa-md",
                                                tab3: "fa fa-check-circle fa-md",
                                                tab4: "fa fa-check-circle fa-md",
                                                tab5: "fa fa-check-circle fa-md",
                                                // tab6:"",
                                                // tab7:"",
                                                tabcolor1: "#ecf0f1",
                                                tabcolor2: "#ecf0f1",
                                                tabcolor3: "#ecf0f1",
                                                tabcolor4: "#ecf0f1",
                                                tabcolor4: "#ecf0f1",
                                                tabcolor5: "#ecf0f1",
                                                tabcolor6: "#2ecc71",
                                                tabcolor7: "#ecf0f1",
                                            })
                                        }
                                    }
                                    }>
                                    <span className="fa fa-arrow-circle-right"></span>
                                </a>

                            </div>

                        </div>
                    </form>
                </div>
                    }                    
                    
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {this.setState.tabClass3 == 0 &&                    
                    <div id="family" className="tab-pane fade in active">
                    <form>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="panel">

                                    <div className="panel-body row">
                                        <div className="col-md-6">
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Father's Name: </label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="Father" className="form-control m-b-5" placeholder="Father's Name" />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Occupation:</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="Foccupation" className="form-control m-b-5" placeholder="Father's Occupation" />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Age:</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="number" name="Fage" className="form-control m-b-5" placeholder="Father's Age" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Mother's Name: </label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="Mother" className="form-control m-b-5" placeholder="Mother's Name" />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Occupation:</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="Moccupation" className="form-control m-b-5" placeholder="Mother's Occupation" />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Age:</label>
                                                <div className="col-md-9">
                                                    <input onChange={this.textchange} type="number" name="Mage" className="form-control m-b-5" placeholder="Mother's Age" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="panel-body row">
                                        <label>Siblings</label>
                                        <div className="col-md-12">
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-1">Sibling Name</label>
                                                <div className="col-md-3">
                                                    <input onChange={this.textchange} type="text" name="Sibname" className="form-control m-b-5" placeholder="Full Name" />
                                                </div>
                                                <label className="col-form-label col-md-1">Birthdate </label>
                                                <div className="col-md-3">
                                                    <input onChange={this.textchange} type="date" name="Sibbday" className="form-control m-b-5" placeholder="Birthday" />
                                                </div>
                                                <label className="col-form-label col-md-1">Occupation </label>
                                                <div className="col-md-3">
                                                    <input onChange={this.textchange} type="text" name="Sibnoccupation" className="form-control m-b-5" placeholder="Occupation" />
                                                </div>
                                            </div>
                                            {this.AddSiblings()}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <button onClick={() => this.appendsiblings()} className="bttnsiblings pull-left">
                                    <span className="fa fa-plus fa-sm"></span>
                                </button>
                            </div>
                            <div className="col-md-6">
                                <a className="bttnnxt pull-right" type="submit" type="button" href={this.state.position_href} data-toggle="tab"
                                    onClick={() => {
                                        alert(this.state.position_href + "," + this.state.Father + "," + this.state.Foccupation + "," + this.state.Fage + "," + this.state.Mother + "," + this.state.Moccupation + "," + this.state.Mage + "," + this.state.Sibname + "," + this.state.Sibbday + "," + this.state.Sibnoccupation)
                                        if (this.state.Father != "" &&
                                            this.state.Foccupation != "" &&
                                            this.state.Fage != "" &&
                                            this.state.Mother != "" &&
                                            this.state.Moccupation != "" &&
                                            this.state.Mage != "" &&
                                            this.state.Sibname != "" &&
                                            this.state.Sibbday != "" &&
                                            this.state.Sibnoccupation != ""

                                        ) {
                                            this.setState({
                                                tab1: "fa fa-check-circle fa-md",
                                                tab2: "fa fa-check-circle fa-md",
                                                tab3: "fa fa-check-circle fa-md",
                                                tab4: "fa fa-check-circle fa-md",
                                                tab5: "fa fa-check-circle fa-md",
                                                tab6: "fa fa-check-circle fa-md",
                                                // tab7:"",
                                                tabcolor1: "#ecf0f1",
                                                tabcolor2: "#ecf0f1",
                                                tabcolor3: "#ecf0f1",
                                                tabcolor4: "#ecf0f1",
                                                tabcolor4: "#ecf0f1",
                                                tabcolor5: "#ecf0f1",
                                                tabcolor6: "#ecf0f1",
                                                tabcolor7: "#2ecc71",
                                            })
                                        }
                                    }
                                    }>
                                    <span className="fa fa-arrow-circle-right"></span>
                                </a>

                            </div>

                        </div>
                    </form>
                </div>
                    }
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {this.setState.tabClass1 == 0 &&
                    <div id="educ" className="tab-pane fade in active">
                        <form>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="panel">

                                        <div className="panel-body row">
                                            <div className="col-md-6">
                                                <center><label>High School</label></center>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Name of School/College</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="HSname" className="form-control m-b-5" placeholder="School Name" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Course</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="HScourse" className="form-control m-b-5" placeholder="Course Title" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Year Graduated</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="HSyear" className="form-control m-b-5" placeholder="YYYY" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <center><label>Vocational</label></center>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Name of School/College</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Vocname" className="form-control m-b-5" placeholder="School Name" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Course</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Voccourse" className="form-control m-b-5" placeholder="Course Title" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Year Graduated</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="Vocyear" className="form-control m-b-5" placeholder="YYYY" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body row">

                                            <div className="col-md-6">
                                                <center><label>College</label></center>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Name of School/College</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Colname" className="form-control m-b-5" placeholder="School Name" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Course</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Colcourse" className="form-control m-b-5" placeholder="Course Title" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Year Graduated</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="Colyear" className="form-control m-b-5" placeholder="YYYY" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <center><label>Post Graduate</label></center>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Name of School/College</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Postname" className="form-control m-b-5" placeholder="School Name" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Course</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="text" name="Postcourse" className="form-control m-b-5" placeholder="Course Title" />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Year Graduated</label>
                                                    <div className="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="Postyear" className="form-control m-b-5" placeholder="YYYY" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">

                                </div>
                                <div className="col-md-6">
                                    <button className="bttnnxt pull-right ">
                                        <span className="fa fa-arrow-circle-right"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    }
                    
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                    {/* ====================================================================================== */}
                </div>





















            </div>
        );
    }
}

export default JobForm;