import React, { Component } from 'react';
import {Zipdownload} from "../../../api/api";


class Downloadzip extends Component {
    constructor(props){
        super(props);
        this.state={
            from: new Date(),
            to: new Date(),
        }
    }
    onChangeDate = (event) => {
        this.setState({ from: event.target.value });
    }
    onChangeDate2 = (event) => {
        this.setState({ to: event.target.value });
    }

    onFormSubmit(e){
        e.preventDefault();
        this.setState({loading:false})
            var from = this.state.from;
            // console.log(from);
            var to = this.state.to
            Zipdownload("Field_audit/Zipdown",{from:from,to:to})
            .then(response=>{
                console.log(response);
            
            this.setState({loading:true});
            })
    }



    render() { 
        return ( 
           <div className="boxed"> {/* start boxed*/}
                    <div id="content-container">{/* start content-container*/}
                        <div id="page-head">

                            <div id="page-title">
                                <h1 className="page-header text-overflow">UPLOAD DOWNLOAD FILE</h1>
                            </div>
                        </div>

                        <div id="page-content">{/*start page-content*/}
                            <div className="row">{/*start row*/}
                            <div className="col-md-4">{/*col-md-4*/}
                            </div>
                                <div className="col-md-4">{/*col-md-4*/}
                                    <div className="panel">
                                    <form onSubmit={this.onFormSubmit}>
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Please Select File</h3>
                                        </div>
                                        <div className="panel-body">    
                                            <input type="date" className="form-control" name="from" dateFormat="YYYY-MM-DD" onChange={this.onChangeDate}
                                             required />
                                            <hr></hr>
                                            <input type="date" className="form-control" name="to" dateFormat="YYYY-MM-DD" onChange={this.onChangeDate2}
                                             required />

                                            <br></br>
                                            {/* <button className="btn btn-block btn-info" type="submit">SUBMIT</button> */}
                                            <a href={"http://api.pacificweb.com.ph/field_audit/Zipdown/"+this.state.from+"/"+this.state.to}>HEHEHE</a>
                                        </div>
                                        </form>
                                    </div>
                                    

                                </div>{/*end col-md3*/}

                            </div>{/*end row*/}
                        </div>{/*end page-content*/}
                        {/*</div>*/} {/* end content-container*/}


                    </div> {/* end boxed*/}

                    </div>
         );
    }
}
 
export default Downloadzip;