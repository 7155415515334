import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import 'intro.js/introjs.css';
import moment from 'moment';
import { getData } from '../../api/api';
import $ from "jquery";


class jobDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor1: "#404040",
            bgcolor2: "transparent",
            bgcolor3: "transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            txtcolor3: "black",
            navbar: "navigation3",
            getUserDetails: [],
            jd: [],
            searchDriver: ""
        };
        this.textchange = this.textchange.bind(this);
        this.submitJD = this.submitJD.bind(this);
        this.searchDriver = this.searchDriver.bind(this);
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    getuserDetails() {
        getData("helpdesk/getUserDetails", localStorage.getItem('u')).then(res => {
            this.setState({ getUserDetails: res });
            this.getJD(res);

        })
    }
    submitJD(e) {
        e.preventDefault();
        let user = this.state.getUserDetails;
        var data = { jd_name: this.state.jd_name, jd_suffix: this.state.jd_suffix, jd_code: this.state.jd_code, company_id: user[0].company_id }
        //    console.log(data)
        getData("position/addJDbyID", data).then(response => {
            // console.log(response)
            if (response.response == true) {
                $("#closeModal").click();
                alert("Job Description Added Successfully")
                this.getJD(user);
            } else {
                alert("Failed to Add Job Description")

            }
        })
    }
    getJD(user) {
        // let id = this.state.getUserDetails;
        var data = { company_id: user[0].company_id }
        // console.log(data)

        getData("position/getJDbyID", data).then(res => {
            // console.log(res)
            this.setState({ jd: res })
        })
    }
    componentDidMount() {
        this.getuserDetails();
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    render() {
        let JdSearch = this.state.jd.filter(
            (files) => {
                return files.job_description.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        // console.log(this.state.jd)
        return (
            <div className="content" id="content">

                <div className="panel" style={{ boxShadow: " 0px 0px 10px 0px rgba(140,140,140,0.50)" }}>
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i className="fas fa-id-card"></i>&nbsp;Job Description</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Job&nbsp;Description&nbsp;List</a></li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav">
                                <div name="navigation4" className="attendnav">
                                    <a href="#/department">Department</a>
                                </div>
                                <div name="navigation2" className="attendnav">
                                    <a href="#/matrix">Salary&nbsp;Matrix</a>
                                </div>
                                <div name="navigation3" className="attendnav">
                                    <a href="#/position">Position</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-green-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;New&nbsp;Job&nbsp;Description</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search" placeholder="Search Position" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Scrollbars style={{ height: 350 }}>
                            <table className="table table-bordered">
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>JD ID</th>
                                        <th>Job Description</th>
                                        <th>Job Description Suffix</th>
                                        <th>Job Description Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {JdSearch.map(data => {
                                        return <tr>
                                            <td>{data.jd_id}</td>
                                            <td>{data.job_description}</td>
                                            <td>{data.jd_suffix}</td>
                                            <td>{data.jd_code}</td>
                                        </tr>
                                    })}

                                </tbody>

                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"></h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.submitJD}>
                                    <fieldset>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Job Description</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="jd_name" placeholder="Name" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Job Description Suffix</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="jd_suffix" placeholder="Suffix" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Job Description Code</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="jd_code" placeholder="Code" required />
                                        </div>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                            &nbsp;Create
                                    </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default jobDescription;