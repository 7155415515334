import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { NotificationContainer, NotificationManager } from 'react-notifications';

class Adding_Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_fname: "",
            user_mname: "",
            user_lname: "",
            user_suffix: "",
            user_age: "",
            user_bday: "",
            birthplace: "",
            gender: "",
            civil_status: "",
            user_mobile: "",
            user_telephone: "",
            user_gcash: "",
            user_email: "",
            pres_house_street_subd: "",
            pres_barangay: "",
            pres_city: "",
            pres_province: "",
            pres_country: "",
            pres_zip: "",
            perm_house_street_subd: "",
            perm_barangay: "",
            perm_city: "",
            perm_province: "",
            perm_country: "",
            perm_zip: "",
            contact_name: "",
            contact_relation: "",
            contact_number: "",
            contact_address: "",
            same_address: false,
            userData: [],
            companies: [],
            departments: [],
            company: "",
            branches: [],
            branch_id: "",
            dept_id: "",
            jobd: "",
            jd: [],
            userjob_position: "",
            positions: [],
            user_date_hired: '',

        }
        this.bdaypicker = this.bdaypicker.bind(this);
        this.onChangeSelectGender = this.onChangeSelectGender.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.textchange = this.textchange.bind(this);
        this.onChangeSelectCompany = this.onChangeSelectCompany.bind(this);
        this.onChangeSelectBranch = this.onChangeSelectBranch.bind(this);
        this.onChangeSelectDepartment = this.onChangeSelectDepartment.bind(this);
        this.onChangeSelectJD = this.onChangeSelectJD.bind(this);
        this.dhiredpicker = this.dhiredpicker.bind(this);
        this.onChangeSelectPosition = this.onChangeSelectPosition.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeSelectEmployeeStatus = this.onChangeSelectEmployeeStatus.bind(this);

    }
    bdaypicker(e) {
        setTimeout(() => {
            this.setState({ user_bday: e });
        }, 100)
        // console.log(e)
    }
    onChangeSelectGender(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ gender: options[i].value })
            }
        }
    }
    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ civil_status: options[i].value })
            }
        }
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    same() {
        this.setState({
            same_address: !this.state.same_address,
            perm_house_street_subd: this.state.pres_house_street_subd,
            perm_barangay: this.state.pres_barangay,
            perm_city: this.state.pres_city,
            perm_province: this.state.pres_province,
            perm_country: this.state.pres_country,
            perm_zip: this.state.pres_zip
        })

    }
    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                // console.log(response)
                this.setState({ userData: response.users, companies: response.companies, req_type: response.request_type, departments: response.departments, hierarchy_structures: response.hierarchy_structures })
            })
    }
    componentDidMount() {
        this.getUser();
        this.getJD();
        this.getPosition();
    }
    onChangeSelectCompany(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                this.setState({ company: options[i].value })
            }
        }
        getData("users/getBranch", company)
            .then(result => {
                this.setState({ branches: result })
            });

    }
    onChangeSelectBranch(e) {
        var options = e.target.options;
        var value = [];
        var branch_id = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch_id = options[i].value;
                value.push(options[i].value);
                this.setState({ branch_id: options[i].value })
                // console.log(options[i].value)

            }
        }
    }

    onChangeSelectDepartment(e) {
        var options = e.target.options;
        var value = [];
        var dept_id = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                dept_id = options[i].value;
                value.push(options[i].value);
                this.setState({ dept_id: options[i].value })
            }
        }
    }
    onChangeSelectJD(e) {
        var options = e.target.options;
        var value = [];
        var jobd = ""

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                jobd = options[i].value;
                value.push(options[i].value);
                this.setState({ jobd: options[i].value })
            }

        }
    }
    getJD() {
        getData("position/getJD").then(res => {
            this.setState({ jd: res })
        })
    }
    onChangeSelectPosition(e) {
        var options = e.target.options;
        var value = [];
        var userjob_position = ""

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                userjob_position = options[i].value;
                value.push(options[i].value);
                this.setState({ userjob_position: options[i].value })
            }
        }
    }
    getPosition() {
        getData("position/getPosition").then(res => {
            this.setState({ positions: res })

        })
    }
    dhiredpicker(e) {
        setTimeout(() => {
            this.setState({ user_date_hired: e });
        }, 100)
        // console.log(e)
    }
    onFormSubmit(e) {
        e.preventDefault();
        var data = {
            dept_id: this.state.dept_id,
            app_id: 7,
            username: this.state.username,
            firstname: this.state.user_fname,
            lastname: this.state.user_lname,
            middlename: this.state.user_mname,
            suffixname: this.state.user_suffix,
            contact: this.state.user_mobile,
            civilStatus: this.state.civil_status,
            gender: this.state.gender,
            email: this.state.user_email,
            company_id: this.state.company,
            b_id: this.state.branch_id,
            userjob_position: this.state.userjob_position,
            jd: this.state.jobd,
            pres_house_street_subd: this.state.pres_house_street_subd,
            pres_barangay: this.state.pres_barangay,
            pres_city: this.state.pres_city,
            pres_province: this.state.pres_province,
            pres_country: this.state.pres_country,
            pres_zip: this.state.pres_zip,
            perm_house_street_subd: this.state.perm_house_street_subd,
            perm_barangay: this.state.perm_barangay,
            perm_city: this.state.perm_city,
            perm_province: this.state.perm_province,
            perm_country: this.state.perm_country,
            perm_zip: this.state.perm_zip,
            user_bday: moment(this.state.user_bday).format('YYYY-MM-DD'),
            user_date_hired: moment(this.state.user_date_hired).format('YYYY-MM-DD'),
            birthplace: this.state.birthplace,
            contact_relation: this.state.contact_relation,
            contact_number: this.state.contact_number,
            contact_name: this.state.contact_name,
            contact_address: this.state.contact_address,
            employee_status: this.state.employee_status,
            user_telephone: this.state.user_telephone,
            user_gcash: this.state.user_gcash,
            user_age: this.state.user_age,
        }
        console.log(data)
        getData("users/addingfieldman", data)
            .then(result => {
                let user = result;
                if (user.res === "wrong") {
                    NotificationManager.error('Employement Already Exists', 'Failed', 5000, true)
                } if (user.res === "success") {
                    NotificationManager.success('Employee Added Successfully', 'Success', 5000, true)
                    this.getUser();
                }
            });
        ;
    }
    onChangeSelectEmployeeStatus(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                // console.log(this.state.employee_status)
                this.setState({ employee_status: options[i].value })
            }
        }
    }
    render() {

        return (
            <div id="content" className="content">
                <NotificationContainer />
                <div className="panel">
                    <div className="panel-heading">

                    </div>
                    <div className="panel-body">
                        <form onSubmit={this.onFormSubmit}>
                            <fieldset>
                                <div className="row m-b-30">
                                    <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse">Personal info about the new employee</legend>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">First Name *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" placeholder="First Name" name="user_fname" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Middle Name</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" placeholder="Middle Name" name="user_mname" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Last Name *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" placeholder="Last Name" name="user_lname" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Suffix Name</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" placeholder="Suffix Name" name="user_suffix" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Employee Age *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="number" placeholder="Age" name="user_age" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Birth Date *</label>
                                            <div className="col-lg-9">
                                                <DatePicker
                                                    selected={this.state.user_bday}
                                                    selectsStart
                                                    startDate={this.state.user_bday}
                                                    // endDate={this.state.request_date_end}
                                                    onChange={this.bdaypicker}
                                                    className="form-control"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    placeholderText="Birth Date"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Birth Place *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" placeholder="Birth Place" name="birthplace" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Gender *</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" onChange={this.onChangeSelectGender} required>
                                                    <option disabled selected>Select Gender</option>
                                                    <option value="female">Female</option>
                                                    <option value="male">Male</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Civil Status *</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" onChange={this.onChangeSelect} required>
                                                    <option disabled selected>Select Civil Status</option>
                                                    <option value="single">Single</option>
                                                    <option value="maried">Married</option>
                                                    <option value="divorced">Divorced</option>
                                                    <option value="widowed">Widowed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-30">
                                    <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse">Contact info about the new employee</legend>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Phone Number *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="number" placeholder="Phone Number" name="user_mobile" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Telephone Number</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="number" placeholder="Telephone Number" name="user_telephone" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Gcash Number</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="number" placeholder="Gcash Number" name="user_gcash" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Email</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="email" placeholder="Contact Email" name="user_email" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-30">
                                    <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse">Address info about the new employee</legend>
                                    <div className="col-md-6">
                                        <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-10 text-inverse">Present Address</legend>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Street / Subdivision&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="pres_house_street_subd" className="form-control" value={this.state.pres_house_street_subd} />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Barangay *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="pres_barangay" className="form-control" value={this.state.pres_barangay} />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">City *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="pres_city" className="form-control" value={this.state.pres_city} />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Province *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="pres_province" className="form-control" value={this.state.pres_province} />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Country *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="pres_country" className="form-control" value={this.state.pres_country} />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Zip-code *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="pres_zip" className="form-control" value={this.state.pres_zip} />
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <input type="checkbox" id="same" name="same" onClick={() => {
                                                this.same()
                                            }} />
                                            <label className="m-l-5" for="cssCheckbox1">Check if present address is the same with permanent address.</label>
                                        </div>
                                    </div>
                                    {this.state.same_address === false &&
                                        <div className="col-md-6">
                                            <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-10 text-inverse">Permanent Address</legend>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Street / Subdivision&nbsp;*</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_house_street_subd" className="form-control" value={this.state.perm_house_street_subd} />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Barangay *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_barangay" className="form-control" value={this.state.perm_barangay} />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">City *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_city" className="form-control" value={this.state.perm_city} />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Province *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_province" className="form-control" value={this.state.perm_province} />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Country *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_country" className="form-control" value={this.state.perm_country} />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Zip-code *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_zip" className="form-control" value={this.state.perm_zip} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.same_address === true &&
                                        <div className="col-md-6">
                                            <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-10 text-inverse">Permanent Address</legend>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Street / Subdivision&nbsp;*</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_house_street_subd" className="form-control" value={this.state.perm_house_street_subd} disabled />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Barangay *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_barangay" className="form-control" value={this.state.perm_barangay} disabled />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">City *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_city" className="form-control" value={this.state.perm_city} disabled />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Province *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_province" className="form-control" value={this.state.perm_province} disabled />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Country *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_country" className="form-control" value={this.state.perm_country} disabled />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-10">
                                                <label className="col-lg-3 text-lg-right col-form-label">Zip-code *</label>
                                                <div className="col-lg-9">
                                                    <input onChange={this.textchange} type="text" name="perm_zip" className="form-control" value={this.state.perm_zip} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row m-b-30">
                                    <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse">Emergency Contact info about the new employee</legend>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Contact Name *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="contact_name" id="contact_name" placeholder="Contact's Name" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Relationship *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="contact_relation" id="contact_relation" placeholder="Relationship with Contact" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Contact Number*</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="number" name="contact_number" placeholder="Contact's Number" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Contact Address *</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="contact_address" placeholder="Contact's Address" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-b-30">
                                    <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse">Personal info about the new employee</legend>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Assigned&nbsp;Company&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <select value={this.state.select} className="form-control" onChange={this.onChangeSelectCompany} placeholder="Select Company" required>
                                                    <option readOnly selected>Select Company Name</option>
                                                    {this.state.companies.map((val, index) =>
                                                        <option value={val.company_id}>{val.company_name}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Assigned&nbsp;Branch&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" onChange={this.onChangeSelectBranch}>
                                                    <option disabled selected>Select Branch Name</option>
                                                    {this.state.branches.map((val1, index) =>
                                                        <option value={val1.bid}>{val1.bname}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Assigned&nbsp;Department&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" onChange={this.onChangeSelectDepartment}>
                                                    <option disabled selected>Select Department Name</option>
                                                    {this.state.departments.map((val1, index) =>
                                                        <option value={val1.dept_id}>{val1.dept_name}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Job&nbsp;Description&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" name="job" onChange={this.onChangeSelectJD} required>
                                                    <option disabled selected>Select Job Description</option>
                                                    {this.state.jd.map((val1, index) => {
                                                        return <option value={val1.job_description}>{val1.job_description}</option>
                                                    }
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Job&nbsp;Position&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" onChange={this.onChangeSelectPosition} required>
                                                    <option readOnly>Select Position Name</option>
                                                    {this.state.positions.map((val, index) =>
                                                        <option value={val.userjob_position}>{val.userjob_position}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Employee&nbsp;Status&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <select className="form-control" onChange={this.onChangeSelectEmployeeStatus} required>
                                                    <option readOnly>Select Employee Status</option>
                                                    <option disabled>--select status--</option>
                                                    <option value="Regular">Regular</option>
                                                    <option value="Probationary">Probationary</option>
                                                    <option value="Trainee">Trainee</option>
                                                    <option value="Contractual">Contractual</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Date&nbsp;Hired&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <DatePicker
                                                    selected={this.state.user_date_hired}
                                                    selectsStart
                                                    startDate={this.state.user_date_hired}
                                                    // endDate={this.state.request_date_end}
                                                    onChange={this.dhiredpicker}
                                                    className="form-control"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    placeholderText="Date Hired"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-10">
                                            <label className="col-lg-3 text-lg-right col-form-label">Username&nbsp;*</label>
                                            <div className="col-lg-9">
                                                <input onChange={this.textchange} type="text" name="username" placeholder="username" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn bg-green text-white m-l-5" type="submit" >Create</button>

                                </div>
                            </fieldset>
                        </form>

                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Job Order Count</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Adding_Employee;