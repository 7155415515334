import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import Modal from '../Modal/index';
class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [],
        };
    }

    modalClick = e => {
        // return <Modal data={e}/>
        let data = [];
        data.push(e);
        // return (<Modal datas={data}/>) 
    }
    render() {
        return (
            <div>
                {this.props.card.map(c =>
                    <div className={c.cardcolor}>
                        <div className="stats-icon"><i className="fa fa-user-tie"></i></div>
                        <div className="stats-info text-white">
                            <h4>{c.cardname}</h4>
                            <p>{c.cardbody}</p>
                        </div>
                        <Modal datas={this.props.card} />
                        {/* <div className="stats-link">
                            <a href="javascript:;" data-target={c.card} data-toggle="modal" onClick={() => this.modalClick(this.props)} >View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                        </div> */}
                    </div>
                )}
            </div>
        );
    }
}
export default Cards;