import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
class Dashboards extends Component {
    constructor(props){
        super(props);
        this.state = {
            filterStartDate: new Date(),
            filterEndDate: new Date(),
            
        }
    }
    filterDateStart = e => {
        this.setState({
            filterStartDate: e
        });
        console.log(this.state)
    }
    filterDateEnd = e => {
        this.setState({
            filterEndDate: e
        });

        console.log(this.state)
    }

    render() { 
        return ( 
            <div id="content" className="content">    
            <div className="row">
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-body">
                                    <form className="form-horizontal form-bordered">
                                            <div className="form-group row">
                                                <label className="col-md-4 col-form-label">Date Ranges</label>
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <DatePicker
                                                                selected={this.state.filterStartDate}
                                                                selectsStart
                                                                startDate={this.state.filterStartDate}
                                                                endDate={this.state.filterEndDate}
                                                                onChange={this.filterDateStart}
                                                                className="form-control"/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <DatePicker
                                                                selected={this.state.filterEndDate}
                                                                selectsEnd
                                                                startDate={this.state.filterStartDate}
                                                                endDate={this.state.filterEndDate}
                                                                onChange={this.filterDateEnd}
                                                                className="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-md-4">Branch</label>
                                                <div className="col-md-8">
                                                    <select className="form-control">
                                                        <option value="All">All</option>
                                                        <option value="All">All</option>
                                                        <option value="All">All</option>
                                                        <option value="All">All</option>
                                                        {/* {this.state.branches.map((branch) =>
                                                        <option value={branch}>{branch}</option>
                                                        )} */}
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <div className="panel-heading-btn"></div>
                                    <div className="row">
                                        <div className="col-md-1">
                                            <button className="btn btn-mint btn-xs" data-target="#demo-lg-modal2" data-toggle="modal">AddRecord</button>
                                        </div>
                                        <div className="col-md-1">
                                            <button className="btn btn-purple btn-xs">Export</button>
                                        </div>
                                        <div className="col-md-6">
                                        
                                        </div>
                                        <div className="col-md-4">
                                        <input type="text" className="form-control" style={{width:"100%"}} placeholder="Search..."/>
                                        </div>
                                    </div>
                                </div>
                                <div id="demo-lg-modal2" className="modal fade" tabindex="-1">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal"><i className="pci-cross pci-circle"></i></button>
                                                <h4 className="modal-title" id="myLargeModalLabel">Insert New Record</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <form className="form-horizontal form-bordered">
                                                        <div className="form-group row">
                                                            <label className="col-md-4 col-form-label">Date Ranges</label>
                                                            <div className="col-md-8">
                                                                       <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-md-4">Branch</label>
                                                            <div className="col-md-8">
                                                            <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-md-4">Branch</label>
                                                            <div className="col-md-8">
                                                            <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-md-4">Branch</label>
                                                            <div className="col-md-8">
                                                            <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <button className="btn btn-info">Submit</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div className="panel-body">
                                    
                                    <Scrollbars style={{height: 260 }}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Branch</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>qqq</td>
                                                    <td>qqq</td>
                                                    <td>qqq</td>
                                                    <td><button className="btn btn-info btn-xs" data-target="#demo-lg-modal" data-toggle="modal">Edit</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    <div id="demo-lg-modal" className="modal fade" tabindex="-1">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal"><i className="pci-cross pci-circle"></i></button>
                                                <h4 className="modal-title" id="myLargeModalLabel">Update form</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <form className="form-horizontal form-bordered">
                                                        <div className="form-group row">
                                                            <label className="col-md-4 col-form-label">Date Ranges</label>
                                                            <div className="col-md-8">
                                                                       <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-md-4">Branch</label>
                                                            <div className="col-md-8">
                                                            <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-md-4">Branch</label>
                                                            <div className="col-md-8">
                                                            <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-md-4">Branch</label>
                                                            <div className="col-md-8">
                                                            <input type="text" placeholder="anything..." className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <button className="btn btn-info">Submit</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="card card-primary card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                    <div className="card card-mint card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                    <div className="card card-success card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                    <div className="card card-danger card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                </div>
            </div>
            
            




           
        </div>

         );
    }
}
 
export default Dashboards;