import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigation: 1,
        };
    }
    componentDidMount() {
    }
    navHandler = () => {
        this.setState({ navigation: !this.state.navigation })
    }
    render() {
        console.log(this.props.data)
        let bgcolor = localStorage.getItem("bgcolor");
        let txtcolor = localStorage.getItem("txtcolor")
        return (
            <div>
                {/* HEADER START */}
                <div className="row">
                    {/* PAGE HEADER & BREADCRUMBS START */}
                    <div className="col-md-6">
                        {this.props.data.map((val, index) => {
                            if (localStorage.getItem("navbar") === val.bread) {
                                return <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;{val.title}</h4>
                            }
                        })}
                        <nav aria-label="breadcrumb bg-white" style={{ marginTop: "-15px" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Parent Breadcrumb</a></li>
                                {this.props.data.map((val, index) => {
                                    if (localStorage.getItem("navbar") === val.bread)
                                        return <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default', color: "black" }}>{val.bread}</a></li>
                                })}
                            </ol>
                        </nav>
                    </div>
                    {/* PAGE HEADER & BREADCRUMBS END */}

                    {/* NAVIGATION BUTTON START */}
                    <div className="col-md-6">
                        <div className="flex-container pull-right text-center m-t-5" >
                            {this.props.data.map((val, index) => {
                                return <div name="navigation1" id="1" className={localStorage.getItem("page") == "1" ? "activenav mapnav" : "inactivenav mapnav"}
                                    onClick={() => {
                                        localStorage.setItem("page", "1");
                                    }}>
                                    <a href={val.link}>{val.navi1}</a>
                                </div>
                            })}
                        </div>
                    </div>
                    {/* NAVIGATION BUTTON END */}
                </div>
                {/* HEADER END */}
            </div>
        );
    }
}
export default Header;