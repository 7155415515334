import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom'
import { getData } from '../../api/api';
class Attendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideBranch: true,
            hideBranch2: true,
            hideBranch3: true,
            hideBranch4: true,
            hideCompany: true,
            hideCompany2: true,
            hideCompany3: true,
            hideCompany4: true,
            today: true,
            timeshit: true,
            officialbusiness: true,
            ob: true,
            leave: true,
            dateToday: new Date(),
            Company: [],
            company_id: "",
            branch_id: "",
            branch_id_today: "",
            DATA: [],
            Branches: [],
            AttendanceToday: [],
            in: [],
            timeSheetHeader: [],
            obsheetHeader: [],
            timeSheet: [],
            obSheet: [],
            leaveSheet: [],
            timeSheetAttendace: [],
            timeSheetAttendaceHeader: [],
            obSheetAttendace: [],
            leaveSheetAttendace: [],
            refresh: false,
            from_date: new Date(),
            to_date: new Date(),
            from: "",
            to: "",
            time_type: ["Time", "time", "time", "time"],
            holdvalue: "",
            daterange: true,
            exportSHOW: true,
            exportOB: true,
            exportLeave: true,
            todaysPresent: 0,
            todaysLate: 0,
            todaysAbsent: 0,
            countTotal: 0,
            breabcrumbs: "Today",
            border1: "",
            user_id: "",
            title: "Today's Data Report",
            searchDriver: "",
            OBheader: [],
            branchBreakdown: [],
            onCLickBranch: [],
            bgcolor1: "#404040",
            bgcolor2: "#ededed",
            bgcolor3: "#ededed",
            bgcolor4: "#ededed",
            bgcolor5: "#ededed",
            txtcolor1: "white",
            txtcolor2: "black",
            txtcolor3: "black",
            txtcolor4: "black",
            txtcolor5: "black",
            navbar: "navigation1",
        }
        this.passBranch = this.passBranch.bind();
        this.searchDriver = this.searchDriver.bind(this);
        // this.FormSubmit = this.FormSubmit.bind();
    }


    filterDateStart = e => {
        setTimeout(() => {
            this.setState({
                from_date: e
            });

            var from = String(e);
            var split_from = from.split(" ");
            var from_date_spit = split_from[3] + "-" + split_from[1] + "-" + split_from[2];
            this.setState({ from: from_date_spit });
        }, 100)

        // console.log(this.state)
    }
    filterDateEnd = e => {
        setTimeout(() => {
            this.setState({
                to_date: e
            });
            var to = String(e);
            var split_to = to.split(" ");
            var to_date_spit = split_to[3] + "-" + split_to[1] + "-" + split_to[2];
            this.setState({ to: to_date_spit });
        }, 100)
        // console.log(this.state)
    }



    passCompany = e => {
        this.setState({ Branches: [] })

        var options = e.target.options;
        var value = [];
        var company = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
            }
        }
        this.setState({ company_id: company });
        var data = [];
        for (let index = 0; index < this.state.DATA.length; index++) {
            var data2 = [];
            if (this.state.DATA[index].company_id == company) {
                data2 = { companyName: this.state.DATA[index].company_name, company_id: this.state.DATA[index].company_id, branch_id: this.state.DATA[index].branch_id, branch_name: this.state.DATA[index].branch_name };
                data.push(data2);
            }
        }
        setTimeout(() => {
            this.setState({ Branches: data })

        }, 300);
        this.setState({ hideBranch: false })
        this.setState({ refresh: true });
    }

    passCompany2 = e => {
        this.setState({ Branches: [] })

        var options = e.target.options;
        var value = [];
        var company = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
            }
        }
        // this.setState({ hideBranch2: true })
        this.setState({ company_id: company });
        var data = [];
        for (let index = 0; index < this.state.DATA.length; index++) {
            var data2 = [];
            if (this.state.DATA[index].company_id == company) {
                data2 = { companyName: this.state.DATA[index].company_name, company_id: this.state.DATA[index].company_id, branch_id: this.state.DATA[index].branch_id, branch_name: this.state.DATA[index].branch_name };
                data.push(data2);
            }
        }
        setTimeout(() => {
            this.setState({ Branches: data })

        }, 300);
        this.setState({ hideBranch2: false })
        this.setState({ refresh: true });
    }

    passCompany3 = e => {

        this.setState({ Branches: [] })
        var options = e.target.options;
        var value = [];
        var company = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
            }
        }
        // this.setState({ hideBranch2: true })
        this.setState({ company_id: company });
        var data = [];
        for (let index = 0; index < this.state.DATA.length; index++) {
            var data2 = [];
            if (this.state.DATA[index].company_id == company) {
                data2 = { companyName: this.state.DATA[index].company_name, company_id: this.state.DATA[index].company_id, branch_id: this.state.DATA[index].branch_id, branch_name: this.state.DATA[index].branch_name };
                data.push(data2);
            }
        }
        setTimeout(() => {
            this.setState({ Branches: data })

        }, 300);
        this.setState({ hideBranch3: false })
        this.setState({ refresh: true });
    }

    passCompany4 = e => {
        this.setState({ Branches: [] })

        var options = e.target.options;
        var value = [];
        var company = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
            }
        }
        // this.setState({ hideBranch2: true })
        this.setState({ company_id: company });
        var data = [];
        for (let index = 0; index < this.state.DATA.length; index++) {
            var data2 = [];
            if (this.state.DATA[index].company_id == company) {
                data2 = { companyName: this.state.DATA[index].company_name, company_id: this.state.DATA[index].company_id, branch_id: this.state.DATA[index].branch_id, branch_name: this.state.DATA[index].branch_name };
                data.push(data2);
            }
        }
        setTimeout(() => {
            this.setState({ Branches: data })

        }, 300);
        this.setState({ hideBranch4: false })
        this.setState({ refresh: true });

    }

    passBranch = e => {
        var options = e.target.options;
        var data = [];
        var branch = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch = options[i].value;
                // value.push(options[i].value);
            }
        }
        // this.setState({branch_id_today:branch});

        this.attendance_today(branch);
    }
    attendance_today(branch) {
        var data = [];
        data = {
            company_id: this.state.company_id,
            branch_id: branch,
            dateToday: this.state.dateToday,
            user_id: localStorage.getItem("u")
        }
        getData("users/GetCurrentAttendance", data)
            .then(response => {
                this.setState({ AttendanceToday: response.details });
                console.log(response)
                var arrayD = []
                var value1 = 0;
                var Arrays = []
                var Attendance = 0;
                var countpresent = 0;
                var countlate = 0;
                var countabsent = 0;
                var branchBreakdown = []
                for (let index = 0; index < response.details.length; index++) {
                    console.log(response.details[index].branch_name)
                    var matchBranch = false;
                    for (let b = 0; b < branchBreakdown.length; b++) {
                        if (branchBreakdown[b].name == response.details[index].branch_name) {
                            var presentDetails = []
                            if (response.details[index]['status'] == "PRESENT" || response.details[index]['status'] == "LATE") {
                                presentDetails.push({ name: response.details[index].branch_name })
                                branchBreakdown[b].presentCount.push(presentDetails)
                            }
                            if (response.details[index]['status'] == "LATE") {
                                branchBreakdown[b].latecount.push({ name: response.details[index].branch_name })

                            }
                            if (response.details[index]['status'] == "ABSENT") {
                                branchBreakdown[b].absentCount.push({ name: response.details[index].branch_name })

                            }
                            matchBranch = true;
                            branchBreakdown[b].count.push({ name: response.details[index].branch_name })


                            // console.log(branchBreakdown[b].count)

                        }

                    }
                    var b_details = { name: '', count: [], presentCount: [], latecount: [], absentCount: [] }
                    // var b_details=[]
                    if (matchBranch == false) {
                        var presentDetails = []
                        if (response.details[index]['status'] == "PRESENT" || response.details[index]['status'] == "LATE") {
                            b_details.presentCount.push({ name: response.details[index].branch_name })
                        }
                        if (response.details[index]['status'] == "LATE") {
                            b_details.latecount.push({ name: response.details[index].branch_name })
                        }
                        if (response.details[index]['status'] == "ABSENT") {
                            b_details.absentCount.push({ name: response.details[index].branch_name })

                        }
                        b_details.name = response.details[index].branch_name;
                        b_details.count.push({ name: response.details[index].branch_name });

                        branchBreakdown.push(b_details);
                    }


                    if (response.details[index]['status'] == "PRESENT" || response.details[index]['status'] == "LATE") {
                        countpresent++;
                        this.setState({ todaysPresent: countpresent });
                    }
                    if (response.details[index]['status'] == "LATE") {
                        countlate++;
                        this.setState({ todaysLate: countlate });
                    }
                    if (response.details[index]['status'] == "ABSENT") {
                        countabsent++;
                        this.setState({ todaysAbsent: countabsent });
                    }
                    this.setState({ countTotal: response.details.length });

                    for (let i = 0; i < response.details[index].details['timeSheet'].length; i++) {
                        var data = [];
                        if (response.details[index].details['timeSheet'][i]['fields'].length > value1) {
                            value1 = response.details[index].details['timeSheet'][i]['fields'].length
                            arrayD = response.details[index].details['timeSheet'][i]['fields']
                        }
                        Arrays = response.details[index].details['timeSheet'][i]['attendance'];

                    }
                }
                console.log(branchBreakdown);
                this.setState({ branchBreakdown: branchBreakdown })
                var dataDetaiils = [];
                for (let index = 0; index < arrayD.length; index++) {
                    var name = { fields: arrayD[index] }
                    dataDetaiils.push(name);
                    this.setState({ timeSheetHeader: dataDetaiils })
                }

                console.log(countpresent);
            });
    }
    passBranch2 = e => {
        var options = e.target.options;
        var data = [];
        var branch = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch = options[i].value;
            }
        }
        this.setState({ branch_id: branch })
        data = {
            company_id: this.state.company_id,
            branch_id: branch
        }
        getData("users/get_Timesheet", data)
            .then(response => {
                this.setState({ timeSheet: response.user_name });

                // console.log(response);
            })
        this.setState({ exportSHOW: false })

    }

    passBranch3 = e => {
        var options = e.target.options;
        var data = [];
        var branch = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch = options[i].value;
            }
        }
        this.setState({ branch_id: branch })
        data = {
            company_id: this.state.company_id,
            branch_id: branch
        }
        getData("users/get_Timesheet", data)
            .then(response => {
                this.setState({ obSheet: response.user_name });

                // console.log(response);
            })
        this.setState({ exportOB: false })

    }

    passBranch4 = e => {
        var options = e.target.options;
        var data = [];
        var branch = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch = options[i].value;
            }
        }
        this.setState({ branch_id: branch })
        data = {
            company_id: this.state.company_id,
            branch_id: branch
        }
        getData("users/get_Timesheet", data)
            .then(response => {
                this.setState({ leaveSheet: response.user_name });

                // console.log(response);
            })
        this.setState({ exportLeave: false })

    }

    getCurrentDay() {
        getData("users/Timeshit", localStorage.getItem("u"))
            .then(response => {
                this.setState({ timeSheet: response });
                this.setState({ obSheet: response });
                this.setState({ leaveSheet: response });
                // console.log(response);
            })
    }

    getTimeshit(user_data) {
        this.setState({
            timeSheetAttendace: [],
            user_id: user_data.user_id
        })
        var data = [];
        data = {
            company_id: user_data.company_id,
            branch_id: user_data.branch_id,
            user_id: user_data.user_id,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        }
        getData("users/fetch_TimesheetDetails", data)
            .then(response => {
                console.log(response);
                var OBheader = []
                for (let i = 0; i < response.data.length; i++) {
                    for (let b = 0; b < response.data[i].timesheet['details'].length; b++) {
                        this.setState({ timeSheetAttendace: response.data[i].timesheet['details'] });
                        this.setState({ refresh: true });
                        if (response.data[i].timesheet['details'][b].OB_Attendance != undefined) {
                            var countOB = []
                            for (let l = 0; l < response.data[i].timesheet['details'][b].OB_Attendance.length; l++) {
                                if (countOB.length == 0) {
                                    var headerOB = { headerOB: "OB-Start" }
                                    countOB.push(headerOB)
                                }
                                else if (countOB[countOB.length - 1].headerOB == "OB-Start") {
                                    var headerOB = { headerOB: "OB-End" }
                                    countOB.push(headerOB)
                                } else if (countOB[countOB.length - 1].headerOB == "OB-End") {
                                    var headerOB = { headerOB: "OB-Start" }
                                    countOB.push(headerOB)
                                }

                            }
                            if (countOB.length > OBheader.length) {
                                OBheader = countOB
                            }
                        }

                    }

                }
                console.log(OBheader);
                this.setState({ OBheader: OBheader });
                // console.log(response.data);
                // console.log(response);
            })
        // console.log(user_data);
    }

    getObshit(user_data) {
        this.setState({ obSheetAttendace: [] })
        var data = [];
        data = {
            company_id: user_data.company_id,
            branch_id: user_data.branch_id,
            user_id: user_data.user_id,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        }
        getData("users/GetOfficialBusiness", data)
            .then(response => {
                console.log(response)
                // var arrayD = []
                // var value1 = 0;
                // for (let i = 0; i < response.data2.length; i++) {
                //     for (let b = 0; b <  response.data2[i].obsheet['details'].length; b++) {

                this.setState({ obSheetAttendace: response.data });
                //         if (response.data2[i].obsheet['details'][b]['attendance_type'].length > value1) {
                //             value1 = response.data2[i].obsheet['details'][b]['attendance_type'].length
                //             arrayD = response.data2[i].obsheet['details'][b]['attendance_type']
                //         }
                //         this.setState({refresh:true});
                //     }

                // }
                // var dataDetaiils = [];
                // for (let index = 0; index < arrayD.length; index++) {
                //     var name = { fields: arrayD[index] }
                //     dataDetaiils.push(name);
                //     this.setState({ obsheetHeader: dataDetaiils })
                // }

            })
    }

    getLeaveshit(user_data) {
        this.setState({ leaveSheetAttendace: [] })
        var data = [];
        data = {
            company_id: user_data.company_id,
            branch_id: user_data.branch_id,
            user_id: user_data.user_id,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        }
        getData("users/get_Leave", data)
            .then(response => {
                this.setState({ leaveSheetAttendace: response.leave });
                // console.log(response);
            })
    }

    componentDidMount() {
        // this.TodayAttendance();
        var branch = "";
        this.attendance_today(branch);
        getData("users/getCompanies", localStorage.getItem("u"))
            .then(response => {
                // console.log(response.company)
                this.setState({ DATA: response.company });
                var data = [];
                for (let i = 0; i < response.company.length; i++) {
                    var match = false;
                    for (let index = 0; index < data.length; index++) {
                        if (data[index].companyName == response.company[i].company_name) {
                            match = true;
                        }
                    }
                    var data2 = [];
                    if (match == false) {
                        data2 = { companyName: response.company[i].company_name, company_id: response.company[i].company_id };
                        data.push(data2);
                    }
                }
                this.setState({ Company: data });
            });

        var from = String(this.state.from_date);
        var split_from = from.split(" ");
        var from_date_spit = split_from[3] + "-" + split_from[1] + "-" + split_from[2];
        this.setState({ from: from_date_spit });

        var to = String(this.state.to_date);
        var split_to = to.split(" ");
        var to_date_spit = split_to[3] + "-" + split_to[1] + "-" + split_to[2];
        this.setState({ to: to_date_spit });


    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }

    render() {
        // console.log( this.state.AttendanceToday)
        let AttendanceToday = this.state.AttendanceToday.filter(
            (files) => {
                return files.name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.branch_name.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1
            })
        // console.log(this.state.obsheetHeader)
        //  console.log(this.state.from_date,"",this.state.to_date)
        return (
            <div id="content" className="content">
                <div className="row" style={{ marginTop: "-15px" }}>
                    <div className="col-md-6">
                        {this.state.navbar === "navigation1" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Today's Attendance</h4>
                        }
                        {this.state.navbar === "navigation2" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Employees' Leave</h4>
                        }
                        {this.state.navbar === "navigation3" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Employees Official Business</h4>
                        }
                        {this.state.navbar === "navigation4" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Timesheet</h4>
                        }
                        {this.state.navbar === "navigation5" &&
                            <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Undertime</h4>
                        }
                        <nav aria-label="breadcrumb bg-white" style={{ marginTop: "-15px" }}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                {this.state.navbar === "navigation1" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Today's Attendance</a></li>
                                }
                                {this.state.navbar === "navigation2" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Employees' Leave</a></li>
                                }
                                {this.state.navbar === "navigation3" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Employees Official Business</a></li>
                                }
                                {this.state.navbar === "navigation4" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Timesheet</a></li>
                                }
                                {this.state.navbar === "navigation5" &&
                                    <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Undertime</a></li>
                                }
                            </ol>
                        </nav>
                    </div>
                    <div className="col-md-6">
                        <div className="flex-container pull-right text-center m-t-5" >
                            <div name="navigation1" className="mapnav" style={{ backgroundColor: this.state.bgcolor1 }}
                                onClick={() => {
                                    this.setState({ navbar: "navigation1" })
                                    this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                    this.setState({ bgcolor2: "#ededed", txtcolor2: "black" })
                                    this.setState({ bgcolor3: "#ededed", txtcolor3: "black" })
                                    this.setState({ bgcolor4: "#ededed", txtcolor4: "black" })
                                    this.setState({ bgcolor5: "#ededed", txtcolor5: "black" })
                                    var branch = "";
                                    this.attendance_today(branch);
                                    this.setState({
                                        dateToday: new Date(),
                                        borderbottom1: 'green',
                                        hideCompany: true,
                                        hideCompany2: true,
                                        hideCompany3: true,
                                        hideCompany4: true,
                                        hideBranch: true,
                                        hideBranch2: true,
                                        hideBranch3: true,
                                        hideBranch4: true,
                                        today: true,
                                        timeshit: true,
                                        officialbusiness: true,
                                        ob: true,
                                        leave: true,
                                        exportSHOW: true,
                                        daterange: true,
                                        exportOB: true,
                                        exportLeave: true,
                                    })
                                }}
                            >
                                <a href="javascript:;" style={{ color: this.state.txtcolor1 }}>Today</a>
                            </div>
                            <div name="navigation2" className="mapnav" style={{ backgroundColor: this.state.bgcolor2 }}
                                onClick={() => {
                                    this.setState({ navbar: "navigation2" })
                                    this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                    this.setState({ bgcolor1: "#ededed", txtcolor1: "black" })
                                    this.setState({ bgcolor3: "#ededed", txtcolor3: "black" })
                                    this.setState({ bgcolor4: "#ededed", txtcolor4: "black" })
                                    this.setState({ bgcolor5: "#ededed", txtcolor5: "black" })
                                    this.getCurrentDay()
                                    this.setState({
                                        officialbusiness: true,
                                        today: false,
                                        timeshit: true,
                                        leave: false,
                                        hideCompany: false,
                                        hideCompany2: true,
                                        hideCompany3: true,
                                        hideCompany4: false,
                                        hideBranch: true,
                                        hideBranch2: true,
                                        hideBranch3: true,
                                        hideBranch4: true,
                                        exportSHOW: true,
                                        daterange: false,
                                        exportOB: true,
                                        exportLeave: true,
                                    })
                                }}
                            >
                                <a href="javascript:;" style={{ color: this.state.txtcolor2 }}>Leave</a>
                            </div>
                            <div name="navigation3" className="mapnav" style={{ backgroundColor: this.state.bgcolor3 }}
                                onClick={() => {
                                    this.setState({ navbar: "navigation3" })
                                    this.setState({ bgcolor3: "#404040", txtcolor3: "white" })
                                    this.setState({ bgcolor1: "#ededed", txtcolor1: "black" })
                                    this.setState({ bgcolor2: "#ededed", txtcolor2: "black" })
                                    this.setState({ bgcolor4: "#ededed", txtcolor4: "black" })
                                    this.setState({ bgcolor5: "#ededed", txtcolor5: "black" })
                                    this.getCurrentDay()
                                    this.setState({
                                        hideCompany: false,
                                        hideCompany2: true,
                                        hideCompany3: false,
                                        hideCompany4: true,
                                        hideBranch: true,
                                        hideBranch2: true,
                                        hideBranch3: true,
                                        hideBranch4: true,
                                        officialbusiness: false,
                                        today: false,
                                        timeshit: true,
                                        leave: true,
                                        exportSHOW: true,
                                        daterange: false,
                                        exportOB: true,
                                        exportLeave: true,
                                    })
                                }}
                            >
                                <a href="javascript:;" style={{ color: this.state.txtcolor3 }}>O.B.</a>
                            </div>
                            <div name="navigation4" className="mapnav" style={{ backgroundColor: this.state.bgcolor4 }}
                                onClick={() => {
                                    this.setState({ navbar: "navigation4" })
                                    this.setState({ bgcolor4: "#404040", txtcolor4: "white" })
                                    this.setState({ bgcolor1: "#ededed", txtcolor1: "black" })
                                    this.setState({ bgcolor2: "#ededed", txtcolor2: "black" })
                                    this.setState({ bgcolor3: "#ededed", txtcolor3: "black" })
                                    this.setState({ bgcolor5: "#ededed", txtcolor5: "black" })
                                    this.getCurrentDay()
                                    this.setState({
                                        hideCompany: false,
                                        hideCompany2: false,
                                        hideCompany3: true,
                                        hideCompany4: true,
                                        hideBranch: true,
                                        hideBranch2: true,
                                        hideBranch3: true,
                                        hideBranch4: true,
                                        today: false,
                                        officialbusiness: true,
                                        timeshit: false,
                                        leave: true,
                                        AttendanceToday: [],
                                        timeSheetHeader: [],
                                        daterange: false,
                                        exportOB: true,
                                        exportLeave: true,
                                    })
                                }}
                            >
                                <a href="javascript:;" style={{ color: this.state.txtcolor4 }}>Timesheet</a>
                            </div>
                            <div name="navigation5" className="mapnav" style={{ backgroundColor: this.state.bgcolor5 }}
                                onClick={() => {
                                    this.setState({ navbar: "navigation5" })
                                    this.setState({ bgcolor5: "#404040", txtcolor5: "white" })
                                    this.setState({ bgcolor1: "#ededed", txtcolor1: "black" })
                                    this.setState({ bgcolor2: "#ededed", txtcolor2: "black" })
                                    this.setState({ bgcolor3: "#ededed", txtcolor3: "black" })
                                    this.setState({ bgcolor4: "#ededed", txtcolor4: "black" })
                                }}>
                                <a href="javascript:;" style={{ color: this.state.txtcolor5 }}>Undertime</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-green">
                            <div className="stats-icon"><i className="fa fa-users"></i></div>
                            <div className="stats-info text-white">
                                <h4>TOTAL EMPLOYEES</h4>
                                <p>{this.state.countTotal}</p>
                            </div>
                            <div className="stats-link">
                                <a href="javascript:;" data-target="#demo-lg-modal5" data-toggle="modal">View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-blue">
                            <div className="stats-icon"><i className="fa fa-user-tie"></i></div>
                            <div className="stats-info text-white">
                                <h4>PRESENT EMPLOYEES</h4>
                                <p>{this.state.todaysPresent}</p>
                            </div>
                            <div className="stats-link">
                                <a href="javascript:;" data-target="#demo-lg-modal6" data-toggle="modal">View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-orange">
                            <div className="stats-icon"><i className="fa fa-user-tie"></i></div>
                            <div className="stats-info text-white">
                                <h4>LATE EMPLOYEES</h4>
                                <p>{this.state.todaysLate}</p>
                            </div>
                            <div className="stats-link">
                                <a href="javascript:;" data-target="#demo-lg-modal7" data-toggle="modal">View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-red">
                            <div className="stats-icon"><i className="fa fa-user-tie"></i></div>
                            <div className="stats-info text-white">
                                <h4>ABSENT EMPLOYEES</h4>
                                <p>{this.state.todaysAbsent}</p>
                            </div>
                            <div className="stats-link">
                                <a href="javascript:;" data-target="#demo-lg-modal8" data-toggle="modal">View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-7">
                        <div className="main11">
                            {this.state.hideCompany === true &&
                                <select className="form-control" style={{ width: '45%' }} onChange={this.passCompany}>
                                    <option>--SELECT COMPANY--</option>
                                    {this.state.Company.map((val, index) =>
                                        <option value={val.company_id} key={index}>{val.companyName}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideBranch === false &&
                                <select className="form-control" style={{ width: '45%', marginLeft: 5 }} onChange={this.passBranch}>
                                    <option>--SELECT BRANCH--</option>
                                    <option></option>
                                    {this.state.Branches.map((val, index) =>
                                        <option value={val.branch_id} key={index}>{val.branch_name}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideCompany2 === false &&
                                <select className="form-control" style={{ width: '45%' }} onChange={this.passCompany2}>
                                    <option>--SELECT COMPANY--</option>
                                    <option></option>
                                    {this.state.Company.map((val, index) =>
                                        <option value={val.company_id} key={index}>{val.companyName}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideBranch2 === false &&
                                <select className="form-control" style={{ width: '45%', marginLeft: 5 }} onChange={this.passBranch2}>
                                    <option>--SELECT BRANCH--</option>
                                    <option></option>
                                    {this.state.Branches.map((val, index) =>
                                        <option value={val.branch_id} key={index}>{val.branch_name}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideCompany3 === false &&
                                <select className="form-control" style={{ width: '45%' }} onChange={this.passCompany3}>
                                    <option>--SELECT COMPANY--</option>
                                    <option></option>
                                    {this.state.Company.map((val, index) =>
                                        <option value={val.company_id} key={index}>{val.companyName}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideBranch3 === false &&
                                <select className="form-control" style={{ width: '45%', marginLeft: 5 }} onChange={this.passBranch3}>
                                    <option>--SELECT BRANCH--</option>
                                    <option></option>
                                    {this.state.Branches.map((val, index) =>
                                        <option value={val.branch_id} key={index}>{val.branch_name}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideCompany4 === false &&
                                <select className="form-control" style={{ width: '45%' }} onChange={this.passCompany4}>

                                    <option>--SELECT COMPANY--</option>
                                    <option></option>
                                    {this.state.Company.map((val, index) =>
                                        <option value={val.company_id} key={index}>{val.companyName}</option>
                                    )}
                                </select>
                            }
                            {this.state.hideBranch4 === false &&
                                <select className="form-control" style={{ width: '45%', marginLeft: 5 }} onChange={this.passBranch4}>
                                    <option>--SELECT BRANCH--</option>
                                    <option></option>
                                    {this.state.Branches.map((val, index) =>
                                        <option value={val.branch_id} key={index}>{val.branch_name}</option>
                                    )}
                                </select>
                            }
                            {this.state.daterange === false &&
                                <button className="bttncalendar bg-blue-lighter m-t-5" data-target="#demo-lg-modal1" data-toggle="modal">
                                    <span className="fa fa-calendar-alt"></span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="main11 col-md-8">
                                <div>
                                    <div className="form-group pull-left m-t-5">
                                        <div className="input-group pull-left" style={{ width: 330 }}>
                                            <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                            <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="main12">
                            {this.state.exportSHOW === false &&
                                <div style={{ flexDirection: 'row' }}>
                                    <a href={"http://api.pacificweb.com.ph/Users/exporting/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id} className="bttnexpo bg-green-lighter">
                                        <span><i className="fa fa-download"></i>&nbsp;Timesheet</span>
                                    </a>
                                    <a href={"http://api.pacificweb.com.ph/Users/exportingOB_timesheet/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id} className="bttnexpoOB bg-green-lighter" style={{ textDecoration: "none" }}>
                                        <span><i className="fa fa-download"></i>&nbsp;Official Business</span>
                                    </a>
                                </div>
                            }
                            {this.state.exportOB === false &&
                                <a href={"http://api.pacificweb.com.ph/Users/exporting_OB/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id} className="bttnexpo bg-green-lighter">
                                    <span className="fa fa-download"></span>
                                </a>
                            }
                            {this.state.exportLeave === false &&
                                <a href={"http://api.pacificweb.com.ph/Users/exporting_Leave/" + this.state.from + "/" + this.state.to + "/" + this.state.branch_id} className="bttnexpo bg-green-lighter">
                                    <span className="fa fa-download"></span>
                                </a>
                            }
                        </div>
                    </div>
                </div>
                <br />
                <div className="panel panel-default">
                    <div className="panel">
                        <div className="panel-body">
                            <Scrollbars style={{ height: 350 }}>
                                {this.state.today === true &&
                                    <table className="table table-td-valign-middle" >
                                        <thead className="tableFixHead text-center" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                                {/* <th>Company</th> */}
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Class</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Type</th>
                                                {this.state.timeSheetHeader.map((val, index1) => {
                                                    return <th style={{ backgroundColor: '#f5f6fa' }} key={index1}>{val.fields}</th>
                                                })}
                                            </tr>
                                        </thead>

                                        {AttendanceToday.map((val, index1) => {
                                            return <tr>
                                                <td>{index1 + 1}</td>
                                                <td>{val.name}</td>
                                                <td>{val.branch_name}</td>
                                                <td>{val.class_type}</td>
                                                <td>{val.status}</td>
                                                {this.state.timeSheetHeader.map((value, index) => {
                                                    var data_value = '--:--';
                                                    {
                                                        this.state.AttendanceToday.map((val1, index1) => {

                                                            if (val.user_id == val1.user_id) {

                                                                for (let i = 0; i < val1.details['timeSheet'].length; i++) {
                                                                    if (val1.details['timeSheet'][i].attendance[index] != undefined) {
                                                                        data_value = val1.details['timeSheet'][i].attendance[index]
                                                                    } else {
                                                                        data_value = '--:--';
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                    return <td key={index}>{data_value}</td>
                                                })}
                                            </tr>
                                        })}
                                    </table>
                                }
                                {this.state.timeshit === false &&
                                    <table className="table" >
                                        <thead className="tableFixHead" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>ID</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                                <th style={{ backgroundColor: '#f5f6fa', zIndex: 2 }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.timeSheet.map((value, index) => {
                                                return <tr key={index}>
                                                    <td width="10%">{index + 1}</td>
                                                    <td width="10%">{value.user_id}</td>
                                                    <td width="30%">{value.COMPLETENAME}</td>
                                                    <td width="30%">{value.company_name}</td>
                                                    <td width="30%">{value.branch_name}</td>
                                                    <td width="30%">
                                                        {/* <a tooltip="VIEW" href="javascript:;" data-target="#demo-lg-modal2" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getTimeshit(value)}>
                                                    <i className="fa fa-2x fa-eye text-success"></i>
                                                </a> */}
                                                        <a target="_blank" className="bttna bg-blue-lighter"
                                                            href="javascript:;" data-target="#demo-lg-modal2" data-toggle="modal" onClick={() => this.getTimeshit(value)}>
                                                            <span className="fa fa-eye  "></span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                }

                                {this.state.officialbusiness === false &&
                                    <table className="table" >
                                        <thead className="tableFixHead" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                {/* <th style={{ backgroundColor: '#f5f6fa' }}>ID</th> */}
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                                <th style={{ backgroundColor: '#f5f6fa', zIndex: 2 }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.obSheet.map((value, index) => {
                                                return <tr key={index}>
                                                    <td width="10%">{index + 1}</td>
                                                    {/* <td width="10%">{value.user_id}</td> */}
                                                    <td width="30%">{value.COMPLETENAME}</td>
                                                    <td width="30%">{value.company_name}</td>
                                                    <td width="30%">{value.branch_name}</td>
                                                    <td width="30%">
                                                        {/* <a tooltip="VIEW" href="javascript:;" data-target="#demo-lg-modal3" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getObshit(value)}>
                                               <i className="fa fa-2x fa-eye text-success"></i>
                                           </a> */}
                                                        {/* <button className="bttna" 
                                                       href="javascript:;" data-target="#demo-lg-modal3" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getObshit(value)}>
                                                                <span className="fa fa-eye  "></span>
                                            </button> */}
                                                        <a target="_blank" className="bttna bg-blue-lighter"
                                                            href="javascript:;" data-target="#demo-lg-modal3" data-toggle="modal" onClick={() => this.getObshit(value)}>
                                                            <span className="fa fa-eye  "></span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                }

                                {this.state.leave === false &&
                                    <table className="table" >
                                        <thead className="tableFixHead" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>ID</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                                <th style={{ backgroundColor: '#f5f6fa', zIndex: 2 }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.leaveSheet.map((value, index) => {
                                                return <tr key={index}>
                                                    <td width="10%">{index + 1}</td>
                                                    <td width="10%">{value.user_id}</td>
                                                    <td width="30%">{value.COMPLETENAME}</td>
                                                    <td width="30%">{value.company_name}</td>
                                                    <td width="30%">{value.branch_name}</td>
                                                    <td width="30%">
                                                        {/* <a tooltip="VIEW" href="javascript:;" data-target="#demo-lg-modal4" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getLeaveshit(value)}>
                                               <i className="fa fa-2x fa-eye text-success"></i>
                                           </a> */}
                                                        {/* <a target="_blank" className="bttna" 
                                                       href="javascript:;" data-target="#demo-lg-modal4" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getLeaveshit(value)}>
                                                                <span className="fa fa-eye  "></span>
                                            </a> */}
                                                        <a target="_blank" className="bttna bg-blue-lighter"
                                                            href="javascript:;" data-target="#demo-lg-modal4" data-toggle="modal" onClick={() => this.getLeaveshit(value)}>

                                                            <span className="fa fa-eye  "></span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                }

                            </Scrollbars>
                        </div>
                    </div>
                    {/* <Scrollbars style={{ height: 350 }}>
                        {this.state.today === true &&
                            <table className="table table-bordered table-striped table-td-valign-middle" >
                                <thead className="tableFixHead text-center" >
                                    <tr>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Class</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Type</th>
                                        {this.state.timeSheetHeader.map((val, index1) => {
                                            return <th style={{ backgroundColor: '#f5f6fa' }} key={index1}>{val.fields}</th>
                                        })}
                                    </tr>
                                </thead>
                                {this.state.AttendanceToday.map((val, index1) => {
                                    return <tr>
                                        <td>{index1 + 1}</td>
                                        <td>{val.name}</td>
                                        <td>{val.branch_name}</td>
                                        <td>{val.class_type}</td>
                                        <td>{val.status}</td>
                                        {this.state.timeSheetHeader.map((value, index) => {
                                            var data_value = '--:--';
                                            {
                                                this.state.AttendanceToday.map((val1, index1) => {

                                                    if (val.user_id == val1.user_id) {

                                                        for (let i = 0; i < val1.details['timeSheet'].length; i++) {
                                                            if (val1.details['timeSheet'][i].attendance[index] != undefined) {
                                                                data_value = val1.details['timeSheet'][i].attendance[index]
                                                            } else {
                                                                data_value = '--:--';
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                            return <td key={index}>{data_value}</td>
                                        })}
                                    </tr>
                                })}
                            </table>
                        }
                        {this.state.timeshit === false &&
                            <table className="table table-bordered" >
                                <thead className="tableFixHead" >
                                    <tr>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>ID</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                        <th style={{ backgroundColor: '#f5f6fa' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.timeSheet.map((value, index) => {
                                        return <tr key={index}>
                                            <td width="10%">{index + 1}</td>
                                            <td width="10%">{value.user_id}</td>
                                            <td width="30%">{value.COMPLETENAME}</td>
                                            <td width="30%">{value.company_name}</td>
                                            <td width="30%">{value.branch_name}</td>
                                            <td width="30%">
                                                <a target="_blank" className="bttna" 
                                                       href="javascript:;" data-target="#demo-lg-modal2" data-toggle="modal" onClick={()=>this.getTimeshit(value)}>
                                                                <span className="fa fa-eye  "></span>
                                                </a>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        }

                        {this.state.officialbusiness === false &&
                           <table className="table table-bordered" >
                           <thead className="tableFixHead" >
                               <tr>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Action</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.obSheet.map((value, index) => {
                                   return <tr key={index}>
                                       <td width="10%">{index + 1}</td>
                                       <td width="30%">{value.COMPLETENAME}</td>
                                       <td width="30%">{value.company_name}</td>
                                       <td width="30%">{value.branch_name}</td>
                                       <td width="30%">
                                           <a tooltip="VIEW" href="javascript:;" data-target="#demo-lg-modal3" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getObshit(value)}>
                                               <i className="fa fa-2x fa-eye text-success"></i>
                                           </a>
                                       </td>
                                   </tr>
                               })}
                           </tbody>
                       </table>
                        } 

                        {this.state.leave === false &&
                           <table className="table table-bordered" >
                           <thead className="tableFixHead" >
                               <tr>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>ID</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Name</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Company Name</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Branch</th>
                                   <th style={{ backgroundColor: '#f5f6fa' }}>Action</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.leaveSheet.map((value, index) => {
                                   return <tr key={index}>
                                       <td width="10%">{index + 1}</td>
                                       <td width="10%">{value.user_id}</td>
                                       <td width="30%">{value.COMPLETENAME}</td>
                                       <td width="30%">{value.company_name}</td>
                                       <td width="30%">{value.branch_name}</td>
                                       <td width="30%">
                                           <a tooltip="VIEW" href="javascript:;" data-target="#demo-lg-modal4" data-toggle="modal" className="btn btn-xs" onClick={()=>this.getLeaveshit(value)}>
                                               <i className="fa fa-2x fa-eye text-success"></i>
                                           </a>
                                       </td>
                                   </tr>
                               })}
                           </tbody>
                       </table>
                        }   

                    </Scrollbars> */}
                </div>
                <div id="demo-lg-modal1" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Date-Range</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <form onSubmit={this.FormSubmit}> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>From date :</label>
                                        <br />
                                        <DatePicker
                                            selected={this.state.from_date}
                                            selectsStart
                                            startDate={this.state.from_date}
                                            endDate={this.state.to_date}
                                            onChange={this.filterDateStart}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>To date :</label>
                                        <br />
                                        <DatePicker
                                            selected={this.state.to_date}
                                            selectsEnd
                                            startDate={this.state.from_date}
                                            endDate={this.state.to_date}
                                            onChange={this.filterDateEnd}
                                            className="form-control"
                                        />
                                    </div>

                                </div>
                                <button type="submit"
                                    className="bttnsub bg-green-lighter pull-right m-t-25">
                                    <span className="fa fa-folder-plus "></span>
                                </button>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div id="demo-lg-modal2" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>TIMESHEET</h4>
                                <button className="bttnclose pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <a href={"http://api.pacificweb.com.ph/Users/export_singleTimesheet/" + this.state.from + "/" + this.state.to + "/" + this.state.user_id} className="bttnexpo bg-green-lighter" style={{ marginBottom: '10px' }}>
                                <span className="fa fa-file-export  "></span>
                            </a>
                            <div className="modal-body">

                                <Scrollbars style={{ height: 400 }}>
                                    <table className="table" >
                                        <thead className="tableFixHead" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Date</th>
                                                {/* <th style={{ backgroundColor: '#f5f6fa' }}>Status</th> */}
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Time-in</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Time-out</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Time-in</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Time-out</th>
                                                {this.state.OBheader.map((val, index) => {
                                                    return <th key={index} style={{ backgroundColor: '#f5f6fa' }}>{val.headerOB}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.timeSheetAttendace.map((val, index) => {




                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.date_Attendance}</td>
                                                    {/* <td>present</td> */}
                                                    {this.state.time_type.map((val3, index3) => {
                                                        var value = "";
                                                        {
                                                            this.state.timeSheetAttendace.map((val2, indx2) => {
                                                                if (val2.date_Attendance == val.date_Attendance) {
                                                                    // console.log(index3+" "+val2.time_attendance.length)
                                                                    if ((index3 === 2 || index3 === 1) && val2.time_attendance.length === 2) {
                                                                        value = "";
                                                                    } else {
                                                                        if (val2.time_attendance.length === 2 && index3 === 3)
                                                                            value = val2.time_attendance[1]
                                                                        else
                                                                            value = val2.time_attendance[index3];
                                                                    }
                                                                }
                                                            })
                                                        }

                                                        var split_date = "";
                                                        var splitting = "";
                                                        var attendance = "";
                                                        var location = "";
                                                        if (value === undefined || value === "") {
                                                            split_date = ":-:";
                                                            splitting = split_date.split("-");
                                                            attendance = splitting[0];
                                                            location = splitting[1];
                                                        } else {
                                                            split_date = String(value);
                                                            splitting = split_date.split("-");
                                                            attendance = splitting[0];
                                                            location = splitting[1];
                                                        }
                                                        return <td key={index3}>
                                                            <p>{attendance} <br /> <i style={{ color: '#B53471' }}>{location}</i></p>
                                                        </td>
                                                    }


                                                    )}
                                                    {val.OB_Attendance != undefined &&
                                                        val.OB_Attendance.map((valOB, indexOB) => {
                                                            var splitting = valOB.date.split("-");
                                                            var attendance = splitting[0];
                                                            var location = splitting[1];
                                                            return <td key={indexOB}>
                                                                <p>{attendance} <br /> <i style={{ color: '#B53471' }}>{location}</i></p>
                                                            </td>
                                                        })

                                                    }
                                                    {val.OB_Attendance == undefined &&
                                                        this.state.OBheader.map((valOB, indexOB) => {
                                                            return <td key={indexOB}>
                                                                <p>: <br /> <i style={{ color: '#B53471' }}>:</i></p>
                                                            </td>

                                                        })
                                                    }

                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modal3" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>OFFICIAL BUSINESS</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 400 }}>
                                    <table className="table" >
                                        <thead className="tableFixHead" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Date File</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Date of Ob Start</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Date of Ob End</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Justification</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.obSheetAttendace.map((val, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.req_date_added}</td>
                                                    <td>{val.req_date_start}</td>
                                                    <td>{val.req_date_end}</td>
                                                    <td>{val.req_justification}</td>
                                                    <td>{val.status}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modal4" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>LEAVE</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 400 }}>
                                    <table className="table" >
                                        <thead className="tableFixHead" >
                                            <tr>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>No.</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Request Date</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Leave Start</th>
                                                <th style={{ backgroundColor: '#f5f6fa' }}>Leave End</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.leaveSheetAttendace.map((val, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.req_date_added}</td>
                                                    <td>{val.req_date_start}</td>
                                                    <td>{val.req_date_end}</td>

                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modal5" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>TOTAL EMPLOYEES PER BRANCH</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 300 }}>
                                    {this.state.branchBreakdown.map((val, index) => {
                                        var percent = val.count.length / this.state.countTotal * 100
                                        return <div key={index} className="widget-list-item rounded-0 p-t-3">
                                            <div className="widget-list-media icon">
                                                <i className="fa fa-circle text-gradient bg-gradient-green"></i>
                                            </div>
                                            <div className="widget-list-content">
                                                <div className="widget-list-title">{val.name}</div>
                                            </div>

                                            <div className="widget-list-action text-nowrap text-grey">
                                                <div className=" f-s-11"><i className="fa fa-user"></i> <span data-animation="number" data-value="262">{val.count.length}</span></div>
                                                <div className="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.79">{percent.toFixed(2)}</span>%</div>
                                            </div>
                                        </div>
                                    })}
                                </Scrollbars>

                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modal6" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>PRESENT EMPLOYEES PER BRANCH</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 300 }}>
                                    {this.state.branchBreakdown.map((val, index) => {
                                        var percent = val.presentCount.length / val.count.length * 100
                                        return <div key={index} className="widget-list-item rounded-0 p-t-3">
                                            <div className="widget-list-media icon">
                                                <i className="fa fa-circle text-gradient bg-gradient-blue"></i>
                                            </div>
                                            <div className="widget-list-content">
                                                <div className="widget-list-title">{val.name}</div>
                                            </div>

                                            <div className="widget-list-action text-nowrap text-grey">
                                                <div className=" f-s-11"><i className="fa fa-user"></i> <span data-animation="number" data-value="262">{val.presentCount.length}</span></div>
                                                <div className="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.79">{percent.toFixed(2)}</span>%</div>
                                            </div>
                                        </div>
                                    })}
                                </Scrollbars>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal7" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>LATE EMPLOYEES PER BRANCH</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 300 }}>
                                    {this.state.branchBreakdown.map((val, index) => {
                                        var percent = val.latecount.length / val.count.length * 100
                                        return <div key={index} className="widget-list-item rounded-0 p-t-3">
                                            <div className="widget-list-media icon">
                                                <i className="fa fa-circle text-gradient bg-gradient-orange"></i>
                                            </div>
                                            <div className="widget-list-content">
                                                <div className="widget-list-title">{val.name}</div>
                                            </div>

                                            <div className="widget-list-action text-nowrap text-grey">
                                                <div className="f-s-11"><i className="fa fa-user"></i> <span data-animation="number" data-value="262">{val.latecount.length}</span></div>
                                                <div className="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.79">{percent.toFixed(2)}</span>%</div>
                                            </div>
                                        </div>
                                    })}
                                </Scrollbars>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal8" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>ABSENT EMPLOYEES PER BRANCH</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 300 }}>
                                    {this.state.branchBreakdown.map((val, index) => {
                                        var percent = val.absentCount.length / val.count.length * 100
                                        return <div key={index} className="widget-list-item rounded-0 p-t-3">
                                            <div className="widget-list-media icon">
                                                <i className="fa fa-circle text-gradient bg-gradient-red"></i>
                                            </div>
                                            <div className="widget-list-content">
                                                <div className="widget-list-title">{val.name}</div>
                                            </div>

                                            <div className="widget-list-action text-nowrap text-grey">
                                                <div className="f-s-11"><i className="fa fa-user"></i> <span data-animation="number" data-value="262">{val.absentCount.length}</span></div>
                                                <div className="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.79">{percent.toFixed(2)}</span>%</div>
                                            </div>
                                        </div>
                                    })}
                                </Scrollbars>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Attendance;