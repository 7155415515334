import React, { Component } from 'react';
// import { getData } from '../../api/api';
import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import TextareaAutosize from 'react-autosize-textarea';
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
 
import $ from "jquery";
import swal from "sweetalert/dist/sweetalert.min.js";


class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor1: "#404040",
            bgcolor2: "transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            hide1: 0,
            reply: 0,
            company: "",
            companies: [],
            branches: [],
            departments: [],
            details: [],
            dept_id: "",
            department: "",
            selectBranch: [],
            select: [],
            branch_id: [],
            handler: "",
            selectedBranch: "",
            handlerName: [],
            selectLevel: "",
            selectStatus: "",
            selectStatus2: "",
            helpDeskData: [],
            totalProgress: [],
            totalDone: [],
            totalQueue: [],

            search2: '',
            search2Data: [],
            selectedCard: "Total",
            viewHelpDetails: [],
            users: [],
            getUserDetails: [],
            replyDescription: "",
            replies: []


        };
        this.onChangeSelectCompany = this.onChangeSelectCompany.bind(this);
        this.onChangeSelectBranch = this.onChangeSelectBranch.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.selectedCard = this.selectedCard.bind(this);
        this.submitReply = this.submitReply.bind(this);
        this.changeHelpStatus = this.changeHelpStatus.bind(this);
        this.updateStatus2 = this.updateStatus2.bind(this);

    }

    helpDeskData() {
        getData("helpdesk/getHelpDeskData", localStorage.getItem('u')).then(res => {

            this.setState({ helpDeskData: res });
            this.setState({ search2Data: res });

            let queue = [];
            let progress = [];
            let done = [];

            for (let i = 0; i < res.length; i++) {
                if (res[i].help_status === "Open") {
                    queue.push(res[i]);
                } else if (res[i].help_status === "Progress") {
                    progress.push(res[i]);
                } else if (res[i].help_status === "Closed") {
                    done.push(res[i]);
                }
            }

            this.setState({ totalDone: done });
            this.setState({ totalProgress: progress });
            this.setState({ totalQueue: queue });

        })
    }

    getUserDetails() {
        getData("helpdesk/getUserDetails", localStorage.getItem('u')).then(res => {
            this.setState({ getUserDetails: res });
        })
    }

    updateSearch(event) {
        this.setState({ search2: event.target.value.substr(0, 20) });
    }

    selectedCard(e) {
        let data = [];
        let total = this.state.helpDeskData;
        let queue = this.state.totalQueue;
        let progress = this.state.totalProgress;
        let done = this.state.totalDone;

        if (e.target.name === "Total") {
            for (let i = 0; i < total.length; i++) {
                data.push(total[i]);
            }
        } else if (e.target.name === "Open") {
            for (let i = 0; i < queue.length; i++) {
                data.push(queue[i]);
            }
        } else if (e.target.name === "Progress") {
            for (let i = 0; i < progress.length; i++) {
                data.push(progress[i]);
            }
        } else if (e.target.name === "Closed") {
            for (let i = 0; i < done.length; i++) {
                data.push(done[i]);
            }
        }

        this.setState({ search2Data: data });
    }

    viewHelpDetails(e) {
        let info = [];
        info.push(e);
        var data = { user_id: info[0].help_id }

        getData("helpdesk/getReplies", data).then(res => {
            this.setState({ replies: res.response })
        })
        this.setState({ viewHelpDetails: info });
    }



    componentDidMount() {
        this.getDetails();
        this.helpDeskData();
        this.getUserDetails();
    }

    getDetails() {

        let cid = document.getElementsByName("company");
        let bid = document.getElementsByName("selectedBranch");
        let hid = document.getElementsByName("handler");
        let dept = document.getElementsByName("department");
        let lvl = document.getElementsByName("selectLevel");
        let status = document.getElementsByName("selectStatus");

        cid[0].value = "";
        bid[0].value = "";
        hid[0].value = "";
        dept[0].value = "";
        lvl[0].value = "";
        status[0].value = "";


        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin" })
            .then(response => {
                this.setState({ companies: response.companies, departments: response.departments, users: response.users })
            })
    }

    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeSelectCompany(e) {
        let users = [];

        getData("users/getBranch", e.target.value)
            .then(result => {
                this.setState({ branches: result })
            });

        var x = document.getElementsByName("selectedBranch");
        x[0].value = "";

        var y = document.getElementsByName("handler");
        y[0].value = "";

    }

    onChangeSelectBranch(e) {

        let users = [];
        let dataUsers = this.state.users;

        for (let i = 0; i < dataUsers.length; i++) {
            if (e.target.value === dataUsers[i].branch_id) {
                users.push(dataUsers[i]);
            }
        }
        var y = document.getElementsByName("handler");
        y[0].value = "";

        this.setState({ handlerName: users });
    }

    handlerSubmit(e) {
        e.preventDefault();

        let cid = document.getElementsByName("company");
        let bid = document.getElementsByName("selectedBranch");
        let hid = document.getElementsByName("handler");
        let dept = document.getElementsByName("department");
        let lvl = document.getElementsByName("selectLevel");
        let status = document.getElementsByName("selectStatus");
        let details = this.state.viewHelpDetails;

        var data = {
            company_id: cid[0].value,
            branch_id: bid[0].value,
            department_id: this.state.department,
            handlername_id: hid[0].value,
            level: this.state.selectLevel,
            status: this.state.selectStatus,
            help_id: details[0].help_id,
        }

        details.forEach(element => {
            element['handler_fname'] = hid[0].options[hid[0].options.selectedIndex].text;
            element['help_status'] = status[0].options[status[0].options.selectedIndex].text;
        });

        getData("HelpDesk/addingHandler", data)
            .then(result => {
                if (result.res === "Success") {
                    swal("Successfully Assigned", "", "success");

                    cid[0].value = "";
                    bid[0].value = "";
                    hid[0].value = "";
                    dept[0].value = "";
                    lvl[0].value = "";
                    status[0].value = "";

                    this.helpDeskData();
                    $('#close-modal').click();

                } else {
                    swal("Failed to Assign Area", "", "error");
                }
            })
    }

    submitReply() {
        let user = this.state.getUserDetails;
        let details = this.state.viewHelpDetails;
        let data = {
            user: user[0].user_id,
            id: details[0].help_id,
            description: this.state.replyDescription,
            property: "admin"
        }
        let replyDes =  document.getElementsByName("replyDescription");

        getData("HelpDesk/submitReply", data).then(res => {
            swal("Successfully reply", "", "success");
            replyDes[0].value = "";
            this.setState({replyDescription: ""});

            var data = { user_id: details[0].help_id }
            getData("helpdesk/getReplies", data).then(res => {
                this.setState({ replies: res.response })
            })
        })


    }

    changeHelpStatus() {
        var details = this.state.viewHelpDetails
        let status = document.getElementsByName("selectStatus2");
        var data = { help_id: details[0].help_id, status: this.state.selectStatus2 }


        if(data.status !== ""){
            getData("helpdesk/changeHelpStatus", data).then(response => {
                if (response.response === true) {
                    $('#close-modal2').click();
                    details.forEach(element => {
                        element['help_status'] = status[0].options[status[0].options.selectedIndex].text;
                    });
                    this.getDetails();
                    this.helpDeskData();
                    swal("Status Updated Successfully", "", "success");
    
                } else {
                    swal("Status Updated Failed!", "", "error");
                }
            })
        }
    }

    updateStatus2(){
        let status = document.getElementsByName("selectStatus2");

        if(status.length !== 0){
            status[0].value = "";
            this.setState({selectStatus2: ""});
        }
        this.setState({selectStatus2: ""});
    }

    render() {

        let searchData = this.state.search2Data.filter(
            (help) => {
                return help.person_fname.toLowerCase().indexOf(
                    this.state.search2.toLowerCase()) !== -1 || help.person_lname.toLowerCase().indexOf(
                        this.state.search2.toLowerCase()) !== -1 || help.help_subject.toLowerCase().indexOf(
                            this.state.search2.toLowerCase()) !== -1 || help.help_status.toLowerCase().indexOf(
                                this.state.search2.toLowerCase()) !== -1 || help.handler_fname.toLowerCase().indexOf(
                                    this.state.search2.toLowerCase()) !== -1 || help.handler_lname.toLowerCase().indexOf(
                                        this.state.search2.toLowerCase()) !== -1;
            }
        )

        return (
            <div id="content" className="content">
                {this.state.hide1 == 0 &&
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="navmedia">
                                <div className="navtitle panel-title">
                                    <h4 className="page-header">Administrator</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Help Desk Admin</a></li>
                                            <li className="breadcrumb-item"><a href="/scrumboard/" style={{ textDecoration: 'none', cursor: 'default' }}>Help Desk List</a></li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="navnav">
                                    <div className="attendnav">
                                        <a href="#/helpdeskadmin" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}
                                            onClick={() => {
                                                this.setState({ bgcolor1: "#404040", txtcolor1: "white" });
                                                this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                                                this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                            }}>Tickets</a>
                                    </div>
                                    <div className="attendnav  m-r-30">
                                        <a href="#/scrumboard" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}
                                            onClick={() => {
                                                this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                                                this.setState({ bgcolor2: "#404040", txtcolor2: "white" });
                                                this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                            }}>Scrumboard</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div class="widget widget-stats bg-teal-transparent-9">
                                        <div class="stats-icon"><i class="far fa-clock"></i></div>
                                        <div class="stats-info">
                                            <h4>TICKETS&nbsp;OPEN</h4>
                                            <p className="text-white">{this.state.totalQueue.length}</p>
                                        </div>
                                        <div class="stats-link">
                                            <a href="javascript:;" name="Open" onClick={this.selectedCard}>Sort Table <i class="fa fa-arrow-alt-circle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="widget widget-stats bg-blue-transparent-9">
                                        <div class="stats-icon"><i class="far fa-hourglass"></i></div>
                                        <div class="stats-info">
                                            <h4>TICKETS&nbsp;PROGRESS</h4>
                                            <p className="text-white">{this.state.totalProgress.length}</p>
                                        </div>
                                        <div class="stats-link">
                                            <a href="javascript:;" name="Progress" onClick={this.selectedCard}>Sort Table <i class="fa fa-arrow-alt-circle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="widget widget-stats bg-green-transparent-9">
                                        <div class="stats-icon"><i class="far fa-check-circle"></i></div>
                                        <div class="stats-info">
                                            <h4>TICKETS&nbsp;CLOSED</h4>
                                            <p className="text-white">{this.state.totalDone.length}</p>
                                        </div>
                                        <div class="stats-link">
                                            <a href="javascript:;" name="Closed" onClick={this.selectedCard}>Sort Table <i class="fa fa-arrow-alt-circle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="widget widget-stats bg-blue-transparent-9">
                                        <div class="stats-icon"><i class="fas fa-ticket-alt"></i></div>
                                        <div class="stats-info">
                                            <h4>TOTAL&nbsp;TICKETS</h4>
                                            <p className="text-white">{this.state.helpDeskData.length}</p>
                                        </div>
                                        <div class="stats-link">
                                            <a href="javascript:;" name="Total" onClick={this.selectedCard}>Sort Table <i class="fa fa-arrow-alt-circle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group pull-right">
                                <div className="input-group pull-left" >
                                    <input type="text" className="form-control" id="search2" onChange={this.updateSearch.bind(this)} placeholder="Search" autocomplete="off" />
                                    <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                </div>
                            </div>
                            <Scrollbars style={{ height: 360 }}>
                                <table className="table table-bordered ">
                                    <thead className="tableFixHead ">
                                        <tr className="text-center">
                                            <th>ID</th>
                                            <th>Sender</th>
                                            <th>Subject</th>
                                            <th>Date&nbsp;Issued</th>
                                            <th>Assigned&nbsp;To</th>
                                            <th>Status</th>
                                            <th style={{ zIndex: 2 }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map(help =>
                                            <tr>
                                                <td>{help.help_id}</td>
                                                <td>{help.person_fname + " " + help.person_lname}</td>
                                                <td>{help.help_subject}</td>
                                                <td>{help.help_date_added}</td>
                                                <td>{help.handler_fname + " " + help.handler_lname}</td>
                                                <td>{help.help_status}</td>
                                                <td className="text-center">
                                                    <button className=" btttn fas fa-info-circle bg-blue-transparent-9 text-white"
                                                        onClick={() => {
                                                            this.viewHelpDetails(help)
                                                            this.setState({ hide1: 1 })
                                                        }
                                                        }>&nbsp;Details</button>
                                                </td>

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Scrollbars>
                        </div>
                    </div>
                }
                {this.state.hide1 == 1 &&
                    <div>
                        <div className="panel">
                            {this.state.viewHelpDetails.map(details =>
                                <div className="panel-body">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                            <div>
                                                <p>Subject: {details.help_subject}</p>
                                            </div>
                                            <div>
                                                <p className="far fa-user-circle">&nbsp;{details.person_email}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                            <div>
                                                <p>Status - {details.help_status}</p>
                                            </div>
                                            <div>
                                                <p className="far fa-user-circle">&nbsp;Assigned&nbsp;to :&nbsp;{details.handler_fname + " " + details.handler_lname}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <TextareaAutosize className="border-0" rows={4} style={{ width: '100%', textIndent: "10px", textDecoration: 'none', outline: 0 }} required
                                        placeholder={details.help_description}
                                        readOnly />
                                    <div className="btn-group">
                                        <button className="btttn bg-green-transparent-9 text-white fas fa-reply m-2" onClick={() => { this.setState({ reply: 1 }) }}>&nbsp;Reply</button>
                                        <button className="btttn bg-blue-transparent-9 text-white fas fa-plus-circle m-2" data-target="#demo-modal-handler" data-toggle="modal" onClick={() => { this.getDetails() }}>&nbsp;Handler</button>
                                        <button className="btttn bg-blue-transparent-9 text-white fas fa-cog m-2" data-target="#demo-modal-tickets" data-toggle="modal" onClick={this.updateStatus2}>&nbsp;Update&nbsp;Status</button>
                                        <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-2" onClick={() => { this.setState({ hide1: 0, reply: 0 }) }}>&nbsp;Back</button>
                                    </div>

                                </div>
                            )}

                        </div>
                        <div>
                            {this.state.replies.map((val, index) => {
                                return <div className="panel">
                                    <div className="panel-body">
                                        <form>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
                                                    <div>
                                                        <p className="far fa-user-circle">&nbsp;{val.user_fname}&nbsp;{val.user_lname}</p>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                                    <div>
                                                        <p className="far fa-calendar-alt">{moment(val.reply_added).format('lll')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <TextareaAutosize value={val.reply_description} className="border-0" rows={4} style={{ width: '100%', textIndent: "10px", textDecoration: 'none', outline: 0 }}
                                                readOnly />
                                        </form>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>


                }
                {this.state.reply == 1 &&
                    <div className="panel">
                        <div className="panel-body">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
                                    <div>
                                        <i className="far fa-caret-square-up m-r-15" />
                                    </div>
                                    <div>
                                        {this.state.viewHelpDetails.map(details =>
                                            <p className="far fa-user-circle">&nbsp;{details.person_email}</p>
                                        )}
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                    <div>
                                        {this.state.viewHelpDetails.map(details =>
                                            <p className="far fa-user-circle">&nbsp;Assigned&nbsp;to :&nbsp;{details.handler_fname + " " + details.handler_lname}</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <hr />
                            <TextareaAutosize className="border-0" rows={4} style={{ width: '100%', textIndent: "10px", textDecoration: 'none', outline: 0 }} required
                                placeholder='reply' name="replyDescription" onChange={this.textchange} />
                            <div className="btn-group">
                                <button className="btttn bg-green-transparent-9 text-white fas fa-check-circle m-2" onClick={this.submitReply}>&nbsp;Send</button>
                                <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-2" onClick={() => { this.setState({ reply: 0 }) }}>&nbsp;Cancel</button>
                            </div>
                        </div>
                    </div>
                }

                <div id="demo-modal-compose" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Ticket Form</h4>
                                <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="panel">
                                    <div className="panel-body">
                                        <form>
                                            <div class="form-group">
                                                <label>Subject:</label>
                                                <input className="form-control m-b-5" style={{ width: "100%" }} placeholder="Brief Summary of the question or issue" required />
                                            </div>
                                            <div class="form-group">
                                                <label>Type:</label>
                                                <select className="default-select2 form-control" style={{ width: "100%" }} required>
                                                    <option disabled selected>-Select Category-</option>
                                                    <option>Technical Support</option>
                                                    <option>Human Resources</option>
                                                    <option>Software Assistance</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Upload:</label>
                                                <input className="form-control" type="file" name="Upload Photo/File" id="fileToUpload"></input>
                                            </div>
                                            <div class="form-group">
                                                <label>Description:</label>
                                                <TextareaAutosize rows={4} style={{ border: "solid", borderWidth: 1, borderColor: "#bec6ce", width: '100%', textIndent: "10px", textDecoration: 'none', outline: 0, borderRadius: 5 }} required
                                                    placeholder='Detailed account of the question or issue' />
                                            </div>
                                            <button type="submits" className="btttn bg-green-transparent-9 fas fa-check-circle">&nbsp;Submit</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-modal-handler" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Assigning Form</h4>
                                <button id="close-modal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="panel">
                                    <div className="panel-body">
                                        <form onSubmit={this.handlerSubmit}>
                                            <div class="form-group">
                                                <label>Select Company:</label>
                                                <select className="form-control" name="company" onChange={this.onChangeSelectCompany} placeholder="Select Company" required>
                                                    <option disabled value="" selected>-Select Company-</option>
                                                    {this.state.companies.map((val, index) =>
                                                        <option key={index} value={val.company_id}>{val.company_name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Select Branch:</label>
                                                <select name="selectedBranch" className="form-control" onChange={this.onChangeSelectBranch} required>
                                                    <option disabled value="" selected>-Select Branch-</option>
                                                    {this.state.branches.map((val, index) =>
                                                        <option key={index} value={val.bid}>{val.bname}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Select Department:</label>
                                                <select className="form-control" name="department" onChange={this.textchange} required>
                                                    <option disabled value="" selected>-Select Department-</option>

                                                    {this.state.departments.map((val, index) =>
                                                        <option value={val.dept_id}>{val.dept_name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Select Handler:</label>
                                                <select className="form-control" name="handler" onChange={this.textchange} required>
                                                    <option value="" disabled selected>-Select Handler-</option>
                                                    {this.state.handlerName.map((val, index) =>
                                                        <option key={index} value={val.user_id}>{val.completename}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Priority Level:</label>
                                                <select onChange={this.textchange} name="selectLevel" className="default-select2 form-control" style={{ width: "100%" }} required>
                                                    <optgroup label="-Select Level-">
                                                        <option value="Low">Low</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="High">High</option>
                                                    </optgroup>

                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Select Status:</label>
                                                <select onChange={this.textchange} name="selectStatus" className="default-select2 form-control" style={{ width: "100%" }} required>
                                                    <optgroup label="-Select Status-">
                                                        <option value="Open">Open</option>
                                                        <option value="Progress">Progress</option>
                                                        <option value="Closed">Closed</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <button type="submit" className="btttn bg-green-transparent-9 fas fa-check-circle">&nbsp;Submit</button>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="demo-modal-tickets" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Update Ticket Status</h4>
                                <button id="close-modal2" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.viewHelpDetails.map(data => {
                                    return <div>
                                        <div class="form-group">
                                            <label>Subject:</label>
                                            <input className="form-control" value={data.help_subject} readOnly />
                                        </div>
                                        <div class="form-group">
                                            <label>Select Status:</label>
                                            {this.state.viewHelpDetails.map(data => {
                                                if (data.help_status === 'Open') {
                                                    return <div>
                                                        <select onChange={this.textchange} name="selectStatus2" className="default-select2 form-control" style={{ width: "100%" }} required>
                                                            <option disabled value="" selected>{data.help_status}</option>
                                                            <option value="Open">Open</option>
                                                            <option value="Progress">Progress</option>
                                                            <option value="Closed">Closed</option>
                                                        </select>
                                                        <button className="btttn bg-green-transparent-9 text-white fas fa-cog pull-right m-15"  onClick={() => { this.changeHelpStatus(data) }}>&nbsp;Update</button>

                                                    </div>

                                                } else if (data.help_status === 'Progress') {
                                                    return <div>
                                                        <select onChange={this.textchange} name="selectStatus2" className="default-select2 form-control" style={{ width: "100%" }} required>
                                                            <option disabled value="" selected>{data.help_status}</option>
                                                            <option value="Closed">Closed</option>
                                                        </select>
                                                        <button className="btttn bg-green-transparent-9 text-white fas fa-cog pull-right m-15" onClick={() => { this.changeHelpStatus(data) }}>&nbsp;Update</button>

                                                    </div>

                                                } else if (data.help_status === 'Closed') {
                                                    return <input className="form-control" value={data.help_status} readOnly />
                                                }
                                            })}

                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Admin;