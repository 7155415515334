import React, { Component } from 'react';
import {getDataFleet} from "../../api/api";
class Incident extends Component {
    constructor(props){
        super(props);
        this.state = {
            servicesMain: []
        }
    }
    _isMounted = true;


    services(){
        getDataFleet("services/getServices").then(result=> {
            this.setState({servicesMain : result.elf});
            console.log(result.elf); 
            let data = result.elf;
            let user = [];
            let driver = [];

            for(let i=0; i<data.length; i++){
                if(data[i].reporter === "User"){
                    user.push(data[i]);
                }else{
                    driver.push(data[i]);
                }
            }

            
        })
    }

    componentDidMount(){
        this.services();
        this.update();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "incident"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "incident"){
            setTimeout(() => {
                this.services();
                this.update();
            }, 500000);
        }else{
            clearTimeout(this.update);
        }
      }
    render() { 
        localStorage.setItem("pass", "incident")
        return ( 
            <div id="content" className="content">
                <div className="row">
                    <div className="col-md-3">
                        <div className="row">
                        
                            <div class="col-md-12 col-sm-12">
                                <div class="widget widget-stats bg-green">
                                <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                    <div class="stats-info">
                                        <h4>Users Incident</h4>
                                        <p>1</p>	
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div class="widget widget-stats bg-green">
                                <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                    <div class="stats-info">
                                        <h4>Drivers Incident</h4>
                                        <p>1</p>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="panel">
                            <div className="panel-body">
                                <div class="tableFixHead">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th style={{width: "5px"}}></th>
                                            <th>Trip Number</th>
                                            <th>Contact </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.servicesMain.map(map =>
                                            <tr>
                                                <td>{map.service.date}</td>
                                            </tr>
                                            )}
                                            {/* {this.state.userLoad.map(userLoad =>
                                                <tr>
                                                    <td><i class="fa fa-2x fa-bars pointer" data-target="#modal-user" data-toggle="modal" id={userLoad.user.user_id} onClick={this.viewDetails.bind(this)}></i></td>
                                                    <td>{userLoad.user.user_details.lname+", "+userLoad.user.user_details.fname+" "+userLoad.user.user_details.mname}</td>
                                                    <td>{userLoad.user.contact.mobile}</td>
                                                    
                                                </tr>
                                            )} */}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Incident;