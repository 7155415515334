import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
am4core.useTheme(am4themes_animated);
class DPie3 extends Component {
    state = { 
     }

    chart(){

        
        const {pieGraph} = this.props;
        // console.log(pieGraph)
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create("chartDPie3",am4charts.PieChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = pieGraph;
        chart.radius = am4core.percent(70);
        chart.innerRadius = am4core.percent(40);
        chart.startAngle = 180;
        chart.endAngle = 360;


        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "value";
        series.dataFields.category = "title";

        // series.slices.template.cornerRadius = 10;
        // series.slices.template.innerCornerRadius = 7;
        series.slices.template.draggable = false;
        series.slices.template.inert = true;

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;

        series.legendSettings.labelText = "[am4core.color('#fff')]{name}";

        series.colors.list = [
        am4core.color("#f8b553"),
        am4core.color("#32a932"),

       
        ];

        series.ticks.template.disabled = true;
        series.labels.template.disabled = true;

        chart.legend = new am4charts.Legend();
    }
    componentDidMount(){
     
        this.chart();
    }
    componentDidUpdate(){
        this.chart();
    }
    render() { 
        return ( 
            <div id="chartDPie3" style={{ width: "100%", height: "180px"}}></div>
         );
    }
}
 
export default DPie3;