import React, { Component } from 'react';
import * as $ from 'jquery';
import {getDataFleet, postDataFleet} from "../../api/api";
import Magnifier from 'react-magnifier';
import noimage from '../../apps/mis/img/nophoto.png';
import {Loader} from "react-overlay-loader"
import swal from "sweetalert/dist/sweetalert.min.js";
import { element, func } from 'prop-types';
import axios from 'axios';

class Vehicles extends Component {
    constructor(props){
        super(props);
        this.state = {
            vehicleList: [],
            pendingVehicles: [],
            loading: 'false',
            modalVehicles: [],
            modalPending:[],
            hideVehicles: false,
            hidePending: true,
            show: false,
            documents: [],
            uploadPics: null,
            file: null,
          
        }
        this.vehicleDetails = this.vehicleDetails.bind(this);
        this.pendingDetails = this.pendingDetails.bind(this);
        this.approvedVehicle = this.approvedVehicle.bind(this);
        this.deniedVehicle = this.deniedVehicle.bind(this);
        this.documents = this.documents.bind(this);

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    _isMounted = true;

    Vehicles(e){
        getDataFleet("Users/getUsers").then(result =>{
            if(result.response === "Success"){
                let data = result.elf;
                let driver = [];
                for(let i=0; i<data.length; i++){
                    if(data[i].employment.jobposition === "Fleet Driver"){
                        driver.push(data[i]);
                    }
                }
                let vehicles = [];
                let pendingVehicles = [];
                for(let i=0; i<driver.length; i++){
                    for(let b=0; b<driver[i].vehicle.length; b++){
                        if(driver[i].vehicle[b].validity === "0"){
                            pendingVehicles.push({'contact': driver[i].contact, 'employment': driver[i].employment, 'details': driver[i].user_details, 'vehicle': driver[i].vehicle[b]});
                        }else if(driver[i].vehicle[b].validity === "1") {
                            vehicles.push({'contact': driver[i].contact, 'employment': driver[i].employment, 'details': driver[i].user_details, 'vehicle': driver[i].vehicle[b]});
                        }
                    }
                }
                this.setState({pendingVehicles: pendingVehicles});
                this.setState({vehicleList: vehicles});
                this.setState({loading: "true"})        
            }
        })
    }

    onChange(e){
        this.setState({file:e.target.files[0]});
    }

    onFormSubmit(e){
        const formData = new FormData();
        formData.append('picture',this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        getDataFleet('services/fleetUpload',formData,config).then(result =>{
            console.log(result);
        })
    }

    hidePending(){
        this.setState({
            hidePending: false,
            hideVehicles: true,
        })
    }
    hideVehicles(){
        this.setState({
            hideVehicles: false,
            hidePending: true,
        })
    }

    vehicleDetails(e){
        var data = [];
        data.push(e);
        this.setState({modalVehicles: data});
    }

    pendingDetails(e){
        var data = [];
        data.push(e);
        this.setState({modalPending: data});
    }

    documents = e => {
        let data = e.target.files[0];
        let pics = [];

        for(let i=0; i<data.length; i++){
            pics.push(URL.createObjectURL(data[i]))
        }

        this.setState({
            documents: pics,
            uploadPics: data,
        })
    }



    approvedVehicle(e){

        var data = {
            vehicle_id: e.vehicle.vehicle_id,
            validity: 1,
            log: localStorage.getItem("u"),
            owner: e.vehicle.owner_id,
        }

        

        // getDataFleet("services/fleetVehicle", data).then(result =>{
        //     if(result.res === "Success"){
        //         swal("Success", "Successfully Approved Vehicle", "success",{
        //             button: "Done",
        //         });
        //         let data = this.state.pendingVehicles;
        //         let vehicle = this.state.vehicleList;
        //         let pending = [];
        //         data.forEach(element =>{
        //             if(element['vehicle'].vehicle_id === e.vehicle.vehicle_id){
        //                 element['vehicle'].validity = "1"
        //             }
        //         })

        //         for(let i=0; i<data.length; i++){
        //             if(data[i].vehicle.validity === "0"){
        //                 pending.push(data[i]);
        //             }else if(data[i].vehicle.validity === "1"){
        //                 vehicle.push(data[i]);
        //             }
        //         }
        //         this.setState({pendingVehicles: pending});
        //         this.setState({vehicleList: vehicle});

        //     }else{
        //         swal("Error", "Error Connecting to Database", "error",{
        //             button: "Done"
        //         });
        //     }
        // })

        const formData = new FormData();
        formData.append('picture', this.state.uploadPics);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axios.post('http://beta.api.pacificweb.com.ph/?key=12345ABCFleet/services/fleetUpload/'+e.vehicle.vehicle_id+"/"+e.vehicle.owner_id,formData,config)
            .then((result) =>{
                console.log(result);
            })
   
        
       
    }

    deniedVehicle(e){
        var data = { 
            vehicle_id: e.vehicle.vehicle_id,
            validity: 2,
            log: localStorage.getItem("u"),
            owner: e.vehicle.owner_id,
        }
        getDataFleet("services/fleetVehicle", data).then(result =>{
            if(result.res === "Success"){
                swal("Success", "Successfully Denied Vehicle", "success",{
                    button: "Done",
                });
                let data = this.state.pendingVehicles;
                let pending = [];
                data.forEach(element =>{
                    if(element['vehicle'].vehicle_id === e.vehicle.vehicle_id){
                        element['vehicle'].validity = "2"
                    }
                })

                for(let i=0; i<data.length; i++){
                    if(data[i].vehicle.validity === "0"){
                        pending.push(data[i]);
                    }
                }
                this.setState({pendingVehicles: pending});
               

            }else{
                swal("Error", "Error Connecting to Database", "error",{
                    button: "Done"
                });
            }
        })
    }

    componentDidMount(){
        this.Vehicles();
        this.update();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "vehicles"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "vehicles"){
            setTimeout(() => {
                this.Vehicles();
                this.update();
            }, 500000);
        }else{
            clearTimeout(this.update);
        }
      }


    render() { 
        localStorage.setItem("pass", "vehicles");
        var hideVehicles = {
			display: this.state.hideVehicles ? "none" : "block"
        }
        var hidePending = {
			display: this.state.hidePending ? "none" : "block"
        }
        return ( 
            <div id="content" className="content">
                {(this.state.loading === "false" &&
                    <Loader fullPage loading />
                )}
                 <div className="panel" style={{marginTop: "5px"}}>
                    <div className="panel-body">
                        <button className="pointer" onClick={this.hideVehicles.bind(this)}>Approved Vehicles</button><button className="pointer" onClick={this.hidePending.bind(this)}>Pending Vehicles</button>
                        <div class="tableFixHead" style={hideVehicles}>
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th style={{width: "5px"}}></th>
                                    <th>Vehicle</th>
                                    <th>Vehicle Type</th>
                                    <th>Driver</th>
                                    <th>Contact </th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.vehicleList.map((list, i) => 
                                    <tr key={i}>
                                        <td> <i class="fa fa-2x fa-bars pointer" data-target="#modal-vehicles" data-toggle="modal" id={list.vehicle.vehicle_id} onClick={() => this.vehicleDetails(list)}></i></td>
                                        <td>{list.vehicle.vehicle_name}</td>
                                        <td>{list.vehicle.vehicle_type}</td>
                                        <td>{list.details.lname+", "+list.details.fname+" "+list.details.mname}</td>
                                        <td>{list.contact.mobile}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                         <div class="tableFixHead" style={hidePending}>
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th style={{width: "5px"}}></th>
                                    <th>Vehicle</th>
                                    <th>Vehicle Type</th>
                                    <th>Driver</th>
                                    <th>Contact </th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.pendingVehicles.map((list, i) => 
                                    <tr key={i}>
                                        <td> <i class="fa fa-2x fa-bars pointer" data-target="#modal-pending" data-toggle="modal" id={list.vehicle.vehicle_id} onClick={() => this.pendingDetails(list)}></i></td>
                                        <td>{list.vehicle.vehicle_name}</td>
                                        <td>{list.vehicle.vehicle_type}</td>
                                        <td>{list.details.lname+", "+list.details.fname+" "+list.details.mname}</td>
                                        <td>{list.contact.mobile}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                
                <div class="modal fade" id="modal-vehicles">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">  
                                {this.state.modalVehicles.map(data =>
                                    <p>{data.details.lname+", "+data.details.fname+" "+data.details.mname}</p>
                                )}
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                       <center> <button className="pointer">Edit Details</button></center>
                                    </div>
                                    <div className="col-md-4">
                                    <center> <button className="pointer">Insert Images</button></center>
                                    </div>
                                    <div className="col-md-4">
                                    <center>   <button className="pointer">Edit Images</button></center>
                                    </div>
                                </div>

                                <div className="row">
                                
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="modal-pending">
                    <div class="modal-dialog modal-mm">
                        <div class="modal-content">
                            <div class="modal-header">  
                                {this.state.modalPending.map(data =>
                                    <p>{data.details.lname+", "+data.details.fname+" "+data.details.mname}</p>
                                )}
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table>
                                            {this.state.modalPending.map((modal, i) => 
                                                <tbody key={i}>
                                                <tr>
                                                    <td>Vehicle:</td>
                                                    <td><input type="text" className="form-control" name="jo_number" value={modal.vehicle.vehicle_name}></input></td>
                                                </tr>
                                                <tr>
                                                    <td>Vehicle type:</td>
                                                    <td><input type="text" className="form-control" name="meter_number" value={modal.vehicle.vehicle_type}></input></td>
                                                </tr>
                                                <tr>
                                                    <td>Plate No.:</td>
                                                    <td><input type="text" className="form-control" name="meter_reading" value={modal.vehicle.vehicle_plate_no}></input></td>
                                                </tr>
                                                <tr>
                                                    <td>Color:</td>
                                                    <td><input type="text" className="form-control" name="meter_reading" value={modal.vehicle.vehicle_color}></input></td>
                                                </tr>
                                                </tbody>
                                            )}
                                        </table>
                                    </div>

                                    <div className="col-md-12" style={{marginTop: "15px"}}>
                                        {/* <form onSubmit={this.onFormSubmit}>
                                            <input type="file" name="myImage" className="form-control" onChange= {this.onChange} />
                                            <button type="submit" className="btn btn-primary form-control">Upload</button>    
                                        </form> */}
                                        
                                            <center><input id="myfiles" multiple type="file" onChange={this.documents}/></center>
                                            {/* <button onClick={}>Uplaod</button> */}
                                    </div>
                                    
                                
                                    <div className="col-md-12" style={{marginTop: "15px"}}>
                                        {this.state.documents.map(doc =>
                                            <img src={doc} style={{height: "155px", width: "155px"}}/>
                                        )}
                                   
                                    </div>

                                    <div className="col-md-12" style={{marginTop: "15px"}}>
                                        {this.state.modalPending.map(modal =>
                                          <center><button onClick={() => this.approvedVehicle(modal)}>Approve</button><button onClick={() => this.deniedVehicle(modal)}>Denied</button></center>
                                        )}
                                    </div>
                        

                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
         );
    }
}
 
export default Vehicles;