import React, { Component } from 'react';
import {getDataFleet} from "../../api/api";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import Magnifier from 'react-magnifier';
import noimage from '../../apps/mis/img/nophoto.png';
import {Loader} from "react-overlay-loader"
import swal from "sweetalert/dist/sweetalert.min.js";


// import "../mis/css/style.css";
class Driver extends Component {
    constructor(props){
        super(props);
        this.state = {
            encryptServices: [],
            encryptLoad: [],
            encryptDrivers: [],
            hired: [],
            applicant: [],
            denied: [],
            searchDriver: '',
            searchApplicant: '',
            viewDetails: [],
            tripHistory: [],
            loadDetails: [],
            loadHistory: [],
            receiptPicture: [],
            soloPicture: [],
            success: 0,
            failed: 0,
            pending: 0,
            cancelled: 0,
            Tsub: 0,
            Tsuccess: 0,
            Tfailed: 0,
            viewLoad: [],
            viewTrip: [],
            historyimage: [],
            historysolo: [],
            viewLoadHistory: [],
            currentBalance: 0,
            loadWallet: 0,
            data1: "false",
            data2: "false",
            loading: "false",
            vehicleList: [],


        }
        this.viewLoad = this.viewLoad.bind(this);
        this.viewTrip = this.viewTrip.bind(this);
        this.viewLoadHistory = this.viewLoadHistory.bind(this);

    }

    _isMounted = true;


    services(){
        getDataFleet("services/getServices").then(result=> {
            this.setState({encryptServices : result.elf});
            this.setState({data1: "true"})
        })

    }

    load(){
        getDataFleet("billing/getLoadRequest").then(result => {
            this.setState({encryptLoad : result.elf});  
            this.setState({data2: "true"})
       })
   }

   drivers(){
        getDataFleet("Users/getUsers").then(result=>{
            if(result.response === "Success"){
                if(this.state.data1 === "true" && this.state.data2 === "true"){
                    const fleetDrivers = [];
                    const results = result.elf;
         
                    for(let i=0; i<results.length; i++){
                        if(results[i].employment.jobposition === "Fleet Driver"){
                            fleetDrivers.push(results[i]);
                        }
                    }
         
                    const hired = [];
                    const applicant = [];
                    const denied = [];
         
                    for(let i=0; i<fleetDrivers.length; i++){
                        if(fleetDrivers[i].employment.status === "Hired"){
                             hired.push(fleetDrivers[i]);
                        }else if(fleetDrivers[i].employment.status === "Applicant"){
                             applicant.push(fleetDrivers[i]);
                        }else if(fleetDrivers[i].employment.status === "Denied"){
                             denied.push(fleetDrivers[i]);
                        }
                    }
         
                    let load = this.state.encryptLoad;
                    const loadCount = [];
                    let count = 0;
                    for(let i=0; i<hired.length; i++){
                        const arrayL = [];
                        const arrayH = [];
                        for(let b=0; b<load.length; b++){
                             if(hired[i].user_id === load[b].id){
                                 if(load[b].load.status === "Pending"){
                                     arrayL.push(load[b]);
                                     count++;
                                 }else{
                                     arrayH.push(load[b]);
                                 }
                             }
                        }
                        loadCount.push({"drivers": hired[i], "load": arrayL, "history": arrayH });
                    }
                    this.setState({hired: loadCount});
                    this.setState({applicant: applicant});
                    this.setState({denied: denied});
         
                    this.setState({loadWallet: count}); 
                    this.setState({loading: "true"})        
                }
            }else{

            } 

       })
   }

   viewDetails(e){
       this.setState({
           success: 0,
           failed: 0,
           pending: 0,
           cancelled: 0,
           Tsuccess: 0,
           Tfailed: 0,
           incident: 0,
           Tsub: 0,
       })
        let hired = this.state.hired;
        const viewDetails = [];
        for(let i=0; i<hired.length; i++){
            if(hired[i].drivers.user_id === e.target.id){
                viewDetails.push(hired[i]);
            }
        }
        this.setState({viewDetails: viewDetails});
        let currentBalance = 0;
        for(let i=0; i<viewDetails.length; i++){
            currentBalance = viewDetails[i].drivers.user_details.balance;
        }
        this.setState({currentBalance: currentBalance});


        let load = [];
        for(let i=0; i<viewDetails.length; i++){
            for(let b=0; b<viewDetails[i].load.length; b++){
                load.push(viewDetails[i].load[b]);
            }
        }
       
        this.setState({loadDetails: load})

        let history = [];
        for(let i=0; i<viewDetails.length; i++){
            for(let b=0; b<viewDetails[i].history.length; b++){
                history.push(viewDetails[i].history[b]);
            }
        }
        this.setState({loadHistory: history});

        let image = [];
        let solo = [];

        for(let i=0; i<load.length; i++){
            // for(let b=0; b<load[i].load.pic.length; b++){
            //     image.push(load[i].load.pic[b]);
            // }
            for(let b=0; b<load[i].load.length; b++){
                image.push(load[i].load[b].pic)
            }
            // image.push(load[i].load.pic);
        }

        for(let i=0; i<1; i++){
           solo.push(image[i]);
        }
        this.setState({receiptPicture: image});
        this.setState({soloPicture: solo});

        let historyimage = [];
        let historysolo = [];

        for(let i=0; i<history.length; i++){
            // for(let b=0; b<load[i].load.pic.length; b++){
            //     image.push(load[i].load.pic[b]);
            // }
            for(let b=0; b<history[i].load.length; b++){
                history.push(history[i].load[b].pic)
            }
           
        }

        for(let i=0; i<1; i++){
           historysolo.push(historyimage[i]);
        }


        this.setState({historyimage: historyimage});
        this.setState({historysolo: historysolo});
        
        let services = this.state.encryptServices;
        const tripHistory = [];
        const incident = [];
        for(let i=0; i<services.length; i++){
            if(services[i].driver.driver_id === e.target.id){
                tripHistory.push(services[i]);
                incident.push(services[i].incident);
            }
        }
        this.setState({tripHistory: tripHistory});
      
        

        const report = [];
        for(let i=0; i<incident.length; i++){
            for(let b=0; b<incident[i].length; b++){
                if(incident[i][b].reporter !== "Driver"){
                    report.push(incident[i][b]);
                }
            }
        }

        this.setState({failed: report.length})
        const success = [];
        const cancelled = [];
        const pending = [];
        for(let i=0; i<tripHistory.length; i++){
            if(tripHistory[i].status !== "Cancelled"){
                success.push(tripHistory[i]);
            }else{
                cancelled.push(tripHistory[i]);
            }
        }
        this.setState({success: success.length});
        this.setState({cancelled: cancelled.length});


        const totalFailed = report.length / success.length * 100;
        const sub = success.length - report.length;
        const totalSuccess = sub / success.length * 100;

        const fixedFailed = totalFailed.toFixed(0);
        const fixedSucess = totalSuccess.toFixed(0);


        if(success.length === 0){
            this.setState({Tsuccess: 0});
            this.setState({Tfailed: 0});
        }else{
            this.setState({Tsuccess: fixedSucess});
            this.setState({Tfailed: fixedFailed});
            this.setState({Tsub: sub});
        }

        let drivers = [];
        for(let i=0; i<viewDetails.length; i++){
            drivers.push(viewDetails[i].drivers);
        }
        
        let vehicleList = [];
        for(let i=0; i<drivers.length; i++){
            for(let b=0; b<drivers[i].vehicle.length; b++){
                vehicleList.push(drivers[i].vehicle[b]);
            }
        }
        this.setState({vehicleList: vehicleList});
        console.log(vehicleList);
   }

   approveDriver(applicant){
        const data = {
            status: "Approved",
            id: applicant.user_id,
            updated_by : localStorage.getItem('u'),
        }

        getDataFleet("users/userApproval", data)
        .then(res => {
           if(res.res === "Success"){
                swal("Success", "Successfully Approved Driver!", "success",{
                    button: "Done"
                });
           }else{
                swal("Error", "Error Connecting to Database", "error",{
                    button: "Done"
                });
           }
        })
   }
   deniedDriver(applicant){
        const data = {
            status: "Denied",
            id: applicant.user_id,
            updated_by : localStorage.getItem('u'),
        }

        getDataFleet("users/userApproval", data)
        .then(res => {
          
        })
   }

   approveLoad(load){
        const data = {
            status : "Approved",
            id : load.load.id,
            updated_by : localStorage.getItem('u'),
            user_id :  load.id,
            amount : load.load.price
        }
        getDataFleet("billing/approveLoadRequest", data)
        .then(response => {
            let result = response;
        
            if(result.res === "Success"){
                swal("Success", "Successfully Added", "success",{
                    button: "Done"
                });
                let loads = this.state.loadDetails;
               
                loads.forEach(element => {
                    if(element['load'].id === load.load.id){
                        element['load'].status = "Approved"
                    }
                });
                this.setState({loadDetails: loads});

                let history = this.state.loadHistory;
                for(let i=0; i<loads.length; i++){
                    history.push(loads[i]);
                }
                this.setState({loadHistory: history});

                let currentBalance = this.state.currentBalance;
                var balance = parseInt(currentBalance) + parseInt(load.load.price);
                this.setState({currentBalance: balance})

                let loadWallet = this.state.loadWallet;
                let wallet = loadWallet - 1;
                this.setState({loadWallet: wallet});

            }else{
                swal("Error", "Error Connecting to Database", "error",{
                    button: "Done"
                });
            }
        });
   }

   deniedLoad(load){
    const data = {
        status : "Denied",
        id : load.load.id,
        updated_by : localStorage.getItem('u'),
        user_id :  load.id,
        amount : 0
    }
    getDataFleet("billing/approveLoadRequest", data)
    .then(response => {
        let result = response;
    
        if(result.res === "Success"){
            swal("Success", "Successfully Added", "success",{
                button: "Done"
            });
            let loads = this.state.loadDetails;
            loads.forEach(element => {
                if(element['load'].id === load.load.id){
                    element['load'].status = "Denied"
                }
            });
            this.setState({loadDetails: loads}); 

            let history = this.state.loadHistory;
            for(let i=0; i<loads.length; i++){
                history.push(loads[i]);
            }
            this.setState({loadHistory: history});
            
            let loadWallet = this.state.loadWallet;
            let wallet = loadWallet - 1;
            this.setState({loadWallet: wallet});    
        }else{
            swal("Error", "Error Connecting to Database", "error",{
                button: "Done"
            });
        }
    });
}

   viewLoad(e){
       let load = this.state.loadDetails;
       const data =[];
       for(let i=0; i<load.length; i++){
           if(load[i].load.id === e.target.id){
            data.push(load[i]);
           }
       }
       this.setState({viewLoad: data});
   }

   viewTrip(e){
        let trip = this.state.tripHistory;
        const data = [];
        for(let i=0; i<trip.length; i++){
            if(trip[i].id === e.target.id){
                data.push(trip[i]);
            }
        }
        this.setState({viewTrip: data});
   }

   viewLoadHistory(e){
    let load = this.state.loadHistory;
    const data =[];
    for(let i=0; i<load.length; i++){
        if(load[i].load.id === e.target.id){
         data.push(load[i]);
        }
    }
    this.setState({viewLoadHistory: data});
    }
   






    componentDidMount(){
        
        this.load();
        this.services();
        this.drivers();
        this.update();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "driver"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "driver"){
            setTimeout(() => {
                this.load();
                this.services();
                this.drivers();
                this.update();
            }, 500000);
        }else{
            clearTimeout(this.update);
        }
      }

    searchDriver(event){
        this.setState({searchDriver : event.target.value.substr(0, 20)});
    }
    searchApplicant(event){
        this.setState({searchApplicant : event.target.value.substr(0, 20)});
    }



    render() {
        localStorage.setItem("pass", "driver");
        let filterDriver = this.state.hired.filter(
            (hired) => {
                return hired.drivers.user_details.fname.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        let filterApplicant = this.state.applicant.filter(
            (applicant) => {
                return applicant.user_details.fname.toLowerCase().indexOf(
                    this.state.searchApplicant.toLocaleLowerCase()) !== -1
            }
        )
      
        return ( 
            <div id="content" className="content">
                {(this.state.loading === "false" &&
                    <Loader fullPage loading />
                )}
                <div className="row">
                    <div className="col-md-3">
                    
                        <div class="col-md-12 col-sm-12">
                            <div class="widget widget-stats bg-green">
                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                <div class="stats-info">
                                    <h4>Activated Drivers</h4>
                                    <p>{this.state.hired.length}</p>	
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="widget widget-stats bg-green">
                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                <div class="stats-info">
                                    <h4>Load Wallet</h4>
                                    <p>{this.state.loadWallet}</p>	
                                </div>
                            </div>
                        </div>
                      
                        {/* <div class="col-md-12 col-sm-12">
                           
                                <legend>Vehicles</legend>
                                <div className="row">
                                    <ul>
                                        <li>Sedan</li>
                                        <li>Van</li>
                                    </ul>
                                </div>
                      
                        </div>  */}
                           
                    </div>
                    <div className="col-md-9">
                    <div className="panel">
                    <div className="panel-body">

                     <Tabs>
                        <TabList>
                        <Tab>Drivers Table</Tab>
                        <Tab>Waiting for validation</Tab>
                        </TabList>
                    
                        <TabPanel>
                        <div className="panel" style={{marginTop: "5px"}}>
                            <div className="panel-body">
                                <div class="tableFixHead">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th style={{width: "5px"}}></th>
                                            <th>Driver</th>
                                            <th>Contact </th>
                                            <th>Vehicle</th>
                                            <th>Vehicle Type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {filterDriver.map((driver) =>{
                                                if(driver.load.length === 0)
                                                    return  <tr key={driver.drivers.user_id}>
                                                    {/* <td><Badge color="info">{driver.load.length}</Badge></td> */}
                                                    <td> <i class="fa fa-2x fa-bars pointer" data-target="#demo-lg-modal" data-toggle="modal" id={driver.drivers.user_id} onClick={this.viewDetails.bind(this)}></i></td>
                                                    <td>{driver.drivers.user_details.lname}, {driver.drivers.user_details.fname} {driver.drivers.user_details.mname}</td>
                                                    <td>{driver.drivers.contact.mobile}</td>
                                                    <td>{driver.drivers.vehicle.name}</td>
                                                    <td>{driver.drivers.vehicle.type}</td>
                                          
                              
                                                    {/* <td><button className="btn-view" onClick={this.toggle} id={driver.drivers.user_id}>View</button></td> */}
                                                    {/* <td><p><a href="javascript:void(0);" id={driver.drivers.user_id} onClick={this.toggle} style={{textDecoration: "underline"}}>Details</a></p></td> */}
                                                   </tr>
                                                   return  <tr key={driver.drivers.user_id} style={{backgroundColor: "#f7f1e3"}}>
                                                   {/* <td><Badge color="info">{driver.load.length}</Badge></td> */}
                                                   <td>  <i class="fa fa-2x fa-bars pointer" data-target="#demo-lg-modal" data-toggle="modal" id={driver.drivers.user_id} onClick={this.viewDetails.bind(this)}></i> </td>
                                                   <td>{driver.drivers.user_details.lname}, {driver.drivers.user_details.fname} {driver.drivers.user_details.mname}</td>
                                                   <td>{driver.drivers.contact.mobile}</td>
                                                   <td>{driver.drivers.vehicle.name}</td>
                                                   <td>{driver.drivers.vehicle.type}</td>
                                               
                                           
                                                   {/* <td><button className="btn-view" onClick={this.toggle} id={driver.drivers.user_id}>View</button></td> */}
                                                   {/* <td><p><a href="javascript:void(0);" id={driver.drivers.user_id} onClick={this.toggle} style={{textDecoration: "underline"}}>Details</a></p></td> */}
                                               </tr>
                                                
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        </TabPanel>
                        <TabPanel>
                        <div className="panel" style={{marginTop: "5px"}}>
                            <div className="panel-body">
                            <div className="search alignright" style={{margin: 9}}>
                            <span className="fa fa-search"></span>
                            <input placeholder="Search" value={this.state.searchApplicant} onChange={this.searchApplicant.bind(this)}/>
                            </div>
                                <div class="tableFixHead">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{width: "5px"}}></th>
                                                <th>Driver</th>
                                                <th>Contact </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {filterApplicant.map((driver) => 
                                            <tr key={driver.user_id} hidden={driver.hidden}>
                                                <td> <i class="fa fa-2x fa-bars pointer"  data-target="#modal-validation" data-toggle="modal" id={driver.user_id}></i></td>
                                                <td>{driver.user_details.lname}, {driver.user_details.fname} {driver.user_details.mname}</td>
                                                <td>{driver.contact.mobile}</td>
                                                {/* <td>{driver.vehicle.status}</td> */}
                                                {/* <td><button className="btn-view" onClick={this.applicantDetails} id={driver.user_id}>View</button><button className="btn-approve" onClick={() => this.approveDriver(driver)}>Approve</button><button className="btn-decline" onClick={() => this.deniedDriver(driver)}>Decline</button></td> */}
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs>


        
                    
                    </div>

                    	<div class="modal fade" id="demo-lg-modal">
								<div class="modal-dialog modal-lg">
									<div class="modal-content">
										<div class="modal-header">  
                                            {this.state.viewDetails.map(details =>
                                            <div>
                                            	<h4 class="modal-title">{details.drivers.user_details.fname}</h4>
                                                <h4 class="modal-title">Current Balance: {this.state.currentBalance}</h4>
                                            </div>
                                            )}
                                            {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button> */}
										</div>
										<div class="modal-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="fa fa-bookmark fa-3x text-info"></div>
                                                            <div className="ml-3">
                                                                <div className="small">Total Trips</div>
                                                                <div className="text-large">{this.state.success}</div>
                                                                <div className="stats-desc small">Cancelled Trips : {this.state.cancelled}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="div-left-right mb-4">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center">
                                                                <div className="fa fa-thumbs-up fa-3x text-success"></div>
                                                                <div className="ml-3">
                                                                    <div className="text-muted small">Successful Trips</div>
                                                                    <div className="text-large">{this.state.Tsuccess+"%"}</div>
                                                                    <div className="stats-desc text-muted small">Total : {this.state.Tsub}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="fa fa-times fa-3x text-danger"></div>
                                                            <div className="ml-3">
                                                                <div className="text-muted small">Failed Trips</div>
                                                                <div className="text-large">{this.state.Tfailed+"%"}</div>
                                                                <div className="stats-desc text-muted small">Total : {this.state.failed}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                <Tabs>
                                                    <TabList>
                                                    <Tab>Trip History</Tab>
                                                    <Tab>Load Wallet Request</Tab>
                                                    <Tab>Load Wallet History</Tab>
                                                    </TabList>
                                                
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <div class="tableFixHead">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                <tr>
                                                                    <th style={{width: "5px"}}></th>
                                                                    <th>Requestor</th>
                                                                    <th>Address </th>
                                                                    <th>Status</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.tripHistory.map(trip =>
                                                                        <tr>
                                                                            <td><i class="fa fa-2x fa-bars pointer" id={trip.id} onClick={this.viewTrip} data-target="#modal-trip" data-toggle="modal"></i></td>
                                                                            <td>{trip.user_details.fname}</td>
                                                                            <td>{trip.location.place}</td>
                                                                            <td>{trip.status}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <div class="tableFixHead">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                <tr>
                                                                    <th style={{width: "5px"}}></th>
                                                                    <th>Mode </th>
                                                                    <th>Receipt</th>
                                                                    <th>Date</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.loadDetails.map(load =>{
                                                                        if(load.load.status === "Pending")
                                                                        return <tr>
                                                                            <td><i class="fa fa-2x fa-bars pointer" id={load.load.id} onClick={this.viewLoad} data-target="#modal-load" data-toggle="modal"></i></td>
                                                                            <td>{load.load.mode}</td>
                                                                            <td>{load.load.receipt}</td>
                                                                            <td>{load.load.date}</td>
                                                                            <td>{load.load.status}</td>
                                                                        </tr>
                                                                    }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <div class="tableFixHead">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                <tr>
                                                                    <th style={{width: "5px"}}></th>
                                                                    <th>Mode </th>
                                                                    <th>Receipt</th>
                                                                    <th>Date</th>
                                                                    <th>Status</th>
                                                            
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.loadHistory.map(load =>{
                                                                        if(load.load.status !== "Pending")
                                                                        return <tr>
                                                                            <td><i class="fa fa-2x fa-bars pointer" id={load.load.id} onClick={this.viewLoadHistory} data-target="#modal-loadHistory" data-toggle="modal"></i></td>
                                                                            <td>{load.load.mode}</td>
                                                                            <td>{load.load.receipt}</td>
                                                                            <td>{load.load.date}</td>
                                                                            <td>{load.load.status}</td>
                                                                        </tr>
                                                                    }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                </Tabs>
                                                </div>
                                            </div>

                                            <div className="row" style={{marginBottom: "15px"}}>
                                                <div className="col-md-12">
                                                <fieldset>
                                                    <legend>My Vehicles</legend>
                                                    <ul class="media-list media-list-with-divider">
                                                        {this.state.vehicleList.map(vehicle =>
                                                         <li class="media media-mm">
                                                            <a class="media-left" href="javascript:;">
                                                                <img src={noimage} alt="" class="media-object"/>
                                                            </a>
                                                            <div class="media-body">
                                                                <h4 class="media-heading">{vehicle.vehicle_name}</h4>
                                                                <p></p>
                                                                {/* <p>
                                                                    <a href="javascript:;" class="btn btn-sm btn-danger m-r-5">Reject</a>
                                                                    <a href="javascript:;" class="btn btn-sm btn-default">Cancel</a>
                                                                </p> */}
                                                            </div>
                                                        </li>
                                                        )}
                                                    </ul>
                                                </fieldset>
                                                </div>
                                            </div>


										</div>
										<div class="modal-footer">
											<a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
									
										</div>
									</div>
								</div>
							</div>

                            <div class="modal fade" id="modal-validation">
								<div class="modal-dialog modal-lg">
									<div class="modal-content">
										<div class="modal-header">  
                                           
										</div>
										<div class="modal-body">
                                     
                                        {this.state.applicant.map(applicant =>
                                        <div>
                                        <legend>Applicant Details</legend>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div class="form-group">
                                                    <label for="exampleInputEmail1">Firstname</label>
                                                    <input type="text" class="form-control" id="exampleInputEmail1" value={applicant.user_details.fname}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="form-group">
                                                    <label for="exampleInputPassword1">Middlename</label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.user_details.mname} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="form-group">
                                                    <label for="exampleInputPassword1">Lastname </label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.user_details.lname} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Gender</label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.user_details.gender}/>
                                                </div>   
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="form-group">
                                                    <label for="exampleInputPassword1">Civil status</label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.user_details.civilstatus}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="form-group">
                                                    <label for="exampleInputPassword1">Nationality</label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.user_details.nationality}/>
                                                    </div>  
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div class="form-group">
                                                    <label for="exampleInputPassword1">Email</label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.contact.email}/>
                                                    </div>  
                                                </div>
                                                <div className="col-md-6">
                                                    <div class="form-group">
                                                    <label for="exampleInputPassword1">Contact number</label>
                                                    <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.contact.mobile}/>
                                                    </div>  
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Address</label>
                                                <input type="text" class="form-control" id="exampleInputPassword1" value={applicant.user_details.address}/>
                                            </div>
                                          
                                            </div>
                                        )}
                                            
										</div>
										<div class="modal-footer">
                                            {this.state.applicant.map(applicant =>
                                            <div>
                                            <a href="javascript:;" class="btn btn-sm btn-white" onClick={() => this.deniedDriver(applicant)}>Denied</a>
											<a href="javascript:;" class="btn btn-sm btn-white"  onClick={() => this.approveDriver(applicant)}>Approved</a>
                                            </div>
                                            )}
                                            
										</div>
									</div>
								</div>
							</div>

                            <div id="modal-load" className="modal fade" tabindex="-1">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div class="modal-header">
                                        {/* {this.state.modalData.map(data =>
                                            <h5 class="modal-title">Fieldman: {data.user_fname} {data.user_lname}</h5>
                                        )} */}
                                         <h5 class="modal-title"> </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                       

                                        </div>
                                        <div class="modal-body">
                                            <div className="row">
                                                <div class="col-md-7">
                                                {this.state.soloPicture.map((solo, i) =>{
                                                    if(solo === undefined || solo === "")
                                                    return <div><Magnifier mgShape="square"  src={noimage} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/></div>
                                                    return <div><Magnifier mgShape="square"  src={solo.pic} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/>
                                                                <div style={{marginTop: 15, overflowX:"scroll", whiteSpace: "nowrap", height: 75, width: 435}}>
                                                                    {this.state.receiptPicture.map(img=>
                                                                        <img className="pointer" src={img.pic} style={{width : 55, height : 55, marginLeft : 11}} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                })}
                                                    
                                                </div>
                                                <div class="col-md-5">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {this.state.viewLoad.map(load =>
                                                         <center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={() => this.approveLoad(load)}>Approved</button> <button className="btn btn-sm btn-danger m-r-9 pointer" onClick={() => this.deniedLoad(load)}>Denied</button></center>
                                                        )}
                                                        
                                                    </div>
                                                    <div class="col-md-12"><p> </p></div>
                                                        <div class="col-md-12">
                                                            <div>
                                                                <table className="table table-border">
                                                                    {this.state.viewLoad.map(load =>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Mode:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.mode}></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Receipt:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.receipt
                                                                                }></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Price:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.price}></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Date:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.date}></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Status:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.status}></input></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )}

                                                                </table>
                                                            </div>
                                                    </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
                                        </div>

                                    </div>
                                </div>
                            </div>

                             <div class="modal fade" id="modal-trip">
								<div class="modal-dialog modal-mm">
									<div class="modal-content">
										<div class="modal-header">  
                                           
										</div>
										<div class="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                <div>
                                                    <table className="table table-border">
                                                            {this.state.viewTrip.map(trip =>
                                                             <tbody>
                                                             <tr>
                                                                 <td>Requestor:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.user_details.fname+" "+trip.user_details.mname+" "+trip.user_details.lname}></input></td>
                                                             </tr>
                                                             <tr>
                                                                 <td>Service type:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.service.type}></input></td>
                                                             </tr>
                                                             <tr>
                                                                 <td>Payment method:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.service.payment_method}></input></td>
                                                             </tr>
                                                             <tr>
                                                                 <td>Date of service:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.service.date_service}></input></td>
                                                             </tr>
                                                             <tr>
                                                                 <td>Vehicle used:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.service.vehicle}></input></td>
                                                             </tr>
                                                             <tr>
                                                                 <td>Fare:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.service.fare}></input></td>
                                                             </tr>
                                                             <tr>
                                                                 <td>Status:</td>
                                                                 <td><input type="text" className="form-control" name="tableID" value={trip.status}></input></td>
                                                             </tr>
                                                            </tbody>
                                                            )}
                                                    </table>
                                                </div>   
                                                </div>
                                                <div className="col-md-12">
                                                <center><button className="btn btn-sm btn-success m-r-9 pointer">View Map</button> <button className="btn btn-sm btn-danger m-r-9 pointer">View Incidents</button></center>
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="modal-loadHistory" className="modal fade" tabindex="-1">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div class="modal-header">
                                        {/* {this.state.modalData.map(data =>
                                            <h5 class="modal-title">Fieldman: {data.user_fname} {data.user_lname}</h5>
                                        )} */}
                                         <h5 class="modal-title"> </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                       

                                        </div>
                                        <div class="modal-body">
                                            <div className="row">
                                                <div class="col-md-7">
                                                {this.state.historysolo.map((solo, i) =>{
                                                    if(solo === undefined || solo === "")
                                                    return <div><Magnifier mgShape="square"  src={noimage} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/></div>
                                                    return <div><Magnifier mgShape="square"  src={solo.pic} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/>
                                                                <div style={{marginTop: 15, overflowX:"scroll", whiteSpace: "nowrap", height: 75, width: 435}}>
                                                                    {this.state.historyimage.map(img=>
                                                                        <img className="pointer" src={img.pic} style={{width : 55, height : 55, marginLeft : 11}} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                })}
                                                    
                                                </div>
                                                <div class="col-md-5">
                                                <div className="row">
                                                    {/* <div className="col-md-12">
                                                        {this.state.viewLoad.map(load =>
                                                         <center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={() => this.approveLoad(load)}>Approved</button> <button className="btn btn-sm btn-danger m-r-9 pointer" onClick={() => this.deniedLoad(load)}>Denied</button></center>
                                                        )}
                                                        
                                                    </div> */}
                                                    {/* <div class="col-md-12"><p> </p></div> */}
                                                        <div class="col-md-12">
                                                            <div>
                                                                <table className="table table-border">
                                                                    {this.state.viewLoadHistory.map(load =>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Mode:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.mode}></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Receipt:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.receipt
                                                                                }></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Price:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.price}></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Date:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.date}></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Status:</td>
                                                                                <td><input type="text" className="form-control" name="tableID" value={load.load.status}></input></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )}

                                                                </table>
                                                            </div>
                                                    </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
                                        </div>

                                    </div>
                                </div>
                            </div>

                </div>
                </div>
                </div>
            </div>
         );
    }
}
 
export default Driver;