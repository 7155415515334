import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { getData } from "../../../api/api";
import axios, { post } from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';

class Upload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fieldAudit: [],
			startDate: new Date(),
			endDate: new Date(),
			file: [],
			todaydate: "",
			branches: [],
			branch: "",
			fileuploadname: [],
			fieldman:[],
			fieldman_name:[],
			filterStartDate: new Date(),
		}
		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onChangeBranch = this.onChangeBranch.bind(this);
		this.onChangeBranch2 = this.onChangeBranch2.bind(this);
	}
	_isMounted = true;

	filterDateStart = e => {
console.log(e);
		var from = new Date(e);
		var data = from.toISOString();
		var dataFrom = data.split("T")
		this.setState({
				filterStartDate: e
		});
	

}
	onChange(e) {

		this.setState({ file: e.target.files })
		alert("Check the date before clicking submit button.")


	}
	onChangeDate = (event) => {
		this.setState({ todaydate: event.target.value });
	}
	fileUpload(file) {


		const url = 'http://beta.pacificweb.com.ph/field_audit/upload_excel/' + this.state.todaydate + "/" + this.state.branch;
		const formData = new FormData();


		for (let i = 0; i < this.state.file.length; i++) {
			formData.append('file' + i, file[i])
		}

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		return post(url, formData, config)

	}
	onFormSubmit(e) {
		e.preventDefault() // Stop form submit

		if (this.state.file.length > 9) {

			alert("Upload 9 files only.");
		}
		else {


			this.fileUpload(this.state.file).then((response) => {

				console.log((response.data));
				this.setState({ fileuploadname: response.data })
				alert("Data Uploaded");

			}).catch(err => {
				this.setState({ loading: true })

				alert("Failed to Upload");
			});


		}

	}
	branch() {
		getData("field_audit/getMISbranch").then(response => {
			console.log(response)
			this.setState({ branches: response.elf, fileuploadname: response.filename_upload ,fieldman:response.fieldman })
		})
	}
	componentWillMount() {
		this.branch();

	}

	onChangeBranch(e) {
		var options = e.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}

		value.forEach(element => {
			this.setState({ branch: element });

		});

	}
	onChangeBranch2(e) {
		var options = e.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}

		value.forEach(element => {
			this.setState({ branch: element });
			var data=[]
			for(let i=0;i<this.state.fieldman.length;i++){

				if(this.state.fieldman[i].branch_name == element){
					data.push(this.state.fieldman[i].user_fname+" "+this.state.fieldman[i].user_lname)
				}
			}
			this.setState({ fieldman_name: data });
		});

	}
	render() {

		return (

			<div id="content" className="content">
												<div className="row">{/*start row*/}
					<div className="col-md-3">{/*col-md-4*/}
						<div className="panel">
							<form onSubmit={this.onFormSubmit}>
								{/* <div className="panel-heading"> */}
									{/* <h3 className="panel-title">Please Select File</h3> */}
								{/* </div> */}
								<div className="panel-body">

									{/* <label for="file">Maximum excel File can be Upload(3)</label> */}
									{/* <br></br> */}
									<h5>Select Branch</h5>
									<select className="form-control" onChange={this.onChangeBranch}>
										<option value="All"></option>
										{this.state.branches.map((branch, index) =>
											<option value={branch.name}>{branch.name}</option>

										)}
									</select>
									{/* <br></br> */}
									<h3 className="panel-title">Please Select File</h3>
									<input type="file" className="form-control" name="dupload" id="dupload" onChange={this.onChange} multiple />
									<hr></hr>
									<input type="date" className="form-control" name="date" dateFormat="YYYY-MM-DD" onChange={this.onChangeDate} required />

									<br></br>
									<button className="btn btn-block xs-btn btn-info" type="submit">SUBMIT</button>

								</div>
							</form>
						</div>
					</div>{/*end col-md3*/}
					<div className="col-md-9">{/*start col-md8*/}
						<div className="panel">
							<div className="panel-body">
								<div className="pad-btm form-inline">
									<div className="row">
										<div className="col-sm-12 table-toolbar-right">
											<div className="form-group">

											</div>
										</div>
									</div>
								</div>
								<div className="table-responsive panelheight">
								<Scrollbars style={{ height: 400 }}>
									<table className="table table-striped">
										<thead>
											<tr>
												<th style={{ color: "#130f40" }}>No.</th>
												<th style={{ color: "#130f40" }}>Filename</th>
												<th style={{ color: "#130f40" }}>Uploaded Date</th>
											</tr>
										</thead>
										<tbody>
											{this.state.fileuploadname.map((data, index) => {
												return <tr key={index}>
													<td style={{ color: "#130f40" }}>{index + 1}</td>
													<td style={{ color: "#130f40" }}>{data.file_name}</td>
													<td style={{ color: "#130f40" }}>{data.date_posted}</td>
												</tr>

											})}

										</tbody>
									</table>
									</Scrollbars>
								</div>
							</div>
						</div>
					</div>{/*end col-md8*/}

				</div>{/*end row*/}
			{/* <button  data-target="#demo-lg-modal2" data-toggle="modal" className="btn btn-info btn-xs">Upload Fo</button>
			<hr/>
				<div id="demo-lg-modal2" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"><i className="pci-cross pci-circle"></i></button>
                                <h4 className="modal-title" id="myLargeModalLabel">Upload FO</h4>
                            </div>
                            <div className="modal-body">


					

                                <hr />
                              

                            </div>
                        </div>
                    </div>
                </div> */}
			
			</div>


		);
	}
}

export default Upload;