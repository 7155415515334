import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import Gauges from '../../maynilad/dashboard/charts/accomplishments'
import Accomplished from '../chart'
class Meter extends Component {
    state = {  }
    render() { 
        return ( 
               <div id="content" className="content">


            <div className="row">
                <div className="col-md-4">
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="panel-heading-btn"></div>
                            <h4 className="panel-title">Filter</h4>
                        </div>
                        <div className="panel-body">
                            <form className="form-horizontal form-bordered">
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label">Date</label>
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <DatePicker
                                                    selected={this.state.filterStartDate}
                                                    selectsStart
                                                    startDate={this.state.filterStartDate}
                                                    onChange={this.filterDateStart}
                                                    className="form-control"
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-3">Branch</label>
                                    <div className="col-md-9">
                                        <select className="form-control" onChange={this.showBYbranch}>
                                            <option value=""></option>
                                            <option value="Cainta">Cainta</option>
                                            <option value="Mandaluyong">Mandaluyong</option>
                                            <option value="Rosario">Rosario</option>
                                            
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="panel">
                        <div className="panel-heading">
                            <div className="panel-heading-btn">
                            </div>
                            <h4 className="panel-title">Choose 2 to compare</h4>
                        </div>
                        {/* <div className="panel-body"> */}
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td class="with-checkbox">
                                            <div class="checkbox checkbox-css">
                                                <input type="checkbox" id="table_checkbox_2" value="" checked=""/>
                                                <label for="table_checkbox_2"></label>
                                            </div>
                                        </td>
                                        <td>Received</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td class="with-checkbox">
                                            <div class="checkbox checkbox-css">
                                                <input type="checkbox" id="table_checkbox_2" value="" checked=""/>
                                                <label for="table_checkbox_2"></label>
                                            </div>
                                        </td>
                                        <td>Return</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td class="with-checkbox">
                                            <div class="checkbox checkbox-css">
                                                <input type="checkbox" id="table_checkbox_2" value="" checked=""/>
                                                <label for="table_checkbox_2"></label>
                                            </div>
                                        </td>
                                        <td>Accomplsihed</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td class="with-checkbox">
                                            <div class="checkbox checkbox-css">
                                                <input type="checkbox" id="table_checkbox_2" value="" checked=""/>
                                                <label for="table_checkbox_2"></label>
                                            </div>
                                        </td>
                                        <td>Assigned</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td class="with-checkbox">
                                            <div class="checkbox checkbox-css">
                                                <input type="checkbox" id="table_checkbox_2" value="" checked=""/>
                                                <label for="table_checkbox_2"></label>
                                            </div>
                                        </td>
                                        <td>Pending</td>
                                        <td>0</td>
                                    </tr>
                                 </tbody>
                            </table>
                         {/* </div> */}
                    </div>
                </div> 
                <div className="col-md-8">
                    <div className="panel">
                        {/* <div className="panel-body"> */}
                            <Gauges/>
                        {/* </div> */}
                    </div>
                 </div>
            </div>

           <div className="row">
                <div className="col-md-3">
                    <div className="card card-primary card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-success card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-mint card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-info card-colorful">
                        <div className="pad-all text-center">
                            <span className="text-3x text-thin">22</span>
                            <p>Total Employees</p>
                            <i className="demo-pli-shopping-bag icon-lg"></i>
                        </div>
                    </div>
                </div>
                
           </div>   
           <div className="row">
                <div className="col-md-12">
                <div className="panel">
                        <div className="panel-body">
                        <Accomplished/>
                        </div>
                    </div>
                    
                </div>
           </div>

        </div>
         );
    }
}
 
export default Meter;
