import React, { Component, Fragment } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect, Link } from 'react-router-dom'
import ReactExport from "react-data-export";
import PaySlip from './payslipDesign'
import ReactToPrint from 'react-to-print';
import $ from 'jquery'
import { connect } from 'react-redux'
import { LoadingOverlay, Loader } from 'react-overlay-loader';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import PAGE from'./employeeDetails'

// import console = require('console');


class Payroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterEndDate: new Date(),
            filterStartDate: new Date(),
            payrollData: [],
            alert: false,
            alertWarning: false,
            branches: [],
            branch_selected: "select branch",
            payroll_data: [],
            Branch: "",
            MonthPeriod: "",
            showEmpPayroll: false,
            searchDriver: "",
            redirectpayrollProfile: false,
            companies: [],
            company_selected: "",
            listEmp: [],
            deduction: [],
            responsedata: [],
            earning: [],
            DATA: [],
            selection: false,
            route: "",
            colspan_earning: 0,
            colspan_deduction: 0,
            pay_id: 0,
            earningAmount: 0,
            deductionAmount: 0,
            govenment_cont: false,
            selectBranches: [],
            refresh: false,
            count: 0,
            philH_EE: 0,
            payroll_details_id: '',
            user_ID: '',
            bgcolor1: "#404040",
            bgcolor2: " transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            navbar: "navigation1",
            firstCutoff: 0,
            employee: [],
            employee_selected: [],
            hideDisplay: true,
            loading: false,
            delete_pay_id: ''
        }
        this.textchange = this.textchange.bind(this);
    }
    updatePHILHEALTH() {
        getData('humanResource/PHILHEALTH_update', { payroll_details_id: this.state.payroll_details_id, amount: this.state.philH_EE })
            .then((response) => {
                this.payroll_data(this.state.DATA);

                this.setState({ refresh: true })

            })
    }
    firstCutoff() {
        getData('humanResource/payroll_update', { payroll_details_id: this.state.payroll_details_id, data: { prev_total_earning: this.state.firstCutoff } })
            .then((response) => {
                $('#modal_cut_off').click()
                console.log(response);
                this.payroll_data(this.state.DATA);

                this.setState({ refresh: true })

            })
    }
    textchange = e => {
        console.log(e.target.value)
        this.setState({ [e.target.name]: e.target.value });
    };
    filterDateStart = e => {
        console.log(e)
        this.setState({
            filterStartDate: e
        });

    }
    filterDateEnd = e => {
        console.log(e)
        this.setState({
            filterEndDate: e
        });

    }
    selectHierarchyStructuresPayroll() {
        getData('humanResource/selectHierarchyStructuresPayroll', { user_id: localStorage.getItem('u') })
            .then(response => {
                this.setState({ hierarchy_structures: response.hierarchy_structures })
                this.props.dispatch({
                    type: 'Branches',
                    data: response.branches
                })
                console.log(response)
            })
    }
    componentDidMount() {
        this.selectHierarchyStructuresPayroll()
        var data = localStorage.getItem("")
        this.checkPagePrivilege();
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.setState({ filterStartDate: firstDay })
        // this.getEmp();
        this.getPayroll();
        this.getCompanies();
        // setInterval(() => {
        //     if (localStorage.getItem("transaction") == "true") {
        //         this.payroll_data(this.state.DATA);

        //         localStorage.setItem("transaction", false)
        //     }
        // }, 1000)

    }
    getPayroll() {
        if (this.state.payrollData.length == 0) {
            getData("humanResource/getPayroll", { user_id: localStorage.getItem("u") })
                .then(response => {
                    console.log(response)
                    if (response.success == true) {
                        this.setState({ payrollData: response.data, count: response.data.length })
                    }
                })
        }

    }
    getEmp() {
        getData("humanResource/userTimesheet2", { user_id: localStorage.getItem("u") })

            .then(response => {

            })
    }
    generate() {
        this.setState({ alertWarning: false, alert: false, loading: true })
        getData("humanResource/payroll_group", { payroll_update: false, employee: this.state.employee_selected, user_id: localStorage.getItem("u"), bid: this.state.branch_selected, date_from: this.state.filterStartDate, date_to: this.state.filterEndDate, government_deduct: this.state.govenment_cont })
            .then(response => {
                console.log(response);
                if (response.success == true) {
                    this.setState({ payrollData: response.data, alert: true, loading: false })
                } else {
                    this.setState({ alertWarning: true, loading: false })
                }
                setTimeout(() => {
                    if (this.state.alert == true) {
                        this.setState({ alert: false, })
                    }
                    if (this.state.alertWarning == true) {
                        this.setState({ alertWarning: false })
                    }
                }, 100000)
            })
    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {
                this.setState({ branches: response.data, branch_selected: "select branch" })
            })
    }
    showBYbranch = e => {
        e.preventDefault();

        this.setState({ branch_selected: e.target.value })
        this.fetchEmployee(e.target.value)
    }
    fetchEmployee(branch) {
        getData("humanResource/getUsers_branch", { branch: branch })
            .then(response => {
                console.log(response);
                this.setState({ employee: response.response })
            })
    }

    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, employee: [] })
        setTimeout(() => { this.getBranch() }, 200)
    }
    // payroll_data = (data) => {
    //     // if(this.state.listEmp.length != 0 ){
    //     this.setState({ showEmpPayroll: true, DATA: data, pay_id: data.p_id })
    //     // }

    //     getData("humanResource/getPayroll_details", { user_id: localStorage.getItem("u"), pay_id: data.p_id })
    //         .then(response => {
    //             console.log(response);
    //             const sssDataTable = response.sss_data;
    //             const annual_tax_data = response.annual_tax_data;
    //             var Listemp = [];
    //             var deduction = [];
    //             var earning = [];
    //             let gov_conti = []

    //             for (let i = 0; i < response.data.length; i++) {
    //                 console.log(response.data[i]);

    //                 if (response.data[i].data_schema['timesheet']['ot'].length != 0) {


    //                     var totalDeduction = 0;
    //                     var totalEarning = 0;
    //                     for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'].length; j++) {

    //                         var match = false;
    //                         for (let k = 0; k < deduction.length; k++) {
    //                             if (deduction[k].deduction_name == response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'][j]) {
    //                                 match = true;
    //                             }
    //                         }
    //                         var dudect_name = [];
    //                         if (match == false) {
    //                             dudect_name = { deduction_name: response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'][j] }
    //                             deduction.push(dudect_name);
    //                         }

    //                     }

    //                     for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_name'].length; j++) {

    //                         var match = false;
    //                         for (let k = 0; k < earning.length; k++) {
    //                             if (earning[k].earning_name == response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j]) {
    //                                 match = true;
    //                             }
    //                         }
    //                         var earn_name = [];
    //                         if (match == false) {
    //                             earn_name = { earning_name: response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j] }
    //                             earning.push(earn_name);
    //                         }

    //                     }
    //                     var valuecolde = deduction.length + 23;
    //                     var valuecol = earning.length + 38;
    //                     this.setState({ colspan_earning: valuecol, colspan_deduction: valuecolde })

    //                     for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'].length; j++) {
    //                         totalDeduction += parseFloat(response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'][j]);

    //                     }
    //                     for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
    //                         totalEarning += parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
    //                     }

    //                     var details = [];
    //                     var firstCUT_off = 0;
    //                     var secondCUT_off = 0;
    //                     var totalCUT_off = 0;
    //                     var SSS_CONTRIBUTION = 0;
    //                     var SSS_EC = 0;
    //                     var SSS_ER = 0;
    //                     var SSS_EE = 0;



    //                     let Allowance = 0;
    //                     for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
    //                         // if (response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j].toUpperCase() == ("Allowance").toUpperCase()) {
    //                             Allowance = parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
    //                         // }


    //                     }

    //                     if (response.data[i].government_contribution == 1) {
    //                         // let total_gross = parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])

    //                         // console.log(Allowance);
    //                         let totalEarning_sss = (parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + Allowance + parseFloat(response.data[i].data_schema['timesheet']['total_earning']))
    //                         console.log(totalEarning_sss)
    //                         const sssContributionData = sssDataTable.filter((val) => {
    //                             if (parseFloat(val.rangefrom) <= totalEarning_sss && parseFloat(val.rangeto) >= totalEarning_sss) {
    //                                 return val
    //                             }
    //                         }
    //                         )
    //                         let sss = 0;
    //                         for (let i = 0; i < sssContributionData.length; i++) {
    //                             sss = sssContributionData[i].sss_conti_EE;
    //                         }
    //                         let u_id_data = response.data[i].data_schema['user_id'],
    //                             late_data = response.data[i].data_schema['timesheet']['totalLate_Rate'],
    //                             Phil_health = (parseFloat(response.data[i].data_schema['timesheet']['salary_rate']) * 0.03) / 2,
    //                             Pag_Ibig = 100;

    //                         if ((parseFloat(response.data[i].data_schema['timesheet']['salary_rate'])) <= 10000) {
    //                             Phil_health = 150.00;
    //                         }
    //                         let Gross = (totalEarning_sss - (parseFloat(sss) + Phil_health + Pag_Ibig + parseFloat(late_data)));
    //                         let excess = 0;
    //                         let multiplier = 1;
    //                         let additional = 0;
    //                         let amount_val = 0;

    //                         const annual_tax = annual_tax_data.filter((val) => {
    //                             if (parseFloat(val.range_from) < Gross && parseFloat(val.range_to) >= Gross) {
    //                                 return val
    //                             }
    //                         }
    //                         )
    //                         for (let i = 0; i < annual_tax.length; i++) {
    //                             excess = parseFloat(annual_tax[i].range_from);
    //                             multiplier = parseFloat(annual_tax[i].multiplier);
    //                             additional = parseFloat(annual_tax[i].additional);
    //                         }
    //                         if (annual_tax.length > 0) {
    //                             amount_val = (((Gross - excess) * multiplier) + additional);
    //                         }

    //                         console.log(amount_val)

    //                         // getData("humanResource/getSSScontribution", { salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate']), p_id: response.data[i].pid, late: response.data[i].data_schema['timesheet']['totalLate_Rate'], user_id: response.data[i].data_schema['user_id'], totalEarnings: (parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + Allowance + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])) })
    //                         //     .then(sss_data => {

    //                         var PHILHEALTH = 0;
    //                         var PHILHEALTH_EE = 0;
    //                         var rate2 = 0;
    //                         var totalDeduction = 0;
    //                         var totalEarning = 0;

    //                         var sunday = "";
    //                         var sunday_ot = "";
    //                         var sunday_ot_rate = "";
    //                         var lateHours = "";
    //                         var lateHours_Rate = "";
    //                         var amount2 = "";
    //                         var regularHoliday = "";
    //                         var specialHoliday = "";
    //                         var sunday_amount = "";
    //                         var regularHoliday_amount = "";
    //                         var specialHoliday_amount = "";
    //                         var totalRegularOT = "";
    //                         var totalRegularOT_Rate = "";
    //                         var totalRegualar_Hol_OT_Rate = "";
    //                         var totalRegualar_Hol_OT = "";
    //                         var totalSpecial_Hol_OT = "";
    //                         var totalSpecial_Hol_OT_Rate = "";
    //                         var totalUndertime_hours = "";
    //                         var totalUndertime_amount = "";
    //                         var leave_w_pay_amount = "";
    //                         var leave_w_pay_days = "";
    //                         var holding_tax = "";
    //                         var allowance_amount = "";
    //                         var double_book_amount = "";
    //                         var double_book = "";
    //                         var jo_number = "";
    //                         var jo_total_amount = "";
    //                         var retro_ot = "";
    //                         var retro_ot_amount = "";



    //                         if (response.data[i].data_schema['timesheet']['retro_ot'] != 0) {
    //                             retro_ot = response.data[i].data_schema['timesheet']['retro_ot'];
    //                             retro_ot_amount = response.data[i].data_schema['timesheet']['retro_ot_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['double_book_amount'] != 0) {
    //                             double_book_amount = response.data[i].data_schema['timesheet']['double_book_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['double_book'] != 0) {
    //                             double_book = response.data[i].data_schema['timesheet']['double_book'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['jo_number'] != 0) {
    //                             jo_number = response.data[i].data_schema['timesheet']['jo_number'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['jo_total_amount'] != 0) {
    //                             jo_total_amount = response.data[i].data_schema['timesheet']['jo_total_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['allowance_amount'] != 0) {
    //                             allowance_amount = response.data[i].data_schema['timesheet']['allowance_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['sunday'] != 0) {
    //                             sunday = response.data[i].data_schema['timesheet']['sunday'];
    //                         }
    //                         if (amount_val != 0) {
    //                             holding_tax = parseFloat(amount_val).toFixed(2);
    //                             totalDeduction += amount_val;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['totalLate'] != 0) {
    //                             lateHours = response.data[i].data_schema['timesheet']['totalLate'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['totalLate_Rate'] != 0) {
    //                             lateHours_Rate = response.data[i].data_schema['timesheet']['totalLate_Rate'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['amount'] != 0) {
    //                             amount2 = response.data[i].data_schema['timesheet']['amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['regularHoliday'] != 0) {
    //                             regularHoliday = response.data[i].data_schema['timesheet']['regularHoliday'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['specialHoliday'] != 0) {
    //                             specialHoliday = response.data[i].data_schema['timesheet']['specialHoliday'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['sunday_amount'] != 0) {
    //                             sunday_amount = response.data[i].data_schema['timesheet']['sunday_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['regularHol_amount'] != 0) {
    //                             regularHoliday_amount = response.data[i].data_schema['timesheet']['regularHol_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['specialHol_amount'] != 0) {
    //                             specialHoliday_amount = response.data[i].data_schema['timesheet']['specialHol_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][0].total_ot != 0) {
    //                             totalRegularOT = response.data[i].data_schema['timesheet']['ot'][0].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][3].total_ot != 0) {
    //                             sunday_ot = response.data[i].data_schema['timesheet']['ot'][3].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate != 0) {
    //                             sunday_ot_rate = response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate != 0) {
    //                             totalRegularOT_Rate = response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate != 0) {
    //                             totalRegualar_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][1].total_ot != 0) {
    //                             totalRegualar_Hol_OT = response.data[i].data_schema['timesheet']['ot'][1].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
    //                             totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
    //                             totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate != 0) {
    //                             totalSpecial_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['undertimeHours'] != 0) {
    //                             totalUndertime_hours = response.data[i].data_schema['timesheet']['undertimeHours'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['undertimeAmount'] != 0) {
    //                             totalUndertime_amount = response.data[i].data_schema['timesheet']['undertimeAmount'];
    //                         }

    //                         for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'].length; j++) {
    //                             totalDeduction += parseFloat(response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'][j]);

    //                         }
    //                         for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
    //                             totalEarning += parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
    //                         }
    //                         // if(response.data[i].deduction.length != 0){
    //                         //     for (let j = 0; j < response.data[i].deduction['deduction_amount'].length; j++) {
    //                         //         totalDeduction += parseFloat(response.data[i].deduction['deduction_amount'][j]);

    //                         //     }
    //                         // }
    //                         // if(response.data[i].earning.length != 0){
    //                         //     for (let j = 0; j < response.data[i].earning['earning_amount'].length; j++) {
    //                         //         totalEarning += parseFloat(response.data[i].earning['earning_amount'][j])
    //                         //     }
    //                         // }



    //                         if (response.data[i].data_schema['timesheet']['type_rate'] == "Monthly") {
    //                             rate2 = parseFloat(response.data[i].data_schema['timesheet']['salary_rate']);
    //                         } else if (response.data[i].data_schema['timesheet']['type_rate'] == "Daily") {
    //                             rate2 = (parseFloat(response.data[i].data_schema['timesheet']['salary_rate']) * 313) / 12;
    //                         }

    //                         if (rate2 <= 10000 && rate2 > 0) {
    //                             PHILHEALTH = 150.00
    //                         } else {
    //                             PHILHEALTH = ((rate2 * 0.03) / 2).toFixed(2)
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['leaveDay'] != 0) {
    //                             leave_w_pay_days = response.data[i].data_schema['timesheet']['leaveDay'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['leaveDayAmount'] != 0) {
    //                             leave_w_pay_amount = parseFloat(response.data[i].data_schema['timesheet']['leaveDayAmount']).toFixed(2);
    //                         }

    //                         if (response.data[i].philhealth_EE != 0) {
    //                             PHILHEALTH_EE = parseFloat(response.data[i].philhealth_EE).toFixed(2);
    //                         } else {
    //                             PHILHEALTH_EE = PHILHEALTH;
    //                         }

    //                         // console.log(firstCUT_off);
    //                         for (let l = 0; l < sssContributionData.length; l++) {
    //                             SSS_CONTRIBUTION = sssContributionData[l].sss_conti_Total;
    //                             SSS_EC = sssContributionData[l].ec_contri_ER;
    //                             SSS_ER = sssContributionData[l].sss_conti_ER;
    //                             SSS_EE = sssContributionData[l].sss_conti_EE;

    //                             // SSS_CONTRIBUTION = sss_data[l].sss_conti_Total;
    //                             // SSS_EC = sss_data[l].ec_contri_ER;
    //                             // SSS_ER = sss_data[l].total_conti_ER;
    //                             // SSS_EE = sss_data[l].sss_conti_EE;


    //                         }

    //                         gov_conti.push({
    //                             user_id: u_id_data,
    //                             SSS_CONTRIBUTION: SSS_CONTRIBUTION,
    //                             SSS_EC: SSS_EC,
    //                             SSS_ER: SSS_ER,
    //                             SSS_EE: SSS_EE,
    //                             PHILHEALTH: PHILHEALTH,
    //                             PHILHEALTH_EE: PHILHEALTH_EE,
    //                             PAG_IBIG_ER: 100,
    //                             PAG_IBIG_EE: 100,
    //                             holding_tax: holding_tax
    //                             // SSS:SSS_EE,
    //                             // PHILHEALTH:PHILHEALTH_EE,
    //                             // PAG_IBIG:100
    //                         })
    //                         details = {
    //                             retro_ot_amount:retro_ot_amount,
    //                             retro_ot:retro_ot,
    //                             pid:response.data[0].pid,
    //                             allowance_amount: allowance_amount,
    //                             allowance_earnings: parseFloat(parseFloat(Allowance) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])).toFixed(2),
    //                             company_name:response.data[i].company_name,
    //                             emp_id: response.data[i].data_schema['user_id'],
    //                             branch_name: response.data[i].data_schema['branch_name'],
    //                             emp_name: response.data[i].data_schema['user_name'],
    //                             emp_monthly: response.data[i].data_schema['timesheet']['type_rate'],
    //                             emp_salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate'].replace(/,/g, '')).toLocaleString(),
    //                             totalDays: response.data[i].data_schema['timesheet']['totalDay'],
    //                             sunday: sunday,
    //                             sunday_ot: sunday_ot,
    //                             sunday_ot_rate: sunday_ot_rate,
    //                             lateHours: lateHours,
    //                             lateHours_Rate: lateHours_Rate,
    //                             amount2: amount2,
    //                             regularHoliday: regularHoliday,
    //                             specialHoliday: specialHoliday,
    //                             sunday_amount: sunday_amount,
    //                             regularHoliday_amount: regularHoliday_amount,
    //                             specialHoliday_amount: specialHoliday_amount,
    //                             payroll_details_id: response.data[i].pid,
    //                             totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH),

    //                             // totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['undertimeAmount'] + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH),
    //                             totalRegularOT: totalRegularOT,
    //                             totalRegularOT_Rate: totalRegularOT_Rate,
    //                             totalRegualar_Hol_OT_Rate: totalRegualar_Hol_OT_Rate,
    //                             totalRegualar_Hol_OT: totalRegualar_Hol_OT,

    //                             totalSpecial_Hol_OT: totalSpecial_Hol_OT,
    //                             totalSpecial_Hol_OT_Rate: totalSpecial_Hol_OT_Rate,
    //                             total_earning: parseFloat(response.data[i].data_schema['timesheet']['total_earning']) + parseFloat(totalEarning),

    //                             totalUndertime_hours: totalUndertime_hours,
    //                             totalUndertime_amount: totalUndertime_amount,

    //                             firstCUT_off: parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']),
    //                             secondCUT_off: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning),
    //                             totalCUT_off: parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning),

    //                             SSS_EE: SSS_EE,
    //                             SSS_CONTRIBUTION: SSS_CONTRIBUTION,
    //                             SSS_EC: SSS_EC,
    //                             SSS_ER: SSS_ER,

    //                             PHILHEALTH: PHILHEALTH,
    //                             PHILHEALTH_EE: PHILHEALTH_EE,

    //                             PAG_IBIG: 100,
    //                             total_net_pay: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']) + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH_EE)),

    //                             leave_w_pay_days: leave_w_pay_days,
    //                             leave_w_pay_amount: (leave_w_pay_amount),

    //                             holding_tax: holding_tax,

    //                             double_book_amount :double_book_amount,
    //                             double_book : double_book,
    //                             jo_number :jo_number,
    //                             jo_total_amount : jo_total_amount,
    //                         }

    //                         Listemp.push(details);

    //                         // })


    //                     } else if (response.data[i].government_contribution == 0) {
    //                         var sunday_ot = "";
    //                         var sunday_ot_rate = "";
    //                         var sunday = "";
    //                         var lateHours = "";
    //                         var lateHours_Rate = "";
    //                         var amount2 = "";
    //                         var regularHoliday = "";
    //                         var specialHoliday = "";
    //                         var sunday_amount = "";
    //                         var regularHoliday_amount = "";
    //                         var specialHoliday_amount = "";
    //                         var totalRegularOT = "";
    //                         var totalRegularOT_Rate = "";
    //                         var totalRegualar_Hol_OT_Rate = "";
    //                         var totalRegualar_Hol_OT = "";
    //                         var totalSpecial_Hol_OT = "";
    //                         var totalSpecial_Hol_OT_Rate = "";
    //                         var totalUndertime_hours = "";
    //                         var totalUndertime_amount = "";
    //                         var leave_w_pay_amount = "";
    //                         var leave_w_pay_days = "";
    //                         var holding_tax = ""
    //                         var allowance_amount = "";
    //                         var double_book_amount = "";
    //                         var double_book = "";
    //                         var jo_number = "";
    //                         var jo_total_amount = "";
    //                         var retro_ot = "";
    //                         var retro_ot_amount = "";
    //                         if (response.data[i].data_schema['timesheet']['retro_ot'] != 0) {
    //                             retro_ot = response.data[i].data_schema['timesheet']['retro_ot'];
    //                             retro_ot_amount = response.data[i].data_schema['timesheet']['retro_ot_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['double_book_amount'] != 0) {
    //                             double_book_amount = response.data[i].data_schema['timesheet']['double_book_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['double_book'] != 0) {
    //                             double_book = response.data[i].data_schema['timesheet']['double_book'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['jo_number'] != 0) {
    //                             jo_number = response.data[i].data_schema['timesheet']['jo_number'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['jo_total_amount'] != 0) {
    //                             jo_total_amount = response.data[i].data_schema['timesheet']['jo_total_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['allowance_amount'] != 0) {
    //                             allowance_amount = response.data[i].data_schema['timesheet']['allowance_amount'];
    //                         }

    //                         if (response.data[i].data_schema['timesheet']['sunday'] != 0) {
    //                             sunday = response.data[i].data_schema['timesheet']['sunday'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][3].total_ot != 0) {
    //                             sunday_ot = response.data[i].data_schema['timesheet']['ot'][3].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate != 0) {
    //                             sunday_ot_rate = response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['totalLate'] != 0) {
    //                             lateHours = response.data[i].data_schema['timesheet']['totalLate'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['totalLate_Rate'] != 0) {
    //                             lateHours_Rate = response.data[i].data_schema['timesheet']['totalLate_Rate'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['amount'] != 0) {
    //                             amount2 = response.data[i].data_schema['timesheet']['amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['regularHoliday'] != 0) {
    //                             regularHoliday = response.data[i].data_schema['timesheet']['regularHoliday'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['specialHoliday'] != 0) {
    //                             specialHoliday = response.data[i].data_schema['timesheet']['specialHoliday'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['sunday_amount'] != 0) {
    //                             sunday_amount = response.data[i].data_schema['timesheet']['sunday_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['regularHol_amount'] != 0) {
    //                             regularHoliday_amount = response.data[i].data_schema['timesheet']['regularHol_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['specialHol_amount'] != 0) {
    //                             specialHoliday_amount = response.data[i].data_schema['timesheet']['specialHol_amount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][0].total_ot != 0) {
    //                             totalRegularOT = response.data[i].data_schema['timesheet']['ot'][0].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate != 0) {
    //                             totalRegularOT_Rate = response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate != 0) {
    //                             totalRegualar_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][1].total_ot != 0) {
    //                             totalRegualar_Hol_OT = response.data[i].data_schema['timesheet']['ot'][1].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
    //                             totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
    //                             totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate != 0) {
    //                             totalSpecial_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate;
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['undertimeHours'] != 0) {
    //                             totalUndertime_hours = response.data[i].data_schema['timesheet']['undertimeHours'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['undertimeAmount'] != 0) {
    //                             totalUndertime_amount = response.data[i].data_schema['timesheet']['undertimeAmount'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['leaveDay'] != 0) {
    //                             leave_w_pay_days = response.data[i].data_schema['timesheet']['leaveDay'];
    //                         }
    //                         if (response.data[i].data_schema['timesheet']['leaveDayAmount'] != 0) {
    //                             leave_w_pay_amount = parseFloat(response.data[i].data_schema['timesheet']['leaveDayAmount']).toFixed(2);
    //                         }

    //                         details = {
    //                             retro_ot_amount:retro_ot_amount,
    //                             retro_ot:retro_ot,
    //                             pid:response.data[0].pid,
    //                             allowance_amount: allowance_amount,
    //                             allowance_earnings: parseFloat(response.data[i].data_schema['timesheet']['total_earning']).toFixed(2),
    //                             company_name:response.data[i].company_name,
    //                             emp_id: response.data[i].data_schema['user_id'],
    //                             branch_name: response.data[i].data_schema['branch_name'],
    //                             emp_name: response.data[i].data_schema['user_name'],
    //                             emp_monthly: response.data[i].data_schema['timesheet']['type_rate'],
    //                             emp_salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate'].replace(/,/g, '')).toLocaleString(),
    //                             totalDays: response.data[i].data_schema['timesheet']['totalDay'],
    //                             sunday: sunday,
    //                             sunday_ot: sunday_ot,
    //                             sunday_ot_rate: sunday_ot_rate,
    //                             lateHours: lateHours,
    //                             lateHours_Rate: lateHours_Rate,
    //                             amount2: amount2,
    //                             regularHoliday: regularHoliday,
    //                             specialHoliday: specialHoliday,
    //                             sunday_amount: sunday_amount,
    //                             regularHoliday_amount: regularHoliday_amount,
    //                             specialHoliday_amount: specialHoliday_amount,
    //                             payroll_details_id: response.data[i].pid,
    //                             totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['totalLate_Rate'],

    //                             // totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['undertimeAmount'] + response.data[i].data_schema['timesheet']['totalLate_Rate'],
    //                             totalRegularOT: totalRegularOT,
    //                             totalRegularOT_Rate: totalRegularOT_Rate,
    //                             totalRegualar_Hol_OT_Rate: totalRegualar_Hol_OT_Rate,
    //                             totalRegualar_Hol_OT: totalRegualar_Hol_OT,

    //                             totalSpecial_Hol_OT: totalSpecial_Hol_OT,
    //                             totalSpecial_Hol_OT_Rate: totalSpecial_Hol_OT_Rate,
    //                             total_earning: parseFloat(parseFloat(response.data[i].data_schema['timesheet']['total_earning']) + parseFloat(totalEarning)).toFixed(2),

    //                             totalUndertime_hours: totalUndertime_hours,
    //                             totalUndertime_amount: totalUndertime_amount,

    //                             firstCUT_off: "",
    //                             secondCUT_off: "",
    //                             totalCUT_off: "",

    //                             SSS_EE: "",
    //                             SSS_CONTRIBUTION: "",
    //                             SSS_EC: "",
    //                             SSS_ER: "",

    //                             PHILHEALTH: "",
    //                             PHILHEALTH_EE: "",

    //                             PAG_IBIG: "",
    //                             total_net_pay: (parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']))).toFixed(2),

    //                             leave_w_pay_days: leave_w_pay_days,
    //                             leave_w_pay_amount: (leave_w_pay_amount),

    //                             holding_tax: holding_tax,

    //                            double_book_amount :double_book_amount,
    //                            double_book : double_book,
    //                            jo_number :jo_number,
    //                            jo_total_amount : jo_total_amount,
    //                         }

    //                         Listemp.push(details);

    //                     }


    //                 }
    //             }
    //             let field = 'emp_name';
    //             setTimeout(() => {
    //                 Listemp.sort((a, b) => {
    //                     return a.emp_name > b.emp_name;
    //                 });
    //                 this.setState({ listEmp: Listemp, deduction: deduction, responsedata: response.data, earning: earning, MonthPeriod: data.date_from + ' - ' + data.date_to })
    //                 this.TotalEarnings(Listemp);
    //             }, 1000)
    //             localStorage.setItem('MonthPeriod', data.date_from + '--' + data.date_to)
    //             // console.log(gov_conti);
    //             this.props.dispatch({
    //                 type: 'GetEmployeePayroll',
    //                 Listemp: Listemp,
    //                 deduction:deduction,
    //                 earning:earning
    //             })
    //             getData("humanResource/updateGov", { gov_conti: gov_conti, pay_id: data.p_id })
    //                 .then(response => {
    //                     console.log(response);
    //                 })


    //         })
    // }
    payroll_data = () => {

    }

    getSSScontribution(data) {


    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    TotalEarnings(earnings) {
        getData("humanResource/ ", { data: earnings })
            .then(response => {
                //
            })
    }
    // government_deduction(){
    //     if(this.state.govenment_cont == true){
    //         // getData("humanResource/check_totalEarnings", {data:earnings})
    //         // .then(response => {
    //            
    //         // })
    //     }    
    // }
    checkPagePrivilege() {
        var route = "#/payroll";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                //
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    getCompanies() {

        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })

            .then(response => {
                console.log(response.data);
                this.setState({ companies: response.data })


            })
    }
    delete_payroll = () => {
        getData("humanResource/delete_payroll", { payroll_id: this.state.delete_pay_id })
            .then(response => {
                // this.componentDidMount();
                if (response.response == true) {

                    alert('Payroll successfully deleted')
                    getData("humanResource/getPayroll", { user_id: localStorage.getItem("u") })
                        .then(response => {

                            if (response.success == true) {
                                this.setState({ payrollData: response.data, count: response.data.length })
                            }
                        })
                }
                // console.log(response);
            })

    }
    showEmployee = (e) => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                if (options[i].value == 'ALL') {
                    // for(let i=0;i< this.state.employee.length;i++){
                    //     value.push(this.state.employee[i].user_id);
                    // }
                    value.push("ALL")
                } else {
                    value.push(options[i].value);

                }
                console.log(value)
            }
        }
        this.setState({ employee_selected: value })
    }
    downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob(['hehehhe'], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    render() {
        // console.log(this.state.govenment_cont);

        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        if (this.state.redirectpayrollProfile == true) {
            return <Redirect to='/payrollProfile' />
        }

        let EmployeeSearch = this.state.listEmp.filter(
            (files) => {
                return files.emp_name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (

            <div id="content " className="content " >
                {this.state.loading == true &&
                    <Loader fullPage loading />
                }
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                {this.state.navbar === "navigation1" &&
                                    <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Payroll Report</h4>
                                }
                                {this.state.navbar === "navigation2" &&
                                    <h4 className="page-header"><i class="far fa-snowflake"></i>&nbsp;Holidays</h4>
                                }
                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Payroll Report</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Holidays</a></li>
                                        }
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav" >

                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                        this.setState({ bgcolor1: " transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/holidays" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Holidays</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollProfile" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Earnings/Deductions</a>
                                </div>
                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                        this.setState({ bgcolor1: " transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/governmentDues" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Government&nbsp;Dues</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollSettings" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Payroll&nbsp;Settings</a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        {this.state.alert == true &&
                            <div className="row  w3-animate-zoom" style={{ width: '100%' }} >
                                <div className="col-md-12">
                                    <div className="panel bg-success">
                                        <a onClick={() => this.setState({ alert: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                        <div className="panel-body" >
                                            <div style={{ color: "white" }}>
                                                <i className="fa fa-check"></i>&nbsp;<label style={{ color: "white" }}>Success!</label>
                                                <p>Payroll generated successfully!</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.alertWarning == true &&
                            <div>

                                <div className="row  w3-animate-zoom" >
                                    <div className="col-md-12">
                                        <div className="panel bg-warning">
                                            <a onClick={() => this.setState({ alertWarning: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                            <div className="panel-body" >
                                                <div style={{ color: "white" }}>
                                                    <i className="fa fa-exclamation"></i>&nbsp;<label style={{ color: "white" }}>Failed!</label>
                                                    <p>Payroll Date is already generated!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.showEmpPayroll == true &&
                            <div className="navmedia m-b-5">
                                <div className="btn-group">
                                    <button className="btttn bg-red-transparent-9 fas fa-caret-left text-white" onClick={() => this.setState({ showEmpPayroll: false })}>&nbsp;Back</button>
                                    <button className="btttn bg-green-transparent-9 text-white m-l-5">
                                        <a className="fas fa-cloud-download-alt" href={"http://api.pacificweb.com.ph/humanResource/excel/" + localStorage.getItem("u") + "/" + this.state.pay_id} style={{ textDecoration: "none", color: "white" }}>&nbsp;Download&nbsp;Payroll</a>
                                    </button>


                                    <ReactToPrint
                                        trigger={() => <button className="btttn bg-green-transparent-9 text-white m-l-5">
                                            <a className="fas fa-print" href={'#/payroll'} style={{ textDecoration: "none", color: "white" }}>&nbsp;Print&nbsp;Payslip</a>
                                        </button>
                                        }
                                        content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}>
                                        <PaySlip ref={el => (this.componentRef = el)} data={this.state.listEmp} MonthPeriod={this.state.MonthPeriod} deduction={this.state.deduction} earning={this.state.earning} responsedata={this.state.responsedata} />
                                    </div>
                                    <button onClick={() => { this.downloadTxtFile() }}>button</button>


                                </div>
                                <div>
                                    <div className="input-group pull-left" style={{ marginBottom: 5 }}>
                                        <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.showEmpPayroll == false &&
                            <div>
                                <button className="btttn bg-green-transparent-9  m-b-10 fas fa-plus-circle" data-target="#demo-lg-modal11" data-toggle="modal" onClick={() => { this.setState({ showBranch: 0 }) }}>&nbsp;Create Payroll</button>
                            </div>
                        }
                        {this.state.showEmpPayroll == false &&
                            <div className="row">
                                <Scrollbars style={{ height: 300 }}>
                                    <table className="table tableFixhead table-bordered  table-td-valign-middle" >
                                        <thead>
                                            <tr className="text-center">
                                                <th>No.</th>
                                                <th>Payroll ID</th>
                                                <th>Month</th>
                                                <th>Branch</th>
                                                <th>Payroll Period</th>
                                                <th style={{ zIndex: 1, width: "auto" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.payrollData.map((val, index) => {
                                                return <tr key={index} className="text-center" style={{ background: val.status == 'Approved' ? '#009432' : undefined }}>
                                                    <td style={{ color: val.status == 'Approved' ? '#fff' : undefined }}>{this.state.payrollData.length - index}</td>
                                                    <td style={{ color: val.status == 'Approved' ? '#fff' : undefined }}>{val.p_id}</td>
                                                    <td style={{ color: val.status == 'Approved' ? '#fff' : undefined }}>{val.month.toUpperCase()}</td>
                                                    <td style={{ color: val.status == 'Approved' ? '#fff' : undefined }}>{val.branch_name}</td>
                                                    <td style={{ color: val.status == 'Approved' ? '#fff' : undefined }}><i className=" fa  fa-calendar-alt" style={{ color: "#009432" }}></i>&nbsp;&nbsp;{val.date_from}{' -- '}{val.date_to}</td>
                                                    {/* <td> <a tooltip="VIEW" href="javascript:;" className="btn btn-xs"><i className="fa fa-lg fa-eye text-success" onClick={()=>{this.payroll_data(val)}}></i></a></td> */}
                                                    <td >
                                                        <div className="btn-group">
                                                            <Link to={'/payrollEmployeeList/' + val.date_from + '/' + val.date_to + '/' + val.company_name + '/' + (String(val.branch_name).split('('))[0] + '/' + val.branch_id}> <button className="btttn bg-blue-transparent-9  fa fa-info-circle" onClick={() => {
                                                                localStorage.setItem('paydata', JSON.stringify(val));
                                                                this.props.dispatch({
                                                                    type: 'PayrollGroup',
                                                                    data: val
                                                                })
                                                            }}>&nbsp;Information</button></Link>
                                                            <div class="m-l-3 or m-r-3"></div>
                                                            {val.status != 'Approved' ? <>

                                                                <button className="btttn bg-red-transparent-9 fas fa fa-trash" onClick={() => { this.setState({ delete_pay_id: val.p_id }) }} data-target='#demo-lg-modal-delete' data-toggle='modal'>&nbsp;Delete</button>
                                                            </> : undefined

                                                            }
                                                            {val.status == 'Approved' ? <>
                                                                <button disabled className="btttn bg-red-transparent-9 fas fa fa-trash" onClick={() => { this.setState({ delete_pay_id: val.p_id }) }} data-target='#demo-lg-modal-delete' data-toggle='modal'>&nbsp;Delete</button>
                                                            </> : undefined

                                                            }

                                                        </div>
                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>

                                    </table>
                                </Scrollbars>
                            </div>
                        }
                        {this.state.showEmpPayroll == true &&
                            <Scrollbars style={{ height: 360 }}>
                                <table className="table table-td-valign-middle table-bordered" style={{ position: "relative" }}>

                                    <thead>
                                        <tr >
                                            <th>Employee ID</th>
                                            <th>Employee Name</th>
                                            <th>Salary Rate</th>
                                            <th>Rate</th>
                                            <th>Net Amount Paid</th>
                                            <th style={{ zIndex: 1, width: "auto" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {EmployeeSearch.map((val, index) => {

                                            return <tr key={index}>
                                                <td>{val.emp_id}</td>
                                                <td>{val.emp_name.toUpperCase()}</td>
                                                <td>{val.emp_salary_rate}</td>
                                                <td>{val.emp_monthly}</td>
                                                <td>{parseFloat(val.total_net_pay).toFixed(2)}</td>
                                                <td> <a onClick={() => {
                                                    localStorage.setItem('payDetails', JSON.stringify(val))
                                                    localStorage.setItem('earnings', JSON.stringify(this.state.earning))
                                                    localStorage.setItem('responsedata', JSON.stringify(this.state.responsedata))
                                                    localStorage.setItem('deduction', JSON.stringify(this.state.deduction))



                                                }} href={'#/payrollDetails/'}><button style={{ height: 23, backgroundColor: '#3498db', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} className="fas fa-eye">&nbsp;&nbsp;View</button> </a></td>

                                            </tr>
                                        })}


                                    </tbody>


                                </table>
                            </Scrollbars>
                        }
                    </div>
                </div>

                <div id="demo-lg-modal11" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="panel-title">Add</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9">
                                    <span className="fa fa-times-circle "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="panel">
                                    <div className="panel">
                                        <div className="panel-body solid">
                                            <label>Date Range</label>
                                            <div className="row" style={{ marginLeft: "11%" }}>
                                                <div className="form-inline">

                                                    <div className="form-group m-r-10" >
                                                        <DatePicker
                                                            selected={this.state.filterStartDate}
                                                            selectsStart
                                                            startDate={this.state.filterStartDate}
                                                            endDate={this.state.filterEndDate}
                                                            onChange={this.filterDateStart}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group m-r-10" >
                                                        <DatePicker
                                                            selected={this.state.filterEndDate}
                                                            selectsEnd
                                                            startDate={this.state.filterStartDate}
                                                            endDate={this.state.filterEndDate}
                                                            onChange={this.filterDateEnd}
                                                            className="form-control"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <label>Select Company</label>
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" onChange={this.showBYcompanies} required>
                                                    <option ></option>
                                                    {this.state.companies.map((val, index) =>
                                                        <option value={val.company_id}>{val.company_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Branch</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                                    <option>--select branch--</option>
                                                    <option></option>
                                                    {this.state.branches.map((branch, index) =>
                                                        <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Employee</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%", }}>
                                                <select className="form-control" style={{ height: 150 }} onChange={this.showEmployee} multiple required>
                                                    <option value="ALL">--Select All--</option>
                                                    <option></option>

                                                    {this.state.employee.map((val, index) =>
                                                        <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`.toUpperCase()}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <br />
                                            <div className="form-check form-check-inline" tooltip="Check to APPLY Government Deduction">
                                                <input type="checkbox" value="" id="checkbox-inline" className="form-check-input m-l-10" onChange={() => this.setState({ govenment_cont: !this.state.govenment_cont })} />
                                                <label className="form-check-label m-l-5" for="checkbox-inline">Government Deduction</label>
                                            </div>
                                            <br />
                                            <div className="pull-right">
                                                <button className="bttnpayroll bg-green-lighter" onClick={() => this.generate()} data-dismiss="modal" aria-label="Close">
                                                    <span className="fa fa-plus "></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {EmployeeSearch.length == 0 &&
                    <div id="modal" className="modal w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                        <div className="modal-dialog modal-sm modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="page-header">NOT EXISTED</h4>
                                    <button data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9 ">
                                        <span className="fa fa-times-circle fa-sm"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div id="PHILHEALTH_modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header" >
                                <h4 className="page-header">PHILHEALTH</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9 ">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <div className="form-group">
                                    <label className="col-lg-12 col-form-label">PHILHEALTH EE:</label>
                                    <div className="col-lg-12">
                                        <input name='philH_EE' type='number' onChange={this.textchange} className='form-control' />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button onClick={() => { this.updatePHILHEALTH(); }} className="btttn pull-right bg-green-transparent-9 fa fa-save" type="submit">
                                    &nbsp;Save
                                </button>
                            </div>

                        </div>


                    </div>
                </div>
                <div id="firstCutoff" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header" >
                                <h4 className="page-header">Cut-off</h4>
                                <button data-dismiss="modal" id="modal_cut_off" className="pull-right bttnclose bg-red-transparent-9 ">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <div className="form-group">
                                    <label className="col-lg-12 col-form-label">Day 1 - 15:</label>
                                    <div className="col-lg-12">
                                        <input name='firstCutoff' type='number' onChange={this.textchange} className='form-control' />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button onClick={() => { this.firstCutoff(); }} className="btttn pull-right bg-green-transparent-9 fa fa-save" type="submit">
                                    &nbsp;Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-delete" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete Payroll</h4>
                                {/* <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this payroll?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" onClick={() => { this.delete_payroll() }} data-dismiss="modal" aria-label="Close" >
                                    &nbsp;Delete
                                </button>
                                <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                    &nbsp;Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}
function mapStateToProps(state) {
    console.log(state)
    return {
    }
}

export default connect(mapStateToProps)(Payroll);