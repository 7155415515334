import React, { Component } from 'react';
import Maynilad from "../assets/maynilad-logo.png"
import PocketHR from "../assets/iconPocket.png"
import PIC211 from "../assets/211-2.png"
import Water from "../assets/water.png"
import { getData } from '../../../api/api';
import { Scrollbars } from 'react-custom-scrollbars';

// import Redirect from "react-router-dom"

import swal from 'sweetalert';
class Apps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maynilad_status: "OPEN",
            hr_status: "OPEN",
            Two11_status: "CLOSE",
            m_s_disabledButton: false,
            m_u_disabledButton: false,
            m_o_disabledButton: false,

            p_s_disabledButton: false,
            p_u_disabledButton: false,
            p_o_disabledButton: false,

            t_s_disabledButton: false,
            t_u_disabledButton: false,
            t_o_disabledButton: false,

            w_s_disabledButton: false,
            w_u_disabledButton: false,
            w_o_disabledButton: false,
            apps: [],
            refresh: false,
            hour: null
        };

    }
    // componentDidMount() {
    //     this.getHour()
    // }

    getHour = () => {
        const date = new Date();
        const hour = date.getHours()
        // this.setState({
        //     hour
        // });
        if (hour < 12) {
            return <p>Good Morning!</p>
        } else if (hour => 12) {
            return <p>Good Afternoon!</p>
        } else if (hour => 19) {
            return <p>Good Evening!</p>

        }
    }
    componentWillMount() {
        var app_id = JSON.parse(localStorage.getItem('app_id_priv'))
        getData("users/getApps", app_id).then(response => {
            console.log(response);
            var details = []
            var app_details = [];
            for (let i = 0; i < response.length; i++) {
                var data = [];
                data = { app_id: response[i][0].app_id, category_attribute: response[i][0].category_attribute, category_details: response[i][0].category_details, category_field: response[i][0].category_field, category_id: response[i][0].category_id, category_type: response[i][0].category_type, company_id: response[i][0].company_id, href: response[i][0].c_href }
                app_details.push(data);
            }
            //    console.log(app_details);
            this.setState({ apps: app_details })

        })
        this.setState({ m_o_disabledButton: true })
        this.setState({ p_o_disabledButton: true })
        this.setState({ t_o_disabledButton: true })
        this.setState({ w_o_disabledButton: true })
    }
    onclick(data) {
        var array = []
        console.log(data)
        localStorage.setItem('app_idDta', JSON.stringify(array));
        localStorage.setItem('AppD', true)
        localStorage.setItem('selectApp', true)
        localStorage.setItem('selectApp_id', data.app_id)
        this.setState({ refresh: true })
    }
    render() {
        return (
            <div id="content" className="content">
                <div className="panel" style={{ marginTop: "-20px" }}>
                    <div className="panel-body text-center bg-green-transparent-9">
                        <h4 className="text-white">{this.getHour()}</h4>
                        <h4 className="text-white">Welcome to Pacificweb Systems Apps, &nbsp; {localStorage.getItem('fullName')}</h4>
                    </div>
                    <div className="panel-footer">
                        <h4 className="text-center ">Your Application/s</h4>
                    </div>
                </div>
                <br />
                <div className="row" style={{ marginTop: "-20px" }}>

                    <div className="col-md-12">
                        <div className="row justify-content-center ">
                            {this.state.apps.map((val) =>
                                <a className="col-md-2  category  m-l-5 m-r-5 m-t-20" href={val.href} onClick={() => this.onclick(val)}>
                                    <div className="text-center shadow bg-white p-t-10 p-b-10">
                                        <img src={"http://api.pacificweb.com.ph/assets/img/apps/" + val.category_attribute} style={{ width: 80, height: 80, maxWidth: 80 }} /><br /><hr />
                                        <span className="text-black">{val.category_details}</span>
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}

export default Apps;