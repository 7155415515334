import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect, Link } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
// import PAGE from'./employeeDetails'

// import console = require('console');
class DetailsAdjustment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            date_from: new Date(),
            date_to: new Date(),
            emp_name: '',
            adjusment_id: '',
            emp_id: '',
            hours: '',
            amount: '',
            status: '',
            redirect: false,
            req_hierarchy_level: 0
        }
        this.textChange = this.textChange.bind(this)
        this._save = this._save.bind(this)

    }
    componentDidMount = () => {


        this.setState({ type: localStorage.getItem('adjustment_type') })
        this.getDataAdjustment()
    }
    getDataAdjustment = () => {
        let adjustment_id = this.props.match.params.id
        // alert(adjustment_id)
        getData('humanResource/getAdjustmentDetails', adjustment_id)
            .then((response) => {
                console.log(response)
                for (let index = 0; index < response.length; index++) {
                    this.setState({
                        emp_name: `${response[index].user_lname} ${response[index].user_fname} ${response[index].user_mname}`,
                        emp_id: response[index].user_id,
                        adjusment_id: response[index].req_id,
                        hours: response[index].req_hours_approved,
                        date_from: new Date(response[index].req_date_start),
                        date_to: new Date(response[index].req_date_end),
                        amount: response[index].req_quantity,
                        status: response[index].status,
                        req_hierarchy_level: response[index].req_hierarchy_level,
                    })
                }
            })
    }
    textChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    _save(e) {
        e.preventDefault();
        let details = {
            req_date_start: moment(this.state.date_from).format('Y-MM-DD'),
            req_date_start: moment(this.state.date_to).format('Y-MM-DD'),
            req_hours_approved: this.state.hours,
            req_quantity: this.state.amount,
        }
        getData('humanResource/updateAdjustmentDetails', { details: details, trans_id: this.props.match.params.id })
            .then((response) => {
                if (response == true) {
                    this.setState({ redirect: true })
                }
                else {
                    alert('Warning:Update the details first!')
                }

            })

    }
    _delete() {
        getData('humanResource/deleteAdjustment', { trans_id: this.props.match.params.id })
            .then((response) => {
                if (response == true) {
                    alert('Details has been deleted.')
                    this.setState({ redirect: true })
                }
                console.log(response);
                // if(response == true){
                //     this.setState({redirect:true})
                // }

            })
    }

    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/payrollAdjustment' />
        }
        return (
            <div id="content " className="content" style={{ paddingLeft: 30, paddingRight: 20 }}>
                <div className="panel" >
                    <div className="panel-heading">
                        <div className="navtitle panel-title">
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;{this.props.match.params.type} </h4>

                            </div>


                            {/* <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Basic</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Holidays</a></li>
                                        }
                                    </ol>
                                </nav> */}
                        </div>
                    </div>
                    <div className='panel-body' style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <form onSubmit={this._save}>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Adjustment ID:</b></h5>
                                </div>
                                <div className='col-md-2'>

                                    <input value={this.props.match.params.id} className='form-control' type='text' readOnly />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Employee ID:</b></h5>
                                </div>
                                <div className='col-md-2'>
                                    <input value={this.state.emp_id} className='form-control' type='text' readOnly />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Employee Name:</b></h5>
                                </div>
                                <div className='col-md-3'>
                                    <input value={this.state.emp_name} className='form-control' type='text' readOnly />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Hours:</b></h5>
                                </div>
                                <div className='col-md-2'>
                                    <input name='hours' value={this.state.hours} onChange={this.textChange} className='form-control' type='number' />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Amount:</b></h5>
                                </div>
                                <div className='col-md-2'>
                                    <input name='amount' value={this.state.amount} onChange={this.textChange} className='form-control' type='number' />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Date From:</b></h5>
                                </div>
                                <div className='col-md-3'>
                                    <DatePicker
                                        selected={this.state.date_from}
                                        startDate={this.state.date_to}
                                        className="form-control"
                                        onChange={(event) => this.setState({ date_from: event })}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Date To:</b></h5>
                                </div>
                                <div className='col-md-3'>
                                    <DatePicker
                                        selected={this.state.date_to}
                                        startEnd={this.state.date_to}
                                        className="form-control"
                                        onChange={(event) => this.setState({ date_to: event })}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-2'>
                                    <h5><b>Status:</b></h5>
                                </div>
                                <div className='col-md-2'>
                                    <input name='amount' value={this.state.status} className='form-control' type='text' readOnly />
                                </div>

                            </div>
                            <br />
                            <div>
                                <Link to='/payrollAdjustment'><button type='button' style={{ marginRight: 10 }} className="btttn bg-blue-transparent-9 fas fa-arrow-left pull-left"
                                >
                                    &nbsp;Back
                            </button></Link>

                              
                                {this.state.req_hierarchy_level == 0 ?
                                <>
                                  <button type='submit' style={{ marginRight: 10 }} className="btttn bg-green-transparent-9 fas fa-edit pull-left"
                                >
                                    &nbsp;Update
                            </button>
                                    <button type='button' className="btttn bg-red-transparent-9 fas fa-trash pull-left"
                                        onClick={() => {
                                            this._delete()
                                        }}>
                                        &nbsp;Delete
                                    </button>
                                    </>
                                    : undefined

                                }

                            </div>
                        </form>


                    </div>
                </div>
            </div>



        );
    }
}


export default DetailsAdjustment;