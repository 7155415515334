import React, { Component } from 'react';
import {    
    HashRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";

import Homepage from './homepage'
import JobForm from './jobForm'
import jobForm2 from './jobForm2'
import approval from './approval'
import home2 from './home2'
import register from './register'

class Routes extends Component {
    state = {  }
    render() { 
        return ( 
          <Router>
              <div>
                <Route path="/pocketHR/homepage" component={Homepage}/>
                <Route path="/pocketHR/JobForm" component={JobForm}/>
                <Route path="/pocketHR/jobForm2" component={jobForm2}/>
                <Route path="/pocketHR/approval" component={approval}/>
                <Route path="/pocketHR/home2" component={home2}/>
                <Route path="/pocketHR/register" component={register}/>

              </div>
          </Router>
         );
    }
}
 
export default Routes;