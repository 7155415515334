import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import DarPrivacy from './darPrivacy'
// import pockethr from './img/pockethr.png'
class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            searchDriver: []
        };
    }
    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                this.setState({ userData: response.users })
            })
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    checkPagePrivilege() {
        var route = "#/privacy";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                //
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    componentDidMount = () => {
        this.getUser();
        this.checkPagePrivilege()
    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        // let EmployeeSearch = this.state.userData.filter(
        //     (files) => {
        //         return files.completename.toLowerCase().indexOf(
        //             this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.branch_name.toLowerCase().indexOf(
        //                 this.state.searchDriver.toLocaleLowerCase()) !== -1
        //     }
        // )
        return (
            <div id="content " className="content  " style={{ marginTop: -15}} >
                <div className='row' style={{marginLeft:10}}>
                    <h3><b>Privacy Settings</b></h3>
                </div>
                <div className='row'  style={{marginLeft:10}}>
                    <div className='col-md-3'>
                        <div>
                            <h5><b>Dar Privacy</b></h5>
                        </div>
                    </div>
                    <div className='col-md-9'>
                      <DarPrivacy/>
                    </div>
                </div>

            </div>
        );
    }
}

export default Privacy;