import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import pockethr from './img/pockethr.png'
import reunion from './img/reunion.svg'

class Monitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Company: [],
            DATA: [],
            dateToday: new Date(),
            company_id: '',
            AttendanceToday: [],
            filterAttendance: [],
            search: '',
            selectedBranch: 'ALL',
            selectedCompany: 'ALL',
            selectedStatus: 'ALL',
            Branches: [],
            company_id2: '',
            needValidation: [],
            needValidation2: [],
            validationSelectedBranch: "",
            companies: [],
            branches: [],
            company: "",
            branch: "",
            status: "",
            status2: "",
            sheet: [],
            showhidden: 0,
            time: []


        };
        this.textchange = this.textchange.bind(this);
        this.passCompanyValidation = this.passCompanyValidation.bind(this);
        this.passBranchValidation = this.passBranchValidation.bind(this);
        this.passStatusValidation = this.passStatusValidation.bind(this);
    }

    attendance_today(branch) {
        var data = [];
        data = {
            company_id: this.state.company_id,
            branch_id: branch,
            dateToday: this.state.dateToday,
            user_id: localStorage.getItem("u")
        };
        getData("users/GetCurrentAttendance", data).then(response => {
            this.setState({ AttendanceToday: response.details });
            // this.setState({ filterAttendance: response.details });

            let x = [];
            x = response.details;
            x.forEach(element => {
                if (element['company_name'] === undefined || element['company_name'] === null) {
                    element['company_name'] = "";
                }
                if (element['branch_name'] === undefined || element['branch_name'] === null) {
                    element['branch_name'] = "";
                }
            });

            let present = [];
            let late = [];
            let absent = [];
            let final = [];


            for (let i = 0; i < x.length; i++) {
                if (x[i].details.timeSheet.length !== 0) {
                    if (x[i].details.timeSheet[0].fields[x[i].details.timeSheet[0].fields.length - 1] === "Time-in") {
                        final.push(x[i]);
                    }
                }
            }
            for (let i = 0; i < x.length; i++) {
                if (x[i].details.timeSheet.length !== 0) {
                    if (x[i].details.timeSheet[0].fields[x[i].details.timeSheet[0].fields.length - 1] === "Start") {
                        final.push(x[i]);
                    }
                }
            }
            for (let i = 0; i < x.length; i++) {
                if (x[i].details.timeSheet.length !== 0) {
                    if (x[i].details.timeSheet[0].fields[x[i].details.timeSheet[0].fields.length - 1] === "Time-out") {
                        final.push(x[i]);
                    }
                }
            }
            for (let i = 0; i < x.length; i++) {
                if (x[i].details.timeSheet.length !== 0) {
                    if (x[i].details.timeSheet[0].fields[x[i].details.timeSheet[0].fields.length - 1] === "End") {
                        final.push(x[i]);
                    }
                }
            }
            for (let i = 0; i < x.length; i++) {
                if (x[i].details.timeSheet.length === 0) {
                    final.push(x[i]);
                }
            }
            // for(let i = 0; i<x.length; i++){
            //     if(x[i].status === "PRESENT"){
            //         // final.push(x[i])
            //         // absent.push(x[i]);
            //     }else if(x[i].status === "LATE"){
            //         // present.push(x[i]);
            //     }else if(x[i].status === "ABSENT"){
            //         // late.push(x[i]);
            //         final.push(x[i]);
            //     }
            // }


            this.setState({ filterAttendance: final });
        });
    }

    updateSearch(event) {
        this.setState({ search: event.target.value.substr(0, 20) });
    }

    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });

        let data = this.state.selectedBranch;
        let data2 = this.state.selectedCompany;
        if ([e.target.name] === "selectedBranch") {
            data = e.target.value
        } else {
            data2 = e.target.value
        }

    }

    passCompanyValidation(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                if (options[i].value === "") {
                    this.setState({ company: '', branch: '' })
                    document.getElementsByName('selectedBranch')[0].value = ''
                } else {
                    this.setState({ company: options[i].innerHTML, branch: '' })
                }
            }
        }
        getData("users/getBranch", company)
            .then(result => {
                this.setState({ branches: result })
            });
    }

    passBranchValidation = e => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ branch: options[i].value })
            }
        }

    }
    passStatusValidation(e) {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({ status: options[i].value })
            }

        }

    }



    componentDidMount() {
        var branch = "";
        this.attendance_today(branch);
        getData("users/getCompanies", localStorage.getItem("u")).then(response => {
            this.setState({ DATA: response.company });
            var data = [];
            for (let i = 0; i < response.company.length; i++) {
                var match = false;
                for (let index = 0; index < data.length; index++) {
                    if (data[index].companyName == response.company[i].company_name) {
                        match = true;
                    }
                }
                var data2 = [];
                if (match == false) {
                    data2 = {
                        companyName: response.company[i].company_name,
                        company_id: response.company[i].company_id
                    };
                    data.push(data2);
                }
            }

            this.setState({ Company: data });
        });

        // getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
        //   .then(response => {
        //     this.setState({ companies: response.companies })

        //   })

    }



    render() {
        let filterUsers = this.state.filterAttendance.filter(
            (users) => {
                return users.name.toLowerCase().indexOf(
                    this.state.search.toLowerCase()) !== -1 || users.status.toLowerCase().indexOf(
                        this.state.search.toLowerCase()) !== -1
            }
        )
        return (
            <div id="content" className="content">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i className="fas fa-user-check"></i>&nbsp;Attendance Monitoring</h4>

                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Attendance Monitoring</a></li>
                                    </ol>
                                </nav>
                            </div>
                            {/* <div className="navnav" >
                                <div className="attendnav">
                                    <a href="#/users">Master&nbsp;List</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-green-transparent-9 fas fa-filter" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;Filter Attendance</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input className="form-control width-200" type="text" placeholder="Search..." name='search' value={this.state.search} onChange={this.updateSearch.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            {filterUsers.filter(x => x.company_name.includes(this.state.company) &&
                                x.branch_name.includes(this.state.branch) &&
                                (x.status.includes(this.state.status) ||
                                    (x.status.includes(this.state.status === 'PRESENT' ? 'LATE' : undefined)))

                            ).map((user, index1) => {
                                let com = user.company_name
                                let bra = user.branch_name
                                let pic = user.user_pic
                                if (user.company_name === undefined || user.company_name === null) {
                                    com = "";
                                }
                                if (user.branch_name === undefined || user.company_name === null) {
                                    bra = "";
                                }
                                if (user.user_pic === undefined || user.user_pic === '') {
                                    pic = "no.jpeg"
                                }
                                if (user.details.timeSheet.length !== 0) {
                                    // console.log(user.details.timeSheet[0].fields[user.details.timeSheet[0].fields.length - 1])
                                }
                                if (user.details.timeSheet.length !== 0) {
                                    if (user.details.timeSheet[0].fields[user.details.timeSheet[0].fields.length - 1] === "Time-in") {
                                        return <div className="zoom m-5" style={{ borderStyle: "solid", borderColor: "#65bf65" }}>
                                            <div
                                                data-target="#demo-view-modal"
                                                data-toggle="modal"
                                                onClick={() => {
                                                    this.setState({ sheet: [user], time: user.details.timeSheet })

                                                }}>
                                                <div className="img-contain">
                                                    <img className="no-filter" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                                </div>
                                            </div>
                                        </div>
                                    } else if (user.details.timeSheet[0].fields[user.details.timeSheet[0].fields.length - 1] === "Start") {
                                        return <div className="zoom m-5" style={{ borderStyle: "solid", borderColor: "#67abe9" }}>
                                            <div
                                                data-target="#demo-view-modal "
                                                data-toggle="modal"
                                                onClick={() => {
                                                    this.setState({ sheet: [user], time: user.details.timeSheet })
                                                }}>
                                                <div className="img-contain">
                                                    <img className="filtering-out" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                                </div>
                                            </div>
                                        </div>
                                    } else {
                                        return <div className="zoom m-5 " style={{ borderStyle: "solid", borderColor: "#c8d1d7" }}>
                                            <div
                                                data-target="#demo-view-modal "
                                                data-toggle="modal"
                                                onClick={() => {
                                                    this.setState({ sheet: [user], time: user.details.timeSheet })
                                                }}>
                                                <div className="img-contain">
                                                    <img className="filtering-out" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                                </div>
                                            </div>
                                        </div>
                                    }


                                } else {
                                    return <div className="zoom m-5 " style={{ borderStyle: "solid", borderColor: "#c8d1d7" }}>
                                        <div
                                            data-target="#demo-view-modal"
                                            data-toggle="modal"
                                            onClick={() => {
                                                this.setState({ sheet: [user] })
                                            }}>
                                            <div className="img-contain">
                                                <img className="filtering-out" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                            </div>
                                        </div>
                                    </div>
                                }



                                // if (user.class_type === "Office") {
                                //     if (user.details.timeSheet.length !== 0) {
                                //         if (user.details.timeSheet[0].fields[user.details.timeSheet[0].fields.length - 1] === "Time-out") {
                                //             return <div className="zoom">
                                //                 <div className="m-5 "
                                //                     data-target="#demo-view-modal "
                                //                     data-toggle="modal"
                                //                     onClick={() => {
                                //                         this.setState({ sheet: [user], time: user.details.timeSheet  })
                                //                     }}>
                                //                     <div className="img-contain">
                                //                         <img className="filtering-out" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                //                     </div>
                                //                 </div>

                                //             </div>
                                //         } else {
                                //             return <div className="zoom">
                                //                 <div className="m-5"
                                //                     data-target="#demo-view-modal"
                                //                     data-toggle="modal"
                                //                     onClick={() => {
                                //                         this.setState({ sheet: [user], time: user.details.timeSheet })

                                //                     }}>
                                //                     <div className="img-contain">
                                //                         <img className="no-filter" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         }
                                //     }
                                // } else if (user.class_type === "OB") {
                                //     if (user.details.timeSheet.length !== 0) {
                                //         if (user.details.timeSheet[0].fields[user.details.timeSheet[0].fields.length - 1] === "Time-in") {
                                //             return <div className="zoom">
                                //                 <div className="m-5"
                                //                     data-target="#demo-view-modal"
                                //                     data-toggle="modal"
                                //                     onClick={() => {
                                //                         this.setState({ sheet: [user], time: user.details.timeSheet })
                                //                     }}>
                                //                     <div className="img-contain">
                                //                         <img className="no-filter" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         } else {
                                //             return <div className="zoom">
                                //                 <div className="m-5"
                                //                     data-target="#demo-view-modal"
                                //                     data-toggle="modal"
                                //                     onClick={() => {
                                //                         this.setState({ sheet: [user], time: user.details.timeSheet })
                                //                     }}>
                                //                     <div className="img-contain">
                                //                         <img className="filtering-out" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                //                     </div>
                                //                 </div>


                                //             </div>
                                //         }
                                //     }
                                // }
                                // else {
                                //     return <div className="zoom">
                                //         <div className="m-5"
                                //             data-target="#demo-view-modal"
                                //             data-toggle="modal"
                                //             onClick={() => {
                                //                 this.setState({ sheet: [user] })
                                //             }}>
                                //             <div className="img-contain">
                                //                 <img className="filtering-out" src={'http://images.pacificweb.com.ph/pockethr/profilepic/' + pic} />
                                //             </div>
                                //         </div>
                                //     </div>
                                // }
                            })}
                        </div>

                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Filter Attendance</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Company Filter</label>
                                    <select className="form-control" onChange={this.passCompanyValidation} name="selectedCompany">
                                        <option value="">All</option>
                                        {this.state.Company.map((val, index) =>
                                            <option value={val.company_id} name={val.companyName}>{val.companyName}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPassword1">Branch Filter</label>
                                    <select className="form-control" onChange={this.passBranchValidation} name="selectedBranch">
                                        <option value="" name="All">All</option>
                                        {this.state.branches.map((val, index) =>
                                            <option value={val.bname} name={val.bname}>{val.bname}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPassword1">Status Filter</label>
                                    <select className="form-control" onChange={this.passStatusValidation} name="selectedBranch">
                                        <option value="" name="All">All</option>
                                        <option value="PRESENT" name="">Present</option>
                                        <option value="LATE" name="">Late</option>
                                        <option value="ABSENT" name="">Absent</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-view-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Attendance Modal</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" onClick={() => { this.setState({ sheet: [], time: [] }) }}>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.sheet.map((val, index) => {
                                    return <div key={index} className="row">
                                        {val.status === 'PRESENT' &&
                                            <div className="col-md-4 col-sm-12 text-center">
                                                <img style={{height:"1.10in",borderStyle: "solid", borderColor: "#65bf65", width: "80%", borderRadius: "12%", marginTop: "10%" }} src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} />
                                            </div>
                                        }
                                        {val.status === 'LATE' &&
                                            <div className="col-md-4 col-sm-12 text-center">
                                                <img style={{height:"1.10in", width: "80%", borderRadius: "12%", marginTop: "10%", borderStyle: "solid", borderColor: "#f8b553"  }} src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} />
                                            </div>
                                        }
                                        {val.status === 'ABSENT' &&
                                            <div className="col-md-4 col-sm-12 text-center">
                                                <img style={{height:"1.10in", width: "80%", borderRadius: "12%", marginTop: "10%", borderStyle: "solid", borderColor: "#ff8481"  }} src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} />
                                            </div>
                                        }

                                        <div className="col-md-8 col-sm-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div class="form-group">
                                                        <label>Name</label>
                                                        <input type="text" class="form-control" value={val.name} readOnly />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Location</label>
                                                        <input type="text" class="form-control" value={val.class_type} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div class="form-group">
                                                        <label>Status</label>
                                                        <input type="text" class="form-control" value={val.status} readOnly />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Company</label>
                                                        <input type="text" class="form-control" value={val.company_name} readOnly />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        < table className="table table-td-valign-middle table-bordered">
                                            <thead>

                                                {this.state.time.map((val, index) => {
                                                    return <tr>
                                                        {val.fields.map((val2, index2) => {
                                                            return < th >{val2}</th>
                                                        })}
                                                    </tr>
                                                })}

                                            </thead>
                                            <tbody>

                                                {this.state.time.map((val1, index) => {
                                                    return <tr>
                                                        {val1.attendance.map((val3, index3) => {
                                                            if (val3 === undefined) {
                                                                return <td>--:--</td>
                                                            } else {
                                                                return <td>{moment(val3, "LT").format('LT')}</td>
                                                            }

                                                        })}
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                })}


                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

export default Monitoring;
