import React, { Component } from 'react';
import DatePicker from "react-datepicker";

import {getMISbranch, checking} from "../../../api/api";

import swal from "sweetalert/dist/sweetalert.min.js";
import { element } from 'prop-types';

class Checking extends Component {
    constructor(props){
        super(props);
        this.state = {
            startDate: new Date(),
            branch: [],
            selectedBranch: '',
            checking: [],
            submitted: false,
            selectedBranchOld: '',
            selectedDate: new Date(),
        }
    }
    _isMounted = true;

    handleChangeStart = e => {
        this.setState({
            startDate: e
        });
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    branch(){
        getMISbranch("field_audit/getMISbranch").then(result =>{
            if(result.response === "404"){
                result = [];
                this.setState({branch : result});
            }else{
                this.setState({branch: result.elf})
          
            }
        })
    }


    submit = e => {
        var branch = this.state.selectedBranch;
        var date = this.state.startDate;
        var convert = date.toISOString();
        var splitDate = convert.split("T");

        var save = {
            date: splitDate[0],
            branch: branch
        }

        if(branch === null || branch === ""){
            swal("Error", "Select branch first", "error",{
                button: "Done"
            });
        }else{
            checking("field_audit/checking", save).then(result =>{
               this.setState({checking: result});
               this.setState({submitted: true})
            })
        }

        this.setState({selectedBranchOld: branch});
        this.setState({selectedDate: date})
    }

    done = e => {
        var checked = e.target.name;

        var data = this.state.checking;
        var x = [];

        data.forEach(element =>{
            if(element['id'] === e.target.name){
                element['checked'] = 'checked';
            }
        })

        this.setState({checking: data})


        checking("field_audit/checkingDone", checked).then(result =>{
          
            if(result === "Success"){
                swal("Success", "Checking Done!", "success",{
                    button: "Done"
                });
            }
        })
    }

    checking(){
        var branch = this.state.selectedBranchOld;
        var date = this.state.selectedDate;
        var convert = date.toISOString();
        var splitDate = convert.split("T");
        var submitted = this.state.submitted;

        var save = {
            date: splitDate[0],
            branch: branch
        }

        if(branch !== '' && submitted === true){
            checking("field_audit/checking", save).then(result =>{
                this.setState({checking: result});
             })
        }
    }

    componentDidMount(){
        this.branch();
        this.checking();
        this.update();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "checking"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "checking"){
            setTimeout(() => {
                this.checking();
                this.update();
            }, 5000);
        }else{
            clearTimeout(this.update);
        }
      }

    render() { 
        localStorage.setItem("pass", "checking");
        return ( 
             <div id="content" className="content">
                
                    <div class="panel">
                        <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                        <div className="col-md-12">
                                            <div class="form-inline">
                                                <div class="form-group m-r-10" >
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        selectsStart
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onChange={this.handleChangeStart}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div class="form-group m-r-10" >
                                                    <select className="form-control"  onChange={this.textchange} name="selectedBranch">
                                                        <option value="">---Select branch---</option>
                                                        {this.state.branch.map(branch =>
                                                        <option value={branch.id}>{branch.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div class="form-check m-r-10">
                                                    <button type="submit" class="btn btn-sm btn-primary m-r-5" onClick={this.submit}>Submit</button>
                                                </div>

                                                <div class="form-check m-r-10">
                                                    <p>Total: {this.state.checking.length}</p>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                              <br></br>

                                <div class="tableFixHead">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Fieldman</th>
                                            <th>Job order</th>
                                            <th>Meter number</th>
                                            <th>Meter Condition</th>
                                            <th>Seal Condition</th>
                                            <th>Primises Condition</th>
                                            <th>Reading</th>
                                            <th> </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.checking.map((checking, i)=>
                                            <tr key={i}>
                                                <td>{checking.user_fname} {checking.user_lname}</td>
                                                <td>{checking.jo_number}</td>
                                                <td>{checking.meter_number}</td>
                                                <td>{checking.meter_condition}</td>
                                                <td>{checking.seal_condition}</td>
                                                <td>{checking.premises_condition}</td>
                                                <td>{checking.meter_reading}</td>
                                                <td><button class="btn btn-sm btn-primary m-r-5" name={checking.field_audit_id} onClick={this.done}>Done</button></td>
                                            </tr>
                                            )}
                                            
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                    </div>
                              

                </div>
             </div>
         );
    }
}
 
export default Checking;