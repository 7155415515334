import {combineReducers} from 'redux'
import Payroll from '../reducers/payroll'
// import Clients from '../reducers/user_reducer'
// import Devices from '../reducers/device_reducer'
// import Dummy from '../reducers/dummy_reducer'
// import Payment from '../reducers/payment_reducer'



export default combineReducers({
    Payroll:Payroll,
   
  
})