import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalValue: [],
            modalClick: [],
        };
    }


    modal() {
        const { datas } = this.props;
        let data = [];
        data.push(datas);
        this.setState({ modalValue: datas });
    }

    componentDidMount() {
        this.modal();
    }

    modalClick = e => {
        let data = [];
        data.push(e);
        this.setState({ modalClick: data });
    }



    render() {
        console.log(this.state.modalClick)
        return (

            <div>
                {this.state.modalValue !== undefined ?
                    <div>
                        {this.state.modalValue.map(c =>
                            <div className="stats-link">
                                <a href="javascript:;" data-target={c.card} data-toggle="modal" onClick={() => this.modalClick(c)}>View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                            </div>
                        )}
                    </div>
                    :
                    <div></div>
                }
                {this.state.modalClick.map(mv => {
                    if (mv.card === "#demo-modal-sm") {
                        return <div id="demo-modal-sm" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4>{mv.cardname}</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {mv.cardbody}
                                    </div>
                                    <div className="modal-footer">
                                        MODAL-FOOTER
                                </div>
                                </div>
                            </div>
                        </div>
                    } else if (mv.card === "#demo-modal-md") {
                        return <div id="demo-modal-md" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4>{mv.cardname}</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {mv.cardbody}
                                    </div>
                                    <div className="modal-footer">
                                        MODAL-FOOTER
                            </div>
                                </div>
                            </div>
                        </div>
                    } else if (mv.card === "#demo-modal-lg") {
                        return <div id="demo-modal-lg" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog  modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4>{mv.cardname}</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {mv.cardbody}
                                    </div>
                                    <div className="modal-footer">
                                        MODAL-FOOTER
                            </div>
                                </div>
                            </div>
                        </div>
                    }
                }
                )}

            </div>
        );
    }
}
export default Modal;