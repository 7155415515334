import React, { Component } from 'react';
import readXlsxFile from 'read-excel-file'
import { Scrollbars } from 'react-custom-scrollbars';
// import swal from "sweetalert/dist/sweetalert.min.js";

import {getData} from '../../api/api';

import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadData : [],
            data : [],
            dataHead : []
        }

        this.uploadAndConvert = this.uploadAndConvert.bind(this);
        this.selectData = this.selectData.bind(this);
        this.uploadToDatabase = this.uploadToDatabase.bind(this);
    }

    uploadAndConvert(e){
        readXlsxFile(e.target.files[0]).then((row) =>{

            const data = [];
            const header = row[10];

            for(let i = 12; i < row.length; i++){
                data.push({
                    address: row[i][0],
                    clientName : row[i][1],
                    meterNum : row[i][2],
                    type : row[i][3],
                    prevRdg: row[i][4],
                    presRdg: row[i][5],
                    consumption : row[i][5]-row[i][4],
                    time : row[i][6]
                });
            }

            this.setState({
                uploadData : data,
                dataHead : header
            })
        })
    }

    selectData = () =>{
        getData('anlz/selectData').then(result => {
            this.setState({

            })
        })
    }

    uploadToDatabase(e){
        e.preventDefault();
        const data = this.state.uploadData 

        getData('anlz/addData', data).then(result => {
            // console.log(result);
            if(result === 'Success'){
                // console.log('Success');
            }else{
                // console.log('Error');
            }
        })
    }
  
    render() {
        return (
            <div className="content" id="content">
                <div className="panel m-t-10 " >
                    <div className="panel-heading">
                        <div class="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i class="fas fa-coins"></i>&nbsp;Create Report</h4>

                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Anlz</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Create Report</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left m-r-4 bg-green-transparent-9 fas fa-plus-circle" data-target="#newData" data-toggle="modal">&nbsp;New&nbsp;Data</button>
                                <button className="btttn pull-left bg-blue-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;Filter</button>
                            </div>
                        </div>

                        <Scrollbars style={{ height: 350, marginTop:20 }}>
                            <table className="table table-bordered">
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>Meter Number</th>
                                        <th>Type</th>
                                        <th>Previous Reading</th>
                                        <th>Present Reading</th>
                                        <th>Consumption</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map(data => 
                                        <tr data-target="#demo-lg-modal-2" data-toggle="modal">
                                            <td>{data.meterNum}</td>
                                            <td>{data.type}</td>
                                            <td>{data.prevRdg}</td>
                                            <td>{data.presRdg}</td>
                                            <td>{data.consumption}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Scrollbars>

                    </div>

                    <div id="newData" className="modal w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-sm modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Add Data</h4>
                                    <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={this.uploadToDatabase}>
                                        <fieldset>
                                            <div className="form-group">
                                                <label for="exampleInput\Email1">Upload Excel File</label>
                                                <input type="file" className="form-control" id="input" onChange={this.uploadAndConvert} style={{ borderStyle: 'none' }} />
                                            </div>
                                            <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                                &nbsp;Create
                                            </button>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default Home;