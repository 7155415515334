import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import PAGE from'./employeeDetails'

// import console = require('console');
class Holidays extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterEndDate: new Date(),

            filterStartDate: new Date(),
            type: "",
            description: "",
            branches: [],
            holidayType: [],
            holiday: [],
            $dateData: [],
            company: "",
            companies: [],
            payrollData: [],
            branch_select: [],
            alert: false,
            alertWarning: false,
            branches: [],
            branch_selected: "",
            payroll_data: [],
            Branch: "",
            MonthPeriod: "",
            showEmpPayroll: false,
            searchDriver: "",
            hide: true,
            hideCompany: true,
            refresh: false,
            col: "col-md-12",
            bgcolor1: "transparent",
            bgcolor2: "#404040",
            bgcolor3: "transparent",
            bgcolor4: "transparent",
            txtcolor1: "black",
            txtcolor2: "white",
            txtcolor3: "black",
            txtcolor4: "black",
            navbar: "navigation2",

        }

        this.handleChange = this.handleChange.bind();
        this.changeDay = this.changeDay.bind();
    }
    handleChange = (e) => {

        getData("users/getMonthHoliday", { month: e, u_id: localStorage.getItem('u') })
            .then(response => {
                console.log(response)
                this.setState({ holiday: response.data })
                var arrayDate = [];
                for (let i = 0; i < response.data.length; i++) {
                    var date = new Date(response.data[i].holiday_date);
                    arrayDate.push(date);
                }
                this.setState({ dateData: arrayDate })
                // this.setState({holiday:response.data})
                console.log(arrayDate);
            })
    }
    changeDay = (e) => {
        getData("users/getHoliday", { day: e, u_id: localStorage.getItem('u') })
            .then(response => {
                console.log(response);

                this.setState({ holiday: response.data })

            })

    }
    filterDateStart = e => {
        // console.log(e)
        this.setState({
            filterStartDate: e
        });

    }

    componentDidMount() {
        this.checkPagePrivilege();
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.setState({ filterStartDate: firstDay })
        this.getHoliday();
        this.getHolidayDetails();
        this.getUser();

    }
    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin" })
            .then(response => {
                //    console.log(response);
                this.setState({ companies: response.companies })

            })
    }
    getHoliday() {
        getData("humanResource/getholidayRates")
            .then(response => {
                // console.log(response);  
                this.setState({ holidayType: response.data })
            })
    }
    getHolidayDetails() {

        getData("humanResource/getHolidays", { date: this.state.filterStartDate, u_id: localStorage.getItem('u') })
            .then(response => {
                console.log(response);
                this.setState({ holiday: response.data })
                var arrayDate = [];
                for (let i = 0; i < response.data.length; i++) {
                    var date = new Date(response.data[i].holiday_date);
                    // this.state.dateData.push(date);
                    arrayDate.push(date);
                }
                this.setState({ dateData: arrayDate })
                console.log(response);
            })
    }

    showBYType = e => {
        e.preventDefault();
        var types = e.target.value
        this.setState({ type: e.target.value })
        // console.log(e.target.value);
        if (types == 1) {
            this.setState({ col: 'col-md-12' });
            this.setState({ hideCompany: true });
        } else {
            this.setState({ col: 'col-md-8' });
            this.setState({ hideCompany: false });
        }
    }

    checkPagePrivilege() {
        var route = "#/payroll";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                // console.log(response);
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    passCompany = e => {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ company: options[i].value })
            }
        }
        this.setState({ hide: false });
        getData("users/getBranch", company)
            .then(result => {
                this.setState({ branches: result })
                // console.log(result)

            });
    }


    SubmitForm() {

        var data = { date: this.state.filterStartDate, type: this.state.type, description: this.state.description, company: this.state.company, branches: this.state.branch_select }

        getData("humanResource/Insertholiday", { user_id: localStorage.getItem("u"), data: data })
            .then(response => {
                console.log(response);
                this.getHoliday();
                this.getHolidayDetails();
                this.setState({ description: "" })

            })
    }

    render() {
        // console.log(this.state.branch_select)
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }

        return (
            <div id="content " className="content" >
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                {this.state.navbar === "navigation1" &&
                                    <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Payroll Report</h4>
                                }
                                {this.state.navbar === "navigation2" &&
                                    <h4 className="page-header"><i class="far fa-snowflake"></i>&nbsp;Holidays</h4>
                                }
                                {this.state.navbar === "navigation3" &&
                                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Navigation 3</h4>
                                }
                                {this.state.navbar === "navigation4" &&
                                    <h4 className="page-header"><i className="far fa-user text-dark" />&nbsp;Navigation 4</h4>
                                }
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Payroll Report</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Holidays</a></li>
                                        }
                                        {this.state.navbar === "navigation3" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Navigation3</a></li>
                                        }
                                        {this.state.navbar === "navigation4" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Navigation4</a></li>
                                        }
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav " >
                                <div name="navigation1" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor2: "transparent", txtcolor2: "black" })
                                        this.setState({ bgcolor3: "transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor4: "transparent", txtcolor4: "black" })
                                    }}>
                                    <a href="#/payroll" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}>Payroll</a>
                                </div>
                               
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollProfile" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Earnings/Deductions</a>
                                </div>
                                
                                <div name="navigation2" className="attendnav"
                                  
                                >
                                    <a href="#/governmentDues" >Government&nbsp;Dues</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollSettings" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Payroll&nbsp;Settings</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        {this.state.alert == true &&
                            <div className="row  w3-animate-zoom" >
                                <div className="col-md-12">
                                    <div className="panel" style={{ backgroundColor: "#2ecc71" }}>
                                        <a onClick={() => this.setState({ alert: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                        <div className="panel-body" >
                                            <div style={{ color: "white" }}>
                                                <i className="fa fa-check"></i>&nbsp;<label style={{ color: "white" }}>Success!</label>
                                                <p>Payroll generated successfully!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.alertWarning == true &&
                            <div>
                                <div className="row  w3-animate-zoom" >
                                    <div className="col-md-12">
                                        <div className="panel" style={{ backgroundColor: "#f39c12" }}>
                                            <a onClick={() => this.setState({ alertWarning: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                            <div className="panel-body" >
                                                <div style={{ color: "white" }}>
                                                    <i className="fa fa-exclamation"></i>&nbsp;<label style={{ color: "white" }}>Failed!</label>
                                                    <p>Payroll Date is already generated!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                            <div >
                                <button className="btttn bg-green-transparent-9 m-b-10 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;Add Holiday</button>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" }}>
                                <div className="radio radio-css radio-inline">
                                    <input type="radio" name="radio_css_inline" id="inlineCssRadio1" value="option1" checked />
                                    <label for="inlineCssRadio1">Regular Holidays</label>
                                </div>
                                <div className="radio radio-css radio-inline">
                                    <input type="radio" name="radio_css_inline" id="inlineCssRadio2" value="option2" />
                                    <label for="inlineCssRadio2">Special Holiday</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <Scrollbars style={{ height: 400 }} className="table-responsive">
                                    <table class="table table-bordered " style={{ fontSize: 12 }}>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Date</th>
                                                <th>Branch</th>
                                                <th>Type</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {this.state.holiday.map((val, index) =>{
                                                
                                               return  <tr key={index}>
                                                 <td>{index + 1}</td>
                                                 <td><i className=" fa  fa-calendar-alt" style={{ color: "#009432" }}></i>&nbsp;&nbsp;{val.holiday_date}</td>
                                                 <td>{val.holiday_type.toUpperCase() == 'REGULAR HOLIDAY'?'ALL':val.branch_name}</td>
                                                 <td>{val.holiday_type.toUpperCase()}</td>
                                                 <td>{val.holiday_description.toUpperCase()}</td>
                                             </tr>
                                            }

                                               
                                            )}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                            <div className="col-md-3 m-l-30">
                                <DatePicker
                                    inline
                                    selected={this.state.startDate}
                                    highlightDates={this.state.dateData}
                                    // onChange={this.handleChange}
                                    onChange={this.changeDay}
                                    onMonthChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="panel-title">Add Holiday</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                    <div className="row">
                                        {/* <div className={this.state.col}> */}
                                        <div className="col-md-12">
                                            <form action="/" method="POST">
                                                <fieldset>
                                                    <div class="form-group row m-b-15">
                                                        <label class="col-md-3 col-form-label">Select Date:</label>
                                                        <div class="col-md-7">
                                                            <DatePicker
                                                                selected={this.state.filterStartDate}
                                                                onChange={this.filterDateStart}
                                                                className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row m-b-15">
                                                        <label class="col-md-3 col-form-label">Holiday Type:</label>
                                                        <div class="col-md-7">
                                                            <select className="form-control" onChange={this.showBYType} required>
                                                                <option></option>
                                                                {this.state.holidayType.map((val) =>
                                                                    <option value={val.holiday_rate_id}>{val.holiday_type}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {this.state.hideCompany === false &&
                                                        <div>
                                                            <div class="form-group row m-b-15">
                                                                <label class="col-md-3 col-form-label">Select Company:</label>
                                                                <div class="col-md-7">
                                                                    <select className="form-control" onChange={this.passCompany}>
                                                                        <option></option>
                                                                        {this.state.companies.map((val, index) =>
                                                                            <option key={index} value={val.company_id}>{val.company_name}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {this.state.hide === false &&
                                                                <div class="form-group row m-b-15">
                                                                    <label class="col-md-3 col-form-label">Select Branch:</label>
                                                                    <div class="col-md-7">
                                                                        <Scrollbars style={{ height: 100 }}>
                                                                            <table width="100%">
                                                                                <tbody>
                                                                                    {this.state.branches.map((val, index) =>
                                                                                        <tr key={index}>
                                                                                            <td width="10%"><input type="checkbox" onChange={() => {
                                                                                                var branch_selected = [];
                                                                                                var match = false;
                                                                                                for (let b = 0; b < this.state.branch_select.length; b++) {
                                                                                                    if (this.state.branch_select[b].bid == val.bid) {
                                                                                                        this.state.branch_select.splice(parseInt(b), 1)
                                                                                                        match = true;
                                                                                                        this.setState({ refresh: true })
                                                                                                    }
                                                                                                }
                                                                                                if (match === false) {
                                                                                                    branch_selected = { bid: val.bid, branch_name: val.bname }
                                                                                                    this.state.branch_select.push(branch_selected);
                                                                                                    this.setState({ refresh: true });
                                                                                                }
                                                                                            }} /></td>
                                                                                            <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.bname}</p></td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </Scrollbars>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    }
                                                    <div class="form-group row m-b-15">
                                                        <label class="col-md-3 col-form-label">Description:</label>
                                                        <div class="col-md-7">
                                                            <textarea rows="4" onChange={this.textchange} type="text" name="description" placeholder="Please Specify Holiday..." value={this.state.description} className="form-control" style={{ width: "100%", height: "100%" }} />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                <div className="pull-right m-t-10">
                                    <button className="bttnhol bg-green-lighter" onClick={() => this.SubmitForm()} data-dismiss="modal" aria-label="Close">
                                        <span className="fa fa-plus fa-sm"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Holidays;