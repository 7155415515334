import React, { Component } from 'react';

class MyProfrile extends Component {
    constructor(props) {
        super(props);
        this.state = {

            refresh: false
        };
    }

    componentDidMount() {


    }
    render() {

        return (
            <div id="content" className="content" >
                <div class="profile">
                    <div style={{ marginTop: '-40px' }}>
                        <div class="profile-header">
                            <div class="profile-header-cover"></div>
                            <div class="profile-header-content">
                                <div class="profile-header-img">
                                    <img src="../assets/img/user/user-13.jpg" alt="" />
                                </div>
                                <div class="profile-header-info">
                                    <h4 class="mt-0 mb-1">Marco Manalo</h4>
                                    <p class="mb-2">Frontend Developer</p>
                                    <a href="#" class="btn btn-xs btn-yellow">Edit/Update Profile</a>
                                </div>
                            </div>
                            <ul class="profile-header-tab nav nav-tabs">
                                <li class="nav-item"><a href="#profile-post" class="nav-link active" data-toggle="tab">NAV</a></li>
                                <li class="nav-item"><a href="#profile-about" class="nav-link" data-toggle="tab">NAV</a></li>
                                <li class="nav-item"><a href="#profile-photos" class="nav-link" data-toggle="tab">NAV</a></li>
                                <li class="nav-item"><a href="#profile-videos" class="nav-link" data-toggle="tab">NAV</a></li>
                                <li class="nav-item"><a href="#profile-friends" class="nav-link" data-toggle="tab">NAV</a></li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="bg-gray">
                    <div class="tab-pane fade show active" id="profile-post">
                        <ul class="timeline">
                            <li>
                                <div class="timeline-time">
                                    <span class="date">today</span>
                                    <span class="time">04:20</span>
                                </div>
                                <div class="timeline-icon">
                                    <a href="javascript:;">&nbsp;</a>
                                </div>
                                <div class="timeline-body">
                                    <div class="timeline-header">
                                        <span class="userimage"><img src="../assets/img/user/user-12.jpg" alt="" /></span>
                                        <span class="username"><a href="javascript:;">Marco Manalo</a> <small></small></span>
                                        <span class="pull-right text-muted">18 Views</span>
                                    </div>
                                    <div class="timeline-content">
                                        <p>Dear Employee,<br />Recently, we’ve received some feedback regarding the last update of Pacific Systems Apps.</p>
                                        <p>What's New</p>
                                        <ol>
                                            <li>
                                                Add Home page for postings announcement and events
 <ul>
                                                    <li>add comment feature</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Add overtime column in the export timesheet
 </li>
                                            <li>
                                                Improve the User-interface design
 <ul>
                                                    <li>buttons</li>
                                                    <li>navigation bar</li>
                                                    <li>breadcrumbs</li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <p>If you have encountered any issues, do not hesitate to contact us.<br />Thank you for your continued support to Pacific Systems Apps</p>





                                    </div>
                                    <div class="timeline-likes">
                                        <div class="stats">
                                            <span class="fa-stack fa-fw stats-icon">
                                                <i class="fa fa-circle fa-stack-2x text-danger"></i>
                                                <i class="fa fa-heart fa-stack-1x fa-inverse t-plus-1"></i>
                                            </span>
                                            <span class="fa-stack fa-fw stats-icon">
                                                <i class="fa fa-circle fa-stack-2x text-primary"></i>
                                                <i class="fa fa-thumbs-up fa-stack-1x fa-inverse"></i>
                                            </span>
                                            <span class="stats-total">4.3k</span>
                                        </div>
                                        <div class="stats-right">
                                            <span class="stats-text">259 Shares</span>
                                            <span class="stats-text">21 Comments</span>
                                        </div>
                                    </div>
                                    <div class="timeline-footer">
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-thumbs-up fa-fw fa-lg m-r-3"></i> Like</a>
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-comments fa-fw fa-lg m-r-3"></i> Comment</a>
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-share fa-fw fa-lg m-r-3"></i> Share</a>
                                    </div>
                                    <div class="timeline-comment-box">
                                        <div class="user"><img src="../assets/img/user/user-12.jpg" /></div>
                                        <div class="input">
                                            <form action="">
                                                <div class="input-group">
                                                    <input type="text" class="form-control rounded-corner" placeholder="Write a comment..." />
                                                    <span class="input-group-btn p-l-10">
                                                        <button class="btn btn-primary f-s-12 rounded-corner" type="button">Comment</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="timeline-time">
                                    <span class="date">24 February 2014</span>
                                    <span class="time">08:17</span>
                                </div>
                                <div class="timeline-icon">
                                    <a href="javascript:;">&nbsp;</a>
                                </div>
                                <div class="timeline-body">
                                    <div class="timeline-header">
                                        <span class="userimage"><img src="../assets/img/user/user-12.jpg" alt="" /></span>
                                        <span class="username">Marco Manalo</span>
                                        <span class="pull-right text-muted">1,282 Views</span>
                                    </div>
                                    <div class="timeline-content">
                                        <p class="lead">
                                            <i class="fa fa-quote-left fa-fw pull-left"></i>
                                            OKAY LANG MASAKTAN, BASTA HINDI MASAKIT
	<i class="fa fa-quote-right fa-fw pull-right"></i>
                                        </p>
                                    </div>
                                    <div class="timeline-footer">
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-thumbs-up fa-fw fa-lg m-r-3"></i> Like</a>
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-comments fa-fw fa-lg m-r-3"></i> Comment</a>
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-share fa-fw fa-lg m-r-3"></i> Share</a>
                                    </div>
                                    <div class="timeline-comment-box">
                                        <div class="user"><img src="../assets/img/user/user-12.jpg" /></div>
                                        <div class="input">
                                            <form action="">
                                                <div class="input-group">
                                                    <input type="text" class="form-control rounded-corner" placeholder="Write a comment..." />
                                                    <span class="input-group-btn p-l-10">
                                                        <button class="btn btn-primary f-s-12 rounded-corner" type="button">Comment</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="timeline-time">
                                    <span class="date">10 January 2014</span>
                                    <span class="time">20:43</span>
                                </div>
                                <div class="timeline-icon">
                                    <a href="javascript:;">&nbsp;</a>
                                </div>
                                <div class="timeline-body">
                                    <div class="timeline-header">
                                        <span class="userimage"><img src="../assets/img/user/user-12.jpg" alt="" /></span>
                                        <span class="username">Marco Manalo</span>
                                        <span class="pull-right text-muted">1,021,282 Views</span>
                                    </div>
                                    <div class="timeline-content">
                                        <h4 class="template-title">
                                            <i class="fa fa-map-marker-alt text-danger fa-fw"></i>
                                            795 Folsom Ave, Suite 600 San Francisco, CA 94107
	</h4>
                                        <p>In hac habitasse platea dictumst. Pellentesque bibendum id sem nec faucibus.
                                        Maecenas molestie, augue vel accumsan rutrum, massa mi rutrum odio,
 id luctus mauris nibh ut leo.</p>
                                        <p class="m-t-20">
                                            <img src="../assets/img/gallery/gallery-5.jpg" alt="" />
                                        </p>
                                    </div>
                                    <div class="timeline-footer">
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-thumbs-up fa-fw fa-lg m-r-3"></i> Like</a>
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-comments fa-fw fa-lg m-r-3"></i> Comment</a>
                                        <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-share fa-fw fa-lg m-r-3"></i> Share</a>
                                    </div>
                                    <div class="timeline-comment-box">
                                        <div class="user"><img src="../assets/img/user/user-12.jpg" /></div>
                                        <div class="input">
                                            <form action="">
                                                <div class="input-group">
                                                    <input type="text" class="form-control rounded-corner" placeholder="Write a comment..." />
                                                    <span class="input-group-btn p-l-10">
                                                        <button class="btn btn-primary f-s-12 rounded-corner" type="button">Comment</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="timeline-icon">
                                    <a href="javascript:;">&nbsp;</a>
                                </div>
                                <div class="timeline-body">
                                    Loading...
	                            </div>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        );
    }
}

export default MyProfrile;