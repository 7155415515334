import React, { Component } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";
import User from "./users/index"
import Accomplishments from "./accomplishments/accomplishments"
// import Maps from "./maps/index"
import History from "./history/index"
import Clients from "./clients/index"
import DLine3 from './users/charts/d_line';
import Deployment from './deployment';
import Assign from './deployment/assign';
import SaleSettings from './settings/index';
// import Meter from "./meter/index";
class Routes extends Component {
    state = {}
    render() {
        return (
            <Router>
                <div>
                    <Route path="/customers/users/" component={User} />
                    <Route path="/customers/accomplishments/" component={Accomplishments} />
                    {/* <Route path="/customers/maps/" component={Maps} /> */}
                    <Route path="/customers/history/" component={History} />
                    <Route path="/customers/clients" component={Clients} />
                    <Route path="/customers/deployment" component={Deployment} />
                    <Route path="/customers/assign" component={Assign}/>
                    <Route path="/customers/settings" component={SaleSettings}/>
                   
                </div>
            </Router>
        );
    }
}
export default Routes;