import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom'
class admin_AddPriv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // branchesData:"hehehe",
            branchesData: [],
            Users: [],
            Groups: [],
            Groupelements: [],
            GroupPages: [],
            check_page: [],
            searchDriver: '',
            name: '',
            id: '',
            group_id: '',
            parent_ids: [],
            new_privelege: [],

            group: [],
            parent: [],
            groups: "",
            parents: "",
            nav_name: "",
            icon: "",
            route: "",
            nav: [],
            apps: [],
            projects: [],
            team: [],
            humanresourcces: [],
            reports: [],
            setting: [],
            usersSeting: [],

            Checkpriv: [],
            pages1: [],
            edits: false,
            showadd: false,
            showcontroller: true,
            refresh: false,

            firstname: '',
            middlename: '',
            lastname: '',
            username: '',
            contact: '',
            Bcolor: '',


            show_tab: true,
            app_nav: [],
            allApps: [],
            appName: "",
            check_app_nav: [],
            clickAppData: [],
            myapp: [],
            redirect: false,
            userdata: [],
            check: [],
            hideDelete: [],
            employeDetails: [],
            companies: [],
            branch_id: "",
            handleBranches: [],
            alert: false,
            alertWarning: false,
            commentEn: false,
            title: "List of Employees",
            title2: "",
            resetUser:""

        }
        this.textchange = this.textchange.bind(this);
        this.Addpriveledge = this.Addpriveledge.bind(this);
        this.onChangeSelectCompany = this.onChangeSelectCompany.bind(this);
        this.onChangeSelectBranch = this.onChangeSelectBranch.bind(this);

    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    AddFieldman = e => {
        e.preventDefault();
        const save = {
            firstname: this.state.firstname,
            middlename: this.state.middlename,
            lastname: this.state.lastname,
            contact: this.state.contact,
            username: this.state.username,
        }
        getData("users/addingfieldman", save)
            .then(result => {
                console.log(result)

                let user = result;
                if (user.res === "wrong") {
                    alert('FAILED TO ADD USER!')
                } if (user.res === "success") {
                    alert('SUCCESSFULLY ADDED');
                }
            });
    };

    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }

    Addpriveledge = (pages, type) => {

        var data = [];
        var submit = [];
        if (type == 1) {
            data.push({
                priveleges: pages,
                user_id: this.state.id,
                type: type
            }
            )

        } else {
            data.push({
                priveleges: pages,
                user_id: pages.user_id,
                navigation_id: pages.navigation_id,
                type: type
            }
            )

        }

        submit.push(data);
        getData("users/AddPrivelege", data)
            .then(response => {
                console.log(response)
                if (response.res == 'success') {
                    alert('Successfully Added!');
                    this.passname(this.state.userdata);
                }
                if (response.res == 'success2') {
                    alert('Successfully Removed!');
                    this.passname(this.state.userdata);
                }
                if (response.res == 'failed') {
                    alert('Failed to Add Privilege!');
                }
                // console.log(response);
            })
    }

    edit = (data) => {

        var pages = [];
        pages.push(data);
        this.state.pages1.push(data);
        this.setState({ refresh: true })
        this.Addpriveledge(pages, "1");
        // console.log(this.state.pages1);
        // this.setState({nav_name:data.page_name,icon:data.icon,route:data.href,groups:data.group_id,parents:data.parent_id,edits:edit})
        // this.filter_group(data.group_id);
    }


    passname(value) {

        this.setState({ name: value.completename, userdata: value, check: [] });
        this.setState({ id: value.user_id });
        this.setState({ showcontroller: false });
        getData("users/CheckUserpriv", value.user_id)
            .then(response => {
                console.log(response);
                this.setState({ Checkpriv: response.privileges });
            })
        getData("users/getPrivilege2", value.user_id).then(result => {
            this.setState({ myapp: result.app_id })
        })

    }
    checkPagePrivilege() {
        var route = "#/controller/admin_control";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    componentDidMount() {
        this.checkPagePrivilege();
        this.getUser();
        this.getCompanies();

    }

    getUser() {
        var app_id = JSON.parse(localStorage.getItem('app_id_priv'))
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin" })
            .then(response => {
                this.setState({ Users: response.users })
                this.setState({ Groups: response.groups })
            })
        getData("users/Get_nav_admin", { u_id: localStorage.getItem("u"), apps: app_id })
            .then(response => {
                console.log(response);
                this.setState({ allApps: response.all_apps, nav: response.nav, apps: response.apps, projects: response.projects, team: response.team, humanresourcces: response.humanresource, reports: response.report, setting: response.setting, usersSeting: response.users })
            })
        // this.getNav()

    }

    // getNav() {
    //     getData("users/Get_nav").then(response => {

    //         this.setState({ allApps: response.all_apps })
    //     })
    // }

    onclick_tab(type) {
        if (type == 1) {
            this.setState({ show_tab: true })
        } else if (type == 2) {
            this.setState({ show_tab: false })
        } else {
            this.setState({ showcontroller: true })
        }
    }
    app_id = (data) => {
        this.setState({ appName: data.category_details, clickAppData: data })
        var u_id = this.state.id;
        // getData("users/getApp_Nav", { app_id: data.app_id, company_id: data.company_id }).then(response => {
        //     console.log(response);
        //     this.setState({ app_nav: response.data, appName: response.app_name })
        // })
        getData("users/getappHomeNav", { app_id: data.app_id, user_id: u_id }).then(result => {

            this.setState({ check_app_nav: result.appHomeNav })
        })
        getData("users/getappHomeNav", { app_id: data.app_id, user_id: localStorage.getItem('u') }).then(result => {

            this.setState({ app_nav: result.result, appName: result.app_name })
        })
    }

    access_addNav = (data) => {
        var u_id = this.state.id;
        getData("users/AddAppPrivelege", { details: data, user_id: u_id })
            .then(response => {
                this.app_id(this.state.clickAppData);
            })
    }
    removeAppNav = (data) => {
        var u_id = this.state.id;
        getData("users/DeleteAppPrivelege", { details: data, user_id: u_id })
            .then(response => {
                this.app_id(this.state.clickAppData);

            })
    }
    DeleteUser() {
        this.state.hideDelete.push(this.state.deleteUser)
        this.setState({ refresh: true })
        getData("users/deletefieldman", this.state.deleteUser)
            .then(result => {
                if (result == true) {
                    alert('Deleted');
                    this.getUser();
                } else {

                }

            })

    }
    employeeDetails(data) {
        var details = [data]
        this.setState({ alert: false, alertWarning: false })
        getData("users/CheckUserpriv", data.user_id)
            .then(response => {
                var commentEn = false;
                for (let index = 0; index < response.commentEnable.length; index++) {
                    if (response.commentEnable[index].comment_enable == 1) {
                        commentEn = true;
                    }
                }

                this.setState({ employeDetails: details, edit: false, handleBranches: response.bRanch_id, commentEn: commentEn })
                console.log(response);
            })
    }
    // getCompanies() {
    //     getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin" })
    //         .then(response => {

    //             console.log(response);
    //             this.setState({ companies: response.companies, })

    //         })
    //     // getData("users/Get_nav")
    //     //     .then(response => {

    //     //     })
    //     // this.getNav()

    // }
    commentenable = (uid, type) => {

        getData("users/updateComment", { u_id: uid, type: type })
            .then(response => {
                var commentEn = false;
                for (let index = 0; index < response.data.length; index++) {
                    if (response.data[index].comment_enable == 1) {
                        commentEn = true;
                    }
                }
                this.setState({ commentEn: commentEn })


            });
    }

    onChangeSelectCompany(e) {
        this.setState({ branchesData: [] })

        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);

                this.setState({ company_id: options[i].value })

            }
        }
        getData("users/getBranch", company)
            .then(result => {
                setTimeout(() => { this.setState({ branchesData: result }) }, 300)

            });
    }
    onChangeSelectBranch(e) {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                // console.log(options[i].value)
            }
        }
        this.setState({ branch_id: value })

    }

    HandleBranch = (val) => {
        var data = { user_id: val.user_id, bid: this.state.branch_id, company_id: this.state.company_id }
        console.log(data)
        getData("users/BranchPriviledge", data)
            .then(response => {
                console.log(response)
                if (response.success == true) {
                    alert('success')
                    this.setState({ alert: true })
                } else {
                    this.setState({ alertWarning: true })
                }
                setTimeout(() => {
                    if (this.state.alert == true) {
                        this.setState({ alert: false })
                    }
                    if (this.state.alertWarning == true) {
                        this.setState({ alertWarning: false })
                    }
                }, 10000)
            });
    }
    _resetPassword = (id) => {
        getData("users/resetPassword", { u_id: id })
            .then((response) => {
                if (response.response == true) {
                    alert('Reset Password Successfully!')
                }

                console.log(response);
            })
    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                console.log(response.data);
                this.setState({ companies: response.data })
            })
    }

    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        // let SearchUser = this.state.Users.filter(
        //     (usr) => {
        //         return usr.completename.toLowerCase().indexOf(
        //             this.state.searchDriver.toLocaleLowerCase()) !== -1
        //     }
        // )
        let EmployeeSearch = this.state.Users.filter(
            (files) => {
                return files.completename.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (

            <div id="content" className="content">
                <div className="row text-center" style={{ marginTop: "-40px" }}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item1</label>
                        </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item2</label>
                        </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item3</label>
                        </a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>

                        <a href="javascript:;">
                            <i className="fa fa-clock fa-lg text-success"></i>&nbsp;<label>Nav item4</label>
                        </a>

                    </div>
                </div><br />
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">{this.state.title}</h1>
                    </div>
                    <div className="col-sm-6">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Settings</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Privilege Option</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>List of Employees</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>{this.state.title2}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {this.state.alert == true &&
                    <div className="row  w3-animate-zoom" >
                        <div className="col-md-12">
                            <div className="panel" style={{ backgroundColor: "#2ecc71" }}>
                                <a onClick={() => this.setState({ alert: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                <div className="panel-body" >
                                    <div style={{ color: "white" }}>
                                        <i className="fa fa-check"></i>&nbsp;<label style={{ color: "white" }}>Success!</label>
                                        <p>Branch added successfully!</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.alertWarning == true &&
                    <div>

                        <div className="row  w3-animate-zoom" >
                            <div className="col-md-12">
                                <div className="panel" style={{ backgroundColor: "#f39c12" }}>
                                    <a onClick={() => this.setState({ alertWarning: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                    <div className="panel-body" >
                                        <div style={{ color: "white" }}>
                                            <i className="fa fa-exclamation"></i>&nbsp;<label style={{ color: "white" }}>Failed!</label>
                                            <p>Branch is already exist!</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                {this.state.showcontroller == true &&
                    <div className="row" >
                        <div className="col-md-12">
                            <div className="panel panel-default">
                                {/* <div className="panel-heading">
                                <span>List of Employees</span>
                                <span><button type="button" class="btn pull-right" data-target="#demo-lg-modal" data-toggle="modal"><i className="fa fa-plus" style={{color:'#fff', marginRight: 5}}></i>Add Report</button></span>    

                        </div> */}
                                <div className="panel-body">



                                    <div className="form-group pull-left col-md-4">
                                        {this.state.check.length == 0 &&
                                            <input id="demo-input-search2" type="text" placeholder="Search Name" className="form-control" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        }
                                        {this.state.check.length != 0 &&
                                            <input id="demo-input-search2" type="text" placeholder="Search Name" className="form-control" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} readOnly />
                                        }

                                    </div>
                                    <div className="pull-right">
                                        {/* <div class="input-group" >
                                            <button type="button" class="btn-xs btn-success">Add Form</button>
                                            <span class="input-group-addon" data-target="#demo-lg-modal" data-toggle="modal"><i className="fa fa-plus"></i></span>
                                        </div> */}
                                        {/* <div class="btn-group " data-target="#demo-lg-modal" data-toggle="modal">
                                            <a href="javascript:;" class="btn btn-success">Add Form</a>
                                            <a href="javascript:;" class="btn btn-default"><i className="fa fa-plus"></i></a>
                                        </div> */}

                                        <button className="bttnaform bg-green-lighter" data-target="#demo-lg-modal" data-toggle="modal">
                                            <span className="fa fa-folder-plus"></span>
                                        </button>
                                    </div>



                                    {/* <hr style={{ height: "2px", border: 10 }} /> */}

                                    <Scrollbars style={{ height: 460 }}>

                                        <table className="table" style={{ fontSize: 12 }}>
                                            <thead className="tableFixHead">
                                                <tr>
                                                    <th>User ID</th>
                                                    <th>Employee Name</th>
                                                    <th>Company</th>
                                                    <th>Position</th>
                                                    <th>Status</th>
                                                    <th>Contact</th>
                                                    <th style={{ zIndex: 2 }}>Action</th>
                                                </tr>
                                            </thead>
                                            {/* <Scrollbars style={{ height: 390 }}> */}
                                            {EmployeeSearch.map((val, index) => {
                                                var match = false;
                                                var match1 = false;
                                                var dataMatch = []
                                                // console.log(this.state.check);
                                                for (let i = 0; i < this.state.check.length; i++) {

                                                    if (this.state.check[i].user_id == val.user_id) {

                                                        match = true;
                                                    }
                                                }

                                                var hide = false;

                                                //    this.state.hideDelete.forEach(element => {

                                                //         if(element.user_id == val.user_id){
                                                //             hide = true;

                                                //         }
                                                //    });
                                                for (let i = 0; i < this.state.hideDelete.length; i++) {

                                                    if (this.state.hideDelete[i].user_id == val.user_id) {
                                                        hide = true;

                                                    }

                                                }
                                                if (hide === false)
                                                    return <tbody>
                                                        <tr>
                                                            <td>{val.user_id}</td>
                                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;
                               {/* <img src="../assets/img/user/user.jpg"  alt="Avatar" style={{borderRadius:"50%",width:"40px"}} />&nbsp;&nbsp;&nbsp;&nbsp; */}

                                                                {val.completename.toUpperCase()}</td>
                                                            {val.company_name == null &&
                                                                <td>{val.company_name}</td>
                                                            }
                                                            {val.company_name != null &&
                                                                <td>{val.company_name.toUpperCase()}</td>
                                                            }
                                                            {val.user_jobposition == null &&
                                                                <td>{val.user_jobposition}</td>
                                                            }
                                                            {val.user_jobposition != null &&
                                                                <td>{val.user_jobposition.toUpperCase()}</td>
                                                            }
                                                            <td>{}</td>

                                                            <td>{val.user_mobile}</td>
                                                            <td>
                                                                {/* <div className="btn-group">
                                                                    <button className="btn btn-xs pr-2 border border-success border-rigth-0" onClick={() => this.passname(val)}>
                                                                        <i className="fa fa-eye fa-sm ">View</i>
                                                                    </button>
                                                                    <div class="or "></div>
                                                                    <button data-target="#demo-lg-modal5 " data-toggle="modal" className="btn btn-xs pl-2 border border-success border-left-0" onClick={() => this.employeeDetails(val, true)}> <i className="fa fa-share-alt fa-sm">Branch</i></button>
                                                                </div> */}
                                                                <div className="btn-group">
                                                                    <button className="bttnview2 bg-blue-lighter" onClick={() => { this.passname(val); this.setState({ title2: "Navigation Privilege" }); this.setState({ title: "Navigation Privilege" }) }}>
                                                                        <span className="fa fa-hands-helping"></span>
                                                                    </button>
                                                                    <div class="or"></div>
                                                                    <button className="bttnbranch bg-green-lighter" onClick={() => this.employeeDetails(val, true)} data-target="#demo-lg-modal5 " data-toggle="modal">
                                                                        <span className="fa fa-hands"></span>
                                                                    </button>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                            })}

                                        </table>
                                    </Scrollbars>

                                    {/* </div> */}


                                </div>

                            </div>
                        </div>
                    </div>
                }
                {(this.state.showcontroller == false && this.state.show_tab == true) &&
                    <div >
                        <div className="row" >
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header">
                                        <div className="row justify-content-around">
                                            <div className="col-md-6  m-t-10">
                                                <h4 className="pull-left m-l-10 m-t-5">Name:&nbsp;{this.state.name}</h4>
                                            </div>
                                            <div className="col-md-6  m-t-10">
                                                <button class="bttnlapp bg-blue-lighter pull-right m-r-10 m-t-5" onClick={() => { this.onclick_tab(2); this.setState({ title2: "Applications Privilege" }); this.setState({ title: "Applications Privilege" }) }}>
                                                    <span className="fa fa-mobile-alt"></span>
                                                </button>
                                                <button class="bttnlemp bg-green-lighter pull-right m-t-5" onClick={() => { this.onclick_tab(3); this.setState({ title: "List of Employees" }); this.setState({ title2: "" }) }}>
                                                    <span className="fa fa-list-alt"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">

                                        <div>
                                            {
                                                <Scrollbars style={{ height: 340 }}>
                                                    <div className="panel-body">
                                                        <h6>Navigaitons</h6>

                                                        <table class="table table-condensed">
                                                            {this.state.nav.map((val, index) => {

                                                                // var match = false;
                                                                // for (let c = 0; c < this.state.Checkpriv.length; c++) {
                                                                //     if (this.state.Checkpriv[c].nav_priv_group == val.group_id && this.state.Checkpriv[c].nav_priv_parent == val.parent_id && this.state.Checkpriv[c].nav_priv_page_id == val.page_id) {
                                                                //         match = true;
                                                                //     }
                                                                // }
                                                                var hide = false;
                                                                var arrayValue = [];
                                                                for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                    if (this.state.Checkpriv[i].page_id == val.page_id || this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                        hide = true;
                                                                        arrayValue.push(this.state.Checkpriv[i])
                                                                    }
                                                                }
                                                                return <tbody>
                                                                    <tr key={index}>
                                                                        <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                            <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                        }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                        <td width="33%">{val.href}</td>
                                                                        <td width="33%">
                                                                            {hide == false &&
                                                                                <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                    <span className="fa fa-cloud-download-alt"></span>
                                                                                </button>
                                                                            }
                                                                            {hide == true &&
                                                                                <button className="bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                    <span className="fa fa-ban"></span>
                                                                                </button>
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            })}
                                                        </table>
                                                    </div>

                                                    <div className="panel-body">
                                                        <h6>Applications</h6>
                                                        <table class="table table-condensed">
                                                            {this.state.apps.map((val, index) => {
                                                                var hide = false;
                                                                var arrayValue = [];
                                                                for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                    if (this.state.Checkpriv[i].page_id == val.page_id && this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                        hide = true;
                                                                        arrayValue.push(this.state.Checkpriv[i])
                                                                    }
                                                                }
                                                                return <tbody>
                                                                    <tr key={index}>
                                                                        <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                            <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                        }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                        <td width="33%">{val.href}</td>
                                                                        <td width="33%">
                                                                            {hide == false &&
                                                                                <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                    <span className="fa fa-cloud-download-alt"></span>
                                                                                </button>
                                                                            }
                                                                            {hide == true &&
                                                                                <button className="bttnundo bg-red-lighter pull-right" onClick={() => {
                                                                                    this.Addpriveledge(arrayValue, "2");
                                                                                }}>
                                                                                    <span className="fa fa-ban"></span>
                                                                                </button>
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            })}
                                                        </table>
                                                    </div>
                                                    {this.state.projects.length != 0 &&
                                                        <div>
                                                            <hr />
                                                            <div className="panel-body">

                                                                <div>
                                                                    <h4>Department</h4>
                                                                    <hr style={{ height: "2px", border: 10 }} />
                                                                    <h6>Projects</h6>
                                                                </div>


                                                                <table class="table table-condensed">
                                                                    {this.state.projects.map((val, index) => {
                                                                        var hide = false;
                                                                        var arrayValue = [];
                                                                        for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                            if (this.state.Checkpriv[i].page_id == val.page_id && this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                                hide = true;
                                                                                arrayValue.push(this.state.Checkpriv[i])
                                                                            }
                                                                        }
                                                                        return <tbody>

                                                                            <tr key={index}>
                                                                                <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                                    <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                                }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                                <td width="33%">{val.href}</td>
                                                                                <td width="33%">
                                                                                    {hide == false &&
                                                                                        <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                            <span className="fa fa-cloud-download-alt"></span>
                                                                                        </button>
                                                                                    }
                                                                                    {hide == true &&
                                                                                        <button className="bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                            <span className="fa fa-ban"></span>
                                                                                        </button>
                                                                                    }
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    })}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.team.length != 0 &&
                                                        <div className="panel-body">

                                                            <h6>Team</h6>

                                                            <table class="table table-condensed">
                                                                {this.state.team.map((val, index) => {
                                                                    var hide = false;
                                                                    var arrayValue = [];
                                                                    for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                        if (this.state.Checkpriv[i].page_id == val.page_id && this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                            hide = true;
                                                                            arrayValue.push(this.state.Checkpriv[i])
                                                                        }
                                                                    }
                                                                    return <tbody>

                                                                        <tr key={index}>
                                                                            <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                                <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                            }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                            <td width="33%">{val.href}</td>
                                                                            <td width="33%">
                                                                                {hide == false &&
                                                                                    <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                        <span className="fa fa-cloud-download-alt"></span>
                                                                                    </button>

                                                                                }
                                                                                {hide == true &&
                                                                                    <button className="bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                        <span className="fa fa-ban"></span>
                                                                                    </button>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                })}
                                                            </table>
                                                        </div>
                                                    }

                                                    {this.state.humanresourcces.length != 0 &&
                                                        <div>
                                                            <hr />

                                                            <div className="panel-body">

                                                                <div>
                                                                    <h4>Administrative</h4>
                                                                    <hr style={{ height: "2px", border: 10 }} />

                                                                    <h6>Human Resource</h6>
                                                                </div>



                                                                <table class="table table-condensed">
                                                                    {this.state.humanresourcces.map((val, index) => {
                                                                        var hide = false;
                                                                        var arrayValue = [];
                                                                        for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                            if (this.state.Checkpriv[i].page_id == val.page_id && this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                                hide = true;
                                                                                arrayValue.push(this.state.Checkpriv[i])
                                                                            }
                                                                        }
                                                                        return <tbody>

                                                                            <tr key={index}>
                                                                                <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                                    <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                                }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                                <td width="33%">{val.href}</td>
                                                                                <td width="33%">
                                                                                    {hide == false &&
                                                                                        <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                            <span className="fa fa-cloud-download-alt"></span>
                                                                                        </button>
                                                                                    }
                                                                                    {hide == true &&
                                                                                        <button className=" bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                            <span className="fa fa-ban"></span>
                                                                                        </button>
                                                                                    }
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    })}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.usersSeting.length != 0 &&
                                                        <div className="panel-body">

                                                            <h6>Users</h6>

                                                            <table class="table table-condensed">
                                                                {this.state.usersSeting.map((val, index) => {
                                                                    var hide = false;
                                                                    var arrayValue = [];
                                                                    for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                        if (this.state.Checkpriv[i].page_id == val.page_id && this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                            hide = true;
                                                                            arrayValue.push(this.state.Checkpriv[i])
                                                                        }
                                                                    }
                                                                    return <tbody>

                                                                        <tr key={index}>
                                                                            <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                                <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                            }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                            <td width="33%">{val.href}</td>
                                                                            <td width="33%">
                                                                                {hide == false &&
                                                                                    <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                        <span className="fa fa-cloud-download-alt"></span>
                                                                                    </button>

                                                                                }
                                                                                {hide == true &&
                                                                                    <button className="bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                        <span className="fa fa-ban"></span>
                                                                                    </button>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                })}
                                                            </table>
                                                        </div>
                                                    }
                                                    {this.state.reports.length != 0 &&
                                                        <div>


                                                            <hr />
                                                            <div className="panel-body">

                                                                <h6>Reports</h6>


                                                                <table class="table table-condensed">
                                                                    {this.state.reports.map((val, index) => {
                                                                        var hide = false;
                                                                        var arrayValue = [];
                                                                        for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                            if (this.state.Checkpriv[i].page_id == val.page_id && this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                                hide = true;
                                                                                arrayValue.push(this.state.Checkpriv[i])
                                                                            }
                                                                        }
                                                                        return <tbody>

                                                                            <tr key={index}>
                                                                                <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                                    <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                                }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                                <td width="33%">{val.href}</td>
                                                                                <td width="33%">
                                                                                    {hide == false &&
                                                                                        <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                            <span className="fa fa-cloud-download-alt"></span>
                                                                                        </button>

                                                                                    }
                                                                                    {hide == true &&
                                                                                        <button className="bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                            <span className="fa fa-ban"></span>
                                                                                        </button>
                                                                                    }
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    })}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.setting.length != 0 &&
                                                        <div className="panel-body">

                                                            <h6>Settings</h6>


                                                            <table class="table table-condensed">
                                                                {this.state.setting.map((val, index) => {

                                                                    var hide = false;
                                                                    var arrayValue = [];
                                                                    for (let i = 0; i < this.state.Checkpriv.length; i++) {
                                                                        if (this.state.Checkpriv[i].page_id == val.page_id || this.state.Checkpriv[i].parent_id == val.parent_id) {
                                                                            hide = true;
                                                                            arrayValue.push(this.state.Checkpriv[i])
                                                                        }
                                                                    }
                                                                    return <tbody>

                                                                        <tr key={index}>
                                                                            <td width="33%">{hide == true && <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>}{hide == false &&
                                                                                <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                                            }<i className={val.icon}></i>&nbsp;&nbsp;{val.page_name}</td>
                                                                            <td width="33%">{val.href}</td>
                                                                            <td width="33%">
                                                                                {hide == false &&
                                                                                    <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.edit(val)}>
                                                                                        <span className="fa fa-cloud-download-alt"></span>
                                                                                    </button>

                                                                                }
                                                                                {hide == true &&
                                                                                    <button className="bttnundo bg-red-lighter pull-right" onClick={() => { this.Addpriveledge(arrayValue, "2"); }}>
                                                                                        <span className="fa fa-ban"></span>
                                                                                    </button>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                })}
                                                            </table>
                                                        </div>
                                                    }
                                                </Scrollbars>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.state.show_tab == false && this.state.showcontroller == false) &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-header">
                                    <div className="row justify-content-around">
                                        <div className="col-md-6  m-t-10">
                                            <h4 className="pull-left m-l-10 m-t-5">Name:&nbsp;{this.state.name}</h4>
                                        </div>
                                        <div className="col-md-6  m-t-10">
                                            <button class="bttnlnav bg-blue-lighter pull-right m-r-10 m-t-5" onClick={() => { this.onclick_tab(1); this.setState({ title2: "Navigation Privilege" }); this.setState({ title: "Navigation Privilege" }) }}>
                                                <span className="fa fa-drafting-compass"></span>
                                            </button>
                                            <button class="bttnlemp bg-green-lighter pull-right m-t-5" onClick={() => { this.onclick_tab(3); this.setState({ title: "List of Employees" }); this.setState({ title2: "" }) }}>
                                                <span className="fa fa-list-alt"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body bg-default">
                                    <hr style={{ height: "2px", border: 10 }} />
                                    <Scrollbars style={{ height: 300 }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row justify-content-start">
                                                    {this.state.allApps.map((val, index) => {


                                                        return <a className="col-md-2  category  m-l-5 m-r-5 m-t-20" key={index} data-target="#demo-lg-modal2" data-toggle="modal" onClick={() => this.app_id(val)}>
                                                            <div className="text-center shadow bg-white p-t-10 p-b-10">
                                                                <img src={"http://api.pacificweb.com.ph/assets/img/apps/" + val.category_attribute} style={{ width: 80, height: 80 }} /><br />
                                                                <span>{val.category_details}</span>
                                                            </div>
                                                        </a>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div id="demo-lg-modal2" className="modal w3-animate-zoom " tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Settings</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Scrollbars style={{ height: 400 }}>
                                    <div className="panel-body">
                                        <h6>Account Privilege</h6>
                                        <table class="table table-condensed">

                                            {this.state.app_nav.map((val) => {
                                                // console.log(val);

                                                var hide = false

                                                for (let i = 0; i < this.state.check_app_nav.length; i++) {
                                                    console.log(this.state.check_app_nav[i])

                                                    if (this.state.check_app_nav[i].parent_id == val.parent_id) {
                                                        hide = true;
                                                    }
                                                }

                                                return <tbody>
                                                    <tr>
                                                        <td width="33%">  {hide == true &&
                                                            <i className="fa fa-check" style={{ color: "green", marginRight: "5%" }}></i>
                                                        } {hide == false &&
                                                            <i className="fa fa-blank" style={{ color: "green", marginRight: "10%" }}></i>
                                                            } {val.delete_id == 1 &&
                                                                <i className="fa fa-times" style={{ color: "red", marginRight: "5%" }}></i>}<i className={val.icon}>&nbsp;&nbsp;</i><span >{val.parent_name}</span>
                                                        </td>
                                                        <td width="33%"><span >{val.href}</span></td>
                                                        {hide == true &&
                                                            <td width="33%">
                                                                <button className="bttnundo bg-red-lighter pull-right" onClick={() => this.removeAppNav(val)}>
                                                                    <span className="fa fa-ban"></span>
                                                                </button>
                                                            </td>
                                                        }
                                                        {hide == false &&
                                                            <td width="33%">
                                                                <button className="bttnimport bg-green-lighter pull-right" onClick={() => this.access_addNav(val)}>
                                                                    <span className="fa fa-cloud-download-alt"></span>
                                                                </button>
                                                            </td>
                                                        }
                                                    </tr>

                                                </tbody>
                                            })}
                                        </table>
                                    </div>
                                </Scrollbars>
                                <hr />



                            </div>
                        </div>
                    </div>
                </div>


                <div id="demo-lg-modal" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Add Form</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <label for="exampleZip" style={{ color: "#130f40" }}>Lastname*</label>
                                        <input onChange={this.textchange} type="text" name="lastname" id="exampleZip" placeholder="type your lastname" className="form-control" required />
                                        <label for="exampleCity" style={{ color: "#130f40" }}>Firstname*</label>
                                        <input onChange={this.textchange} type="text" name="firstname" id="exampleCity" placeholder="type your firstname" className="form-control" required />
                                        <label for="exampleState" style={{ color: "#130f40" }}>Middlename*</label>
                                        <input onChange={this.textchange} type="text" name="middlename" id="exampleState" placeholder="type your middlename" className="form-control" required />
                                        <label for="exampleZip" style={{ color: "#130f40" }}>Contact*</label>
                                        <input onChange={this.textchange} type="text" name="contact" id="exampleZip" placeholder="type your Contact" className="form-control" required />
                                        <label for="exampleState" style={{ color: "#130f40" }}>Username*</label>
                                        <input onChange={this.textchange} type="text" name="username" id="exampleState" placeholder="type your Username" className="form-control" required /> */}
                                        <form>
                                            <div class="form-group row m-b-15">
                                                <label class="col-form-label col-md-3" for="exampleZip">Lastname*</label>
                                                <div class="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="lastname" id="exampleZip" placeholder="type your lastname" className="form-control" required />
                                                </div>
                                            </div>
                                            <div class="form-group row m-b-15">
                                                <label class="col-form-label col-md-3" for="exampleZip">Firstname*</label>
                                                <div class="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="firstname" id="exampleCity" placeholder="type your firstname" className="form-control" required />
                                                </div>
                                            </div>
                                            <div class="form-group row m-b-15">
                                                <label class="col-form-label col-md-3" for="exampleZip">Middlename*</label>
                                                <div class="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="middlename" id="exampleState" placeholder="type your middlename" className="form-control" required />
                                                </div>
                                            </div>
                                            <div class="form-group row m-b-15">
                                                <label class="col-form-label col-md-3" for="exampleZip">Contact*</label>
                                                <div class="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="contact" id="exampleZip" placeholder="type your Contact" className="form-control" required />
                                                </div>
                                            </div>
                                            <div class="form-group row m-b-15">
                                                <label class="col-form-label col-md-3" for="exampleZip">Username*</label>
                                                <div class="col-md-9">
                                                    <input onChange={this.textchange} type="text" name="username" id="exampleState" placeholder="type your Username" className="form-control" required />
                                                </div>
                                            </div>
                                            <button className="bttnsub bg-green-lighter pull-right" onClick={this.AddFieldman}>
                                                <span className="fa fa-paper-plane"></span>
                                            </button>
                                        </form>
                                    </div>

                                </div><br />
                                {/* <hr />
                                <button className="bttnsub pull-right" onClick={this.AddFieldman}>
                                    <span className="fa fa-paper-plane"></span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modal5" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Configuration</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times"></span>
                                </button>
                                {/* <button type="button" className="close pull-right" data-dismiss="modal"><i class="fas fa-lg fa-window-close text-success"></i></button> */}
                            </div>
                            <div className="modal-body" style={{ fontSize: 12 }}>

                                {this.state.employeDetails.map((val, index) =>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="panel">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <img src="../assets/img/user/user.jpg" alt="Avatar" style={{ borderRadius: "50%", width: "80px" }} />
                                                            <h4 style={{ lineHeight: 4 }}><b>{val.user_lname}, {val.user_fname} {val.user_mname} {val.user_sname}</b></h4>

                                                        </div>

                                                    </div>
                                                    <hr style={{ height: "2px", border: 10 }} />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p style={{ fontSize: "13px" }}><b>Company</b><br /><span>{val.company_name}</span></p>
                                                            <br />
                                                            <hr style={{ height: "2px", border: 10 }} />

                                                            <p style={{ fontSize: "13px" }}><b>Handle Branch</b>
                                                                <Scrollbars style={{ height: 100 }}>


                                                                    {this.state.handleBranches.map((val2, index) =>
                                                                        <div>

                                                                            <span key={index}>{val2.branch_name}</span>
                                                                        </div>

                                                                    )}
                                                                </Scrollbars>
                                                            </p>
                                                        </div>
                                                        <hr style={{ height: "2px", border: 10 }} />
                                                        <div className="col-md-6">
                                                            <p style={{ fontSize: "12px", color: "#353b48" }}>Add Company*:</p>
                                                            <select value={this.state.select} className="form-control" placeholder="Select Company" onChange={this.onChangeSelectCompany} required>
                                                                <option>--SELECT COMPANY--</option>

                                                                <option ></option>

                                                                {this.state.companies.map((val3, index) =>
                                                                    <option value={val3.company_id}>{val3.company_name}</option>
                                                                )}
                                                            </select><br />
                                                            <p style={{ fontSize: "12px", color: "#353b48", marginTop: 10 }}>Add Branch*:</p>
                                                            <select value={this.state.selectbranch} className="form-control" placeholder="Select Branch" onChange={this.onChangeSelectBranch} multiple required>
                                                                <option>--SELECT BRANCH--</option>
                                                                <option ></option>

                                                                {this.state.branchesData.map((val4, index) =>
                                                                    <option value={val4.bid}>{val4.bname}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <hr style={{ height: "2px", border: 10 }} />
                                                    <button className="btttn bg-blue-transparent-9 fas fa-key" onClick={()=> {this.setState({resetUser:val.user_id})}} data-target="#demo-lg-modal-RESET" data-toggle="modal" data-dismiss="modal">&nbsp;Reset&nbsp;Password</button>
                                                    <button className="btttn bg-green-transparent-9 fas fa-plus-circle" onClick={() => this.HandleBranch(val) }>&nbsp;Add&nbsp;Branch</button>                  

                                                </div>

                                            </div>

                                        </div>


                                      
                                       
                                    </div>
                                 )}

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-RESET" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Reset Password</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle"/>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to reset password?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5"  onClick={() => { this._resetPassword(this.state.resetUser) }} data-dismiss="modal" aria-label="Close" >
                                            &nbsp;Reset
                                </button>
                                        <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                            &nbsp;Cancel
                                </button>

                                    </div>
                                </div>
                            </div>
                        </div>



            </div>

        );
    }
}

export default admin_AddPriv;