import React, { Component } from 'react';
import { getData } from '../../../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import $ from "jquery";
class SaleSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Branches: [],
            DATA: [],
            company: "",
            branches: [],
            companies: [],
            location: "",
            categories:[]
        };
        this.onChangeSelectCompany = this.onChangeSelectCompany.bind(this);
        this.textchange = this.textchange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this)

    }

    componentDidMount() {
        this.getCompanies();
        this.getSalesBusinessArea();
    }

    onFormSubmit(e) {
        e.preventDefault();
        var data = { app_id: 7, location: this.state.location, company_id: this.state.company }
        console.log(data)

        getData("users/insertSalesBusinessArea", data)
            .then(response => {
                console.log(response)
                if (response.response == true){
                    alert("Successful Recorded!");

                    $("#demo-modal-modal").hide();
                    this.getSalesBusinessArea();

                }
                else{
                    alert("invalid input!")
                }
            })
    }
    onChangeSelectCompany(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                this.setState({ company: options[i].value })
            }
        }
    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                console.log(response.data);
                this.setState({ companies: response.data })
            })
    }
    textchange = e => {
        this.setState({ location: e.target.value });
    }
    getSalesBusinessArea() {
        getData("users/fetchSalesBusinessArea", { app_id: 7, user_id: localStorage.getItem("u") })
            .then(response => {
                console.log(response)
                this.setState({categories: response})
            })

    }
    render() {
        // console.log(this.state.company)
        // console.log(this.state.location)

        return (
            <div>
                <div className="panel">
                    <div className="panel-heading">
                        <h4 className="panel-title">Employees</h4>
                        <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
                            <div className="input-group pull-right">
                                <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" />
                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <button className="btttn bg-green-transparent-9 fas fa-plus-circle text-white" data-target="#demo-modal-modal" data-toggle="modal">&nbsp;Create</button>
                    <div className="panel-body">
                        <Scrollbars style={{ height: 380, width: "100%" }}>
                            <table>
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Location Name</th>
                                        <th>Company Name</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white table table-condense">
                                    {this.state.categories.map((val, index) =>
                                        <tr>
                                            <td>{val.category_id}</td>
                                            <td>{val.category_details}</td>
                                            <td>{val.company_name}</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-modal-modal" refs={this.modal1} className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>title</h4>
                                <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onFormSubmit}>
                                    <div class="form-group">
                                        <label>Select Company</label>
                                        <select value={this.state.select} className="form-control" onChange={this.onChangeSelectCompany} required>
                                            <option ></option>
                                            {this.state.companies.map((val, index) =>
                                                <option value={val.company_id}>{val.company_name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Add Location</label>
                                        <input onChange={this.textchange} type="text" name="location" className="form-control" required />
                                    </div>

                                    <button className="btttn bg-green-transparent-9 text-white fas fa-plus-circle" type="submit">&nbsp;Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}
export default SaleSettings;