import React, { Component } from 'react';
import {getData211, getData} from "../../../api/api";
import swal from "sweetalert/dist/sweetalert.min.js";
class Users extends Component {
    constructor(props){
        super(props);
        this.state = {
            branch: [],
            firstname: '',
            middlename: '',
            lastname: '',
            phonenumber: '',
            selectedBranch: '',
        }
        this.textchange = this.textchange.bind(this);
    }
    _isMounted = true;

    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    submit(){
        let save = {
            'fname': this.state.firstname,
            'mname': this.state.middlename,
            'lname': this.state.lastname,
            'phonenumber': this.state.phonenumber,
            'branch': this.state.selectedBranch,
            'company': '6'
        }

        if(this.state.selectedBranch !== "" && this.state.firstname !== "" && this.state.middlename !== "" && this.state.lastname !== "" && this.state.phonenumber !== ""){
            swal("Successfully Added Fieldman!", "", "success");
            this.setState({
                firstname: '',
                middlename: '',
                lastname: '',
                phonenumber: '',
            })

            getData211("field_audit/insert211Fieldman", save).then(result =>{
                console.log(result)
            })

        }else{
            swal("Incomplete Details!", "", "error");
        }
    }

    
    branch() {
        getData211("field_audit/getMISbranch").then(result => {
            if (result.response === "404") {
                result = [];
                this.setState({ branch: result });
            } else {
                this.setState({ branch: result.elf })
                this.setState({ loading: "true" });
            }
        })
    }

    componentDidMount() {

        this.branch();

        this.update();
    }

    componentWillUnmount() {
        if (localStorage.getItem("pass") !== "dashboard") {
            this._isMounted = false
        }
    }

    update() {
        if (localStorage.getItem("pass") === "dashboard") {
            setTimeout(() => {
                this.branch();

                this.update();
            }, 500000);
        } else {
            clearTimeout(this.update);
        }
    }



    render() { 
        return ( 
            <div id="content" className="content">
              <div className="row text-center" style={{ marginTop: "-20px" }}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="#/payroll"><i className="fa fa-hand-holding-usd p-r-5 fa-lg text-success"></i><label>Navbar 1</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="#/holidays"><i className="fa fa-gift text-success p-r-5 fa-lg"></i><label>Navbar 2</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-briefcase fa-lg text-success p-r-5"></i><label>Navbar 3</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-user-slash fa-lg text-success p-r-5"></i><label>Navbar 4</label></a>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">Users</h1>
                    </div>
                    <div className="col-sm-6 ">
                       
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-blocked bg-green-lighter text-white" data-target="#demo-users" data-toggle="modal" >Add Fieldman</button>
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{ minHeight: 170, maxHeight: 550, overflow: 'auto' }}>
                                        <table className="table-hover">
                                            <thead className="tableFixHead table table-condensed text-center">
                                                <tr>
                                                    <th className="bg-light" style={{ padding: 15 }}>JO#</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Type</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>SSN</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Customer Name</th>
                                                    <th className="bg-light" style={{ padding: 15 }}>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table table-bordered">
                            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="demo-users" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-mm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Fieldman</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body table-scroll2 scrollDesign">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">First name</label>
                                    <input type="text" class="form-control" name="firstname" placeholder="Enter first name" value={this.state.firstname} onChange={this.textchange}/>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Middle name</label>
                                    <input type="text" class="form-control" name="middlename" placeholder="Enter middle name" value={this.state.middlename} onChange={this.textchange}/>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Last name</label>
                                    <input type="text" class="form-control" name="lastname" placeholder="Enter last name" value={this.state.lastname} onChange={this.textchange}/>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Phone number</label>
                                    <input type="number" class="form-control" name="phonenumber" placeholder="Enter phone number" value={this.state.phonenumber} onChange={this.textchange}/>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Branch</label>
                                    <select className="form-control" name="selectedBranch" onChange={this.textchange}>
                                    <option value="">Select Branch</option>
                                    {this.state.branch.map(branch =>
                                        <option value={branch.id}>{branch.name}</option>
                                    )}
                                </select>
                                </div>
                                <div class="form-group">
                                    <center><button className="btn btn-blocked bg-green-lighter text-white" onClick={this.submit.bind(this)}>Submit</button></center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Users;