import React, { Component } from "react";


// import PropTypes from 'prop-types';
// const propTypes = {
// 	data: PropTypes.array
// }

class TotalBooks extends Component {
    state = {  }
    
    render() { 
        // console.log(this.props.data);
        return ( 
            <div>
                            
            </div>
         );

    }
}
// TotalBooks.propTypes = propTypes;
export default TotalBooks;