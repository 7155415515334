import React, { Component } from 'react';

import { getData } from '../api/api';
import moment from 'moment';
import { Redirect } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import Header from './Header/index';
import $ from "jquery";



class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid: localStorage.getItem('u'),
            userData: [],
            nav_page_id: [],
            nav_group_id: [],
            nav_parent_id: [],
            href: "javascript:;",
            nav: [],
            apps: [],
            projects: [],
            team: [],
            humanresource: [],
            users: [],
            reports: [],
            privilege: [],
            app_nav: [],
            app_Home_Nav: [],
            apps2: [],
            app_name: "",
            hide: false,
            refresh: false,
            count: 0,
            FullName: "",
            Email: "",
            notification: [],
            redirect: false,
            count_noti: [],
            new_password: '',
            showPass: false,
            showPassconfirm: false,
            old_password: '',
            new_username: '',
            confirm_password: '',
            unmatch: '',
            wrong_pass: '',
            opennav: false,
            toggle: []
        }
        this.textchange = this.textchange.bind(this)
        this.ChangePassword = this.ChangePassword.bind(this)
        this.showSideNav = this.showSideNav.bind(this)
    }

    UserData = () => {
        getData("users/ActiveUser", localStorage.getItem('u')).then(result => {
            // console.log( localStorage.getItem('u'));
            // console.log(result);
            var c_name = ""
            var email = ""
            for (let index = 0; index < result.elf.length; index++) {
                var f_name = result.elf[index].user_details.fname;
                var l_name = result.elf[index].user_details.lname;
                var em = result.elf[index].contact.email;
                c_name = f_name + ' ' + l_name;
                email = em;
            }
            // console.log(email)
            this.setState({ userData: result.elf, FullName: c_name, Email: email })
            // localStorage.setItem('login_state',false)
            localStorage.setItem('imagePic', result.elf[0].user_details.image)

            localStorage.setItem("fullName", result.elf[0].user_details.fname + " " + result.elf[0].user_details.lname)
        })
    }
    logout = () => {
        localStorage.clear();
        window.location.replace("http://console.pacificweb.com.ph/#/")
        // window.location.replace("http://localhost:3000/#/")
        window.location.reload();
    }
    componentDidMount() {
        var details = []
        localStorage.setItem('totalNotiDetails', JSON.stringify(details))
        setTimeout(() => {
            var data = []
            var v_details_data = []
            localStorage.setItem('request_detail', JSON.stringify(data))
            getData("users/getPrivilege2", localStorage.getItem('u')).then(result => {

                this.setState({ app_nav: result.app_nav, nav: result.nav, apps: result.apps_nav, projects: result.projects_nav, team: result.team_nav, humanresource: result.humanresource_nav, users: result.users_nav, reports: result.reports_nav, privilege: result.privilege });
                localStorage.setItem('app_id_priv', JSON.stringify(result.app_id))
                v_details_data = result.app_id;
                var noti_data = []
                var count_noti = []
                for (let index = 0; index < result.noti.length; index++) {
                    var details = JSON.parse(result.noti[index].req_hierarchy_structure)
                    // console.log(details);
                    for (let i = 0; i < details.length; i++) {
                        for (let k = 0; k < details[i].approver.length; k++) {
                            if (details[i].approver[k].id == result.id) {
                                if (parseInt(i) <= parseInt(result.noti[index].req_hierarchy_level)) {
                                    // if(result.noti[index].req_hierarchy_level == null || result.noti[index].req_hierarchy_level = i ){
                                    // var data_details2 = { my_view_level:i,date_added: moment(result.noti[index].req_date_added).startOf('day').fromNow(), name: result.noti[index].user_lname + ' ' + result.noti[index].user_fname, level2: i, req_id: result.noti[index].req_id, status: result.noti[index].req_approval_status, level: result.noti[index].req_hierarchy_level, req_type: result.noti[index].req_type }
                                    // count_noti.push(data_details2)
                                    // }
                                    var data_details1 = { my_view_level: i, date_added: moment(result.noti[index].req_date_added).startOf('day').fromNow(), name: result.noti[index].user_lname + ' ' + result.noti[index].user_fname, level2: i, req_id: result.noti[index].req_id, status: result.noti[index].req_approval_status, level: result.noti[index].req_hierarchy_level, req_type: result.noti[index].req_type }
                                    noti_data.push(data_details1);
                                }
                            }
                        }
                    }
                }
                localStorage.setItem('totalNoti', noti_data.length)
                localStorage.setItem('totalNotiDetails', JSON.stringify(noti_data))
                this.setState({ notification: noti_data })
            })
            // this.noti();
            setTimeout(() => {
                this.UserData();
                this.GetApps(v_details_data);
                this.setState({ refresh: true })
                // if(this.state.app_nav.length == 0 && this.state.count < 3){
                //     window.location.reload();
                //     this.state.count++
                setInterval(() => {
                    this.setState({ refresh: true })
                }, 100);
                // }
            }, 200)
        }, 300);
    }
    noti() {
        getData("users/fetchnotification", { u_id: localStorage.getItem("u") }).then(result => {
            // console.log(result);
        })
    }
    GetApps(data) {
        var app_id = JSON.parse(localStorage.getItem('app_id_priv'))
        // console.log(app_id);
        getData("users/getApps", data).then(response => {
            var details = []
            var app_details = [];
            if (response.length != 0) {
                for (let i = 0; i < response.length; i++) {
                    var data = [];
                    data = { app_id: response[i][0].app_id, category_attribute: response[i][0].category_attribute, category_details: response[i][0].category_details, category_field: response[i][0].category_field, category_id: response[i][0].category_id, category_type: response[i][0].category_type, company_id: response[i][0].company_id, href: response[i][0].c_href }
                    app_details.push(data);
                }
                this.setState({ apps2: app_details })
            }
        })
    }
    data_nav() {
        // console.log( this.state.app_Home_Nav.length);
        if (localStorage.getItem('AppD') == "true") {
            var app_id_home = localStorage.getItem('selectApp_id');

            var u_id = localStorage.getItem('u');
            getData("users/getappHomeNav", { app_id: app_id_home, user_id: u_id }).then(result => {
                // console.log(result);
                localStorage.setItem('app_idDta', JSON.stringify(result.appHomeNav));
                localStorage.setItem('app_nameDta', result.app_name);

                this.setState({ app_Home_Nav: result.appHomeNav, app_name: result.app_name })
                localStorage.setItem('AppD', false)
            })
        }

    }
    textchange = e => {

        this.setState({ [e.target.name]: e.target.value, unmatch: '', wrong_pass: '' });


    }
    onclick(data) {
        var array = []
        localStorage.setItem('app_idDta', JSON.stringify(array));
        localStorage.setItem('AppD', true)
        localStorage.setItem('selectApp', true)
        localStorage.setItem('selectApp_id', data.app_id)
        this.setState({ refresh: true })
    }
    // ViewNoti(){

    //     getData('humanResource/view_Noti',this.state.count_noti).then((response)=>{
    //        if(response.success == "Success"){
    //         this.setState({count_noti:0})
    //        }

    //     })
    // }
    ChangePassword(e) {
        e.preventDefault();
        var details = { user_id: localStorage.getItem('u'), old_pass: this.state.old_password, new_password: this.state.new_password, new_username: this.state.new_username, confirm_password: this.state.confirm_password }
        if (this.state.new_password != this.state.confirm_password) {
            alert('Password unmatch!')
            this.setState({ confirm_password: '', unmatch: '#e55039' })
        } else {
            getData('users/ChangePass', details)
                .then((response) => {
                    // console.log(response);
                    if (response == true) {
                        document.getElementById('demo-xl-setting');
                        alert("Password changed successfully")
                        $('#closeModal').click();

                        // console.log(document.getElementById('demo-xl-setting'))
                    } else {
                        alert('Wrong Password')
                        this.setState({ old_password: '', wrong_pass: '#e55039' })

                    }
                })
        }

    }

    showSideNav = () => {
        this.setState({ opennav: !this.state.opennav });
    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/pocketHR/approval' />
        }

        // console.log(this.state.opennav)

        return (

            <div id="page-container" className={this.state.opennav ? 'fade page-sidebar-fixed page-header-fixed show has-scroll page-sidebar-toggled' : 'fade page-sidebar-fixed page-header-fixed show has-scroll'}>
                <Header shownav={() => this.showSideNav()} />
                <div id="sidebar" className="sidebar border-end" style={{ backgroundColor: '#ecf0f1' }}>
                    <div className="header">
                        <div className="navbar-header ">
                            <a href="/" className="navbar-brand text-center" style={{ color: 'rgb(0, 145, 151)' }}> <b>Workflow</b><small>++</small></a>
                        </div>
                    </div>
                    <div data-scrollbar="true" data-height="100%">
                        <ul className="nav ">
                            {/* <li className="nav-header text-default ">Navigation</li> */}
                            {this.state.nav.map((val, index) =>
                                <li key={index} className="has-sub">
                                    <a href={val.href}>
                                        <i style={{ color: 'rgb(0, 145, 151)' }} className={val.icon}></i>
                                        <span style={{ fontSize: 12, color: 'black' }}>{val.navigation}</span>
                                    </a>
                                </li>
                            )}
                            {/* {(this.state.apps.length != 0 &&
                                <li>
                                    <span style={{ fontSize: 9.2 }}>Applications</span>
                                </li>
                            )} */}
                            {this.state.apps.map((val) =>
                                <li className="has-sub">
                                    <a href={val.href}>
                                        <i className={val.icon}></i>
                                        <span style={{ fontSize: 12, color: 'black' }}>{val.page_name}</span>
                                    </a>
                                </li>
                            )}
                            {/* <hr className="divider" /> */}

                        </ul>
                        {/* ------------------------------------------------------------------D-E-P-A-R-T-M-E-N-T---------------------------------------------------------------- */}
                        {/* <view>
                            {(this.state.projects.length != 0 || this.state.team.length != 0) &&
<hr className="divider" />
}
                            <ul className="nav">
                                {(this.state.projects.length != 0 || this.state.team.length != 0) &&
                                    <li className="nav-header">Department</li>
                                }
                                {(this.state.projects.length != 0 &&
                                    <li>
                                        <span style={{ fontSize: 9.2 }}>Projects</span>
                                    </li>
                                )}
                                {this.state.projects.map((val) =>
                                    <li className="has-sub">
                                        <a href={val.href}>
                                            <i className={val.icon}></i>
                                            <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                        </a>
                                    </li>
                                )}
                                {(this.state.team.length != 0 &&
                                    <li>
                                        <span style={{ fontSize: 9.2 }}>Team</span>
                                    </li>
                                )}
                                {this.state.team.map((val) =>
                                    <li className="has-sub">
                                        <a href={val.href}>
                                            <i className={val.icon}></i>
                                            <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </view>
 */}


                        {/* ------------------------------------------------------------------A-D-M-I-N-I-S-T-R-A-T-I-V-E------------------------------------------------------------ */}


                        {/* <view>
                            {(this.state.humanresource.length != 0 || this.state.users.length != 0 || this.state.reports.length != 0) &&
<hr className="divider" />
}
                            <ul className="nav">
                                {(this.state.humanresource.length != 0 || this.state.users.length != 0 || this.state.reports.length != 0) &&
                                    <li className="nav-header">Administrative</li>
                                }
                                {(this.state.humanresource != 0 &&
                                    <li>
                                        <span style={{ fontSize: 9.2 }}>Human Resource</span>
                                    </li>
                                )}
                                {this.state.humanresource.map((val) =>
                                    <li className="has-sub">
                                        <a href={val.href}>
                                            <i className={val.icon}></i>
                                            <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                        </a>
                                    </li>
                                )}
                                {(this.state.users.length != 0 &&
                                    <li>
                                        <span style={{ fontSize: 9.2 }}>Users</span>
                                    </li>
                                )}
                                {this.state.users.map((val) =>
                                    <li className="has-sub">
                                        <a href={val.href}>
                                            <i className={val.icon}></i>
                                            <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                        </a>
                                    </li>
                                )}

                                {(this.state.reports.length != 0 &&
                                    <li>
                                        <span style={{ fontSize: 9.2 }}>Reports</span>
                                    </li>
                                )}
                                {this.state.reports.map((val) =>
                                    <li className="has-sub">
                                        <a href={val.href}>
                                            <i className={val.icon}></i>
                                            <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                        </a>
                                    </li>
                                )}

                            </ul>
                        </view> */}

                        {/* ------------------------------------------------------------------S-E-T-T-I-N-G-S------------------------------------------------------------- */}

                        <view>
                            {/* {(this.state.privilege.length != 0) &&
<hr className="divider" />
} */}
                            <ul className="nav">
                                {/* {(this.state.privilege.length != 0) &&
                                    <li className="nav-header">Settings</li>
                                } */}
                                {this.state.privilege.map((val) =>
                                    <li className="has-sub">
                                        <a href={val.href}>
                                            <i style={{ color: 'rgb(0, 145, 151)' }} className={val.icon}></i>
                                            <span style={{ fontSize: 12, color: 'black' }}>{val.page_name}</span>
                                        </a>
                                    </li>
                                )}
                                {/* <hr className="divider" /> */}

                            </ul>
                        </view>
                        {/* ------------------------------------------------------------------A-P-P--N-A-v--------------------------------------------------------------- */}

                        {/* <view>
                            {(this.state.app_nav.length != 0) &&
                            <hr className="divider" />
                            }

                            <ul className="nav">
                            {(this.state.app_nav.length != 0) &&
                            <li className="nav-header">Navigation for Apps</li>
                            }
                            {this.state.app_nav.map((val) =>
                            <li className="has-sub">
                            <a href={val.href}>
                            <i className={val.icon}></i>
                            <span style ={{fontSize:12}}>{val.page_name}</span>
                            </a>
                            </li>
                            )}


                            </ul>
                            </view>
                            */}

                        {/* ------------------------------------------------------------------A-P-P--P-A-G-E--N-A-V----------------------------------------------------------- */}
                        {/* {localStorage.getItem('selectApp') == "true" &&

                            <view>
                                {this.data_nav()}
                                {(JSON.parse(localStorage.getItem('app_idDta')).length != 0) &&
                                    <hr className="divider" />
                                }
                                <ul className="nav">
                                    {(JSON.parse(localStorage.getItem('app_idDta')).length != 0) &&
                                        <li className="nav-header"><span>{localStorage.getItem('app_nameDta')}</span></li>
                                    }
                                    {JSON.parse(localStorage.getItem('app_idDta')).map((val, index) =>
                                        <li className="has-sub" key={index}>
                                            <a href={val.href} onClick={() => {if(val.page_name == 'Sales' ){setTimeout(() => {
                                                    window.location.reload()
                                                }, 100)}
                                               
                                            }}>
                                                <i className={val.icon}></i>
                                                <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                            </a>
                                        </li>
                                    )}


                                </ul>
                            </view>
                        } */}
                        {localStorage.getItem('selectApp') == "true" &&

                            <div class="pos-f-t">
                                <nav class="navbar">
                                    <span className="text-white" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                        {(JSON.parse(localStorage.getItem('app_idDta')).length != 0) &&
                                            <p>{localStorage.getItem('app_nameDta')}&nbsp;<i class="fas fa-caret-right" /></p>
                                        }
                                        {/* <span class="navbar-toggler-icon"></span> */}
                                    </span>
                                </nav>
                                <div class="collapse show" id="navbarToggleExternalContent">
                                    <div>
                                        {this.data_nav()}
                                        <ul className="nav">
                                            {/* {(JSON.parse(localStorage.getItem('app_idDta')).length != 0) &&
                                        <li className="nav-header"><span>{localStorage.getItem('app_nameDta')}</span></li>
                                    } */}
                                            {JSON.parse(localStorage.getItem('app_idDta')).map((val, index) =>
                                                <li className="has-sub" key={index}>
                                                    <a href={val.href} onClick={() => {
                                                        if (val.page_name == 'Sales') {
                                                            setTimeout(() => {
                                                                window.location.reload()
                                                            }, 50)
                                                        }
                                                    }}>
                                                        <i className={val.icon}></i>
                                                        <span style={{ fontSize: 12 }}>{val.page_name}</span>
                                                    </a>
                                                </li>
                                            )}


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* ------------------------------------------------------------------A-P-P--N-A-v--------------------------------------------------------------- */}

                        {/* <view>
                            {(this.state.app_nav.length != 0) &&
                            <hr className="divider" />
                            }

                            <ul className="nav">
                            {(this.state.app_nav.length != 0) &&
                            <li className="nav-header">Navigation for Apps</li>
                            }
                            {this.state.app_nav.map((val) =>
                            <li className="has-sub">
                            <a href={val.href}>
                            <i className={val.icon}></i>
                            <span style ={{fontSize:12}}>{val.page_name}</span>
                            </a>
                            </li>
                            )}


                            </ul>
                            </view>
                            */}
                        {/* <li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i class="fa fa-angle-double-left"></i></a></li> */}
                    </div>

                </div>
                {/* } */}
                {/* } */}

                {/* <div className="" ></div> */}
                {this.state.hide == true &&
                    <div id="sidebar-right" className="sidebar sidebar-right w3-animate-top">
                        <div data-scrollbar="true" data-height="100%">
                            <div className="row">
                                <div className="card" style={{ height: '500px', width: 2000, backgroundColor: '#fff' }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            {this.state.apps2.map((val) =>
                                                <div className="col-md-3 w3-animate-top">
                                                    <div className="pad-all text-center">
                                                        <a href={val.href} onClick={() => this.onclick(val)} className="m-l-5 p-t-10" bg-info>
                                                            <img src={"http://api.pacificweb.com.ph/assets/img/apps/" + val.category_attribute} style={{ width: '6em', height: 60 }} className="m-l-5 p-t-10" />
                                                        </a>
                                                    </div>
                                                </div>

                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                <div id="demo-xl-setting" className="modal fade" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Settings</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9">
                                    <span className="fa fa-times-circle" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.ChangePassword}>
                                    <div className="form-group row m-b-15">
                                        <label className="col-form-label col-md-3">Old Password</label>
                                        <div className="col-md-9">
                                            <input name='old_password' value={this.state.old_password} onChange={this.textchange} style={{ backgroundColor: this.state.wrong_pass }} type="password" className="form-control m-b-5" required />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row m-b-15">
                                            <label className="col-form-label col-md-3">New Username</label>
                                            <div className="col-md-9">
                                                <input name='new_username' onChange={this.textchange} type="text" className="form-control m-b-5" required/>
                                            </div>
                                        </div> */}



                                    <div className="form-group row m-b-15">
                                        <label className="col-form-label col-md-3">New Password</label>
                                        <div className="col-md-9">
                                            {this.state.showPass == false &&
                                                <div style={{ flexDirection: 'row' }}>
                                                    <input value={this.state.new_password} name='new_password' onChange={this.textchange} type="password" className="form-control m-b-5" required />
                                                    {this.state.new_password != '' &&
                                                        <a onClick={() => { this.setState({ showPass: true }) }}>Show</a>
                                                    }

                                                </div>
                                            }
                                            {this.state.showPass == true &&
                                                <div>
                                                    <input value={this.state.new_password} name='new_password' onChange={this.textchange} type="text" className="form-control " required />
                                                    <a onClick={() => { this.setState({ showPass: false }) }}>Hide</a>
                                                </div>
                                            }

                                        </div>


                                        <label className="col-form-label col-md-3">Confirm Password</label>
                                        <div className="col-md-9">
                                            {this.state.showPassconfirm == false &&
                                                <div style={{ flexDirection: 'row' }}>
                                                    <input value={this.state.confirm_password} style={{ backgroundColor: this.state.unmatch }} name='confirm_password' onChange={this.textchange} type="password" className="form-control m-b-5" required />
                                                    {this.state.confirm_password != '' &&
                                                        <a onClick={() => { this.setState({ showPassconfirm: true }) }}>Show</a>
                                                    }

                                                </div>
                                            }
                                            {this.state.showPassconfirm == true &&
                                                <div>
                                                    <input value={this.state.confirm_password} style={{ backgroundColor: this.state.unmatch }} name='confirm_password' onChange={this.textchange} type="text" className="form-control " required />
                                                    <a onClick={() => { this.setState({ showPassconfirm: false }) }}>Hide</a>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* <div className="form-group row m-b-15">
                                        <label className="col-form-label col-md-3">New Contact No.</label>
                                        <div className="col-md-9">
                                            <input type="number" className="form-control m-b-5" />
                                        </div>
                                    </div> */}
                                    <div className="modal-footer">
                                        <button className="pull-right btttn bg-green-transparent-9 far fa-save ">
                                            &nbsp;Save Password
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navigation;