import React, { Component } from 'react';
import DatePicker from "react-datepicker";

// import DGauage1 from '../charts/d_gauge1';
// import DBar1 from "../charts/d_bar1";
import DPie1 from "../charts/d_pie1";
import { Loader } from "react-overlay-loader"
import { getMISbranch, MISDashboard, getMISuser, MISActiveFieldman, getAccomplishment } from "../../../api/api";
// import { first } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { array } from '@amcharts/amcharts4/core';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            branch: [],
            dashboard: [],
            selectedBranch: '',
            example: [],
            TotalScan: 0,
            TotalAssigned: 0,
            TotalNotAssigned: 0,
            TotalReturn: 0,
            TotalAccomplished: 0,
            TotalPending: 0,
            loading: "false",

            new_assign: [],
            new_execute: [],
            old_assign: [],
            old_execute: [],
            receive: [],
            returns: [],
            accomplished: [],
            pending: [],
            assigned: [],
            totalJo: 0,
            notAssigned: [],
            backlog: [],

            checkScan: false,
            checkAssigned: false,
            checkNotAssigned: false,
            checkAccomplished: false,
            checkBacklog: false,
            checkbox: ["checkScan", "checkAssigned", "checkNotAssigned", "checkAccomplished", "checkBacklog"],
            checkData: [],
            percent: 0,
            splitData: [],


            groupAccomplish: [],
            pieGraph: [],
            fieldmanAssigned: [],
            fieldmanCainta: 0,
            fieldmanMandaluyong: 0,
            fieldmanRosario: 0,
            fieldmanMain: [],
            fieldmanMainData: [],

            viewDetails: [],
            showFieldmanAssign: [],
            showFieldmanAssignTitle: [],
            showFieldmanAccomplished: [],
            showFieldmanAccomplishedTitle: [],


        }
        this.submit = this.submit.bind(this);
        this.textchange = this.textchange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.selectMain = this.selectMain.bind(this);
        this.viewDetails = this.viewDetails.bind(this);

    }
    _isMounted = true;

    textchange = e => {
        //  this.setState({
        //      checkbox: ["checkScan","checkAssigned","checkNotAssigned","checkAccomplished","checkBacklog"],
        //      checkData: [],
        //  })
       

        var dashboard = this.state.dashboard;
        var branch = this.state.selectedBranch;
        var mainData = [];
        var accomplished = [];
        var assigned = [];
        var new_assign = [];
        var new_execute = [];
        var old_assign = [];
        var old_execute = [];
        var pending = [];
        var received = [];
        var returns = [];

        var Backlog = [];

        var totalJO = [];

        var fieldman = [];
        var dataFieldman = [];



        if (dashboard !== "" || dashboard !== null) {
            for (let i = 0; i < dashboard.length; i++) {
                if (e.target.value === dashboard[i].branch_name) {
                    mainData = dashboard[i].data;
                } else if (e.target.value === "All" || e.target.value === "") {
                    mainData.push(dashboard[i].data);
                }
            }


            if (e.target.value === "All" || e.target.value === "") {
                var dataFinal = [];
                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].length; b++) {
                        dataFinal.push({
                            date: mainData[i][b].date, assigned: mainData[i][b].assigned, accomplished: mainData[i][b].accomplished, new_assign: mainData[i][b].new_assign,
                            new_execute: mainData[i][b].new_execute, old_assign: mainData[i][b].old_assign, old_execute: mainData[i][b].old_execute, pending: mainData[i][b].pending,
                            received: mainData[i][b].received, returns: mainData[i][b].return
                        });
                    }
                }

                // for(let i = 0; i<fieldman.length; i++){
                //     for(let b = 0; b<fieldman[i].length; b++){
                //         dataFieldman.push({user_id: fieldman[i][b].user_id, user_name: fieldman[i][b].user_name, branch: fieldman[i][b].branch});
                //     }
                //
                // this.setState({fieldmanMainData: dataFieldman});

                var allFieldman = [];
                var fieldmanAssigned = [];
                var fieldmanAccomplished = [];

                for (let i = 0; i < dashboard.length; i++) {
                    for (let b = 0; b < dashboard[i].fieldman.length; b++) {
                        allFieldman = allFieldman.concat(dashboard[i].fieldman[b]);
                    }
                }

                for (let i = 0; i < allFieldman.length; i++) {
                    fieldman.push({ 'fieldman': allFieldman[i], 'data': { 'assigned': [], 'accomplished': [] } });
                }

                for (let i = 0; i < dataFinal.length; i++) {
                    for (let b = 0; b < dataFinal[i].assigned.length; b++) {
                        fieldmanAssigned = fieldmanAssigned.concat(dataFinal[i].assigned[b]);
                    }
                }
                for (let i = 0; i < dataFinal.length; i++) {
                    for (let b = 0; b < dataFinal[i].accomplished.length; b++) {
                        fieldmanAccomplished = fieldmanAccomplished.concat(dataFinal[i].accomplished[b]);
                    }
                }

                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAssigned.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAssigned[b].user_id) {
                            fieldman[i].data.assigned.push(fieldmanAssigned[b]);
                        }
                    }
                }
                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAccomplished.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAccomplished[b].user_id) {
                            fieldman[i].data.accomplished.push(fieldmanAccomplished[b]);
                        }
                    }
                }


                this.setState({ fieldmanMainData: fieldman });




                for (let i = 0; i < dataFinal.length; i++) {
                    assigned = assigned.concat(dataFinal[i].assigned);
                    accomplished = accomplished.concat(dataFinal[i].accomplished);
                    new_assign = new_assign.concat(dataFinal[i].new_assign);
                    new_execute = new_execute.concat(dataFinal[i].new_execute);
                    old_assign = old_assign.concat(dataFinal[i].old_assign);
                    old_execute = old_execute.concat(dataFinal[i].old_execute);
                    pending = pending.concat(dataFinal[i].pending);
                    received = received.concat(dataFinal[i].received);
                    returns = returns.concat(dataFinal[i].returns);

                    totalJO = totalJO.concat(dataFinal[i].received);
                    totalJO = totalJO.concat(dataFinal[i].returns);

                }



                this.setState({ accomplished: accomplished });
                this.setState({ assigned: assigned });
                this.setState({ new_assign: new_assign });
                this.setState({ new_execute: new_execute });
                this.setState({ old_assign: old_assign });
                this.setState({ old_execute: old_execute });
                this.setState({ notAssigned: pending });
                this.setState({ received: received });

                for (let i = 0; i < totalJO.length; i++) {
                    if (totalJO[i].status !== "Accomplished") {
                        Backlog.push(totalJO[i]);
                    }
                }


                // this.setState({backlog: (returns.length + received.length) - (accomplished.length)});
                this.setState({ backlog: Backlog });

                this.setState({ TotalScan: returns.length + received.length });
                this.setState({ TotalAccomplished: accomplished.length });
                this.setState({ TotalAssigned: assigned.length });


                var total = returns.length + received.length;
                var accomplisheds = accomplished.length;
                var assigneds = assigned.length;

                var pieTotal = 0;
                var pieAssigned = 0;
                var pieAccomplished = 0;

                if (total === assigneds) {
                    pieTotal = 0;
                    pieAssigned = assigneds;
                } else {
                    var dif = total - assigneds;
                    pieTotal = dif;
                    pieAssigned = assigneds;
                }

                if (assigneds === accomplisheds) {
                    pieAssigned = 0;
                    pieAccomplished = accomplisheds;
                } else {
                    var dif = assigneds - accomplisheds;
                    pieAssigned = dif;
                    pieAccomplished = accomplisheds;
                }

                this.setState({ pieGraph: [{ title: "Total Accomplished", value: pieAccomplished }, { title: "Total Assigned", value: pieAssigned }, { title: "Total Not Assigned", value: pieTotal }] });


            } else {
                for (let i = 0; i < dashboard.length; i++) {
                    for (let b = 0; b < dashboard[i].fieldman.length; b++) {
                        if (dashboard[i].branch_name === e.target.value) {
                            fieldman.push({ 'fieldman': dashboard[i].fieldman[b], 'data': { 'assigned': [], 'accomplished': [] } });
                        }
                    }
                }

                var fieldmanAssigned = [];
                var fieldmanAccomplished = [];

                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].assigned.length; b++) {
                        fieldmanAssigned.push(mainData[i].assigned[b]);
                    }
                }
                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].accomplished.length; b++) {
                        fieldmanAccomplished.push(mainData[i].accomplished[b]);
                    }
                }


                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAssigned.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAssigned[b].user_id) {
                            fieldman[i].data.assigned.push(fieldmanAssigned[b]);
                        }
                    }
                }
                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAccomplished.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAccomplished[b].user_id) {
                            fieldman[i].data.accomplished.push(fieldmanAccomplished[b]);
                        }
                    }
                }


                this.setState({ fieldmanMainData: fieldman });

                mainData.forEach(element => {
                    accomplished = accomplished.concat(element['accomplished']);
                    assigned = assigned.concat(element['assigned']);
                    new_assign = new_assign.concat(element['new_assign']);
                    new_execute = new_execute.concat(element['new_execute']);
                    old_assign = old_assign.concat(element['old_assign']);
                    old_execute = old_execute.concat(element['old_execute']);
                    pending = pending.concat(element['pending']);
                    received = received.concat(element['received']);
                    returns = returns.concat(element['return']);

                    totalJO = totalJO.concat(element['received']);
                    totalJO = totalJO.concat(element['return']);

                });

                this.setState({ accomplished: accomplished });
                this.setState({ assigned: assigned });
                this.setState({ new_assign: new_assign });
                this.setState({ new_execute: new_execute });
                this.setState({ old_assign: old_assign });
                this.setState({ old_execute: old_execute });
                this.setState({ notAssigned: pending });
                this.setState({ received: received });
                // this.setState({backlog: (returns.length + received.length) - (new_execute.length + old_execute.length)});

                for (let i = 0; i < totalJO.length; i++) {
                    if (totalJO[i].status !== "Accomplished") {
                        Backlog.push(totalJO[i]);
                    }
                }

                this.setState({ backlog: Backlog });


                this.setState({ TotalScan: returns.length + received.length });
                this.setState({ TotalAccomplished: accomplished.length });
                this.setState({ TotalAssigned: assigned.length });


                var total = returns.length + received.length;
                var accomplisheds = accomplished.length;
                var assigneds = assigned.length;

                var pieTotal = 0;
                var pieAssigned = 0;
                var pieAccomplished = 0;

                if (total === assigneds) {
                    pieTotal = 0;
                    pieAssigned = assigneds;
                } else {
                    var dif = total - assigneds;
                    pieTotal = dif;
                    pieAssigned = assigneds;
                }

                if (assigneds === accomplisheds) {
                    pieAssigned = 0;
                    pieAccomplished = accomplisheds;
                } else {
                    var dif = assigneds - accomplisheds;
                    pieAssigned = dif;
                    pieAccomplished = accomplisheds;
                }

                this.setState({ pieGraph: [{ title: "Total Accomplished", value: pieAccomplished }, { title: "Total Assigned", value: pieAssigned }, { title: "Total Not Assigned", value: pieTotal }] });


            }


        }
    };

    date = e => {
        this.setState({
            date: e
        });
    }
    handleChangeStart = e => {
        this.setState({
            startDate: e
        });
    }

    handleChangeEnd = e => {
        this.setState({
            endDate: e
        });
    }


    showFieldmanAssign = e => {
        var fieldman = this.state.fieldmanMainData;
        var data = [];
        var data2 = [];
        for (let i = 0; i < fieldman.length; i++) {
            if (fieldman[i].fieldman.user_id === e.target.id) {
                data = fieldman[i].data.assigned;
            }
        }
        for (let i = 0; i < 1; i++) {
            data2.push(data[i]);
        }
        this.setState({ showFieldmanAssign: data });
        this.setState({ showFieldmanAssignTitle: data2 });

    }

    showFieldmanAccomplished = e => {
        var fieldman = this.state.fieldmanMainData;
        var data = [];
        var data2 = [];
        for (let i = 0; i < fieldman.length; i++) {
            if (fieldman[i].fieldman.user_id === e.target.id) {
                data = fieldman[i].data.accomplished;
            }
        }
        for (let i = 0; i < 1; i++) {
            if (data[i] === undefined) {
                data2.push({ user_fname: "No", user_lname: "Accomplishment", user_id: "x" });
            } else {
                data2.push(data[i]);
            }

        }

        this.setState({ showFieldmanAccomplished: data });
        this.setState({ showFieldmanAccomplishedTitle: data2 });

    }



    handleClick = e => {

        var data = this.state.checkbox;
        var name = e.target.name;
        // var value = e.target.name+"-"+e.target.value;
        var value = '';
        if (e.target.name === "checkBacklog" || e.target.name === "checkNotAssigned") {
            value = e.target.name + "-" + "neg" + "-" + e.target.value;
        } else {
            value = e.target.name + "-" + "pos" + "-" + e.target.value;
        }


        var finalData = this.state.checkData;

        if (e.target.checked) {
            data = data.filter(item => item !== name);
            if (e.target.name === "checkBacklog" || e.target.name === "checkNotAssigned") {
                finalData = finalData.concat(e.target.name + "-" + "neg" + "-" + e.target.value);
            } else {
                finalData = finalData.concat(e.target.name + "-" + "pos" + "-" + e.target.value);
            }
            this.setState({ checkData: finalData });
            this.setState({ checkbox: data });

        } else {
            data = data.concat(e.target.name);
            finalData = finalData.filter(item => item !== value);
            this.setState({ checkData: finalData });
            this.setState({ checkbox: data });
        }

        if (finalData.length === 2) {
            const number = [];

            const checkSplit1 = finalData[0].split("-");
            const checkSplit2 = finalData[1].split("-");


            var percentage = 0;

            if (checkSplit1[1] === checkSplit2[1]) {
                number.push(checkSplit1[2], checkSplit2[2]);
                number.sort((a, b) => b - a);
                var div = number[1] / number[0];
                var times = div * 100;

                percentage = times;

            } else {

                if (checkSplit1[1] === "pos") {
                    var first = parseInt(checkSplit1[2]);
                    var second = parseInt(checkSplit2[2]);
                    number.push(first, second);
                    var sum = number[0] + number[1];
                    var divs = number[0] / sum;
                    var timess = divs * 100;

                    percentage = timess;

                }
                else if (checkSplit2[1] === "pos") {
                    var first = parseInt(checkSplit2[2]);
                    var second = parseInt(checkSplit1[2]);

                    number.push(first, second);
                    var sum = number[0] + number[1];
                    var divs = number[0] / sum;
                    var timess = divs * 100;

                    percentage = timess;

                }
            }
            this.setState({ percent: Math.trunc(percentage) });
        } else if (finalData.length === 0) {
            this.setState({ percent: 0 });
        } else {
            this.setState({ percent: 100 });
        }


        if (data.length === 3) {
            for (let i = 0; i < data.length; i++) {
                this.setState({ [data[i]]: true });
            }
        } else {
            for (let i = 0; i < data.length; i++) {
                this.setState({ [data[i]]: false });
            }
        }

    }

    selectMain = e => {

        var totalJo = this.state.totalJo;
        var receive = this.state.receive;
        var returns = this.state.returns;
        var accomplished = this.state.accomplished;
        var pending = this.state.pending;
        var assigned = this.state.assigned;

        var new_assign = this.state.new_assign;
        var old_assign = this.state.old_assign;
        var new_execute = this.state.new_execute;
        var old_execute = this.state.old_execute;


        if (e.target.id === "checkTotal") {
            var receive = this.state.receive;
            var returns = this.state.returns;

            var new_assign = this.state.new_assign;
            var old_assign = this.state.old_assign;
            var new_execute = this.state.new_execute;
            var old_execute = this.state.old_execute;

            this.setState({ TotalScan: totalJo });
            this.setState({ TotalAssigned: assigned });
            this.setState({ TotalNotAssigned: pending });
            this.setState({ TotalReturn: returns });
            this.setState({ TotalAccomplished: accomplished });
            this.setState({ TotalPending: returns + receive - accomplished });


        } else if (e.target.id === "checkNew") {
            this.setState({ TotalScan: receive });
            this.setState({ TotalAssigned: new_assign });
            this.setState({ TotalNotAssigned: receive - new_assign });
            this.setState({ TotalAccomplished: new_execute });
            this.setState({ TotalPending: returns - new_execute });

        } else if (e.target.id === "checkOld") {

            this.setState({ TotalScan: returns });
            this.setState({ TotalAssigned: old_assign });
            this.setState({ TotalNotAssigned: returns - old_assign });
            this.setState({ TotalAccomplished: old_execute });
            this.setState({ TotalPending: returns - old_execute });
        }
    }


    viewDetails = e => {
        if (e.target.name === "new_assign") {
            var new_assign = this.state.new_assign;
            this.setState({ viewDetails: new_assign });
        } else if (e.target.name === "old_assign") {
            var old_assign = this.state.old_assign;
            this.setState({ viewDetails: old_assign });
        } else if (e.target.name === "new_execute") {
            var new_execute = this.state.new_execute;
            this.setState({ viewDetails: new_execute });
        } else if (e.target.name === "old_execute") {
            var old_execute = this.state.old_execute;
            this.setState({ viewDetails: old_execute });
        } else if (e.target.name === "pending") {
            var pending = this.state.notAssigned;
            this.setState({ viewDetails: pending });
        } else if (e.target.name === "backlog") {
            var backlog = this.state.backlog;
            this.setState({ viewDetails: backlog });
        }
    }

    branch() {
        getMISbranch("field_audit/getMISbranch").then(result => {
            if (result.response === "404") {
                result = [];
                this.setState({ branch: result });
            } else {
                this.setState({ branch: result.elf })
                this.setState({ loading: "true" });
            }
        })
    }

    audit() {
        MISDashboard("field_audit/MISDashboard").then(result => {
            // if(result.response === "404"){
            //     result = [];
            //     this.setState({dashboard : result});
            // }else{
            //     this.setState({dashboard: result.elf})
            // }
        })
    }

    submit = e => {
        e.preventDefault();
        this.setState({ loading: "false" });


        let s = this.state.startDate;
        let start = s.toISOString();
        var end = this.state.endDate;
        var endDate = new Date(end);

        // if(s.toString() !== endDate.toString()){
        //     endDate.setDate(endDate.getDate() + 1);
        // }


        var value = endDate - s;
        var day, hour, minute, seconds;
        seconds = Math.floor(value / 1000);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;

        const startdate = []


        for (let i = 0; i < day + 1; i++) {
            var starts = start;
            var today = new Date(starts);
            var todayy = new Date(today.getTime() + (86400000 * i));
            var splitDate = String(todayy).split(" ");
            var finalDate = splitDate[1] + " " + splitDate[2];

            let startyear = todayy.getFullYear();
            let startmonth = todayy.getMonth() + 1;
            let startdt = todayy.getDate();
            if (startdt < 10) {
                startdt = '0' + startdt;
            }
            if (startmonth < 10) {
                startmonth = '0' + startmonth;
            }
            startdate.push(startyear + '-' + startmonth + '-' + startdt);
        }

        let save = {
            date: startdate,
            id: "5",
            cid: "5",
        }

        getAccomplishment("field_audit/dashboardMIS", save).then(result =>{
            let data = [];
            data = result;
        })


        getAccomplishment("field_audit/getAccomplishment1", startdate).then(result => {
            this.setState({ dashboard: result });
            var branch = this.state.selectedBranch;
            var mainData = [];
            var accomplished = [];
            var assigned = [];
            var new_assign = [];
            var new_execute = [];
            var old_assign = [];
            var old_execute = [];
            var pending = [];
            var received = [];
            var returns = [];

            var Backlog = [];
            var totalJO = [];


            var fieldman = [];
            var dataFieldman = [];

            var totalDetails = [];

            for (let i = 0; i < result.length; i++) {
                if (branch === result[i].branch_name) {
                    mainData = result[i].data;
                } else if (branch === "All" || branch === "") {
                    mainData.push(result[i].data);
                }
            }



            if (branch === "All" || branch === "") {
                var dataFinal = [];
                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].length; b++) {
                        dataFinal.push({
                            date: mainData[i][b].date, assigned: mainData[i][b].assigned, accomplished: mainData[i][b].accomplished, new_assign: mainData[i][b].new_assign,
                            new_execute: mainData[i][b].new_execute, old_assign: mainData[i][b].old_assign, old_execute: mainData[i][b].old_execute, pending: mainData[i][b].pending,
                            received: mainData[i][b].received, returns: mainData[i][b].return
                        });
                    }
                }
                // for(let i = 0; i<fieldman.length; i++){
                //     for(let b = 0; b<fieldman[i].length; b++){
                //         dataFieldman.push({user_id: fieldman[i][b].user_id, user_name: fieldman[i][b].user_name, branch: fieldman[i][b].branch});
                //     }
                // }

                var allFieldman = [];
                var fieldmanAssigned = [];
                var fieldmanAccomplished = [];

                for (let i = 0; i < result.length; i++) {
                    for (let b = 0; b < result[i].fieldman.length; b++) {
                        allFieldman = allFieldman.concat(result[i].fieldman[b]);
                    }
                }

                for (let i = 0; i < allFieldman.length; i++) {
                    fieldman.push({ 'fieldman': allFieldman[i], 'data': { 'assigned': [], 'accomplished': [] } });
                }


                for (let i = 0; i < dataFinal.length; i++) {
                    for (let b = 0; b < dataFinal[i].assigned.length; b++) {
                        fieldmanAssigned = fieldmanAssigned.concat(dataFinal[i].assigned[b]);
                    }
                }
                for (let i = 0; i < dataFinal.length; i++) {
                    for (let b = 0; b < dataFinal[i].accomplished.length; b++) {
                        fieldmanAccomplished = fieldmanAccomplished.concat(dataFinal[i].accomplished[b]);
                    }
                }

                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAssigned.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAssigned[b].user_id) {
                            fieldman[i].data.assigned.push(fieldmanAssigned[b]);
                        }
                    }
                }
                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAccomplished.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAccomplished[b].user_id) {
                            fieldman[i].data.accomplished.push(fieldmanAccomplished[b]);
                        }
                    }
                }

       
       
                this.setState({fieldmanMainData: fieldman});



                for (let i = 0; i < dataFinal.length; i++) {
                    assigned = assigned.concat(dataFinal[i].assigned);
                    accomplished = accomplished.concat(dataFinal[i].accomplished);
                    new_assign = new_assign.concat(dataFinal[i].new_assign);
                    new_execute = new_execute.concat(dataFinal[i].new_execute);
                    old_assign = old_assign.concat(dataFinal[i].old_assign);
                    old_execute = old_execute.concat(dataFinal[i].old_execute);
                    pending = pending.concat(dataFinal[i].pending);
                    received = received.concat(dataFinal[i].received);
                    returns = returns.concat(dataFinal[i].returns);

                    totalJO = totalJO.concat(dataFinal[i].received);
                    totalJO = totalJO.concat(dataFinal[i].returns);

                }

                this.setState({ accomplished: accomplished });
                this.setState({ assigned: assigned });
                this.setState({ new_assign: new_assign });
                this.setState({ new_execute: new_execute });
                this.setState({ old_assign: old_assign });
                this.setState({ old_execute: old_execute });
                this.setState({ notAssigned: pending });
                this.setState({ received: received });
                // this.setState({backlog: (returns.length + received.length) - (new_execute.length + old_execute.length)});

                for (let i = 0; i < totalJO.length; i++) {
                    if (totalJO[i].status !== "Accomplished") {
                        Backlog.push(totalJO[i]);
                    }
                }
                this.setState({ backlog: Backlog });



                this.setState({ TotalScan: returns.length + received.length });
                this.setState({ TotalAccomplished: accomplished.length });
                this.setState({ TotalAssigned: assigned.length });


                var total = returns.length + received.length;
                var accomplisheds = accomplished.length;
                var assigneds = assigned.length;

                var pieTotal = 0;
                var pieAssigned = 0;
                var pieAccomplished = 0;

                if (total === assigneds) {
                    pieTotal = 0;
                    pieAssigned = assigneds;
                } else {
                    var dif = total - assigneds;
                    pieTotal = dif;
                    pieAssigned = assigneds;
                }

                if (assigneds === accomplisheds) {
                    pieAssigned = 0;
                    pieAccomplished = accomplisheds;
                } else {
                    var dif = assigneds - accomplisheds;
                    pieAssigned = dif;
                    pieAccomplished = accomplisheds;
                }

                this.setState({ pieGraph: [{ title: "Total Accomplished", value: pieAccomplished }, { title: "Total Assigned", value: pieAssigned }, { title: "Total Not Assigned", value: pieTotal }] });

                this.setState({ loading: "true" });


            } else {

                for (let i = 0; i < result.length; i++) {
                    for (let b = 0; b < result[i].fieldman.length; b++) {
                        if (result[i].branch_name === branch) {
                            fieldman.push({ 'fieldman': result[i].fieldman[b], 'data': { 'assigned': [], 'accomplished': [] } });
                        }
                    }
                }

                var fieldmanAssigned = [];
                var fieldmanAccomplished = [];

                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].assigned.length; b++) {
                        fieldmanAssigned.push(mainData[i].assigned[b]);
                    }
                }
                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].accomplished.length; b++) {
                        fieldmanAccomplished.push(mainData[i].accomplished[b]);
                    }
                }


                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAssigned.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAssigned[b].user_id) {
                            fieldman[i].data.assigned.push(fieldmanAssigned[b]);
                        }
                    }
                }
                for (let i = 0; i < fieldman.length; i++) {
                    for (let b = 0; b < fieldmanAccomplished.length; b++) {
                        if (fieldman[i].fieldman.user_id === fieldmanAccomplished[b].user_id) {
                            fieldman[i].data.accomplished.push(fieldmanAccomplished[b]);
                        }
                    }
                }


                this.setState({ fieldmanMainData: fieldman });



                mainData.forEach(element => {
                    accomplished = accomplished.concat(element['accomplished']);
                    assigned = assigned.concat(element['assigned']);
                    new_assign = new_assign.concat(element['new_assign']);
                    new_execute = new_execute.concat(element['new_execute']);
                    old_assign = old_assign.concat(element['old_assign']);
                    old_execute = old_execute.concat(element['old_execute']);
                    pending = pending.concat(element['pending']);
                    received = received.concat(element['received']);
                    returns = returns.concat(element['return']);

                    totalJO = totalJO.concat(element['received']);
                    totalJO = totalJO.concat(element['return']);

                });

                this.setState({ accomplished: accomplished });
                this.setState({ assigned: assigned });
                this.setState({ new_assign: new_assign });
                this.setState({ new_execute: new_execute });
                this.setState({ old_assign: old_assign });
                this.setState({ old_execute: old_execute });
                this.setState({ notAssigned: pending });
                this.setState({ received: received });
                // this.setState({backlog: (returns.length + received.length) - (new_execute.length + old_execute.length)});
                for (let i = 0; i < totalJO.length; i++) {
                    if (totalJO[i].status !== "Accomplished") {
                        Backlog.push(totalJO[i]);
                    }
                }
                this.setState({ backlog: Backlog });

                this.setState({ TotalScan: returns.length + received.length });
                this.setState({ TotalAccomplished: accomplished.length });
                this.setState({ TotalAssigned: assigned.length });


                var total = returns.length + received.length;
                var accomplisheds = accomplished.length;
                var assigneds = assigned.length;

                var pieTotal = 0;
                var pieAssigned = 0;
                var pieAccomplished = 0;

                if (total === assigneds) {
                    pieTotal = 0;
                    pieAssigned = assigneds;
                } else {
                    var dif = total - assigneds;
                    pieTotal = dif;
                    pieAssigned = assigneds;
                }

                if (assigneds === accomplisheds) {
                    pieAssigned = 0;
                    pieAccomplished = accomplisheds;
                } else {
                    var dif = assigneds - accomplisheds;
                    pieAssigned = dif;
                    pieAccomplished = accomplisheds;
                }

                this.setState({ pieGraph: [{ title: "Total Accomplished", value: pieAccomplished }, { title: "Total Assigned", value: pieAssigned }, { title: "Total Not Assigned", value: pieTotal }] });

                // for(let i = 0; i<fieldman.length; i++){
                //     dataFieldman.push({user_id: fieldman[i].user_id, user_name: fieldman[i].user_name, branch: fieldman[i].branch});
                // }

                // this.setState({fieldmanMainData: dataFieldman});


                this.setState({ loading: "true" });
            }

        })

    }


    componentDidMount() {
        this.audit();
        this.branch();

        this.update();
    }

    componentWillUnmount() {
        if (localStorage.getItem("pass") !== "dashboard") {
            this._isMounted = false
        }
    }

    update() {
        if (localStorage.getItem("pass") === "dashboard") {
            setTimeout(() => {
                this.audit();
                this.branch();

                this.update();
            }, 500000);
        } else {
            clearTimeout(this.update);
        }
    }

    render() {
        // console.log(this.state.fieldmanMainData);
        var percent = (0 / 0) * 100;
        // console.log(percent);
        localStorage.setItem("pass", "dashboard");

        return (
            <div id="content" className="content">
                <div className="row text-center" style={{ marginTop: "-20px" }}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="#/payroll"><i className="fa fa-hand-holding-usd p-r-5 fa-lg text-success"></i><label>Navbar 1</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="#/holidays"><i className="fa fa-gift text-success p-r-5 fa-lg"></i><label>Navbar 2</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-briefcase fa-lg text-success p-r-5"></i><label>Navbar 3</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-user-slash fa-lg text-success p-r-5"></i><label>Navbar 4</label></a>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">Dashboad</h1>
                    </div>
                   
                </div>

               


                {(this.state.loading === "false" &&
                    <Loader fullPage loading />
                )}


                <div className="row">
                    <div className="col-md-7">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="row">

                                    <div className="form-inline">
                                        <div className="form-group m-r-10" >
                                            <DatePicker
                                                selected={this.state.startDate}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeStart}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group m-r-10" >
                                            <DatePicker
                                                selected={this.state.endDate}
                                                selectsEnd
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeEnd}
                                                minDate={this.state.startDate}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-check">
                                            <button className="bttnsubdash" type="submit" onClick={this.submit}>
                                                <span className="fa fa-chart-pie"></span>
                                            </button>
                                            {/* <button type="submit" className="btn btn-sm btn-primary m-r-5" onClick={this.submit}>Submit</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="panel">
                            <div className="panel-body">

                                <select className="form-control" onChange={this.textchange} name="selectedBranch">
                                    <option value="All">All</option>
                                    {this.state.branch.map(branch =>
                                        <option value={branch.name}>{branch.name}</option>
                                    )}
                                </select>

                            </div>

                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div className="card-body">
                                        <div className="mb-3 text-grey">
                                            <b className="mb-3">Total Scan</b>
                                            <span className="ml-2"><i className="fa fa-search" data-toggle="popover" data-trigger="hover" data-title="Conversion Rate" data-placement="top" data-content="Percentage of sessions that resulted in orders from total number of sessions." data-original-title="" title=""></i></span>
                                        </div>
                                        <div className="d-flex align-items-center mb-1 text-center">
                                            <h2 className="text-white mb-0"><span data-animation="number">{this.state.TotalScan}</span></h2>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div className="card-body">
                                        <div className="mb-3 text-grey">
                                            <b className="mb-3">Assigned</b>
                                            <span className="ml-2"><i className="fa fa-hand-point-right" data-toggle="popover" data-trigger="hover" data-title="Store Sessions" data-placement="top" data-content="Number of sessions on your online store. A session is a period of continuous activity from a visitor." data-original-title="" title=""></i></span>
                                        </div>
                                        <div className="d-flex align-items-center mb-1">
                                            <h2 className="text-white mb-0"><span data-animation="number">{this.state.TotalAssigned}</span></h2>
                                            <div className="ml-auto">
                                                <div id="store-session-sparkline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div className="card-body">
                                        <div className="mb-3 text-grey">
                                            <b className="mb-3">Accomplished</b>
                                            <span className="ml-2"><i className="fa fa-handshake text-center" data-toggle="popover" data-trigger="hover" data-title="Conversion Rate" data-placement="top" data-content="Percentage of sessions that resulted in orders from total number of sessions." data-original-title="" title=""></i></span>
                                        </div>
                                        <div className="d-flex align-items-center mb-1">
                                            <h2 className="text-white mb-0"><span data-animation="number">{this.state.TotalAccomplished}</span></h2>
                                            <div className="ml-auto">
                                                <div id="conversion-rate-sparkline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="row">
                    {/* <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-d-total card-colorful">
                                    <div className="pad-all text-center">
                                        <span className="text-3x text-thin">{this.state.TotalScan}</span>
                                        <p>Total Scan</p>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card card-d-assigned card-colorful">
                                    <div className="pad-all text-center">
                                        <span className="text-3x text-thin">{this.state.TotalAssigned}</span>
                                        <p>Assigned</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card card-d-accomplished card-colorful">
                                    <div className="pad-all text-center">
                                        <span className="text-3x text-thin">{this.state.TotalAccomplished}</span>
                                        <p>Accomplished</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <button className="bttnfoa" data-target="#demo-lg-modal2" data-toggle="modal">
                                            <span className="fa fa-file-contract"> &nbsp; {this.state.fieldmanMainData.length}</span>
                                        </button>
                                    </div>
                                    <div className="col-md-8">
                                        <h3>{this.state.selectedBranch}</h3>
                                    </div>
                                </div>
                                {/* <i className="fa fa-user pointer" data-target="#demo-lg-modal2" data-toggle="modal" style={{ fontSize: 20, color: 'green' }} /><span style={{ fontSize: 20 }}>{this.state.fieldmanMainData.length}</span> */}
                                <DPie1 pieGraph={this.state.pieGraph} />
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-4 card-d-border2">
                                        <div className="card card- card-colorful">
                                            <div className="pad-all text-center">
                                                <span className="text-3x text-thin">{this.state.new_assign.length}</span>
                                                <p>New FO Assigned</p>
                                                <button className="btn btn-sm btn-primary m-r-5" name="new_assign" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 card-d-border1">
                                        <div className="card card- card-colorful">
                                            <div className="pad-all text-center">
                                                <span className="text-3x text-thin">{this.state.old_assign.length}</span>
                                                <p>Old FO Assigned</p>
                                                <button className="btn btn-sm btn-primary m-r-5" name="old_assign" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 card-d-border2">
                                        <div className="card card- card-colorful">
                                            <div className="pad-all text-center">
                                                <span className="text-3x text-thin">{this.state.notAssigned.length}</span>
                                                <p>Not Assigned</p>
                                                <button className="btn btn-sm btn-primary m-r-5" name="pending" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card card- card-colorful">
                                            <div className="pad-all text-center">
                                                <span className="text-3x text-thin">{this.state.new_execute.length}</span>
                                                <p>New FO Execute</p>
                                                <button className="btn btn-sm btn-primary m-r-5" name="new_execute" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 card-d-border3">
                                        <div className="card card- card-colorful">
                                            <div className="pad-all text-center">
                                                <span className="text-3x text-thin">{this.state.old_execute.length}</span>
                                                <p>Old FO Execute</p>
                                                <button className="btn btn-sm btn-primary m-r-5" name="old_execute" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card card- card-colorful">
                                            <div className="pad-all text-center">
                                                <span className="text-3x text-thin">{this.state.backlog.length}</span>
                                                <p>Backlog</p>
                                                <button className="btn btn-sm btn-primary" name="backlog" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div> */}


                <div className="row row-space-10 m-b-20">
                    <div className="col-lg-4 col-sm-6">
                        <div className="widget widget-stats bg-light m-b-10">
                            <div className="stats-content">
                            <div className="row">
                                    <div className="col-md-6">
                                        <div className="stats-title">New FO Assigned</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="fa fa-info-circle fa-sm btnmis pull-right" name="new_assign" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>
                                        {/* <span className="fa fa-info-circle fa-sm"></span> */}
                                        Details
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-number"><h1>{this.state.new_assign.length}</h1></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="widget widget-stats bg-light m-b-10">
                            <div className="stats-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stats-title">Old FO Assigned</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="fa fa-info-circle fa-sm btnmis pull-right" name="old_assign" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>
                                        {/* <span className="fa fa-info-circle fa-sm"></span> */}
                                        Details
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-number"><h1>{this.state.old_assign.length}</h1></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="widget widget-stats bg-light m-b-10">
                            <div className="stats-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stats-title">Not Assigned</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="fa fa-info-circle fa-sm btnmis pull-right" name="pending" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>
                                         {/* <span className="fa fa-info-circle fa-sm"></span> */}
                                         Details
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-number"><h1>{this.state.notAssigned.length}</h1></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="widget widget-stats bg-light m-b-10">
                            <div className="stats-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stats-title">New FO Execute</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="fa fa-info-circle fa-sm btnmis pull-right" name="new_execute" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>
                                          {/* <span className="fa fa-info-circle fa-sm"></span> */}
                                          Details
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-number"><h1>{this.state.new_execute.length}</h1></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="widget widget-stats bg-light m-b-10">
                            <div className="stats-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stats-title">Old FO Execute</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="fa fa-info-circle fa-sm btnmis pull-right" name="old_execute" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>
                                         {/* <span className="fa fa-info-circle fa-sm"></span> */}
                                         Details
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-number"><h1>{this.state.old_execute.length}</h1></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="widget widget-stats bg-light m-b-10">
                            <div className="stats-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stats-title">Backlog</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="fa fa-info-circle fa-sm btnmis pull-right" name="backlog" data-target="#demo-lg-modal3" data-toggle="modal" onClick={this.viewDetails}>
                                          {/* <span className="fa fa-info-circle fa-sm"></span> */}
                                          Details
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-number"><h1>{this.state.backlog.length}</h1></div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <div id="demo-lg-modal2" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                            <div className="modal-body">
                                     
                                         <div className="row">
                                            <div className="col-md-4">
                                            {this.state.fieldmanMainData.map(main =>
                                                <div className="row pointer" style={{ border: "1px solid"}} onClick={this.fieldmanGraph}>
                                                    <div className="col-md-5">
                                                        <p>{main.fieldman.user_name}</p>
                                                        <p>21%</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <p>Assign: {main.data.assigned.length}</p>
                                                        <p>Accomplished: {main.data.accomplished.length}</p>
                                                    </div>
                                                </div>
                                                 )}
                                            </div>
                                            <div className="col-md-8">
                                               
                                                 <Pictorial/>
                                               
                                            </div>
   
                                        </div>
                                       
                                     
                            </div>
                        </div>
                    </div>
                </div> */}

                <div id="demo-lg-modal2" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"></h4>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="tableFixHead">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Fieldman</th>
                                                <th>Assigned</th>
                                                <th>Accomplished</th>
                                                <th><p style={{ visibility: 'hidden' }}>aaaaaaaaaaaaaaaaaaaaaaaaaaaaa</p></th>
                                                <th>Branch</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.fieldmanMainData.map(main => {
                                                var assigned = main.data.assigned.length;
                                                var accomplished = main.data.accomplished.length;
                                                var percent = (accomplished / assigned) * 100;
                                                var final = Math.trunc(percent);

                                                if (percent >= 71) {
                                                    return <tr>
                                                        <td>{main.fieldman.user_id}</td>
                                                        <td>{main.fieldman.user_name}</td>
                                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAssigned" data-toggle="modal" style={{ fontSize: "15px", color: '#2980b9' }} id={main.fieldman.user_id} onClick={this.showFieldmanAssign}>{main.data.assigned.length}</p></u></center></td>
                                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAccomplished" data-toggle="modal" style={{ fontSize: "15px", color: '#218c74' }} id={main.fieldman.user_id} onClick={this.showFieldmanAccomplished}>{main.data.accomplished.length}</p></u></center></td>
                                                        <td><div className="progress progress-striped">
                                                            <div className="progress-bar progress-bar-success" style={{ width: final + "%", backgroundColor: '#27ae60' }}>{final + "%"}</div>
                                                        </div></td>
                                                        <td>{main.fieldman.branch}</td>
                                                    </tr>

                                                } else if (percent >= 41 || percent <= 70) {
                                                    return <tr>
                                                        <td>{main.fieldman.user_id}</td>
                                                        <td>{main.fieldman.user_name}</td>
                                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAssigned" data-toggle="modal" style={{ fontSize: "15px", color: '#2980b9' }} id={main.fieldman.user_id} onClick={this.showFieldmanAssign}>{main.data.assigned.length}</p></u></center></td>
                                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAccomplished" data-toggle="modal" style={{ fontSize: "15px", color: '#218c74' }} id={main.fieldman.user_id} onClick={this.showFieldmanAccomplished}>{main.data.accomplished.length}</p></u></center></td>
                                                        <td><div className="progress progress-striped">
                                                            <div className="progress-bar progress-bar-success" style={{ width: final + "%", backgroundColor: '#f1c40f' }}>{final + "%"}</div>
                                                        </div></td>
                                                        <td>{main.fieldman.branch}</td>
                                                    </tr>

                                                } else if (percent < 40) {
                                                    return <tr>
                                                        <td>{main.fieldman.user_id}</td>
                                                        <td>{main.fieldman.user_name}</td>
                                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAssigned" data-toggle="modal" style={{ fontSize: "15px", color: '#2980b9' }} id={main.fieldman.user_id} onClick={this.showFieldmanAssign}>{main.data.assigned.length}</p></u></center></td>
                                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAccomplished" data-toggle="modal" style={{ fontSize: "15px", color: '#218c74' }} id={main.fieldman.user_id} onClick={this.showFieldmanAccomplished}>{main.data.accomplished.length}</p></u></center></td>
                                                        {percent <= 5 ?
                                                            <td><div className="progress progress-striped">
                                                                <div className="progress-bar progress-bar-success" style={{ width: final + "%", backgroundColor: '#e74c3c', color: 'black' }}>{final + "%"}</div>
                                                            </div>
                                                            </td>
                                                            :
                                                            <td><div className="progress progress-striped">
                                                                <div className="progress-bar progress-bar-success" style={{ width: final + "%", backgroundColor: '#e74c3c' }}>{final + "%"}</div>
                                                            </div>
                                                            </td>
                                                        }
                                                        <td>{main.fieldman.branch}</td>
                                                    </tr>

                                                }

                                            }
                                            )}

                                            {/* {this.state.fieldmanMainData.map(main =>{
                                        var assigned = main.data.assigned.length;
                                        var accomplished = main.data.accomplished.length;
                                        var percent = (accomplished / assigned) * 100;
                                        var final = Math.trunc(percent);
                                        if(percent > 71){
                                            return <tr>
                                            <td>{main.fieldman.user_id}</td>
                                            <td>{main.fieldman.user_name}</td>
                                           
                                            <td><center><u><p className="pointer" data-target="#demo-lg-modalAssigned" data-toggle="modal" style={{fontSize: "15px", color: '#2980b9'}} id={main.fieldman.user_id} onClick={this.showFieldmanAssign}>{main.data.assigned.length}</p></u></center></td>
                                            <td><center><u><p className="pointer" data-target="#demo-lg-modalAccomplished" data-toggle="modal" style={{fontSize: "15px", color: '#218c74'}} id={main.fieldman.user_id} onClick={this.showFieldmanAccomplished}>{main.data.accomplished.length}</p></u></center></td>
                                            <td><div className="progress progress-striped active">
                                                    <div className="progress-bar progress-bar-success" style={{width: final+"%", backgroundColor: '#27ae60'}}>{final+"%"}</div>
                                                </div>
                                               
 
                                            </td>
                                            <td>{main.fieldman.branch}</td>
                                        </tr>
                                    }
                                    else if (percent >= 41 && percent <= 70){
                                        return <tr>
                                        <td>{main.fieldman.user_id}</td>
                                        <td>{main.fieldman.user_name}</td>
                                     
                                      <td><center><u><p className="pointer" data-target="#demo-lg-modalAssigned" data-toggle="modal" style={{fontSize: "15px", color: '#2980b9'}} id={main.fieldman.user_id} onClick={this.showFieldmanAssign}>{main.data.assigned.length}</p></u></center></td>
                                            <td><center><u><p className="pointer" data-target="#demo-lg-modalAccomplished" data-toggle="modal" style={{fontSize: "15px", color: '#218c74'}} id={main.fieldman.user_id} onClick={this.showFieldmanAccomplished}>{main.data.accomplished.length}</p></u></center></td>
                                        <td><div className="progress progress-striped active">
                                                <div className="progress-bar progress-bar-success" style={{width: final+"%", backgroundColor: '#f1c40f'}}>{final+"%"}</div>
                                            </div>
                                           

                                        </td>
                                        <td>{main.fieldman.branch}</td>
                                    </tr>
                                    }else if (percent < 40){
                                        return <tr>
                                        <td>{main.fieldman.user_id}</td>
                                        <td>{main.fieldman.user_name}</td>
                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAssigned" data-toggle="modal" style={{fontSize: "15px", color: '#2980b9'}} id={main.fieldman.user_id} onClick={this.showFieldmanAssign}>{main.data.assigned.length}</p></u></center></td>
                                        <td><center><u><p className="pointer" data-target="#demo-lg-modalAccomplished" data-toggle="modal" style={{fontSize: "15px", color: '#218c74'}} id={main.fieldman.user_id} onClick={this.showFieldmanAccomplished}>{main.data.accomplished.length}</p></u></center></td>
                                        {percent  <= 5 ?
                                          <td><div className="progress progress-striped active">
                                          <div className="progress-bar progress-bar-success" style={{width: final+"%", backgroundColor: '#e74c3c', color: 'black'}}>{final+"%"}</div>
                                        </div>
                                        </td>
                                        :
                                        <td><div className="progress progress-striped active">
                                                <div className="progress-bar progress-bar-success" style={{width: final+"%", backgroundColor: '#e74c3c'}}>{final+"%"}</div>
                                            </div>
                                        </td>
                                        }
                                        <td>{main.fieldman.branch}</td>
                                    </tr>
                                    }
                                       
                                    })} */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modalAssigned" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-mm">
                        <div className="modal-content">
                            <div className="modal-header">
                                {this.state.showFieldmanAssignTitle.map(title =>
                                    <h4 className="modal-title">Assigned to: {title.user_id + "-" + title.user_fname + " " + title.user_lname}</h4>
                                )}
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body table-scroll2 scrollDesign">
                                <div className="tableFixHead">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>FO #</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.showFieldmanAssign.map((map, i) =>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{map.jo_number}</td>

                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modalAccomplished" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                {this.state.showFieldmanAccomplishedTitle.map(title =>
                                    <h4 className="modal-title">Accomplished by: {title.user_id + "-" + title.user_fname + " " + title.user_lname}</h4>
                                )}
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body table-scroll2 scrollDesign">
                                <div className="tableFixHead">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>FO #</th>
                                                <th>Meter Number</th>
                                                <th>Meter Condition</th>
                                                <th>Reading</th>
                                                <th>Created</th>
                                                <th>Posted </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.showFieldmanAccomplished.map((map, i) =>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{map.jo_number}</td>
                                                    <td>{map.meter_number}</td>
                                                    <td>{map.meter_condition}</td>
                                                    <td>{map.meter_reading}</td>
                                                    <td>{map.date_created}</td>
                                                    <td>{map.date_posted}</td>

                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="demo-lg-modal3" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"></h4>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>

                            <div className="modal-body">
                                <div className="tableFixHead">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>JO Number</th>
                                                <th>Meter Number</th>
                                                <th>Meter Condition</th>
                                                <th>Fieldman</th>
                                                <th>Branch</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.viewDetails.map((view, i) =>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{view.jo_number}</td>
                                                    <td>{view.meter_number}</td>
                                                    <td>{view.meter_condition}</td>
                                                    <td>{view.user_fname}</td>
                                                    <td>{view.branch_name}</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Dashboard;