import React, { Component, createRef } from 'react';
import DatePicker from "react-datepicker";
import { getData } from "../../../api/api";
import axios, { post } from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
// import { SelectableGroup } from 'react-selectable-fast'
import Counters from './Counters'
import List from './List'
import data from './sample-data'

class Assign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fieldAudit: [],
			startDate: new Date(),
			endDate: new Date(),
			file: [],
			todaydate: "",
			branches: [],
			branch: "",
			fileuploadname: [],
			fieldman:[],
			fieldman_name:[],
			filterStartDate: new Date(),
			disableFirstRow: false,
		}
		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onChangeBranch = this.onChangeBranch.bind(this);
		this.onChangeBranch2 = this.onChangeBranch2.bind(this);
	}
	_isMounted = true;

	filterDateStart = e => {
		var from = new Date(e);
		var data = from.toISOString();
		var dataFrom = data.split("T")
		this.setState({
				filterStartDate: e
		});
	

	}
	
	onChange(e) {

		this.setState({ file: e.target.files })
		alert("Check the date before clicking submit button.")


	}
	onChangeDate = (event) => {
		this.setState({ todaydate: event.target.value });
	}
	fileUpload(file) {


		const url = 'http://beta.pacificweb.com.ph/field_audit/upload_excel/' + this.state.todaydate + "/" + this.state.branch;
		const formData = new FormData();


		for (let i = 0; i < this.state.file.length; i++) {
			formData.append('file' + i, file[i])
		}

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		return post(url, formData, config)

	}
	onFormSubmit(e) {
		e.preventDefault() // Stop form submit

		if (this.state.file.length > 9) {

			alert("Upload 9 files only.");
		}
		else {


			this.fileUpload(this.state.file).then((response) => {

				console.log((response.data));
				this.setState({ fileuploadname: response.data })
				alert("Data Uploaded");

			}).catch(err => {
				this.setState({ loading: true })

				alert("Failed to Upload");
			});


		}

	}
	branch() {
		getData("field_audit/getMISbranch").then(response => {
			console.log(response)
			this.setState({ branches: response.elf, fileuploadname: response.filename_upload ,fieldman:response.fieldman })
		})
	}
	componentWillMount() {
		this.branch();

	}

	onChangeBranch(e) {
		var options = e.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}

		value.forEach(element => {
			this.setState({ branch: element });

		});

	}
	onChangeBranch2(e) {
		var options = e.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}

		value.forEach(element => {
			this.setState({ branch: element });
			var data=[]
			for(let i=0;i<this.state.fieldman.length;i++){

				if(this.state.fieldman[i].branch_name == element){
					data.push(this.state.fieldman[i].user_fname+" "+this.state.fieldman[i].user_lname)
				}
			}
			this.setState({ fieldman_name: data });
		});

	}
	render() {
		const { disableFirstRow, reversed } = this.state

		const itemsToRender = disableFirstRow ? data.slice(5) : data
		const orderedItems = reversed ? itemsToRender.slice().reverse() : itemsToRender
		
		return (

			<div id="content" className="content">
											
				<div className="row">
				<div className="col-md-2">{/*col-md-3*/}
						<div className="panel">
							<form onSubmit={this.onFormSubmit}>
							
								<div className="panel-body">
								<h5>Select Date</h5>
								<DatePicker
										id="dateto"
										selected={this.state.filterStartDate}
										selectsEnd
									
										startDate={this.state.filterStartDate}
										// endDate={this.state.filterEndDate}
										onChange={this.filterDateStart}
										className="form-control"
								/>
									<hr></hr>
								
									<h5>Select Branch</h5>
									<select className="form-control" onChange={this.onChangeBranch2} required>
										<option value="All"></option>
										{this.state.branches.map((branch, index) =>
											<option value={branch.name}>{branch.name}</option>

										)}
									</select>
									<br></br>
									<h5>Select Fieldman</h5>
									<select className="form-control" onChange={this.onChangeBranch} required>
										<option value="All"></option>
										{this.state.fieldman_name.map((branch, index) =>
											<option value={branch}>{branch}</option>

										)}
									</select>
							
									<br></br>
									<button className="btn btn-block btn-xs btn-info" type="submit">Assign</button>
								</div>
							
							</form>
						</div>
					</div>{/*end col-md3*/}	
					<div className="col-md-9">{/*start col-md9*/}
						<div className="panel">
							<div className="panel-body">
								<div className="pad-btm form-inline">
									<div className="row">
										<div className="col-sm-12 table-toolbar-right">
											<div className="form-group">

											</div>
										</div>
									</div>
								</div>
								<div >
								{/* <SelectableGroup
                                      ref={ref => (window.selectableGroup = ref)}
                                      className="main"
                                      clickClassName="tick"
                                      enableDeselect
                                      tolerance={0}
                                      deselectOnEsc={false}
                                      allowClickWithoutSelected={false}
                                      duringSelection={this.handleSelecting}
                                      onSelectionClear={this.handleSelectionClear}
                                      onSelectionFinish={this.handleSelectionFinish}
                                      ignoreList={['.not-selectable']}
                                      >
                                      <List items={orderedItems} />
                                      </SelectableGroup> */}
								</div>
							</div>
						</div>
					</div>{/*end col-md9*/}				
				</div>
			</div>


		);
	}
}

export default Assign;