import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom';
import DBar2 from "./charts/d_bar2";
import $ from "jquery";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from './Header/index';
// import PAGE from'./employeeDetails'
// import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup, } from '@progress/kendo-react-excel-export';
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            civil_status: "",
            gender: "",
            companies: [],
            firstname: "",
            lastname: "",
            middlename: "",
            suffixname: "",
            // address: "",
            contact: "",
            email: "",
            department: "",
            company: "",
            position: "",
            refresh: false,
            check: [],
            employeDetails: [],
            edit: false,
            user_id: "",
            username: "",
            searchDriver: "",
            deleteUser: [],
            hideDelete: [],
            branches: [],
            branch_id: "",
            salary_edit: false,
            schedule_edit: false,
            salary_rate: 0,
            create: true,
            default: true,
            others: true,
            flextime: true,
            typeRate: "",
            typeSched: "",
            company_ids: "",
            branch_ids: "",
            weekDays: [],
            mondayIN: "08:00",
            mondayOUT: "17:00",
            tuesdayIN: "08:00",
            tuesdayOUT: "17:00",
            wednesdayIN: "08:00",
            wednesdayOUT: "17:00",
            thursdayIN: "08:00",
            thursdayOUT: "17:00",
            fridayIN: "08:00",
            fridayOUT: "17:00",
            saturdayIN: "08:00",
            saturdayOUT: "17:00",
            sundayIN: "08:00",
            sundayOUT: "17:00",
            hidemonday: true,
            hidetuesday: true,
            hidewednesday: true,
            hidethursday: true,
            hidefriday: true,
            hidesaturday: true,
            hidesunday: true,
            editmonday: false,
            editmonday2: true,
            edittuesday: false,
            edittuesday2: true,
            editwednesday: false,
            editwednesday2: true,
            editthursday: false,
            editthursday2: true,
            editfriday: false,
            editfriday2: true,
            editsaturday: false,
            editsaturday2: true,
            editsunday: false,
            editsunday2: true,
            getdaySched: [],
            createdSched: [],
            daySched: [{ day: 'Mon', val: 'M' }, { day: 'Tue', val: 'T' }, { day: 'Wed', val: 'W' }, { day: 'Thu', val: 'TH' }, { day: 'Fri', val: 'F' }, { day: 'Sat', val: 'ST' }, { day: 'Sun', val: 'SN' }],
            backgroundColor1: '#348fe2',
            backgroundColor2: '#348fe2',
            backgroundColor3: '#348fe2',
            backgroundColor4: '#348fe2',
            user_request_hierarchy: [],
            request_type: [],
            Hierarchy: false,
            newHierarchy: false,
            SelectHierarchy: true,
            ADDLevel: [],
            name_hierarchy: [],
            name_hierarchy1: [],
            count: 0,
            indexVal: 0,
            HIERARCHY: [],
            check_status: false,
            req_type: [],
            hierarchy_name: "",
            hierarchy_structures: [],
            selecthierarchy: "",
            selectedHierarchy: [],
            review: false,
            type_details: [],
            select_type: [],
            u_id: "",
            employeeIndex: 0,
            modal: "",
            modalPath: "",
            modalnav1: "#32a932",
            modalnav2: "",
            modalnav3: "",
            modalnav4: "",
            modalnav5: "",
            modalnav6: "",
            modalcol1: "white",
            modalcol2: "",
            modalcol3: "",
            modalcol4: "",
            modalcol5: "",
            modalcol6: "",
            click: "personalinfo",
            barGraph: [],
            bgcolor1: "#404040",
            bgcolor2: "transparent",
            bgcolor3: "transparent",
            bgcolor4: "transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            txtcolor3: "black",
            txtcolor4: "black",
            dateData: [],
            navbar: "navigation1",
            picture: '',
            showinfo: 'personalinfo',
            colorinfo1: 'green',
            colorinfo2: 'gray',
            colorinfo3: 'gray',
            colorinfo4: 'gray',
            departments: [],
            dept_id: "",
            Allowance: false,
            allowance: 0,
            leavewithpay: "",
            countleave: 0,
            cntleave: [],
            infodata: [],
            fixed: false,
            sss: '',
            tin: '',
            philHealth: '',
            pagIbig: '',
            positions: [],
            getUserCompanyID: "",
            jd: [],
            jobd: '',
            jd_id: '',
            shift: '',
            startDate_sched: '',
            lunchBreak: [],
            sched_days: [],
            View: false,
            userjob_position: '',
            employment_status: "",
            employee_status: "",
            completeName: "",
            h_single: [],
            pres_house_street_subd: "",
            pres_barangay: "",
            pres_city: "",
            pres_province: "",
            pres_country: "",
            pres_zip: "",
            perm_house_street_subd: "",
            perm_barangay: "",
            perm_city: "",
            perm_province: "",
            perm_country: "",
            perm_zip: "",
            same_address: false,
            sel_company: "",
            sel_branch: "",
            sel_status: "",
            sel_position: "",
            user_bday: '',
            user_date_hired: '',
            birthplace: '',
            contact_relation: '',
            contact_number: '',
            contact_name: '',
            contanct_address: '',
            Mon: '12:00',
            Tue: '12:00',
            Wed: '12:00',
            Thu: '12:00',
            Fri: '12:00',
            Sat: '12:00',
            Sun: '12:00',

        }
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeSelectGender = this.onChangeSelectGender.bind(this);
        this.onChangeSelectCompany = this.onChangeSelectCompany.bind(this);
        this.onChangeSelectBranch = this.onChangeSelectBranch.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.textchange = this.textchange.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.UpdateSalary = this.UpdateSalary.bind(this);
        this.UpdateSchedule = this.UpdateSchedule.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleChange3 = this.handleChange3.bind(this);
        this.handleChange4 = this.handleChange4.bind(this);
        this.handleChange5 = this.handleChange5.bind(this);
        this.handleChange6 = this.handleChange6.bind(this);
        this.handleChange7 = this.handleChange7.bind(this);
        this.handleChange8 = this.handleChange8.bind(this);
        this.handleChange9 = this.handleChange9.bind(this);
        this.handleChange10 = this.handleChange10.bind(this);
        this.handleChange11 = this.handleChange11.bind(this);
        this.handleChange12 = this.handleChange12.bind(this);
        this.handleChange13 = this.handleChange13.bind(this);
        this.handleChange14 = this.handleChange14.bind(this);
        this.UpdateAllowance = this.UpdateAllowance.bind(this);

        this.onChangeSelectHierarchy = this.onChangeSelectHierarchy.bind(this);
        this.onChangeSelectDepartment = this.onChangeSelectDepartment.bind(this);
        this.onSubmitLeave = this.onSubmitLeave.bind(this);
        this.onChangeSelectPosition = this.onChangeSelectPosition.bind(this);
        this.onChangeSelectShift = this.onChangeSelectShift.bind(this);
        this.onChangeSelectJD = this.onChangeSelectJD.bind(this);
        this.onChangeSelectEmployeeStatus = this.onChangeSelectEmployeeStatus.bind(this);
        this.onChangeSelectEmploymentStatus = this.onChangeSelectEmploymentStatus.bind(this);
        this.saveStatus = this.saveStatus.bind(this);
        this.submitSingle = this.submitSingle.bind(this);
        this.uploadSingle = this.uploadSingle.bind(this);
        this.filterCompany = this.filterCompany.bind(this);
        this.filterBranch = this.filterBranch.bind(this);
        this.filterStatus = this.filterStatus.bind(this);
        this.filterPosition = this.filterPosition.bind(this);
        this.bdaypicker = this.bdaypicker.bind(this);
        this.dhiredpicker = this.dhiredpicker.bind(this);
        this.handleChangeL = this.handleChangeL.bind(this)
        // this.createNotification = this.createNotification.bind(this);
    }
    handleChange1(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Mon") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange2(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Mon") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    handleChange3(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Tue") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange4(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Tue") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    handleChange5(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Wed") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange6(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Wed") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    handleChange7(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Thu") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange8(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Thu") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    handleChange9(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Fri") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange10(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Fri") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    handleChange11(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Sat") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange12(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Sat") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    handleChange13(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Sun") {
                this.state.weekDays[i].timein = e.target.value;
            }
        }
    }
    handleChange14(e) {
        for (let i = 0; i < this.state.weekDays.length; i++) {
            if (this.state.weekDays[i].day == "Sun") {
                this.state.weekDays[i].timeout = e.target.value;
            }
        }
    }
    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                let x = [];
                x = response.users;
                x.forEach(element => {
                    if (element['company_name'] === undefined || element['company_name'] === null) {
                        element['company_name'] = "";
                    }
                    if (element['branch_name'] === undefined || element['branch_name'] === null) {
                        element['branch_name'] = "";
                    }
                    if (element['user_jobposition'] === undefined || element['user_jobposition'] === null) {
                        element['user_jobposition'] = "";
                    } else {
                        element['user_jobposition'] = element['user_jobposition'].toUpperCase()
                    }
                });
                this.setState({ userData: x, companies: response.companies, req_type: response.request_type, departments: response.departments, hierarchy_structures: response.hierarchy_structures })
                console.log(this.state.hierarchy_structures)
            })
    }
    componentDidMount() {
        this.checkPagePrivilege();
        this.getUser();
        this.getuserDetails();
    }
    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ civil_status: options[i].value })
            }
        }
    }
    onChangeSelectHierarchy(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                var data = []
                if (options[i].value != "") {
                    data.push(this.state.hierarchy_structures[options[i].value])
                    var data_ty = []
                    for (let k = 0; k < this.state.hierarchy_structures[options[i].value].structure.length; k++) {
                        data_ty.push(this.state.hierarchy_structures[options[i].value].structure[k])
                    }
                    this.setState({ selecthierarchy: options[i].value, selectedHierarchy: data, select_type: data_ty })
                } else {
                    this.setState({ select_type: [] })
                }
            }
        }
    }
    onChangeSelectGender(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ gender: options[i].value })
            }
        }
    }
    onChangeSelectShift(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ shift: options[i].value })
            }
        }
    }
    onChangeSelectCompany(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                this.setState({ company: options[i].value })
            }
        }
        getData("users/getBranch", company)
            .then(result => {
                this.setState({ branches: result })

            });

    }
    onChangeSelectDepartment(e) {
        var options = e.target.options;
        var value = [];
        var dept_id = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                dept_id = options[i].value;
                value.push(options[i].value);
                this.setState({ dept_id: options[i].value })
            }
        }
        // getData("users/getDepartment", departments)
        //     .then(result => {

        //     });
    }
    onChangeSelectBranch(e) {
        var options = e.target.options;
        var value = [];
        var branch_id = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch_id = options[i].value;
                value.push(options[i].value);
                this.setState({ branch_id: options[i].value })
            }
        }
    }
    textchange = e => {

        // var test1 = String('this.')
        // var test2 = String("state")
        // var test = String("name_hierarchy"+''+(2))
        // var final = test1+test2+'.'+test
        // var t = final.replace('"','')
        // alert(t);
        if (e.target.name == "title") {

            this.state.name_hierarchy[this.state.indexVal].title = e.target.value
            this.setState({ refresh: true })
            setTimeout(() => {
                var match = false
                for (let index = 0; index < this.state.name_hierarchy.length; index++) {
                    var title = this.state.name_hierarchy[index].title
                    if (title == "") {
                        match = true
                    } if (this.state.name_hierarchy[index].approver.length == 0) {
                        match = true
                    } if (this.state.hierarchy_name == "") {
                        match = true
                    }
                }
                if (match == true) {


                } else {
                    // alert("hehehe")
                    this.setState({ modal: "modal", modalPath: "#demo-lg-modal-Review" }); this.review()
                }
            }, 100)
        } else {
            this.setState({ [e.target.name]: e.target.value });


        }
    };
    onFormSubmit(e) {
        e.preventDefault();

        var data = {
            dept_id: this.state.dept_id,
            app_id: 7,
            username: this.state.username,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            middlename: this.state.middlename,
            suffixname: this.state.suffixname,
            // address: this.state.address,
            contact: this.state.contact,
            civilStatus: this.state.civil_status,
            gender: this.state.gender,
            email: this.state.email,
            company_id: this.state.company,
            b_id: this.state.branch_id,
            userjob_position: this.state.userjob_position,
            jd: this.state.jobd,
            pres_house_street_subd: this.state.pres_house_street_subd,
            pres_barangay: this.state.pres_barangay,
            pres_city: this.state.pres_city,
            pres_province: this.state.pres_province,
            pres_country: this.state.pres_country,
            pres_zip: this.state.pres_zip,
            perm_house_street_subd: this.state.perm_house_street_subd,
            perm_barangay: this.state.perm_barangay,
            perm_city: this.state.perm_city,
            perm_province: this.state.perm_province,
            perm_country: this.state.perm_country,
            perm_zip: this.state.perm_zip,
            user_bday: moment(this.state.user_bday).format('YYYY-MM-DD'),
            user_date_hired: moment(this.state.user_date_hired).format('YYYY-MM-DD'),
            birthplace: this.state.birthplace,
            contact_relation: this.state.contact_relation,
            contact_number: this.state.contact_number,
            contact_name: this.state.contact_name,
            contact_address: this.state.contact_address,
            employee_status: this.state.employee_status,
            // employment_status : this.state.employment_status,        

        }
        getData("users/addingfieldman", data)
            .then(result => {
                let user = result;
                if (user.res === "wrong") {
                    NotificationManager.error('Employement Already Exists', 'Failed', 5000, true)
                } if (user.res === "success") {
                    NotificationManager.success('Employee Added Successfully', 'Success', 5000, true)
                    this.getUser();
                }
            });
        ;
    }
    employeeDetails(data) {
        this.getSchedLogs(data.user_id)
        var array = []
        var request_type = [{ type: 'Overtime' }, { type: 'Official Business' }, { type: 'Leave' }, { type: 'Undertime' }, { type: 'Additonal Basic Adjustment' }, { type: 'Deduction Basic Adjustment' }]
        var details = [data]
        var hierar = data.user_request_hierarchy
        if (data.user_request_hierarchy != "") {
            hierar = JSON.parse(data.user_request_hierarchy)
        }
        this.setState({

            //Set Data to the state for editing purpose
            user_id: data.user_id,
            firstname: data.user_fname,
            lastname: data.user_lname,
            middlename: data.user_mname,
            // address: data.user_address,
            suffixname: data.user_sname,
            contact: data.user_mobile,
            civil_status: data.user_civilstatus,
            gender: data.user_gender,
            email: data.user_email,


            company: data.company_id,
            branch: data.branch_name,
            department: data.dept_name,
            dept_id: data.dept_id,
            userjob_position: data.user_jobposition,
            position: data.pos_id,
            sss: data.sss,
            philHealth: data.philHealth,
            tin: data.tin,
            pagIbig: data.pagIbig,
            jobd: data.job_description,
            branch_id: data.branch_id,
            company_id: data.company_id,
            jd_id: data.jd_id,
            pres_house_street_subd: data.pres_house_street_subd,
            pres_barangay: data.pres_barangay,
            pres_city: data.pres_city,
            pres_province: data.pres_province,
            pres_country: data.pres_country,
            pres_zip: data.pres_zip,
            perm_house_street_subd: data.perm_house_street_subd,
            perm_barangay: data.perm_barangay,
            perm_city: data.perm_city,
            perm_province: data.perm_province,
            perm_country: data.perm_country,
            perm_zip: data.perm_zip,


            employeDetails: details,
            company_ids: data.company_id,
            branch_ids: data.branch_id,
            edit: false,
            salary_edit: false,
            user_request_hierarchy: hierar,
            request_type: request_type,
            u_id: data.user_id,
            picture: data.user_pic,
            countleave: data.user_leave,
            infodata: data,
            click: "personalinfo",
            modalnav1: "#32a932",
            modalnav2: "transparent",
            modalnav3: "transparent",
            modalnav4: "transparent",
            modalnav5: "transparent",
            modalnav6: "transparent",
            modalcol1: 'white',
            modalcol2: 'black',
            modalcol3: 'black',
            modalcol4: 'black',
            modalcol5: 'black',
            modalcol6: 'black'

        })

        var user_id = {
            u_id: data.user_id,
            user_id: localStorage.getItem("u")
        }
        getData("users/getSched", user_id)
            .then(result => {
                this.setState({ getdaySched: result.data })
                this.setState({ createdSched: result.date_created })
            });

        setTimeout(() => {
            this.getLeaveCount();
        }, 200)

    }
    employeeUpdate(data) {
        this.setState({
            edit: true,
        })
    }
    employeeUpdateSalary(data) {

        this.setState({ salary_edit: true, salary_rate: data.salary_rate, user_id: data.user_id })
    }
    employeeUpdateAllowance(data) {
        this.setState({ Allowance: true, user_id: data.user_id })
    }
    employeeUpdateSchedule(data) {

        this.setState({ schedule_edit: true, user_id: data.user_id })
    }
    getSingleUser() {

        getData("users/fetched_users_Single", this.state.user_id)
            .then(result => {
                this.setState({
                    employeDetails: result
                })
            })

    }
    onSubmitEdit(e) {
        e.preventDefault();

        var data = {
            dept_id: this.state.dept_id,
            user_id: this.state.user_id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            middlename: this.state.middlename,
            suffixname: this.state.suffixname,
            // address: this.state.address,
            contact: this.state.contact,
            civilStatus: this.state.civil_status,
            gender: this.state.gender,
            email: this.state.email,
            company_id: this.state.company,
            b_id: this.state.branch_id,
            sss: this.state.sss,
            tin: this.state.tin,
            philHealth: this.state.philHealth,
            pagIbig: this.state.pagIbig,
            jd: this.state.jobd,
            position: this.state.userjob_position,
            pres_house_street_subd: this.state.pres_house_street_subd,
            pres_barangay: this.state.pres_barangay,
            pres_city: this.state.pres_city,
            pres_province: this.state.pres_province,
            pres_country: this.state.pres_country,
            pres_zip: this.state.pres_zip,
            perm_house_street_subd: this.state.perm_house_street_subd,
            perm_barangay: this.state.perm_barangay,
            perm_city: this.state.perm_city,
            perm_province: this.state.perm_province,
            perm_country: this.state.perm_country,
            perm_zip: this.state.perm_zip
        }
        getData("users/updatefieldman", data)
            .then(result => {
                let user = result;
                if (user.res === "wrong") {
                    NotificationManager.warning('Employee Already Exist', 'Alert', 5000, true);

                } if (user.res === "success") {
                    NotificationManager.success('Employee Information Updated Successfully', 'Success', 5000, true);
                    let employeDetails = this.state.employeDetails;
                    employeDetails.forEach(element => {
                        element['userjob_position'] = this.state.position
                        element['dept_id'] = this.state.dept_id;
                        element['user_id'] = this.state.user_id;
                        element['firstname'] = this.state.firstname
                        element['lastname'] = this.state.lastname
                        element['middlename'] = this.state.middlename
                        element['suffixname'] = this.state.suffixname
                        // element['address'] = this.state.address
                        element['contact'] = this.state.contact
                        element['civilStatus'] = this.state.civil_status
                        element['gender'] = this.state.gender
                        element['email'] = this.state.email
                        element['company_id'] = this.state.company
                        element['b_id'] = this.state.branch_id
                        element['sss'] = this.state.sss
                        element['tin'] = this.state.tin
                        element['position'] = this.state.userjob_position
                        element['philHealth'] = this.state.philHealth
                        element['pagIbig'] = this.state.pagIbig
                        element['jd'] = this.state.jobd
                        element['pres_house_street_subd'] = this.state.pres_house_street_subd
                        element['pres_barangay'] = this.state.pres_barangay
                        element['pres_city'] = this.state.pres_city
                        element['pres_province'] = this.state.pres_province
                        element['pres_country'] = this.state.pres_country
                        element['pres_zip'] = this.state.pres_zip
                        element['perm_house_street_subd'] = this.state.perm_house_street_subd
                        element['perm_barangay'] = this.state.perm_barangay
                        element['perm_city'] = this.state.perm_city
                        element['perm_province'] = this.state.perm_province
                        element['perm_country'] = this.state.perm_country
                        element['perm_zip'] = this.state.perm_zip
                    });
                    this.setState({ employeDetails: employeDetails })
                    this.getUser();
                    this.getSingleUser();
                    this.setState({
                        edit: false,

                        // firstname:"",
                        // lastname:"",
                        // middlename:"",
                        // address:"",
                        // contact:"",
                        // civil_status:"",
                        // gender:"",
                        // email:"",
                        // company:"",
                        // user_id:""

                    })
                }
            });
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    DeleteUser() {
        this.state.hideDelete.push(this.state.deleteUser)
        this.setState({ refresh: true })
        getData("users/deletefieldman", this.state.deleteUser)
            .then(result => {
                if (result == true) {
                    alert('Deleted');
                    this.getUser();
                } else {

                }

            })

    }
    checkPagePrivilege() {
        var route = "#/users";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                if (response == "show_404") {
                    // this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    getSchedule() {

        var data = {
            user_id: localStorage.getItem("u"),
            u_id: this.state.user_id
        }
        getData("users/getSched", data)
            .then(response => {

                this.setState({ getdaySched: response.data })
            })
    }
    getAttendance(user_id) {

        var month = ''
        getData("humanResource/getMonthAttendance", { month: new Date(), u_id: user_id })
            .then(response => {
                this.setState({ holiday: response.data })
                var arrayDate = [];
                for (let i = 0; i < response.data.length; i++) {
                    var date = new Date(response.data[i].holiday_date);
                    arrayDate.push(date);
                }
                this.setState({ dateData: arrayDate })
                this.setState({ holiday: response.data })

                this.setState({ DaysPresent: response.DaysPresent, pieGraph: [{ title: "Days Present", value: response.DaysPresent.length }, { title: "Days Late", value: response.DaysLate.length }, { title: "Days Absent", value: response.DaysAbsent }] })
                var arrayDate = []

                for (let i = 0; i < response.DaysPresent.length; i++) {
                    var date = new Date(response.DaysPresent[i].date);
                    arrayDate.push(date);

                }
                this.setState({ dateData: arrayDate, monthSelected: month })
            })
    }
    UpdateSchedule(e) {
        e.preventDefault()
        var data = [];
        if (this.state.typeSched == 'Default') {
            var week = [{ day: 'Mon', timein: this.state.mondayIN, timeout: this.state.mondayOUT, break: 1, time_start: '12:00' },
            { day: 'Tue', timein: this.state.tuesdayIN, timeout: this.state.tuesdayOUT, break: 1, time_start: '12:00' },
            { day: 'Wed', timein: this.state.wednesdayIN, timeout: this.state.wednesdayOUT, break: 1, time_start: '12:00' },
            { day: 'Thu', timein: this.state.thursdayIN, timeout: this.state.thursdayOUT, break: 1, time_start: '12:00' },
            { day: 'Fri', timein: this.state.fridayIN, timeout: this.state.fridayOUT, break: 1, time_start: '12:00' },
            { day: 'Sat', timein: this.state.saturdayIN, timeout: this.state.saturdayOUT, break: 1, time_start: '12:00' }]
            data = {
                date_implement: this.state.startDate_sched,
                shift: this.state.shift,
                user_id: localStorage.getItem("u"),
                u_id: this.state.user_id,
                company_id: this.state.company_ids,
                branch_id: this.state.branch_ids,
                typeSched: this.state.typeSched,
                schedule: week
            }
        } else if (this.state.typeSched == 'Others') {
            data = {
                shift: this.state.shift,
                date_implement: this.state.startDate_sched,
                user_id: localStorage.getItem("u"),
                u_id: this.state.user_id,
                company_id: this.state.company_ids,
                branch_id: this.state.branch_ids,
                typeSched: this.state.typeSched,
                schedule: this.state.weekDays
            }
        } else if (this.state.typeSched == 'Create') {
            data = {
                shift: this.state.shift,
                date_implement: this.state.startDate_sched,
                user_id: localStorage.getItem("u"),
                u_id: this.state.user_id,
                company_id: this.state.company_ids,
                branch_id: this.state.branch_ids,
                typeSched: this.state.typeSched,
                schedule: this.state.weekDays
            }
        } else if (this.state.typeSched == 'Flex-time') {
            data = {
                date_implement: this.state.startDate_sched,
                shift: this.state.shift,
                user_id: localStorage.getItem("u"),
                u_id: this.state.user_id,
                company_id: this.state.company_ids,
                branch_id: this.state.branch_ids,
                typeSched: this.state.typeSched,
                schedule: this.state.weekDays
            }
        }

        for (let index = 0; index < data.schedule.length; index++) {
            for (let index2 = 0; index2 < this.state.lunchBreak.length; index2++) {
                if (data.schedule[index].day == this.state.lunchBreak[index2].day) {
                    data.schedule[index].break = this.state.lunchBreak[index2].hours
                    data.schedule[index].time_start = this.state.lunchBreak[index2].time_start
                }
            }
        }

        getData("users/updateSched", data)
            .then(response => {
                this.getUser();
                this.getSingleUser();
                this.getSchedule();
                this.setState({ schedule_edit: false })
            })
    }
    UpdateSalary(e) {
        e.preventDefault()
        var data = []
        data = {
            user_id: localStorage.getItem("u"),
            u_id: this.state.user_id,
            company_id: this.state.company_ids,
            branch_id: this.state.branch_ids,
            typeRate: this.state.typeRate,
            salary: this.state.salary_rate,
            date_implement: moment(this.state.startDate_sched_salary).format('Y-MM-DD'),
        }

        getData("users/updateSalary", data)
            .then(response => {
                this.getUser();
                this.getSingleUser();
                this.getSchedule();
                this.setState({ salary_edit: false })


            })
    }
    UpdateAllowance(e) {
        e.preventDefault()
        var data = []
        data = {
            user_id: localStorage.getItem("u"),
            u_id: this.state.user_id,
            company_id: this.state.company_ids,
            branch_id: this.state.branch_ids,
            allowance: this.state.allowance,
            fixed: this.state.fixed
        }

        getData("users/updateAllowance", data)
            .then(response => {
                NotificationManager.success('Employee Allowance Updated Successfully', 'Success', 5000, true)
                this.setState({ Allowance: false })
                this.getUser();
                this.getSingleUser();
                this.getSchedule();
                this.setState({ salary_edit: false })
            })
    }
    Hierarchy() {
        this.setState({ Hierarchy: true })
    }
    AddLevel() {
        this.state.count++
        var title = ""
        var data = { title, approver: [], types: [] }
        this.state.name_hierarchy.push(data)
        // this.setState({name_hierarchy:{}})
        // alert("name_hierarchy"+this.state.count);
        this.state.ADDLevel.push(<div><br /><div className="solid" style={{ padding: 10 }}><div className="row"><div className="col-sm-12" ><label>Set Level Title*</label></div></div><br /><br /><div className="flex-container"><input onChange={this.textchange} type="text" name="name_hierarchy" className="form-control" /><i className="fa fa-2x fa-trash-alt m-l-10" onClick={() => {

        }} style={{ color: "green", marginTop: "5px" }}></i></div><div className="row m-l-13"><button className=" bttnADDApprover pull-right"><span className="fa fa-plus fa-sm"></span></button></div><br /><div className="row"><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">OT</label></div><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">OB</label></div><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">LV</label></div><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">UT</label></div></div></div></div>)
        this.setState({ refresh: true })

    }
    mapLevel() {
        var data = []
        for (let index = 0; index < this.state.ADDLevel.length; index++) {
            data.push(<div>
                <hr />
                <div >
                    <div className="form-group">
                        <label>Set Level Title</label>
                        <input onChange={this.textchange} onClick={() => { this.setState({ indexVal: index }) }} value={this.state.name_hierarchy[index].title} type="text" name="title" className="form-control" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }} >
                        {this.state.req_type.map((val, index3) => {
                            return <div key={index3}    >
                                <div >
                                    <input name={val.req_type_acName + index} type="checkbox" onChange={() => {
                                        var match = false
                                        for (let k = 0; k < this.state.name_hierarchy[index].types.length; k++) {
                                            if (this.state.name_hierarchy[index].types[k] == val.req_type_details) {
                                                this.state.name_hierarchy[index].types.splice(parseInt(k), 1)
                                                match = true
                                                this.setState({ refresh: true })
                                            }
                                        }
                                        if (match == false) {
                                            this.state.name_hierarchy[index].types.push(val.req_type_details)
                                            this.setState({ refresh: true })
                                        }
                                    }} />
                                    <label className="m-l-5">{val.req_type_acName}</label>
                                </div>
                            </div>
                        })}
                    </div>
                    {this.state.name_hierarchy.map((val, index1) => {
                        var data_details = []
                        for (let index3 = 0; index3 < val.approver.length; index3++) {
                            var name = { name: val.approver[index3].name }
                            data_details.push(name);
                        }
                        if (index == index1)
                            return <div key={index1}>
                                {data_details.map((val2, index2) => {
                                    return <div >
                                        <div key={index2} className="flex-container m-t-10 m-l-10">
                                            <input type="text" className="form-control" value={val2.name} readOnly />
                                            <button onClick={() => {
                                                this.state.name_hierarchy[index].approver.splice(parseInt(index2), 1)
                                                this.setState({ refresh: true })
                                            }} className="btttn bg-red-transparent-9 m-l-5 fa fa-trash-alt">&nbsp;Remove&nbsp;Approver</button>

                                        </div>
                                    </div>
                                })}
                            </div>
                    })
                    }
                    <button className=" btttn fas fa-user-check bg-green-transparent-9 m-t-5" data-dismiss="modal" data-target="#demo-lg-modal-ADDApprover" data-toggle="modal" onClick={() => {
                        this.setState({ indexVal: index, name_hierarchy1: [] })
                    }}>&nbsp;Add Approver</button>

                </div>

            </div>);
        }

        return data
    }
    unCheck() {
        var x = document.getElementsByClassName("checkbox")[0].checked;

        for (let i = 0; i < x.length; i++) {
            x[i].checked = false

        }
    }
    review() {

        this.state.type_details = []


        for (let i = 0; i < this.state.name_hierarchy.length; i++) {
            for (let k = 0; k < this.state.name_hierarchy[i].types.length; k++) {
                var match = false
                for (let l = 0; l < this.state.type_details.length; l++) {
                    if (this.state.name_hierarchy[i].types[k] == this.state.type_details[l].type) {
                        match = true
                    }

                }

                if (match == false) {
                    var type = []
                    type = { type: this.state.name_hierarchy[i].types[k] }
                    this.state.type_details.push(type);
                }

            }
        }

    }
    SaveHierarchy = (data, type) => {
        console.log(data)
        var details = []

        if (type == "Update") {
            for (let index = 0; index < data.length; index++) {
                var data = { id: data[index].id, structure: JSON.stringify(data[index].structure), user_id: this.state.u_id }
                details.push(data)
            }
        } else {

            var data = { user_id: this.state.u_id, id: null, admin_id: localStorage.getItem('u'), structure: data, name: this.state.hierarchy_name }
            details.push(data)
        }


        getData("humanResource/updateUserHierarchy", { hierarchy: details, method: type })
            .then(response => {

                // if(response == "Success"){
                this.setState({ Hierarchy: false, select_type: [], newHierarchy: false, SelectHierarchy: true, selectedHierarchy: [] })
                this.getUser();
                setTimeout(() => {

                    for (let index = 0; index < this.state.userData.length; index++) {


                        if (this.state.u_id == this.state.userData[index].user_id) {

                            this.setState({ user_request_hierarchy: JSON.parse(this.state.userData[index].user_request_hierarchy) })
                        }
                    }

                }, 500)
                alert('Success')
                $('#close_modal').click()
                // }

            })
    }
    onSubmitLeave(e) {
        e.preventDefault();

        var data = { leave_days: this.state.leavecount, user_id: this.state.u_id }

        getData("users/updateLeave", data)
            .then(response => {
                if (response.response == true) {
                    NotificationManager.success('Employemee Leave Added Successfully', 'Success', 5000, true)
                    this.setState({ leavecount: "" });
                    $("#demo-lg-modal-totalleave").hide();
                    this.employeeDetails(this.state.infodata)
                } else {
                    NotificationManager.error('Employemee Leave Added Failed', 'Error', 5000, true)

                }
            })

    }
    getLeaveCount() {
        var data = { user_id: this.state.u_id }
        getData("users/getLeaveConsumed", data)
            .then(response => {
                this.setState({ cntleave: response.response })

            })

    }
    fixedChange = () => {
        this.setState({ fixed: !this.state.fixed })
    }
    getPosition(company_id) {
        var data = { company_id: company_id }
        getData("position/getPositionbyID", data).then(res => {
            this.setState({ positions: res })

        })
    }
    getuserDetails() {
        getData("helpdesk/getUserDetails", localStorage.getItem('u')).then(res => {
            this.setState({ getUserCompanyID: res });
            // this.getPosition();
            let company_id = ""
            for (let index = 0; index < res.length; index++) {
                company_id = res[index].company_id
            }
            this.getPosition(company_id);
            this.getJD(company_id);

        })
    }
    onChangeSelectPosition(e) {
        var options = e.target.options;
        var value = [];
        var userjob_position = ""

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                userjob_position = options[i].value;
                value.push(options[i].value);
                this.setState({ userjob_position: options[i].value })
            }
        }
    }
    onChangeSelectJD(e) {
        var options = e.target.options;
        var value = [];
        var jobd = ""

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                jobd = options[i].value;
                value.push(options[i].value);
                this.setState({ jobd: options[i].value })
            }

        }
        // let array = []
        // array.push(value)
        // this.setState({ job: array })

    }
    getJD(company_id) {
        // let id = this.state.getUserDetails;
        var data = { company_id: company_id }

        getData("position/getJDbyID", data).then(res => {
            this.setState({ jd: res })
        })
    }
    lunchBreak = (day) => {
        let match = false;
        for (let index = 0; index < this.state.lunchBreak.length; index++) {
            if (this.state.lunchBreak[index].day == day) {
                this.state.lunchBreak.splice(parseInt(index), 1)
                match = true
            }

        }

        if (!match) {
            let details = {
                day: day, hours: 0, time_start: "12:00"
            }
            this.state.lunchBreak.push(details);
        }
        this.setState({ refresh: true })
    }
    hoursLunchBreak = (day, e) => {
        for (let index = 0; index < this.state.lunchBreak.length; index++) {
            if (this.state.lunchBreak[index].day == day) {
                this.state.lunchBreak[index].hours = e.target.value
            }

        }
    }
    getSchedLogs = (user_id) => {
        getData("users/getSchedLogs", user_id)
            .then(result => {
                let sched = []
                for (let i = 0; i < result.length; i++) {
                    let match = false;
                    for (let index = 0; index < sched.length; index++) {
                        if (sched[index].sched_id == result[i].sched_id) {
                            match = true;
                            sched[index].shed_days.push(result[i])
                        }
                    }
                    if (!match) {
                        let details = { shed_days: [result[i]], sched_id: result[i].sched_id, sched_implemented: result[i].date_added }
                        sched.push(details)
                    }
                }
                this.setState({ sched_days: sched })


            });
    }
    onChangeSelectEmployeeStatus(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({ employee_status: options[i].value })
            }
        }
    }
    onChangeSelectEmploymentStatus(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                var data = { user_employment_status: options[i].value, user_id: this.state.user_id }
                getData("users/StatusUpdateEmployee", data).then(res => {
                    if (res.res === "Success") {
                        this.getUser();
                        NotificationManager.success('Employement Status Updated Successfully', 'Success', 5000, true)
                        $("#close").click();
                    } else {
                        NotificationManager.error('Employement Status Updated Failed', 'Failed', 5000, true)

                    }
                })
            }
        }
    }
    saveStatus(e) {
        e.preventDefault();
        var data = { user_employment_status: this.state.employment_status, user_id: this.state.user_id }
        getData("users/StatusUpdateEmployee", data).then(res => {
            if (res.res === "Success") {
                this.getUser();
                NotificationManager.success('Employement Status Updated Successfully', 'Success', 5000, true)
                $("#close").click();
            } else {
                NotificationManager.error('Employement Status Updated Failed', 'Failed', 5000, true)

            }
        })
    }
    uploadSingle(e) {
        console.log( e.target.files )
        this.setState({ h_single: e.target.files })
    }
    submitSingle(e) {
        e.preventDefault();
        const data = new FormData()
        for (var x = 0; x < this.state.h_single.length; x++) {
            data.append('file[]', this.state.h_single[x])
        }
        data.set('user_id', this.state.user_id);
        console.log(data)

        axios({
            method: 'post',
            url: 'http://api.pacificweb.com.ph/helpdesk/uploadSingleFile/',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', }
        }).then(result => {
            if (result.statusText === 'OK') {
                this.getUser();
                this.getSingleUser();
                NotificationManager.success('Employee Picture Uploaded Successfully', 'Success', 5000, true)

            } else {
                NotificationManager.error('Employee Picture Uploaded Failed', 'Failed', 5000, true)
            }
        })
    }
    same() {
        this.setState({
            same_address: !this.state.same_address,
            perm_house_street_subd: this.state.pres_house_street_subd,
            perm_barangay: this.state.pres_house_street_subd,
            perm_city: this.state.pres_house_street_subd,
            perm_province: this.state.pres_province,
            perm_country: this.state.pres_country,
            perm_zip: this.state.pres_zip
        })

    }
    filterCompany(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
                if (options[i].value === "") {
                    this.setState({ company: '', branch: '' })
                    document.getElementsByName('selectedBranch')[0].value = ''
                } else {
                    this.setState({ sel_company: options[i].innerHTML, branch: '' })
                }
            }
        }
        getData("users/getBranch", company)
            .then(result => {
                this.setState({ branches: result })
            });
    }
    filterBranch = e => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ sel_branch: options[i].value })
            }
        }

    }
    filterStatus(e) {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({ sel_status: options[i].value })
            }

        }

    }
    filterPosition(e) {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({ sel_position: options[i].value })
            }

        }

    }
    bdaypicker(e) {
        setTimeout(() => {
            this.setState({ user_bday: e });
        }, 100)
    }
    dhiredpicker(e) {
        setTimeout(() => {
            this.setState({ user_date_hired: e });
        }, 100)
    }
    handleChangeL(time, day) {
        for (let index = 0; index < this.state.lunchBreak.length; index++) {
            if (this.state.lunchBreak[index].day == day) {
                this.state.lunchBreak[index].time_start = time
            }

        }

    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        let EmployeeSearch = this.state.userData.filter(
            (files) => {
                return files.completename.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.branch_name.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (
            <div id="content " className="content">
                <NotificationContainer />
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-blue-transparent-9 m-5 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;New Employee
                        </button>
                                <button className="btttn pull-left bg-green-transparent-9 m-5 fas fa-filter" data-target="#demo-lg-filter" data-toggle="modal">&nbsp;Filter Table
                        </button>
                                <button className="btttn fas fa-sync-alt border border-secondary m-5 border-solid text-black-lighter bg-none" onClick={() => {
                                    this.setState({
                                        sel_company: document.getElementsByName('selectedCompany')[0].name = 'All',
                                        sel_branch: "",
                                        sel_status: "",
                                        sel_position: "",

                                    })
                                }}>&nbsp;Reset Filter
                        </button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search2" placeholder="Search Name" autoComplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Scrollbars style={{ height: 450 }}>
                                    <table className="table table-td-valign-middle table-bordered" >
                                        <thead className="tableFixHead ">
                                            <tr className="text-center">
                                                <th >ID</th>
                                                <th >Employee Name</th>
                                                {/* <th >Company</th>
                                                <th >Branch</th> */}
                                                <th >Branch&nbsp;(Company)</th>
                                                <th >Position</th>
                                                <th>Employment Status</th>
                                                <th >Contact</th>
                                                <th style={{ zIndex: 2 }}>Action</th>
                                            </tr>
                                        </thead>
                                        {EmployeeSearch.filter(i => i.company_name.includes(this.state.sel_company) && i.branch_name.includes(this.state.sel_branch) && i.user_status.includes(this.state.sel_status) && i.user_jobposition.includes(this.state.sel_position)).map((val, index1) => {

                                            var val_index = index1;
                                            var match = false;
                                            var match1 = false;
                                            var dataMatch = []
                                            for (let i = 0; i < this.state.check.length; i++) {

                                                if (this.state.check[i].user_id == val.user_id) {

                                                    match = true;
                                                }
                                            }
                                            var hide = false;
                                            for (let i = 0; i < this.state.hideDelete.length; i++) {

                                                if (this.state.hideDelete[i].user_id == val.user_id) {
                                                    hide = true;
                                                }
                                            }
                                            if (hide === false)
                                                return <tbody className="bg-white" key={index1}>
                                                    <tr className="tablerow">
                                                        <td>{val.user_id}</td>
                                                        <td>{val.completename.toUpperCase()}</td>
                                                        {/* <td>{val.company_name == null ? "" : val.company_name.toUpperCase()}</td>
                                                        <td>{val.branch_name == null ? "" : val.branch_name.toUpperCase()}</td> */}
                                                        <td>{val.branch_company == null ? "" : val.branch_company.toUpperCase()}</td>
                                                        {/* {val.branch_name == null &&
                                                            <td>{val.company_name}</td>
                                                        }
                                                        {val.branch_name != null &&
                                                            <td>{val.branch_name.toUpperCase()}</td>
                                                        } */}
                                                        {val.userjob_position == null &&
                                                            <td>{val.user_jobposition}</td>
                                                        }
                                                        {val.userjob_position != null &&
                                                            <td>{val.user_jobposition}</td>


                                                        }
                                                        {/* <td>{val.employee_status}</td> */}
                                                        <td>
                                                            <select style={{ borderStyle: 'none', backgroundColor: "transparent" }} onChange={this.onChangeSelectEmploymentStatus} onClick={() => { this.setState({ user_id: val.user_id }) }}>
                                                                <option className="text-center text-black" selected>{val.user_employment_status}</option>
                                                                <option className="text-center" value='Hired'>Hired</option>
                                                                <option className="text-center" value='Separeted'>Separeted</option>
                                                                <option className="text-center" value='Resigned'>Resigned</option>
                                                                <option className="text-center" value='Terminated'>Terminated</option>
                                                            </select>
                                                        </td>
                                                        <td>{val.user_mobile}</td>
                                                        <td>
                                                            {/* <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <a href="javascript:;" className="tabledetails text-green-darker table-button"
                                                                    onClick={() => {
                                                                        this.employeeDetails(val);
                                                                        this.setState({ employeeIndex: val_index });
                                                                        this.setState({ lunchBreak: [] });
                                                                    }}
                                                                    data-target="#demo-lg-modal5"
                                                                    data-toggle="modal">INFORMATION</a>
                                                                <i className="tabledetails table-button" style={{ color: "black" }}>&nbsp;&#124;&nbsp;</i>
                                                                <a href="javascript:;" className="tabledetails text-blue-darker table-button" data-target="#demo-lg-modal-status" data-toggle="modal"
                                                                    onClick={() => { this.setState({ employment_status: val.user_status, completeName: val.completename, user_id: val.user_id }) }}>STATUS</a>
                                                                <i className="tabledetails table-button" style={{ color: "black" }}>&nbsp;&#124;&nbsp;</i>
                                                                <a href="javascript:;" className="tabledetails text-red-darker table-button" data-target="#demo-lg-modal-DELETE" data-toggle="modal"
                                                                    onClick={() => this.setState({ deleteUser: val })}>DELETE</a>
                                                            </div> */}




                                                            <div className="btn-group tabledetails">
                                                                <button
                                                                    className="btttn  bg-blue-transparent-9 fa fa-info-circle"
                                                                    onClick={() => { this.employeeDetails(val); this.setState({ employeeIndex: val_index }); this.setState({ lunchBreak: [] }) }} data-target="#demo-lg-modal5"
                                                                    data-toggle="modal">&nbsp;Information&nbsp;
                                                        </button>
                                                                {/* <div className="m-l-3 or m-r-3"></div>

                                                                <button
                                                                    data-target="#demo-lg-modal-status" data-toggle="modal"
                                                                    onClick={() => { this.setState({ status: val.user_status, completeName: val.completename, user_id: val.user_id }) }}

                                                                    className="btttn bg-green-transparent-9 far fa-id-card"
                                                                >&nbsp;Status
                                                        </button> */}
                                                                <div className="m-l-3 or m-r-3"></div>
                                                                <button
                                                                    data-target="#demo-lg-modal-DELETE" data-toggle="modal"
                                                                    onClick={() => this.setState({ deleteUser: val })}
                                                                    className="btttn bg-red-transparent-9 fas fa-trash-alt"
                                                                >&nbsp;Delete&nbsp;
                                                        </button>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                        })}
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                        <div id="demo-lg-modal5" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4>Employee Details</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                        <button hidden id='close_modal' data-dismiss="modal" className="pull-right btttn bg-red">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                        {/* <button type="button" className="close pull-right" data-dismiss="modal"><i className="fas fa-lg fa-window-close text-success"></i></button> */}
                                    </div>
                                    <div className="modal-body" style={{ fontSize: 12 }}>

                                        {this.state.employeDetails.map((val, index) => {
                                            // let middle = val.lastname;
                                            // middle.split();
                                            return <div>
                                                <div className="row">
                                                    <div className="col-md-4 ">
                                                        <div className="row m-t-15" >
                                                            <div className="col-md-4" style={{ display: "flex", flexDirection: "column" }}>
                                                                <img className="m-auto " style={{ borderRadius: "12px", width: "80px", height: "80px" }}
                                                                    src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + this.state.picture)} />
                                                                <a className="text-center m-t-5" style={{ textDecoration: "underline", cursor: "pointer" }} data-target="#demo-lg-picture"
                                                                    data-toggle="modal" data-dismiss="modal" >Edit Picture</a>
                                                            </div>
                                                            <div className="col-md-8 m-t-10">
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <h4>{val.completename.toUpperCase()}</h4>
                                                                    {val.user_status === "Active" &&
                                                                        <span>
                                                                            Employment Status:&nbsp;<label style={{ color: "green" }}>{val.user_status}</label>
                                                                        </span>
                                                                    }
                                                                    {val.user_status === "Terminated" &&
                                                                        <span>
                                                                            Employment Status:&nbsp;<label style={{ color: "red" }}>{val.user_status}</label>
                                                                        </span>
                                                                    }
                                                                    {val.user_status === "Inactive" &&
                                                                        <span>
                                                                            Employment Status:&nbsp;<label style={{ color: "yellow" }}>{val.user_status}</label>
                                                                        </span>
                                                                    }
                                                                    {val.user_status === "AWOL" &&
                                                                        <span>
                                                                            Employment Status:&nbsp;<label style={{ color: "orange" }}>{val.user_status}</label>
                                                                        </span>
                                                                    }
                                                                    <span>
                                                                        Account Username:&nbsp;<label>{val.account_username}</label>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="text-center m-t-10" >
                                                            <div >
                                                                <span onClick={() => {
                                                                    this.setState({
                                                                        click: "personalinfo",
                                                                        modalnav1: "#32a932",
                                                                        modalnav2: "transparent",
                                                                        modalnav3: "transparent",
                                                                        modalnav4: "transparent",
                                                                        modalnav5: "transparent",
                                                                        modalnav6: "transparent",
                                                                        modalcol1: 'white',
                                                                        modalcol2: 'black',
                                                                        modalcol3: 'black',
                                                                        modalcol4: 'black',
                                                                        modalcol5: 'black',
                                                                        modalcol6: 'black'
                                                                    })
                                                                }}>
                                                                    <h3 style={{ backgroundColor: this.state.modalnav1, color: this.state.modalcol1, padding: 10, borderRadius: 12, transition: '0.99s' }}>Personal Information</h3>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span onClick={() => {

                                                                    this.setState({
                                                                        click: "salary",
                                                                        modalnav1: "transparent",
                                                                        modalnav2: "#32a932",
                                                                        modalnav3: "transparent",
                                                                        modalnav4: "transparent",
                                                                        modalnav5: "transparent",
                                                                        modalnav6: "transparent",
                                                                        modalcol1: 'black',
                                                                        modalcol2: 'white',
                                                                        modalcol3: 'black',
                                                                        modalcol4: 'black',
                                                                        modalcol5: 'black',
                                                                        modalcol6: 'black',

                                                                    })
                                                                }}>
                                                                    <h3 style={{ backgroundColor: this.state.modalnav2, color: this.state.modalcol2, padding: 10, borderRadius: 12, transition: '0.99s' }}>Salary Rate</h3>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span onClick={() => {
                                                                    this.setState({
                                                                        click: "schedule",
                                                                        modalnav1: "transparent",
                                                                        modalnav2: "transparent",
                                                                        modalnav3: "#32a932",
                                                                        modalnav4: "transparent",
                                                                        modalnav5: "transparent",
                                                                        modalnav6: "transparent",
                                                                        modalcol1: 'black',
                                                                        modalcol2: 'black',
                                                                        modalcol3: 'white',
                                                                        modalcol4: 'black',
                                                                        modalcol5: 'black',
                                                                        modalcol6: 'black',
                                                                        lunchBreak: []
                                                                    })
                                                                }}>
                                                                    <h3 style={{ backgroundColor: this.state.modalnav3, color: this.state.modalcol3, padding: 10, borderRadius: 12, transition: '0.99s' }}>Schedule</h3>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span onClick={() => {
                                                                    this.setState({
                                                                        click: "approver",
                                                                        modalnav1: "transparent",
                                                                        modalnav2: "transparent",
                                                                        modalnav3: "transparent",
                                                                        modalnav4: "#32a932",
                                                                        modalnav5: "transparent",
                                                                        modalnav6: "transparent",
                                                                        modalcol1: 'black',
                                                                        modalcol2: 'black',
                                                                        modalcol3: 'black',
                                                                        modalcol4: 'white',
                                                                        modalcol5: 'black',
                                                                        modalcol6: 'black',
                                                                    })
                                                                }}>
                                                                    <h3 style={{ backgroundColor: this.state.modalnav4, color: this.state.modalcol4, padding: 10, borderRadius: 12, transition: '0.99s' }}>Approver Hierarchy</h3>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span onClick={() => {
                                                                    this.setState({
                                                                        click: "attendance",
                                                                        modalnav1: "transparent",
                                                                        modalnav2: "transparent",
                                                                        modalnav3: "transparent",
                                                                        modalnav4: "transparent",
                                                                        modalnav5: "#32a932",
                                                                        modalnav6: "transparent",
                                                                        modalcol1: 'black',
                                                                        modalcol2: 'black',
                                                                        modalcol3: 'black',
                                                                        modalcol4: 'black',
                                                                        modalcol5: 'white',
                                                                        modalcol6: 'black',
                                                                    })
                                                                }}>
                                                                    <h3 style={{ backgroundColor: this.state.modalnav5, color: this.state.modalcol5, padding: 10, borderRadius: 12, transition: '0.99s' }}>Attendance History</h3>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span onClick={() => {
                                                                    this.setState({
                                                                        click: "leaveStatus",
                                                                        modalnav1: "white",
                                                                        modalnav2: "white",
                                                                        modalnav3: "white",
                                                                        modalnav4: "white",
                                                                        modalnav5: "white",
                                                                        modalnav6: "#32a932",
                                                                        modalcol1: 'black',
                                                                        modalcol2: 'black',
                                                                        modalcol3: 'black',
                                                                        modalcol4: 'black',
                                                                        modalcol5: 'black',
                                                                        modalcol6: 'white',
                                                                        leavecount: val.user_leave
                                                                    })

                                                                }}>
                                                                    <h3 style={{ backgroundColor: this.state.modalnav6, color: this.state.modalcol6, padding: 10, borderRadius: 12, transition: '0.99s' }}>Leave Status</h3>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-8">

                                                        <Scrollbars style={{ height: "500px" }}>
                                                            {this.state.click === "personalinfo" &&
                                                                <div className="panel solid">
                                                                    {this.state.edit == false &&
                                                                        <div className="panel-body w3-animate-left">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <h5 style={{ color: "#bdc3c7" }}><b>Personal Information</b></h5>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <button className="btttn pull-right bg-green-transparent-9 fas fa-edit" onClick={() => this.employeeUpdate(val)}>
                                                                                        &nbsp;Edit
                                                                            </button>
                                                                                    {/*  <a tooltip="Edit" className="pull-right" onClick={() => this.employeeUpdate(val)}><i className="fa  fa-edit" style={{ color: "green", marginRight: "5%" }}></i></a> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Full Name*</label>
                                                                                    <input onChange={this.textchange} type="text" name="name" value={`${val.user_lname.toUpperCase()} ${","}  ${val.user_fname.toUpperCase()} ${val.user_mname.charAt(0).toUpperCase()} ${val.user_sname.toUpperCase()}`} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Gender*</label>
                                                                                    <input onChange={this.textchange} type="text" name="gender" value={val.user_gender} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Civil Status*</label>
                                                                                    <input onChange={this.textchange} type="text" name="contact" value={val.user_civilstatus} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Present Address*</label>
                                                                                    <input onChange={this.textchange} type="text" name="address" id="address" value={val.complete_present_address} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent Address*</label>
                                                                                    <input onChange={this.textchange} type="text" name="address" id="address" value={val.complete_permanent_address} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Contact*</label>
                                                                                    <input onChange={this.textchange} type="number" name="contact" value={val.user_mobile} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Email*</label>
                                                                                    <input onChange={this.textchange} type="text" name="email" value={val.user_email} className="form-control" readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row m-t-10">
                                                                                <div className="col-md-6">
                                                                                    <h5 style={{ color: "#bdc3c7" }}><b>Company Information</b></h5>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Company*</label>
                                                                                    <input onChange={this.textchange} type="text" name="compant" value={val.company_name} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Branch*</label>
                                                                                    <input onChange={this.textchange} type="text" name="branch" value={val.branch_name} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Department*</label>
                                                                                    <input onChange={this.textchange} type="text" name="department" value={val.dept_name} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Position*</label>
                                                                                    <input onChange={this.textchange} type="text" name="position" value={val.user_jobposition} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Job Description*</label>
                                                                                    <input onChange={this.textchange} type="text" name="jobd" value={val.job_description} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>SSS Number*</label>
                                                                                    <input onChange={this.textchange} type="number" name="sss" value={val.sss} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Tin Number*</label>
                                                                                    <input onChange={this.textchange} type="number" name="tin" value={val.tin} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>PhilHealth Number*</label>
                                                                                    <input onChange={this.textchange} type="number" name="philHealth" value={val.philHealth} className="form-control" readOnly />
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ fontSize: "12px", color: "#353b48" }}>Pag-ibig Number*</label>
                                                                                    <input onChange={this.textchange} type="number" name="pagIbig" value={val.pagIbig} className="form-control" readOnly />
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    }
                                                                    {this.state.edit == true &&
                                                                        <form onSubmit={this.onSubmitEdit}>
                                                                            <div className="panel-body w3-animate-right">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <h5 style={{ color: "#bdc3c7" }}><b>Personal Information</b></h5>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" onClick={() => this.setState({ edit: false })}>&nbsp;Cancel
                                                                                </button>
                                                                                        {/* <a tooltip="Back" className="pull-right" onClick={() => this.setState({ edit: false })}><i className="fa  fa-arrow-circle-left" style={{ color: "red", marginRight: "5%" }}></i></a> */}
                                                                                    </div>

                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>First name*</label>
                                                                                        <input onChange={this.textchange} type="text" name="firstname" value={this.state.firstname} className="form-control" required />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Middle name*</label>
                                                                                        <input onChange={this.textchange} type="text" name="middlename" id="exampleState" value={this.state.middlename} className="form-control" required />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Last name*</label>
                                                                                        <input onChange={this.textchange} type="text" name="lastname" value={this.state.lastname} className="form-control" required />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Suffix name*</label>
                                                                                        <input onChange={this.textchange} type="text" name="suffixname" id="exampleState" value={this.state.suffixname} className="form-control" />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Gender*</label>
                                                                                        <select className="form-control" onChange={this.onChangeSelectGender} required>
                                                                                            <option>{val.user_gender}</option>
                                                                                            <option disabled>--select gender--</option>
                                                                                            <option value="female">Female</option>
                                                                                            <option value="male">Male</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Civil Status*</label>
                                                                                        <select className="form-control" onChange={this.onChangeSelect} required>
                                                                                            <option >{val.user_civilstatus}</option>
                                                                                            <option disabled>--select gender--</option>
                                                                                            <option value="single">Single</option>
                                                                                            <option value="maried">Married</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {/* <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Address*</label>
                                                                                        <input onChange={this.textchange} type="text" name="address" id="address" value={this.state.address} className="form-control" required />
                                                                                    </div> */}
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Present House No./Street/Subdivision*</label>
                                                                                        <input onChange={this.textchange} type="text" name="pres_house_street_subd" className="form-control" value={this.state.pres_house_street_subd} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Present Barangay*</label>
                                                                                        <input onChange={this.textchange} type="text" name="pres_barangay" className="form-control" value={this.state.pres_barangay} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Present City*</label>
                                                                                        <input onChange={this.textchange} type="text" name="pres_city" className="form-control" value={this.state.pres_city} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Present Province*</label>
                                                                                        <input onChange={this.textchange} type="text" name="pres_province" className="form-control" value={this.state.pres_province} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Present Country*</label>
                                                                                        <input onChange={this.textchange} type="text" name="pres_country" className="form-control" value={this.state.pres_country} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Present Zip Code*</label>
                                                                                        <input onChange={this.textchange} type="text" name="pres_zip" className="form-control" value={this.state.pres_zip} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent House No./Street/Subdivision*</label>
                                                                                        <input onChange={this.textchange} type="text" name="perm_house_street_subd" className="form-control" value={this.state.perm_house_street_subd} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent Barangay*</label>
                                                                                        <input onChange={this.textchange} type="text" name="perm_barangay" className="form-control" value={this.state.perm_barangay} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent City*</label>
                                                                                        <input onChange={this.textchange} type="text" name="perm_city" className="form-control" value={this.state.perm_city} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent Province*</label>
                                                                                        <input onChange={this.textchange} type="text" name="perm_province" className="form-control" value={this.state.perm_province} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent Country*</label>
                                                                                        <input onChange={this.textchange} type="text" name="perm_country" className="form-control" value={this.state.perm_country} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Permanent Zip Code*</label>
                                                                                        <input onChange={this.textchange} type="text" name="perm_zip" className="form-control" value={this.state.perm_zip} />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Contact*</label>
                                                                                        <input onChange={this.textchange} type="number" name="contact" value={this.state.contact} className="form-control" required />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Email*</label>
                                                                                        <input onChange={this.textchange} type="text" name="email" value={this.state.email} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row m-t-10">
                                                                                    <div className="col-md-6">
                                                                                        <h5 style={{ color: "#bdc3c7" }}><b>Company Information</b></h5>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Company*</label>
                                                                                        <select className="form-control" onChange={this.onChangeSelectCompany} required>
                                                                                            <option value={val.company_id}>{val.company_name}</option>
                                                                                            <option disabled>--select company--</option>
                                                                                            {this.state.companies.map((val1, index) =>
                                                                                                <option value={val1.company_id}>{val1.company_name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Branch*</label>
                                                                                        <select className="form-control" onChange={this.onChangeSelectBranch}>
                                                                                            <option value={val.branch_id}>{val.branch_name}</option>
                                                                                            <option disabled>--select branch--</option>
                                                                                            {this.state.branches.map((val1, index) =>
                                                                                                <option value={val1.bid}>{val1.bname}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Department*</label>
                                                                                        {/* <input onChange={this.textchange} type="text" name="department"   value={val.department} className="form-control" /> */}
                                                                                        <select className="form-control" onChange={this.onChangeSelectDepartment}>
                                                                                            <option value={val.dept_id}>{val.dept_name}</option>
                                                                                            <option disabled>--select department--</option>
                                                                                            {this.state.departments.map((val1, index) =>
                                                                                                <option value={val1.dept_id}>{val1.dept_name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Position*</label>
                                                                                        <select className="form-control" onChange={this.onChangeSelectPosition}>
                                                                                            <option value={val.user_jobposition}>{val.user_jobposition}</option>
                                                                                            <option disabled>Select Position</option>
                                                                                            {this.state.positions.map((val1, index) =>
                                                                                                <option value={val1.user_jobposition}>{val1.userjob_position}</option>
                                                                                            )}
                                                                                        </select>
                                                                                        {/* <input onChange={this.textchange} type="text" name="position"   value={val.position} className="form-control" /> */}
                                                                                    </div>
                                                                                    <div className="col-sm-12 m-b-10">
                                                                                        <label style={{ color: "#130f40" }}>Job Description*</label>
                                                                                        {/* <input onChange={this.textchange} type="text" name="position" value={val.position}   placeholder="Position" className="form-control" required /> */}
                                                                                        <select className="form-control" name="job" onChange={this.onChangeSelectJD} required>
                                                                                            <option value={val.job_description}>{val.job_description}</option>
                                                                                            {this.state.jd.map((val1, index) => {
                                                                                                return <option value={val1.job_description}>{val1.job_description}</option>
                                                                                            }
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>SSS Number*</label>
                                                                                        <input onChange={this.textchange} type="number" name="sss" value={this.state.sss} className="form-control" />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Tin Number*</label>
                                                                                        <input onChange={this.textchange} type="number" name="tin" value={this.state.tin} className="form-control" />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>PhilHealth Number*</label>
                                                                                        <input onChange={this.textchange} type="number" name="philHealth" value={this.state.philHealth} className="form-control" />
                                                                                    </div>
                                                                                    <div className="col-sm-12">
                                                                                        <label style={{ fontSize: "12px", color: "#353b48" }}>Pag-ibig Number*</label>
                                                                                        <input onChange={this.textchange} type="number" name="pagIbig" value={this.state.pagIbig} className="form-control" />
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <button className="btttn pull-right bg-green-transparent-9 fa fa-save" type="submit">&nbsp;Save
                                                                        </button>
                                                                                {/* <button className="btn  btn-xs pull-right" 
                                                                style={{ color: "white", backgroundColor: "#34495e", width: 55 }} 
                                                                type="submit" >Update</button> */}
                                                                            </div>
                                                                        </form>
                                                                    }
                                                                </div>
                                                            }


                                                            {this.state.click === "salary" &&
                                                                <>
                                                                    <div className="panel solid">
                                                                        {this.state.salary_edit == false &&
                                                                            <div className="panel-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <h5 style={{ color: "#bdc3c7" }}><b>Salary Rate</b></h5>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <button className="btttn pull-right bg-green-transparent-9 fas fa-edit" onClick={() => { this.employeeUpdateSalary(val) }}>&nbsp;Edit
                                                                            </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm-6  w3-animate-left">
                                                                                        <p style={{ fontSize: "12px", color: "#353b48" }}>Salary*</p>
                                                                                        <input onChange={this.textchange} type="text" name="email" value={val.salary_rate} className="form-control" readOnly />
                                                                                    </div>
                                                                                    <div className="col-sm-6  w3-animate-left">
                                                                                        <p style={{ fontSize: "12px", color: "#353b48" }}>Type of Rate*</p>
                                                                                        <input onChange={this.textchange} type="text" name="email" value={val.type_rate} className="form-control" readOnly />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {this.state.salary_edit == true &&
                                                                            <div className="panel-body ">
                                                                                <form onSubmit={this.UpdateSalary}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <h5 style={{ color: "#bdc3c7" }}><b>Salary Rate</b></h5>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" onClick={() => this.setState({ salary_edit: false })}>
                                                                                                &nbsp;Cancel
                                                                                </button>
                                                                                            {/* <a tooltip="Back" className="pull-right" onClick={() => this.setState({ salary_edit: false })}><i className="fa fa-arrow-circle-left" style={{ color: "red", marginRight: "5%" }}></i></a> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr />
                                                                                    <label>Choose Type of Rate*</label><br />
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <input type="radio" name="typeRate" value="Monthly" onChange={this.textchange} /><span style={{ color: '#2d3436', marginLeft: 10 }}>Monthly</span>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <input type="radio" name="typeRate" value="Daily" onChange={this.textchange} /><span style={{ color: '#2d3436', marginLeft: 10 }} >Daily</span>
                                                                                        </div>
                                                                                        <div className="col-md-7"></div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6  w3-animate-right">
                                                                                            <p style={{ fontSize: "12px", color: "#353b48" }}>Salary*</p>
                                                                                            <input onChange={this.textchange} type="number" name="salary_rate" value={this.state.salary_rate} className="form-control" />
                                                                                        </div>
                                                                                        <div className="col-sm-6  w3-animate-right">

                                                                                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                                                                                <p style={{ fontSize: "12px", color: "#353b48" }}>Start Date  Implemented*</p>
                                                                                                <DatePicker
                                                                                                    selected={this.state.startDate_sched_salary}
                                                                                                    selectsEnd
                                                                                                    startDate={this.state.startDate_sched_salary}
                                                                                                    onChange={(e) => this.setState({ startDate_sched_salary: e })}
                                                                                                    className="form-control"
                                                                                                    required
                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    {/* <div className="row">
                                                                                        <div className="col-sm-6  w3-animate-right">
                                                                                            <p style={{ fontSize: "12px", color: "#353b48" }}>Start Date  Implemented*</p>
                                                                                            <DatePicker
                                                                                                selected={this.state.startDate_sched}
                                                                                                selectsEnd
                                                                                                startDate={this.state.startDate_sched}
                                                                                                onChange={(e) => this.setState({ startDate_sched: e })}
                                                                                                className="form-control"
                                                                                                required
                                                                                            />
                                                                                        </div>
                                                                                    </div> */}
                                                                                    <br />
                                                                                    <button className="btttn pull-right bg-green-transparent-9 fa fa-save" type="submit">&nbsp;Save
                                                                        </button>
                                                                                </form>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="panel solid">
                                                                        {this.state.Allowance == false &&
                                                                            <div className="panel-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <h5 style={{ color: "#bdc3c7" }}><b>Allowance</b></h5>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <button className="btttn text-center pull-right bg-green" onClick={() => { this.employeeUpdateAllowance(val) }}>
                                                                                            <span className="fa fa-search-dollar fa-sm"></span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm-6  w3-animate-left">
                                                                                        <p style={{ fontSize: "12px", color: "#353b48" }}>Amount per month*</p>
                                                                                        <input onChange={this.textchange} type="text" name="allowance" value={val.allowance} className="form-control" readOnly />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        }


                                                                        {this.state.Allowance == true &&
                                                                            <>
                                                                                <div className="panel-body ">
                                                                                    <form onSubmit={this.UpdateAllowance}>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <h5 style={{ color: "#bdc3c7" }}><b>Allowance</b></h5>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <button className="bttnbred text-center pull-right bg-red" onClick={() => this.setState({ Allowance: false })}>
                                                                                                    <span className="fa fa-arrow-circle-left fa-sm"></span>
                                                                                                </button>
                                                                                                {/* <a tooltip="Back" className="pull-right" onClick={() => this.setState({ salary_edit: false })}><i className="fa fa-arrow-circle-left" style={{ color: "red", marginRight: "5%" }}></i></a> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6  w3-animate-right">
                                                                                                <p style={{ fontSize: "12px", color: "#353b48" }}>Amount per month*</p>
                                                                                                <input onChange={this.textchange} type="number" name="allowance" value={this.state.allowance} className="form-control" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6  w3-animate-right">
                                                                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                                                                    <p style={{ fontSize: "12px", color: "#353b48", marginRight: 10 }}>Fixed</p>
                                                                                                    <input onChange={() => this.fixedChange()} type="checkbox" name="allowance" className="form-control" />
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                        <button className="bttnupdate text-center pull-right bg-green" type="submit">
                                                                                            <span className="fa fa-save fa-sm"></span>
                                                                                        </button>
                                                                                    </form>
                                                                                </div>

                                                                            </>

                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                            {this.state.click === "schedule" &&
                                                                <>
                                                                    <div className="panel solid">
                                                                        {this.state.schedule_edit == false &&
                                                                            <>
                                                                                <div className="panel-body ">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <h5 style={{ color: "#bdc3c7" }}><b>Schedule:</b></h5>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <button className="btttn text-center pull-right bg-green" onClick={() => {

                                                                                                this.setState({
                                                                                                    lunchBreak: [],
                                                                                                    weekDays: [],
                                                                                                    hidemonday: true,
                                                                                                    hidetuesday: true,
                                                                                                    hidewednesday: true,
                                                                                                    hidethursday: true,
                                                                                                    hidefriday: true,
                                                                                                    hidesaturday: true,
                                                                                                    hidesunday: true,
                                                                                                    editmonday: false,
                                                                                                    editmonday2: true,
                                                                                                    edittuesday: false,
                                                                                                    edittuesday2: true,
                                                                                                    editwednesday: false,
                                                                                                    editwednesday2: true,
                                                                                                    editthursday: false,
                                                                                                    editthursday2: true,
                                                                                                    editfriday: false,
                                                                                                    editfriday2: true,
                                                                                                    editsaturday: false,
                                                                                                    editsaturday2: true,
                                                                                                    editsunday: false,
                                                                                                    editsunday2: true,
                                                                                                    create: true,
                                                                                                    default: true,
                                                                                                    others: true,
                                                                                                    flextime: true,
                                                                                                    backgroundColor1: '#348fe2',
                                                                                                    backgroundColor2: '#348fe2',
                                                                                                    backgroundColor3: '#348fe2',
                                                                                                    backgroundColor4: '#348fe2'
                                                                                                });
                                                                                                this.employeeUpdateSchedule(val)
                                                                                            }}>
                                                                                                <span className="fa fa-calendar-check fa-sm"></span>
                                                                                            </button>
                                                                                            {/* <a tooltip="Edit" className="pull-right" onClick={() =>{
                                                                         this.setState({
                                                                             weekDays:[],
                                                                             hidemonday: true,
                                                                             hidetuesday: true,
                                                                             hidewednesday: true,
                                                                             hidethursday: true,
                                                                             hidefriday: true,
                                                                             hidesaturday: true,
                                                                             hidesunday: true,
                                                                             editmonday: false,
                                                                             editmonday2: true,
                                                                             edittuesday: false,
                                                                             edittuesday2: true,
                                                                             editwednesday: false,
                                                                             editwednesday2: true,
                                                                             editthursday: false,
                                                                             editthursday2: true,
                                                                             editfriday: false,
                                                                             editfriday2: true,
                                                                             editsaturday: false,
                                                                             editsaturday2: true,
                                                                             editsunday: false,
                                                                             editsunday2: true,
                                                                             create: true,
                                                                             default: true,
                                                                             others: true,
                                                                             flextime: true,
                                                                             backgroundColor1: '#34495e',
                                                                             backgroundColor2: '#34495e',
                                                                             backgroundColor3: '#34495e',
                                                                             backgroundColor4: '#34495e'
                                                                            });
                                                                         this.employeeUpdateSchedule(val)}}>
                                                                             <i className="fa  fa-edit" style={{ color: "green", marginRight: "5%" }}></i>
                                                                        </a> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row_parent">

                                                                                        {this.state.daySched.map((val, index) => {

                                                                                            var backgroundColor = '#6c757d';
                                                                                            for (let i = 0; i < this.state.getdaySched.length; i++) {
                                                                                                if (this.state.getdaySched[i].day == val.day) {
                                                                                                    backgroundColor = '#32a932';
                                                                                                }
                                                                                            }
                                                                                            return <div className="alignHOrizontal" style={{ backgroundColor: backgroundColor, height: 20, width: 20, borderRadius: 5, marginRight: 3, marginBottom: 5 }} key={index}>
                                                                                                <strong>
                                                                                                    <p style={{ fontSize: '10px', marginLeft: 4, marginTop: 2 }}>{val.val}</p>
                                                                                                </strong>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                    <table width="100%" border="1" style={{ borderColor: '#dcdde1' }}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className="bg-green texxt-white">DAY</th>
                                                                                                <th className="bg-green texxt-white">FROM</th>
                                                                                                <th className="bg-green texxt-white">TO</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.getdaySched.map((val, index) => {
                                                                                                if (val.day != null && val.attn_in != null && val.attn_out != null) {
                                                                                                    var AM = moment(`${'2019-01-01'} ${val.attn_in}`).format('LT');
                                                                                                    var PM = moment(`${'2019-01-01'} ${val.attn_out}`).format('LT');
                                                                                                    if (val.attn_in >= "00:00" && val.attn_in <= "11:59") {
                                                                                                        // AM = val.attn_in + " " + 'AM';
                                                                                                    } else if (val.attn_in == "0") {
                                                                                                        AM = "flex-time";
                                                                                                    }

                                                                                                    if (val.attn_out <= "23:59" && val.attn_out >= "12:00") {
                                                                                                        // PM = val.attn_out + " " + 'PM';
                                                                                                    } else if (val.attn_out == "0") {
                                                                                                        PM = "flex-time";
                                                                                                    }
                                                                                                    return <tr key={index}>
                                                                                                        <td style={{ color: '#353b48' }}>{val.day}</td>
                                                                                                        <td style={{ color: '#353b48' }}>{AM}</td>
                                                                                                        <td style={{ color: '#353b48' }}>{PM}</td>
                                                                                                    </tr>
                                                                                                }
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <br />
                                                                                </div>


                                                                            </>
                                                                        }
                                                                        {this.state.schedule_edit == true &&
                                                                            <div className="panel-body ">
                                                                                <form onSubmit={this.UpdateSchedule}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <h5 style={{ color: "#bdc3c7" }}><b>Schedule Edit</b></h5>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <button className="bttnbred text-center pull-right bg-red" onClick={() => this.setState({ schedule_edit: false })}>
                                                                                                <span className="fa fa-arrow-circle-left fa-sm"></span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className="col-md-12">
                                                                                            <div className='row'>

                                                                                                <div className="col-md-6">
                                                                                                    <label>Shift schedule</label>
                                                                                                    <select className='form-control' onChange={this.onChangeSelectShift} required>
                                                                                                        <option value="">  -- Select Shift --</option>
                                                                                                        <option value=""></option>
                                                                                                        <option value="Day Shift">  Day Shift</option>
                                                                                                        <option value="Night Shift"> Night Shift</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <label>Start Date  Implemented</label>
                                                                                                    <DatePicker
                                                                                                        selected={this.state.startDate_sched}
                                                                                                        selectsEnd
                                                                                                        startDate={this.state.startDate_sched}
                                                                                                        onChange={(e) => this.setState({ startDate_sched: e })}
                                                                                                        className="form-control"
                                                                                                        required
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <br />



                                                                                    </div>
                                                                                    <br />
                                                                                    <br />
                                                                                    <div className='row'>
                                                                                        <div className='col-md-6 m-l-5'>
                                                                                            <label>Lunch Break</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row m-l-5'>
                                                                                        {/* <div className='row'> */}
                                                                                        <div className='col-md-12'>

                                                                                            <div>
                                                                                                <table>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td ><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Mon')
                                                                                                            }} />
                                                                                                            </td>
                                                                                                            <td ><label>Monday</label></td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Mon')

                                                                                                                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                                                                                                                            <input className='form-control' onChange={(e) => {
                                                                                                                                this.hoursLunchBreak('Mon', e)
                                                                                                                            }} required />&nbsp;<label>HOURS</label>
                                                                                                                        </div>
                                                                                                                })
                                                                                                                }</td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Mon')
                                                                                                                        return <input type="time" id="mondayIN" name="Mon" value={this.state.Mon} onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Tue')
                                                                                                            }} /></td>
                                                                                                            <td><label>Tuesday</label></td>
                                                                                                            <td>{this.state.lunchBreak.map((val, index) => {
                                                                                                                if (val.day == 'Tue')
                                                                                                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} ><input className='form-control' onChange={(e) => {
                                                                                                                        this.hoursLunchBreak('Tue', e)
                                                                                                                    }} required />&nbsp;<label>HOURS</label>
                                                                                                                    </div>
                                                                                                            })
                                                                                                            }
                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Tue')
                                                                                                                        return <input type="time" id="mondayIN" name="Tue" value={this.state.Tue} onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Wed')
                                                                                                            }} /></td>
                                                                                                            <td><label>Wednesday</label></td>
                                                                                                            <td>{this.state.lunchBreak.map((val, index) => {
                                                                                                                if (val.day == 'Wed')
                                                                                                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} > <input className='form-control' onChange={(e) => {
                                                                                                                        this.hoursLunchBreak('Wed', e)
                                                                                                                    }} />&nbsp;<label>HOURS</label>
                                                                                                                    </div>
                                                                                                            })
                                                                                                            }</td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Wed')
                                                                                                                        return <input type="time" id="mondayIN" value={this.state.Wed} name="Wed" onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>

                                                                                                        <tr>
                                                                                                            <td><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Thu')
                                                                                                            }} /></td>
                                                                                                            <td><label>Thursday</label></td>
                                                                                                            <td>{this.state.lunchBreak.map((val, index) => {
                                                                                                                if (val.day == 'Thu')
                                                                                                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} > <input className='form-control' onChange={(e) => {
                                                                                                                        this.hoursLunchBreak('Thu', e)
                                                                                                                    }} required />&nbsp;<label>HOURS</label>
                                                                                                                    </div>
                                                                                                            })
                                                                                                            }</td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Thu')
                                                                                                                        return <input type="time" id="mondayIN" value={this.state.Thu} name="Thu" onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Fri')
                                                                                                            }} /></td>
                                                                                                            <td><label>Friday</label></td>
                                                                                                            <td>{this.state.lunchBreak.map((val, index) => {
                                                                                                                if (val.day == 'Fri')
                                                                                                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} ><input className='form-control' onChange={(e) => {
                                                                                                                        this.hoursLunchBreak('Fri', e)
                                                                                                                    }} required />&nbsp;<label>HOURS</label>
                                                                                                                    </div>
                                                                                                            })
                                                                                                            }</td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Fri')
                                                                                                                        return <input type="time" id="mondayIN" value={this.state.Fri} name="Fri" onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Sat')
                                                                                                            }} /></td>
                                                                                                            <td><label>Saturday</label></td>
                                                                                                            <td>{this.state.lunchBreak.map((val, index) => {
                                                                                                                if (val.day == 'Sat')
                                                                                                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} ><input className='form-control' onChange={(e) => {
                                                                                                                        this.hoursLunchBreak('Sat', e)
                                                                                                                    }} required />&nbsp;<label>HOURS</label>
                                                                                                                    </div>
                                                                                                            })
                                                                                                            }</td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Sat')
                                                                                                                        return <input type="time" id="mondayIN" value={this.state.Sat} name="Sat" onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><input type='checkbox' onChange={() => {
                                                                                                                this.lunchBreak('Sun')
                                                                                                            }} /></td>
                                                                                                            <td><label>Sunday</label></td>
                                                                                                            <td>{this.state.lunchBreak.map((val, index) => {
                                                                                                                if (val.day == 'Sun')
                                                                                                                    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} ><input className='form-control' onChange={(e) => {
                                                                                                                        this.hoursLunchBreak('Sun', e)
                                                                                                                    }} required />&nbsp;<label>HOURS</label>
                                                                                                                    </div>

                                                                                                            })
                                                                                                            }</td>
                                                                                                            <td >
                                                                                                                {this.state.lunchBreak.map((val, index) => {
                                                                                                                    if (val.day == 'Sun')
                                                                                                                        return <input type="time" id="mondayIN" value={this.state.Sun} name="Sun" onChange={this.handleChangeL} />
                                                                                                                })
                                                                                                                }</td>
                                                                                                        </tr>

                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className='col-md-12'>

                                                                                            <div>
                                                                                                <table>
                                                                                                    <tbody>
                                                                                                        
                                                                                                       
                                                                                                       


                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        {/* </div> */}

                                                                                    </div>

                                                                                    <br />

                                                                                    <center><div className="row ">
                                                                                        <div className="col-md-12">
                                                                                            <div className="row">
                                                                                                <div className="col-md-3">
                                                                                                    <a className="btn  btn-xs" style={{ color: "white", backgroundColor: this.state.backgroundColor1, width: 100, height: 30 }} onClick={() => {
                                                                                                        this.setState({
                                                                                                            default: false,
                                                                                                            create: true,
                                                                                                            others: true,
                                                                                                            flextime: true,
                                                                                                            hidemonday: true,
                                                                                                            hidetuesday: true,
                                                                                                            hidewednesday: true,
                                                                                                            hidethursday: true,
                                                                                                            hidefriday: true,
                                                                                                            hidesaturday: true,
                                                                                                            hidesunday: true,
                                                                                                            typeSched: "Default",
                                                                                                            weekDays: [],
                                                                                                            backgroundColor1: '#32a932',
                                                                                                            backgroundColor2: '#0000001a',
                                                                                                            backgroundColor3: '#0000001a',
                                                                                                            backgroundColor4: '#0000001a'
                                                                                                        })
                                                                                                    }}>Default</a>
                                                                                                </div>
                                                                                                <div className="col-md-3">
                                                                                                    <a className="btn  btn-xs" style={{ color: "white", backgroundColor: this.state.backgroundColor2, width: 100, height: 30 }} onClick={() => {
                                                                                                        this.setState({
                                                                                                            default: true,
                                                                                                            create: true,
                                                                                                            flextime: true,
                                                                                                            others: false,
                                                                                                            weekDays: [],
                                                                                                            typeSched: "Others",
                                                                                                            backgroundColor1: '#0000001a',
                                                                                                            backgroundColor2: '#32a932',
                                                                                                            backgroundColor3: '#0000001a',
                                                                                                            backgroundColor4: '#0000001a'
                                                                                                        })
                                                                                                    }}>Others</a>
                                                                                                </div>
                                                                                                <div className="col-md-3">
                                                                                                    <a className="btn  btn-xs" style={{ color: "white", backgroundColor: this.state.backgroundColor3, width: 100, height: 30 }} onClick={() => {
                                                                                                        this.setState({
                                                                                                            default: true,
                                                                                                            others: true,
                                                                                                            flextime: true,
                                                                                                            create: false,
                                                                                                            weekDays: [],
                                                                                                            typeSched: "Create",
                                                                                                            backgroundColor1: '#0000001a',
                                                                                                            backgroundColor2: '#0000001a',
                                                                                                            backgroundColor3: '#32a932',
                                                                                                            backgroundColor4: '#0000001a'

                                                                                                        })
                                                                                                    }}>Create</a>
                                                                                                </div>
                                                                                                <div className="col-md-3">
                                                                                                    <a className="btn  btn-xs" style={{ color: "white", backgroundColor: this.state.backgroundColor4, width: 100, height: 30 }} onClick={() => {
                                                                                                        this.setState({
                                                                                                            weekDays: [],
                                                                                                            hidemonday: true,
                                                                                                            hidetuesday: true,
                                                                                                            hidewednesday: true,
                                                                                                            hidethursday: true,
                                                                                                            hidefriday: true,
                                                                                                            hidesaturday: true,
                                                                                                            hidesunday: true,
                                                                                                            default: true,
                                                                                                            others: true,
                                                                                                            create: true,
                                                                                                            flextime: false,
                                                                                                            typeSched: "Flex-time",
                                                                                                            backgroundColor1: '#00001a',
                                                                                                            backgroundColor2: '#0000001a',
                                                                                                            backgroundColor3: '#0000001a',
                                                                                                            backgroundColor4: '#32a932'
                                                                                                        })
                                                                                                    }}>Flex-Time</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6"></div>
                                                                                    </div></center>
                                                                                    <br />
                                                                                    {this.state.default === false &&
                                                                                        <div>
                                                                                            {/* <p style={{ color: "#353b48" }}>Default Schedule:</p> */}
                                                                                            <table width="70%" className="table border-stripped">
                                                                                                <tr>
                                                                                                    <th>Default Schedule:</th>
                                                                                                    <th></th>

                                                                                                </tr>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >MON</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.mondayIN} />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.mondayOUT} />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >TUE</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.tuesdayIN} />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.tuesdayOUT} />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >WED</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.wednesdayIN} />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.wednesdayOUT} />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >THU</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.thursdayIN} />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.thursdayOUT} />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >FRI</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.fridayIN} />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.fridayOUT} />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >SAT</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.saturdayIN} />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" value={this.state.saturdayOUT} />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    }
                                                                                    {this.state.others === false &&
                                                                                        <table className="table border-stripped">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className="bg-green text-white">Created Schedule</th>

                                                                                                    <th className="bg-green"></th>
                                                                                                </tr>

                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.state.createdSched.map((val, index) => {
                                                                                                    return <tr key={index}>
                                                                                                        <td width="20%">
                                                                                                            <input type="radio" style={{ marginTop: 5 }} name="radio" onChange={() => {
                                                                                                                this.setState({ weekDays: val.date })
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="row_parent" >
                                                                                                                {val.date.map((val2, index2) => {
                                                                                                                    return <div className="alignHOrizontal" style={{ backgroundColor: '#6ab04c', height: 40, width: 65, borderRadius: 5, marginRight: 3, marginBottom: 5 }} key={index2}>
                                                                                                                        <strong>
                                                                                                                            <p style={{ fontSize: '10px', marginLeft: 4, marginTop: 2 }}>{val2.day}<br /><span>{val2.timein}&nbsp;-&nbsp;{val2.timeout}</span></p>
                                                                                                                            {/* <p style={{ fontSize: '10px', marginLeft: 4, marginTop: 2 }}></p> */}

                                                                                                                        </strong>
                                                                                                                    </div>
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                })}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    }
                                                                                    {this.state.create === false &&
                                                                                        <div>
                                                                                            <table width="70%" className="table border-stripped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th className="bg-green text-white">Created Schedule</th>

                                                                                                        <th className="bg-green"></th>
                                                                                                        <th className="bg-green"></th>

                                                                                                    </tr>

                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Mon'
                                                                                                                var timeIn = this.state.mondayIN;
                                                                                                                var timeOut = this.state.mondayOUT;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: String(timeIn), timeout: String(timeOut), break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >MON</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidemonday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editmonday === false &&

                                                                                                                            <input type="time" value={this.state.mondayIN} />
                                                                                                                        }
                                                                                                                        {this.state.editmonday2 === false &&
                                                                                                                            <input type="time" id="mondayIN" name="mondayIN" onChange={this.handleChange1} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editmonday === false &&
                                                                                                                            <input type="time" value={this.state.mondayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.editmonday2 === false &&
                                                                                                                            <input type="time" id="mondayOUT" name="mondayOUT" onChange={this.handleChange2} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       editmonday: !this.state.editmonday,
                                                                       editmonday2: !this.state.editmonday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                editmonday: !this.state.editmonday,
                                                                                                                                editmonday2: !this.state.editmonday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Tue'
                                                                                                                var timeIn = this.state.tuesdayIN;
                                                                                                                var timeOut = this.state.tuesdayOUT;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidetuesday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidetuesday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >TUE</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidetuesday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.edittuesday === false &&
                                                                                                                            <input type="time" value={this.state.tuesdayIN} />
                                                                                                                        }
                                                                                                                        {this.state.edittuesday2 === false &&
                                                                                                                            <input type="time" id="tuesdayIN" name="tuesdayIN" onChange={this.handleChange3} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.edittuesday === false &&
                                                                                                                            <input type="time" value={this.state.tuesdayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.edittuesday2 === false &&
                                                                                                                            <input type="time" id="tuesdayOUT" name="tuesdayOUT" onChange={this.handleChange4} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       edittuesday: !this.state.edittuesday,
                                                                       edittuesday2: !this.state.edittuesday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                edittuesday: !this.state.edittuesday,
                                                                                                                                edittuesday2: !this.state.edittuesday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var Day = 'Wed'
                                                                                                                var timeIn = this.state.wednesdayIN;
                                                                                                                var timeOut = this.state.wednesdayOUT;
                                                                                                                var day_selected = [];
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidewednesday: true })
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidewednesday: false })
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >WED</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidewednesday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editwednesday === false &&
                                                                                                                            <input type="time" value={this.state.wednesdayIN} />
                                                                                                                        }
                                                                                                                        {this.state.editwednesday2 === false &&
                                                                                                                            <input type="time" id="wednesdayIN" name="wednesdayIN" onChange={this.handleChange5} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editwednesday === false &&
                                                                                                                            <input type="time" value={this.state.wednesdayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.editwednesday2 === false &&
                                                                                                                            <input type="time" id="wednesdayOUT" name="wednesdayOUT" onChange={this.handleChange6} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       editwednesday: !this.state.editwednesday,
                                                                       editwednesday2: !this.state.editwednesday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                editwednesday: !this.state.editwednesday,
                                                                                                                                editwednesday2: !this.state.editwednesday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var Day = 'Thu'
                                                                                                                var timeIn = this.state.thursdayIN;
                                                                                                                var timeOut = this.state.thursdayOUT;
                                                                                                                var day_selected = [];
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidethursday: true })
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidethursday: false })
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >THU</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidethursday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editthursday === false &&
                                                                                                                            <input type="time" value={this.state.thursdayIN} />
                                                                                                                        }
                                                                                                                        {this.state.editthursday2 === false &&
                                                                                                                            <input type="time" id="thursdayIN" name="thursdayIN" onChange={this.handleChange7} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editthursday === false &&
                                                                                                                            <input type="time" value={this.state.thursdayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.editthursday2 === false &&
                                                                                                                            <input type="time" id="thursdayOUT" name="thursdayOUT" onChange={this.handleChange8} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       editthursday: !this.state.editthursday,
                                                                       editthursday2: !this.state.editthursday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                editthursday: !this.state.editthursday,
                                                                                                                                editthursday2: !this.state.editthursday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Fri'
                                                                                                                var timeIn = this.state.fridayIN;
                                                                                                                var timeOut = this.state.fridayOUT;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidefriday: true })
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidefriday: false })
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >FRI</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidefriday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editfriday === false &&
                                                                                                                            <input type="time" value={this.state.fridayIN} />
                                                                                                                        }
                                                                                                                        {this.state.editfriday2 === false &&
                                                                                                                            <input type="time" id="fridayIN" name="fridayIN" onChange={this.handleChange9} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editfriday === false &&
                                                                                                                            <input type="time" value={this.state.fridayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.editfriday2 === false &&
                                                                                                                            <input type="time" id="fridayOUT" name="fridayOUT" onChange={this.handleChange10} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       editfriday: !this.state.editfriday,
                                                                       editfriday2: !this.state.editfriday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                editfriday: !this.state.editfriday,
                                                                                                                                editfriday2: !this.state.editfriday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Sat'
                                                                                                                var timeIn = this.state.saturdayIN;
                                                                                                                var timeOut = this.state.saturdayOUT;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidesaturday: true })
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidesaturday: false })
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >SAT</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidesaturday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editsaturday === false &&
                                                                                                                            <input type="time" value={this.state.saturdayIN} />
                                                                                                                        }
                                                                                                                        {this.state.editsaturday2 === false &&
                                                                                                                            <input type="time" id="saturdayIN" name="saturdayIN" onChange={this.handleChange11} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editsaturday === false &&
                                                                                                                            <input type="time" value={this.state.saturdayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.editsaturday2 === false &&
                                                                                                                            <input type="time" id="saturdayOUT" name="saturdayOUT" onChange={this.handleChange12} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       editsaturday: !this.state.editsaturday,
                                                                       editsaturday2: !this.state.editsaturday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                editsaturday: !this.state.editsaturday,
                                                                                                                                editsaturday2: !this.state.editsaturday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var timeIn = this.state.sundayIN;
                                                                                                                var timeOut = this.state.sundayOUT;
                                                                                                                var Day = 'Sun'
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidesunday: true })
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidesunday: false })
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >SUN</p></td>
                                                                                                        <td>
                                                                                                            {this.state.hidesunday === false &&
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editsunday === false &&
                                                                                                                            <input type="time" value={this.state.sundayIN} />
                                                                                                                        }
                                                                                                                        {this.state.editsunday2 === false &&
                                                                                                                            <input type="time" id="sundayIN" name="sundayIN" onChange={this.handleChange13} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-5">
                                                                                                                        {this.state.editsunday === false &&
                                                                                                                            <input type="time" value={this.state.sundayOUT} />
                                                                                                                        }
                                                                                                                        {this.state.editsunday2 === false &&
                                                                                                                            <input type="time" id="sundayOUT" name="sundayOUT" onChange={this.handleChange14} />
                                                                                                                        }
                                                                                                                        {/* <input type="time" value={this.state.mondayOUT}/> */}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-2">
                                                                                                                        {/* <i className="fa fa-edit" style={{ fontSize: '20px', marginTop: 13, color: '#2d3436' }} onClick={() => {
                                                                   this.setState({
                                                                       editsunday: !this.state.editsunday,
                                                                       editsunday2: !this.state.editsunday2
                                                                   })
                                                               }}></i> */}
                                                                                                                        <button type='button' className="bttnschededit" onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                editsunday: !this.state.editsunday,
                                                                                                                                editsunday2: !this.state.editsunday2
                                                                                                                            })
                                                                                                                        }}>
                                                                                                                            <span className="fa fa-edit fa-sm"></span>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    }
                                                                                    {this.state.flextime === false &&
                                                                                        <div>
                                                                                            <p style={{ color: "#353b48" }}>Flex-time Schedule:</p>
                                                                                            <table width="70%" className="table border-stripped">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Mon'
                                                                                                                var timeIn = 0;
                                                                                                                var timeOut = 0;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >MON</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Tue'
                                                                                                                var timeIn = 0;
                                                                                                                var timeOut = 0;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >TUE</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Wed'
                                                                                                                var timeIn = 0;
                                                                                                                var timeOut = 0;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >WED</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Thu'
                                                                                                                var timeIn = 0;
                                                                                                                var timeOut = 0;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >THU</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Fri'
                                                                                                                var timeIn = 0;
                                                                                                                var timeOut = 0;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >FRI</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <input type="checkbox" style={{ marginTop: 13 }} onChange={() => {
                                                                                                                var day_selected = [];
                                                                                                                var Day = 'Sat'
                                                                                                                var timeIn = 0;
                                                                                                                var timeOut = 0;
                                                                                                                var match = false;
                                                                                                                for (let d = 0; d < this.state.weekDays.length; d++) {
                                                                                                                    if (this.state.weekDays[d].day === Day) {
                                                                                                                        this.state.weekDays.splice(parseInt(d), 1)
                                                                                                                        match = true;
                                                                                                                        this.setState({ hidemonday: true });
                                                                                                                        this.setState({ refresh: true });
                                                                                                                    }
                                                                                                                }

                                                                                                                if (match == false) {
                                                                                                                    day_selected = { day: Day, timein: timeIn, timeout: timeOut, break: 1, time_start: '12:00' }
                                                                                                                    this.state.weekDays.push(day_selected)
                                                                                                                    this.setState({ hidemonday: false });
                                                                                                                    this.setState({ refresh: true })
                                                                                                                }
                                                                                                            }} />
                                                                                                        </td>
                                                                                                        <td><p style={{ color: "#353b48", marginTop: 13 }} >SAT</p></td>
                                                                                                        <td>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-5">
                                                                                                                    <input type="time" style={{ color: 'red' }} readOnly />
                                                                                                                </div>
                                                                                                                <div className="col-md-2">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    }
                                                                                    <br />
                                                                                    <button className="bttnupdate text-center pull-right bg-green" type="submit">
                                                                                        <span className="fa fa-save fa-sm"></span>
                                                                                    </button>
                                                                                    {/* <button className="btn  btn-xs pull-right" style={{ color: "white", backgroundColor: "#34495e", width: 55 }} >Update</button> */}
                                                                                </form>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="panel solid">
                                                                        <br />
                                                                        <div className="row p-15">
                                                                            <div className="col-md-6">
                                                                                <h5 style={{ color: "#bdc3c7" }}><b>Schedule Logs</b></h5>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <button className="bttnedit text-center pull-right bg-green" onClick={() => {
                                                                                    this.setState({ View: !this.state.View })

                                                                                }}>
                                                                                    <span>View</span>
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                        {this.state.View && this.state.sched_days.map((val2, index2) => {
                                                                            let update = '';
                                                                            if (index2 == (parseInt(this.state.sched_days.length) - 1)) {
                                                                                update = 'Current Schedule';
                                                                            }
                                                                            return <div className="panel-body ">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <h4 style={{ color: 'green' }}>{update}</h4>
                                                                                        <h6>Date Implemented : <span style={{ fontWeight: 'bold' }}>{val2.sched_implemented}</span></h6>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row_parent">

                                                                                    {this.state.daySched.map((val3, index3) => {
                                                                                        var backgroundColor = '#6c757d';
                                                                                        for (let i = 0; i < val2.shed_days.length; i++) {
                                                                                            if (val2.shed_days[i].day_sched == val3.day) {
                                                                                                backgroundColor = '#32a932';
                                                                                            }
                                                                                        }
                                                                                        return <div className="alignHOrizontal" style={{ backgroundColor: backgroundColor, height: 20, width: 20, borderRadius: 5, marginRight: 3, marginBottom: 5 }} key={index3}>
                                                                                            <strong>
                                                                                                <p style={{ fontSize: '10px', marginLeft: 4, marginTop: 2 }}>{val3.val}</p>
                                                                                            </strong>
                                                                                        </div>
                                                                                    })}
                                                                                </div>
                                                                                <table width="100%" border="1" style={{ borderColor: '#dcdde1' }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="bg-green texxt-white">DAY</th>
                                                                                            <th className="bg-green texxt-white">FROM</th>
                                                                                            <th className="bg-green texxt-white">TO</th>
                                                                                            <th className="bg-green texxt-white">Break</th>
                                                                                            <th className="bg-green texxt-white">Lunch Start</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>



                                                                                        {val2.shed_days.map((val, index) => {

                                                                                            if (val.day_sched != null && val.attn_in != null && val.attn_out != null) {
                                                                                                var AM = moment(`${'2019-01-01'} ${val.attn_in}`).format('LT');
                                                                                                var PM = moment(`${'2019-01-01'} ${val.attn_out}`).format('LT');
                                                                                                if (val.attn_in >= "00:00" && val.attn_in <= "11:59") {
                                                                                                    // AM = val.attn_in + " " + 'AM';
                                                                                                } else if (val.attn_in == "0") {
                                                                                                    AM = "flex-time";
                                                                                                }

                                                                                                if (val.attn_out <= "23:59" && val.attn_out >= "12:00") {
                                                                                                    // PM = val.attn_out + " " + 'PM';
                                                                                                } else if (val.attn_out == "0") {
                                                                                                    PM = "flex-time";
                                                                                                }
                                                                                                return <tr key={index}>
                                                                                                    <td style={{ color: '#353b48' }}>{val.day_sched}</td>
                                                                                                    <td style={{ color: '#353b48' }}>{AM}</td>
                                                                                                    <td style={{ color: '#353b48' }}>{PM}</td>
                                                                                                    <td style={{ color: '#353b48' }}>{val.break}{val.break >= 0 && <span>&nbsp;Hrs</span>}</td>
                                                                                                    <td style={{ color: '#353b48' }}>{val.break_start}</td>
                                                                                                </tr>
                                                                                            }
                                                                                        })

                                                                                        }



                                                                                    </tbody>
                                                                                </table>
                                                                                <br />
                                                                            </div>
                                                                        })

                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                            {this.state.click === "approver" &&
                                                                <div className="panel solid">
                                                                    {this.state.Hierarchy == false &&
                                                                        <div className="panel-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <h5 style={{ color: "#bdc3c7" }}><b>Hierarchy Request Approval</b></h5>
                                                                                </div>
                                                                                <div className="col-md-6">

                                                                                    <button className="btttn pull-right bg-green-transparent-9 fas fa-edit" onClick={() => {
                                                                                        this.Hierarchy(val)
                                                                                    }}>&nbsp;Edit</button>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.request_type.map((val, index) => {
                                                                                //     var type_request = "";
                                                                                //     var title = "";
                                                                                //     var name ="";

                                                                                //    for (let i = 0; i < this.state.user_request_hierarchy.length; i++) {
                                                                                //        var JSON_type = JSON.parse(this.state.user_request_hierarchy[i].types )
                                                                                //        for (let k = 0; k < JSON_type.length; k++) {
                                                                                //         if(val.type ==JSON_type[k]) {
                                                                                //             approver = this.state.user_request_hierarchy[i].approver
                                                                                //             title =  this.state.user_request_hierarchy[i].title
                                                                                //            }

                                                                                //        }

                                                                                //     }

                                                                                return <div key={index}><hr />
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12  w3-animate-left">
                                                                                            <p style={{ fontSize: "15px", color: "#353b48" }}><b>{val.type}</b></p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row m-l-10">
                                                                                        <div className="col-sm-12  w3-animate-left">
                                                                                            <p className='m-l-20'>
                                                                                                {this.state.user_request_hierarchy.length != 0 &&
                                                                                                    <div>
                                                                                                        {this.state.user_request_hierarchy.map((val1, index1) => {
                                                                                                            var approver = []
                                                                                                            var title = ""
                                                                                                            var JSON_type = []
                                                                                                            if (val1.types != "") {
                                                                                                                JSON_type = JSON.parse(val1.types)
                                                                                                            }
                                                                                                            var level = ""
                                                                                                            for (let k = 0; k < JSON_type.length; k++) {
                                                                                                                if (val.type == JSON_type[k]) {
                                                                                                                    approver = this.state.user_request_hierarchy[index1].approver
                                                                                                                    title = val1.title
                                                                                                                    level = index1 + 1
                                                                                                                }

                                                                                                            }
                                                                                                            return <div key={index}><span style={{ fontSize: "14px", color: "rgb(15, 158, 62)", marginTop: '10' }}>
                                                                                                                <span style={{ fontSize: "12px", color: 'black' }}>{level}</span> <b>{title}</b>
                                                                                                            </span>
                                                                                                                {approver.map((val2, index2) => {
                                                                                                                    return <div key={index}> <br />
                                                                                                                        <span className='m-l-40' style={{ fontSize: "12px", color: "#353b48", marginTop: '10' }}>
                                                                                                                            <i className='fa fa-user-alt'></i> <b>{val2.name.toUpperCase()}</b>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                })}
                                                                                                            </div>
                                                                                                        })}
                                                                                                    </div>
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })}
                                                                        </div>
                                                                    }
                                                                    {this.state.Hierarchy == true &&
                                                                        <div className="panel-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <h5 style={{ color: "#bdc3c7" }}><b>Hierarchy Request Approval</b></h5>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" onClick={() => this.setState({ Hierarchy: false })}>
                                                                                        &nbsp;Cancel
                                                                            </button>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            {this.state.SelectHierarchy == true &&
                                                                                <div>
                                                                                    <div>
                                                                                        <label style={{ color: "#130f40" }}>Select Hierarchy Structure*</label>
                                                                                        <select className="form-control" onChange={this.onChangeSelectHierarchy} >
                                                                                            <option></option>
                                                                                            {this.state.hierarchy_structures.map((val, index) => {
                                                                                                return <option value={index}>{val.name}</option>
                                                                                            })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                    {this.state.selectedHierarchy != 0 &&
                                                                                        this.state.request_type.map((val_type, indx) => {
                                                                                            return <div>
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-12  w3-animate-left">
                                                                                                        <p style={{ fontSize: "15px", color: "#353b48" }}><b>{val_type.type}</b></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row m-l-10">
                                                                                                    <div className="col-sm-12  w3-animate-left">
                                                                                                        <p className='m-l-20'>

                                                                                                            <div>
                                                                                                                {this.state.select_type.map((val1, index1) => {
                                                                                                                    var approver = []
                                                                                                                    var title = ""
                                                                                                                    var JSON_type = JSON.parse(val1.types)
                                                                                                                    var level = ""
                                                                                                                    for (let k = 0; k < JSON_type.length; k++) {
                                                                                                                        if (JSON_type[k] == val_type.type) {
                                                                                                                            approver = val1.approver
                                                                                                                            title = val1.title
                                                                                                                            level = index1 + 1
                                                                                                                        }
                                                                                                                    }
                                                                                                                    return <div><span style={{ fontSize: "14px", color: "rgb(15, 158, 62)", marginTop: '10' }}>
                                                                                                                        <span style={{ fontSize: "12px", color: 'black' }}>{level}</span> <b>{title}</b>
                                                                                                                    </span>
                                                                                                                        {approver.map((val2, index2) => {
                                                                                                                            return <div> <br />
                                                                                                                                <span className='m-l-40' style={{ fontSize: "12px", color: "#353b48", marginTop: '10' }}>
                                                                                                                                    <i className='fa fa-user-alt'></i> <b>{val2.name.toUpperCase()}</b>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {this.state.newHierarchy == true &&
                                                                                <div>
                                                                                    <label>Set Name of Hierarchy*</label><br />

                                                                                    <div className="row">
                                                                                        <div className="col-sm-12  w3-animate-right">

                                                                                            <input onChange={this.textchange} type="text" name="hierarchy_name" className="form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    {
                                                                                        this.mapLevel()
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {(this.state.newHierarchy == true) &&
                                                                                <div className="pull-right m-t-10">
                                                                                    <button className="btttn fa fa-plus bg-green-transparent-9 m-l-5" onClick={() => { this.AddLevel() }}>
                                                                                        &nbsp;New Level
                                                                            </button>
                                                                                    <button className="btttn bg-blue-transparent-9 fas fa-clipboard-check m-l-5" data-dismiss={this.state.modal} data-target={this.state.modalPath} data-toggle="modal" onClick={() => {
                                                                                        var match = false;
                                                                                        for (let index = 0; index < this.state.name_hierarchy.length; index++) {
                                                                                            var title = this.state.name_hierarchy[index].title
                                                                                            if (title == "") {
                                                                                                match = true
                                                                                            }
                                                                                            if (this.state.name_hierarchy[index].approver.length == 0) {
                                                                                                match = true
                                                                                            }
                                                                                        }
                                                                                        if (match == true) {
                                                                                            alert('Complete the details')

                                                                                        } else {
                                                                                            this.setState({ review: true }); this.review()
                                                                                        }
                                                                                    }}>&nbsp;Save Hierarchy</button>
                                                                                    <button className="btttn fa fa-trash-alt bg-red-transparent-9 m-l-5" onClick={() => {
                                                                                        this.state.ADDLevel.splice(parseInt(this.state.ADDLevel.length - 1), 1); this.state.name_hierarchy.splice(parseInt(this.state.name_hierarchy.length - 1), 1)
                                                                                        this.setState({ refresh: true, indexVal: 0 })
                                                                                    }}>&nbsp;Remove Level
                                                                            </button>
                                                                                    <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none  m-l-5" onClick={() => {
                                                                                        this.setState({ newHierarchy: false, SelectHierarchy: true, selectedHierarchy: [] })
                                                                                    }}>&nbsp;Cancel
                                                                            </button>
                                                                                </div>

                                                                            }
                                                                            {this.state.newHierarchy == false &&
                                                                                <div className="m-t-10">
                                                                                    <button className="btttn pull-right bg-blue-transparent-9 fa fa-save m-r-5" onClick={() => {
                                                                                        this.SaveHierarchy(this.state.selectedHierarchy, "Update")
                                                                                    }}>&nbsp;Save Hierarchy
                                                                            </button>
                                                                                    <button className="btttn pull-right bg-green-transparent-9 fa fa-plus m-r-5" onClick={() => {
                                                                                        this.setState({ newHierarchy: true, SelectHierarchy: false, name_hierarchy: [], ADDLevel: [] })
                                                                                    }}>&nbsp;New Hierarchy</button>
                                                                                </div>

                                                                            }


                                                                            {/* </form> */}
                                                                        </div>
                                                                    }
                                                                </div>}
                                                            {this.state.click === "attendance" &&
                                                                <div className="panel-body solid">

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h5 style={{ color: "#bdc3c7" }}><b>Attendance Monitoring</b></h5>
                                                                        </div>
                                                                        <div className="col-md-6">

                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-3 m-l-30 m-t-20 widget widget-stats bg-blue">
                                                                            <div className="stats-content">
                                                                                <div className="stats-title text-white">Present</div>
                                                                                <div className="stats-number text-white pull-right m-r-30">25</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 m-l-30 m-t-20 widget widget-stats bg-orange-lighter">
                                                                            <div className="stats-content">
                                                                                <div className="stats-title text-white">Late</div>
                                                                                <div className="stats-number text-white pull-right m-r-30">1</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 m-l-30 m-t-20 widget widget-stats bg-red">
                                                                            <div className="stats-content">
                                                                                <div className="stats-title text-white">Absent</div>
                                                                                <div className="stats-number text-white pull-right m-r-30">0</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div>
                                                                        <DBar2 barGraph={this.state.barGraph} />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {this.state.click === "leaveStatus" &&
                                                                <div className="panel-body solid">

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h5 style={{ color: "#bdc3c7" }}><b>Leave Status</b></h5>

                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <button className="btttn bg-green-transparent-9 text-white fas fa-info pull-right" data-toggle="modal" data-target="#demo-lg-modal-totalleave">&nbsp;Information</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6 m-t-20">
                                                                            <div className="widget widget-stats bg-green-transparent-9">
                                                                                <div className="stats-content">
                                                                                    <div className="stats-title text-white">Total Leave</div>
                                                                                    <div className="stats-number text-white pull-right m-r-30">{(this.state.countleave == 0 || this.state.countleave == undefined) ? "0" : this.state.countleave}</div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-6 m-t-20">
                                                                            <div className="widget widget-stats bg-blue-transparent-9">
                                                                                <div className="stats-content">
                                                                                    <div className="stats-title text-white">Remaining Leave</div>
                                                                                    <div className="stats-number text-white pull-right m-r-30">{((this.state.countleave == 0 || this.state.countleave == undefined) ? "0" : (parseInt(this.state.countleave) - parseInt(this.state.cntleave.length)))}</div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div>
                                                                        <table className="table table-td-valign-middle table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Request ID</th>
                                                                                    <th>Start Date</th>
                                                                                    <th>End Date</th>
                                                                                    <th>Justification</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.cntleave.map((val, index) =>
                                                                                    <tr className="text-black">
                                                                                        <td>{val.req_id}</td>
                                                                                        <td>{moment(val.req_date_start).format('LLL')}</td>
                                                                                        <td>{moment(val.req_date_end).format('LLL')}</td>
                                                                                        <td>{val.req_justification}</td>
                                                                                    </tr>
                                                                                )}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Scrollbars>
                                                    </div>


                                                </div>
                                            </div>
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header" >
                                        <h4>Add Employee</h4>
                                        <button className="btttn bg-red pull-right" data-dismiss="modal" onClick={() => {
                                            this.setState({ showinfo: "personalinfo" });
                                            this.setState({ colorinfo1: 'green', colorinfo2: 'gray', colorinfo3: 'gray', colorinfo4: 'gray', })
                                        }}>
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }} className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-personal" role="tab" aria-selected="true"><i className="fas fa-user text-green m-r-1" />Personal &nbsp;Information</a>
                                            </li>
                                            <li className="nav-item">
                                                <a style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }} className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-address" role="tab" aria-selected="false"><i className="fas fa-home text-orange m-r-1" />Address&nbsp;Information</a>
                                            </li>
                                            <li className="nav-item">
                                                <a style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }} className="nav-link" id="pills-emergency-tab" data-toggle="pill" href="#pills-emergency" role="tab" aria-selected="false"><i className="fas fa-address-book text-blue m-r-1" />Emergency&nbsp;Contact</a>
                                            </li>
                                            <li className="nav-item">
                                                <a style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }} className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-company" role="tab" aria-selected="false"><i className="fas fa-building text-indigo m-r-1" />Company&nbsp;Information</a>
                                            </li>
                                        </ul>
                                        <form onSubmit={this.onFormSubmit}>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab" style={{ backgroundColor: "white" }}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Name*</label>
                                                                <input onChange={this.textchange} type="text" name="firstname" id="exampleCity" placeholder="First Name" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>&nbsp;</label>
                                                                <input onChange={this.textchange} type="text" name="middlename" id="exampleState" placeholder="Middle Name (Optional)" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>&nbsp;</label>
                                                                <input onChange={this.textchange} type="text" name="lastname" placeholder="Last Name" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>&nbsp;</label>
                                                                <input onChange={this.textchange} type="text" name="suffixname" id="exampleState" placeholder="Suffix Name (Optional)" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Gender*</label>
                                                                <select placeholder="Select Gender" value={this.state.gender} className="form-control" onChange={this.onChangeSelectGender} required>
                                                                    <option readOnly>Select Gender</option>
                                                                    <option value="female">Female</option>
                                                                    <option value="male">Male</option>
                                                                </select>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Civil Status*</label>
                                                                <select value={this.state.civil_status} className="form-control" onChange={this.onChangeSelect} required>
                                                                    <option readOnly>Select Civil Status</option>
                                                                    <option value="single">Single</option>
                                                                    <option value="maried">Married</option>
                                                                    <option value="divorced">Divorced</option>
                                                                    <option value="separated ">Married</option>
                                                                </select>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Contact*</label>
                                                                <input onChange={this.textchange} type="number" name="contact" placeholder="Contact " className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="m-b-2">
                                                                <label style={{ color: "#130f40" }}>Email*</label>
                                                                <input onChange={this.textchange} type="text" name="email" value={this.state.email} placeholder="email (Optional)" className="form-control" />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label className=" col-form-label">Birth Date*</label>
                                                                <div >
                                                                    <DatePicker
                                                                        selected={this.state.user_bday}
                                                                        selectsStart
                                                                        startDate={this.state.user_bday}
                                                                        // endDate={this.state.request_date_end}
                                                                        onChange={this.bdaypicker}
                                                                        className="form-control"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                        placeholderText="Birth Date"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Birth Place*</label>
                                                                <input onChange={this.textchange} type="text" name="birthplace" placeholder="Birth Place" className="form-control" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-address" role="tabpanel" aria-labelledby="pills-contact-tab" style={{ backgroundColor: "white" }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Present House No./Street/Subdivision</label>
                                                                <input onChange={this.textchange} placeholder="Present House No./Street/Subdivision" type="text" name="pres_house_street_subd" id="pres_house_street_subd" className="form-control" />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Present Barangay</label>
                                                                <input onChange={this.textchange} placeholder="Present Barangay" type="text" name="pres_barangay" id="pres_barangay" className="form-control" />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Present City</label>
                                                                <input onChange={this.textchange} placeholder="Present City" type="text" name="pres_city" id="pres_city" className="form-control" />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Present Province</label>
                                                                <input onChange={this.textchange} placeholder="Present Province" type="text" name="pres_province" id="pres_province" className="form-control" />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Present Country</label>
                                                                <input onChange={this.textchange} placeholder="Present Country" type="text" name="pres_country" id="pres_country" className="form-control" />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Present Zip Code</label>
                                                                <input onChange={this.textchange} placeholder="Present Zip Code" type="text" name="pres_zip" id="pres_zip" className="form-control" />
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <input type="checkbox" id="same" name="same" onClick={() => {
                                                                    this.same()
                                                                }} />
                                                                <label className="m-l-5" for="cssCheckbox1">Check if present address is the same with permanent address.</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {this.state.same_address === false &&
                                                                <div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent House No./Street/Subdivision</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent House No./Street/Subdivision" type="text" name="perm_house_street_subd" id="perm_house_street_subd" className="form-control" />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent Barangay</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Barangay" type="text" name="perm_barangay" id="perm_barangay" className="form-control" />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent City</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent City" type="text" name="perm_city" id="perm_city" className="form-control" />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent Province</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Province" type="text" name="perm_province" id="perm_province" className="form-control" />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent Country</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Country" type="text" name="perm_country" id="perm_country" className="form-control" />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent&nbsp;Zip&nbsp;Code</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Zip Code" type="text" name="perm_zip" id="perm_zip" className="form-control" />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {this.state.same_address === true &&
                                                                <div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent House No./Street/Subdivision</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent House No./Street/Subdivision" type="text" className="form-control" disabled />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent Barangay</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Barangay" type="text" className="form-control" disabled />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent City</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent City" type="text" className="form-control" disabled />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent Province</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Province" type="text" className="form-control" disabled />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent Country</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Country" type="text" className="form-control" disabled />
                                                                    </div>
                                                                    <div className="m-b-10">
                                                                        <label style={{ color: "#130f40" }}>Permanent&nbsp;Zip&nbsp;Code</label>
                                                                        <input onChange={this.textchange} placeholder="Permanent Zip Code" type="text" className="form-control" disabled />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-emergency" role="tabpanel" aria-labelledby="pills-emergency-tab" style={{ backgroundColor: "white" }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Contact Name*</label>
                                                                <input onChange={this.textchange} type="text" name="contact_name" id="contact_name" placeholder="Contact's Name" className="form-control" required />
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Relationship*</label>
                                                                <input onChange={this.textchange} type="text" name="contact_relation" id="contact_relation" placeholder="Relationship with Contact" className="form-control" required />
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Contact*</label>
                                                                <input onChange={this.textchange} type="number" name="contact_number" placeholder="Contact's Number" className="form-control" required />
                                                            </div>
                                                            <div className="m-b-2">
                                                                <label style={{ color: "#130f40" }}>Contact Address*</label>
                                                                <input onChange={this.textchange} type="text" name="contact_address" placeholder="Contact's Address" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-company" role="tabpanel" aria-labelledby="pills-company-tab" style={{ backgroundColor: "white" }}>
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12 wrapper">
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Company*</label>
                                                                <select value={this.state.select} className="form-control" onChange={this.onChangeSelectCompany} placeholder="Select Company" required>
                                                                    <option readOnly>Select Company Name</option>
                                                                    {this.state.companies.map((val, index) =>
                                                                        <option value={val.company_id}>{val.company_name}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Branch*</label>
                                                                <select value={this.state.selectbranch} className="form-control" onChange={this.onChangeSelectBranch} placeholder="Select Branch" required>
                                                                    <option readOnly>Select Branch Name</option>
                                                                    {this.state.branches.map((val, index) =>
                                                                        <option value={val.bid}>{val.bname}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Department*</label>
                                                                <select className="form-control" onChange={this.onChangeSelectDepartment} required>
                                                                    <option readOnly>Select Department Name</option>
                                                                    {this.state.departments.map((val, index) =>
                                                                        <option value={val.dept_id}>{val.dept_name}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Job Description*</label>
                                                                {/* <input onChange={this.textchange} type="text" name="position" value={this.state.position}   placeholder="Position" className="form-control" required /> */}
                                                                <select className="form-control" name="job" onChange={this.onChangeSelectJD} required>
                                                                    <option readOnly>Select Job Description</option>
                                                                    {this.state.jd.map((val, index) => {
                                                                        return <option value={val.job_description}>{val.job_description}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12 wrapper">

                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Position*</label>
                                                                {/* <input onChange={this.textchange} type="text" name="position" value={this.state.position}   placeholder="Position" className="form-control" required /> */}
                                                                <select className="form-control" onChange={this.onChangeSelectPosition} required>
                                                                    <option readOnly>Select Position Name</option>
                                                                    {this.state.positions.map((val, index) =>
                                                                        <option value={val.userjob_position}>{val.userjob_position}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="m-b-2">
                                                                <label style={{ color: "#130f40" }}>Employee Status*</label>
                                                                <select className="form-control" onChange={this.onChangeSelectEmployeeStatus} required>
                                                                    <option readOnly>Select Employee Status</option>
                                                                    <option disabled>--select status--</option>
                                                                    <option value="Regular">Regular</option>
                                                                    <option value="Probationary">Probationary</option>
                                                                    <option value="Trainee">Trainee</option>
                                                                    <option value="Contractual">Contractual</option>
                                                                </select>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label className=" col-form-label">Date Hired*</label>
                                                                <div >
                                                                    <DatePicker
                                                                        selected={this.state.user_date_hired}
                                                                        selectsStart
                                                                        startDate={this.state.user_date_hired}
                                                                        // endDate={this.state.request_date_end}
                                                                        onChange={this.dhiredpicker}
                                                                        className="form-control"
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                        placeholderText="Date Hired"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Username*</label>
                                                                <input onChange={this.textchange} type="text" name="username" placeholder="username" className="form-control" required />
                                                            </div>
                                                            {/* <div className="m-b-10">
                                                                <label style={{ color: "#130f40" }}>Password*</label>
                                                                <input onChange={this.textchange} type="password" name="password" placeholder="password" className="form-control" required />
                                                            </div> */}
                                                        </div>


                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <button className="btn bg-green text-white m-l-5" type="submit" >Create</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="demo-lg-modal-status" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Employment Status</h4>
                                        <button id="close" data-dismiss="modal" className="pull-right btttn bg-red-lighter">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                    </div>
                                    <div className="modal-body ">
                                        <form onSubmit={this.saveStatus}>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Name</label>
                                                <div className="col-md-9">
                                                    <input type="email" className="form-control m-b-5" placeholder={this.state.completeName.toUpperCase()} readOnly />
                                                </div>
                                            </div>
                                            <div className="form-group row m-b-15">
                                                <label className="col-form-label col-md-3">Status Select</label>
                                                <div className="col-md-9">
                                                    <select className="form-control" onChange={this.onChangeSelectEmploymentStatus}>
                                                        <option disabled>--select status--</option>
                                                        <option value="Hired">Hired</option>
                                                        <option value="Separeted">Separeted</option>
                                                        <option value="Resigned">Resigned</option>
                                                        <option value="Terminated">Terminated</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <button className="btttn bg-green-transparent-9 pull-right  fas fa-check-circle m-r-5" aria-label="Close" >
                                                &nbsp;Update
                                </button>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-modal-ADDApprover" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5><b>Select Approver</b></h5>
                                        <button type="button" className="close" data-dismiss="modal"></button>
                                    </div>

                                    <div className="modal-body" style={{ fontSize: 12 }}>
                                        <Scrollbars style={{ height: 400 }}>
                                            <table className="table table-td-valign-middle">
                                                <thead className="tableFixHead">
                                                    <tr>
                                                        <th>

                                                        </th>
                                                        <th>
                                                            Name
                                            </th>
                                                        <th>
                                                            Company
                                            </th>
                                                        <th>
                                                            Branch
                                            </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.userData.map((val, index) => {
                                                        var match1 = false
                                                        if (this.state.name_hierarchy.length != 0) {
                                                            for (let index = 0; index < this.state.name_hierarchy[this.state.indexVal].approver.length; index++) {
                                                                if (val.user_id == this.state.name_hierarchy[this.state.indexVal].approver[index].id) {

                                                                    match1 = true;
                                                                }

                                                            }
                                                        }
                                                        if (match1 == false)
                                                            return <tr>
                                                                <td>
                                                                    <input type="checkbox" className="checkbox" onClick={() => {
                                                                        var match = false
                                                                        for (let index = 0; index < this.state.name_hierarchy1.length; index++) {
                                                                            var title = this.state.name_hierarchy1[index].title
                                                                            if (title == "") {
                                                                                match = true
                                                                            } if (this.state.name_hierarchy1.length == 0) {
                                                                                match = true
                                                                            } if (this.state.hierarchy_name == "") {
                                                                                match = true
                                                                            }


                                                                        }
                                                                        if (match == true) {


                                                                        } else {

                                                                            this.setState({ modal: "modal", modalPath: "#demo-lg-modal-Review" }); this.review()
                                                                        }



                                                                        this.setState({ check_status: !this.state.check_status })
                                                                        var match = false;
                                                                        for (let index = 0; index < this.state.name_hierarchy1.length; index++) {
                                                                            if (val.user_id == this.state.name_hierarchy1[index].id) {
                                                                                this.state.name_hierarchy1.splice(parseInt(index), 1);
                                                                                match = true;
                                                                            }

                                                                        }
                                                                        if (match == false) {
                                                                            var data = []
                                                                            data = { id: val.user_id, cloud_id: val.user_device_id, name: val.completename }
                                                                            this.state.name_hierarchy1.push(data)
                                                                        }
                                                                    }} />
                                                                </td>
                                                                <td>
                                                                    <p>{val.completename.toUpperCase()}</p>
                                                                </td>
                                                                <td>
                                                                    <p>{val.company_name}</p>
                                                                </td>
                                                                <td>
                                                                    <p>{val.branch_name}</p>
                                                                </td>

                                                            </tr>
                                                    })}

                                                </tbody>
                                            </table>
                                        </Scrollbars>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btttn fas fa-user-check  bg-green-transparent-9 pull-right" data-dismiss="modal" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal" onClick={() => {
                                            for (let index = 0; index < this.state.name_hierarchy1.length; index++) {
                                                this.state.name_hierarchy[this.state.indexVal].approver.push(this.state.name_hierarchy1[index]);
                                                this.setState({ refresh: true })
                                            }
                                        }}>Select Approver
                                </button>
                                        <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" data-dismiss="modal" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal">
                                            &nbsp;Cancel
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-modal-Review" className="modal  w3-animate-zoom" tabindex="-1">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5><b>Hierarchy Review</b></h5>
                                        <button type="button" className="close" data-dismiss="modal"></button>
                                    </div>
                                    {/* <div className="modal-header">
                                <div className="modal-title" id="exampleModalCenterTitle">Delete Record</div>
                            </div> */}
                                    <div className="modal-body" style={{ fontSize: 12 }}>
                                        <Scrollbars style={{ height: 400 }}>
                                            {this.state.type_details.map((val, index) => {

                                                return <div className="solid" key={index} style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-sm-12  w3-animate-left">
                                                            <p style={{ fontSize: "15px", color: "#353b48" }}><b>{val.type}</b></p>
                                                        </div>
                                                    </div>
                                                    <div className="row m-l-10">
                                                        <div className="col-sm-12  w3-animate-left">
                                                            <p className='m-l-20'>
                                                                {
                                                                    this.state.name_hierarchy.map((val1, index1) => {

                                                                        var approver = []
                                                                        var title = ""
                                                                        var JSON_type = (val1.types)
                                                                        var level = 0
                                                                        for (let k = 0; k < JSON_type.length; k++) {
                                                                            if (val.type == JSON_type[k]) {
                                                                                approver = val1.approver
                                                                                title = val1.title
                                                                                level = index1 + 1
                                                                            }

                                                                        }
                                                                        return <div>
                                                                            <div><span style={{ fontSize: "14px", color: "rgb(15, 158, 62)", marginTop: '10' }}>
                                                                                <span style={{ fontSize: "12px", color: 'black' }}></span> <b>{title}</b>
                                                                            </span>
                                                                                {approver.map((val2, index2) => {


                                                                                    return <div> <br />
                                                                                        <span className='m-l-40' style={{ fontSize: "12px", color: "#353b48", marginTop: '10' }}>
                                                                                            <i className='fa fa-user-alt'></i> <b>{val2.name.toUpperCase()}</b>
                                                                                        </span>
                                                                                    </div>

                                                                                })

                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    })}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </Scrollbars>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-save" data-dismiss="modal" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal" onClick={() => {
                                            this.SaveHierarchy(this.state.name_hierarchy, "Insert")
                                        }}>
                                            &nbsp;Save&nbsp;Hierarchy
                                </button>
                                        <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal">
                                            &nbsp;Cancel
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-modal-totalleave" className="modal w3-animate-zoom" tabindex="-1" data-backdrop="static">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Update Total Leave with Pay</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" />
                                    </div>
                                    <div className="modal-body">
                                        <form className="form-group m-r-10" onSubmit={this.onSubmitLeave}>
                                            <label>Number of Leave with Pay:</label>
                                            <input onChange={this.textchange} name="leavecount" value={this.state.leavecount} type="number" className="form-control m-l-5" placeholder="Enter count of leave with pay" />
                                            <div className="pull-right">
                                                <button className="btttn bg-green-transparent-9 fas fa-save m-15">
                                                    &nbsp;Save
                                            </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-picture" className="modal w3-animate-zoom" tabindex="-1" data-backdrop="static">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Upload Picture</h4>
                                        <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" data-target="#demo-lg-modal5" data-toggle="modal" />
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={this.submitSingle}>
                                            <div className="form-group">
                                                <label>Upload Single:</label>
                                                <input className="form-control" type="file" id="fileSingle" name="h_single" onChange={this.uploadSingle} ></input>
                                            </div>
                                            <button type="submit" className="btttn bg-green-transparent-9 fas fa-check-circle">&nbsp;Upload</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-modal-DELETE" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Delete User</h4>
                                        {/* <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button> */}
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want delete this record?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" onClick={() => this.DeleteUser()} data-dismiss="modal" aria-label="Close" >
                                            &nbsp;Delete
                                </button>
                                        <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                            &nbsp;Cancel
                                </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-lg-filter" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Filter Attendance</h4>
                                        <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Company Filter</label>
                                            <select className="form-control" onChange={this.filterCompany} name="selectedCompany">
                                                <option value=" " name="All">All</option>
                                                {this.state.companies.map((val, index) =>
                                                    <option value={val.company_id} name={val.company_name}>{val.company_name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Branch Filter</label>
                                            <select className="form-control" onChange={this.filterBranch} name="selectedBranch">
                                                <option value="" name="All">All</option>
                                                {this.state.branches.map((val, index) =>
                                                    <option value={val.bname} name={val.bname}>{val.bname}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Status Filter</label>
                                            <select className="form-control" onChange={this.filterStatus} name="selectedFilter">
                                                <option value="" name="All">All</option>
                                                <option value="Active" name="">Active</option>
                                                <option value="Terminated" name="">Terminated</option>
                                                <option value="Inactive" name="">Inactive</option>
                                                <option value="AWOL" name="">AWOL</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Position Filter</label>
                                            <select className="form-control" onChange={this.filterPosition} name="selectedPosition">
                                                <option value="" name="All">All</option>
                                                {this.state.positions.map((val, index) =>
                                                    <option value={val.user_jobposition} name={val.user_jobposition}>{val.userjob_position.toUpperCase()}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >

        );
    }
}

export default Users;