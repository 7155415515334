import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
// import ReactExport from "react-data-export";
import readXlsxFile from 'read-excel-file';


class Retro extends Component {
    constructor(props){
        super(props);
        this.state = {
          file:[],
		

        }
    }
    componentDidMount(){
        alert("retro!")
    }
 

    render() { 
      
        return ( 
             <div id="content" className="content">
                  
             </div>
         );
    }
}
 
export default Retro;