import React, { Component } from 'react';
import {    
    HashRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";

import Home from './Home'

class Routes extends Component {
    state = {  }
    render() { 
        return ( 
          <Router>
              <div>
                <Route path="/anlz/home" component={Home}/>
              </div>
          </Router>
         );
    }
}
 
export default Routes;