import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import { Redirect } from 'react-router-dom';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
// import pockethr from './img/pockethr.png'
class DarProvacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            searchDriver: [],
            companies: [],
            branches: [],
            branch_selected: '',
            employee: [],
            employee_selected: [],
            validator: [],
            validator_selected: [],
            company_selected:''
        };
    }
    getCompanies() {

        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
            })
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, employee: [] })
        setTimeout(() => { this.getBranch() }, 200)

    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {

                this.setState({ branches: response.data, branch_selected: "Select Branch" })

            })
    }
    showBYbranch = e => {
        e.preventDefault();
        this.setState({ branch_selected: e.target.value })
        this.fetchEmployee(e.target.value)
    }
    fetchEmployee(branch) {
        getData("humanResource/getUsers_branch", { branch: branch })
            .then(response => {
                console.log(response);
                this.setState({ employee: response.response })
            })
    }
    showEmployee = (e) => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                if (options[i].value == 'ALL') {
                    for (let i = 0; i < this.state.employee.length; i++) {
                        value.push(this.state.employee[i].user_id);
                    }
                    // value = this.state.employee
                } else {
                    value.push(options[i].value);

                }
                console.log(value)
            }
        }
        this.setState({ employee_selected: value })
    }
    showValidator = (e) => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                if (options[i].value == 'ALL') {
                    for (let i = 0; i < this.state.userData.length; i++) {
                        value.push(this.state.userData[i].user_id);
                    }
                    // value = this.state.validator
                } else {
                    value.push(options[i].value);

                }
                console.log(value)
            }
        }
        this.setState({ validator_selected: value })
    }
    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                this.setState({ userData: response.users })
            })
    }
    componentDidMount = () => {
        this.getCompanies();
        this.getUser();
    }
    onSubmit =(e)=>{
    e.preventDefault();
    let validator = [];
    for (let index = 0; index <  this.state.validator_selected .length; index++) {
        const validator_data = {
            component:"DAR",user_id:this.state.validator_selected[index]
        }
        validator.push(validator_data);
    }
    getData("users/insert_privacy", { validator: JSON.stringify(validator),employee:this.state.employee_selected})
    .then(response => {
    console.log(response);
    if(response.response == true){
        alert('Privacy has been applied.')
        this.setState({branches:[],employee:[],company_selected:[]})
    }

        // this.setState({ userData: response.users })
    })
   
    
    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        // let EmployeeSearch = this.state.userData.filter(
        //     (files) => {
        //         return files.completename.toLowerCase().indexOf(
        //             this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.branch_name.toLowerCase().indexOf(
        //                 this.state.searchDriver.toLocaleLowerCase()) !== -1
        //     }
        // )
        return (

            <div >
                <div className='panel-heading'>
                    <h4>Dar Privacy</h4>
                    <hr />
                    <h5><i>This allow users to choose the validator of the employee's daily accomplisment reports.</i></h5>
                </div>
                <div className='panel-body'>
                    <form onSubmit={this.onSubmit}>
                        <div className='row'>
                            <div className='col-md-3'>
                                <label><b>Choose Company</b></label>
                            </div>
                            <div className='col-md-5'>
                                <select className="form-control" onChange={this.showBYcompanies} value={this.state.company_selected} required>
                                    <option ></option>
                                    {this.state.companies.map((val, index) =>
                                        <option value={val.company_id}>{val.company_name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: 20 }}>
                            <div className='col-md-3'>
                                <label><b>Choose Branch</b></label>
                            </div>
                            <div className='col-md-5'>
                                <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                    <option>--Select Branch--</option>
                                    <option></option>
                                    {this.state.branches.map((branch, index) =>
                                        <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: 20 }}>
                            <div className='col-md-3'>
                                <label><b>Choose Employee</b></label>
                            </div>
                            <div className='col-md-5'>
                                <select className="form-control" style={{ height: 150 }} onChange={this.showEmployee} multiple required>
                                    <option value="ALL">--Select All--</option>
                                    <option></option>

                                    {this.state.employee.map((val, index) =>
                                        <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: 20 }}>
                            <div className='col-md-3'>
                                <label><b>Choose Validator</b></label>
                            </div>
                            <div className='col-md-5'>
                                <select className="form-control" style={{ height: 150 }} onChange={this.showValidator} multiple required>
                                    <option>--Remove--</option>
                                    <option></option>
                                    <option value="ALL">--Select All--</option>
                                    <option></option>

                                    {this.state.userData.map((val, index) =>
                                        <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className='row'  style={{ marginTop: 25 }}>
                        <div className='col-md-3'>
                             
                            </div>
                            <div className='col-md-5 '>
                            <button type='submit' className=" pull-right btttn bg-green-transparent-9  m-b-10 fas fa-plus-circle " >&nbsp;Submit</button>

                            </div>

                        </div>
                    </form>

                </div>
            </div>

        );
    }
}

export default DarProvacy;