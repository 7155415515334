import React, { Component } from 'react';
import axios, { post } from 'axios';
class Cookies extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div class="alert bg-black-lighter text-white position-fixed rounded-0 position-fixed mb-0 d-md-flex align-items-center pl-xl-5 pr-xl-5 pt-xl-4 pt-4 pb-xl-4" style={{left: 0, right: 0 , bottom: 0, zIndex: 2920}}>
                <div class="flex-fill">
                    <h4>This website uses cookies</h4>
                    <p class="mb-0">
                        We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
  </p>
                </div>
                <div class="pl-xl-4 pt-xl-0 pt-3">
                    <a href="javascript" class="btn btn-outline-white btn-lg width-150" data-dismiss="alert" onClick={()=>{localStorage.setItem("cookies", 0)}}>Accept</a>
                </div>
            </div>

        );
    }
}
export default Cookies;