import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect, Link } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { connect } from 'react-redux'
import { LoadingOverlay, Loader } from 'react-overlay-loader';
// import PAGE from'./employeeDetails'

// import console = require('console');
class PayrollDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payrollDetails: [],
            earning: [],
            responsedata: [],
            deduction: [],
            payroll_details_id: '',
            firstCutoff: '',
            philH_EE: '',
            loading: false
        }
        this.firstCutoff = this.firstCutoff.bind(this)
        this.textchange = this.textchange.bind(this)
        this.updatePHILHEALTH = this.updatePHILHEALTH.bind(this)

    }
    // componentDidMount = () => {
    //     // JSON.parse()
    //     const details = JSON.parse(localStorage.getItem('payDetails'))
    //     const earnings = JSON.parse(localStorage.getItem('earnings'))
    //     const responsedata = JSON.parse(localStorage.getItem('responsedata'))
    //     const deduction = JSON.parse(localStorage.getItem('deduction'))
    //     console.log(earnings)
    //     console.log(responsedata)

    //     this.setState({
    //         payrollDetails: details,
    //         earning: earnings,
    //         responsedata: responsedata,
    //         deduction: deduction
    //     })
    // }
    firstCutoff(e) {
        e.preventDefault()
        this.setState({ loading: true })
        console.log(this.state.payroll_details_id);

        getData('humanResource/payroll_update', { payroll_details_id: this.state.payroll_details_id, data: { prev_total_earning: this.state.firstCutoff } })
            .then((response) => {
                $('#modal_cut_off').click()
                console.log(response);
                this.payroll_data();

                this.setState({ refresh: true })

            })
    }
    updatePHILHEALTH(e) {
        e.preventDefault()
        this.setState({ loading: true })
        getData('humanResource/PHILHEALTH_update', { payroll_details_id: this.state.payroll_details_id, amount: this.state.philH_EE })
            .then((response) => {
                this.payroll_data();
                this.setState({ refresh: true })
                $('#close_modal_phil').click()

            })
    }
    payroll_data = () => {

        getData("humanResource/getPayroll_details_user", { user_id: localStorage.getItem("u"), pay_id: this.props.payDetails.pid, payroll_details_id: this.state.payroll_details_id })
            .then(response => {
                console.log(response);

                const sssDataTable = response.sss_data;
                const annual_tax_data = response.annual_tax_data;
                var Listemp = [];
                var deduction = [];
                var earning = [];
                let gov_conti = []

                for (let i = 0; i < response.data.length; i++) {
                    console.log(response.data[i]);

                    if (response.data[i].data_schema['timesheet']['ot'].length != 0) {


                        var totalDeduction = 0;
                        var totalEarning = 0;
                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'].length; j++) {

                            var match = false;
                            for (let k = 0; k < deduction.length; k++) {
                                if (deduction[k].deduction_name == response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'][j]) {
                                    match = true;
                                }
                            }
                            var dudect_name = [];
                            if (match == false) {
                                dudect_name = { deduction_name: response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'][j] }
                                deduction.push(dudect_name);
                            }

                        }

                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_name'].length; j++) {

                            var match = false;
                            for (let k = 0; k < earning.length; k++) {
                                if (earning[k].earning_name == response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j]) {
                                    match = true;
                                }
                            }
                            var earn_name = [];
                            if (match == false) {
                                earn_name = { earning_name: response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j] }
                                earning.push(earn_name);
                            }

                        }
                        var valuecolde = deduction.length + 23;
                        var valuecol = earning.length + 38;
                        this.setState({ colspan_earning: valuecol, colspan_deduction: valuecolde })

                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'].length; j++) {
                            totalDeduction += parseFloat(response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'][j]);

                        }
                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
                            totalEarning += parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
                        }
                        // totalEarning += parseFloat(response.data[i].data_schema['timesheet']['basic_amount_additional']);
                        totalDeduction += parseFloat(response.data[i].data_schema['timesheet']['basic_amount_deduction']);

                        var details = [];
                        var firstCUT_off = 0;
                        var secondCUT_off = 0;
                        var totalCUT_off = 0;
                        var SSS_CONTRIBUTION = 0;
                        var SSS_EC = 0;
                        var SSS_ER = 0;
                        var SSS_EE = 0;



                        let Allowance = 0;
                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
                            // if (response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j].toUpperCase() == ("Allowance").toUpperCase()) {
                            Allowance = parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
                            // }


                        }

                        if (response.data[i].government_contribution == 1) {
                            // let total_gross = parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])

                            // console.log(Allowance);
                            let totalEarning_sss = (parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + Allowance + parseFloat(response.data[i].data_schema['timesheet']['total_earning']))
                            console.log(totalEarning_sss)
                            const sssContributionData = sssDataTable.filter((val) => {
                                if (parseFloat(val.rangefrom) <= totalEarning_sss && parseFloat(val.rangeto) >= totalEarning_sss) {
                                    return val
                                }
                            }
                            )
                            let sss = 0;
                            for (let i = 0; i < sssContributionData.length; i++) {
                                sss = sssContributionData[i].sss_conti_EE;
                            }
                            let u_id_data = response.data[i].data_schema['user_id'],
                                late_data = response.data[i].data_schema['timesheet']['totalLate_Rate'],
                                Phil_health = (parseFloat(response.data[i].data_schema['timesheet']['salary_rate']) * 0.03) / 2,
                                Pag_Ibig = 100;

                            if ((parseFloat(response.data[i].data_schema['timesheet']['salary_rate'])) <= 10000) {
                                Phil_health = 150.00;
                            }
                            let Gross = (totalEarning_sss - (parseFloat(sss) + Phil_health + Pag_Ibig + parseFloat(late_data)));
                            let excess = 0;
                            let multiplier = 1;
                            let additional = 0;
                            let amount_val = 0;

                            const annual_tax = annual_tax_data.filter((val) => {
                                if (parseFloat(val.range_from) < Gross && parseFloat(val.range_to) >= Gross) {
                                    return val
                                }
                            }
                            )
                            for (let i = 0; i < annual_tax.length; i++) {
                                excess = parseFloat(annual_tax[i].range_from);
                                multiplier = parseFloat(annual_tax[i].multiplier);
                                additional = parseFloat(annual_tax[i].additional);
                            }
                            if (annual_tax.length > 0) {
                                amount_val = (((Gross - excess) * multiplier) + additional);
                            }

                            console.log(amount_val)

                            // getData("humanResource/getSSScontribution", { salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate']), p_id: response.data[i].pid, late: response.data[i].data_schema['timesheet']['totalLate_Rate'], user_id: response.data[i].data_schema['user_id'], totalEarnings: (parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + Allowance + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])) })
                            //     .then(sss_data => {

                            var PHILHEALTH = 0;
                            var PHILHEALTH_EE = 0;
                            var rate2 = 0;
                            var totalDeduction = 0;
                            var totalEarning = 0;

                            var sunday = "";
                            var sunday_ot = "";
                            var sunday_ot_rate = "";
                            var lateHours = "";
                            var lateHours_Rate = "";
                            var amount2 = "";
                            var regularHoliday = "";
                            var specialHoliday = "";
                            var sunday_amount = "";
                            var regularHoliday_amount = "";
                            var specialHoliday_amount = "";
                            var totalRegularOT = "";
                            var totalRegularOT_Rate = "";
                            var totalRegualar_Hol_OT_Rate = "";
                            var totalRegualar_Hol_OT = "";
                            var totalSpecial_Hol_OT = "";
                            var totalSpecial_Hol_OT_Rate = "";
                            var totalUndertime_hours = "";
                            var totalUndertime_amount = "";
                            var leave_w_pay_amount = "";
                            var leave_w_pay_days = "";
                            var holding_tax = "";
                            var allowance_amount = "";
                            var double_book_amount = "";
                            var double_book = "";
                            var jo_number = "";
                            var jo_total_amount = "";
                            var retro_ot = "";
                            var retro_ot_amount = "";
                            var basic_amount_additional = "";
                            var basic_amount_deduction = "";
                            var basic_hours_additional = "";
                            var basic_hours_deduction = "";
                            var total_night_defferential_hours = "";
                            var total_night_defferential_amount = "";

                            if (response.data[i].data_schema['timesheet']['total_night_defferential_hours'] != 0) {
                                total_night_defferential_hours = response.data[i].data_schema['timesheet']['total_night_defferential_hours'];
                                total_night_defferential_amount = response.data[i].data_schema['timesheet']['total_night_defferential_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['basic_hours_additional'] != 0) {
                                basic_hours_additional = response.data[i].data_schema['timesheet']['basic_hours_additional'];
                                basic_amount_additional = response.data[i].data_schema['timesheet']['basic_amount_additional'];
                            }
                            if (response.data[i].data_schema['timesheet']['retrobasic_hours_deduction_ot'] != 0) {
                                basic_hours_deduction = response.data[i].data_schema['timesheet']['basic_hours_deduction'];
                                basic_amount_deduction = response.data[i].data_schema['timesheet']['basic_amount_deduction'];
                            }
                            if (response.data[i].data_schema['timesheet']['retro_ot'] != 0) {
                                retro_ot = response.data[i].data_schema['timesheet']['retro_ot'];
                                retro_ot_amount = response.data[i].data_schema['timesheet']['retro_ot_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book_amount'] != 0) {
                                double_book_amount = response.data[i].data_schema['timesheet']['double_book_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book'] != 0) {
                                double_book = response.data[i].data_schema['timesheet']['double_book'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_number'] != 0) {
                                jo_number = response.data[i].data_schema['timesheet']['jo_number'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_total_amount'] != 0) {
                                jo_total_amount = response.data[i].data_schema['timesheet']['jo_total_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['allowance_amount'] != 0) {
                                allowance_amount = response.data[i].data_schema['timesheet']['allowance_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['sunday'] != 0) {
                                sunday = response.data[i].data_schema['timesheet']['sunday'];
                            }
                            if (amount_val != 0) {
                                holding_tax = parseFloat(amount_val).toFixed(2);
                                totalDeduction += amount_val;
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate'] != 0) {
                                lateHours = response.data[i].data_schema['timesheet']['totalLate'];
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate_Rate'] != 0) {
                                lateHours_Rate = response.data[i].data_schema['timesheet']['totalLate_Rate'];
                            }
                            if (response.data[i].data_schema['timesheet']['amount'] != 0) {
                                amount2 = response.data[i].data_schema['timesheet']['amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHoliday'] != 0) {
                                regularHoliday = response.data[i].data_schema['timesheet']['regularHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHoliday'] != 0) {
                                specialHoliday = response.data[i].data_schema['timesheet']['specialHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['sunday_amount'] != 0) {
                                sunday_amount = response.data[i].data_schema['timesheet']['sunday_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHol_amount'] != 0) {
                                regularHoliday_amount = response.data[i].data_schema['timesheet']['regularHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHol_amount'] != 0) {
                                specialHoliday_amount = response.data[i].data_schema['timesheet']['specialHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot != 0) {
                                totalRegularOT = response.data[i].data_schema['timesheet']['ot'][0].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot != 0) {
                                sunday_ot = response.data[i].data_schema['timesheet']['ot'][3].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate != 0) {
                                sunday_ot_rate = response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate != 0) {
                                totalRegularOT_Rate = response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate != 0) {
                                totalRegualar_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot != 0) {
                                totalRegualar_Hol_OT = response.data[i].data_schema['timesheet']['ot'][1].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate != 0) {
                                totalSpecial_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeHours'] != 0) {
                                totalUndertime_hours = response.data[i].data_schema['timesheet']['undertimeHours'];
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeAmount'] != 0) {
                                totalUndertime_amount = response.data[i].data_schema['timesheet']['undertimeAmount'];
                            }

                            for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'].length; j++) {
                                totalDeduction += parseFloat(response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'][j]);

                            }
                            for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
                                totalEarning += parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
                            }
                            // if(response.data[i].deduction.length != 0){
                            //     for (let j = 0; j < response.data[i].deduction['deduction_amount'].length; j++) {
                            //         totalDeduction += parseFloat(response.data[i].deduction['deduction_amount'][j]);

                            //     }
                            // }
                            // if(response.data[i].earning.length != 0){
                            //     for (let j = 0; j < response.data[i].earning['earning_amount'].length; j++) {
                            //         totalEarning += parseFloat(response.data[i].earning['earning_amount'][j])
                            //     }
                            // }



                            if (response.data[i].data_schema['timesheet']['type_rate'] == "Monthly") {
                                rate2 = parseFloat(response.data[i].data_schema['timesheet']['salary_rate']);
                            } else if (response.data[i].data_schema['timesheet']['type_rate'] == "Daily") {
                                rate2 = (parseFloat(response.data[i].data_schema['timesheet']['salary_rate']) * 313) / 12;
                            }

                            if (rate2 <= 10000 && rate2 > 0) {
                                PHILHEALTH = 150.00
                            } else {
                                PHILHEALTH = ((rate2 * 0.03) / 2).toFixed(2)
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDay'] != 0) {
                                leave_w_pay_days = response.data[i].data_schema['timesheet']['leaveDay'];
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDayAmount'] != 0) {
                                leave_w_pay_amount = parseFloat(response.data[i].data_schema['timesheet']['leaveDayAmount']).toFixed(2);
                            }
                            if (response.data[i].philhealth_EE != 0 && response.data[i].philhealth_EE != "") {
                                // console.log(response.data[i].philhealth_EE )

                                PHILHEALTH_EE = parseFloat(response.data[i].philhealth_EE).toFixed(2);
                            } else {
                                PHILHEALTH_EE = PHILHEALTH;
                            }

                            // console.log(firstCUT_off);
                            for (let l = 0; l < sssContributionData.length; l++) {
                                SSS_CONTRIBUTION = sssContributionData[l].sss_conti_Total;
                                SSS_EC = sssContributionData[l].ec_contri_ER;
                                SSS_ER = sssContributionData[l].sss_conti_ER;
                                SSS_EE = sssContributionData[l].sss_conti_EE;

                                // SSS_CONTRIBUTION = sss_data[l].sss_conti_Total;
                                // SSS_EC = sss_data[l].ec_contri_ER;
                                // SSS_ER = sss_data[l].total_conti_ER;
                                // SSS_EE = sss_data[l].sss_conti_EE;


                            }

                            gov_conti.push({
                                user_id: u_id_data,
                                SSS_CONTRIBUTION: SSS_CONTRIBUTION,
                                SSS_EC: SSS_EC,
                                SSS_ER: SSS_ER,
                                SSS_EE: SSS_EE,
                                PHILHEALTH: PHILHEALTH,
                                PHILHEALTH_EE: PHILHEALTH_EE,
                                PAG_IBIG_ER: 100,
                                PAG_IBIG_EE: 100,
                                holding_tax: holding_tax
                                // SSS:SSS_EE,
                                // PHILHEALTH:PHILHEALTH_EE,
                                // PAG_IBIG:100
                            })
                            details = {
                                basic_amount_additional: basic_amount_additional,
                                basic_amount_deduction: basic_amount_deduction,
                                basic_hours_additional: basic_hours_additional,
                                basic_hours_deduction: basic_hours_deduction,
                                retro_ot_amount: retro_ot_amount,
                                retro_ot: retro_ot,
                                pid: response.data[0].pid,
                                allowance_amount: allowance_amount,
                                allowance_earnings: parseFloat(parseFloat(Allowance) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])).toFixed(2),
                                company_name: response.data[i].company_name,
                                emp_id: response.data[i].data_schema['user_id'],
                                branch_name: response.data[i].data_schema['branch_name'],
                                emp_name: response.data[i].data_schema['user_name'],
                                emp_monthly: response.data[i].data_schema['timesheet']['type_rate'],
                                emp_salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate'].replace(/,/g, '')).toLocaleString(),
                                totalDays: response.data[i].data_schema['timesheet']['totalDay'],
                                sunday: sunday,
                                sunday_ot: sunday_ot,
                                sunday_ot_rate: sunday_ot_rate,
                                lateHours: lateHours,
                                lateHours_Rate: lateHours_Rate,
                                amount2: amount2,
                                regularHoliday: regularHoliday,
                                specialHoliday: specialHoliday,
                                sunday_amount: sunday_amount,
                                regularHoliday_amount: regularHoliday_amount,
                                specialHoliday_amount: specialHoliday_amount,
                                payroll_details_id: response.data[i].pid,
                                totalDeduction: 800 + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH_EE),

                                // totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['undertimeAmount'] + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH),
                                totalRegularOT: totalRegularOT,
                                totalRegularOT_Rate: totalRegularOT_Rate,
                                totalRegualar_Hol_OT_Rate: totalRegualar_Hol_OT_Rate,
                                totalRegualar_Hol_OT: totalRegualar_Hol_OT,

                                totalSpecial_Hol_OT: totalSpecial_Hol_OT,
                                totalSpecial_Hol_OT_Rate: totalSpecial_Hol_OT_Rate,
                                total_earning: parseFloat(response.data[i].data_schema['timesheet']['total_earning']) + parseFloat(totalEarning),

                                totalUndertime_hours: totalUndertime_hours,
                                totalUndertime_amount: totalUndertime_amount,

                                firstCUT_off: parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']),
                                secondCUT_off: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning),
                                totalCUT_off: parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning),

                                SSS_EE: SSS_EE,
                                SSS_CONTRIBUTION: SSS_CONTRIBUTION,
                                SSS_EC: SSS_EC,
                                SSS_ER: SSS_ER,

                                PHILHEALTH: PHILHEALTH,
                                PHILHEALTH_EE: PHILHEALTH_EE,

                                PAG_IBIG: 100,
                                total_net_pay: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']) + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH_EE)),

                                leave_w_pay_days: leave_w_pay_days,
                                leave_w_pay_amount: (leave_w_pay_amount),

                                holding_tax: holding_tax,

                                double_book_amount: double_book_amount,
                                double_book: double_book,
                                jo_number: jo_number,
                                jo_total_amount: jo_total_amount,
                            }
                            // localStorage.setItem('payDetails', JSON.stringify(details))
                            this.state.payrollDetails = details
                            Listemp.push(details);
                            this.props.dispatch({
                                type: 'ViewPayrollDetails',
                                payDetails: details,
                                responsedata: response.data,
                            })

                            // })


                        } else if (response.data[i].government_contribution == 0) {
                            var sunday_ot = "";
                            var sunday_ot_rate = "";
                            var sunday = "";
                            var lateHours = "";
                            var lateHours_Rate = "";
                            var amount2 = "";
                            var regularHoliday = "";
                            var specialHoliday = "";
                            var sunday_amount = "";
                            var regularHoliday_amount = "";
                            var specialHoliday_amount = "";
                            var totalRegularOT = "";
                            var totalRegularOT_Rate = "";
                            var totalRegualar_Hol_OT_Rate = "";
                            var totalRegualar_Hol_OT = "";
                            var totalSpecial_Hol_OT = "";
                            var totalSpecial_Hol_OT_Rate = "";
                            var totalUndertime_hours = "";
                            var totalUndertime_amount = "";
                            var leave_w_pay_amount = "";
                            var leave_w_pay_days = "";
                            var holding_tax = ""
                            var allowance_amount = "";
                            var double_book_amount = "";
                            var double_book = "";
                            var jo_number = "";
                            var jo_total_amount = "";
                            var retro_ot = "";
                            var retro_ot_amount = "";
                            var basic_amount_additional = "";
                            var basic_amount_deduction = "";
                            var basic_hours_additional = "";
                            var basic_hours_deduction = "";
                            var total_night_defferential_hours = "";
                            var total_night_defferential_amount = "";

                            if (response.data[i].data_schema['timesheet']['total_night_defferential_hours'] != 0) {
                                total_night_defferential_hours = response.data[i].data_schema['timesheet']['total_night_defferential_hours'];
                                total_night_defferential_amount = response.data[i].data_schema['timesheet']['total_night_defferential_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['basic_hours_additional'] != 0) {
                                basic_hours_additional = response.data[i].data_schema['timesheet']['basic_hours_additional'];
                                basic_amount_additional = response.data[i].data_schema['timesheet']['basic_amount_additional'];
                            }
                            if (response.data[i].data_schema['timesheet']['retrobasic_hours_deduction_ot'] != 0) {
                                basic_hours_deduction = response.data[i].data_schema['timesheet']['basic_hours_deduction'];
                                basic_amount_deduction = response.data[i].data_schema['timesheet']['basic_amount_deduction'];
                            }
                            if (response.data[i].data_schema['timesheet']['retro_ot'] != 0) {
                                retro_ot = response.data[i].data_schema['timesheet']['retro_ot'];
                                retro_ot_amount = response.data[i].data_schema['timesheet']['retro_ot_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book_amount'] != 0) {
                                double_book_amount = response.data[i].data_schema['timesheet']['double_book_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book'] != 0) {
                                double_book = response.data[i].data_schema['timesheet']['double_book'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_number'] != 0) {
                                jo_number = response.data[i].data_schema['timesheet']['jo_number'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_total_amount'] != 0) {
                                jo_total_amount = response.data[i].data_schema['timesheet']['jo_total_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['allowance_amount'] != 0) {
                                allowance_amount = response.data[i].data_schema['timesheet']['allowance_amount'];
                            }

                            if (response.data[i].data_schema['timesheet']['sunday'] != 0) {
                                sunday = response.data[i].data_schema['timesheet']['sunday'];
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot != 0) {
                                sunday_ot = response.data[i].data_schema['timesheet']['ot'][3].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate != 0) {
                                sunday_ot_rate = response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate'] != 0) {
                                lateHours = response.data[i].data_schema['timesheet']['totalLate'];
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate_Rate'] != 0) {
                                lateHours_Rate = response.data[i].data_schema['timesheet']['totalLate_Rate'];
                            }
                            if (response.data[i].data_schema['timesheet']['amount'] != 0) {
                                amount2 = response.data[i].data_schema['timesheet']['amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHoliday'] != 0) {
                                regularHoliday = response.data[i].data_schema['timesheet']['regularHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHoliday'] != 0) {
                                specialHoliday = response.data[i].data_schema['timesheet']['specialHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['sunday_amount'] != 0) {
                                sunday_amount = response.data[i].data_schema['timesheet']['sunday_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHol_amount'] != 0) {
                                regularHoliday_amount = response.data[i].data_schema['timesheet']['regularHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHol_amount'] != 0) {
                                specialHoliday_amount = response.data[i].data_schema['timesheet']['specialHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot != 0) {
                                totalRegularOT = response.data[i].data_schema['timesheet']['ot'][0].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate != 0) {
                                totalRegularOT_Rate = response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate != 0) {
                                totalRegualar_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot != 0) {
                                totalRegualar_Hol_OT = response.data[i].data_schema['timesheet']['ot'][1].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate != 0) {
                                totalSpecial_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeHours'] != 0) {
                                totalUndertime_hours = response.data[i].data_schema['timesheet']['undertimeHours'];
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeAmount'] != 0) {
                                totalUndertime_amount = response.data[i].data_schema['timesheet']['undertimeAmount'];
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDay'] != 0) {
                                leave_w_pay_days = response.data[i].data_schema['timesheet']['leaveDay'];
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDayAmount'] != 0) {
                                leave_w_pay_amount = parseFloat(response.data[i].data_schema['timesheet']['leaveDayAmount']).toFixed(2);
                            }

                            details = {
                                basic_amount_additional: basic_amount_additional,
                                basic_amount_deduction: basic_amount_deduction,
                                basic_hours_additional: basic_hours_additional,
                                basic_hours_deduction: basic_hours_deduction,
                                retro_ot_amount: retro_ot_amount,
                                retro_ot: retro_ot,
                                pid: response.data[0].pid,
                                allowance_amount: allowance_amount,
                                allowance_earnings: parseFloat(response.data[i].data_schema['timesheet']['total_earning']).toFixed(2),
                                company_name: response.data[i].company_name,
                                emp_id: response.data[i].data_schema['user_id'],
                                branch_name: response.data[i].data_schema['branch_name'],
                                emp_name: response.data[i].data_schema['user_name'],
                                emp_monthly: response.data[i].data_schema['timesheet']['type_rate'],
                                emp_salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate'].replace(/,/g, '')).toLocaleString(),
                                totalDays: response.data[i].data_schema['timesheet']['totalDay'],
                                sunday: sunday,
                                sunday_ot: sunday_ot,
                                sunday_ot_rate: sunday_ot_rate,
                                lateHours: lateHours,
                                lateHours_Rate: lateHours_Rate,
                                amount2: amount2,
                                regularHoliday: regularHoliday,
                                specialHoliday: specialHoliday,
                                sunday_amount: sunday_amount,
                                regularHoliday_amount: regularHoliday_amount,
                                specialHoliday_amount: specialHoliday_amount,
                                payroll_details_id: response.data[i].pid,
                                totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['totalLate_Rate'],

                                // totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['undertimeAmount'] + response.data[i].data_schema['timesheet']['totalLate_Rate'],
                                totalRegularOT: totalRegularOT,
                                totalRegularOT_Rate: totalRegularOT_Rate,
                                totalRegualar_Hol_OT_Rate: totalRegualar_Hol_OT_Rate,
                                totalRegualar_Hol_OT: totalRegualar_Hol_OT,

                                totalSpecial_Hol_OT: totalSpecial_Hol_OT,
                                totalSpecial_Hol_OT_Rate: totalSpecial_Hol_OT_Rate,
                                total_earning: parseFloat(parseFloat(response.data[i].data_schema['timesheet']['total_earning']) + parseFloat(totalEarning)).toFixed(2),

                                totalUndertime_hours: totalUndertime_hours,
                                totalUndertime_amount: totalUndertime_amount,

                                firstCUT_off: "",
                                secondCUT_off: "",
                                totalCUT_off: "",

                                SSS_EE: "",
                                SSS_CONTRIBUTION: "",
                                SSS_EC: "",
                                SSS_ER: "",

                                PHILHEALTH: "",
                                PHILHEALTH_EE: "",

                                PAG_IBIG: "",
                                total_net_pay: (parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']))).toFixed(2),

                                leave_w_pay_days: leave_w_pay_days,
                                leave_w_pay_amount: (leave_w_pay_amount),

                                holding_tax: holding_tax,

                                double_book_amount: double_book_amount,
                                double_book: double_book,
                                jo_number: jo_number,
                                jo_total_amount: jo_total_amount,
                            }
                            console.log(details);
                            this.state.payrollDetails = details
                            Listemp.push(details);
                            // localStorage.setItem('payDetails', JSON.stringify(details))
                            this.props.dispatch({
                                type: 'ViewPayrollDetails',
                                payDetails: details,
                                responsedata: response.data,
                            })
                        }
                    }
                }
                let field = 'emp_name';

                //    let data_details = Listemp.sort(function(a, b){
                //         return a.firstname === b.firstname ? 0 : a.firstname < b.firstname ? -1 : 1;
                //     })

                setTimeout(() => {
                    // Listemp.sort((a, b) => {
                    //     return a.emp_name > b.emp_name;
                    // });  

                    this.props.dispatch({
                        type: 'UpdateDeductionEarnings',
                        deduction: deduction,
                        earning: earning,
                    })

                    this.setState({ loading: false })
                    this.TotalEarnings(Listemp);
                }, 1000)
                // localStorage.setItem('MonthPeriod', data.date_from + '--' + data.date_to)
                console.log(gov_conti);

                getData("humanResource/updateGov", { gov_conti: gov_conti, pay_id: response.data[0].pid, })
                    .then(response => {
                        console.log(response);
                    })
            })
    }
    TotalEarnings(earnings) {
        getData("humanResource/insertEarnings", { data: earnings })
            .then(response => {
                //
            })
    }
    textchange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    formatNumber(num) {
        let num2 = parseFloat(num).toFixed(2);
        return num2.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }



    render() {
        console.log(this.props.payDetails)
        const details = this.props.payDetails
        if (this.state.redirect == true) {
            return <Redirect to='/payrollAdjustment' />
        }

        return (
            <>
                {/* {this.state.loading == true &&
                    <Loader loading />
                } */}
                <div id="content " className="content" style={{ paddingLeft: 30, paddingRight: 20 }}>

                    <div className="panel" >
                        <div className="panel-heading">
                            <div className="navtitle panel-title">
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <h4 className="page-header"><i ></i>&nbsp;Payroll Overview</h4>

                                </div>
                                {/* <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Basic</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Holidays</a></li>
                                        }
                                    </ol>
                                </nav> */}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12' style={{ paddingLeft: 50, paddingRight: 50 }}>
                                <button className="btttn  bg-blue-transparent-9 pull-right" data-target='#attendance_summary_modal' data-toggle='modal' onClick={() => {

                                }}>Attendance Summary
                                </button>
                            </div>
                        </div>
                        <div className='panel-body' style={{ paddingLeft: 50, paddingRight: 50 }}>
                            <div className='row box-my-shadow' style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <div className='col-md-3 border-right' >
                                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                                        <h5>{String(details.emp_name).toUpperCase()}</h5>
                                        <label style={{ color: '#1e90ff', fontWeight: 'bold' }}>Employee Name</label>
                                    </div>
                                </div>
                                <div className='col-md-3 border-right' >
                                    <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                        <label style={{ color: '#192a56', fontWeight: 'bold' }}>Rate</label>
                                        <h3 style={{ alignSelf: 'center' }}>{details.emp_monthly} Rate</h3>

                                    </div>
                                </div>
                                <div className='col-md-3 border-right' >
                                    <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                        <label style={{ color: '#192a56', fontWeight: 'bold' }}>Net Amount Paid</label>
                                        <h3 style={{ alignSelf: 'center' }}>{this.formatNumber(parseFloat(details.total_net_pay).toFixed(2))}</h3>

                                    </div>
                                </div>
                                <div className='col-md-3 border-right' >
                                    <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                        <label style={{ color: '#192a56', fontWeight: 'bold' }}>Payroll Date</label>
                                        <h5 style={{ alignSelf: 'center', alignSelf: 'center' }}>{localStorage.getItem('MonthPeriod')}</h5>
                                        {/* <h5 style={{ alignSelf: 'center', fontWeight: 'bold', alignSelf: 'center' }}>2020</h5> */}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='row' >
                                <div className='col-md-12 row-width'>
                                    <div className='row '   >
                                        <div style={{ marginLeft: 10, marginTop: 20 }}>
                                            <h4>Earnings</h4>

                                        </div>
                                        <div style={{ overflowY: 'scroll', width: '100%', maxHeight: 500 }}>
                                            <table className="table table-td-valign-middle table-bordered remove-last-row" style={{ position: "relative" }}>

                                                <thead>
                                                    <tr >
                                                        <th width='50%'>Name</th>
                                                        <th width='25%'>Days / Hours</th>
                                                        <th width='25%'>Amount</th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    <tr>
                                                        <td><b>BASIC</b></td>
                                                        <td>{details.totalDays}</td>
                                                        <td>{this.formatNumber(details.amount2)}</td>
                                                    </tr>
                                                    {details.basic_amount_additional != 0 && details.basic_amount_additional != "" ?
                                                        <tr>
                                                            <td><b>BASIC ADDITIONAL ADJUSTMENT</b></td>
                                                            <td>{details.basic_hours_additional / 8}</td>
                                                            <td>{this.formatNumber(details.basic_amount_additional)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.leave_w_pay_days != 0 && details.leave_w_pay_days != "" ?
                                                        <tr>
                                                            <td><b>LEAVE WITH PAY</b></td>
                                                            <td>{details.leave_w_pay_days}</td>
                                                            <td>{this.formatNumber(details.leave_w_pay_amount)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.total_night_defferential_amount != undefined && details.total_night_defferential_amount != 0 && details.total_night_defferential_amount != "" ?
                                                        <tr>
                                                            <td><b>NIGHT DEFFERENTIAL</b></td>
                                                            <td>{details.total_night_defferential_hours} &nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.total_night_defferential_amount)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.totalRegularOT != 0 && details.totalRegularOT != "" ?
                                                        <tr>
                                                            <td><b>OVERTIME</b></td>
                                                            <td>{details.totalRegularOT} &nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.totalRegularOT_Rate)}</td>
                                                        </tr> : undefined
                                                    }


                                                    {details.sunday != 0 && details.sunday != "" ?
                                                        < tr >
                                                            <td><b>REST DAY</b></td>
                                                            <td>{details.sunday/8} &nbsp;<span></span></td>
                                                            <td>{this.formatNumber(details.sunday_amount)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.sunday_ot != 0 && details.sunday_ot != "" ?
                                                        <tr>
                                                            <td><b>REST OVERTIME</b></td>
                                                            <td>{details.sunday_ot}&nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.sunday_ot_rate)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.specialHoliday != 0 && details.specialHoliday != "" ?
                                                        <tr>
                                                            <td><b>SPECIAL HOLIDAY</b></td>
                                                            <td>{details.specialHoliday} &nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.specialHoliday_amount)}</td>
                                                        </tr> : undefined
                                                    }

                                                    {details.totalSpecial_Hol_OT != 0 && details.totalSpecial_Hol_OT != "" ?
                                                        <tr>
                                                            <td><b>SPEACIAL HOLIDAY W/ OVERTIME PER HR</b></td>
                                                            <td>{details.totalSpecial_Hol_OT} &nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.totalSpecial_Hol_OT_Rate)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.regularHoliday != 0 && details.regularHoliday != "" ?
                                                        <tr>
                                                            <td><b>REGULAR HOLIDAY</b></td>
                                                            <td>{details.regularHoliday} &nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.regularHoliday_amount)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.totalRegualar_Hol_OT != 0 && details.totalRegualar_Hol_OT != "" ?
                                                        <tr>
                                                            <td><b>REGULAR HOLIDAY W/ OVERTIME PER HR</b></td>
                                                            <td>{details.totalRegualar_Hol_OT} &nbsp;<span>Hours</span></td>
                                                            <td>{this.formatNumber(details.totalRegualar_Hol_OT_Rate)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.allowance_amount != 0 && details.allowance_amount != "" ?
                                                        <tr>
                                                            <td><b>ALLOWANCE</b></td>
                                                            <td></td>
                                                            <td>{this.formatNumber(details.allowance_amount)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {this.props.earning.map((valEarn, index1) => {
                                                        console.log(valEarn)
                                                        var value = 0;
                                                        {
                                                            this.props.responsedata.map((valRes) => {
                                                                if (valRes.user_id == details.emp_id) {
                                                                    for (let i = 0; i < valRes.data_schema['earning_deduction']['earning']['earning_name'].length; i++) {

                                                                        if (valEarn.earning_name == valRes.data_schema['earning_deduction']['earning']['earning_name'][i]) {
                                                                            value = valRes.data_schema['earning_deduction']['earning']['earning_amount'][i];
                                                                            if (value == undefined) {
                                                                                value = 0;
                                                                            }
                                                                        }

                                                                    }
                                                                }

                                                            })
                                                        }
                                                        if (value != 0)
                                                            return <tr>
                                                                <td><b style={{ fontWeight: 'bold' }}>{(valEarn.earning_name).toUpperCase()}</b></td>
                                                                <td></td>
                                                                <td>{this.formatNumber(value)}</td>
                                                            </tr>

                                                    }
                                                    )}

                                                    <tr>
                                                        <td></td>
                                                        <td className='pull-right   '><h5 style={{ fontWeight: 'bold', fontSize: 15 }}>TOTAL EARNINGS</h5></td>
                                                        <td><h5 style={{ fontWeight: 'bold', fontSize: 15 }}>{this.formatNumber(details.total_earning)}</h5></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            {details.SSS_EE != "" && details.SSS_EE != 0 ?
                                <div className='row' >
                                    <div className='col-md-12 row-width'>
                                        <div className='row '   >
                                            <div style={{ marginLeft: 10, marginTop: 10 }}>
                                                <h4>Employee Share</h4>

                                            </div>
                                            <div style={{ overflowY: 'scroll', width: '100%', maxHeight: 500 }}>
                                                <table className="table table-td-valign-middle table-bordered " style={{ position: "relative" }}>

                                                    <thead>
                                                        <tr >
                                                            <th width='75%'>Name</th>
                                                            {/* <th width='25%'>Days / Hours</th> */}
                                                            <th width='25%'>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>SSS</b></td>
                                                            {/* <td>{details.lateHours}</td> */}
                                                            <td>{this.formatNumber(details.SSS_EE)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>PHILHEALTH</b></td>
                                                            {/* <td>{details.lateHours}</td> */}
                                                            <td>{this.formatNumber(details.PHILHEALTH_EE)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>PAG-IBIG</b></td>
                                                            {/* <td>{details.lateHours}</td> */}
                                                            <td>{this.formatNumber(details.PAG_IBIG)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : undefined}

                            <div className='row' >
                                <div className='col-md-12 row-width'>
                                    <div className='row '   >
                                        <div style={{ marginLeft: 10, marginTop: 10 }}>
                                            <h4>Deduction</h4>

                                        </div>
                                        <div style={{ overflowY: 'scroll', width: '100%', maxHeight: 500 }}>
                                            <table className="table table-td-valign-middle table-bordered remove-last-row" style={{ position: "relative" }}>

                                                <thead>
                                                    <tr >
                                                        <th width='50%'>Name</th>
                                                        <th width='25%'>Days / Hours</th>
                                                        <th width='25%'>Amount</th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    {details.basic_amount_deduction != 0 && details.basic_amount_deduction != "" ?
                                                        <tr>
                                                            <td><b>BASIC DEDUCTION ADJUSTMENT</b></td>
                                                            <td>{details.basic_hours_deduction / 8}</td>
                                                            <td>{this.formatNumber(details.basic_amount_deduction)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {details.lateHours_Rate != 0 && details.lateHours_Rate != "" ?
                                                        <tr>
                                                            <td><b>LATE</b></td>
                                                            <td>{details.lateHours}Hours</td>
                                                            <td>{this.formatNumber(details.lateHours_Rate)}</td>
                                                        </tr> : undefined
                                                    }
                                                    {this.props.deduction.map((val2) => {

                                                        var matches = 0;
                                                        {
                                                            this.props.responsedata.map((val3) => {
                                                                if (val3.user_id === details.emp_id) {
                                                                    // val3.deduction.map(element => {
                                                                    for (let index = 0; index < val3.data_schema['earning_deduction']['deduction']['deduction_name'].length; index++) {
                                                                        if (val3.data_schema['earning_deduction']['deduction']['deduction_name'][index] === val2.deduction_name) {
                                                                            matches = val3.data_schema['earning_deduction']['deduction']['deduction_amount'][index];
                                                                        }
                                                                    }
                                                                    // })
                                                                }
                                                            }

                                                            )
                                                        }
                                                        // if(matches == 0){
                                                        // matches = 0;
                                                        if (matches != 0)
                                                            return <tr>
                                                                <td><b style={{ fontWeight: 'bold' }}>{(val2.deduction_name).toUpperCase()}</b></td>
                                                                <td></td>
                                                                <td>{this.formatNumber(matches)}</td>
                                                            </tr>

                                                        // }

                                                    }
                                                    )}
                                                    {details.holding_tax != 0 && details.holding_tax != "" ?
                                                        <tr>
                                                            <td><b>HOLDING TAX</b></td>
                                                            <td></td>
                                                            <td>{this.formatNumber(details.holding_tax)}</td>
                                                        </tr> : undefined
                                                    }

                                                    <tr>

                                                        <td></td>
                                                        <td className='pull-right   '><h5 style={{ fontWeight: 'bold', fontSize: 15 }}>TOTAL DEDUCTIONS</h5></td>
                                                        <td><h5 style={{ fontWeight: 'bold', fontSize: 15 }}>{this.formatNumber((details.totalDeduction))}</h5></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            {details.SSS_CONTRIBUTION != "" ?
                                <>
                                    <div style={{ marginTop: 10 }}>
                                        <h4>Government Contribution</h4>

                                    </div>
                                    <div className='row box-my-shadow' style={{ paddingTop: 20, paddingBottom: 20 }}>

                                        <div className='col-md-4 border-right' >
                                            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <label style={{ color: '#192a56', fontWeight: 'bold' }}>First Cut-off</label>
                                                    <a data-target="#firstCutoff" data-toggle="modal" className='m-l-10' style={{ cursor: 'pointer' }}><i className='fa fa-edit' onClick={() => {
                                                        this.setState({ payroll_details_id: details.payroll_details_id })
                                                        // alert(details.payroll_details_id)
                                                    }}></i></a>
                                                </div>

                                                <h3 style={{ alignSelf: 'center' }}>{this.formatNumber(details.firstCUT_off)}</h3>

                                            </div>
                                        </div>
                                        <div className='col-md-4 border-right' >
                                            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                                <label style={{ color: '#192a56', fontWeight: 'bold' }}>Second Cut-off</label>
                                                <h3 style={{ alignSelf: 'center' }}>{this.formatNumber(details.secondCUT_off)}</h3>

                                            </div>
                                        </div>
                                        <div className='col-md-4 border-right' >
                                            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                                <label style={{ color: '#192a56', fontWeight: 'bold' }}>Total</label>
                                                <h3 style={{ alignSelf: 'center' }}>{this.formatNumber(details.totalCUT_off)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row' >
                                        <div className='col-md-12 row-width'>
                                            <div className='row '   >

                                                <div style={{ overflowY: 'scroll', width: '100%', maxHeight: 500 }}>
                                                    <table className="table table-td-valign-middle table-bordered" style={{ position: "relative" }}>

                                                        <thead>
                                                            <tr >
                                                                <th width='50%'>Name</th>
                                                                {/* <th width='25%'>Days / Hours</th> */}
                                                                <th width='25%'>Amount</th>
                                                            </tr>
                                                        </thead>


                                                        <tbody>

                                                            <tr>
                                                                <td><b>SSS-CONTRIBUTION</b></td>
                                                                <td>{this.formatNumber((details.SSS_CONTRIBUTION))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>SSS-EC</b></td>
                                                                <td>{this.formatNumber((details.SSS_EC))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>SSS-ER</b></td>
                                                                <td>{this.formatNumber((details.SSS_ER))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>SSS-EE</b></td>
                                                                <td>{this.formatNumber((details.SSS_EE))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>PHILHEALTH-ER</b></td>
                                                                <td>{this.formatNumber(details.PHILHEALTH)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>PHILHEALTH-EE</b>
                                                                    {/* <a data-target="#PHILHEALTH_modal" data-toggle="modal" className='m-l-10' style={{ cursor: 'pointer' }}><i className='fa fa-edit' onClick={() => {
                                                                        this.setState({ payroll_details_id: this.state.payrollDetails.payroll_details_id })

                                                                    }}></i></a> */}

                                                                </td>
                                                                <td>{this.formatNumber(details.PHILHEALTH_EE)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>PAG-IBIG-EE</b></td>
                                                                <td>{this.formatNumber((details.PAG_IBIG))}</td>
                                                            </tr>  <tr>
                                                                <td><b>PAG-IBIG-EE</b></td>
                                                                <td>{this.formatNumber((details.PAG_IBIG))}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </>
                                : undefined
                            }
                            {this.props.payDetails.date_pending_payroll != null ?
                                <div className='row' >
                                    <div className='col-md-12 row-width'>
                                        <div className='row '   >
                                            <div style={{ marginLeft: 10, marginTop: 10 }}>
                                                <h4>Payroll Status</h4>
                                            </div>
                                            <div style={{ overflowY: 'scroll', width: '100%', maxHeight: 500 }}>
                                                <table className="table table-td-valign-middle table-bordered " style={{ position: "relative" }}>

                                                    <thead>
                                                        <tr >
                                                            <th width='25%'>Date</th>
                                                            <th width='75%'><center>Status</center></th>
                                                            {/* <th width='25%'>Amount</th> */}
                                                        </tr>
                                                    </thead>


                                                    <tbody>

                                                        {this.props.payDetails.date_pending_payroll != null ? this.props.payDetails.date_pending_payroll[0].date.map((val, index) => {
                                                            // return <div>
                                                            {/* {val.date.map((val1, index1) => { */ }
                                                            return <tr key={index}>
                                                                <td>
                                                                    <label>{moment(val.date).format('LL')}</label>
                                                                </td>
                                                                <td>
                                                                    {val.pending.map((val2, index2) => {
                                                                        return <label style={{ color: val2.type == 'COMPLETE' ? 'green' : undefined }} className='m-r-20'><b>{val2.type}</b><span className='m-l-20' style={{ color: '#bdc3c7' }}>|</span></label>

                                                                    })

                                                                    }
                                                                </td>

                                                            </tr>
                                                            {/* })} */ }
                                                            // </div>
                                                        }) : undefined}

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>
                                </div> : undefined
                            }
                        </div>

                    </div>
                    <div id="firstCutoff" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                        <form onSubmit={this.firstCutoff}>
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header" >
                                        <h4 className="page-header">Cut-off</h4>
                                        <button data-dismiss="modal" id="modal_cut_off" className="pull-right bttnclose bg-red-transparent-9 ">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <div className="form-group">
                                            <label className="col-lg-12 col-form-label">Day 1 - 15:</label>
                                            <div className="col-lg-12">
                                                <input name='firstCutoff' type='number' step="0.01" onChange={this.textchange} className='form-control' required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-save" type="submit">
                                            &nbsp;Save
                                </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div id="PHILHEALTH_modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                        <form onSubmit={this.updatePHILHEALTH}>
                            <div className="modal-dialog modal-sm modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header" >
                                        <h4 className="page-header">PHILHEALTH</h4>
                                        <button id="close_modal_phil" data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9 ">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <div className="form-group">
                                            <label className="col-lg-12 col-form-label">PHILHEALTH EE:</label>
                                            <div className="col-lg-12">
                                                <input name='philH_EE' type='number' step="0.01" onChange={this.textchange} className='form-control' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-save" type="submit">
                                            &nbsp;Save
                                </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div id="attendance_summary_modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                        <form onSubmit={this.updatePHILHEALTH}>
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header" >
                                        <h4 className="page-header">Attendance summary</h4>
                                        <button id="close_modal_phil" data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9 ">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <table className="table table-td-valign-middle table-bordered " style={{ position: "relative" }}>
                                            <thead>
                                                <tr >
                                                    <th >Date</th>
                                                    <th >Date Type</th>
                                                    <th >Total Hours</th>
                                                    <th >Late</th>
                                                    <th >Undertime</th>
                                                    <th >Overtime</th>
                                                    <th >Leave With pay</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.payDetails.payroll_summary.map((val,index)=>{
                                                    return <tr>
                                                         <td>{val.date}</td>
                                                         <td style={{color:val.daytype =="Absent"?'#fff':undefined,background:val.daytype  =="Absent"?'#f1c40f':undefined,}}>{val.daytype}</td>
                                                         <td>{parseFloat(val.total_hours).toFixed(2)}</td>
                                                         <td style={{color:val.late !=0?'#fff':undefined,background:val.late !=0?'red':undefined,}}>{parseFloat(val.late).toFixed(2)}</td>
                                                         <td style={{color:val.undertime !=0?'#fff':undefined,background:val.undertime !=0?'red':undefined,}}>{parseFloat(val.undertime).toFixed(2)}</td>
                                                         <td>{val.overtime}</td>
                                                         <td>{val.leave_with_pay}</td>
                                                     </tr>
                                                })

                                                }{this.props.payDetails.retro_ot != ""?<tr style={{background:'#f1c40f'}}>
                                                <td><b>Retro Overtime</b></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td ></td>
                                                <td><b>{parseFloat(this.props.payDetails.retro_ot).toFixed(2)}</b></td>
                                                <td></td>
                                            </tr>:undefined

                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div >
            </>


        );
    }
}
function mapStateToProps(state) {

    return {
        responsedata: state.Payroll.responsedata,
        payDetails: state.Payroll.payDetails,
        deduction: state.Payroll.deduction,
        earning: state.Payroll.earning,

    }
}


export default connect(mapStateToProps)(PayrollDetails);