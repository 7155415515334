import React, { Component } from 'react';
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            refresh:false
        };
    }
  
    componentDidMount(){
     localStorage.setItem('pageNotFound',true);

    }
    render() {

        return (
            <div id="content" className="content" style={{marginTop:"15%"}}>
                <center><h1 style={{fontSize:50}}>404 page not found</h1>
                <p  style={{fontSize:20}}>We are sorry but the page you are looking for does not exist on your account.</p></center>
              
            </div>

        );
    }
}

export default NotFound;