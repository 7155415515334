import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { MISCategories, getMISbranch, getAccomplishment, downloadImages, updateValidation } from "../../../api/api";
import '../css/style.css';
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import noimage from '../img/nophoto.png';

import Magnifier from 'react-magnifier';
import swal from "sweetalert/dist/sweetalert.min.js";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Meter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldAudit: [],
            startDate: new Date(),
            endDate: new Date(),
            date: new Date(),
            branch: [],
            Total: 0,
            TotalValid: 0,
            TotalInvalid: 0,
            valid: [],
            invalid: [],
            selectedBranch: '',
            finalData: [],
            modalData: [],
            loading: "false",
            soloImage: [],
            dataImage: [],
            search: '',
            Images: [],
            downloadURL: '',
            splitStart: '',
            splitEnd: '',
            hidden: true,
            hidden2: true,
            validation: [],

            jo_number: '',
            field_audit_id: '',
            meter_condition: '',
            meter_number: '',
            meter_reading: '',
            premises_condition: '',
            premisescondition: [],
            metercondition: [],
            editJONumber: true,
            editMNumber: true,
            editReading: true,
            editMCondtion: true,
            editPCondition: true,
            v_remarks: '',
            v_comment: '',
            tableID: '',

            valid: [],
            invalid: [],

            TotalAccomplishment: [],
            TotalFieldman: [],
            Filename: '',
            arrayDate: [],
            TotalAssign: [],
            

            selectOption: "",




        }
        this.toggle = this.toggle.bind(this);
        this.clickImage = this.clickImage.bind(this);
        this.downloadImages = this.downloadImages.bind(this);
        this.hidden = this.hidden.bind(this);
        this.hidden2 = this.hidden2.bind(this);
        this.submitValid = this.submitValid.bind(this);
        this.submitInvalid = this.submitInvalid.bind(this);
        this.textchange = this.textchange.bind(this);
        this.editJONumber = this.editJONumber.bind(this);
        this.editMNumber = this.editMNumber.bind(this);
        this.editReading = this.editReading.bind(this);
        this.editMCondtion = this.editMCondtion.bind(this);
        this.editPCondition = this.editPCondition.bind(this);
        this.createExcel = this.createExcel.bind(this);
    }
    _isMounted = true;

    handleChangeStart = e => {
        this.setState({
            startDate: e
        });

        const start = e.toISOString();
        const splitStart = start.split("T");
        this.setState({ splitStart: splitStart[0] });

    }

    handleChangeEnd = e => {
        this.setState({
            endDate: e
        });

        const end = e.toISOString();
        const splitEnd = end.split("T");
        this.setState({ splitEnd: splitEnd[0] });

    }

    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
        // console.log(e.target.value)
    }

    toggle(e) {
        this.setState({
            soloImage: [],
            hidden: true,
            hidden2: true,
            editJONumber: true,
            editMNumber: true,
            editReading: true,
            editMCondtion: true,
            editPCondition: true,
        })
        const data = [];
        var finalData = this.state.finalData;

        // for(let i=0; i<finalData.length; i++){
        //     if(finalData[i].id === e.target.id){
        //         data.push(finalData[i]);
        //     }
        // }


        data.push(finalData[e.target.id]);
        this.setState({ modalData: data });

        var field_audit_id = '';
        var jo_number = '';
        var meter_number = '';
        var meter_reading = '';
        var meter_condition = '';
        var premises_condition = '';
        var tableID = '';

        for (let i = 0; i < data.length; i++) {
            field_audit_id = data[i].field_audit_id;
            jo_number = data[i].jo_number;
            meter_number = data[i].meter_number;
            meter_reading = data[i].meter_reading;
            meter_condition = data[i].meter_condition;
            premises_condition = data[i].premises_condition;
            tableID = data[i].id;
        }

        this.setState({ field_audit_id: field_audit_id });
        this.setState({ jo_number: jo_number });
        this.setState({ meter_number: meter_number });
        this.setState({ meter_reading: meter_reading });
        this.setState({ meter_condition: meter_condition });
        this.setState({ premises_condition: premises_condition });
        this.setState({ tableID: tableID });


        const images = [];

        for (let i = 0; i < data.length; i++) {
            for (let b = 0; b < data[i].images.length; b++) {
                images.push(data[i].images[b]);
            }
        }

        this.setState({ dataImage: images });

        const defaultImage = [];
        for (let i = 0; i < 1; i++) {
            defaultImage.push(images[i]);
        }
        this.setState({ soloImage: defaultImage });



    }

    clickImage(e) {
        const data = this.state.dataImage
        const soloImage = []
        for (let i = 0; i < data.length; i++) {
            if (data[i].image_id === e.target.id) {
                soloImage.push(data[i]);
            }
        }
        this.setState({ soloImage: soloImage });
    }

    updateSearch(event) {
        this.setState({ search: event.target.value.substr(0, 20) });
    }

    hidden(e) {
        this.setState({
            hidden: !this.state.hidden,
            hidden2: true,
            v_remarks: '',
            v_comment: ''
        });
    }
    hidden2(e) {
        this.setState({
            hidden2: !this.state.hidden2,
            hidden: true,
            v_remarks: '',
            v_comment: ''
        });
    }

    submitValid(e) {
        var save = {
            field_audit_id: this.state.field_audit_id,
            jo_number: this.state.jo_number,
            meter_number: this.state.meter_number,
            meter_reading: this.state.meter_reading,
            meter_condition: this.state.meter_condition,
            premises_condition: this.state.premises_condition,
            v_remarks: '',
            v_comment: this.state.v_comment,
            validation: 'Valid',
        }

        updateValidation("field_audit/updateValidation", save).then(result => {
            if (result === "Success") {
                swal("Success", "Successfully Validated!", "success", {
                    button: "Done"
                });
                var data = this.state.finalData;
                var modalData = [];

                data.forEach(element => {
                    if (element['id'] === this.state.tableID && element['jo_number'] === this.state.jo_number) {
                        element['validation'] = 'Valid';
                        element['jo_number'] = this.state.jo_number;
                        element['meter_number'] = this.state.meter_number;
                        element['meter_reading'] = this.state.meter_reading;
                        element['meter_condition'] = this.state.meter_condition
                        element['premises_condition'] = this.state.premises_condition
                        element['v_remarks'] = this.state.v_remarks
                        element['v_comment'] = this.state.v_comment
                    }
                })

                modalData.push(data[this.state.tableID]);

                this.setState({ modalData: modalData });
                this.setState({ finalData: data });

                var valid = [];
                var invalid = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].validation === "Valid") {
                        valid.push(data[i]);
                    } else if (data[i].validation === "Invalid") {
                        invalid.push(data[i]);
                    }
                }
                this.setState({ invalid: invalid });
                this.setState({ TotalInvalid: invalid.length });
                this.setState({ valid: valid });
                this.setState({ TotalValid: valid.length })

            } else {
                swal("Error", "Error Connecting to Database", "error", {
                    button: "Done"
                });
            }
        });
    }
    submitInvalid(e) {
        var save = {
            field_audit_id: this.state.field_audit_id,
            jo_number: this.state.jo_number,
            meter_number: this.state.meter_number,
            meter_reading: this.state.meter_reading,
            meter_condition: this.state.meter_condition,
            premises_condition: this.state.premises_condition,
            v_remarks: this.state.v_remarks,
            v_comment: this.state.v_comment,
            validation: "Invalid",
            // id: this.state.tableID
        }


        updateValidation("field_audit/updateValidation", save).then(result => {
            if (result === "Success") {
                swal("Success", "Successfully Validated!", "success", {
                    button: "Done"
                });

                var data = this.state.finalData;
                var modalData = [];

                data.forEach(element => {
                    if (element['id'] === this.state.tableID && element['jo_number'] === this.state.jo_number) {
                        element['validation'] = 'Invalid';
                        element['jo_number'] = this.state.jo_number;
                        element['meter_number'] = this.state.meter_number;
                        element['meter_reading'] = this.state.meter_reading;
                        element['meter_condition'] = this.state.meter_condition
                        element['premises_condition'] = this.state.premises_condition
                        element['v_remarks'] = this.state.v_remarks
                        element['v_comment'] = this.state.v_comment
                    }
                })

                modalData.push(data[this.state.tableID]);

                this.setState({ modalData: modalData });
                this.setState({ finalData: data });

                var valid = [];
                var invalid = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].validation === "Valid") {
                        valid.push(data[i]);
                    } else if (data[i].validation === "Invalid") {
                        invalid.push(data[i]);
                    }
                }
                this.setState({ invalid: invalid });
                this.setState({ TotalInvalid: invalid.length });
                this.setState({ valid: valid });
                this.setState({ TotalValid: valid.length })

            } else {
                swal("Error", "Error Connecting to Database", "error", {
                    button: "Done"
                });
            }
        });



    }

    editJONumber(e) {
        this.setState({
            editJONumber: !this.state.editJONumber
        })
    }
    editMNumber(e) {
        this.setState({
            editMNumber: !this.state.editMNumber
        })
    }
    editReading(e) {
        this.setState({
            editReading: !this.state.editReading
        })
    }
    editMCondtion(e) {
        this.setState({
            editMCondtion: !this.state.editMCondtion
        })
    }
    editPCondition(e) {
        this.setState({
            editPCondition: !this.state.editPCondition
        })
    }



    submit = e => {
        // console.log(this.state.selectedBranch)
        this.setState({ loading: "false", selectOption: this.state.selectedBranch });


        let s = this.state.startDate;
        let start = s.toISOString();
        var end = this.state.endDate;
        var endDate = new Date(end);

        var branch = this.state.selectedBranch;
        if (branch === null || branch === '') {
            branch = 'All';
        }
        var splitStart = start.split("T");
        var convertEnd = endDate.toISOString();
        var splitEnd = convertEnd.split("T");

        var filename = branch + " " + splitStart[0] + " to " + splitEnd[0];

        this.setState({ Filename: filename });

        // if(s.toString() !== endDate.toString()){
        //     endDate.setDate(endDate.getDate() + 1);
        // }


        var value = endDate - s;
        var day, hour, minute, seconds;
        seconds = Math.floor(value / 1000);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;

        const startdate = []
        for (let i = 0; i < day + 1; i++) {
            var starts = start;
            var today = new Date(starts);
            var todayy = new Date(today.getTime() + (86400000 * i));
            var splitDate = String(todayy).split(" ");
            var finalDate = splitDate[1] + " " + splitDate[2];

            let startyear = todayy.getFullYear();
            let startmonth = todayy.getMonth() + 1;
            let startdt = todayy.getDate();
            if (startdt < 10) {
                startdt = '0' + startdt;
            }
            if (startmonth < 10) {
                startmonth = '0' + startmonth;
            }
            startdate.push(startyear + '-' + startmonth + '-' + startdt);

            this.setState({ arrayDate: startdate });
        }


        getAccomplishment("field_audit/getAccomplishment1", startdate).then(result => {
            this.setState({ fieldAudit: result });
            var branch = this.state.selectedBranch;
            var mainData = [];
            var finalData = [];
            var fieldmanData = []

            for (let i = 0; i < result.length; i++) {
                if (branch === result[i].branch_name) {
                    mainData = result[i].data;
                    fieldmanData = result[i].fieldman;
                } else if (branch === "All" || branch === "") {
                    mainData.push(result[i].data);
                    fieldmanData.push(result[i].fieldman);
                }
            }

            if (branch === "All" || branch === "") {
                var dataFinal = [];
                for (let i = 0; i < mainData.length; i++) {
                    for (let b = 0; b < mainData[i].length; b++) {
                        dataFinal.push({
                            date: mainData[i][b].date, assigned: mainData[i][b].assigned, accomplished: mainData[i][b].accomplished, new_assign: mainData[i][b].new_assign,
                            new_execute: mainData[i][b].new_execute, old_assign: mainData[i][b].old_assign, old_execute: mainData[i][b].old_execute, pending: mainData[i][b].pending,
                            received: mainData[i][b].received, returns: mainData[i][b].return
                        });
                    }
                }

                for (let i = 0; i < dataFinal.length; i++) {
                    finalData = finalData.concat(dataFinal[i].received);
                    finalData = finalData.concat(dataFinal[i].returns);
                }

                var combineData = [];
                for (let i = 0; i < finalData.length; i++) {
                    finalData[i].tableID = i;
                    combineData.push(finalData[i]);
                }

                this.setState({ Total: combineData.length });
                this.setState({ finalData: combineData });

                this.setState({ loading: "true" });


                var accomplished = [];
                var dataPending = [];

                for (let i = 0; i < combineData.length; i++) {
                    if (combineData[i].status === "Accomplished") {
                        accomplished.push(combineData[i]);
                    }else{
                        dataPending.push(combineData[i])
                    }
                }

                for (let i = 0; i < accomplished.length; i++) {
                    var x = accomplished[i].date_created.split(" ");
                    accomplished[i].date_created = x[0];
                }
                for(let i = 0; i<dataPending.length; i++){
                    var x = dataPending[i].date_created.split(" ");
                    dataPending[i].date_created = x[0];
                }   

                this.setState({ TotalAccomplishment: accomplished });


                var fieldman = [];
                var fieldmans = [];
                var fieldmanAccomplished = [];


                for (let i = 0; i < fieldmanData.length; i++) {
                    for (let b = 0; b < fieldmanData[i].length; b++) {
                        fieldman = fieldman.concat(fieldmanData[i][b]);
                    }
                }

                for(let i =0; i<fieldman.length; i++){
                    fieldmans.push({'id': fieldman[i].user_id ,'fieldman': fieldman[i].user_name, 'accomplished': [], 'pending': []});
                }



                for (let i = 0; i < fieldmans.length; i++) {
                    for (let b = 0; b < accomplished.length; b++) {
                        if (fieldmans[i].id === accomplished[b].user_id) {
                            fieldmans[i].accomplished.push(accomplished[b]);
                        }
                    }
                    for(let c =0; c<dataPending.length; c++){
                        if(fieldmans[i].id === dataPending[c].user_id){
                            fieldmans[i].pending.push(dataPending[c]);
                        }
                    }
                }


                for(let i = 0; i<fieldmans.length; i++){
                    fieldmanAccomplished.push({'id': fieldmans[i].id ,'fieldman': fieldmans[i].fieldman, 'accomplished': fieldmans[i].accomplished})
                }
                // this.setState({TotalFieldman: fieldmanAccomplished});


                var data = [
                    {
                        xSteps: 3,
                        ySteps: 1,
                        columns: ['Fieldman', 'JO Number', 'Meter Number', 'Meter Condition', 'Reading', 'Date Created', 'Date Posted'],
                        data: []
                    }
                ]

                var data2 = [
                    {
                        columns: ['Date'],
                        data: []
                    }
                ]

                var dataAssign = [
                    {
                        columns:['Date'],
                        data:[]
                    }
                ]

                for(let i = 0; i<accomplished.length; i++){
                    data[0].data.push([accomplished[i].user_fname,accomplished[i].jo_number,accomplished[i].meter_number,accomplished[i].meter_condition,accomplished[i].meter_reading,
                        accomplished[i].date_created,accomplished[i].date_posted])
                }

                for(let i = 0; i<fieldmans.length; i++){
                    data2[0].columns.push(fieldmans[i].fieldman);
                }

                
                for(let i = 0; i<fieldmans.length; i++){
                    dataAssign[0].columns.push(fieldmans[i].fieldman);
                }

                dataAssign[0].columns.push("Total");
                data2[0].columns.push('Total Accomplished','Pending','Total JO'); 
            



                var arrayDate = this.state.arrayDate;

                // for(let i = 0; i<arrayDate.length; i++){
                //     var dataset = [arrayDate[i]]; 
                //     for(let b = 0; b<fieldmans.length; b++){
                //         var dataaccom = [];
                //         for(let x = 0; x<fieldmans[b].accomplished.length; x++){
                //             if(arrayDate[i] === fieldmans[b].accomplished[x].date_created){
                //                 dataaccom.push("");
                //             }
                //         }
                //         dataset.push(dataaccom.length);
                //     }
                //     data2[0].data.push(dataset);
                // }

                for (let i = 0; i < arrayDate.length; i++) {
                    var dataset = [arrayDate[i]];
                    var datasource = [];
                    var total = 0;
                    var datasourcePending = [];
                    var totalpending = 0;
                    for(let b = 0; b<fieldmans.length; b++){
                        var dataaccom = [];
                        var datapen = [];
                        for(let x = 0; x<fieldmans[b].accomplished.length; x++){
                            if(arrayDate[i] === fieldmans[b].accomplished[x].date_created){
                                dataaccom.push("");
                            }
                        }
                        dataset.push(dataaccom.length);
                        datasource.push(dataaccom.length);
                        
                        for(let y=0; y<fieldmans[b].pending.length; y++){
                            if(arrayDate[i] === fieldmans[b].pending[y].date_created){
                                datapen.push("");
                            }
                        }
                        
                        datasourcePending.push(datapen.length); 

                    }
                    for (let i = 0; i < datasource.length; i++) {
                        total += datasource[i];
                    }
                    dataset.push(total);
                    data2[0].data.push(dataset);

                    for(let i = 0; i<datasourcePending.length; i++){
                        totalpending += datasourcePending[i];
                    }
                    dataset.push(totalpending);
                    let sum = total + totalpending;
                    dataset.push(sum)

                }

                var total = ["Total"];
                ////////////////////////////////////////////////////////
                for (let i = 1; i < data2[0].data[0].length; i++) {
                    var sum = 0;
                    for (let b = 0; b < data2[0].data.length; b++) {
                        sum += data2[0].data[b][i];
                    }

                    total.push(sum)
                }
                //////////////////////////////////////////////////////////

                data2[0].data.push(total);
                this.setState({TotalFieldman: data2});


                // for(let i = 0; i<arrayDate.length; i++){
                //     var dataset = [arrayDate[i]];
                //     var datasource = [];
                //     var total = 0;
                //     var datasourcePending = [];
                //     var totalpending = 0;
                //     for(let b = 0; b<fieldmans.length; b++){
                //         var dataaccom = [];
                //         var datapen = [];
                //         for(let x = 0; x<fieldmans[b].accomplished.length; x++){
                //             if(arrayDate[i] === fieldmans[b].accomplished[x].date_created){
                //                 dataaccom.push("");
                //             }
                //         }
                //         dataset.push(dataaccom.length); 
                //         datasource.push(dataaccom.length);
                        
                //         for(let y=0; y<fieldmans[b].pending.length; y++){
                //             if(arrayDate[i] === fieldmans[b].pending[y].date_created){
                //                 datapen.push("");
                //             }
                //         }
                        
                //         datasourcePending.push(datapen.length); 

                //     }
                //     for(let i = 0; i<datasource.length; i++){
                //         total += datasource[i];
                //     }
                //     dataset.push(total);
                //     data2[0].data.push(dataset);

                //     for(let i = 0; i<datasourcePending.length; i++){
                //         totalpending += datasourcePending[i];
                //     }
                //     dataset.push(totalpending);
                //     let sum = total + totalpending;
                //     dataset.push(sum)

                // }

                // var total = ["Total"];
                // ////////////////////////////////////////////////////////
                // for(let i = 1; i<data2[0].data[0].length; i++){
                //     var sum = 0;
                //     for(let b = 0; b<data2[0].data.length; b++){
                //         sum += data2[0].data[b][i];
                //     }
                    
                //     total.push(sum)
                // }
                // //////////////////////////////////////////////////////////

                // data2[0].data.push(total);
                // this.setState({TotalFieldman: data2});
                

                //For assign  
                for(let i = 0; i<arrayDate.length; i++){
                    var dataset = [arrayDate[i]];
                    var datasource = [];
                    var total = 0;
                    var datasourcePending = [];
                    var totalpending = 0;
                    for(let b = 0; b<fieldmans.length; b++){
                        var dataaccom = [];
                        var datapen = [];
                        for(let x = 0; x<fieldmans[b].pending.length; x++){
                            if(arrayDate[i] === fieldmans[b].pending[x].date_created){
                                dataaccom.push("");
                            }
                        }
                    for(let x = 0; x<fieldmans[b].accomplished.length; x++){ 
                        if(arrayDate[i] === fieldmans[b].accomplished[x].date_created){ 
                            dataaccom.push("");
                        } 
                    }
                        dataset.push(dataaccom.length); 
                        datasource.push(dataaccom.length);
 

                    }
                    for(let i = 0; i<datasource.length; i++){
                        total += datasource[i];
                    }
                    dataset.push(total);
                    dataAssign[0].data.push(dataset);

                }

                var totals = ["Total"];
                ////////////////////////////////////////////////////////
                for(let i = 1; i<dataAssign[0].data[0].length; i++){
                    var sum = 0;
                    for(let b = 0; b<dataAssign[0].data.length; b++){
                        sum += dataAssign[0].data[b][i];
                    }
                    
                    totals.push(sum)
                }
                //////////////////////////////////////////////////////////

                dataAssign[0].data.push(totals);
                this.setState({TotalAssign: dataAssign});

 


                const images = [];

                for (let i = 0; i < combineData.length; i++) {
                    for (let b = 0; b < combineData[i].images.length; b++) {
                        images.push(combineData[i].images[b]);
                    }
                }

                this.setState({ Images: images });

                var valid = [];
                var invalid = [];

                for (let i = 0; i < combineData.length; i++) {
                    if (combineData[i].validation === "Valid") {
                        valid.push(combineData[i]);
                    } else if (combineData[i].validation === "Invalid") {
                        invalid.push(combineData[i]);
                    }
                }

                this.setState({ TotalValid: valid.length });
                this.setState({ valid: valid });
                this.setState({ TotalInvalid: invalid.length });
                this.setState({ invalid: invalid });


            } else {

                mainData.forEach(element => {
                    finalData = finalData.concat(element["received"]);
                    finalData = finalData.concat(element["return"]);
                });

                var combineData = [];
                for (let i = 0; i < finalData.length; i++) {
                    finalData[i].tableID = i;
                    combineData.push(finalData[i]);
                }

                var dataExample = [
                    {
                        columns:['Date'],
                        data:[]
                    }
                ]


                for(let i=0; i<mainData.length; i++){
                    dataExample[0].data.push(mainData[i].date);
                   for(let b=0; b<fieldmanData.length; b++){
                    dataExample[0].columns.push(fieldmanData[b].user_name);
                       for(let z=0; z<mainData[i].accomplished.length; z++){
                           if(mainData[i].accomplished[z].user_id === fieldmanData[b].user_id){
                               dataExample[0].data.push(mainData[i].accomplished[z].length)
                           }
                       }
                   }
                }

                // console.log(dataExample);

         



                var accomplished = [];
                var dataPending = [];

                for (let i = 0; i < combineData.length; i++) {
                    if (combineData[i].status === "Accomplished") {
                        accomplished.push(combineData[i]);
                    }else{
                        dataPending.push(combineData[i]);
                    }
                }


                for(let i = 0; i<accomplished.length; i++){
                    var x = accomplished[i].date_created.split(" ");
                    accomplished[i].date_created = x[0];
                }

                for(let i = 0; i<dataPending.length; i++){
                    var x = dataPending[i].date_created.split(" ");
                    dataPending[i].date_created = x[0];
                }

            
                
                this.setState({TotalAccomplishment: accomplished});


                this.setState({ Total: combineData.length });
                this.setState({ finalData: combineData });

                this.setState({ loading: "true" });

                var fieldmans = [];
                var fieldmanAccomplished = [];


                for(let i = 0; i<fieldmanData.length; i++){
                    fieldmans.push({'id': fieldmanData[i].user_id, 'fieldman': fieldmanData[i].user_name, 'accomplished': [], 'pending': []});
                }

                for (let i = 0; i < fieldmans.length; i++) {
                    for (let b = 0; b < accomplished.length; b++) {
                        if (fieldmans[i].id === accomplished[b].user_id) {
                            fieldmans[i].accomplished.push(accomplished[b]);
                        }
                    }
                    for(let c =0; c<dataPending.length; c++){
                        if(fieldmans[i].id === dataPending[c].user_id){
                            fieldmans[i].pending.push(dataPending[c]);
                        }
                    }
                }
        


                for (let i = 0; i < fieldmans.length; i++) {
                    fieldmanAccomplished.push({ 'id': fieldmans[i].id, 'fieldman': fieldmans[i].fieldman, 'accomplished': fieldmans[i].accomplished })
                }
               

                // this.setState({TotalFieldman: fieldmanAccomplished});


                const images = [];

                for (let i = 0; i < combineData.length; i++) {
                    for (let b = 0; b < combineData[i].images.length; b++) {
                        images.push(combineData[i].images[b]);
                    }
                }

                this.setState({ Images: images });


                var data = [
                    {
                        xSteps: 1,
                        ySteps: 3,
                        columns: ['Fieldman','JO Number', 'Meter Number', 'Meter Condition','Reading','Date Created','Date Posted'],
                        data: []
                    }
                ]

                var data2 = [
                    { 
                        columns:['Date'],
                        data:[]
                    }
                ]

                var dataAssign = [
                    {
                        columns: ['Date'],
                        data: []
                    }
                ]

                for(let i = 0; i<accomplished.length; i++){
                    data[0].data.push([accomplished[i].user_fname,accomplished[i].jo_number,accomplished[i].meter_number,accomplished[i].meter_condition,accomplished[i].meter_reading,
                        accomplished[i].date_created,accomplished[i].date_posted] )
                }

                for (let i = 0; i < fieldmans.length; i++) {
                    data2[0].columns.push(fieldmans[i].fieldman)
                }
                data2[0].columns.push('Total Accomplished','Pending','Total JO');

                     
                for(let i = 0; i<fieldmans.length; i++){
                    dataAssign[0].columns.push(fieldmans[i].fieldman);
                }

                dataAssign[0].columns.push("Total");
                data2[0].columns.push('Total Accomplished','Pending','Total JO'); 
            
        

                var arrayDate = this.state.arrayDate;
                ///////////////////////////////////////////////////////////////////

                for(let i = 0; i<arrayDate.length; i++){
                    var dataset = [arrayDate[i]];
                    var datasource = [];
                    var datasourcePending = [];
                    var total = 0;
                    var totalpending = 0;
                    for(let b = 0; b<fieldmans.length; b++){
                        var dataaccom = [];
                        var datapen = [];
                        for(let x = 0; x<fieldmans[b].accomplished.length; x++){
                            if(arrayDate[i] === fieldmans[b].accomplished[x].date_created){
                                dataaccom.push("");
                            }
                        }
                        dataset.push(dataaccom.length);
                        datasource.push(dataaccom.length);

                        for(let y=0; y<fieldmans[b].pending.length; y++){
                            if(arrayDate[i] === fieldmans[b].pending[y].date_created){
                                datapen.push("");
                            }
                        }
                        
                        datasourcePending.push(datapen.length); 
                    }
                 
                
                    for(let i = 0; i<datasource.length; i++){
                        total += datasource[i];
                    }
                    dataset.push(total);
                    data2[0].data.push(dataset);

                    for(let i = 0; i<datasourcePending.length; i++){
                        totalpending += datasourcePending[i];
                    }
                    dataset.push(totalpending);
                    let sum = total + totalpending;
                    dataset.push(sum)
                }
                
        
                var total = ["Total"];
                ////////////////////////////////////////////////////////
                for (let i = 1; i < data2[0].data[0].length; i++) {
                    var sum = 0;
                    for (let b = 0; b < data2[0].data.length; b++) {
                        sum += data2[0].data[b][i];
                    }

                    total.push(sum)
                }
              
                //////////////////////////////////////////////////////////

                data2[0].data.push(total);
                this.setState({TotalFieldman: data2});

                //For assign  
                for(let i = 0; i<arrayDate.length; i++){
                var dataset = [arrayDate[i]];
                var datasource = [];
                var total = 0;
                var datasourcePending = [];
                var totalpending = 0;
                for(let b = 0; b<fieldmans.length; b++){
                    var dataaccom = [];
                    var datapen = [];
                    for(let x = 0; x<fieldmans[b].pending.length; x++){ 
                        if(arrayDate[i] === fieldmans[b].pending[x].date_created){ 
                            dataaccom.push("");
                        }
                    }
                    for(let x = 0; x<fieldmans[b].accomplished.length; x++){ 
                        if(arrayDate[i] === fieldmans[b].accomplished[x].date_created){ 
                            dataaccom.push("");
                        } 
                    }
                 
                    
                    dataset.push(dataaccom.length); 
                    datasource.push(dataaccom.length);

                

                }
                for(let i = 0; i<datasource.length; i++){
                    total += datasource[i];
                }
                dataset.push(total);
                dataAssign[0].data.push(dataset);

                }
    
                var totals = ["Total"];
                ////////////////////////////////////////////////////////
                for(let i = 1; i<dataAssign[0].data[0].length; i++){
                    var sum = 0;
                    for(let b = 0; b<dataAssign[0].data.length; b++){
                        sum += dataAssign[0].data[b][i];
                    }
                    
                    totals.push(sum)
                }
                //////////////////////////////////////////////////////////

                dataAssign[0].data.push(totals);
                this.setState({TotalAssign: dataAssign});

    
    
        
            }


        })
    }

    downloadImages(e) {
        const image = this.state.Images;

        const save = {
            images: image,
        }

        downloadImages("field_audit/downloadImages", save).then(result => {

        });

    }

    createExcel() {
        // const data = [[1, 2, 3], [true, false, null, 'sheetjs'], ['foo', 'bar', new Date('2014-02-19T14:30Z'), '0.3'], ['baz', null, 'qux']];
        // var buffer = xlsx.build([{name: "mySheetName", data: data}]); // Returns a buffer

    }


    categories() {
        MISCategories("field_audit/MISCategories").then(result => {
            if (result.response === "404") {
                result = [];
                this.setState({ validation: result });
            } else {
                var elf = result.elf;
                var data = [];
                var premisescondition = [];
                var metercondition = [];
                for (let i = 0; i < elf.length; i++) {
                    if (elf[i].type === "remarks") {
                        data.push(elf[i]);
                    }
                }
                this.setState({ validation: data });

                for (let i = 0; i < elf.length; i++) {
                    if (elf[i].type === "Meter Audit-4") {
                        premisescondition.push(elf[i]);
                    }
                }
                this.setState({ premisescondition: premisescondition });

                for (let i = 0; i < elf.length; i++) {
                    if (elf[i].type === "Meter Audit-2") {
                        metercondition.push(elf[i]);
                    }
                }
                this.setState({ metercondition: metercondition });

            }
        })
    }
    branch() {
        getMISbranch("field_audit/getMISbranch").then(result => {
            if (result.response === "404") {
                result = [];
                this.setState({ branch: result });
            } else {
                this.setState({ branch: result.elf });
                this.setState({ loading: "true" });
            }
        })
    }

    componentDidMount() {
        this.branch();
        this.categories();
        this.update();
    }

    componentWillUnmount() {
        if (localStorage.getItem("pass") !== "meter") {
            this._isMounted = false
        }
    }

    update() {
        if (localStorage.getItem("pass") === "meter") {
            setTimeout(() => {
                this.branch();
                this.categories();
                this.update();
            }, 500000);
        } else {
            clearTimeout(this.update);
        }
    }
    render() {

        localStorage.setItem("pass", "meter");

        let filterAudit = this.state.finalData.filter(
            (audit) => {
                return audit.user_fname.toLowerCase().indexOf(
                    this.state.search.toLowerCase()) !== -1 || audit.jo_number.toLowerCase().indexOf(
                        this.state.search.toLowerCase()) !== -1 || audit.status.toLowerCase().indexOf(
                            this.state.search.toLowerCase()) !== -1 || audit.meter_number.toLowerCase().indexOf(
                                this.state.search.toLowerCase()) !== -1;
            }
        )

        var hidden = {
            display: this.state.hidden ? "none" : "block"
        }
        var hidden2 = {
            display: this.state.hidden2 ? "none" : "block"
        }
        var editJONumber = {
            border: this.state.editJONumber ? 'none' : ''
        }
        var editMNumber = {
            border: this.state.editMNumber ? 'none' : ''
        }
        var editReading = {
            border: this.state.editReading ? 'none' : ''
        }
        var editMCondtion = {
            border: this.state.editMCondtion ? 'none' : ''
        }
        var editPCondition = {
            border: this.state.editPCondition ? 'none' : ''
        }

        const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;

        return (


            <div id="content" className="content">
                <div className="row text-center" style={{ marginTop: "-20px" }}>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-hand-holding-usd p-r-5 fa-lg text-success"></i><label>Navbar 1</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-gift text-success p-r-5 fa-lg"></i><label>Navbar 2</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-briefcase fa-lg text-success p-r-5"></i><label>Navbar 3</label></a>
                    </div>
                    <div className="col-sm-3 col-xs-6 col-md-3 m-t-10 bg-light" style={{ padding: 10 }}>
                        <a href="javascript:;"><i className="fa fa-user-slash fa-lg text-success p-r-5"></i><label>Navbar 4</label></a>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">Meter</h1>
                    </div>
                    {/* <div className="col-sm-6">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>BreadCrumbs</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>BreadCrumbs</a></li>
                                <li className="breadcrumb-item active"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>BreadCrumbs</a></li>
                            </ol>
                        </nav>
                    </div> */}
                </div>
                

                {(this.state.loading === "false" &&
                    <Loader fullPage loading />
                )}
                <div className="row">
                    <div className="col-md-9">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="row">

                                    <div className="form-inline">
                                        <div className="form-group m-r-10">
                                            <DatePicker
                                                selected={this.state.startDate}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeStart}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group m-r-10">
                                            <DatePicker
                                                selected={this.state.endDate}
                                                selectsEnd
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeEnd}
                                                minDate={this.state.startDate}
                                                className="form-control"

                                            />
                                        </div>
                                        <div className="form-group m-r-10">
                                            <select className="form-control" onChange={this.textchange} name="selectedBranch">
                                                <option>--Select Branch--</option>
                                                <option value="All">All</option>
                                                {this.state.branch.map(branch =>
                                                    <option value={branch.name}>{branch.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-check">
                                            <button className="bttnmsub" type="submit" onClick={this.submit}>
                                                <span className="fa fa-paper-plane"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="panel">
                            <div className="panel-body text-center">
                                <ExcelFile filename={this.state.Filename} element={<button className="btn btn-sm btn-success m-r-5 pointer">Download Accomplishments</button>}>
                                    <ExcelSheet dataSet={this.state.TotalFieldman} name="Accomplishments" />
                                    <ExcelSheet dataSet={this.state.TotalAssign} name="Assign"/>
                                </ExcelFile>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div class="card-body">
                                        <div class="mb-3 text-grey">
                                            <b class="mb-3">{this.state.selectOption} Total Accomplishments </b>
                                            <span class="ml-2"><i class="fa fa-handshake" data-toggle="popover" data-trigger="hover" data-title="Conversion Rate" data-placement="top" data-content="Percentage of sessions that resulted in orders from total number of sessions." data-original-title="" title=""></i></span>
                                        </div>
                                        <div class="d-flex align-items-center mb-1 text-center">
                                            <h2 class="text-white mb-0"><span data-animation="number">{this.state.Total}</span></h2>

                                        </div>
                                        {this.state.selectOption == 'All' &&

                                            <div>
                                                <div class="mb-4 text-grey">
                                                    <i class="fa fa-caret-down"></i> <span data-animation="number" data-value="0.50">Total Accomplishments Breakdown</span>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-info f-s-8 mr-2"></i>
                                                        CAINTA
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="262">23</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.79">25</span>%</div>
                                                    </div>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-info f-s-8 mr-2"></i>
                                                        MANDALUYONG
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="11">50</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.85">50</span>%</div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-info f-s-8 mr-2"></i>
                                                        ROSARIO
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="57">23</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="2.19">25</span>%</div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div class="card-body">
                                        <div class="mb-3 text-grey">
                                            <b class="mb-3">{this.state.selectOption} Valid Accomplishments</b>
                                            <span class="ml-2"><i class="fa fa-check-double" data-toggle="popover" data-trigger="hover" data-title="Store Sessions" data-placement="top" data-content="Number of sessions on your online store. A session is a period of continuous activity from a visitor." data-original-title="" title=""></i></span>
                                        </div>
                                        <div class="d-flex align-items-center mb-1">
                                            <h2 class="text-white mb-0"><span data-animation="number">{this.state.TotalValid}</span></h2>
                                            <div class="ml-auto">
                                                <div id="store-session-sparkline"></div>
                                            </div>
                                        </div>
                                        {this.state.selectOption == 'All' &&

                                            <div>
                                                <div class="mb-4 text-grey">
                                                    <i class="fa fa-caret-down"></i> <span data-animation="number" data-value="9.5">Valid Accomplishments Breakdown</span>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-lime f-s-8 mr-2"></i>
                                                        CAINTA
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="25.7">10</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="53210">25%</span></div>
                                                    </div>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-lime f-s-8 mr-2"></i>
                                                        MANDALUYONG
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="16.0">23</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="11959">50%</span></div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-lime f-s-8 mr-2"></i>
                                                        ROSARIO
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="7.9">10</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="5545">25%</span></div>
                                                    </div>
                                                </div>
                                            </div>}

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div class="card-body">
                                        <div class="mb-3 text-grey">
                                            <b class="mb-3">{this.state.selectOption} Invalid Accomplishments</b>
                                            <span class="ml-2"><i class="fa fa-times text-center" data-toggle="popover" data-trigger="hover" data-title="Conversion Rate" data-placement="top" data-content="Percentage of sessions that resulted in orders from total number of sessions." data-original-title="" title=""></i></span>
                                        </div>
                                        <div class="d-flex align-items-center mb-1">
                                            <h2 class="text-white mb-0"><span data-animation="number">{this.state.TotalInvalid}</span></h2>
                                            <div class="ml-auto">
                                                <div id="conversion-rate-sparkline"></div>
                                            </div>
                                        </div>
                                        {this.state.selectOption == 'All' &&
                                            <div>
                                                <div class="mb-4 text-grey">
                                                    <i class="fa fa-caret-down"></i> <span data-animation="number" data-value="0.50">invalid Accomplishments Breakdown</span>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-red f-s-8 mr-2"></i>
                                                        CAINTA
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="262">0</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.79">0.00</span>%</div>
                                                    </div>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-red f-s-8 mr-2"></i>
                                                        MANDALUYONG
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="11">0</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="3.85">0.00</span>%</div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <div class="d-flex align-items-center">
                                                        <i class="fa fa-circle text-red f-s-8 mr-2"></i>
                                                        ROSARIO
										</div>
                                                    <div class="d-flex align-items-center ml-auto">
                                                        <div class="text-grey f-s-11"><i class="fa fa-user"></i> <span data-animation="number" data-value="57">0</span></div>
                                                        <div class="width-50 text-right pl-2 f-w-600"><span data-animation="number" data-value="2.19">0.00</span>%</div>
                                                    </div>
                                                </div>
                                            </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* ====================================================== */}
                <div class="row">
                    {/* <div className="col-md-2">
                        <div class="panel">
                            <div className="panel-body">

                                <div class="form-group m-r-10" >
                                    <DatePicker
                                        selected={this.state.startDate}
                                        selectsStart
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        onChange={this.handleChangeStart}
                                        className="form-control"
                                    />
                                </div>
                                <div class="form-group m-r-10">
                                    <DatePicker
                                        selected={this.state.endDate}
                                        selectsEnd
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        onChange={this.handleChangeEnd}
                                        minDate={this.state.startDate}
                                        className="form-control"
                                    />
                                </div>

                                <div class="form-group m-r-10">
                                    <select className="form-control" onChange={this.textchange} name="selectedBranch">
                                        <option>--Select Branch--</option>
                                        <option value="All">All</option>
                                        {this.state.branch.map(branch =>
                                            <option value={branch.name}>{branch.name}</option>
                                        )}
                                    </select>
                                </div>

                                <center><button type="submit" class="btn btn-sm btn-primary m-r-5" onClick={this.submit}>Submit</button></center>

                            </div>
                        </div>


                    </div> */}
                    <div className="col-md-12">

                     {/* <ExcelFile filename={this.state.Filename} element={<button className="btn btn-sm btn-success m-r-5 pointer">Download Accomplishments</button>}>
                        <ExcelSheet dataSet={this.state.TotalFieldman} name="Accomplishments"/>
                        <ExcelSheet dataSet={this.state.TotalAssign} name="Assign"/>
                    </ExcelFile> */}

                    {/* <ExcelFile element={<button className="btn btn-sm btn-success m-r-5 pointer">Download Details</button>} filename={this.state.Filename}>
                        <ExcelSheet data={this.state.TotalAccomplishment} name="Accomplishments">
                            <ExcelColumn label="Fieldman" value="user_fname"/>
                            <ExcelColumn label="JO Number" value="jo_number"/>
                            <ExcelColumn label="Meter Number" value="meter_number"/>
                            <ExcelColumn label="Meter Condition" value="meter_condition"/>
                            <ExcelColumn label="Reading" value="meter_reading"/>
                            <ExcelColumn label="Premises Condition" value="premises_condition"/>
                            <ExcelColumn label="Date Created" value="date_created"/>
                            <ExcelColumn label="Date Posted" value="date_posted"/>
                            <ExcelColumn label="Status" value="status"/>
                        
                        </ExcelSheet>
                        <ExcelSheet data={this.state.TotalFieldman} name="Fieldman">
                            <ExcelColumn label="Fieldman" value="fieldman"/>
                            <ExcelColumn label="Total Accomplishment" value="accomplished"/>
                      
                        </ExcelSheet>
                    </ExcelFile> */}

                        <div className="row">
                            <div class="col-md-12">
                                <div class="panel">
                                    <div className="panel-heading">
                                        <div className="panel-heading-btn">
                                            <input type="text" placeholder="Search..." name='search' value={this.state.search} onChange={this.updateSearch.bind(this)} style={{ marginTop: '15px', marginLeft: '15px' }} />
                                        </div>
                                        <p className="panel-title">Total: {filterAudit.length}</p>
                                        {/* <a href={"http://api.pacificweb.com.ph/field_audit/downloadImages/"+this.state.splitStart+"/"+this.state.splitEnd} onClick={this.downloadImages}>Downlaod Pictures</a> */}
                                        {/* <a onClick={this.downloadImages}>Downlaod Pictures</a> */}
                                        {/* <a  href={"http://api.pacificweb.com.ph/field_audit/downloadImages/"+this.state.splitStart+"/"+this.state.splitEnd}>Download now</a>
                                            */}

                                    </div>
                                    <div className="panel-body">
                                        <div class="tableFixHead">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        {/* <th><input type="checkbox"/></th> */}
                                                        <th>Meter Reader</th>
                                                        <th>Job order</th>
                                                        <th>Meter number</th>
                                                        <th>Branch</th>
                                                        <th>Status</th>
                                                        <th style={{zIndex: 2}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filterAudit.map((data, index) => {
                                                        if (data.validation === "Valid") {
                                                            return <tr key={index} style={{ backgroundColor: "green" }}>
                                                                {/* <td><input type="checkbox" value={data.field_audit_id}/></td> */}
                                                                <td>{data.user_fname}</td>
                                                                <td>{data.jo_number}</td>
                                                                <td>{data.meter_number}</td>
                                                                <td>{data.branch_name}</td>
                                                                <td>{data.status}</td>
                                                                <td><button className="bttnview" data-target="#demo-lg-modal2" data-toggle="modal" onClick={this.toggle} id={data.tableID} name={data.jo_number}><span className="fa fa-eye"></span></button></td>
                                                            </tr>

                                                        } else if (data.validation === "Invalid") {
                                                            return <tr key={index} style={{ backgroundColor: "red" }}>
                                                                {/* <td><input type="checkbox" value={data.field_audit_id}/></td> */}
                                                                <td>{data.user_fname}</td>
                                                                <td>{data.jo_number}</td>
                                                                <td>{data.meter_number}</td>
                                                                <td>{data.branch_name}</td>
                                                                <td>{data.status}</td>
                                                                <td><button className="bttnview" data-target="#demo-lg-modal2" data-toggle="modal" onClick={this.toggle} id={data.tableID} name={data.jo_number}><span className="fa fa-eye"></span></button></td>
                                                            </tr>


                                                        } else {
                                                            return <tr key={index}>
                                                                {/* <td><input type="checkbox" value={data.field_audit_id}/></td> */}
                                                                <td>{data.user_fname}</td>
                                                                <td>{data.jo_number}</td>
                                                                <td>{data.meter_number}</td>
                                                                <td>{data.branch_name}</td>
                                                                <td>{data.status}</td>
                                                                <td><button className="bttnview" data-target="#demo-lg-modal2" data-toggle="modal" onClick={this.toggle} id={data.tableID} name={data.jo_number}><span className="fa fa-eye"></span></button></td>
                                                            </tr>


                                                        }

                                                    }

                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                {/* ================================================================= */}

                <div id="demo-lg-modal2" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div class="modal-header">
                                {this.state.modalData.map(data =>
                                    <h5 class="modal-title">Fieldman: {data.user_fname} {data.user_lname}</h5>
                                )}
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>

                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div class="col-md-7">
                                        {this.state.soloImage.map((solo, i) =>{
                                        if(solo === undefined)
                                        return <div><Magnifier mgShape="square"  src={noimage} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/></div>
                                        return <div><Magnifier mgShape="square"  src={solo.image_path} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/>
                                                    <div style={{marginTop: 15, overflowX:"scroll", whiteSpace: "nowrap", height: 75, width: 435}}>
                                                        {this.state.dataImage.map(img=>
                                                            <img className="pointer" src={img.image_path} id={img.image_id} key={img.image_id} onClick={this.clickImage} style={{width : 55, height : 55, marginLeft : 11}} />
                                                        )}
                                                    </div>
                                                </div>
                                    })}

                                    </div>
                                    <div class="col-md-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={this.hidden2.bind(this)}>Valid</button> <button className="btn btn-sm btn-danger m-r-9 pointer" onClick={this.hidden.bind(this)}>Invalid</button></center>

                                            </div>
                                            <div class="col-md-12"><p> </p></div>
                                            <div class="col-md-12">
                                                {this.state.modalData.map(model =>
                                                    <div key={model.field_audit_id}>
                                                        <table className="table table-border">
                                                            <tbody>
                                                                <tr style={{ display: 'none' }}>
                                                                    <td>ID:</td>
                                                                    <td><input type="text" style={editJONumber} className="form-control" name="tableID" defaultValue={model.id}></input></td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editJONumber}></i></td>
                                                                </tr>
                                                                <tr style={{ display: 'none' }}>
                                                                    <td>ID:</td>
                                                                    <td><input type="text" style={editJONumber} className="form-control" name="field_audit_id" defaultValue={model.field_audit_id}></input></td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editJONumber}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JO number:</td>
                                                                    <td><input type="text" style={editJONumber} className="form-control" name="jo_number" onChange={this.textchange} defaultValue={model.jo_number} readOnly={this.state.editJONumber}></input></td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editJONumber}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Meter No:</td>
                                                                    <td><input type="text" style={editMNumber} className="form-control" name="meter_number" onChange={this.textchange} defaultValue={model.meter_number} readOnly={this.state.editMNumber}></input></td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editMNumber}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reading:</td>
                                                                    <td><input type="text" style={editReading} className="form-control" name="meter_reading" onChange={this.textchange} defaultValue={model.meter_reading} readOnly={this.state.editReading}></input></td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editReading}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Meter Condition:</td>
                                                                    <td>
                                                                        {/* <input type="text" style={{border: 'none'}} name="meter_condition" onChange={this.textchange} defaultValue={model.meter_condition}></input> */}
                                                                        <select className="form-control" style={editMCondtion} onChange={this.textchange} name="meter_condition" disabled={this.state.editMCondtion}>
                                                                            <option value={model.meter_condition}>{model.meter_condition}</option>
                                                                            {this.state.metercondition.map(meter =>
                                                                                <option value={meter.details}>{meter.details}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editMCondtion}></i></td>
                                                                </tr>
                                                                {/* <tr>
                                                            <td>Seal No:</td>
                                                            <td>{data.seal_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Seal Color:</td>
                                                            <td>{data.seal_color}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Seal Condition:</td>
                                                            <td>{data.seal_condition}</td>
                                                        </tr> */}
                                                                <tr>
                                                                    <td>Premises Condition:</td>
                                                                    <td>
                                                                        <select className="form-control" style={editPCondition} onChange={this.textchange} name="premises_condition" disabled={this.state.editPCondition}>
                                                                            <option value={model.premises_condition}>{model.premises_condition}</option>
                                                                            {this.state.premisescondition.map(meter =>
                                                                                <option value={meter.details}>{meter.details}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td><i class="fa fa-edit pointer" onClick={this.editPCondition}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Validation:</td>
                                                                    {this.state.modalData.map(model2 => {
                                                                        if (model2.validation === "Pending") {
                                                                            return <td key={model2.field_audit_id}>
                                                                                <b>{model2.validation}</b>
                                                                            </td>
                                                                        } else if (model2.validation === "Valid") {
                                                                            return <td key={model2.field_audit_id}>
                                                                                <b style={{ color: 'green' }}>{model2.validation}</b>
                                                                            </td>
                                                                        } else if (model2.validation === "Invalid") {
                                                                            return <td key={model2.field_audit_id}>
                                                                                <b style={{ color: 'red' }}>{model2.validation}</b>
                                                                            </td>
                                                                        }

                                                                    }
                                                                    )}
                                                                    <td> </td>

                                                                    {/* <td></td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td>Remarks:</td>
                                                                    {/* <td><textarea class="form-control" placeholder="" rows="2" value={model.remarks}></textarea></td> */}
                                                                    <td>{model.remarks}</td>
                                                                    <td></td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-12" style={hidden}>
                                                <div class="col-md-12">
                                                    <select className="form-control" onChange={this.textchange} name="v_remarks" value={this.state.v_remarks}>
                                                        <option value="">---Validation Remarks---</option>
                                                        {this.state.validation.map(remarks =>
                                                            <option value={remarks.details}>{remarks.details}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div class="col-md-12"><p> </p></div>
                                                <div class="col-md-12"><textarea class="form-control" placeholder="Comment" rows="3" onChange={this.textchange} name="v_comment" value={this.state.v_comment}></textarea></div>
                                                <div class="col-md-12"><p> </p></div>
                                                <div className="col-md-12"><center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={this.submitInvalid}>Submit</button></center></div>
                                            </div>
                                            <div className="col-md-12" style={hidden2}>
                                                <div class="col-md-12"><textarea class="form-control" placeholder="Comment" rows="3" onChange={this.textchange} name="v_comment" value={this.state.v_comment}></textarea></div>
                                                <div class="col-md-12"><p> </p></div>
                                                <div className="col-md-12"><center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={this.submitValid}>Submit</button></center></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
                            </div>

                        </div>
                    </div>
                </div>




            </div>


        );
    }
}

export default Meter;