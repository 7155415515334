import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import axios, { post } from 'axios';
import { getData } from '../../../api/api';
import moment from 'moment';
import { Redirect } from 'react-router-dom'
class HeaderMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid: localStorage.getItem('u'),
            userData: [],
            nav_page_id: [],
            nav_group_id: [],
            nav_parent_id: [],
            href: "javascript:;",
            nav: [],
            apps: [],
            projects: [],
            team: [],
            humanresource: [],
            users: [],
            reports: [],
            privilege: [],
            app_nav: [],
            app_Home_Nav: [],
            apps2: [],
            app_name: "",
            hide: false,
            refresh: false,
            count: 0,
            FullName: "",
            Email: "",
            notification: [],
            redirect: false,
            count_noti: [],
            new_password: '',
            showPass: false,
            showPassconfirm: false,
            old_password: '',
            new_username: '',
            confirm_password: '',
            unmatch: '',
            wrong_pass: '',
            opennav: false,
        }
        this.textchange = this.textchange.bind(this)
        this.ChangePassword = this.ChangePassword.bind(this)
    }

    UserData = () => {
        getData("users/ActiveUser", localStorage.getItem('u')).then(result => {
            // console.log( localStorage.getItem('u'));
            // console.log(result);
            var c_name = ""
            var email = ""
            for (let index = 0; index < result.elf.length; index++) {
                var f_name = result.elf[index].user_details.fname;
                var l_name = result.elf[index].user_details.lname;
                var em = result.elf[index].contact.email;
                c_name = f_name + ' ' + l_name;
                email = em;
            }
            // console.log(email)
            this.setState({ userData: result.elf, FullName: c_name, Email: email })
            // localStorage.setItem('login_state',false)
            localStorage.setItem('imagePic', result.elf[0].user_details.image)

            localStorage.setItem("fullName", result.elf[0].user_details.fname + " " + result.elf[0].user_details.lname)
        })

    }

    logout = () => {

        localStorage.clear();
        window.location.replace("http://console.pacificweb.com.ph/#/")
        // window.location.replace("http://localhost:3000/#/")

        window.location.reload();

    }

    componentDidMount() {
        var details = []
        localStorage.setItem('totalNotiDetails', JSON.stringify(details))
        setTimeout(() => {
            var data = []
            var v_details_data = []
            localStorage.setItem('request_detail', JSON.stringify(data))
            getData("users/getPrivilege2", localStorage.getItem('u')).then(result => {

                this.setState({ app_nav: result.app_nav, nav: result.nav, apps: result.apps_nav, projects: result.projects_nav, team: result.team_nav, humanresource: result.humanresource_nav, users: result.users_nav, reports: result.reports_nav, privilege: result.privilege });
                localStorage.setItem('app_id_priv', JSON.stringify(result.app_id))
                v_details_data = result.app_id;
                var noti_data = []
                var count_noti = []
                for (let index = 0; index < result.noti.length; index++) {


                    var details = JSON.parse(result.noti[index].req_hierarchy_structure)
                    // console.log(details);
                    for (let i = 0; i < details.length; i++) {

                        for (let k = 0; k < details[i].approver.length; k++) {

                            if (details[i].approver[k].id == result.id) {

                                if (parseInt(i) <= parseInt(result.noti[index].req_hierarchy_level)) {
                                    // if(result.noti[index].req_hierarchy_level == null || result.noti[index].req_hierarchy_level = i ){
                                    // var data_details2 = { my_view_level:i,date_added: moment(result.noti[index].req_date_added).startOf('day').fromNow(), name: result.noti[index].user_lname + ' ' + result.noti[index].user_fname, level2: i, req_id: result.noti[index].req_id, status: result.noti[index].req_approval_status, level: result.noti[index].req_hierarchy_level, req_type: result.noti[index].req_type }
                                    // count_noti.push(data_details2)
                                    // }
                                    var data_details1 = { my_view_level: i, date_added: moment(result.noti[index].req_date_added).startOf('day').fromNow(), name: result.noti[index].user_lname + ' ' + result.noti[index].user_fname, level2: i, req_id: result.noti[index].req_id, status: result.noti[index].req_approval_status, level: result.noti[index].req_hierarchy_level, req_type: result.noti[index].req_type }
                                    noti_data.push(data_details1);
                                }

                            }


                        }

                    }
                }


                localStorage.setItem('totalNoti', noti_data.length)
                localStorage.setItem('totalNotiDetails', JSON.stringify(noti_data))
                this.setState({ notification: noti_data })

            })
            // this.noti();

            setTimeout(() => {
                this.UserData();
                this.GetApps(v_details_data);
                this.setState({ refresh: true })
                // if(this.state.app_nav.length == 0 && this.state.count < 3){
                //     window.location.reload();
                //     this.state.count++
                setInterval(() => {

                    this.setState({ refresh: true })
                }, 100);
                // }
            }, 200)
        }, 300);


    }
    noti() {
        getData("users/fetchnotification", { u_id: localStorage.getItem("u") }).then(result => {
            // console.log(result);

        })
    }
    GetApps(data) {
        var app_id = JSON.parse(localStorage.getItem('app_id_priv'))
        // console.log(app_id);
        getData("users/getApps", data).then(response => {

            var details = []
            var app_details = [];
            if (response.length != 0) {
                for (let i = 0; i < response.length; i++) {
                    var data = [];
                    data = { app_id: response[i][0].app_id, category_attribute: response[i][0].category_attribute, category_details: response[i][0].category_details, category_field: response[i][0].category_field, category_id: response[i][0].category_id, category_type: response[i][0].category_type, company_id: response[i][0].company_id, href: response[i][0].c_href }
                    app_details.push(data);
                }

                this.setState({ apps2: app_details })
            }


        })
    }

    data_nav() {
        // console.log( this.state.app_Home_Nav.length);
        if (localStorage.getItem('AppD') == "true") {
            var app_id_home = localStorage.getItem('selectApp_id');

            var u_id = localStorage.getItem('u');
            getData("users/getappHomeNav", { app_id: app_id_home, user_id: u_id }).then(result => {
                // console.log(result);
                localStorage.setItem('app_idDta', JSON.stringify(result.appHomeNav));
                localStorage.setItem('app_nameDta', result.app_name);

                this.setState({ app_Home_Nav: result.appHomeNav, app_name: result.app_name })
                localStorage.setItem('AppD', false)
            })
        }

    }
    textchange = e => {

        this.setState({ [e.target.name]: e.target.value, unmatch: '', wrong_pass: '' });


    }
    onclick(data) {
        var array = []
        localStorage.setItem('app_idDta', JSON.stringify(array));
        localStorage.setItem('AppD', true)
        localStorage.setItem('selectApp', true)
        localStorage.setItem('selectApp_id', data.app_id)
        this.setState({ refresh: true })
    }
    ChangePassword(e) {
        e.preventDefault();
        var details = { user_id: localStorage.getItem('u'), old_pass: this.state.old_password, new_password: this.state.new_password, new_username: this.state.new_username, confirm_password: this.state.confirm_password }
        if (this.state.new_password != this.state.confirm_password) {
            alert('Password unmatch!')
            this.setState({ confirm_password: '', unmatch: '#e55039' })
        } else {
            getData('users/ChangePass', details)
                .then((response) => {
                    // console.log(response);
                    if (response == true) {
                        document.getElementById('demo-xl-setting');
                        // console.log(document.getElementById('demo-xl-setting'))
                    } else {
                        alert('Wrong Password')
                        this.setState({ old_password: '', wrong_pass: '#e55039' })

                    }
                })
        }

    }

    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/pocketHR/approval' />
        }

        // console.log(this.state.opennav)

        return (
            <div id="header" className="header naving" >
                <div className="navbar-header">
                    <div className="d-flex">
                        <a href="/" className="navbar-brand navtitlemobile"> <b>Paciiiificweb</b> Systems <small> Apps</small></a>
                        <button className="btn toggler fas fa-bars pull-right" onClick={() => this.props.shownav()} />
                    </div>
                    <div className=" m-l-20" style={{ display: "flex", flexDirection: "column", justifyContent: 'space-around' }}>
                        <div className="m-b-20">
                            <h4 className="page-header m-b-0"><i className="fas fa-users m-t-10"></i>&nbsp;&nbsp;&nbsp;PocketHR -&nbsp;Master List</h4>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: 'space-around', marginLeft:"15%",  }}>
                            <div clas style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start' }}>
                                <a className="m-l-10" href="javascript:;" style={{fontSize:"14px", borderBottomStyle: "solid",borderBottomColor:"rgba(0, 120, 255, .9)",color:"rgba(0, 120, 255, .9)", paddingTop: 0, marginBottom: 10, textDecoration: "none" }}>Master List</a>
                                <a className="m-l-10" href="javascript:;" style={{fontSize:"14px", borderBottomStyle: "none", paddingTop: 0, marginBottom: 10, textDecoration: "none",color:"#c8d1d7" }}>Add New</a>
                                {/* <a className="m-l-10" href="javascript:;" style={{ borderBottomStyle: "none", paddingTop: 0, paddingBottom: 10, textDecoration: "none",color:"#c8d1d7" }}>Navigation 3</a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="navbar-nav navbar-right">
                    <li className="dropdown">
                        <a onClick={() => {
                            this.setState({ refresh: true })
                        }} href="#" data-toggle="dropdown" className="dropdown-toggle f-s-14">
                            <i className="fa fa-bell text-orange"></i>
                            {localStorage.getItem('totalNoti') != 0 &&
                                <div>
                                    <span className="label">{localStorage.getItem('totalNoti')}</span>
                                </div>
                            }
                        </a>
                        <ul className="dropdown-menu media-list dropdown-menu-right" style={{ minHeight: 200, maxHeight: 250, overflow: 'auto' }}>
                            <li className="dropdown-header"  >NOTIFICATIONS ({localStorage.getItem('totalNoti')})</li>
                            {JSON.parse(localStorage.getItem('totalNotiDetails')) != null &&
                                JSON.parse(localStorage.getItem('totalNotiDetails')).map((val, index) => {
                                    var status = ""
                                    if (val.status == "Pending") {
                                        status = "fa fa-exclamation-circle text-danger"
                                    }
                                    return <li onClick={() => {
                                        var data_details = { req_id: val.req_id, req_type: val.req_type }
                                        localStorage.setItem('request_detail', JSON.stringify(data_details))
                                    }} className="media" >
                                        <a href='#/pocketHR/approval' >
                                            <div className="media-left notifflow">
                                                <img src="../assets/img/user/user.jpg" className="media-object" alt="" />
                                            </div>

                                            <div className="media-body">
                                                <h6 className="media-heading">{val.name}</h6>

                                                <div className="text-grey-darker f-w-600 f-s-11">{val.date_added}</div>
                                                <div className="text-grey-darker f-w-600 f-s-11">{val.req_type + ' - ' + val.status}</div>
                                            </div>
                                        </a>
                                    </li>
                                })}


                        </ul>
                    </li>
                    <li className="dropdown navbar-user">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">

                            <img src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + localStorage.getItem('imagePic'))} alt="" />
                            <span className="d-none d-md-inline text-black">
                                {this.state.FullName}

                            </span> <b className="caret text-black"></b>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a href="javascript:;" className="dropdown-item">Edit Profile</a>
                            <a href="javascript:;" className="dropdown-item"><span className="badge badge-danger pull-right">2</span> Inbox</a>
                            <a href="javascript:;" className="dropdown-item">Calendar</a>
                            <a href="javascript:;" className="dropdown-item" data-target="#demo-xl-setting" data-toggle="modal">Setting</a>
                            <div className="dropdown-divider"></div>
                            <a href="javascript:;" onClick={this.logout} className="dropdown-item">Log Out</a>
                        </div>
                    </li>
                    {/* <li className="divider d-none d-md-block"></li>
                    <li className="d-none d-md-block">
                        <a href="javascript:;" onClick={() => { this.setState({ hide: !this.state.hide }) }} className="f-s-14">
                            <i className="fa fa-th text-blue"></i>
                        </a>
                    </li> */}
                </ul>

            </div>
        );
    }
}
export default HeaderMain;