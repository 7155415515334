import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";
import { NONAME } from 'dns';
import $ from 'jquery'
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import PAGE from'./employeeDetails'

// import console = require('console');
class PayrollProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deduction_name: "",
            earning_name: "",
            alert: false,
            Deduct_name: [],
            Earning_name: [],
            createDeduct: true,
            createEarn: true,
            DeductionName: "",
            EarningName: "",
            deductionSelect: false,
            earningSelect: false,
            deduction_amount: 0,
            earning_amount: 0,
            employeeDetails: [],
            payroll_detail_id: "",
            deduction_type_id: "",
            earning_type_id: "",
            emp_id: "",
            deductionDetails: [],
            earningDetails: [],
            transaction_type: "",
            Transact_name: [],
            Details: [],
            hide: false,
            EmpName: '',
            from_date: new Date(),
            to_date: new Date(),
            delete_details: [],
            companies: [],
            branches: [], employee: [],
            employee_selected: [],
            selectCutoff: '',
            edit_to: new Date(),
            edit_from: new Date(),
            edit_details: [],
            edit_amount: '',
            edit_cut_off: '',
            edit_transaction_id: ''
        }
        this.submitDeduction = this.submitDeduction.bind(this);
        this.onSubmitDeduction = this.onSubmitDeduction.bind(this);
        this.selectCutoff = this.selectCutoff.bind(this);
        this.editFunction = this.editFunction.bind(this);

    }

    componentDidMount() {
        this.getCompanies()
        var dataEmp = JSON.parse(localStorage.getItem("payroll_profile"));

        // this.setState({ employeeDetails: [dataEmp], payroll_detail_id: dataEmp.payroll_details_id, emp_id: dataEmp.emp_id, EmpName: dataEmp.emp_name })
        console.log(dataEmp);
        this.checkPagePrivilege();
        // localStorage.setItem('payProfile',true);
        setTimeout(() => {
            this.fetchDeductionName();
            // localStorage.setItem('payProfile',false);
        }, 200)


    }
    fetchDeductionName() {
        getData("humanResource/deduction_name", { user_id: localStorage.getItem("u") })
            .then(response => {
                console.log(response);
                this.setState({ Deduct_name: response.data, deductionDetails: response.deduction, Earning_name: response.earnings, earningDetails: response.data_earning_retro })
            })
    }

    checkPagePrivilege() {
        var route = "#/payroll";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                // console.log(response)
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitDeduction(e) {
        e.preventDefault();
        var name = this.state.deduction_name
        var details = { user_id: localStorage.getItem('u'), deduction_name: name, type: this.state.transaction_type };
        getData("humanResource/insertDeductionType", details)
            .then(response => {
                console.log(response)
                this.setState({ alert: true, Transact_name: response.data })
                this.fetchDeductionName();
                $('#modal_deduction_close').click()
                setTimeout(() => { this.setState({ alert: false }) }, 5000)
            })
    }
    onSubmitDeduction(e) {
        e.preventDefault();
        var details = {branch_id:this.state.branch_selected, selectCutoff: this.state.selectCutoff, employee_id: this.state.employee_selected, from_date: this.state.from_date, to_date: this.state.to_date, payroll_detail_id: this.state.payroll_detail_id, deduction_amount: this.state.deduction_amount, deduction_type_id: this.state.deduction_type_id, user_id: localStorage.getItem("u"), type: this.state.transaction_type }
        // console.log(this.state.selectCutoff);
        getData("humanResource/insertDeduction", details)
            .then(response => {
                this.setState({ alert: true, deductionDetails: response.data, earningDetails: response.data_earning })
                // localStorage.setItem("transaction", true);
                this.fetchDeductionName();
                $('#modal_deduction_close').click()
                setTimeout(() => { this.setState({ alert: false }) }, 5000)
            })

    }
    // SubmitEarning(e){
    //     e.preventDefault();
    //     var name = this.state.earning_amount
    //     // var details = { payroll_detail_id: this.state.payroll_detail_id, deduction_amount: this.state.earning_amount, deduction_type_id: this.state.earning_type_id, user_id: localStorage.getItem("u"),type:"Earning"}
    //     console.log(name);
    // }
    selectDeduction(data) {
        // console.log(data);
        this.setState({ createDeduct: false, DeductionName: data.deduction_name, deductionSelect: true, deduction_type_id: data.deduction_type_id })
    }
    _deleteDeduction() {
        getData("humanResource/delete_deduction_earning", { trans_id: this.state.delete_details.deduction_id })
            .then(response => {
                if (response.response == true) {
                    alert('Transaction has been deleted !')
                    this.componentDidMount()
                }
            })
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, employee: [] })
        setTimeout(() => { this.getBranch() }, 200)

    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {

                this.setState({ branches: response.data, branch_selected: "select branch" })

            })
    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
            })
    }
    fetchEmployee(branch) {
        getData("humanResource/getUsers_branch", { branch: branch })
            .then(response => {
                this.setState({ employee: response.response })

            })
    }
    showBYbranch = e => {


        e.preventDefault();

        this.setState({ branch_selected: e.target.value })
        this.fetchEmployee(e.target.value)
    }
    selectCutoff = (e) => {
        e.preventDefault();

        this.setState({ selectCutoff: e.target.value })
    }
    showEmployee = (e) => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                if (options[i].value == 'ALL') {
                    for (let i = 0; i < this.state.employee.length; i++) {
                        value.push(this.state.employee[i].user_id);
                    }
                    // value = this.state.employee
                } else {
                    value.push(options[i].value);

                }
                console.log(value)
            }
        }
        this.setState({ employee_selected: value })
    }
    editFunction = (e) => {
        e.preventDefault();

        let data = {
            trans_id: this.state.edit_transaction_id,
            amount: this.state.edit_amount,
            date_from: this.state.edit_from,
            date_to: this.state.edit_to,
            cut_off: this.state.edit_cut_off
        }
        getData("humanResource/updateDeductionEarnings", { data: data })
            .then(response => {
                if (response.res == true) {
                    alert('Successfully updated.')
                    this.fetchDeductionName();
                    $('#edit_close').click()
                } else {
                    alert('Check the details,failed to update.')
                }
            })
    }
    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }

        return (
            <div id="content " className="content " >
                {/* <div className="row">
                    <div className="col-md-4">
                        <center> <img src="../assets/img/user/user.jpg" alt="Avatar" style={{ borderRadius: "50%", width: "150px", color: "green" }} /></center>
                    </div>
                    <div className="col-md-8">
                        <div className="panel panel-default border boder-success">
                            <div className="panel-body">
                                {this.state.employeeDetails.map((val, index) =>

                                    <div style={{}}>
                                        <h4><b>{val.emp_name.toUpperCase()}</b></h4>
                                        <p style={{ fontSize: "13px" }}>Position </p>
                                        <p style={{ fontSize: "13px", marginTop: "10" }}><span style={{ color: 'green' }}>Active</span> <br />Account Created:2019-05-01</p>
                                        <div className="text-center bg-success rounded text-white p-t-10 p-b-10">
                                            <span>Rate Monthly:</span>
                                            <span className="text-thin">Php 15,000.00</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="panel">
                    <div className="panel-heading">

                        <div className="navmedia">
                            <div className="navtitle panel-title">
                              
                               
                                    <h4 className="page-header"><i class="far fa-snowflake"></i>&nbsp;Earnings and Deductions</h4>
                               
                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                      
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Payroll Report</a></li>
                                       
                                      
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Earnings and Deductions</a></li>
                                       
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav" >
                                <div name="navigation1" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/payroll" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}>Payroll</a>
                                </div>
                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                        this.setState({ bgcolor1: " transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/holidays" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Holidays</a>
                                </div>
                               
                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                        this.setState({ bgcolor1: " transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/governmentDues" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Government&nbsp;Dues</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollSettings" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Payroll&nbsp;Settings</a>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className='row m-l-8 m-r-10'>
                        <div className="col-md-6">
                            <h4 className="panel-title col-md-6">Retro | Deduction Summary</h4>
                        </div>
                        <div className="col-md-6">
                            <button className="btttn bg-green-transparent-9 fas fa-plus-circle pull-right" data-target="#demo-lg-modal-deduction" data-toggle="modal"
                                onClick={() => {
                                    this.setState({
                                        transaction_type: "Deduction", Transact_name:
                                            this.state.Deduct_name, deductionSelect: false, createDeduct: true
                                    })
                                }}>
                                &nbsp;Create&nbsp;Deduction
                            </button>
                        </div>
                    </div>

                   
                        <div className="row">
                            <div className="col-md-12">
                                <Scrollbars style={{ height: 250 }}>
                                    <table className="table table-striped" style={{ fontSize: 12 }}  >
                                        <thead>
                                            <th>No</th>
                                            <th>Employee</th>
                                            <th>Deduction ID</th>
                                            <th>Deductiion Name</th>
                                            <th>Amount</th>
                                            <th>Date From</th>
                                            <th>Date To</th>
                                            <th>Cut-off</th>

                                            {/* <th>Date Added</th> */}
                                            <th>Action</th>
                                        </thead>
                                        <tbody>
                                            {this.state.deductionDetails.map((val, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.user_lname + ' ' + val.user_fname}</td>
                                                    <td>{val.deduction_id}</td>
                                                    <td>{val.deduction_name}</td>
                                                    <td>{val.amount}</td>
                                                    <td>{val.date_from}</td>
                                                    <td>{val.date_to}</td>
                                                    <td>{val.cut_off}</td>

                                                    {/* <td>{val.date_apply}</td> */}

                                                    <td >
                                                        <button onClick={() => { this.setState({ edit_transaction_id: val.transaction_id, edit_cut_off: val.cut_off, edit_amount: val.amount, edit_from: new Date(val.date_from), edit_to: new Date(val.date_to) }) }} className="btttn bg-green-transparent-9 fa fa-edit m-r-5" data-target="#demo-lg-modal-edit" data-toggle="modal">&nbsp;Edit</button>
                                                        <button onClick={() => { this.setState({ delete_details: val }) }} className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" data-target="#demo-lg-modal-DELETE" data-toggle="modal">&nbsp;Delete</button>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
           
                {/* retro | earning start */}
                <div className="panel">
                    <div className="panel-heading row">
                        <h4 className="panel-title col-md-6">Retro | Earnings Summary</h4>
                        <div className="col-md-6">
                            <button className="btttn bg-green-transparent-9 fas fa-plus-circle pull-right" data-target="#demo-lg-modal-deduction" data-toggle="modal"
                                onClick={() => {
                                    this.setState({
                                        transaction_type: "Earning", Transact_name: this.state.Earning_name,
                                        createDeduct: true, deductionSelect: false
                                    })
                                }}>
                                &nbsp;Create&nbsp;Earnings
                            </button>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                                <Scrollbars style={{ height: 250 }}>
                                    <table className="table table-striped" style={{ fontSize: 12 }}  >
                                        <thead>
                                            <th>No</th>
                                            <th>Employee</th>
                                            <th>Transaction ID</th>
                                            <th>Transaction Name</th>
                                            <th>Amount</th>
                                            <th>Date From</th>
                                            <th>Date To</th>
                                            <th>Cut-off</th>
                                            <th>Action</th>
                                        </thead>

                                        <tbody>
                                            {this.state.earningDetails.map((val, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{val.user_lname + ' ' + val.user_fname}</td>
                                                    <td>{val.deduction_id}</td>
                                                    <td>{val.deduction_name}</td>
                                                    <td>{val.amount}</td>
                                                    <td>{val.date_from}</td>
                                                    <td>{val.date_to}</td>
                                                    <td>{val.cut_off}</td>
                                                    <td >
                                                        <button onClick={() => { this.setState({ edit_transaction_id: val.transaction_id, edit_cut_off: val.cut_off, edit_amount: val.amount, edit_from: new Date(val.date_from), edit_to: new Date(val.date_to) }) }} className="btttn bg-green-transparent-9 fa fa-edit m-r-5" data-target="#demo-lg-modal-edit" data-toggle="modal">&nbsp;Edit</button>
                                                        <button onClick={() => { this.setState({ delete_details: val }) }} className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" data-target="#demo-lg-modal-DELETE" data-toggle="modal">&nbsp;Delete</button>
                                                    </td>

                                                </tr>
                                            }


                                            )}

                                        </tbody>

                                    </table>
                                </Scrollbars>
                            </div>

                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {/* retro | earnings end */}
                <div id="demo-lg-modal-deduction" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>{this.state.transaction_type}</h4>
                                <button id='modal_deduction_close' data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" />
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-4 border-right">
                                        <Scrollbars style={{ height: 160 }}>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                {this.state.Transact_name.map((val) => {
                                                    return <center>
                                                        <button className="btttn bg-blue-transparent-9 m-5" style={{ borderStyle: "none" }} onClick={() => { this.selectDeduction(val) }}>
                                                            <span>{val.deduction_name}</span>
                                                        </button>
                                                    </center>
                                                })}
                                            </div>
                                            <center>
                                                <button className="btttn bg-green-transparent-9 text-center fas fa-plus-circle m-5" onClick={() => { this.setState({ createDeduct: true, deductionSelect: false }) }}>
                                                    &nbsp;Add&nbsp;New
                                            </button>
                                            </center>

                                        </Scrollbars>

                                    </div>
                                    <div className="col-md-8 ">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {this.state.alert == true &&
                                                    <div className="row  w3-animate-zoom" >
                                                        <div className="col-md-12">
                                                            <div className="panel" style={{ backgroundColor: "#2ecc71" }}>
                                                                <a onClick={() => this.setState({ alert: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                                                <div className="panel-body" >
                                                                    <div style={{ color: "white" }}>
                                                                        <i className="fa fa-check"></i>&nbsp;<label style={{ color: "white" }}>Success!</label>
                                                                        <p>Transaction name added successfully!</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.alertWarning == true &&
                                                    <div>

                                                        <div className="row  w3-animate-zoom" >
                                                            <div className="col-md-12">
                                                                <div className="panel" style={{ backgroundColor: "#f39c12" }}>
                                                                    <a onClick={() => this.setState({ alertWarning: false })} href="javascript:;"><i style={{ color: "white" }} className="fa fa-times-circle pull-right" ></i></a>
                                                                    <div className="panel-body" >
                                                                        <div style={{ color: "white" }}>
                                                                            <i className="fa fa-exclamation"></i>&nbsp;<label style={{ color: "white" }}>Failed!</label>
                                                                            <p>Transaction  is failed to generate!</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {this.state.createDeduct == true &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <form onSubmit={this.submitDeduction}>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Transaction Names</label>
                                                            <div class="col-md-9">
                                                                <input onChange={this.textchange} type="text" name="deduction_name" id="deduction_name" className="form-control m-b-5" required />
                                                            </div>
                                                        </div>

                                                        <div className="pull-right">
                                                            <button className="btttn fas fa-plus-circle bg-green-transparent-9 m-r-5" type="submit">
                                                                &nbsp;Create
                                                        </button>
                                                            <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none " type="submit" data-dismiss="modal" aria-label="Close" data-dismiss="modal" aria-label="Close" >
                                                                &nbsp;Cancel
                                                        </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        }
                                        {this.state.deductionSelect == true &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <form onSubmit={this.onSubmitDeduction}>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">{this.state.DeductionName}&nbsp;Amout:</label>
                                                            <div class="col-md-9">
                                                                <input onChange={this.textchange} type="currency" name="deduction_amount" id="deduction_name" placeholder="amount" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Select Date From:</label>
                                                            <div class="col-md-9">
                                                                <div className="form-inline">
                                                                    <div className="form-group" >
                                                                        <DatePicker
                                                                            selected={this.state.from_date}
                                                                            startDate={this.state.from_date}
                                                                            className="form-control"
                                                                            onChange={(event) => this.setState({ from_date: event })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Select Date To:</label>
                                                            <div class="col-md-9">
                                                                <div className="form-inline">
                                                                    <div className="form-group" >
                                                                        <DatePicker
                                                                            selected={this.state.to_date}
                                                                            endDate={this.state.to_date}
                                                                            className="form-control"
                                                                            onChange={(event) => this.setState({ to_date: event })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Select Company:</label>
                                                            <div class="col-md-9">
                                                                <div className="form-group" >
                                                                    <div className="row" style={{ marginRight: "11%", width: "100%" }}>
                                                                        <select className="form-control" onChange={this.showBYcompanies} required>
                                                                            <option ></option>
                                                                            {this.state.companies.map((val, index) => {
                                                                                return <option value={val.company_id}>{val.company_name}</option>

                                                                            }
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Select Branch:</label>
                                                            <div class="col-md-9">
                                                                <div className="form-group" >
                                                                    <div className="row" style={{ marginRight: "11%", width: "100%" }}>
                                                                        <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                                                            <option>--select branch--</option>
                                                                            <option></option>
                                                                            {this.state.branches.map((branch, index) =>
                                                                                <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Select Employee:</label>
                                                            <div class="col-md-9">
                                                                <div className="form-group" >
                                                                    <div className="row" style={{ marginRight: "11%", width: "100%" }}>
                                                                        <select className="form-control" style={{ height: 150 }} onChange={this.showEmployee} multiple required>
                                                                            <option value="ALL">--Select All--</option>
                                                                            <option></option>

                                                                            {this.state.employee.map((val, index) =>
                                                                                <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row m-b-15">
                                                            <label class="col-form-label col-md-3">Select Cut-off</label>
                                                            <div class="col-md-9">
                                                                <div className="form-group" >
                                                                    <div className="row" style={{ marginRight: "11%", width: "100%" }}>
                                                                        <select className="form-control" onChange={this.selectCutoff} required>
                                                                            <option ></option>
                                                                            <option value='Both'>Both</option>
                                                                            <option value='First Cut-off'>Every First Cut-off</option>
                                                                            <option value='Second Cut-off'>Every Second Cut-off</option>

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pull-right">
                                                            <button className="btttn fas fa-plus-circle bg-green-transparent-9 m-r-5" type="submit">&nbsp;Submit</button>
                                                            <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none " data-dismiss="modal" aria-label="Close" >&nbsp;Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>


                                            </div>
                                        }


                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-DELETE" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete User</h4>
                                {/* <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want delete this record?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" onClick={() => this._deleteDeduction()} data-dismiss="modal" aria-label="Close" >
                                    &nbsp;Delete
                                </button>
                                <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                    &nbsp;Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-edit" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit</h4>
                                <button id='edit_close' hidden data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <form onSubmit={this.editFunction}>
                                <div className="modal-body">
                                    <div class="form-group row m-b-15">
                                        <label class="col-form-label col-md-3">{this.state.DeductionName}&nbsp;Amout:</label>
                                        <div class="col-md-9">
                                            <input onChange={(e) => this.setState({ edit_amount: e.target.value })
                                            } type="currency" name="edit_amount" id="deduction_name" placeholder="amount" className="form-control" required value={this.state.edit_amount} />
                                        </div>
                                    </div>
                                    <div class="form-group row m-b-15">
                                        <label class="col-form-label col-md-3">Select Date From:</label>
                                        <div class="col-md-9">
                                            <div className="form-inline">
                                                <div className="form-group" >
                                                    <DatePicker
                                                        selected={this.state.edit_from}
                                                        startDate={this.state.edit_from}
                                                        className="form-control"
                                                        onChange={(event) => this.setState({ edit_from: event })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-b-15">
                                        <label class="col-form-label col-md-3">Select Date To:</label>
                                        <div class="col-md-9">
                                            <div className="form-inline">
                                                <div className="form-group" >
                                                    <DatePicker
                                                        selected={this.state.edit_to}
                                                        endDate={this.state.edit_to}
                                                        className="form-control"
                                                        onChange={(event) => this.setState({ edit_to: event })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-b-15">
                                        <label class="col-form-label col-md-3">Select Cut-off</label>
                                        <div class="col-md-9">

                                            <div className="form-group" >
                                                <div className="row" style={{ marginLeft: 1, width: "90%" }}>
                                                    <select className="form-control" onChange={(e) => this.setState({ edit_cut_off: e.target.value })} value={this.state.edit_cut_off} required>
                                                        <option ></option>
                                                        <option value='Both'>Both</option>
                                                        <option value='First Cut-off'>Every First Cut-off</option>
                                                        <option value='Second Cut-off'>Every Second Cut-off</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btttn bg-green-transparent-9 fa fa-check m-r-5" >
                                        &nbsp;Submit
                                </button>
                                    <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                        &nbsp;Cancel
                                </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

export default PayrollProfile;