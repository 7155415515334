import React, { Component } from 'react';
import {getDataFleet} from "../../api/api";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Magnifier from 'react-magnifier';
import noimage from '../../apps/mis/img/nophoto.png';
import swal from "sweetalert/dist/sweetalert.min.js";
import {Loader} from "react-overlay-loader"

class User extends Component {
    constructor(props){
        super(props);
        this.state = {
            fleetUsers: [],
            load: [],
            services: [],
            userLoad: [],
            viewDetails: [],
            viewServices: [],
            viewLoad: [],
            viewHistory: [],
            modalServices: [],
            modalLoad: [],
            modalHistory: [],
            currentBalance: 0,
            loadWallet: 0,
            receiptPicture: [],
            soloPicture: [],
            historyimage: [],
            historysolo: [],
            incident: [],
            success: 0,
            cancelled: 0,
            data1: "false",
            data2: "false",
            loading: "false",
            userCount: 0,
            loadCount: 0,

        }
        this.modalServices = this.modalServices.bind(this);
        this.modalLoad = this.modalLoad.bind(this);
        this.modalHistory = this.modalHistory.bind(this);
    }

    load(){
        getDataFleet("billing/getLoadRequest").then(result => {
            if(result.response === "Success"){
                this.setState({load: result.elf});
                this.setState({data1: "true"});
            }else{
                this.load();
            }
       })
   }

   services(){
        getDataFleet("services/getServices").then(result=> {
            if(result.response === "Success"){
                this.setState({services : result.elf});
                this.setState({data2: "true"})
            }else{
                this.services();
            }
        })
    }



   user(){
        getDataFleet("Users/getUsers").then(result=>{
            if(result.response === "Success"){
                if(this.state.data1 === "true" && this.state.data2 === "true"){
                    let data = result.elf;
                    const users = [];
                    const driver = [];
                    for(let i=0; i<data.length; i++){
                        if(data[i].user_details.app === "1" && data[i].user_details.account === "3"){
                            users.push(data[i]);
                        }
                    }
                 
                    this.setState({userCount: users.length});
                    for(let i=0; i<data.length; i++){
                        if(data[i].employment.jobposition === "Fleet Driver"){
                            driver.push(data[i]);
                        }
                    }
        
                    let services = this.state.services;
                    const newServices = [];
                    for(let i=0; i<services.length; i++){
                        for(let b=0; b<driver.length; b++){
                            if(services[i].driver.driver_id === driver[b].user_id){
                              services[i].driver = driver[b];
                            }
                        }
                    }
                    
                    let load = this.state.load;
                    const userLoad = [];
                    let count = 0;
                    let arrLoad = [];
                    for(let i=0; i<users.length; i++){
                        const arrayL = [];
                        const arrayH = [];
                        for(let b=0; b<load.length; b++){
                            if(users[i].user_id === load[b].id){
                                if(load[b].load.status === "Pending"){
                                    arrayL.push(load[b]);
                                    arrLoad.push(load[b]);
                                    count++;
                                }else{
                                    arrayH.push(load[b]);
                                } 
                            }
                        }
                        const arrayZ = [];
                        for(let z=0; z<services.length; z++){
                            if(users[i].user_id === services[z].user_details.id){
                                arrayZ.push(services[z]);
                            }
                        }
                        userLoad.push({"user": users[i], "load": arrayL, "history":arrayH, 'services': arrayZ});
                    }
                    this.setState({loadCount: arrLoad.length});
                    this.setState({userLoad: userLoad});
                    this.setState({loading : "true"});
                }
            }else{

            }
        })
    }

    viewDetails(e){
        let u = this.state.userLoad;
     
        const details = [];
        const incident = [];
        for(let i=0; i<u.length; i++){
            if(u[i].user.user_id === e.target.id){
                details.push(u[i]);
            } 
        }
        this.setState({viewDetails: details})

        let currentBalance = 0;
        for(let i=0; i<details.length; i++){
            currentBalance = details[i].user.user_details.balance;
        }
        this.setState({currentBalance: currentBalance});

        let services = [];
        let load = [];
        let history = [];

        for(let i=0; i<details.length; i++){
            for(let b=0; b<details[i].services.length; b++){
                services.push(details[i].services[b]);
            }
        }

        for(let i=0; i<details.length; i++){
            for(let b=0; b<details[i].load.length; b++){
                load.push(details[i].load[b]);
            }
        }
        for(let i=0; i<details.length; i++){
            for(let b=0; b<details[i].history.length; b++){
                history.push(details[i].history[b]);
            }
        }

        this.setState({viewServices: services});
        this.setState({viewLoad: load});
        this.setState({viewHistory: history});

        let image = [];
        let solo = [];

        for(let i=0; i<load.length; i++){
            for(let b=0; b<load[i].load.length; b++){
                image.push(load[i].load[b].pic);
            }
        }
        for(let i=0; i<1; i++){
            solo.push(image[i]);
        }
        this.setState({receiptPicture: image});
        this.setState({soloPicture: solo});

        let historyimage = [];
        let historysolo = [];

        for(let i=0; i<history.length; i++){
            for(let b=0; b<history[i].load.length; b++){
                historyimage.push(history[i].load[b].pic);
            }
        }

        for(let i=0; i<1; i++){
           historysolo.push(historyimage[i]);
        }

        this.setState({historyimage: historyimage});
        this.setState({historysolo: historysolo});

        const success = [];
        const cancelled = [];

        for(let i=0; i<services.length; i++){
            if(services[i].status !== "Cancelled"){
                success.push(services[i]);
            }else{
                cancelled.push(services[i]);
            }
        }
        this.setState({success: success.length});
        this.setState({cancelled: cancelled.length});

        const report = [];
        for(let i=0; i<services.length; i++){
            for(let b=0; b<services[i].incident.length; b++){
                report.push(services[i].incident[b]);
            }
        }

        this.setState({incident: report.length})



    }

    modalServices(e){
        let trip = this.state.viewServices;
        const data = [];
        for(let i=0; i<trip.length; i++){
            if(trip[i].id === e.target.id){
                data.push(trip[i]);
            }
        }

        this.setState({modalServices: data});

    }
    modalLoad(e){
        let load = this.state.viewLoad;
        const data =[];
        for(let i=0; i<load.length; i++){
            if(load[i].load.id === e.target.id){
             data.push(load[i]);
            }
        }
     
        this.setState({modalLoad: data});

    }
    modalHistory(e){
        let load = this.state.viewHistory;
        const data =[];
        for(let i=0; i<load.length; i++){
            if(load[i].load.id === e.target.id){
             data.push(load[i]);
            }
        }
 
        this.setState({modalHistory: data});
    }

    approveLoad(load){

        const data = {
            status : "Approved",
            id : load.load.id,
            updated_by : localStorage.getItem('u'),
            user_id :  load.id,
            amount : load.load.price
        }
        getDataFleet("billing/approveLoadRequest", data)
        .then(response => {
            let result = response;
        
            if(result.res === "Success"){
                swal("Success", "Successfully Added", "success",{
                    button: "Done"
                });
                let loads = this.state.viewLoad;
               
                loads.forEach(element => {
                    if(element['load'].id === load.load.id){
                        element['load'].status = "Approved"
                    }
                });
                this.setState({viewLoad: loads});
                let count = this.state.loadCount;
                let sub = count -1;
                this.setState({loadCount: sub})

                let history = this.state.viewHistory;
                for(let i=0; i<loads.length; i++){
                    history.push(loads[i]);
                }
                this.setState({viewHistory: history});

                let currentBalance = this.state.currentBalance;
                var balance = parseInt(currentBalance) + parseInt(load.load.price);
                this.setState({currentBalance: balance})

                let loadWallet = this.state.loadWallet;
                let wallet = loadWallet - 1;
            
            }else{
                swal("Error", "Error Connecting to Database", "error",{
                    button: "Done"
                });
           
            }
        });
   }

   deniedLoad(load){
      
    const data = {
        status : "Denied",
        id : load.load.id,
        updated_by : localStorage.getItem('u'),
        user_id :  load.id,
        amount : 0
    }
    getDataFleet("billing/approveLoadRequest", data)
    .then(response => {
        let result = response;
    
        if(result.res === "Success"){
            swal("Success", "Successfully Added", "success",{
                button: "Done"
            });
            let loads = this.state.viewLoad;
            loads.forEach(element => {
                if(element['load'].id === load.load.id){
                    element['load'].status = "Denied"
                }
            });
            this.setState({viewLoad: loads});
            let count = this.state.loadCount;
            let sub = count -1;
            this.setState({loadCount: sub})
  

            let history = this.state.viewHistory;
            for(let i=0; i<loads.length; i++){
                history.push(loads[i]);
            }
            this.setState({viewHistory: history});

            let loadWallet = this.state.loadWallet;
            let wallet = loadWallet - 1;
            this.setState({loadWallet: wallet});
       
        }else{
            swal("Error", "Error Connecting to Database", "error",{
                button: "Done"
            });
         
        }
    });
}


    componentDidMount(){
        this.load();
        this.services();
        this.user();
        this.update();
    }

    componentWillUnmount(){
        if(localStorage.getItem("pass") !== "user"){
            this._isMounted = false
        }  
    }

    update(){
        if(localStorage.getItem("pass") === "user"){
            setTimeout(() => {
                this.load();
                this.services();
                this.user();
                this.update();
            }, 500000);
        }else{
            clearTimeout(this.update);
        }
    }

    render() { 
        localStorage.setItem("pass","user");
        return ( 
            <div id="content" className="content">

                {(this.state.loading === "false" &&
                    <Loader fullPage loading />
                )}
                <div className="row">
                    <div className="col-md-3">
                        
                        <div class="col-md-12 col-sm-12">
                            <div class="widget widget-stats bg-green">
                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                <div class="stats-info">
                                    <h4>Activated Users</h4>
                                    <p>{this.state.userCount}</p>	
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="widget widget-stats bg-green">
                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                <div class="stats-info">
                                    <h4>Load Wallet Request</h4>
                                    <p>{this.state.loadCount}</p>	
                                </div>
                            </div>
                        </div>
                    
                        {/* <div class="col-md-12 col-sm-12">
                        
                                <legend>Vehicles</legend>
                                <div className="row">
                                    <ul>
                                        <li>Sedan</li>
                                        <li>Van</li>
                                    </ul>
                                </div>
                    
                        </div>  */}   
                    </div>
                    <div className="col-md-9">
                    <div className="panel" style={{marginTop: "5px"}}>
                            <div className="panel-body">
                                <div class="tableFixHead">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th style={{width: "5px"}}></th>
                                            <th>Fullname</th>
                                            <th>Contact </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.userLoad.map(userLoad =>
                                                <tr>
                                                    <td><i class="fa fa-2x fa-bars pointer" data-target="#modal-user" data-toggle="modal" id={userLoad.user.user_id} onClick={this.viewDetails.bind(this)}></i></td>
                                                    <td>{userLoad.user.user_details.lname+", "+userLoad.user.user_details.fname+" "+userLoad.user.user_details.mname}</td>
                                                    <td>{userLoad.user.contact.mobile}</td>
                                                    
                                                </tr>
                                            )}
                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal fade" id="modal-user">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header"> 
                            {this.state.viewDetails.map(details =>
                                <div>
                                    <h4 class="modal-title">{details.user.user_details.fname}</h4>
                                    <h4 class="modal-title">Current Balance: {this.state.currentBalance}</h4>
                                </div>
                            )} 
                    
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="fa fa-bookmark fa-3x text-info"></div>
                                                <div className="ml-3">
                                                    <div className="small">Total Request</div>
                                                    <div className="text-large">{this.state.viewServices.length}</div>
                                                    <div className="stats-desc small">Cancelled Trips : {this.state.cancelled}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="div-left mb-4">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="fa fa-alert fa-3x text-success"></div>
                                                    <div className="ml-3">
                                                        <div className="text-muted small">Complains</div>
                                                        <div className="text-large">{this.state.incident}</div>
                                                        <div className="stats-desc text-muted small">Total :</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Tabs>
                                            <TabList>
                                            <Tab>Trip History</Tab>
                                            <Tab>Load Wallet Request</Tab>
                                            <Tab>Load Wallet History</Tab>
                                            </TabList>
                                        
                                            <TabPanel>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <div class="tableFixHead">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "5px"}}></th>
                                                            <th>Driver</th>
                                                            <th>Address </th>
                                                            <th>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.viewServices.map(details =>
                                                                <tr>
                                                                    <td><i class="fa fa-2x fa-bars pointer" id={details.id} onClick={this.modalServices} data-target="#modal-trip" data-toggle="modal"></i></td>
                                                                    <td>{details.driver.user_details.lname+","+details.driver.user_details.fname+" "+details.driver.user_details.mname}</td>
                                                                    <td>{details.location.place}</td>
                                                                    <td>{details.status}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                            </TabPanel>
                                            <TabPanel>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <div class="tableFixHead">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "5px"}}></th>
                                                            <th>Mode</th>
                                                            <th>Receipt </th>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.viewLoad.map(load =>{
                                                                if(load.load.status === "Pending")
                                                                return <tr>
                                                                    <td><i class="fa fa-2x fa-bars pointer" id={load.load.id} onClick={this.modalLoad} data-target="#modal-load" data-toggle="modal"></i></td>
                                                                    <td>{load.load.mode}</td>
                                                                    <td>{load.load.receipt}</td>
                                                                    <td>{load.load.date}</td>
                                                                    <td>{load.load.status}</td>
                                                                </tr>
                                                            }
                                                            )}
                                                         
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                            </TabPanel>
                                            <TabPanel>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <div class="tableFixHead">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "5px"}}></th>
                                                            <th>Requestor</th>
                                                            <th>Address </th>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.viewHistory.map(load =>{
                                                            if(load.load.status !== "Pending")
                                                            return <tr>
                                                                <td><i class="fa fa-2x fa-bars pointer" id={load.load.id} onClick={this.modalHistory} data-target="#modal-loadHistory" data-toggle="modal"></i></td>
                                                                <td>{load.load.mode}</td>
                                                                <td>{load.load.receipt}</td>
                                                                <td>{load.load.date}</td>
                                                                <td>{load.load.status}</td>
                                                            </tr>
                                                        }
                                                        )}
                                                         
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="modal-trip">
                    <div class="modal-dialog modal-mm">
                        <div class="modal-content">
                            <div class="modal-header">  
                                
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                    <div>
                                        <table className="table table-border">
                                                {this.state.modalServices.map(trip =>
                                                    <tbody>
                                                    <tr>
                                                        <td>Requestor:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.user_details.fname+" "+trip.user_details.mname+" "+trip.user_details.lname}></input></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Service type:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.service.type}></input></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Payment method:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.service.payment_method}></input></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date of service:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.service.date_service}></input></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Vehicle used:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.service.vehicle}></input></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fare:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.service.fare}></input></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status:</td>
                                                        <td><input type="text" className="form-control" name="tableID" value={trip.status}></input></td>
                                                    </tr>
                                                </tbody>
                                                )}
                                        </table>
                                    </div>   
                                    </div>
                                    <div className="col-md-12">
                                    <center><button className="btn btn-sm btn-success m-r-9 pointer">View Map</button> <button className="btn btn-sm btn-danger m-r-9 pointer">View Incidents</button></center>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div id="modal-load" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div class="modal-header">
                            {/* {this.state.modalData.map(data =>
                                <h5 class="modal-title">Fieldman: {data.user_fname} {data.user_lname}</h5>
                            )} */}
                                <h5 class="modal-title"> </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            

                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div class="col-md-7">
                                    {this.state.soloPicture.map((solo, i) =>{
                                        if(solo === undefined || solo === "")
                                        return <div><Magnifier mgShape="square"  src={noimage} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/></div>
                                        return <div><Magnifier mgShape="square"  src={solo.pic} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/>
                                                    <div style={{marginTop: 15, overflowX:"scroll", whiteSpace: "nowrap", height: 75, width: 435}}>
                                                        {this.state.receiptPicture.map(img=>
                                                            <img className="pointer" src={img.pic} style={{width : 55, height : 55, marginLeft : 11}} />
                                                        )}
                                                    </div>
                                                </div>
                                    })}
                                        
                                    </div>
                                    <div class="col-md-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.state.modalLoad.map(load =>
                                                <center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={() => this.approveLoad(load)}>Approved</button> <button className="btn btn-sm btn-danger m-r-9 pointer" onClick={() => this.deniedLoad(load)}>Denied</button></center>
                                            )}
                                            
                                        </div>
                                        <div class="col-md-12"><p> </p></div>
                                            <div class="col-md-12">
                                                <div>
                                                    <table className="table table-border">
                                                        {this.state.modalLoad.map(load =>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Mode:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.mode}></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Receipt:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.receipt
                                                                    }></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Price:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.price}></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Date:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.date}></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Status:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.status}></input></td>
                                                                </tr>
                                                            </tbody>
                                                        )}

                                                    </table>
                                                </div>
                                        </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
                            </div>

                        </div>
                    </div>
                </div>

                 <div id="modal-loadHistory" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div class="modal-header">
                            {/* {this.state.modalData.map(data =>
                                <h5 class="modal-title">Fieldman: {data.user_fname} {data.user_lname}</h5>
                            )} */}
                                <h5 class="modal-title"> </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            

                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div class="col-md-7">
                                    {this.state.historysolo.map((solo, i) =>{
                                        if(solo === undefined || solo === "")
                                        return <div><Magnifier mgShape="square"  src={noimage} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/></div>
                                        return <div><Magnifier mgShape="square"  src={solo.pic} key={i} style={{width : 435, height : 455, marginLeft : 11, backgroundColor: "#95a5a6"}}/>
                                                    <div style={{marginTop: 15, overflowX:"scroll", whiteSpace: "nowrap", height: 75, width: 435}}>
                                                        {this.state.historyimage.map(img=>
                                                            <img className="pointer" src={img.pic} style={{width : 55, height : 55, marginLeft : 11}} />
                                                        )}
                                                    </div>
                                                </div>
                                    })}
                                        
                                    </div>
                                    <div class="col-md-5">
                                    <div className="row">
                                        {/* <div className="col-md-12">
                                            {this.state.viewLoad.map(load =>
                                                <center><button className="btn btn-sm btn-success m-r-9 pointer" onClick={() => this.approveLoad(load)}>Approved</button> <button className="btn btn-sm btn-danger m-r-9 pointer" onClick={() => this.deniedLoad(load)}>Denied</button></center>
                                            )}
                                            
                                        </div> */}
                                        {/* <div class="col-md-12"><p> </p></div> */}
                                            <div class="col-md-12">
                                                <div>
                                                    <table className="table table-border">
                                                        {this.state.viewHistory.map(load =>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Mode:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.mode}></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Receipt:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.receipt
                                                                    }></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Price:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.price}></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Date:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.date}></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Status:</td>
                                                                    <td><input type="text" className="form-control" name="tableID" value={load.load.status}></input></td>
                                                                </tr>
                                                            </tbody>
                                                        )}

                                                    </table>
                                                </div>
                                        </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <a href="javascript:;" class="btn btn-sm btn-white" data-dismiss="modal">Close</a>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
         );
    }
}
 
export default User;