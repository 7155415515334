import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-blue">
                            <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                            <div className="stats-content">
                                <div className="stats-title text-white">Assets</div>
                                <div className="stats-number text-white">125</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-info">
                            <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                            <div className="stats-content">
                                <div className="stats-title text-white">Licenses</div>
                                <div className="stats-number text-white">10</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-success">
                            <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                            <div className="stats-content">
                                <div className="stats-title text-white">Issues</div>
                                <div className="stats-number text-white">183</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="widget widget-stats bg-warning">
                            <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                            <div className="stats-content">
                                <div className="stats-title text-white">For Maintenance</div>
                                <div className="stats-number text-white">139</div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        );
    }
}
export default Cards;