import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup, } from '@progress/kendo-react-excel-export';
import { set } from 'date-fns/esm';
import moment from 'moment';



class Approval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date().toLocaleString(),
            refresh: false,
            selectChange: "",
            DAR: [],
            Company: [],
            DATA: [],
            Branches: [],
            Branch: "",
            hideBranch: true,
            hideDate: true,
            from_date: new Date(),
            to_date: new Date(),
            from: "",
            to: "",
            company_id: "",
            Approve: [],
            ApproveDar: [],
            searchDriver: "",
            REQHeader: [],
            APPHeader: [],
            Navigation: 'Daily Accomplishment Report',
            type: '',
            approvalRequest: [],
            userRequest: [],
            comment: '',
            hidebutton: false,
            totalhours: '',
            start: 0,
            interval: 300,
            bgcolor1: "#404040",
            bgcolor2: "transparent",
            bgcolor3: "transparent",
            bgcolor4: "transparent",
            bgcolor5: "transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            txtcolor3: "black",
            txtcolor4: "black",
            txtcolor5: "black",
            navbar: "navigation1",


        };
        this.onChangeSelect = this.onChangeSelect.bind(this)
        this.branch = this.branch.bind(this)
        this.filterDateStart = this.filterDateStart.bind(this)
        this.filterDateEnd = this.filterDateEnd.bind(this)
        this.textchange = this.textchange.bind(this)
    }

    componentDidMount() {
        this.checkPagePrivilege();
        // this.requestOvertime2()
    }
    data() {
        var request = []
        setInterval(() => {
            request = JSON.parse(localStorage.getItem('request_detail'));

            if (this.state.start == 5) {
                var data = []
                localStorage.setItem('request_detail', JSON.stringify(data))
                this.setState({ start: 0 });
            } if (request.req_id != undefined) {
                this.state.start++
            }

            var split_url = String(window.location.href).split('/');

            var split = split_url[4] + '/' + split_url[5]


            if (request.req_id != undefined && split == 'pocketHR/approval') {
                // console.log(request.req_id +'-'+split);
                this.setState({ Navigation: request.req_type + ' ' + 'Request', type: request.req_type });

                this.requestOvertime2(request.req_id)

            } else {
                var data = []
                localStorage.setItem('request_detail', JSON.stringify(data))
                this.setState({ start: 0 });

            }

        }, this.state.interval);
    }
    checkPagePrivilege() {
        var route = "#/pocketHR/approval";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })
            .then(response => {
                // console.log(response);
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                    this.get_company();
                    this.DAR();
                    this.data()
                }
            })
    }
    textchange = e => {

        this.setState({ [e.target.name]: e.target.value });


    }
    DAR() {
        var data = [];
        data.push("No.");
        data.push("Emp ID");
        data.push("Name");
        data.push("Type");
        data.push("Description");
        data.push("Date Start");
        data.push("Date End");
        data.push("Action");
        this.setState({ REQHeader: data })

    }
    DARDISPLAY() {
        let EmployeeSearch = this.state.DAR.filter(
            (files) => {
                return files.complete_name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        let EmployeeSearch_Approve = this.state.ApproveDar.filter(
            (files) => {
                return files.user_name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return <div>
            <div className="navmedia">
                <div>
                    <button className="btttn bg-green-transparent-9  m-b-10 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;Generate Record</button>
                </div>
                <div>
                    <div className="input-group pull-right" style={{ width: 250 }}>
                        <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                        <span class="input-group-addon"><i className="fa fa-search"></i></span>
                    </div>
                </div>
            </div>


            <div className="panel">
                <div className="panel-heading">
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        {this.state.DAR.length != 0 &&
                            <div>
                                <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt m-l-5">
                                    <a href="javascript:;" style={{ color: "white", textDecoration: "none" }} onClick={() => { this._export1.save(); }}>&nbsp;Download&nbsp;Accomplishments</a>
                                </button>
                                <ExcelExport data={EmployeeSearch} fileName="Dar.xlsx" ref={(exporter1) => { this._export1 = exporter1; }}>
                                    <ExcelExportColumn field="emp_id" title="Emp ID" locked={false} width={200} />
                                    <ExcelExportColumn field="user_name" title="Name" locked={false} width={200} />
                                    <ExcelExportColumn field="accom_type" title="Type" locked={false} width={200} />
                                    <ExcelExportColumn field="accom_description" title="Description" locked={false} width={200} />
                                    <ExcelExportColumn field="accom_date_start" title="Date Start" locked={false} width={200} />
                                    <ExcelExportColumn field="accom_date_end" title="Date End" locked={false} width={200} />
                                </ExcelExport>

                                <button className="btttn bg-blue-lighter fas fa-check-double m-l-5" onClick={() => {
                                    for (let index = 0; index < this.state.DAR.length; index++) {
                                        this.state.Approve.push(this.state.DAR[index])
                                    }
                                    this.setState({ refresh: true })
                                }}>
                                    &nbsp;Select&nbsp;All
                    </button>
                            </div>
                        }
                        {this.state.Approve != 0 &&
                            <div>
                                <button className="btttn bg-red-transparent-9 fas fa-sync-alt m-l-5" onClick={() => { this.setState({ refresh: true, Approve: [] }) }}>
                                    &nbsp;Deselect&nbsp;All
                    </button>
                                <button className="btttn bg-blue-transparent-9 fas fa-check-circle m-l-5" onClick={() => { this.Approve(); }}>
                                    &nbsp;Approve&nbsp;Selected
                    </button>
                            </div>

                        }
                    </div>

                </div>
                <div className="panel-body">
                    <Scrollbars style={{ height: 400 }}>
                        <table className="table table-td-valign-middle table-bordered">
                            <thead className="tableFixHead">
                                <tr>
                                    <th>No.</th>
                                    <th>Emp ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th width='20%'>Description</th>
                                    <th>Date Start</th>
                                    <th>Date End</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {EmployeeSearch.map((val, index) => {
                                    var match = false;
                                    for (let index = 0; index < this.state.Approve.length; index++) {
                                        if (this.state.Approve[index].accom_id == val.accom_id) {
                                            match = true;
                                        }

                                    }
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>{val.emp_id}</td>
                                        <td>{val.complete_name}</td>
                                        <td>{val.accom_type}</td>
                                        <td width='20%'>{val.accom_description}</td>
                                        <td>{moment(val.accom_date_start).format('LLL')}</td>
                                        <td>{moment(val.accom_date_end).format('LLL')}</td>
                                        {match == false &&
                                            <td>
                                                <button className="btttn bg-green-transparent-9 fas fa-check-circle" onClick={() => { this.state.Approve.push(val); this.setState({ refresh: true }) }}>
                                                    &nbsp;Approve
                                                </button>
                                                {/* <button className="bttnSelect bg-green-lighter m-t-5" onClick={() => { this.state.Approve.push(val); this.setState({ refresh: true }) }}>
                                                    <span className="fa fa-check"></span>
                                                </button> */}
                                            </td>
                                        }  {match == true &&
                                            <td>
                                                <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none" onClick={() => {
                                                    for (let index = 0; index < this.state.Approve.length; index++) {
                                                        if (this.state.Approve[index].accom_id == val.accom_id) {
                                                            this.state.Approve.splice(parseInt(index), 1)
                                                            this.setState({ refresh: true })
                                                        }
                                                    }
                                                }}>
                                                    &nbsp;Cancel
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                })}

                            </tbody>
                        </table>

                    </Scrollbars>
                    <p className='pull-right m-t-5'><b>Total :</b> {this.state.DAR.length}</p>
                </div>
            </div>
            <hr />
            <div className='panel panel-default'>
                <div className="panel-heading">
                    <button className="btttn bg-green-transparent-9 fas fa-cloud-download-alt pull-left m-b-15">
                        <a href="javascript:;" style={{ color: "white", textDecoration: "none" }} onClick={() => { this._export.save(); }}>&nbsp;Download&nbsp;Approved&nbsp;Accomplishments</a>
                    </button>
                    <ExcelExport
                        data={EmployeeSearch_Approve}
                        fileName="Approved_Accomplishments.xlsx"
                        ref={(exporter) => { this._export = exporter; }}
                    >
                        <ExcelExportColumn field="mru" title="No." locked={false} width={200} />
                        <ExcelExportColumn field="emp_id" title="Emp ID" locked={false} width={200} />
                        <ExcelExportColumn field="user_name" title="Name" locked={false} width={200} />
                        <ExcelExportColumn field="accom_type" title="Type" locked={false} width={200} />
                        <ExcelExportColumn field="accom_description" title="Description" locked={false} width={200} />
                        <ExcelExportColumn field="accom_date_start" title="Date Start" locked={false} width={200} />
                        <ExcelExportColumn field="accom_date_end" title="Date End" locked={false} width={200} />
                        <ExcelExportColumn field="approver_name" title="Approver" locked={false} width={200} />
                        <ExcelExportColumn field="date_approve" title="Date Approve" locked={false} width={200} />
                    </ExcelExport>
                </div>
                <div className='panel-body'>
                    <Scrollbars style={{ height: 400 }}>
                        <table className="table table-td-valign-middle table-bordered">
                            <thead className="tableFixHead">
                                <tr>
                                    <th>No.</th>
                                    <th>Emp ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Date Start</th>
                                    <th>Date End</th>
                                    <th>Approver</th>
                                    <th>Date Approve</th>

                                </tr>
                            </thead>
                            <tbody>
                                {EmployeeSearch_Approve.map((val, index) => {

                                    return <tr style={{ backgroundColor: "#0f9e3e", color: 'white' }}>
                                        <td>{index + 1}</td>
                                        <td>{val.emp_id}</td>
                                        <td>{val.user_name}</td>
                                        <td>{val.accom_type}</td>
                                        <td>{val.accom_description}</td>
                                        <td>{val.accom_date_start}</td>
                                        <td>{val.accom_date_end}</td>
                                        <td>{val.approver_name}</td>
                                        <td>{val.date_approve}</td>

                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </Scrollbars>
                    <p className='pull-right m-t-5'><b>Total :</b> {this.state.ApproveDar.length}</p>
                </div>
            </div>
        </div>
    }
    RequestDisplay() {
        let EmployeeSearch = this.state.approvalRequest.filter(
            (files) => {
                return files.user_lname.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.user_fname.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return <div>
            <div style={{ width: 200, marginTop: 5 }} className="input-group pull-right" >
                <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />

            </div>
            <br /><br /><br />
            <div className='panel'>
                <div className='panel-body'>
                    <Scrollbars style={{ height: 400 }}>
                        <table className="table table-td-valign-middle table-bordered">
                            <thead className="tableFixHead">
                                <tr>

                                    {/* <th>No.</th> */}
                                    <th>Emp ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th width='150px'>Justification</th>
                                    <th>Date Start</th>
                                    <th>Date End</th>
                                    <th>Status</th>
                                    {/* <th>Action</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {EmployeeSearch.map((val, index) => {

                                    if (val.req_type == this.state.type)
                                        return <tr style={{ cursor: "pointer" }} data-target="#demo-lg-modalRequest" data-toggle="modal" onClick={() => {
                                            var data = [];
                                            data.push(val);
                                            this.setState({ userRequest: data, start: 0 })
                                            localStorage.setItem('request_detail', JSON.stringify(data))
                                        }}>
                                            {/* <td>{index + 1}</td> */}
                                            <td>{val.user_id}</td>
                                            <td>{val.user_lname + ' ' + val.user_fname}</td>
                                            <td>{val.req_type}</td>
                                            <td width='150px'>{val.req_justification}</td>
                                            <td>{val.req_date_start}</td>
                                            <td>{val.req_date_end}</td>
                                            <td>{val.req_approval_status}</td>

                                            {/* <td><button className="bttnSelect m-t-5" onClick={() => {
                                                this.state.Approve.push(val)

                                                this.setState({ refresh: true })
                                            }}>
                                                <span className="fa fa-check"></span>
                                            </button></td> */}


                                        </tr>
                                })}

                            </tbody>
                        </table>

                    </Scrollbars>
                    <p className='pull-right m-t-5'><b>Total :</b> {EmployeeSearch.length}</p>

                </div>


            </div>



        </div>

    }
    _export
    _export1
    filterDateStart = e => {
        setTimeout(() => {
            this.setState({
                from_date: e
            });

            var from = String(e);
            var split_from = from.split(" ");
            var from_date_spit = split_from[3] + "-" + split_from[1] + "-" + split_from[2];
            this.setState({ from: from_date_spit });
        }, 100)

        // console.log(this.state)
    }
    filterDateEnd = e => {
        setTimeout(() => {
            this.setState({
                to_date: e
            });
            var to = String(e);
            var split_to = to.split(" ");
            var to_date_spit = split_to[3] + "-" + split_to[1] + "-" + split_to[2];
            this.setState({ to: to_date_spit });
        }, 100)
        // console.log(this.state)
    }

    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ selectChange: options[i].value })

            }
        }
    }
    get_company() {
        getData("users/getCompanies", localStorage.getItem("u"))
            .then(response => {

                this.setState({ DATA: response.company });
                var data = [];
                for (let i = 0; i < response.company.length; i++) {
                    var match = false;
                    for (let index = 0; index < data.length; index++) {
                        if (data[index].companyName == response.company[i].company_name) {
                            match = true;
                        }
                    }
                    var data2 = [];
                    if (match == false) {
                        data2 = { companyName: response.company[i].company_name, company_id: response.company[i].company_id };
                        data.push(data2);
                    }
                }
                this.setState({ Company: data });

            });

    }
    passCompany = e => {
        var options = e.target.options;
        var value = [];
        var company = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
                value.push(options[i].value);
            }
        }
        this.setState({ company_id: company });
        var data = [];
        for (let index = 0; index < this.state.DATA.length; index++) {
            var data2 = [];
            if (this.state.DATA[index].company_id == company) {
                data2 = { companyName: this.state.DATA[index].company_name, company_id: this.state.DATA[index].company_id, branch_id: this.state.DATA[index].branch_id, branch_name: this.state.DATA[index].branch_name };
                data.push(data2);
            }
        }
        this.setState({ Branches: data, Branch: '' })
        this.setState({ hideBranch: false })
        this.setState({ refresh: true });
    }
    branch = e => {


        var options = e.target.options;
        var value = [];
        var branch = "";
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                branch = options[i].value;
                this.setState({ Branch: branch, hideDate: false })
            }
        }
    }
    getDataDetails() {
        var b_id = "";
        for (let index = 0; index < this.state.Branches.length; index++) {

            if (this.state.Branches[index].branch_name == this.state.Branch && this.state.company_id == this.state.Branches[index].company_id) {
                b_id = this.state.Branches[index].branch_id
            }

        }

        var details = { company_id: this.state.company_id, branch_id: b_id, from: this.state.from_date, to: this.state.to_date }

        getData('humanResource/DarRequest', { u_id: localStorage.getItem('u'), type: "admin", details: details })
            .then((response) => {
                console.log(response)
                if (response.dar.length == 0) {
                    alert('No pending acccomplishment');
                } 
                this.setState({ DAR: response.dar, ApproveDar: response.approveDar });

            })
    }
    Approve() {

        getData('humanResource/DarApprove', { data: this.state.Approve, approver: localStorage.getItem('u') })
            .then((response) => {
                console.log(response)
                if (response.response == 'Success') {
                    this.getDataDetails();
                    this.setState({ Approve: [] })
                }
            })
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    requestOvertime2(data) {

        getData('humanResource/getRequest', { user_id: localStorage.getItem('u'), app_id: 7, branch_id: '', req_lenght: this.state.approvalRequest.length })
            .then((response) => {

                // if( response.requests.length != 0 && response.approval_requests.length != 0 ){

                // var request = JSON.parse(localStorage.getItem('request_detail'));

                // if (request.length == 0) {

                // } else {

                if (data != undefined) {
                    var match = false
                    var data_req = [];
                    for (let index = 0; index < response.approval_requests.length; index++) {


                        if (response.approval_requests[index].req_id == data) {
                            data_req.push(response.approval_requests[index]);

                        }
                    }

                    setTimeout(() => {
                        // if(match == false){
                        this.setState({ approvalRequest: data_req });
                        //     this.setState({ approvalRequest: response.approval_requests });
                        // }else{

                        var data = []
                        //     if(this.state.start == 3){

                        // localStorage.setItem('request_detail', JSON.stringify(data))
                        //     }
                        //   this.data()   

                        // }


                    }, 200);
                }



                // }

                // }

            })
    }
    requestOvertime() {

        getData('humanResource/getRequest', { user_id: localStorage.getItem('u'), app_id: 7, branch_id: '', req_lenght: this.state.approvalRequest.length })
            .then((response) => {

                // if( response.requests.length != 0 && response.approval_requests.length != 0 ){

                var request = JSON.parse(localStorage.getItem('request_detail'));

                // if (request.length == 0) {

                // } else {
                var match = false
                var data_req = [];

                for (let index = 0; index < response.approval_requests.length; index++) {

                    if (response.approval_requests[index].req_id == request.req_id) {
                        data_req.push(response.approval_requests[index]);

                    } else {
                        data_req.push(response.approval_requests[index]);
                    }

                }
                console.log(response.approval_requests)
                localStorage.setItem('totalNoti', data_req.length)
                localStorage.setItem('totalNotiDetails', JSON.stringify(data_req))
                this.setState({ refresh: true })
                setTimeout(() => {
                    // if(match == false){
                    this.setState({ approvalRequest: data_req });
                    //     this.setState({ approvalRequest: response.approval_requests });
                    // }else{

                    var data = []
                    //     if(this.state.start == 3){

                    localStorage.setItem('request_detail', JSON.stringify(data))

                    //     }


                    // }


                }, 200);


                // }

                // }

            })
    }
    ApproveRequest() {

        var data = {
            req_id: this.state.userRequest[0].req_id,
            req_approval_id: this.state.userRequest[0].req_approval_id,
            status: 'Approved',
            req_hierarchy_level: this.state.userRequest[0].req_hierarchy_level,
            approver: "",
            comment: this.state.comment,
            app_id: 7,
            approver_id: localStorage.getItem('u'),
        }

        getData('HumanResource/changeRequestStatus', data)
            .then((response) => {
                alert('Request Approved')
                setTimeout(() => {
                    this.requestOvertime()
                }, 500);

            })

    }
    DeniedRequest() {

        var data = {
            req_id: this.state.userRequest[0].req_id,
            req_approval_id: this.state.userRequest[0].req_approval_id,
            status: 'Denied',
            req_hierarchy_level: this.state.userRequest[0].req_hierarchy_level,
            approver: "",
            comment: this.state.comment,
            app_id: 7,
            approver_id: localStorage.getItem('u')
        }

        getData('HumanResource/changeRequestStatus', data)
            .then((response) => {

                // if (response == "") {
                alert("Request Denied");
                setTimeout(() => {
                    this.requestOvertime()
                }, 500);
                // }
            })

    }


    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }

        return (
            <div id="content" className="content">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                {this.state.navbar === "navigation1" &&
                                    <h4 className="page-header"><i className="fas fa-file-signature text-black"></i>&nbsp;Accomplishment Record</h4>
                                }
                                {this.state.navbar === "navigation2" &&
                                    <h4 className="page-header"><i className="fas fa-file-signature text-black"></i>&nbsp;Employees' Overtime</h4>
                                }
                                {this.state.navbar === "navigation3" &&
                                    <h4 className="page-header"><i className="fas fa-file-signature text-black"></i>&nbsp;Employees' Undertime</h4>
                                }
                                {this.state.navbar === "navigation4" &&
                                    <h4 className="page-header"><i className="fas fa-file-signature text-black"></i>&nbsp;Employees Leave</h4>
                                }
                                {this.state.navbar === "navigation5" &&
                                    <h4 className="page-header"><i className="fas fa-file-signature text-black"></i>&nbsp;Official Business</h4>
                                }
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Today's Attendance</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Employees' Overtime</a></li>
                                        }
                                        {this.state.navbar === "navigation3" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Employees Undertime</a></li>
                                        }
                                        {this.state.navbar === "navigation4" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Employees' Leave</a></li>
                                        }
                                        {this.state.navbar === "navigation5" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Official Business</a></li>
                                        }
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav">
                                <div className="attendnav">
                                    <a style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}
                                        onClick={() => {
                                            this.setState({ navbar: "navigation1" })
                                            this.setState({ Navigation: 'Daily Accomplishment Report' });
                                            this.setState({ bgcolor1: "#404040", txtcolor1: "white" });
                                            this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                                            this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                            this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                                            this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                                        }}>DAR</a>
                                </div>
                                <div className="attendnav">
                                    <a style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}
                                        onClick={() => {
                                            this.setState({ navbar: "navigation2" })
                                            this.setState({ Navigation: 'Overtime Request', type: 'Overtime' }, this.requestOvertime());
                                            this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                                            this.setState({ bgcolor2: "#404040", txtcolor2: "white" });
                                            this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                            this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                                            this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                                        }}>Overtime</a>
                                </div>
                                <div className="attendnav">
                                    <a style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}
                                        onClick={() => {
                                            this.setState({ navbar: "navigation3" })
                                            this.setState({ Navigation: 'Undertime Request', type: 'Undertime' }, this.requestOvertime());
                                            this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                                            this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                                            this.setState({ bgcolor3: "#404040", txtcolor3: "white" });
                                            this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                                            this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                                        }}>Undertime</a>
                                </div><div className="attendnav">
                                    <a style={{ color: this.state.txtcolor4, backgroundColor: this.state.bgcolor4 }}
                                        onClick={() => {
                                            this.setState({ navbar: "navigation4" })
                                            this.setState({ Navigation: 'Leave Request', type: 'Leave' }, this.requestOvertime());
                                            this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                                            this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                                            this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                            this.setState({ bgcolor4: "#404040", txtcolor4: "white" });
                                            this.setState({ bgcolor5: "transparent", txtcolor5: "black" });
                                        }}>Leave</a>
                                </div>
                                <div className="attendnav">
                                    <a style={{ color: this.state.txtcolor5, backgroundColor: this.state.bgcolor5 }}
                                        onClick={() => {
                                            this.setState({ navbar: "navigation5" })
                                            this.setState({ Navigation: 'Official Business Request', type: 'Official Business' }, this.requestOvertime());
                                            this.setState({ bgcolor1: "transparent", txtcolor1: "black" });
                                            this.setState({ bgcolor2: "transparent", txtcolor2: "black" });
                                            this.setState({ bgcolor3: "transparent", txtcolor3: "black" });
                                            this.setState({ bgcolor4: "transparent", txtcolor4: "black" });
                                            this.setState({ bgcolor5: "#404040", txtcolor5: "white" });
                                        }}>OB</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="panel-body">
                        {this.state.Navigation == 'Daily Accomplishment Report' &&
                            this.DARDISPLAY()
                        }{(this.state.Navigation == 'Overtime Request' || this.state.Navigation == 'Undertime Request' || this.state.Navigation == 'Official Business Request' || this.state.Navigation == 'Leave Request') &&
                            this.RequestDisplay()
                        }
                    </div>
                </div>

                <div id="demo-lg-modal1" className="modal" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Date-Range</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <form onSubmit={this.FormSubmit}> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>From date :</label>
                                        <br />
                                        <DatePicker
                                            selected={this.state.from_date}
                                            selectsStart
                                            startDate={this.state.from_date}
                                            endDate={this.state.to_date}
                                            onChange={this.filterDateStart}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>To date :</label>
                                        <br />
                                        <DatePicker
                                            selected={this.state.to_date}
                                            selectsEnd
                                            startDate={this.state.from_date}
                                            endDate={this.state.to_date}
                                            onChange={this.filterDateEnd}
                                            className="form-control"
                                        />
                                    </div>

                                </div>
                                <button onClick={() => { this.getDataDetails() }}
                                    className="bttnsub bg-green-lighter pull-right m-t-25">
                                    <span className="fa fa-folder-plus fa-sm"></span>
                                </button>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="demo-lg-modalRequest" className="modal fade" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        {this.state.userRequest.map((val, index) => {

                            var hierarchy = JSON.parse(val.req_hierarchy_structure);

                            return <div className="modal-content">
                                <div className="modal-header">
                                    <h4>{this.state.type} Request</h4>
                                    <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                        <span className="fa fa-times-circle fa-sm"></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Scrollbars style={{ height: 400 }}>

                                        <div>
                                            <div>
                                                <h5><b>Employee Details</b></h5>
                                            </div>
                                            <div className='panel solid'>
                                                <div className='panel-body'>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <h6><b style={{ marginRight: 18 }}>Emp ID</b> : <span>{val.user_id}</span> </h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <h6><b>Requestor</b> : <span>{val.user_lname + ' ' + val.user_fname}</span> </h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <h6><b style={{ marginRight: 18 }}>Branch</b> : <span>{val.branch_name}</span> </h6>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <h5><b>Request Details</b></h5>
                                            </div>
                                            <div className='panel solid'>

                                                <div className='panel-body'>
                                                    <div className='form-group'>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <h6><b>Time Start</b> : <span>{val.req_date_start}</span></h6>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <h6><b className='m-r-5'>Time End</b> : <span>{val.req_date_end}</span></h6>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <h6><b className='m-r-5'>Total Hours </b> : </h6>
                                                                {this.state.hidebutton == false &&
                                                                    <input className="form-control" value={val.req_hours_approved} disabled />}
                                                                {this.state.hidebutton == true &&

                                                                    <input className="form-control" value={val.req_hours_approved} onChange={this.textchange} name="totalhours" required />
                                                                }
                                                                {/* <button className="btn bg-green-lighter text-white" onClick={() => {this.setState({hidebutton: !this.state.hidebutton})}}>Edit</button> */}

                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <h6><b>Justification* </b></h6>

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <textarea style={{ height: 100 }} className='form-control' value={val.req_justification} readOnly></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <h5><b>Approver</b></h5>
                                            </div>
                                            <div className='panel solid'>

                                                <div className='panel-body'>
                                                    <div className='form-group'>
                                                        {hierarchy.map((val, index) => {

                                                            return <div key={index} className='row m-l-20'>
                                                                <h6 style={{ color: 'black' }}>{val.title}</h6>
                                                                <div className='col-md-12'>
                                                                    {val.approver.map((val1, index1) => {
                                                                        var status = 'Pending'
                                                                        if (index1 < val.req_hierarchy_level) {
                                                                            status = 'Approved'
                                                                        }
                                                                        return <h6><img src="../assets/img/user/user.jpg" alt="Avatar" style={{ borderRadius: "50%", width: "30px", marginRight: 15 }} /><b>{val1.name}</b> - <span>{status}</span></h6>
                                                                    })

                                                                    }

                                                                </div>
                                                            </div>
                                                        })}


                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <h5><b>Comment</b></h5>
                                            </div>
                                            <div className='panel solid'>
                                                <textarea placeholder='Comment Area' onChange={this.textchange} name='comment' className='form-control'></textarea>
                                            </div>
                                        </div>

                                    </Scrollbars>


                                    {/* </form> */}
                                    <div className='row pull-right'>
                                        <div className=' m-r-5'>
                                            <button data-dismiss="modal" onClick={() => { this.ApproveRequest() }}
                                                className="bttnrApprove bg-green-lighter  m-t-5 ">
                                                <span className="fa fa-check fa-sm"></span>
                                            </button>
                                        </div>
                                        <div className=' m-r-5'>
                                            <button data-dismiss="modal" onClick={() => { this.DeniedRequest() }}
                                                className="bttnrDenied bg-red-lighter m-t-5 ">
                                                <span className="fa fa-ban fa-sm"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        })}
                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="panel-title">New Accomplishments Record</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* <div className="flex-container m-t-5">
                                    <div>
                                        <label>Choose Company*</label>
                                        <select className="form-control" style={{ width: 200 }} onChange={this.passCompany}>
                                            <option></option>
                                            {this.state.Company.map((val, index) => {
                                                return <option value={val.company_id}>{val.companyName}</option>
                                            })}
                                        </select>
                                    </div>
                                    {!this.state.hideBranch &&
                                    <div className='m-l-5'>
                                        <label>Choose Branch*</label>
                                        <select className="form-control" value={this.state.Branch} style={{ width: 200 }} onChange={this.branch}>
                                            <option></option>
                                            {this.state.Branches.map((val, index) => {
                                                return <option >{val.branch_name}</option>
                                            })}
                                        </select>
                                    </div>
                                    }
                                    {!this.state.hideDate &&
                                    <div style={{ marginTop: 18 }}>
                                        <button className="bttncalendar bg-blue-lighter m-t-5" data-target="#demo-lg-modal1" data-toggle="modal">
                                            <span className="fa fa-calendar-alt"></span>
                                        </button>
                                    </div>
                                    }
                                </div> */}

                                <div className="row" >
                                    <div className="col-sm-12 m-b-10">
                                        <label for="exampleCity" style={{ color: "#130f40" }}>Select Date-range</label>
                                        <div className="form-inline">
                                            <div className="form-group ">
                                                <DatePicker
                                                    selected={this.state.from_date}
                                                    selectsStart
                                                    startDate={this.state.from_date}
                                                    endDate={this.state.to_date}
                                                    onChange={this.filterDateStart}
                                                    className="form-control"
                                                />
                                            </div>
                                            <i class="fas fa-link m-l-5"></i>
                                            <div className="form-group m-l-5" >
                                                <DatePicker
                                                    selected={this.state.to_date}
                                                    selectsEnd
                                                    startDate={this.state.from_date}
                                                    endDate={this.state.to_date}
                                                    onChange={this.filterDateEnd}
                                                    className="form-control"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 m-b-10">
                                        <label for="exampleCity" style={{ color: "#130f40" }}>Choose Company</label>
                                        <select className="form-control" onChange={this.passCompany}>
                                            <option></option>
                                            {this.state.Company.map((val, index) => {
                                                return <option value={val.company_id}>{val.companyName}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-sm-12 m-b-10">
                                        <label for="exampleCity" style={{ color: "#130f40" }}>Choose Branch</label>
                                        <select className="form-control" value={this.state.Branch} onChange={this.branch}>
                                            <option></option>
                                            {this.state.Branches.map((val, index) => {
                                                return <option >{val.branch_name}</option>
                                            })}
                                        </select>
                                    </div>

                                </div>
                                <button onClick={() => { this.getDataDetails() }}
                                    className="btttn bg-green-transparent-9 pull-right fas fa-plus-circle">
                                    &nbsp;Create
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Approval;