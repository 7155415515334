import React, { Component, Fragment } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect, Link } from 'react-router-dom'
import ReactExport from "react-data-export";
import PaySlip from './payslipDesign'
import ReactToPrint from 'react-to-print';
import $ from 'jquery'
import { connect } from 'react-redux'
import PrintPayroll from './payrollDesign'
import moment from 'moment'
import { LoadingOverlay, Loader} from 'react-overlay-loader';
import jsPDF from "jspdf";
import "jspdf-autotable"


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class PayrollEmployeeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            searchDriver: "",
            responsedata: [],
            deduction: [],
            earning: [],
            pay_id: '',
            listEmp: [],
            MonthPeriod: '',
            colspan_earning: 0,
            colspan_deduction: 0,
            filterStartDate: new Date(),
            filterEndDate: new Date(),
            selectUser: [],
            branch_id: [],
            loading: false,
            payroll_hierarchy: [],
            orientation: ''

        }
    }
    componentDidMount() {
        // let period = localStorage.getItem('MonthPeriod')
        // let start = period.split('-');
        // this.setState({loading:true})
        let pay = this.props.payroll_approver
            .filter((val) => (val.branch_id == this.props.match.params.branch_id))
            .reduce((data, val) => {
                if (val.payroll_hierarchy != '') {
                    JSON.parse(val.payroll_hierarchy).map((val2) =>
                        data.push(val2)
                    )
                }
                return data
            }
                , []
            )
        console.log(pay)
        let data = JSON.parse(localStorage.getItem('paydata'))
        this.setState({ payroll_hierarchy: pay, branch_id: this.props.match.params.branch_id, filterStartDate: moment(new Date(this.props.match.params.date_from)).format('Y-MM-DD'), filterEndDate: moment(new Date(this.props.match.params.date_to)).format('Y-MM-DD') })
        // console.log(start)
        // this.state.branch_id.push(data.branch_id);
        this.payroll_data(data)

    }
    TotalEarnings(earnings) {
        getData("humanResource/insertEarnings", { data: earnings })
            .then(response => {
                //
            })
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    generate() {
        this.setState({ loading: true })
        let employee_id = []
        employee_id.push(this.props.selected_employee_payroll.emp_id);
        // let selected_branch_id = this.state.branch_id;
        let sss = this.props.selected_employee_payroll.SSS_CONTRIBUTION
        let gov_contri = false
        let pid = this.props.selected_employee_payroll.pid
        if (sss != "") {
            gov_contri = true
        }

        this.setState({ alertWarning: false, alert: false, loading: true })
        getData("humanResource/payroll_group", { pid: pid, pay_id: this.state.pay_id, payroll_update: true, employee: employee_id, user_id: localStorage.getItem("u"), bid: this.state.branch_id, date_from: this.state.filterStartDate, date_to: this.state.filterEndDate, government_deduct: gov_contri })
            .then(response => {
                console.log(response);
                if (response == true) {
                    let data = JSON.parse(localStorage.getItem('paydata'))
                    this.payroll_data(data)
                    alert('Payroll has been updated.')

                } else {
                    alert('No changes to be updated.')
                    this.setState({ loading: false })
                }
            })
    }
    formatNumber(num) {
        let num2 = parseFloat(num).toFixed(2);
        return num2.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    generate2() {
        const orientation = 'landscape'
        const unit ='pt'
        const Size = 'legal'
        var doc = new jsPDF(orientation,unit,Size);
        doc.setFontSize(5)
        doc.fromHTML($('#heads').get(0), 5, 5, {'width': 180})
        $("#fontSize").css("font-size", "10px");
        doc.autoTable({
            startY:105,
            theme:'grid',
            html: '#mytable',
            bodyStyles: { fontSize: 6 ,margintTop:-10}, 
            headerStyles:{ fontSize: 6 ,valign: 'middle',
            halign : 'center'},
            willDrawCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    doc.setFontType('bold');
                }
            },
            
            didDrawCell: function (data) {
                // if (data.column.index === 5 && data.cell.section === 'body') {
                //     var td = data.cell.raw;
                //     // var img = td.getElementsByTagName('img')[0];
                //     var dim = data.cell.height - data.cell.padding('vertical');
                //     var textPos = data.cell.textPos;
                //     // doc.addImage(img.src, textPos.x, textPos.y, dim, dim);
                // }
            }
        });

        doc.save("table.pdf");
    }
    payroll_data = (data) => {
        // if(this.state.listEmp.length != 0 ){
        this.setState({ DATA: data, pay_id: data.p_id })
        // }
        getData("humanResource/getPayroll_details", { user_id: localStorage.getItem("u"), pay_id: data.p_id })
            .then(response => {
                console.log(response);
                const sssDataTable = response.sss_data;
                const annual_tax_data = response.annual_tax_data;
                var Listemp = [];
                var deduction = [];
                var earning = [];
                let gov_conti = []
                let hold_tax = 0;
                let Disburstment = [];
                // let PayrollSummary = [];
                let Disburstment_val = {
                    mobile: 'GCASH NO',
                    name: 'FULLNAME SURNAME',
                    amount: '',
                    remarks: 'SURNAME PAY DATE SALARY'
                }
                Disburstment.push(Disburstment_val);

                for (let i = 0; i < response.data.length; i++) {

                    if (response.data[i].data_schema['timesheet']['ot'].length != 0) {
                        var totalDeduction = 0;
                        var totalEarning = 0;
                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'].length; j++) {

                            var match = false;
                            for (let k = 0; k < deduction.length; k++) {
                                if (deduction[k].deduction_name == response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'][j]) {
                                    match = true;
                                }
                            }
                            var dudect_name = [];
                            if (match == false) {
                                dudect_name = { deduction_name: response.data[i].data_schema['earning_deduction']['deduction']['deduction_name'][j] }
                                deduction.push(dudect_name);
                            }

                        }

                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_name'].length; j++) {

                            var match = false;
                            for (let k = 0; k < earning.length; k++) {
                                if (earning[k].earning_name == response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j]) {
                                    match = true;
                                }
                            }
                            var earn_name = [];
                            if (match == false) {
                                earn_name = { earning_name: response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j] }
                                earning.push(earn_name);
                            }

                        }
                        var valuecolde = deduction.length + 23;
                        var valuecol = earning.length + 38;
                        this.setState({ colspan_earning: valuecol, colspan_deduction: valuecolde })

                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'].length; j++) {
                            totalDeduction += parseFloat(response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'][j]);

                        }
                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
                            totalEarning += parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
                        }
                        // console.log(response.data[i].data_schema['earning_deduction']['earning']['earning_amount']);

                        // totalEarning += parseFloat(response.data[i].data_schema['timesheet']['basic_amount_additional']);
                        totalDeduction += parseFloat(response.data[i].data_schema['timesheet']['basic_amount_deduction']);

                        var details = [];
                        var firstCUT_off = 0;
                        var secondCUT_off = 0;
                        var totalCUT_off = 0;
                        var SSS_CONTRIBUTION = 0;
                        var SSS_EC = 0;
                        var SSS_ER = 0;
                        var SSS_EE = 0;



                        let Allowance = 0;
                        for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
                            // if (response.data[i].data_schema['earning_deduction']['earning']['earning_name'][j].toUpperCase() == ("Allowance").toUpperCase()) {
                            Allowance = parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
                            // }


                        }

                        if (response.data[i].government_contribution == 1) {
                            // let total_gross = parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])

                            // console.log(Allowance);
                            let totalEarning_sss = (parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + Allowance + parseFloat(response.data[i].data_schema['timesheet']['total_earning']))

                            const sssContributionData = sssDataTable.filter((val) => {
                                if (parseFloat(val.rangefrom) <= totalEarning_sss && parseFloat(val.rangeto) >= totalEarning_sss) {
                                    return val
                                }
                            }
                            )
                            let sss = 0;
                            for (let i = 0; i < sssContributionData.length; i++) {
                                sss = sssContributionData[i].sss_conti_EE;
                            }
                            let u_id_data = response.data[i].data_schema['user_id'],
                                late_data = response.data[i].data_schema['timesheet']['totalLate_Rate'],
                                Phil_health = (parseFloat(response.data[i].data_schema['timesheet']['salary_rate']) * 0.03) / 2,
                                Pag_Ibig = 100;

                            if ((parseFloat(response.data[i].data_schema['timesheet']['salary_rate'])) <= 10000) {
                                Phil_health = 150.00;
                            }
                            let Gross = (totalEarning_sss - (parseFloat(sss) + Phil_health + Pag_Ibig + parseFloat(late_data)));
                            let excess = 0;
                            let multiplier = 1;
                            let additional = 0;
                            let amount_val = 0;

                            const annual_tax = annual_tax_data.filter((val) => {
                                if (parseFloat(val.range_from) < Gross && parseFloat(val.range_to) >= Gross) {
                                    return val
                                }
                            }
                            )
                            for (let i = 0; i < annual_tax.length; i++) {
                                excess = parseFloat(annual_tax[i].range_from);
                                multiplier = parseFloat(annual_tax[i].multiplier);
                                additional = parseFloat(annual_tax[i].additional);
                            }
                            if (annual_tax.length > 0) {
                                amount_val = (((Gross - excess) * multiplier) + additional);
                                hold_tax = amount_val;
                            }


                            // getData("humanResource/getSSScontribution", { salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate']), p_id: response.data[i].pid, late: response.data[i].data_schema['timesheet']['totalLate_Rate'], user_id: response.data[i].data_schema['user_id'], totalEarnings: (parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + Allowance + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])) })
                            //     .then(sss_data => {

                            var PHILHEALTH = 0;
                            var PHILHEALTH_EE = 0;
                            var rate2 = 0;
                            // var totalDeduction = 0;
                            // var totalEarning = 0;

                            var sunday = "";
                            var sunday_ot = "";
                            var sunday_ot_rate = "";
                            var lateHours = "";
                            var lateHours_Rate = "";
                            var amount2 = "";
                            var regularHoliday = "";
                            var specialHoliday = "";
                            var sunday_amount = "";
                            var regularHoliday_amount = "";
                            var specialHoliday_amount = "";
                            var totalRegularOT = "";
                            var totalRegularOT_Rate = "";
                            var totalRegualar_Hol_OT_Rate = "";
                            var totalRegualar_Hol_OT = "";
                            var totalSpecial_Hol_OT = "";
                            var totalSpecial_Hol_OT_Rate = "";
                            var totalUndertime_hours = "";
                            var totalUndertime_amount = "";
                            var leave_w_pay_amount = "";
                            var leave_w_pay_days = "";
                            var holding_tax = "";
                            var allowance_amount = "";
                            var double_book_amount = "";
                            var double_book = "";
                            var jo_number = "";
                            var jo_total_amount = "";
                            var retro_ot = "";
                            var retro_ot_amount = "";
                            var basic_amount_additional = "";
                            var basic_amount_deduction = "";
                            var basic_hours_additional = "";
                            var basic_hours_deduction = "";
                            var total_night_defferential_hours = "";
                            var total_night_defferential_amount = "";
                            var mobile = "";
                            var date_pending_payroll = []
                            date_pending_payroll = response.data[i].date_pending_payroll
                            if (response.data[i].data_schema['mobile'] != "") {
                                mobile = response.data[i].data_schema['mobile'];
                            }
                            if (response.data[i].data_schema['timesheet']['total_night_defferential_hours'] != 0) {
                                total_night_defferential_hours = response.data[i].data_schema['timesheet']['total_night_defferential_hours'];
                                total_night_defferential_amount = response.data[i].data_schema['timesheet']['total_night_defferential_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['basic_hours_additional'] != 0) {
                                basic_hours_additional = response.data[i].data_schema['timesheet']['basic_hours_additional'];
                                basic_amount_additional = response.data[i].data_schema['timesheet']['basic_amount_additional'];
                            }
                            if (response.data[i].data_schema['timesheet']['retrobasic_hours_deduction_ot'] != 0) {
                                basic_hours_deduction = response.data[i].data_schema['timesheet']['basic_hours_deduction'];
                                basic_amount_deduction = response.data[i].data_schema['timesheet']['basic_amount_deduction'];
                            }
                            if (response.data[i].data_schema['timesheet']['retro_ot'] != 0) {
                                retro_ot = response.data[i].data_schema['timesheet']['retro_ot'];
                                retro_ot_amount = response.data[i].data_schema['timesheet']['retro_ot_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book_amount'] != 0) {
                                double_book_amount = response.data[i].data_schema['timesheet']['double_book_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book'] != 0) {
                                double_book = response.data[i].data_schema['timesheet']['double_book'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_number'] != 0) {
                                jo_number = response.data[i].data_schema['timesheet']['jo_number'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_total_amount'] != 0) {
                                jo_total_amount = response.data[i].data_schema['timesheet']['jo_total_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['allowance_amount'] != 0) {
                                allowance_amount = response.data[i].data_schema['timesheet']['allowance_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['sunday'] != 0) {
                                sunday = response.data[i].data_schema['timesheet']['sunday'];
                            }
                            if (amount_val != 0) {
                                holding_tax = parseFloat(amount_val).toFixed(2);
                                totalDeduction += amount_val;
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate'] != 0) {
                                lateHours = response.data[i].data_schema['timesheet']['totalLate'];
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate_Rate'] != 0) {
                                lateHours_Rate = response.data[i].data_schema['timesheet']['totalLate_Rate'];
                            }
                            if (response.data[i].data_schema['timesheet']['amount'] != 0) {
                                amount2 = response.data[i].data_schema['timesheet']['amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHoliday'] != 0) {
                                regularHoliday = response.data[i].data_schema['timesheet']['regularHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHoliday'] != 0) {
                                specialHoliday = response.data[i].data_schema['timesheet']['specialHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['sunday_amount'] != 0) {
                                sunday_amount = response.data[i].data_schema['timesheet']['sunday_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHol_amount'] != 0) {
                                regularHoliday_amount = response.data[i].data_schema['timesheet']['regularHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHol_amount'] != 0) {
                                specialHoliday_amount = response.data[i].data_schema['timesheet']['specialHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot != 0) {
                                totalRegularOT = response.data[i].data_schema['timesheet']['ot'][0].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot != 0) {
                                sunday_ot = response.data[i].data_schema['timesheet']['ot'][3].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate != 0) {
                                sunday_ot_rate = response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate != 0) {
                                totalRegularOT_Rate = response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate != 0) {
                                totalRegualar_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot != 0) {
                                totalRegualar_Hol_OT = response.data[i].data_schema['timesheet']['ot'][1].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate != 0) {
                                totalSpecial_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeHours'] != 0) {
                                totalUndertime_hours = response.data[i].data_schema['timesheet']['undertimeHours'];
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeAmount'] != 0) {
                                totalUndertime_amount = response.data[i].data_schema['timesheet']['undertimeAmount'];
                            }
                            // for (let j = 0; j < response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'].length; j++) {
                            //     totalDeduction += parseFloat(response.data[i].data_schema['earning_deduction']['deduction']['deduction_amount'][j]);

                            // }
                            // for (let j = 0; j < response.data[i].data_schema['earning_deduction']['earning']['earning_amount'].length; j++) {
                            //     totalEarning += parseFloat(response.data[i].data_schema['earning_deduction']['earning']['earning_amount'][j])
                            // }
                            // if(response.data[i].deduction.length != 0){
                            //     for (let j = 0; j < response.data[i].deduction['deduction_amount'].length; j++) {
                            //         totalDeduction += parseFloat(response.data[i].deduction['deduction_amount'][j]);

                            //     }
                            // }
                            // if(response.data[i].earning.length != 0){
                            //     for (let j = 0; j < response.data[i].earning['earning_amount'].length; j++) {
                            //         totalEarning += parseFloat(response.data[i].earning['earning_amount'][j])
                            //     }
                            // }



                            if (response.data[i].data_schema['timesheet']['type_rate'] == "Monthly") {
                                rate2 = parseFloat(response.data[i].data_schema['timesheet']['salary_rate']);
                            } else if (response.data[i].data_schema['timesheet']['type_rate'] == "Daily") {
                                rate2 = (parseFloat(response.data[i].data_schema['timesheet']['salary_rate']) * 313) / 12;
                            }

                            if (rate2 <= 10000 && rate2 > 0) {
                                PHILHEALTH = 150.00
                            } else {
                                PHILHEALTH = ((rate2 * 0.03) / 2).toFixed(2)
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDay'] != 0) {
                                leave_w_pay_days = response.data[i].data_schema['timesheet']['leaveDay'];
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDayAmount'] != 0) {
                                leave_w_pay_amount = parseFloat(response.data[i].data_schema['timesheet']['leaveDayAmount']).toFixed(2);
                            }

                            if (response.data[i].philhealth_EE != 0) {
                                PHILHEALTH_EE = parseFloat(response.data[i].philhealth_EE).toFixed(2);
                            } else {
                                PHILHEALTH_EE = PHILHEALTH;
                            }

                            // console.log(firstCUT_off);
                            for (let l = 0; l < sssContributionData.length; l++) {
                                SSS_CONTRIBUTION = sssContributionData[l].sss_conti_Total;
                                SSS_EC = sssContributionData[l].ec_contri_ER;
                                SSS_ER = sssContributionData[l].total_conti_ER;
                                SSS_EE = sssContributionData[l].total_conti_EE;

                                // SSS_CONTRIBUTION = sss_data[l].sss_conti_Total;
                                // SSS_EC = sss_data[l].ec_contri_ER;
                                // SSS_ER = sss_data[l].total_conti_ER;
                                // SSS_EE = sss_data[l].sss_conti_EE;


                            }
                            console.log(parseFloat(totalDeduction) + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH))

                            gov_conti.push({
                                user_id: u_id_data,
                                SSS_CONTRIBUTION: SSS_CONTRIBUTION,
                                SSS_EC: SSS_EC,
                                SSS_ER: SSS_ER,
                                SSS_EE: SSS_EE,
                                PHILHEALTH: PHILHEALTH,
                                PHILHEALTH_EE: PHILHEALTH_EE,
                                PAG_IBIG_ER: 100,
                                PAG_IBIG_EE: 100,
                                holding_tax: holding_tax
                                // SSS:SSS_EE,
                                // PHILHEALTH:PHILHEALTH_EE,
                                // PAG_IBIG:100
                            })
                            // let datePaid =  (String(this.state.MonthPeriod).split('-'))[1]
                            let fullname = response.data[i].data_schema['user_fname'] + ' ' + response.data[i].data_schema['user_lname']
                            let date_ofpayment = moment(new Date(new Date(data.date_to).getTime() + 5 * 24 * 60 * 60 * 1000)).format('MMMM DD')
                            // console.log();
                            let Disburstment_val = {
                                mobile: mobile,
                                name: String(fullname).toUpperCase(),
                                amount: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']) + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH_EE)),
                                remarks: String(response.data[i].data_schema['user_lname']).toUpperCase() + ' ' + String(date_ofpayment).toUpperCase() + ' ' + 'SALARY'
                            }
                            Disburstment.push(Disburstment_val);
                            let payrollSummary = []

                            if (response.data[i].payroll_summary != "") {
                                payrollSummary = JSON.parse(response.data[i].payroll_summary)
                            }
                            details = {
                                payroll_summary: payrollSummary,
                                mobile: mobile,
                                total_night_defferential_amount: total_night_defferential_amount,
                                total_night_defferential_hours: total_night_defferential_hours,
                                date_pending_payroll: date_pending_payroll,
                                basic_amount_additional: basic_amount_additional,
                                basic_amount_deduction: basic_amount_deduction,
                                basic_hours_additional: basic_hours_additional,
                                basic_hours_deduction: basic_hours_deduction,
                                retro_ot_amount: retro_ot_amount,
                                retro_ot: retro_ot,
                                pid: response.data[i].pid,
                                allowance_amount: allowance_amount,
                                allowance_earnings: parseFloat(parseFloat(Allowance) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'])).toFixed(2),
                                company_name: response.data[i].company_name,
                                emp_id: response.data[i].data_schema['user_id'],
                                branch_name: response.data[i].data_schema['branch_name'],
                                emp_name: response.data[i].data_schema['user_name'],
                                emp_monthly: response.data[i].data_schema['timesheet']['type_rate'],
                                emp_salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate'].replace(/,/g, '')).toLocaleString(),
                                totalDays: response.data[i].data_schema['timesheet']['totalDay'],
                                sunday: sunday,
                                sunday_ot: sunday_ot,
                                sunday_ot_rate: sunday_ot_rate,
                                lateHours: lateHours,
                                lateHours_Rate: lateHours_Rate,
                                amount2: amount2,
                                regularHoliday: regularHoliday,
                                specialHoliday: specialHoliday,
                                sunday_amount: sunday_amount,
                                regularHoliday_amount: regularHoliday_amount,
                                specialHoliday_amount: specialHoliday_amount,
                                payroll_details_id: response.data[i].pid,
                                totalDeduction: parseFloat(totalDeduction) + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH),

                                // totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['undertimeAmount'] + response.data[i].data_schema['timesheet']['totalLate_Rate'] + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH),
                                totalRegularOT: totalRegularOT,
                                totalRegularOT_Rate: totalRegularOT_Rate,
                                totalRegualar_Hol_OT_Rate: totalRegualar_Hol_OT_Rate,
                                totalRegualar_Hol_OT: totalRegualar_Hol_OT,

                                totalSpecial_Hol_OT: totalSpecial_Hol_OT,
                                totalSpecial_Hol_OT_Rate: totalSpecial_Hol_OT_Rate,
                                total_earning: parseFloat(response.data[i].data_schema['timesheet']['total_earning']) + parseFloat(totalEarning),

                                totalUndertime_hours: totalUndertime_hours,
                                totalUndertime_amount: totalUndertime_amount,

                                firstCUT_off: parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']),
                                secondCUT_off: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning),
                                totalCUT_off: parseFloat(response.data[i].data_schema['timesheet']['prev_total_earning']) + parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning),

                                SSS_EE: SSS_EE,
                                SSS_CONTRIBUTION: SSS_CONTRIBUTION,
                                SSS_EC: SSS_EC,
                                SSS_ER: SSS_ER,

                                PHILHEALTH: PHILHEALTH,
                                PHILHEALTH_EE: PHILHEALTH_EE,

                                PAG_IBIG: 100,
                                total_net_pay: parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']) + parseFloat(SSS_EE) + 100 + parseFloat(PHILHEALTH_EE)),

                                leave_w_pay_days: leave_w_pay_days,
                                leave_w_pay_amount: (leave_w_pay_amount),

                                holding_tax: holding_tax,

                                double_book_amount: double_book_amount,
                                double_book: double_book,
                                jo_number: jo_number,
                                jo_total_amount: jo_total_amount,
                            }

                            Listemp.push(details);

                            // })


                        } else if (response.data[i].government_contribution == 0) {
                            var sunday_ot = "";
                            var sunday_ot_rate = "";
                            var sunday = "";
                            var lateHours = "";
                            var lateHours_Rate = "";
                            var amount2 = "";
                            var regularHoliday = "";
                            var specialHoliday = "";
                            var sunday_amount = "";
                            var regularHoliday_amount = "";
                            var specialHoliday_amount = "";
                            var totalRegularOT = "";
                            var totalRegularOT_Rate = "";
                            var totalRegualar_Hol_OT_Rate = "";
                            var totalRegualar_Hol_OT = "";
                            var totalSpecial_Hol_OT = "";
                            var totalSpecial_Hol_OT_Rate = "";
                            var totalUndertime_hours = "";
                            var totalUndertime_amount = "";
                            var leave_w_pay_amount = "";
                            var leave_w_pay_days = "";
                            var holding_tax = ""
                            var allowance_amount = "";
                            var double_book_amount = "";
                            var double_book = "";
                            var jo_number = "";
                            var jo_total_amount = "";
                            var retro_ot = "";
                            var retro_ot_amount = "";
                            var basic_amount_additional = "";
                            var basic_amount_deduction = "";
                            var basic_hours_additional = "";
                            var basic_hours_deduction = "";
                            var total_night_defferential_hours = "";
                            var total_night_defferential_amount = "";
                            var mobile = "";
                            var date_pending_payroll = [];

                            date_pending_payroll = response.data[i].date_pending_payroll

                            if (response.data[i].data_schema['mobile'] != "") {
                                mobile = response.data[i].data_schema['mobile'];
                            }

                            if (response.data[i].data_schema['timesheet']['total_night_defferential_hours'] != 0) {
                                total_night_defferential_hours = response.data[i].data_schema['timesheet']['total_night_defferential_hours'];
                                total_night_defferential_amount = response.data[i].data_schema['timesheet']['total_night_defferential_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['basic_hours_additional'] != 0) {
                                basic_hours_additional = response.data[i].data_schema['timesheet']['basic_hours_additional'];
                                basic_amount_additional = response.data[i].data_schema['timesheet']['basic_amount_additional'];
                            }
                            if (response.data[i].data_schema['timesheet']['retrobasic_hours_deduction_ot'] != 0) {
                                basic_hours_deduction = response.data[i].data_schema['timesheet']['basic_hours_deduction'];
                                basic_amount_deduction = response.data[i].data_schema['timesheet']['basic_amount_deduction'];
                            }
                            if (response.data[i].data_schema['timesheet']['retro_ot'] != 0) {
                                retro_ot = response.data[i].data_schema['timesheet']['retro_ot'];
                                retro_ot_amount = response.data[i].data_schema['timesheet']['retro_ot_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book_amount'] != 0) {
                                double_book_amount = response.data[i].data_schema['timesheet']['double_book_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['double_book'] != 0) {
                                double_book = response.data[i].data_schema['timesheet']['double_book'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_number'] != 0) {
                                jo_number = response.data[i].data_schema['timesheet']['jo_number'];
                            }
                            if (response.data[i].data_schema['timesheet']['jo_total_amount'] != 0) {
                                jo_total_amount = response.data[i].data_schema['timesheet']['jo_total_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['allowance_amount'] != 0) {
                                allowance_amount = response.data[i].data_schema['timesheet']['allowance_amount'];
                            }

                            if (response.data[i].data_schema['timesheet']['sunday'] != 0) {
                                sunday = response.data[i].data_schema['timesheet']['sunday'];
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot != 0) {
                                sunday_ot = response.data[i].data_schema['timesheet']['ot'][3].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate != 0) {
                                sunday_ot_rate = response.data[i].data_schema['timesheet']['ot'][3].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate'] != 0) {
                                lateHours = response.data[i].data_schema['timesheet']['totalLate'];
                            }
                            if (response.data[i].data_schema['timesheet']['totalLate_Rate'] != 0) {
                                lateHours_Rate = response.data[i].data_schema['timesheet']['totalLate_Rate'];
                            }
                            if (response.data[i].data_schema['timesheet']['amount'] != 0) {
                                amount2 = response.data[i].data_schema['timesheet']['amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHoliday'] != 0) {
                                regularHoliday = response.data[i].data_schema['timesheet']['regularHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHoliday'] != 0) {
                                specialHoliday = response.data[i].data_schema['timesheet']['specialHoliday'];
                            }
                            if (response.data[i].data_schema['timesheet']['sunday_amount'] != 0) {
                                sunday_amount = response.data[i].data_schema['timesheet']['sunday_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['regularHol_amount'] != 0) {
                                regularHoliday_amount = response.data[i].data_schema['timesheet']['regularHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['specialHol_amount'] != 0) {
                                specialHoliday_amount = response.data[i].data_schema['timesheet']['specialHol_amount'];
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot != 0) {
                                totalRegularOT = response.data[i].data_schema['timesheet']['ot'][0].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate != 0) {
                                totalRegularOT_Rate = response.data[i].data_schema['timesheet']['ot'][0].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate != 0) {
                                totalRegualar_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][1].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][1].total_ot != 0) {
                                totalRegualar_Hol_OT = response.data[i].data_schema['timesheet']['ot'][1].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot != 0) {
                                totalSpecial_Hol_OT = response.data[i].data_schema['timesheet']['ot'][2].total_ot;
                            }
                            if (response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate != 0) {
                                totalSpecial_Hol_OT_Rate = response.data[i].data_schema['timesheet']['ot'][2].total_ot_rate;
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeHours'] != 0) {
                                totalUndertime_hours = response.data[i].data_schema['timesheet']['undertimeHours'];
                            }
                            if (response.data[i].data_schema['timesheet']['undertimeAmount'] != 0) {
                                totalUndertime_amount = response.data[i].data_schema['timesheet']['undertimeAmount'];
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDay'] != 0) {
                                leave_w_pay_days = response.data[i].data_schema['timesheet']['leaveDay'];
                            }
                            if (response.data[i].data_schema['timesheet']['leaveDayAmount'] != 0) {
                                leave_w_pay_amount = parseFloat(response.data[i].data_schema['timesheet']['leaveDayAmount']).toFixed(2);
                            }

                            let fullname = response.data[i].data_schema['user_fname'] + ' ' + response.data[i].data_schema['user_lname']
                            let date_ofpayment = moment(new Date(new Date(data.date_to).getTime() + 5 * 24 * 60 * 60 * 1000)).format('MMMM DD')
                            let Disburstment_val = {
                                mobile: mobile,
                                name: String(fullname).toUpperCase(),
                                amount: (parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']))).toFixed(2),
                                remarks: String(response.data[i].data_schema['user_lname']).toUpperCase() + ' ' + String(date_ofpayment).toUpperCase() + ' ' + 'SALARY'
                            }
                            Disburstment.push(Disburstment_val);
                            let payrollSummary = []
                            if (response.data[i].payroll_summary != "x`") {
                                payrollSummary = JSON.parse(response.data[i].payroll_summary)
                            }
                            details = {
                                payroll_summary: payrollSummary,
                                mobile: mobile,
                                total_night_defferential_amount: total_night_defferential_amount,
                                total_night_defferential_hours: total_night_defferential_hours,
                                date_pending_payroll: date_pending_payroll,
                                basic_amount_additional: basic_amount_additional,
                                basic_amount_deduction: basic_amount_deduction,
                                basic_hours_additional: basic_hours_additional,
                                basic_hours_deduction: basic_hours_deduction,
                                retro_ot_amount: retro_ot_amount,
                                retro_ot: retro_ot,
                                pid: response.data[i].pid,
                                allowance_amount: allowance_amount,
                                allowance_earnings: parseFloat(response.data[i].data_schema['timesheet']['total_earning']).toFixed(2),
                                company_name: response.data[i].company_name,
                                emp_id: response.data[i].data_schema['user_id'],
                                branch_name: response.data[i].data_schema['branch_name'],
                                emp_name: response.data[i].data_schema['user_name'],
                                emp_monthly: response.data[i].data_schema['timesheet']['type_rate'],
                                emp_salary_rate: parseFloat(response.data[i].data_schema['timesheet']['salary_rate'].replace(/,/g, '')).toLocaleString(),
                                totalDays: response.data[i].data_schema['timesheet']['totalDay'],
                                sunday: sunday,
                                sunday_ot: sunday_ot,
                                sunday_ot_rate: sunday_ot_rate,
                                lateHours: lateHours,
                                lateHours_Rate: lateHours_Rate,
                                amount2: amount2,
                                regularHoliday: regularHoliday,
                                specialHoliday: specialHoliday,
                                sunday_amount: sunday_amount,
                                regularHoliday_amount: regularHoliday_amount,
                                specialHoliday_amount: specialHoliday_amount,
                                payroll_details_id: response.data[i].pid,
                                totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['totalLate_Rate'],

                                // totalDeduction: totalDeduction + response.data[i].data_schema['timesheet']['undertimeAmount'] + response.data[i].data_schema['timesheet']['totalLate_Rate'],
                                totalRegularOT: totalRegularOT,
                                totalRegularOT_Rate: totalRegularOT_Rate,
                                totalRegualar_Hol_OT_Rate: totalRegualar_Hol_OT_Rate,
                                totalRegualar_Hol_OT: totalRegualar_Hol_OT,

                                totalSpecial_Hol_OT: totalSpecial_Hol_OT,
                                totalSpecial_Hol_OT_Rate: totalSpecial_Hol_OT_Rate,
                                total_earning: parseFloat(parseFloat(response.data[i].data_schema['timesheet']['total_earning']) + parseFloat(totalEarning)).toFixed(2),

                                totalUndertime_hours: totalUndertime_hours,
                                totalUndertime_amount: totalUndertime_amount,

                                firstCUT_off: "",
                                secondCUT_off: "",
                                totalCUT_off: "",

                                SSS_EE: "",
                                SSS_CONTRIBUTION: "",
                                SSS_EC: "",
                                SSS_ER: "",

                                PHILHEALTH: "",
                                PHILHEALTH_EE: "",

                                PAG_IBIG: "",
                                total_net_pay: (parseFloat(response.data[i].data_schema['timesheet']['total_earning'] + totalEarning) - (parseFloat(totalDeduction) + parseFloat(response.data[i].data_schema['timesheet']['undertimeAmount']) + parseFloat(response.data[i].data_schema['timesheet']['totalLate_Rate']))).toFixed(2),

                                leave_w_pay_days: leave_w_pay_days,
                                leave_w_pay_amount: (leave_w_pay_amount),

                                holding_tax: holding_tax,

                                double_book_amount: double_book_amount,
                                double_book: double_book,
                                jo_number: jo_number,
                                jo_total_amount: jo_total_amount,
                            }

                            Listemp.push(details);
                        }
                    }
                }
                let field = 'emp_name';
                setTimeout(() => {
                    Listemp.sort((a, b) => {
                        return a.emp_name > b.emp_name;
                    });
                    this.setState({ listEmp: Listemp, deduction: deduction, responsedata: response.data, earning: earning, MonthPeriod: data.date_from + ' - ' + data.date_to })
                    this.TotalEarnings(Listemp);
                }, 1000)
                localStorage.setItem('MonthPeriod', data.date_from + '--' + data.date_to)
                this.props.dispatch({
                    type: 'GetEmployeePayroll',
                    Listemp: Listemp,
                    deduction: deduction,
                    earning: earning,
                    responsedata: response.data,
                    Disburstment: Disburstment,
                })
                getData("humanResource/updateGov", { gov_conti: gov_conti, pay_id: data.p_id, holding_tax: hold_tax })
                    .then(response => {
                    })


            })
        this.setState({ loading: false })
    }
    downloadTxtFile = () => {
        let array = [];
        array.push('heheheh')
        array.push('heheheh')

        const element = document.createElement("a");
        const file = new Blob([array.join('\t')], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    approvePayroll() {
        //   console.log(this.state.pay_id)
        getData('humanResource/approvePayroll', this.state.pay_id)
            .then((response) => {
                console.log(response)
                if (response == true) {
                    alert('Success')
                    this.props.dispatch({
                        type: 'ApproveStatus',
                        data: 'Approved'
                    })
                }
                console.log(response)
            })
    }
    render() {
        // console.log(this.state.govenment_cont);

        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        // if (this.state.redirectpayrollProfile == true) {
        //     return <Redirect to='/payrollProfile' />
        // }

        let EmployeeSearch = this.props.ListEmployee.filter(
            (files) => {
                return files.emp_name.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (

            <div id="content " className="content " style={{ paddingLeft: 30, paddingRight: 20 }}>
                {this.state.loading == true &&
                    <Loader fullPage loading />
                }
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Payroll Report</h4>
                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Payroll Employee List</a></li>

                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "none" }}>
                                        <PrintPayroll ref={el => (this.componentPrintPayroll = el)} orientation={this.state.orientation} ListEmployee={EmployeeSearch} earning={this.props.earning} deduction={this.props.deduction} responsedata={this.props.responsedata} company={this.props.match.params.company} branch={this.props.match.params.branch} first={this.props.match.params.date_from} second={this.props.match.params.date_to} payroll_hierarchy={this.state.payroll_hierarchy} css='@page { size: landscape; }' />
                                    </div>
                    {/* <table id="mytable" >
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Email</th>
                                <th>Country</th>
                                <th>Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="right">2</td>
                                <td>Janice</td>
                                <td>Henry</td>
                                <td>jhenry1@theatlantic.com</td>
                                <td>Ukraine</td>
                                <td><img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKgSUNDX1BST0ZJTEUAAQEAAAKQbGNtcwQwAABtbnRyUkdCIFhZWiAH3QAIAA4AFgAoAB1hY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAtkZXNjAAABCAAAADhjcHJ0AAABQAAAAE53dHB0AAABkAAAABRjaGFkAAABpAAAACxyWFlaAAAB0AAAABRiWFlaAAAB5AAAABRnWFlaAAAB+AAAABRyVFJDAAACDAAAACBnVFJDAAACLAAAACBiVFJDAAACTAAAACBjaHJtAAACbAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAABwAAAAcAHMAUgBHAEIAIABiAHUAaQBsAHQALQBpAG4AAG1sdWMAAAAAAAAAAQAAAAxlblVTAAAAMgAAABwATgBvACAAYwBvAHAAeQByAGkAZwBoAHQALAAgAHUAcwBlACAAZgByAGUAZQBsAHkAAAAAWFlaIAAAAAAAAPbWAAEAAAAA0y1zZjMyAAAAAAABDEoAAAXj///zKgAAB5sAAP2H///7ov///aMAAAPYAADAlFhZWiAAAAAAAABvlAAAOO4AAAOQWFlaIAAAAAAAACSdAAAPgwAAtr5YWVogAAAAAAAAYqUAALeQAAAY3nBhcmEAAAAAAAMAAAACZmYAAPKnAAANWQAAE9AAAApbcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltwYXJhAAAAAAADAAAAAmZmAADypwAADVkAABPQAAAKW2Nocm0AAAAAAAMAAAAAo9cAAFR7AABMzQAAmZoAACZmAAAPXP/bAEMABQMEBAQDBQQEBAUFBQYHDAgHBwcHDwsLCQwRDxISEQ8RERMWHBcTFBoVEREYIRgaHR0fHx8TFyIkIh4kHB4fHv/bAEMBBQUFBwYHDggIDh4UERQeHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHv/AABEIABgAGAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAABwYI/8QAJxAAAQMEAQMDBQAAAAAAAAAAAQIDBAAFBhEhEiIxBxNBFjJRYXH/xAAZAQACAwEAAAAAAAAAAAAAAAAAAwEEBQb/xAAjEQABAwMDBQEAAAAAAAAAAAABAAIDBAUREjHBITJBUWHR/9oADAMBAAIRAxEAPwDbTM5x+0YE5lYlx7hEZ6W1JhyEOkvK8NbBICufn4BNS+7et31DZJlonW6Lbo01Pt+83KJcbTvfIOgeBo615qNYplFvhTDCessNmySnEiWw2pXU5pCkpWVkk7T1E8Ac7/NVbJIuGW7DlS4NsTCQ5BLSAqO2tEpSk9ijvvQrz3A62N6oqbjI7DCN1bp7fGdUgcOiy8eEJLYmwVbbcOkFKu0jyRz92tUpiubWGBbXrbd8MiSYpTpo2+c7HU0oAd/QSpClk8lSgfNKNZyoGMbKRpRw4jWlAbT+q6Q9EfTy85RhMJN7aadQ06JVvZkOnpcaI0UqPI6SeQn+71ulKWWhz2tPn8KZTjuPocrO5xYo87KH3I9si2dMhKBHaRFUlKhyknpA7TxsnQApSlZVRVyQENaustVvpauMulYMj6Rzhf/Z" /></td>
                            </tr>
                            <tr>
                                <td align="right">3</td>
                                <td>Ruth</td>
                                <td>Wells</td>
                                <td>rwells2@constantcontact.com</td>
                                <td>Trinidad and Tobago</td>
                                <td><img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKgSUNDX1BST0ZJTEUAAQEAAAKQbGNtcwQwAABtbnRyUkdCIFhZWiAH3QAIAA4AFgAoAB1hY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAtkZXNjAAABCAAAADhjcHJ0AAABQAAAAE53dHB0AAABkAAAABRjaGFkAAABpAAAACxyWFlaAAAB0AAAABRiWFlaAAAB5AAAABRnWFlaAAAB+AAAABRyVFJDAAACDAAAACBnVFJDAAACLAAAACBiVFJDAAACTAAAACBjaHJtAAACbAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAABwAAAAcAHMAUgBHAEIAIABiAHUAaQBsAHQALQBpAG4AAG1sdWMAAAAAAAAAAQAAAAxlblVTAAAAMgAAABwATgBvACAAYwBvAHAAeQByAGkAZwBoAHQALAAgAHUAcwBlACAAZgByAGUAZQBsAHkAAAAAWFlaIAAAAAAAAPbWAAEAAAAA0y1zZjMyAAAAAAABDEoAAAXj///zKgAAB5sAAP2H///7ov///aMAAAPYAADAlFhZWiAAAAAAAABvlAAAOO4AAAOQWFlaIAAAAAAAACSdAAAPgwAAtr5YWVogAAAAAAAAYqUAALeQAAAY3nBhcmEAAAAAAAMAAAACZmYAAPKnAAANWQAAE9AAAApbcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltwYXJhAAAAAAADAAAAAmZmAADypwAADVkAABPQAAAKW2Nocm0AAAAAAAMAAAAAo9cAAFR7AABMzQAAmZoAACZmAAAPXP/bAEMABQMEBAQDBQQEBAUFBQYHDAgHBwcHDwsLCQwRDxISEQ8RERMWHBcTFBoVEREYIRgaHR0fHx8TFyIkIh4kHB4fHv/bAEMBBQUFBwYHDggIDh4UERQeHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHv/AABEIABgAGAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAABwYI/8QAJxAAAQMEAQMDBQAAAAAAAAAAAQIDBAAFBhEhEiIxBxNBFjJRYXH/xAAZAQACAwEAAAAAAAAAAAAAAAAAAwEEBQb/xAAjEQABAwMDBQEAAAAAAAAAAAABAAIDBAUREjHBITJBUWHR/9oADAMBAAIRAxEAPwDbTM5x+0YE5lYlx7hEZ6W1JhyEOkvK8NbBICufn4BNS+7et31DZJlonW6Lbo01Pt+83KJcbTvfIOgeBo615qNYplFvhTDCessNmySnEiWw2pXU5pCkpWVkk7T1E8Ac7/NVbJIuGW7DlS4NsTCQ5BLSAqO2tEpSk9ijvvQrz3A62N6oqbjI7DCN1bp7fGdUgcOiy8eEJLYmwVbbcOkFKu0jyRz92tUpiubWGBbXrbd8MiSYpTpo2+c7HU0oAd/QSpClk8lSgfNKNZyoGMbKRpRw4jWlAbT+q6Q9EfTy85RhMJN7aadQ06JVvZkOnpcaI0UqPI6SeQn+71ulKWWhz2tPn8KZTjuPocrO5xYo87KH3I9si2dMhKBHaRFUlKhyknpA7TxsnQApSlZVRVyQENaustVvpauMulYMj6Rzhf/Z" /></td>
                            </tr>
                            <tr>
                                <td align="right">4</td>
                                <td>Jason</td>
                                <td>Ray</td>
                                <td>jray3@psu.edu</td>
                                <td>Brazil</td>
                                <td><img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKgSUNDX1BST0ZJTEUAAQEAAAKQbGNtcwQwAABtbnRyUkdCIFhZWiAH3QAIAA4AFgAoAB1hY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAtkZXNjAAABCAAAADhjcHJ0AAABQAAAAE53dHB0AAABkAAAABRjaGFkAAABpAAAACxyWFlaAAAB0AAAABRiWFlaAAAB5AAAABRnWFlaAAAB+AAAABRyVFJDAAACDAAAACBnVFJDAAACLAAAACBiVFJDAAACTAAAACBjaHJtAAACbAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAABwAAAAcAHMAUgBHAEIAIABiAHUAaQBsAHQALQBpAG4AAG1sdWMAAAAAAAAAAQAAAAxlblVTAAAAMgAAABwATgBvACAAYwBvAHAAeQByAGkAZwBoAHQALAAgAHUAcwBlACAAZgByAGUAZQBsAHkAAAAAWFlaIAAAAAAAAPbWAAEAAAAA0y1zZjMyAAAAAAABDEoAAAXj///zKgAAB5sAAP2H///7ov///aMAAAPYAADAlFhZWiAAAAAAAABvlAAAOO4AAAOQWFlaIAAAAAAAACSdAAAPgwAAtr5YWVogAAAAAAAAYqUAALeQAAAY3nBhcmEAAAAAAAMAAAACZmYAAPKnAAANWQAAE9AAAApbcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltwYXJhAAAAAAADAAAAAmZmAADypwAADVkAABPQAAAKW2Nocm0AAAAAAAMAAAAAo9cAAFR7AABMzQAAmZoAACZmAAAPXP/bAEMABQMEBAQDBQQEBAUFBQYHDAgHBwcHDwsLCQwRDxISEQ8RERMWHBcTFBoVEREYIRgaHR0fHx8TFyIkIh4kHB4fHv/bAEMBBQUFBwYHDggIDh4UERQeHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHv/AABEIABgAGAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAABwYI/8QAJxAAAQMEAQMDBQAAAAAAAAAAAQIDBAAFBhEhEiIxBxNBFjJRYXH/xAAZAQACAwEAAAAAAAAAAAAAAAAAAwEEBQb/xAAjEQABAwMDBQEAAAAAAAAAAAABAAIDBAUREjHBITJBUWHR/9oADAMBAAIRAxEAPwDbTM5x+0YE5lYlx7hEZ6W1JhyEOkvK8NbBICufn4BNS+7et31DZJlonW6Lbo01Pt+83KJcbTvfIOgeBo615qNYplFvhTDCessNmySnEiWw2pXU5pCkpWVkk7T1E8Ac7/NVbJIuGW7DlS4NsTCQ5BLSAqO2tEpSk9ijvvQrz3A62N6oqbjI7DCN1bp7fGdUgcOiy8eEJLYmwVbbcOkFKu0jyRz92tUpiubWGBbXrbd8MiSYpTpo2+c7HU0oAd/QSpClk8lSgfNKNZyoGMbKRpRw4jWlAbT+q6Q9EfTy85RhMJN7aadQ06JVvZkOnpcaI0UqPI6SeQn+71ulKWWhz2tPn8KZTjuPocrO5xYo87KH3I9si2dMhKBHaRFUlKhyknpA7TxsnQApSlZVRVyQENaustVvpauMulYMj6Rzhf/Z" /></td>
                            </tr>
                            <tr>
                                <td align="right">5</td>
                                <td>Jane</td>
                                <td>Stephens</td>
                                <td>jstephens4@go.com</td>
                                <td>United States</td>
                                <td>
                                    <img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKgSUNDX1BST0ZJTEUAAQEAAAKQbGNtcwQwAABtbnRyUkdCIFhZWiAH3QAIAA4AFgAoAB1hY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAtkZXNjAAABCAAAADhjcHJ0AAABQAAAAE53dHB0AAABkAAAABRjaGFkAAABpAAAACxyWFlaAAAB0AAAABRiWFlaAAAB5AAAABRnWFlaAAAB+AAAABRyVFJDAAACDAAAACBnVFJDAAACLAAAACBiVFJDAAACTAAAACBjaHJtAAACbAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAABwAAAAcAHMAUgBHAEIAIABiAHUAaQBsAHQALQBpAG4AAG1sdWMAAAAAAAAAAQAAAAxlblVTAAAAMgAAABwATgBvACAAYwBvAHAAeQByAGkAZwBoAHQALAAgAHUAcwBlACAAZgByAGUAZQBsAHkAAAAAWFlaIAAAAAAAAPbWAAEAAAAA0y1zZjMyAAAAAAABDEoAAAXj///zKgAAB5sAAP2H///7ov///aMAAAPYAADAlFhZWiAAAAAAAABvlAAAOO4AAAOQWFlaIAAAAAAAACSdAAAPgwAAtr5YWVogAAAAAAAAYqUAALeQAAAY3nBhcmEAAAAAAAMAAAACZmYAAPKnAAANWQAAE9AAAApbcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltwYXJhAAAAAAADAAAAAmZmAADypwAADVkAABPQAAAKW2Nocm0AAAAAAAMAAAAAo9cAAFR7AABMzQAAmZoAACZmAAAPXP/bAEMABQMEBAQDBQQEBAUFBQYHDAgHBwcHDwsLCQwRDxISEQ8RERMWHBcTFBoVEREYIRgaHR0fHx8TFyIkIh4kHB4fHv/bAEMBBQUFBwYHDggIDh4UERQeHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHv/AABEIABgAGAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAABwYI/8QAJxAAAQMEAQMDBQAAAAAAAAAAAQIDBAAFBhEhEiIxBxNBFjJRYXH/xAAZAQACAwEAAAAAAAAAAAAAAAAAAwEEBQb/xAAjEQABAwMDBQEAAAAAAAAAAAABAAIDBAUREjHBITJBUWHR/9oADAMBAAIRAxEAPwDbTM5x+0YE5lYlx7hEZ6W1JhyEOkvK8NbBICufn4BNS+7et31DZJlonW6Lbo01Pt+83KJcbTvfIOgeBo615qNYplFvhTDCessNmySnEiWw2pXU5pCkpWVkk7T1E8Ac7/NVbJIuGW7DlS4NsTCQ5BLSAqO2tEpSk9ijvvQrz3A62N6oqbjI7DCN1bp7fGdUgcOiy8eEJLYmwVbbcOkFKu0jyRz92tUpiubWGBbXrbd8MiSYpTpo2+c7HU0oAd/QSpClk8lSgfNKNZyoGMbKRpRw4jWlAbT+q6Q9EfTy85RhMJN7aadQ06JVvZkOnpcaI0UqPI6SeQn+71ulKWWhz2tPn8KZTjuPocrO5xYo87KH3I9si2dMhKBHaRFUlKhyknpA7TxsnQApSlZVRVyQENaustVvpauMulYMj6Rzhf/Z" />
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    <div className="navmedia m-b-5 m-l-15 m-r-15">
                        <div className="btn-group">
                            <Link to={'/payroll/'}><button className="btttn bg-red-transparent-9 fas fa-caret-left text-white">&nbsp;Back</button></Link>

                            <a className="" href={"http://api.pacificweb.com.ph/humanresource/excel/" + localStorage.getItem("u") + "/" + this.state.pay_id + "/" + moment(this.state.filterStartDate).format('MMMM DD') + "/" + moment(this.state.filterEndDate).format('DD YYYY') + '/' + this.props.match.params.company + '/' + this.props.match.params.branch} style={{ textDecoration: "none", color: "white" }}>
                                <button className=" fas fa-cloud-download-alt btttn bg-green-transparent-9 text-white m-l-5">
                                    &nbsp;Download&nbsp;Payroll
                                    </button>
                            </a>
                            <button onClick={() => this.generate2()}>Generate PDF</button>
                            {this.props.status == 'Approved' ? <>
                                <ReactToPrint
                                    onBeforeGetContent={() => {
                                        var css2 = '@page { size: portrait; }',
                                            head2 = document.head || document.getElementsByTagName('head')[0],
                                            style2 = document.createElement('style');

                                        style2.type = 'text/css';
                                        style2.media = 'print';

                                        if (style2.styleSheet) {
                                            style2.styleSheet.cssText = css2;
                                        } else {
                                            style2.appendChild(document.createTextNode(css2));
                                        }
                                        head2.appendChild(style2)
                                    }}
                                    trigger={() => <button className="btttn bg-green-transparent-9 text-white m-l-5">
                                        <a className="fas fa-print" style={{ textDecoration: "none", color: "white" }}>&nbsp;Print&nbsp;Payslip</a>
                                    </button>
                                    }
                                    content={() => this.componentRef}
                                />
                                <div style={{ display: "none" }}>
                                    <PaySlip ref={el => (this.componentRef = el)} orientation={this.state.orientation} data={EmployeeSearch} MonthPeriod={this.state.MonthPeriod} deduction={this.props.deduction} earning={this.props.earning} responsedata={this.props.responsedata} css='@page { size: portrait; }' />
                                </div>
                                <div>
                                    <ExcelFile element={<button className="btttn bg-green-transparent-9 text-white m-l-5">
                                        <a className="fas fa-cloud-download-alt" style={{ textDecoration: "none", color: "white" }}>&nbsp;Download Disbursement&nbsp;</a></button>} >
                                        <ExcelSheet data={this.props.Disburstment} name="SSS Contribution">
                                            <ExcelColumn label="GCash Mobile Number" value="mobile" />
                                            <ExcelColumn label="Full Name" value="name" />
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Remarks" value='remarks' />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                                {/* <div>
                            <a className=""  style={{ textDecoration: "none", color: "white" }}>
                                    <button className=" fas fa-cloud-download-alt btttn bg-green-transparent-9 text-white m-l-5"> 
                                        &nbsp;Approved&nbsp;
                                    </button>
                                </a>
                            </div> */}
                                {/* <button onClick={()=>{this.downloadTxtFile()}} className=" fas fa-cloud-download-alt btttn bg-green-transparent-9 text-white m-l-5"> 
                                        &nbsp;Download&nbsp;Payroll
                                    </button> */}

                                <div>
                                    <ReactToPrint
                                        onBeforeGetContent={() => {
                                            var css = '@page { size: landscape; }',
                                                head = document.head || document.getElementsByTagName('head')[0],
                                                style = document.createElement('style');

                                            style.type = 'text/css';
                                            style.media = 'print';

                                            if (style.styleSheet) {
                                                style.styleSheet.cssText = css;
                                            } else {
                                                style.appendChild(document.createTextNode(css));
                                            }
                                            head.appendChild(style);
                                        }}
                                        trigger={() => <button className="btttn bg-green-transparent-9 text-white m-l-5">
                                            <a className="fas fa-print" style={{ textDecoration: "none", color: "white" }}>&nbsp;Print&nbsp;Payroll</a>
                                        </button>
                                        }
                                        content={() => this.componentPrintPayroll}
                                    />
                                    <div style={{ display: "none" }} >
                                        <PrintPayroll ref={el => (this.componentPrintPayroll = el)} orientation={this.state.orientation} ListEmployee={EmployeeSearch} earning={this.props.earning} deduction={this.props.deduction} responsedata={this.props.responsedata} company={this.props.match.params.company} branch={this.props.match.params.branch} first={this.props.match.params.date_from} second={this.props.match.params.date_to} payroll_hierarchy={this.state.payroll_hierarchy} css='@page { size: landscape; }' />
                                    </div>
                                </div>
                            </>
                                : undefined

                            }

                            {this.props.status != 'Approved' ? <button className="btttn bg-green-transparent-9 text-white m-l-5" data-target='#demo-lg-modal-apprroved' data-toggle='modal'>
                                <a className="fas fa-check" style={{ textDecoration: "none", color: "white" }}>&nbsp;Approve</a>
                            </button> : undefined}

                        </div>
                        <div>
                            <div className="input-group pull-left" style={{ marginBottom: 5 }}>
                                <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>

                    </div>

                    <div className="panel-body">
                        <Scrollbars style={{ height: 360 }}>
                            <table className="table table-td-valign-middle table-bordered" style={{ position: "relative" }}>
                                <thead className='tableFixHead'>
                                    <tr >
                                        <th>Employee ID</th>
                                        <th>Employee Name</th>
                                        <th>Days</th>
                                        <th>Rate</th>
                                        <th>Net Amount Paid</th>
                                        <th style={{ zIndex: 1, width: "auto" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {EmployeeSearch.map((val, index) => {

                                        return <tr key={index}>
                                            <td>{val.emp_id}</td>
                                            <td>{val.emp_name.toUpperCase()}</td>
                                            <td>{val.totalDays}</td>
                                            <td>{val.emp_monthly}</td>
                                            <td>{this.formatNumber(val.total_net_pay)}</td>
                                            <td>
                                                <div>
                                                    <a onClick={() => {
                                                        let res_data = this.state.responsedata.filter((data_filter) => (data_filter.user_id == val.emp_id))
                                                        this.props.dispatch({
                                                            type: 'ViewPayrollDetails',
                                                            payDetails: val,
                                                            responsedata: res_data,
                                                        })
                                                    }} href={'#/payrollDetails/'}><button style={{ height: 23, backgroundColor: '#3498db', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} className="fas fa-eye">&nbsp;&nbsp;View</button> </a>
                                                    {this.props.status != 'Approved' ? <button onClick={() => {
                                                        this.props.dispatch({
                                                            type: 'SelectedEmployeePayroll',
                                                            data: val
                                                        })
                                                    }} data-target='#demo-lg-modal-generate' data-toggle='modal' style={{ height: 23, backgroundColor: 'green', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} className="fas fa-recycle">&nbsp;&nbsp;Update Payroll</button> : undefined

                                                    }


                                                </div>

                                            </td>

                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-lg-modal-generate" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Payroll</h4>

                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to update this payroll?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn bg-green-transparent-9 fas fa-recycle m-r-5" onClick={() => { this.generate() }} data-dismiss="modal" aria-label="Close" >
                                    &nbsp;Update
                                </button>
                                <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                    &nbsp;Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-apprroved" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Approve Payroll</h4>

                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to approve this payroll?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn bg-green-transparent-9 fas fa-recycle m-r-5" onClick={() => { this.approvePayroll() }} data-dismiss="modal" aria-label="Close" >
                                    &nbsp;Approve
                                </button>
                                <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                    &nbsp;Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
function mapStateToProps(state) {

    return {
        ListEmployee: state.Payroll.Listemp,
        selected_employee_payroll: state.Payroll.selected_employee_payroll,
        responsedata: state.Payroll.responsedata,
        deduction: state.Payroll.deduction,
        earning: state.Payroll.earning,
        Disburstment: state.Payroll.Disburstment,
        payroll_approver: state.Payroll.payroll_branches,
        status: state.Payroll.payrollGroup.status,

    }
}
export default connect(mapStateToProps)(PayrollEmployeeList);