import React, { Component } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";
// import Dashboard from "./dashboard/index";
import Control from "./navigation_control";
import Admin_Control from "./admin_navigation/index"  
// import Meter from "./meter/index";
class RoutesControl extends Component {
    state = {}
    render() {
        return (
            <Router>
                <div>
                    <Route path="/controller/add" component={Control} />
                    <Route path="/controller/admin_control" component={Admin_Control} />

                </div>
            </Router>
        );
    }
}
export default RoutesControl;