import React, { Component } from 'react';
import {    
    HashRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";
  import Dashboard from "./dashboard/index";
  import Meter from "./meter/index";
import Downloadzip from './meter/downloadzip';
import Checking from './meter/checking';
import Profile from './dashboard/profile';
import Assign from './meter/assign';
import Users from './meter/users';
class Routes extends Component {
    state = {  }
    render() { 
        return ( 
          <Router>
              <div>
                <Route path="/mis/dashboard" component={Dashboard}/>
                <Route path="/mis/meter" component={Meter}/>
                <Route path="/mis/download" component={Downloadzip}/>
                <Route path="/mis/checking" component={Checking} />
                <Route path="/mis/profile" component={Profile} />
                <Route path="/mis/assign" component={Assign} />
                <Route path="/mis/users" component={Users} />
             
              </div>
          </Router>
         );
    }
}
 
export default Routes;