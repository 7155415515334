import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import TextareaAutosize from 'react-autosize-textarea';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DPie2 from "./charts/d_pie2";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import 'intro.js/introjs.css';
import { Redirect } from 'react-router-dom'
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import $ from 'jquery';
import User from './images/user.png'
const format = 'h:mm a';
const now = moment().hour(0).minute(0);

class home2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textlenght: [],
            pieGraph: [],
            DAR: [],
            timeStart: '',
            timeEnd: '',
            type: '',
            status: '',
            justification: '',
            todayDar: [],
            display: '',
            sick: '',
            vacation: '',
            timeStart1: '',
            timeEnd1: '',
            branch_details: [],
            request_date_start: '',
            request_date_end: '',
            req_type: '',
            branch_id: '',
            company_id: '',
            user_id: '',
            app_id: '',
            req_leave_type: '',
            req_leave_premise: '',
            status: '',
            req_hours_approved: '',
            req_hierarchy_structure: '',
            req_hierarchy_level: 0,
            req_with_pay: '',

            timeStartReq: '',
            timeStartReq: '',
            sickOption: '',
            VacationOption: '',
            headerdata: [],
            refresh: false,
            myRequest: [],
            myReq: [],
            readOnly: '',

            post_details: '',
            click: false,
            company_details: [],
            DAR: [],
            select: [],
            branch1: true,
            branch: true,
            company: true,
            user: true,
            DaysPresent: 0,
            DaysAbsent: 0,
            commentEn: 0,
            countCommentArray: [],
            newsFeed: [],
            show: false,
            showmore: 1,
            countCommentArray: [],
            CommentData: [],
            post_id: '',
            InComment: false,
            selectEmployee: [],
            selectBranch: [],
            employee_details: [],
            DaysPresent: [],
            dateData: [],
            monthSelected: "",
            post_ID: '',
            total_hrs_dar: 0,
            searchDriver: '',
            redirect: false,
            bgbutton: " ",
            pic: '',
            fname: '',
            lname: '',
            position: '',
            company: '',
            branch: '',
            department: '',
            email: '',
            contact: '',
            apps: [],
            refresh: false,
            hour: null,
            loading: false,
            darview: [],
            textarea: "",
            timestater: "",
            timeender: "",
            showtimeend: false,
            showtimestart: false,
            accom_id: "",
            type: "",
            dardelete: ""
        };


        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChangeTimeStart = this.handleChangeTimeStart.bind(this);

        this.handleChangeTimeEnd = this.handleChangeTimeEnd.bind(this);



        this.handleChange2 = this.handleChange2.bind(this);
        this.textchange = this.textchange.bind(this);
        this.onChangeSelectStatus = this.onChangeSelectStatus.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.Submit = this.Submit.bind(this);
        this.onChangeSelectSick = this.onChangeSelectSick.bind(this);
        this.onChangeSelectVacation = this.onChangeSelectVacation.bind(this);
        this.filterDateEnd = this.filterDateEnd.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.updateDAR = this.updateDAR.bind(this)
        this.deleteAccom = this.deleteAccom.bind(this)

    }
    checkPagePrivilege() {
        var route = "#/";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })
            .then(response => {
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                    this.fetch_newsFeed();
                    this.fetchCompany()
                    this.fetchDAR()
                    var present = 9;
                    var late = 1;
                    var absent = 0;

                    setInterval(() => {

                        if (this.state.post_ID != "") {
                            // alert(this.state.post_ID);5
                            this.fetchComments(this.state.post_ID)
                        }
                    }, 2000)
                }
            })
    }
    componentDidMount() {
        setTimeout(() => {
            this.checkPagePrivilege();
        }, 100)
        this.showApps()
        this.textarea = ""
        {
            localStorage.getItem("cookies") == 1 &&
                $("#cookie").click();

        }

    }
    filterDateEnd = e => {
        setTimeout(() => {

            this.setState({ request_date_end: e });
        }, 100)

    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    fetchUser() {

        getData("users/get_User_by_branch", { branch_id: this.state.selectBranch, company: this.state.select, u_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ employee_details: response.user_name })

            })
    }
    showMore() {
        this.state.showmore++;
        this.fetch_newsFeed()
    }
    fetchComments = (post_id) => {

        getData("communication/fetch_Comments", { post_id: post_id, commentCount: this.state.CommentData.length })
            .then(response => {
                console.log(response)
                if (response.data.length != 0) {
                    for (let index = 0; index < this.state.countCommentArray.length; index++) {
                        if (this.state.countCommentArray[index].post_id == post_id) {
                            this.state.countCommentArray[index].countComment = response.data.length;
                        }

                    }
                    this.setState({ CommentData: response.data, post_id_D: post_id })
                }
            })
    }
    insertComment = (e) => {
        e.preventDefault();
        // alert("hehehe");
        var post = this.state.post_id
        getData("communication/insertComment", { u_id: localStorage.getItem("u"), comment: this.state.comment, post_id: post })
            .then(response => {
                console.log(response)
                if (response.success == "success") {

                    this.setState({ InComment: true, CommentData: response.data, countCom: response.data.length, insertTrue: true, comment: "" })
                    for (let index = 0; index < this.state.countCommentArray.length; index++) {
                        if (this.state.countCommentArray[index].post_id == post) {
                            this.state.countCommentArray[index].countComment = response.data.length;
                            this.setState({ refresh: true })
                        }

                    }

                }

            })
    }

    filterDateStart = e => {
        setTimeout(() => {

            this.setState({ request_date_start: e });
        }, 100)

    }
    handleChange1(e) {
        var datenow = new Date().toLocaleString();
        var date = datenow.split(',')
        var date2 = date[0].split('/');
        var date = date2[2] + '-' + date2[0] + '-' + date2[1] + ' ' + e.target.value
        this.state.timeStart = date
        this.setState({ timeStart1: e.target.value })
    }
    handleChangeTimeStart(value) {


        var datenow = new Date().toLocaleString();
        var date = datenow.split(',')
        var date2 = date[0].split('/');
        var date = date2[2] + '-' + date2[0] + '-' + date2[1] + ' ' + value.format('HH:mm')

        this.state.timeStart = date
        // this.setState({ timeStart1: value.format('HH:mm') })
        this.setState({ timeStart1: value })
    }
    handleChangeTimeEnd(value) {

        var datenow = new Date().toLocaleString();
        var date = datenow.split(',')
        var date2 = date[0].split('/');
        var date = date2[2] + '-' + date2[0] + '-' + date2[1] + ' ' + value.format('HH:mm')
        this.state.timeEnd = date
        // this.setState({ timeEnd1: value.format('HH:mm') })
        this.setState({ timeEnd1: value })
    }
    handleChange2(e) {
        var datenow = new Date().toLocaleString();
        var date = datenow.split(',')
        var date2 = date[0].split('/');
        var date = date2[2] + '-' + date2[0] + '-' + date2[1] + ' ' + e.target.value
        this.setState({ timeEnd1: e.target.value })

        this.state.timeEnd = date;
    }
    textchange = e => {

        this.setState({ [e.target.name]: e.target.value });
    }
    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ type: options[i].value })
            }
        }
    }
    onChangeSelectSick(e) {
        var options = e.target.options;
        var value = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ sick: options[i].value })
            }

        }
    }
    onChangeSelectVacation(e) {
        var options = e.target.options;
        var value = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ [e.target.name]: options[i].value })
            }

        }
    }
    onChangeSelectStatus(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                this.setState({ status: options[i].value })
            }
        }
    }
    Submit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        var oneDay = 24 * 60 * 60 * 1000;
        var date1 = new Date(this.state.timeStart);
        var date2 = new Date(this.state.timeEnd);
        var diffDays = Math.abs((date1.getTime() - date2.getTime()) / oneDay * 24);
        if (date1 >= date2 || diffDays > 4) {
            alert('Invalid Time');
        } else {
            getData('HumanResource/LogAccomplishment', {
                app_id: 7,
                user_id: localStorage.getItem('u'),
                accomplishment_description: this.textarea.value,
                accomplishment_date_start: this.state.timeStart,
                accomplishment_date_end: this.state.timeEnd,
                accomplishment_date_added: '',
                project_id: 0,
                accomplishment_type: this.state.type,
                status: this.state.status,
                accomplishment_hours: diffDays,
                method: ""
            })
                .then((response) => {
                    // console.log(response)
                    if (response.response == "Success") {
                        this.setState({ timeStart: "", timeEnd: "", justification: "", type: "", status: "", timeStart1: "", timeEnd1: "" })
                        this.fetchDAR();
                        alert('Sucess');
                        this.setState({ loading: false })


                        this.textarea.value = " "
                    } else {
                        alert('Failed to submit');
                        this.setState({ loading: false })

                    }



                })
        }


    }
    fetchDAR() {

        getData('humanResource/GetDailyDAR', { u_id: localStorage.getItem('u') })
            .then((response) => {
                var total_hours_dar = 0;
                for (let index = 0; index < response.darDaily.length; index++) {

                    var oneDay = 24 * 60 * 60 * 1000;
                    var diffDays = Math.abs((new Date(response.darDaily[index].accomplishment_date_start).getTime() - new Date(response.darDaily[index].accomplishment_date_end).getTime()) / oneDay * 24);
                    total_hours_dar += diffDays;
                }

                this.setState({ todayDar: response.darDaily, myRequest: response.request, total_hrs_dar: total_hours_dar });

            })
        // console.log(this.state.todayDar)

    }
    fetchBranch() {
        var data = [];
        for (let i = 0; i < this.state.select.length; i++) {
            for (let index = 0; index < this.state.DATA.length; index++) {
                if (this.state.select[i].company_id == this.state.DATA[index].company_id) {
                    var match = false;
                    for (let k = 0; k < data.length; k++) {
                        if (data[k].branch_id == this.state.DATA[index].branch_id) {
                            match = true;
                        }
                    }
                    var data2 = [];
                    if (match == false) {
                        data2 = { branch_name: this.state.DATA[index].branch_name, branch_id: this.state.DATA[index].branch_id, company_name: this.state.select[i].company_name, company_id: this.state.select[i].company_id };
                        data.push(data2);
                    }
                }

            }

        }
        this.setState({ branch_details: data });

    }
    darfunc() {
        return <div className="modal-body bg-default">
            <form onSubmit={this.Submit}>

                <div className="form-group m-10">
                    <label>Time Range</label>
                    <div class="form-row">
                        <div class="col">
                            <TimePicker
                                showSecond={false}
                                placeholder="Time Start"
                                onChange={this.handleChangeTimeStart}
                                format={format}
                                use12Hours
                                defaultValue={this.state.timeStart1}
                                required

                            />
                        </div>
                        <div class="col">
                            <TimePicker
                                showSecond={false}
                                format={format}
                                placeholder="Time End"
                                use12Hours
                                onChange={this.handleChangeTimeEnd}
                                defaultValue={this.state.timeEnd1}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group m-10">
                    <label>Accomplishment&nbsp;Type:</label>
                    {this.state.readOnly == 'readOnly' &&
                        <select className="form-control" value={this.state.type} required readOnly />
                    }
                    {this.state.readOnly != 'readOnly' &&
                        <select className="form-control" value={this.state.type} onChange={this.onChangeSelect} required >
                            <option></option>
                            <option>Office Work</option>
                            <option>Official Business</option>
                            <option>Meeting</option>
                            <option>Operational</option>
                            <option>Techinical</option>
                            <option>Training</option>
                            <option>Development</option>
                        </select>
                    }
                </div>
                <div className="form-group m-10">
                    <label className="col-lg-12 col-form-label">Status</label>
                    {this.state.readOnly == 'readOnly' &&
                        <select className="form-control" value={this.state.status} required readOnly />
                    }
                    {this.state.readOnly != 'readOnly' &&
                        <select className="form-control" value={this.state.status} onChange={this.onChangeSelectStatus} required >
                            <option></option>
                            <option>Finished</option>
                            <option>Continouos</option>
                            <option>Unfinished</option>
                        </select>
                    }
                </div>
                {this.state.readOnly == 'readOnly' &&
                    <TextareaAutosize rows={5} placeholder='Description of your activity' value={this.state.justification} className="f-s-14 m-10" style={{ width: '96%', textIndent: "10px", textDecoration: 'none' }} name='justification' onChange={this.textchange} required readOnly />
                }
                {this.state.readOnly != 'readOnly' &&
                    <TextareaAutosize rows={5} placeholder='Description of your activity' className="f-s-14 m-10" style={{ width: '96%', textIndent: "10px", textDecoration: 'none' }} name='justification' ref={(input) => this.textarea = input} required />
                }



                {/* <div className="row">
                    <div className="col-md-6 ">
                        <div className="row" style={{ overflow: "scroll", height: 300 }}>
                            <div className="form-group col-md-6">
                                <label className="col-lg-12 col-form-label">Time Start</label>
                                <div className="col-lg-12">
                                    <div >
                                        <TimePicker
                                            showSecond={false}
                                            onChange={this.handleChangeTimeStart}
                                            format={format}
                                            use12Hours
                                            required
                                            defaultValue={this.state.timeStart1}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="col-lg-12 col-form-label">Time End</label>
                                <div className="col-lg-12">
                                    <div>
                                        <TimePicker
                                            showSecond={false}
                                            format={format}
                                            use12Hours
                                            onChange={this.handleChangeTimeEnd}
                                            defaultValue={this.state.timeEnd1}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="col-lg-12 col-form-label">Type</label>
                                <div className="col-lg-12">
                                    {this.state.readOnly == 'readOnly' &&
                                        <select className="form-control" value={this.state.type} required readOnly />
                                    }
                                    {this.state.readOnly != 'readOnly' &&
                                        <select className="form-control" value={this.state.type} onChange={this.onChangeSelect} required >
                                            <option></option>
                                            <option>Office Work</option>
                                            <option>Official Business</option>
                                            <option>Meeting</option>
                                            <option>Operational</option>
                                            <option>Techinical</option>
                                            <option>Training</option>
                                            <option>Development</option>

                                        </select>
                                    }
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="col-lg-12 col-form-label">Project</label>
                                <div className="col-lg-12">
                                    <select className="form-control" required >
                                        <option></option>
                                        <option>Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="col-lg-12 col-form-label">Status</label>
                                <div className="col-lg-12">
                                    {this.state.readOnly == 'readOnly' &&
                                        <select className="form-control" value={this.state.status} required readOnly>

                                        </select>
                                    }
                                    {this.state.readOnly != 'readOnly' &&
                                        <select className="form-control" value={this.state.status} onChange={this.onChangeSelectStatus} required >
                                            <option></option>
                                            <option>Finished</option>
                                            <option>Continouos</option>
                                            <option>Unfinished</option>
                                        </select>
                                    }
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                {this.state.type == "Meeting" &&
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Meeting With</label>
                                        <div className="col-lg-12">
                                            <input type="text" className="form-control" required></input>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {this.state.readOnly == 'readOnly' &&
                            <TextareaAutosize rows={13} placeholder='Description of your activity' value={this.state.justification} className="f-s-14" style={{ width: '100%', textIndent: "10px", textDecoration: 'none' }} name='justification' onChange={this.textchange} required readOnly />
                        }
                        {this.state.readOnly != 'readOnly' &&
                            <TextareaAutosize rows={13} placeholder='Description of your activity' className="f-s-14" style={{ width: '100%', textIndent: "10px", textDecoration: 'none' }} name='justification' ref={(input) => this.textarea = input} required />
                        }
                    </div>
                </div> */}

                <button className="btttn fa fa-paper-plane pull-right bg-green-transparent-9">
                    &nbsp;Submit
                </button>
            </form>


        </div>
    }

    onChange(value) {

    }
    otfunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">OverTime Request</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter ">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 m-b-20">
                                <div className="flexing border border-black">
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Date Start</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <DatePicker
                                                    selected={this.state.request_date_start}
                                                    selectsStart
                                                    startDate={this.state.request_date_start}
                                                    endDate={this.state.request_date_end}
                                                    onChange={this.filterDateStart}
                                                    className="form-control"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Date End</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <DatePicker
                                                    selected={this.state.request_date_end}
                                                    selectsStart
                                                    startDate={this.state.request_date_end}
                                                    endDate={this.state.request_date_end}
                                                    onChange={this.filterDateEnd}
                                                    className="form-control"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div><br />
                            <div className="col-md-12 col-sm-12 m-b-20">
                                <div className="flexing border border-black">
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Time Start</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <TimePicker
                                                    showSecond={false}
                                                    onChange={this.handleChangeTimeStart}
                                                    format={format}
                                                    use12Hours
                                                    required
                                                    defaultValue={this.state.timeStart1}
                                                />
                                                {/* <input id="timepicker" name='timeStartReq' type="time" value={this.state.timeStart1} className="form-control" onChange={this.handleChange1} required /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Time End</label>
                                        <div className="col-lg-12">
                                            <div>
                                                <TimePicker
                                                    showSecond={false}
                                                    format={format}
                                                    use12Hours
                                                    onChange={this.handleChangeTimeEnd}
                                                    defaultValue={this.state.timeEnd1}
                                                />
                                                {/* <input id="timepicker" name='timeEndReq' type="time" value={this.state.timeEnd1} className="form-control" onChange={this.handleChange2} required /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <TextareaAutosize rows={9} placeholder='Provide details for your requesting overtime' value={this.state.justification} className="f-s-14" style={{ width: '100%', textIndent: "10px", textDecoration: 'none' }} name='justification' onChange={this.textchange} required />
                    </div>
                </div>
            </div>
        </div>
    }
    utfunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">UnderTime Request</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter ">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="row m-l-5 m-r-5 m-b-10" >
                    <div className="col-md-6 border border-black ">
                        <div className="form-group">
                            <label className="col-lg-12 col-form-label">Date Start</label>
                            <div className="col-lg-12">
                                <div >
                                    <DatePicker
                                        selected={this.state.request_date_start}
                                        selectsStart
                                        startDate={this.state.request_date_start}
                                        endDate={this.state.request_date_end}
                                        onChange={this.filterDateStart}
                                        className="form-control"
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-lg-12 col-form-label">Time End</label>
                            <div className="col-lg-12">
                                <div>
                                    <TimePicker
                                        showSecond={false}
                                        onChange={this.handleChangeTimeStart}
                                        format={format}
                                        use12Hours
                                        required
                                        defaultValue={this.state.timeStart1}
                                    />
                                    {/* <input id="timepicker" value={this.state.timeStart1} type="time" className="form-control" name='timeStart1' onChange={this.handleChange1} required /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <TextareaAutosize rows={8} placeholder='Provide details for your requesting undertime' value={this.state.justification} className="f-s-14" style={{ width: '100%', textIndent: "10px", textDecoration: 'none' }} name='justification' onChange={this.textchange} required />
                    </div>
                </div>


            </div>
        </div>
    }
    obfunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">Official Business Request</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 m-b-20">
                                <div className="flexing border border-black">
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Date Start</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <DatePicker
                                                    selected={this.state.request_date_start}
                                                    selectsStart
                                                    startDate={this.state.request_date_start}
                                                    endDate={this.state.request_date_end}
                                                    onChange={this.filterDateStart}
                                                    className="form-control"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Date End</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <DatePicker
                                                    selected={this.state.request_date_end}
                                                    selectsStart
                                                    startDate={this.state.request_date_end}
                                                    endDate={this.state.request_date_end}
                                                    onChange={this.filterDateEnd}
                                                    className="form-control"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br />

                            <div className="col-md-12 col-sm-12 m-b-20">
                                <div className="flexing border border-black">
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Use of company vehicle</label>
                                        <div className="col-lg-12">
                                            <div className="checkbox checkbox-css is-valid m-b-10">
                                                <input type="checkbox" id="cssCheckbox3" value="" disabled />
                                                <label for="cssCheckbox3">Check only if going to use company vehicle</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 m-b-20">
                                <div className="flexing border border-black">
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Plate No.</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <input onChange={this.textchange} type="text" className="form-control" placeholder="" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Model</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <input onChange={this.textchange} type="text" className="form-control" placeholder="" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Trip Ticket No.</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <input onChange={this.textchange} type="text" className="form-control" placeholder="" disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 m-b-20">
                                <div className="flexing border border-black">
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Time Start</label>
                                        <div className="col-lg-12">
                                            <div >
                                                <TimePicker
                                                    showSecond={false}
                                                    onChange={this.handleChangeTimeStart}
                                                    format={format}
                                                    use12Hours
                                                    required
                                                    defaultValue={this.state.timeStart1}
                                                />
                                                {/* <input id="timepicker" type="time" value={this.state.timeStart1} className="form-control" onChange={this.handleChange1} required /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-12 col-form-label">Time End</label>
                                        <div className="col-lg-12">
                                            <div>
                                                <TimePicker
                                                    showSecond={false}
                                                    format={format}
                                                    use12Hours
                                                    onChange={this.handleChangeTimeEnd}
                                                    defaultValue={this.state.timeEnd1}
                                                />
                                                {/* <input id="timepicker" type="time" className="form-control" value={this.state.timeStart2} onChange={this.handleChange2} required /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <TextareaAutosize rows={9} placeholder='Provide details for your requesting official business' value={this.state.justification} className="f-s-14" style={{ width: '100%', textIndent: "10px", textDecoration: 'none' }} name='justification' onChange={this.textchange} required />
                    </div>
                </div>
            </div>
        </div>
    }
    lvfunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">Leave Request</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="row border border-black">
                    <div className="form-group col-md-3">
                        <label className="col-lg-12 col-form-label">Date Start</label>
                        <div className="col-lg-12">
                            <div >
                                <DatePicker
                                    selected={this.state.request_date_start}
                                    selectsStart
                                    startDate={this.state.request_date_start}
                                    endDate={this.state.request_date_end}
                                    onChange={this.filterDateStart}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="col-lg-12 col-form-label">Date End</label>
                        <div className="col-lg-12">
                            <div >
                                <DatePicker
                                    selected={this.state.request_date_end}
                                    selectsStart
                                    startDate={this.state.request_date_end}
                                    endDate={this.state.request_date_end}
                                    onChange={this.filterDateEnd}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="col-lg-12 col-form-label">Time Start</label>
                        <div className="col-lg-12">
                            <div >
                                <TimePicker
                                    showSecond={false}
                                    onChange={this.handleChangeTimeStart}
                                    format={format}
                                    use12Hours
                                    required
                                    defaultValue={this.state.timeStart1}
                                />
                                {/* <input id="timepicker" value={this.state.timeStart1} type="time" className="form-control" onChange={this.handleChange1} required /> */}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="col-lg-12 col-form-label">Time End</label>
                        <div className="col-lg-12">
                            <div>
                                <TimePicker
                                    showSecond={false}
                                    format={format}
                                    use12Hours
                                    onChange={this.handleChangeTimeEnd}
                                    defaultValue={this.state.timeEnd1}
                                />
                                {/* <input id="timepicker" value={this.state.timeEnd1} type="time" className="form-control" onChange={this.handleChange2} required /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-t-10">
                    <div className="col-md-4  border border-black ">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label className="col-lg-12 col-form-label" >Type of Leave</label>
                                <div className="col-lg-12">
                                    <select className="form-control" value={this.state.req_leave_type} name='req_leave_type' onChange={this.onChangeSelectVacation} required>
                                        <option></option>
                                        <option>Maternity</option>
                                        <option>Paternity</option>
                                        <option>Sick</option>
                                        <option>Vacation</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.req_leave_type == "Sick" &&
                                <div className="form-group col-md-12">
                                    <label className="col-lg-12 col-form-label">Incase of Sick Leave</label>
                                    <div className="col-lg-12">
                                        <select className="form-control" name='req_leave_premise' onChange={this.onChangeSelectVacation} required>
                                            <option></option>
                                            <option>In Hospital</option>
                                            <option>Out Patient</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            {this.state.req_leave_type == "req_leave_type" &&
                                <div className="form-group col-md-12">
                                    <label className="col-lg-12 col-form-label">Incase of Vacation Leave</label>
                                    <div className="col-lg-12">
                                        <select className="form-control" name='req_leave_premise' onChange={this.onChangeSelectVacation} required>
                                            <option></option>
                                            <option>Within the Province</option>
                                            <option>Other Province</option>
                                            <option>Abroad</option>
                                        </select>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="checkbox checkbox-css is-valid m-b-10">
                            <input type="checkbox" id="cssCheckbox3" value="" onChange={() => {
                                if (this.state.req_with_pay == 1) {
                                    this.setState({ req_with_pay: '' })
                                } else {
                                    this.setState({ req_with_pay: 1 })
                                }

                            }} />
                            <label for="cssCheckbox3">Check only if it's leave with pay</label>
                        </div>
                        <TextareaAutosize rows={8} placeholder='Provide details for your requesting overtime' value={this.state.justification} className="f-s-14" style={{ width: '100%', textIndent: "10px", textDecoration: 'none' }} name='justification' onChange={this.textchange} required />

                    </div>
                </div>
            </div>
        </div>
    }
    attendance1func() {
        return <div >
            <div className="modal-header">
                <h4 className="page-header f-s-15 m-t-10">{this.state.monthSelected}</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-black-transparent-1">
                <div className="row">
                    <div className="col-md-4 m-t-25">
                        <DatePicker
                            inline
                            selected={this.state.startDate}
                            highlightDates={this.state.dateData}
                            // onChange={this.handleChange}
                            onChange={this.changeDay}
                            onMonthChange={this.handleChangeMonth}


                        />
                    </div>
                    <div className="col-md-8" >
                        <div style={{ height: 300, overflow: 'auto' }}>
                            {this.state.DaysPresent.map((val, index) => {
                                var data = [];

                                for (let index1 = 0; index1 < val.timesheet.in.length; index1++) {
                                    var details = []

                                    details = { date: val.date, in_time: val.timesheet.in[index1], out: val.timesheet.out[index1] }
                                    data.push(details);
                                    // }
                                    // else{
                                    //      details = {date:val.date ,in_time : val.timesheet.in[index1],out:""}
                                    //      data.push(details);
                                    // }



                                }


                                return <div className="panel m-b-5 cards" style={{ borderRadius: 12, width: '97%' }}
                                    data-target="#demo-xl-modal4" data-toggle="modal" data-dismiss="modal" onClick={() => this.setState({ display: "attendance2" })}
                                >
                                    <div className="row">
                                        <div className="col-md-2 pluses bg-green-lighter">
                                            <div className="row text-center f-s-20 text-white">
                                                <div className="col-md-12">{moment(val.date).format('DD')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 pluses">
                                            <div className="row">
                                                <div className="col-md-12">{moment(val.date).format('dddd')}</div>
                                                <div className="col-md-12">{moment(val.date).format('MMMM YYYY')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 ">
                                            <table>
                                                <thead style={{ borderBottomStyle: 'solid' }}>
                                                    <tr className="text-center">
                                                        <td>IN</td>
                                                        <td>OUT</td>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {data.map((val1, index2) => {

                                                        var outDetails = "";
                                                        if (val1.out == undefined) {
                                                            outDetails = ""
                                                        } else {

                                                            outDetails = moment(val1.out).format('LTS');
                                                        }
                                                        return <tr>
                                                            <td>{moment(val1.in_time).format('LTS')}</td>
                                                            <td>{outDetails}</td>
                                                        </tr>
                                                    })

                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            })

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    attendance2func() {
        return <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="page-header f-s-15 m-t-10">October 1, 2019 - Tuesday</h4>
                    <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter" data-target="#demo-xl-modal3" data-toggle="modal" onClick={() => this.setState({ display: "attendance1" })}>
                        <span className="fa fa-times-circle fa-sm"></span>
                    </button>
                </div>
                <div className="modal-body bg-black-transparent-1">
                    <div>
                        <span>Office TimeIn-TimeOut</span>
                        <div className="panel">
                            <table className="table table-bordered text-center">
                                <thead>
                                    <tr >
                                        <td>OFFICE IN</td>
                                        <td>OFFICE OUT</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>08:40:21<small>AM</small></td>
                                        <td>05:40:21<small>PM</small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <span>Official Business Start-End</span>
                        <div className="panel">

                            <table className="table table-bordered text-center">

                                <thead>
                                    <tr>
                                        <td>OB Start</td>
                                        <td>OB End</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><small></small></td>
                                        <td><small></small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    alltable() {

        return <div>
            <div className="modal-header">
                <h4 className="page-header">{this.state.req_type} Table</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="panel">
                    <Scrollbars style={{ height: 400 }}>
                        <table className="tabl table-bordered">
                            <thead>
                                <tr>{this.state.headerdata.map((val, index) => {
                                    return <td >{val.header}</td>
                                })}

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.myReq.map((val, index) => {
                                    var backgroundColor = ''
                                    if (val.status == 'Denied') {
                                        backgroundColor = "bg-red-lighter";
                                    }
                                    if (val.status == 'Approved') {
                                        backgroundColor = "bg-blue-lighter";
                                    }


                                    if (this.state.req_type == val.req_type)

                                        return <tr className={backgroundColor} key={index}>

                                            <td>{val.date_start}</td>
                                            <td>{val.date_end}</td>
                                            <td>{val.req_type}</td>
                                            <td>{val.justification}</td>
                                            <td>{val.req_hours_approved}</td>
                                            <td>{val.status}</td>
                                            <td>VIEW</td>
                                        </tr>
                                })

                                }


                            </tbody>
                        </table>
                    </Scrollbars>
                </div>
            </div>
        </div>
    }
    ottablefunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">{this.state.req_type}</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="panel">
                    <table className="tabl table-bordered">
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Time</td>
                                <td>Justification</td>
                                <td>No. of Hours</td>
                                <td>Status</td>
                                <td>Approved Hours</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Oct 1, 2019</td>
                                <td>5:00<small>AM</small>-9:00<small>AM</small></td>
                                <td>Naglalaro ng ML magdamag</td>
                                <td>4</td>
                                <td>PENDING</td>
                                <td></td>
                                <td>VIEW</td>
                            </tr>
                            <tr className="bg-blue-lighter">
                                <td>Oct 2, 2019</td>
                                <td>5:00<small>PM</small>-9:00<small>PM</small></td>
                                <td>Naglalaro ng LoL magdamag</td>
                                <td>4</td>
                                <td>APPROVED</td>
                                <td>4</td>
                                <td>VIEW</td>
                            </tr>
                            <tr className="bg-red-lighter">
                                <td>Oct 3, 2019</td>
                                <td>5:00<small>PM</small>-9:00<small>PM</small></td>
                                <td>Nagdesign ng table sa OT</td>
                                <td>4</td>
                                <td>DENIED</td>
                                <td></td>
                                <td>VIEW</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    uttablefunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">Filed Undertime Table</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="panel">
                    <table className="tabl table-bordered">
                        <thead>
                            <tr>
                                <td>Date Filed</td>
                                <td>Date Start</td>
                                <td>Time</td>
                                <td>Status</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Oct 21,2019</td>
                                <td>Oct 21, 2019</td>
                                <td>3:00<small>PM</small></td>
                                <td>Pending</td>
                                <td>View</td>
                            </tr>
                            <tr className="bg-red-lighter">
                                <td>Oct 21,2019</td>
                                <td>Oct 21, 2019</td>
                                <td>3:00<small>PM</small></td>
                                <td>Denied</td>
                                <td>View</td>
                            </tr>
                            <tr className="bg-blue-lighter">
                                <td>Oct 21,2019</td>
                                <td>Oct 21, 2019</td>
                                <td>3:00<small>PM</small></td>
                                <td>Approved</td>
                                <td>View</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    obtablefunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">Filed Official Business Table</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="panel">
                    <table className="tabl table-bordered">
                        <thead>
                            <tr>
                                <td>Filing Date</td>
                                <td>Date Start</td>
                                <td>Date End</td>
                                <td>Time Start</td>
                                <td>Time End</td>
                                <td>Use Vehicle</td>
                                <td>Plate No.</td>
                                <td>Model</td>
                                <td>Trip Ticket No.</td>
                                <td>Justification</td>
                                <td>Status</td>
                                <td>Approved Hours/Days</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Oct 1,2019</td>
                                <td>Oct 2,2019</td>
                                <td>Oct 3,2019</td>
                                <td>9:00<small>AM</small></td>
                                <td>11:00<small>AM</small></td>
                                <td>CHECKED</td>
                                <td>OMG 143</td>
                                <td>FHM</td>
                                <td>12345678</td>
                                <td>Manonood ng sine sa manila</td>
                                <td>Pending</td>
                                <td></td>
                                <td>View</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    lvtablefunc() {
        return <div>
            <div className="modal-header">
                <h4 className="page-header">Filed Leave Table</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body bg-default">
                <div className="panel">
                    <table className="tabl table-bordered">
                        <thead>
                            <tr>
                                <td>Filing Date</td>
                                <td>Leave Date</td>
                                <td>Time Start</td>
                                <td>Time End</td>
                                <td>Type</td>
                                <td>Justification</td>
                                <td>Payed Leave</td>
                                <td>Days/Count</td>
                                <td>Status</td>
                                <td>Approved Hours/Days</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Oct 1,2019</td>
                                <td>Oct 10,2019</td>
                                <td>Time Start</td>
                                <td>Time End</td>
                                <td>Maternity</td>
                                <td>Justification 1</td>
                                <td>Yes</td>
                                <td>4</td>
                                <td>Pending</td>
                                <td>4</td>
                                <td>View</td>
                            </tr>
                            <tr className="bg-blue-lighter">
                                <td>Oct 2,2019</td>
                                <td>Oct 20,2019</td>
                                <td>Time Start</td>
                                <td>Time End</td>
                                <td>Paternity</td>
                                <td>Justification 2</td>
                                <td>No</td>
                                <td>4</td>
                                <td>Approved</td>
                                <td>4</td>
                                <td>View</td>
                            </tr>
                            <tr className="bg-red-lighter">
                                <td>Oct 3,2019</td>
                                <td>Oct 30,2019</td>
                                <td>Time Start</td>
                                <td>Time End</td>
                                <td>Sick</td>
                                <td>Justification 3</td>
                                <td>No</td>
                                <td>4</td>
                                <td>Denied</td>
                                <td>4</td>
                                <td>View</td>
                            </tr>
                            <tr>
                                <td>Oct 4,2019</td>
                                <td>Nov 3d,2019</td>
                                <td>Time Start</td>
                                <td>Time End</td>
                                <td>Vacation</td>
                                <td>Justification 4</td>
                                <td>Yes</td>
                                <td>4</td>
                                <td>Pending</td>
                                <td>4</td>
                                <td>View</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    taggingfunc() {
        return <div>
            <div className="modal-header">
                <h4>Tag Audience</h4>
                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                    <span className="fa fa-times-circle fa-sm"></span>
                </button>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card text-center" style={{ borderColor: "#77c8e0" }}>
                            <div className="card-header f-w-600 bg-blue-lighter text-white">
                                <span>Select Company</span>
                            </div>
                            <div className="card-body border-top-0 border border-lime">
                                <div className="row">
                                    <div style={{ height: 130, width: '400px', overflow: 'auto', overflowX: 'hidden' }}>
                                        <div className="panel">
                                            {this.state.company_details.map((val, index) => {
                                                var color = ""
                                                var text = ""
                                                for (let l = 0; l < this.state.select.length; l++) {
                                                    if (val.company_id == this.state.select[l].company_id) {
                                                        color = "#77c8e0"
                                                        text = "white"
                                                    }

                                                }

                                                return <div onClick={() => {
                                                    var match = false;
                                                    this.setState({ branch1: !this.state.branch1, user: true })
                                                    for (let i = 0; i < this.state.select.length; i++) {
                                                        if (this.state.select[i].company_id == val.company_id) {
                                                            this.state.select.splice(parseInt(i), 1)
                                                            match = true;
                                                            setTimeout(() => {
                                                                this.fetchUser()
                                                            }, 100)
                                                        }

                                                    }

                                                    for (let k = 0; k < this.state.branch_details.length; k++) {
                                                        if (val.company_id == this.state.branch_details[k].company_id) {
                                                            this.state.branch_details.splice(parseInt(k), 1)
                                                            k--;

                                                        }

                                                    }
                                                    for (let k = 0; k < this.state.selectBranch.length; k++) {
                                                        if (val.company_id == this.state.selectBranch[k].company_id) {
                                                            this.state.selectBranch.splice(parseInt(k), 1)
                                                            k--;

                                                        }

                                                    }
                                                    for (let j = 0; j < this.state.employee_details.length; j++) {
                                                        if (val.company_id == this.state.employee_details[j].company_id) {
                                                            this.state.employee_details.splice(parseInt(j), 1)
                                                            j--;
                                                        }
                                                    }
                                                    if (match == false) {
                                                        var details = { company_id: val.company_id, company_name: val.company_name }
                                                        this.state.select.push(details);
                                                        this.fetchBranch();
                                                        setTimeout(() => {
                                                            this.fetchUser()
                                                        }, 100)
                                                    }



                                                    this.setState({ refresh: true })
                                                }} className="selectcompany border border-lime col-md-12" style={{ backgroundColor: color, color: text }}>{val.company_name}</div>
                                            })}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card text-center">
                            <div className="card-header f-w-600 bg-red-lighter text-white">
                                <span>Select Branches</span>
                            </div>
                            <div className="card-body border-top-0 border border-lime">
                                <div className="row">
                                    <div style={{ height: 80, width: '400px', overflow: 'auto', overflowX: 'hidden' }}>
                                        <div className="panel">

                                            {this.state.branch_details.map((val, index1) => {
                                                var color = ""
                                                var text = ""
                                                for (let m = 0; m < this.state.selectBranch.length; m++) {
                                                    if (val.branch_id == this.state.selectBranch[m].branch_id) {
                                                        color = "#ff8481"
                                                        text = "white"
                                                    }

                                                }
                                                return <div onClick={() => {

                                                    var match = false;
                                                    for (let i = 0; i < this.state.selectBranch.length; i++) {
                                                        if (this.state.selectBranch[i].branch_id == val.branch_id) {
                                                            this.state.selectBranch.splice(parseInt(i), 1)
                                                            match = true;
                                                            setTimeout(() => {
                                                                this.fetchUser()
                                                            }, 100)
                                                        }

                                                    }
                                                    var count = 0
                                                    for (let j = 0; j < this.state.employee_details.length; j++) {
                                                        if (val.branch_id == this.state.employee_details[j].branch_id) {
                                                            this.state.employee_details.splice(parseInt(j), 1)
                                                            j--;
                                                            this.setState({ refresh: true })


                                                        }
                                                        count++

                                                    }
                                                    if (match == false) {
                                                        var details = { branch_id: val.branch_id, branch_name: val.branch_name, company_id: val.company_id, company_name: val.company_name }
                                                        this.state.selectBranch.push(details);
                                                        setTimeout(() => {
                                                            this.fetchUser()
                                                        }, 100)

                                                    }

                                                    this.setState({ refresh: true })
                                                }}
                                                    className="selectbranch border border-lime col-md-12 " style={{ backgroundColor: color, color: text }}>{val.branch_name}</div>

                                            })}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer f-w-600">
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card text-center">
                            <div className="card-header f-w-600 bg-green-lighter text-white">
                                <span>Select Employees</span>
                            </div>
                            <div className="card-body border-top-0 border border-lime">
                                <div className="row">
                                    <div style={{ height: 310, width: '400px', overflow: 'auto', overflowX: 'hidden' }}>
                                        <div className="panel">
                                            {this.state.employee_details.map((val, index) => {
                                                var color = ""
                                                var text = ""
                                                for (let n = 0; n < this.state.selectEmployee.length; n++) {
                                                    if (val.user_id == this.state.selectEmployee[n].user_id) {
                                                        color = "#5cca7a"
                                                        text = "white"
                                                    }

                                                }
                                                return <div>
                                                    <p style={{ color: "black" }}>{val.company_name}</p>
                                                    <div className="selectemployee border border-lime col-md-12" onClick={() => {
                                                        var match = false;
                                                        for (let i = 0; i < this.state.selectEmployee.length; i++) {
                                                            if (this.state.selectEmployee[i].user_id == val.user_id) {
                                                                this.state.selectEmployee.splice(parseInt(i), 1)
                                                                match = true;

                                                            }

                                                        }

                                                        if (match == false) {
                                                            var details = { company_id: val.company_id, company_name: val.company_name, branch_id: val.branch_id, user_id: val.user_id }
                                                            this.state.selectEmployee.push(details);

                                                        }


                                                        this.setState({ refresh: true })
                                                    }} style={{ backgroundColor: color, color: text }}>{val.COMPLETENAME}</div></div>
                                            })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    submitRequest(e) {
        e.preventDefault();
        // var oneDay = 24 * 60 * 60 * 1000;
        // var date1 = new Date(this.state.timeStart);
        // var date2 = new Date(this.state.timeEnd);
        // var diffDays = Math.abs((date1.getTime() - date2.getTime()) / oneDay * 24);
        var date2 = '';
        var date_end = ''
        var date_start = moment(this.state.request_date_start).format('YYYY-MM-DD');
        var date1 = new Date(date_start + ' ' + this.state.timeStart1);
        var timeSt = this.state.timeStart1
        var timeEn = ''
        if (this.state.req_type == 'Undertime') {
            date2 = date1
            timeEn = timeSt
            date_end = date_start
        } else {
            date_end = moment(this.state.request_date_end).format('YYYY-MM-DD')
            date2 = new Date(date_end + ' ' + this.state.timeEnd1);
            timeEn = this.state.timeEnd1
        }

        // var oneDay = 24 * 60 * 60 * 1000;


        // var diffDays = Math.abs((date1.getTime() - date2.getTime()) / oneDay * 24);
        if (date1 < date2 || this.state.req_type == 'Undertime') {
            getData('HumanResource/logRequest', {
                req_type: this.state.req_type,
                branch_id: '',
                company_id: '',
                user_id: localStorage.getItem('u'),
                app_id: '7',
                req_justification: this.state.justification,
                request_date_start: date_start + ' ' + timeSt,
                request_date_end: date_end + ' ' + timeEn,
                req_leave_type: this.state.req_leave_type,
                req_leave_premise: this.state.req_leave_premise,
                status: 'Pending',
                req_hours_approved: this.state.req_hours_approved,
                req_hierarchy_structure: "",
                req_hierarchy_level: 0,
                req_with_pay: this.state.req_with_pay,

            })
                .then((response) => {
                    if (response.result == "Success") {
                        this.fetchCompany();
                        this.fetchDAR();
                        alert('Success');
                        this.setState({ justification: '', timeStart1: '', timeEnd1: '', request_date_start: '', request_date_end: '' })
                    }
                })
        } else {
            alert('Invalid Time');

        }

    }
    requestHeader(type) {


        var headerdata = []


        headerdata.push({ header: "Start" })
        headerdata.push({ header: "End" })
        headerdata.push({ header: "Type" })
        headerdata.push({ header: "Justification" })
        headerdata.push({ header: "Approved Hours" })
        headerdata.push({ header: "Status" })
        headerdata.push({ header: "Action" })
        this.setState({ headerdata: headerdata })
        var tableData = []
        for (let index = 0; index < this.state.myRequest.length; index++) {
            var data = {
                req_type: this.state.myRequest[index].req_type, date_start: this.state.myRequest[index].req_date_start, date_end: this.state.myRequest[index].req_date_end, justification: this.state.myRequest[index].req_justification, req_hours_approved: this.state.myRequest[index].req_hours_approved, status: this.state.myRequest[index].status

            }
            tableData.push(data);
        }
        this.setState({ myReq: tableData })



    }
    fetchCompany() {
        getData("users/getCompanies", localStorage.getItem("u"))
            .then(response => {
                this.setState({ DATA: response.company });
                var data = [];
                for (let i = 0; i < response.company.length; i++) {
                    var match = false;
                    for (let index = 0; index < data.length; index++) {
                        if (data[index].company_name == response.company[i].company_name) {
                            match = true;
                        }
                    }
                    var data2 = [];
                    if (match == false) {
                        data2 = { company_name: response.company[i].company_name, company_id: response.company[i].company_id };
                        data.push(data2);
                    }
                }
                this.setState({ company_details: data });
            });
    }
    postSelection() {

        return <div>
            {this.state.click == true &&

                <div>
                    <div className='panel' style={{ marginRight: '5%' }}>
                        <div className='panel-body'>
                            <h5><i className="fa fa-newspaper">&nbsp;</i>Tag With</h5>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <br />
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h6 ><input type="checkbox" onClick={() => { this.setState({ user: true, company: !this.state.company, branch: true, branch1: true, branch_details: [], select: [], employee_details: [] }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Company</h6>
                                        </div>
                                        <div className='col-md-6'>
                                            {this.state.select.length != 0 &&
                                                <h6 ><input type="checkbox" onClick={() => { this.setState({ branch: !this.state.branch }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Branch</h6>
                                            }
                                        </div>



                                    </div>

                                    <div>
                                        {!this.state.company &&
                                            <div className="panel-body solid">
                                                <label>Select Company</label>
                                                <Scrollbars style={{ height: 120 }}>
                                                    <table width="100%">
                                                        <tbody>
                                                            {this.state.company_details.map((val, index) => {
                                                                return <tr key={index}>
                                                                    <td width="10%"><input type="checkbox" onClick={() => {
                                                                        var match = false;
                                                                        this.setState({ branch1: !this.state.branch1, user: true })
                                                                        for (let i = 0; i < this.state.select.length; i++) {
                                                                            if (this.state.select[i].company_id == val.company_id) {
                                                                                this.state.select.splice(parseInt(i), 1)
                                                                                match = true;
                                                                                setTimeout(() => {
                                                                                    this.fetchUser()
                                                                                }, 100)
                                                                            }

                                                                        }

                                                                        for (let k = 0; k < this.state.branch_details.length; k++) {
                                                                            if (val.company_id == this.state.branch_details[k].company_id) {
                                                                                this.state.branch_details.splice(parseInt(k), 1)
                                                                                k--;

                                                                            }

                                                                        }
                                                                        for (let j = 0; j < this.state.employee_details.length; j++) {
                                                                            if (val.company_id == this.state.employee_details[j].company_id) {
                                                                                this.state.employee_details.splice(parseInt(j), 1)
                                                                                j--;
                                                                            }
                                                                        }
                                                                        if (match == false) {
                                                                            var details = { company_id: val.company_id, company_name: val.company_name }
                                                                            this.state.select.push(details);
                                                                            this.fetchBranch();
                                                                            setTimeout(() => {
                                                                                this.fetchUser()
                                                                            }, 100)
                                                                        }



                                                                        this.setState({ refresh: true })
                                                                    }} /></td>
                                                                    <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.company_name}</p></td>
                                                                </tr>
                                                            })

                                                            }


                                                        </tbody>
                                                    </table>
                                                </Scrollbars>
                                            </div>
                                        }

                                        {/* <div style={{ marginTop: 10 }}>
                                            {!this.state.branch1 &&
                                                <h6><input type="checkbox" onClick={() => { this.setState({ branch: !this.state.branch }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Branch</h6>
                                            }

                                        </div> */}
                                        {!this.state.branch != 0 &&
                                            <div className="panel-body solid">
                                                <label>Select Branch</label>
                                                <Scrollbars style={{ height: 120 }}>
                                                    <table width="100%">
                                                        <tbody>
                                                            {this.state.select.map((val1, index1) => {
                                                                return <div>
                                                                    <p style={{ color: "black" }}>{val1.company_name}</p>
                                                                    {this.state.branch_details.map((val, index) => {
                                                                        if (val1.company_id == val.company_id)
                                                                            return <div>
                                                                                <tr key={index}>
                                                                                    <td width="10%"><input type="checkbox" onClick={() => {

                                                                                        var match = false;
                                                                                        for (let i = 0; i < this.state.selectBranch.length; i++) {
                                                                                            if (this.state.selectBranch[i].branch_id == val.branch_id) {
                                                                                                this.state.selectBranch.splice(parseInt(i), 1)
                                                                                                match = true;
                                                                                                setTimeout(() => {
                                                                                                    this.fetchUser()
                                                                                                }, 100)
                                                                                            }

                                                                                        }
                                                                                        var count = 0
                                                                                        for (let j = 0; j < this.state.employee_details.length; j++) {
                                                                                            if (val.branch_id == this.state.employee_details[j].branch_id) {
                                                                                                this.state.employee_details.splice(parseInt(j), 1)
                                                                                                j--;
                                                                                                this.setState({ refresh: true })


                                                                                            }
                                                                                            count++

                                                                                        }
                                                                                        if (match == false) {
                                                                                            var details = { branch_id: val.branch_id, branch_name: val.branch_name, company_id: val.company_id, company_name: val.company_name }
                                                                                            this.state.selectBranch.push(details);
                                                                                            setTimeout(() => {
                                                                                                this.fetchUser()
                                                                                            }, 100)

                                                                                        }

                                                                                        this.setState({ refresh: true })
                                                                                    }} /></td>
                                                                                    <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.branch_name}</p></td>
                                                                                </tr>
                                                                            </div>
                                                                    })
                                                                    }
                                                                </div>
                                                            })}
                                                            {/* <tr >
                                                           <td width="10%"><input type="checkbox"/></td>
                                                           <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;Camanava</p></td>
                                                       </tr> */}
                                                        </tbody>
                                                    </table>
                                                </Scrollbars>
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    {!this.state.branch1 &&
                                        <div >
                                            {/* <h6><input type="checkbox" onClick={() => {this.setState({ user: !this.state.user, selectEmployee: [] }) }} /><i className=""></i>&nbsp;&nbsp;&nbsp;Employee</h6> */}
                                        </div>
                                    }
                                    {this.state.select.length != 0 &&
                                        <div className="panel-body solid" style={{ marginTop: 40 }}>
                                            <label>Select Employee for specific persons only</label>
                                            <Scrollbars style={{ height: 335 }}>
                                                <table width="100%">
                                                    <tbody>
                                                        {this.state.select.map((val1, index) => {

                                                            return <div>

                                                                <p style={{ color: "black" }}>{val1.company_name}</p>

                                                                {this.state.employee_details.map((val, index) => {
                                                                    if (val1.company_id == val.company_id)
                                                                        return <tr key={index}>
                                                                            <td width="10%"><input type="checkbox" onClick={() => {
                                                                                var match = false;
                                                                                for (let i = 0; i < this.state.selectEmployee.length; i++) {
                                                                                    if (this.state.selectEmployee[i].user_id == val.user_id) {
                                                                                        this.state.selectEmployee.splice(parseInt(i), 1)
                                                                                        match = true;

                                                                                    }

                                                                                }

                                                                                if (match == false) {
                                                                                    var details = { company_id: val.company_id, company_name: val.company_name, branch_id: val.branch_id, user_id: val.user_id }
                                                                                    this.state.selectEmployee.push(details);

                                                                                }


                                                                                this.setState({ refresh: true })
                                                                            }} /></td>
                                                                            <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.COMPLETENAME}</p></td>
                                                                        </tr>
                                                                })

                                                                }
                                                            </div>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Scrollbars>
                                        </div>
                                    }



                                </div>

                            </div>
                            <hr className="HR"></hr>
                            {/* <button className="form-control" style={{ backgroundColor: "#0f9e3e", color: "white", cursor: "pointer" }} onClick={() => this.post()}><center>Post</center></button> */}
                        </div>
                    </div>
                </div>

            }
        </div>
    }

    post1Selection() {

        return <div>
            {this.state.click == true &&

                <div>
                    <div className='panel' style={{ marginRight: '5%' }}>
                        <div className='panel-body'>
                            <h5><i className="fa fa-newspaper">&nbsp;</i>Tag With</h5>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <br />
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h6 ><input type="checkbox" onClick={() => { this.setState({ user: true, company: !this.state.company, branch: true, branch1: true, branch_details: [], select: [], employee_details: [] }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Company</h6>
                                        </div>
                                        <div className='col-md-6'>
                                            {this.state.select.length != 0 &&
                                                <h6 ><input type="checkbox" onClick={() => { this.setState({ branch: !this.state.branch }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Branch</h6>
                                            }
                                        </div>



                                    </div>

                                    <div>
                                        {!this.state.company &&
                                            <div className="panel-body solid">
                                                <label>Select Company</label>
                                                <Scrollbars style={{ height: 120 }}>
                                                    <table width="100%">
                                                        <tbody>
                                                            {this.state.company_details.map((val, index) => {
                                                                return <tr key={index}>
                                                                    <td width="10%"><input type="checkbox" onClick={() => {
                                                                        var match = false;
                                                                        this.setState({ branch1: !this.state.branch1, user: true })
                                                                        for (let i = 0; i < this.state.select.length; i++) {
                                                                            if (this.state.select[i].company_id == val.company_id) {
                                                                                this.state.select.splice(parseInt(i), 1)
                                                                                match = true;
                                                                                setTimeout(() => {
                                                                                    this.fetchUser()
                                                                                }, 100)
                                                                            }

                                                                        }

                                                                        for (let k = 0; k < this.state.branch_details.length; k++) {
                                                                            if (val.company_id == this.state.branch_details[k].company_id) {
                                                                                this.state.branch_details.splice(parseInt(k), 1)
                                                                                k--;

                                                                            }

                                                                        }
                                                                        for (let j = 0; j < this.state.employee_details.length; j++) {
                                                                            if (val.company_id == this.state.employee_details[j].company_id) {
                                                                                this.state.employee_details.splice(parseInt(j), 1)
                                                                                j--;
                                                                            }
                                                                        }
                                                                        if (match == false) {
                                                                            var details = { company_id: val.company_id, company_name: val.company_name }
                                                                            this.state.select.push(details);
                                                                            this.fetchBranch();
                                                                            setTimeout(() => {
                                                                                this.fetchUser()
                                                                            }, 100)
                                                                        }



                                                                        this.setState({ refresh: true })
                                                                    }} /></td>
                                                                    <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.company_name}</p></td>
                                                                </tr>
                                                            })

                                                            }


                                                        </tbody>
                                                    </table>
                                                </Scrollbars>
                                            </div>
                                        }

                                        {/* <div style={{ marginTop: 10 }}>
                                            {!this.state.branch1 &&
                                                <h6><input type="checkbox" onClick={() => { this.setState({ branch: !this.state.branch }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Branch</h6>
                                            }
                                        </div> */}
                                        {!this.state.branch != 0 &&
                                            <div className="panel-body solid">
                                                <label>Select Branch</label>
                                                <Scrollbars style={{ height: 120 }}>
                                                    <table width="100%">
                                                        <tbody>
                                                            {this.state.select.map((val1, index1) => {
                                                                return <div>
                                                                    <p style={{ color: "black" }}>{val1.company_name}</p>
                                                                    {this.state.branch_details.map((val, index) => {
                                                                        if (val1.company_id == val.company_id)
                                                                            return <div>
                                                                                <tr key={index}>
                                                                                    <td width="10%"><input type="checkbox" onClick={() => {

                                                                                        var match = false;
                                                                                        for (let i = 0; i < this.state.selectBranch.length; i++) {
                                                                                            if (this.state.selectBranch[i].branch_id == val.branch_id) {
                                                                                                this.state.selectBranch.splice(parseInt(i), 1)
                                                                                                match = true;
                                                                                                setTimeout(() => {
                                                                                                    this.fetchUser()
                                                                                                }, 100)
                                                                                            }

                                                                                        }
                                                                                        var count = 0
                                                                                        for (let j = 0; j < this.state.employee_details.length; j++) {
                                                                                            if (val.branch_id == this.state.employee_details[j].branch_id) {
                                                                                                this.state.employee_details.splice(parseInt(j), 1)
                                                                                                j--;
                                                                                                this.setState({ refresh: true })


                                                                                            }
                                                                                            count++

                                                                                        }
                                                                                        if (match == false) {
                                                                                            var details = { branch_id: val.branch_id, branch_name: val.branch_name, company_id: val.company_id, company_name: val.company_name }
                                                                                            this.state.selectBranch.push(details);
                                                                                            setTimeout(() => {
                                                                                                this.fetchUser()
                                                                                            }, 100)

                                                                                        }

                                                                                        this.setState({ refresh: true })
                                                                                    }} /></td>
                                                                                    <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.branch_name}</p></td>
                                                                                </tr>
                                                                            </div>
                                                                    })
                                                                    }
                                                                </div>
                                                            })}
                                                            {/* <tr >
                                                           <td width="10%"><input type="checkbox"/></td>
                                                           <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;Camanava</p></td>
                                                       </tr> */}
                                                        </tbody>
                                                    </table>
                                                </Scrollbars>
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    {!this.state.branch1 &&
                                        <div >
                                            {/* <h6><input type="checkbox" onClick={() => {this.setState({ user: !this.state.user, selectEmployee: [] }) }} /><i className=""></i>&nbsp;&nbsp;&nbsp;Employee</h6> */}
                                        </div>
                                    }
                                    {this.state.select.length != 0 &&
                                        <div className="panel-body solid" style={{ marginTop: 40 }}>
                                            <label>Select Employee for specific persons only</label>
                                            <Scrollbars style={{ height: 335 }}>
                                                <table width="100%">
                                                    <tbody>
                                                        {this.state.select.map((val1, index) => {

                                                            return <div>

                                                                <p style={{ color: "black" }}>{val1.company_name}</p>

                                                                {this.state.employee_details.map((val, index) => {
                                                                    if (val1.company_id == val.company_id)
                                                                        return <tr key={index}>
                                                                            <td width="10%"><input type="checkbox" onClick={() => {
                                                                                var match = false;
                                                                                for (let i = 0; i < this.state.selectEmployee.length; i++) {
                                                                                    if (this.state.selectEmployee[i].user_id == val.user_id) {
                                                                                        this.state.selectEmployee.splice(parseInt(i), 1)
                                                                                        match = true;

                                                                                    }

                                                                                }

                                                                                if (match == false) {
                                                                                    var details = { company_id: val.company_id, company_name: val.company_name, branch_id: val.branch_id, user_id: val.user_id }
                                                                                    this.state.selectEmployee.push(details);

                                                                                }


                                                                                this.setState({ refresh: true })
                                                                            }} /></td>
                                                                            <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.COMPLETENAME}</p></td>
                                                                        </tr>
                                                                })

                                                                }
                                                            </div>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Scrollbars>
                                        </div>
                                    }



                                </div>

                            </div>
                            <hr className="HR"></hr>
                            {/* <button className="form-control" style={{ backgroundColor: "#0f9e3e", color: "white", cursor: "pointer" }} onClick={() => this.post()}><center>Post</center></button> */}
                        </div>
                    </div>
                </div>

            }
        </div>
    }

    post() {
        this.setState({ showMore: 1, newsFeed: [], countCommentArray: [] })


        getData("humanResource/postNewsFedd", { company: this.state.select, branch: this.state.selectBranch, employee: this.state.selectEmployee, handle: this.state.DATA, post_details: this.state.post_details, u_id: localStorage.getItem("u") })
            .then(response => {
                if (response.success == "success") {

                    setTimeout(() => {
                        this.fetch_newsFeed();
                    }, 500)

                    this.setState({ select: [], select: [], selectEmployee: [], post_details: "", company: true, branch: true, user: true, click: false })
                }
            })
    }
    fetch_newsFeed() {

        getData("humanResource/getNewsFedd", { u_id: localStorage.getItem("u"), showMore: this.state.showmore })
            .then(response => {
                var countComment = []
                // this.setState({ DaysPresent: response.DaysPresent, DaysAbsent: response.DaysAbsent })
                this.setState({ DaysPresent: response.DaysPresent, pieGraph: [{ title: "Days Present", value: response.DaysPresent.length }, { title: "Days Late", value: response.DaysLate.length }, { title: "Days Absent", value: response.DaysAbsent }] })
                var arrayDate = []
                var month = ''
                for (let i = 0; i < response.DaysPresent.length; i++) {
                    var date = new Date(response.DaysPresent[i].date);
                    arrayDate.push(date);
                    month = moment().format('MMMM YYYY')
                }
                this.setState({ dateData: arrayDate, monthSelected: month })
                for (let index = 0; index < response.commentEn.length; index++) {
                    this.setState({ commentEn: response.commentEn[index].comment_enable })

                }
                for (let index = 0; index < response.data.length; index++) {
                    var dataArr = { post_id: response.data[index].post_id, countComment: response.data[index].countComment };

                    this.state.countCommentArray.push(dataArr)
                }
                var dataDetails = [];

                if (this.state.showmore != 1) {

                }
                for (let index = 0; index < response.data.length; index++) {
                    this.state.newsFeed.push(response.data[index]);

                }

                if (response.data.length >= 10) {
                    this.setState({ show: true })
                }
                if (response.data.length == 0) {
                    this.setState({ show: false })
                }
                this.setState({ refresh: true })

                // this.state.newsFeed.push(response.data)

            })
    }
    handleChangeMonth = (e) => {
        var month = ''
        month = moment(e).format('MMMM YYYY')
        getData("humanResource/getMonthAttendance", { month: e, u_id: localStorage.getItem('u') })
            .then(response => {
                // this.setState({ holiday: response.data })
                // var arrayDate = [];
                // for (let i = 0; i < response.data.length; i++) {
                //     var date = new Date(response.data[i].holiday_date);
                //     arrayDate.push(date);
                // }
                // this.setState({ dateData: arrayDate })
                // // this.setState({holiday:response.data})

                this.setState({ DaysPresent: response.DaysPresent, pieGraph: [{ title: "Days Present", value: response.DaysPresent.length }, { title: "Days Late", value: response.DaysLate.length }, { title: "Days Absent", value: response.DaysAbsent }] })
                var arrayDate = []

                for (let i = 0; i < response.DaysPresent.length; i++) {
                    var date = new Date(response.DaysPresent[i].date);
                    arrayDate.push(date);

                }


                this.setState({ dateData: arrayDate, monthSelected: month })

            })
    }
    searchPost() {
        // this.state.showMore++;
        this.searchP();
    }
    searchP() {
        var data = [];
        data = { u_id: localStorage.getItem('u'), searhPost: this.state.searchDriver, showMore: 1 }

        getData('Users/searchPost', data)
            .then((response) => {
                this.setState({ newsFeed: [] })
                for (let index = 0; index < response.data.length; index++) {
                    this.state.newsFeed.push(response.data[index]);
                }
                this.setState({ refresh: true })
            })
    }



    componentWillMount() {

        this.setState({ m_o_disabledButton: true })
        this.setState({ p_o_disabledButton: true })
        this.setState({ t_o_disabledButton: true })
        this.setState({ w_o_disabledButton: true })
    }
    onclick(data) {
        var array = []
        localStorage.setItem('app_idDta', JSON.stringify(array));
        localStorage.setItem('AppD', true)
        localStorage.setItem('selectApp', true)
        localStorage.setItem('selectApp_id', data.app_id)
        this.setState({ refresh: true })
    }

    showApps() {
        var app_id = JSON.parse(localStorage.getItem('app_id_priv'))
        getData("users/getApps", app_id).then(response => {
            var details = []
            var app_details = [];
            for (let i = 0; i < response.length; i++) {
                var data = [];
                data = { app_id: response[i][0].app_id, category_attribute: response[i][0].category_attribute, category_details: response[i][0].category_details, category_field: response[i][0].category_field, category_id: response[i][0].category_id, category_type: response[i][0].category_type, company_id: response[i][0].company_id, href: response[i][0].c_href }
                app_details.push(data);
            }
            this.setState({ apps: app_details })

        })
    }
    updateDAR(e) {

        e.preventDefault();
        var oneDay = 24 * 60 * 60 * 1000;
        var date1 = new Date(this.state.timeStart1);
        var date2 = new Date(this.state.timeEnd1);
        let start = "";
        if (this.state.timeStart1 === "") {
            start = moment(this.state.timestater).format('YYYY-MM-DD H-mm-ss')
            date1 = new Date(this.state.timestater);
        } else {
            start = moment(this.state.timeStart1).format('YYYY-MM-DD H-mm-ss')
        }
        let end = "";
        if (this.state.timeEnd1 === "") {
            end = moment(this.state.timeender).format('YYYY-MM-DD H-mm-ss')
            date2 = new Date(this.state.timeender);

        } else {
            end = moment(this.state.timeEnd1).format('YYYY-MM-DD H-mm-ss')
        }
        var diffDays = Math.abs((date1.getTime() - date2.getTime()) / oneDay * 24);

        var data = {
            accomplishment_id: this.state.accom_id,
            accomplishment_date_start: start,
            accomplishment_date_end: end,
            accomplishment_hours: diffDays,
            status: this.state.status,
            accomplishment_description: this.state.textarea,
            accomplishment_type: this.state.type,
            app_id: 7,
            user_id: localStorage.getItem("u"),
            project_id: 0,
            method: "Edit"
        }
        // console.log(data)

        getData("humanresource/LogAccomplishment", data).then(response => {
            // console.log(response)
            if (response.response == "Success") {
                this.fetchDAR();
                alert("Accomplishement Updated Success")
                $("#close").click();

            } else {
                alert("Accomplishement Updated Failed")
            }
        })
    }
    deleteAccom() {
        getData("humanresource/delete_accomplishment", this.state.accom_id).then(response => {
            if (response === true) {
                this.fetchDAR()
                $("#close").click();
                alert("Accomplishment Deleted Successfully!")
            } else {
                alert("Accomplishment Deleted Failed!")

            }
        })
    }
    render() {
        // console.log(this.state.CommentData)
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        // let Post =this.state.newsFeed.filter(
        //     (files) => {
        //         return files.post_details.toLowerCase().indexOf(
        //             this.state.searchDriver.toLocaleLowerCase()) !== -1 ||  files.user_fname.toLowerCase().indexOf(
        //                 this.state.searchDriver.toLocaleLowerCase()) !== -1 ||  files.user_lname.toLowerCase().indexOf(
        //                     this.state.searchDriver.toLocaleLowerCase()) !== -1
        //     }
        // )
        return (
            <div id="content" className="content">
                <button id="cookie" data-target="#demo-cookies" data-toggle="modal" hidden />
                {/* ========================================content start===================================== */}
                <div className="row">
                    {/* ========================================news feed start===================================== */}
                    <div className="col-md-9">
                        <Scrollbars autoHide style={{ minHeight: 600, }}>
                            <div>
                                <div className="tab-pane fade show " >
                                    {this.state.commentEn == 1 &&
                                        <div>
                                            <div className=" panel " style={{ minHeight: 100, marginRight: '5%' }}>
                                                <div className="panel-body row">
                                                    <div className="col-md-1">
                                                        <span className="userimage">
                                                            <img style={{ borderRadius: "50%", width: 50, height: 50 }} className=" section_div_img" src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + localStorage.getItem('imagePic'))} />
                                                        </span>
                                                    </div>
                                                    <div className="col-md-11" data-step='16' data-position='bottom-middle-aligned' data-intro='This is section is where you compose your announcements'>
                                                        <TextareaAutosize rows={3} placeholder='Would like to make a announcement?' onChange={this.textchange} onClick={() => this.setState({ refresh: true })} type="text" name="post_details" value={this.state.post_details} className="f-s-14" style={{ width: '100%', borderStyle: "none", textIndent: "10px", textDecoration: 'none' }} />
                                                    </div>
                                                </div>
                                                <div className="panel-footer row" style={{ marginRight: '5%' }}>
                                                    <div className="col-md-6 col-sm-12">
                                                        {/* <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ click: !this.state.click }) }} className="fa fa-user-plus p-l-20 p-r-20 fa-2x" data-step='18' data-position='bottom-middle-aligned' data-intro='This is button selects the audience for your announcement'></span> */}
                                                        <button className="btttn pull-left fa fa-user-tag bg-blue-transparent-9" data-target="#demo-xl-tag" data-toggle="modal" >&nbsp;Select&nbsp;Audience</button>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <button onClick={() => this.post()} className="btttn pull-right bg-green-transparent-9 fa fa-paper-plane">&nbsp;Post</button>
                                                    </div>
                                                </div>

                                            </div>

                                            {this.postSelection()}
                                        </div>
                                    }
                                    <div className="panel">
                                        <div className="panel-heading row">
                                            <div className="col-md-6">
                                                <h4 className="panel-title">Post an update</h4>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="pull-right" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <button className="btttn bg-green-transparent-9 fas fa-user-tag m-5">&nbsp;Tag&nbsp;Employees</button>
                                                    <button className="btttn bg-blue-transparent-9 fa fa-paper-plane m-5">&nbsp;Post</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <TextareaAutosize rows={3} placeholder="What's on your mind?" style={{ width: "100%", borderStyle: "solid", borderRadius: 12, borderWidth: 1, textIndent: 10 }}></TextareaAutosize>
                                        </div>

                                    </div>
                                    {this.state.newsFeed.map((val, index) => {
                                        return <div style={{ position: "relative", marginTop: 10 }}>
                                            <img src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} className="position-user-image" />
                                            <div className="panel position-user-dets">
                                                <div className="panel-heading" style={{ textIndent: 15 }}>
                                                    <div className="homepost">
                                                        <div className="home-name-company">
                                                            <h4 className="panel-title">{val.user_fname + ' ' + val.user_lname}</h4>
                                                            <label>Posted {moment(val.datePost).startOf('day').fromNow()}</label>
                                                        </div>
                                                        <div className="home-tag-time">
                                                            <label>{val.user_jobposition} | {val.company_name}</label>
                                                            <label>Tagged Employees: <i class="fas fa-user-tag text-indigo" /></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel-body" >
                                                    <p dangerouslySetInnerHTML={{ __html: val.post_details }} />
                                                </div>
                                                <div id="accordion" className="accordion">

                                                    <div className="card" data-step='3' data-position='left' data-intro='This section is for Overtime/Undertime/Official Business/Leave Filing Request'>
                                                        {this.state.countCommentArray.map((valcom, indexcom) => {
                                                            if (val.post_id == valcom.post_id)
                                                                return <div className="card-header ulliot pointer-cursor d-flex align-items-center" data-toggle="collapse" data-target="#collapsecomment"
                                                                    onClick={() => {
                                                                        // var dataVal = val.post_id
                                                                        if (!this.state.comment1) {
                                                                            this.setState({ post_ID: val.post_id })
                                                                            alert("condition 1")
                                                                        } else {
                                                                            this.setState({ post_ID: "" })
                                                                            alert("condition 2")
                                                                        }
                                                                        if (this.state.post_id == val.post_id) {
                                                                            this.setState({ post_id: "" })
                                                                            alert("condition 3")
                                                                        }
                                                                        else {
                                                                            // (
                                                                            alert("condition 4")
                                                                            //     )
                                                                            this.setState({ post_id: val.post_id })
                                                                        }
                                                                        this.setState({ comment1: !this.state.comment1 }); this.fetchComments(val.post_id)
                                                                    }}
                                                                >
                                                                    <i className="far fa-comments text-blue mr-2" />Comments ({valcom.countComment})
                                                    </div>
                                                        })}
                                                        {(val.post_id == this.state.post_id) &&
                                                            <div>
                                                                {this.state.CommentData.map((val1, index1) => {
                                                                    console.log(val1)
                                                                    return <div index={index1} className="comment-main" style={{ borderBottomStyle: "solid", borderBottomWidth: 0.1 }}>
                                                                        <img className="comment-pic m-10" style={{ width: 50, height: 50, borderRadius: 50 }} src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val1.user_pic)} />
                                                                        <div className="comment-comment p-5">
                                                                            <label className="m-l-10">{val1.user_fname.toUpperCase()}&nbsp;{val1.user_lname.toUpperCase()}</label>
                                                                            <label className="text-grey m-l-15">{moment(val1.DATE).startOf('day').fromNow()}</label>
                                                                            <TextareaAutosize style={{ textAlign: "justify", whiteSpace: 'normal', width: '100%', borderStyle: 'none' }} disabled className="bg-white">
                                                                                {val1.comment_details}
                                                                            </TextareaAutosize>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                                <form className="form" onSubmit={this.insertComment}>
                                                                    <input className="input" placeholder="Write a comment.." name="comment" value={this.state.comment} onChange={this.textchange2} required />
                                                                    <button type="submit"  ><i className="fa fa-3x fa-arrow-alt-circle-right" ></i></button>
                                                                </form>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    })}




                                    <div data-step='19' data-position='bottom-middle-aligned' data-intro='This is button selects the audience for your announcement'>
                                        <ul className="timeline">
                                            {this.state.newsFeed.map((val, index) => {
                                                var date = ''
                                                if (moment(val.datePost).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')) {
                                                    date = "today"
                                                } else {
                                                    date = moment(val.datePost).format('ll')
                                                }
                                                return <li style={{ padding: 5 }}>
                                                    {/* <div className="timeline-time">
                                                        <span className="date">{date}</span>
                                                        <span className="time">{moment(val.datePost).format('LT')}</span>
                                                    </div>
                                                    <div className="timeline-icon">
                                                        <a href="javascript:;">&nbsp;</a>
                                                    </div> */}
                                                    <div className="timeline-body ">
                                                        <div className="timeline-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <span className="userimage" > <img align="middle" style={{ borderRadius: "50%" }} src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} /></span>
                                                                <div data-target="#demo-xl-preprof" data-toggle="modal" onClick={() => {
                                                                    this.setState({ pic: val.user_pic })
                                                                    this.setState({ fname: val.user_fname })
                                                                    this.setState({ lname: val.user_lname })
                                                                    this.setState({ position: val.user_jobposition })
                                                                    this.setState({ company: val.company_name })
                                                                    this.setState({ branch: val.branch_name })
                                                                    this.setState({ email: val.user_email })
                                                                    this.setState({ contact: val.user_mobile })
                                                                    this.setState({ department: val.dept_name })
                                                                }} className="username">
                                                                    <a href="javascript:;" style={{ fontSize: 13 }}>{val.user_lname + ' ' + val.user_fname}</a>
                                                                    <p style={{ fontSize: 13, marginTop: '-15px' }}>{val.company_name + " " + "|" + " " + val.dept_name}</p>
                                                                </div>
                                                            </div>

                                                            <span className="pull-right text-muted"><a className="tool pull-right" data-tip={"Shared to : " + val.Shared} tabindex="2" ><i className="fa fa-users"></i></a></span>

                                                        </div>
                                                        {/* <div className="timeline-header">
                                                            <span className="userimage" > <img align="middle" style={{ borderRadius: "50%" }} src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} /></span>
                                                            <div data-target="#demo-xl-preprof" data-toggle="modal" onClick={() => {
                                                                this.setState({ pic: val.user_pic })
                                                                this.setState({ fname: val.user_fname })
                                                                this.setState({ lname: val.user_lname })
                                                                this.setState({ position: val.user_jobposition })
                                                                this.setState({ company: val.company_name })
                                                                this.setState({ branch: val.branch_name })
                                                                this.setState({ email: val.user_email })
                                                                this.setState({ contact: val.user_mobile })
                                                                this.setState({ department: val.dept_name })
                                                            }} className="username">
                                                                <a href="javascript:;" style={{ fontSize: 13 }}>{val.user_lname + ' ' + val.user_fname}</a>
                                                                <p>{val.company_name + " " + "|" + " " + val.dept_name}</p>
                                                            </div>
                                                            <span className="pull-right text-muted"><a className="tool pull-right" data-tip={"Shared to : " + val.Shared} tabindex="2" ><i className="fa fa-users"></i></a></span>
                                                        </div> */}
                                                        <div className="timeline-content">
                                                            <p className="Features" dangerouslySetInnerHTML={{ __html: val.post_details }} />
                                                        </div><hr />
                                                        <div className="text-center">
                                                            {this.state.countCommentArray.map((valcom, indexcom) => {
                                                                if (val.post_id == valcom.post_id)
                                                                    return <div >
                                                                        <p href="javascript:;" onClick={() => {
                                                                            // var dataVal = val.post_id
                                                                            if (!this.state.comment1) {
                                                                                this.setState({ post_ID: val.post_id })
                                                                            } else {
                                                                                this.setState({ post_ID: "" })
                                                                            }
                                                                            if (this.state.post_id == val.post_id) {
                                                                                this.setState({ post_id: "" })
                                                                            } else {
                                                                                this.setState({ post_id: val.post_id })
                                                                            }
                                                                            this.setState({ comment1: !this.state.comment1 }); this.fetchComments(val.post_id)
                                                                        }} className="col-xl-6 text-black far fa-comments f-s-13" style={{ textDecorationLine: 'none', padding: 10 }}>&nbsp;{valcom.countComment}&nbsp;Comment</p>
                                                                        <p className="col-xl-6 text-black far fa-bookmark f-s-13" href="javascript:;" style={{ textDecorationLine: 'none', padding: 10 }} onClick={() => { }}>&nbsp;Bookmark</p>
                                                                    </div>
                                                            })}
                                                        </div><hr />
                                                        {(val.post_id == this.state.post_id) &&
                                                            <div style={{ marginTop: '-20px' }}>
                                                                <div className="timeline-comment-box">
                                                                    <div className="user"><img src="../assets/img/user/user-12.jpg" /></div>
                                                                    <div className="input">
                                                                        <form onSubmit={this.insertComment}>
                                                                            <div className="input-group">
                                                                                <input type="text" className="form-control rounded-corner" placeholder="Write a comment..." name="comment" value={this.state.comment} onChange={this.textchange} required />
                                                                                <span className="input-group-btn p-l-10">
                                                                                    <button className="btn bg-blue-lighter text-white border-none f-s-12 rounded-corner" type="submit">Comment</button>
                                                                                </span>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {this.state.CommentData.map((val1, index1) => {
                                                                        if (val.post_id == val1.post_id)
                                                                            return <div key={index1} className="d-flex  ">
                                                                                <img align="middle" style={{ borderRadius: "50%", width: '8%', height: '8%', marginRight: '1%', marginLeft: '1%', marginTop: '2%' }} src="../assets/img/user/user.jpg" />
                                                                                <div className="text-green-lighter" style={{ width: '95%', marginBottom: 5, padding: 10 }} className="d-flex" rows="auto" cols="12">
                                                                                    {val1.user_fname + ' ' + val1.user_mname + ' ' + val1.user_lname + ':'}
                                                                                    <br />{val1.time_post}
                                                                                    <textarea style={{ textAlign: "justify", whiteSpace: 'normal', width: '100%', borderStyle: 'none', borderRadius: 12 }} disabled className="bg-grey-transparent-2">
                                                                                        {val1.comment_details}
                                                                                    </textarea>
                                                                                </div>
                                                                            </div>
                                                                    })
                                                                    }
                                                                </div>
                                                                <br />

                                                                <form className="form" onSubmit={this.insertComment}>
                                                                    <input className="input" placeholder="Write a comment.." name="comment" value={this.state.comment} onChange={this.textchange2} required />
                                                                    <button type="submit" ><i className="fa fa-3x fa-arrow-alt-circle-right" ></i></button>
                                                                </form>
                                                            </div>
                                                        }
                                                    </div>
                                                </li>
                                            })}
                                            {this.state.show == true &&
                                                <li>
                                                    <div className="timeline-icon">
                                                        <a href="javascript:;">&nbsp;</a>
                                                    </div>
                                                    <div className="timeline-body">

                                                        <button className="btn btn-block bg-green-lighter text-white" onClick={() => { this.showMore() }}>Show more</button>

                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </Scrollbars>
                    </div>
                    {/* ========================================news feed end===================================== */}


                    {/* ========================================side cards  start===================================== */}
                    <div className="col-md-3 d-none d-md-block">
                        <div className="input-group m-b-10" data-step='15' data-position='left' data-intro='This is the searchboxx, which can help you find announcements or employee with posts'>
                            <input className="form-control" placeholder="Search Post" value={this.state.searchDriver} name='searchDriver' onChange={this.textchange} data-id="color-palette-1" />
                            <div className="input-group-append">
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li>
                                        <div id="color-palette-1"></div>
                                    </li>
                                </ul>
                                <a style={{ cursor: 'pointer' }} className="btn btn-grey text-black-lighter" onClick={() => { this.searchPost() }}><i className="fa fa-search"></i></a>
                            </div>
                        </div>
                        <div className="card">
                            <h5 className="card-header rounded-top" style={{ backgroundColor: "rgb(21, 27, 38)", color: "white" }}>Your Applications:</h5>

                            <div className="widget-list widget-list-rounded">
                                <Scrollbars style={{ height: 200 }}>
                                    {this.state.apps.map((val) =>
                                        <a href={val.href} onClick={() => this.onclick(val)} className="widget-list-item rounded-0 p-t-3">
                                            <div className="widget-list-media">
                                                <img src={"http://api.pacificweb.com.ph/assets/img/apps/" + val.category_attribute} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div className="widget-list-content">
                                                <div className="widget-list-title">{val.category_details}</div>
                                            </div>

                                        </a>
                                    )}
                                </Scrollbars>
                            </div>
                        </div>
                        <div style={{ cursor: 'pointer' }} className="card border-0 attendancecard" data-step='1' data-position='left' data-intro='This section is where you can view your attendance in current month.<br/>This card is clickable, try it later!' data-target="#demo-xl-modal3" data-toggle="modal" onClick={() => this.setState({ display: "attendance1" })}>
                            <div className="card-body">
                                <h4 className="card-title text-center"> {this.state.monthSelected}</h4>
                                <DPie2 pieGraph={this.state.pieGraph} />
                            </div>
                        </div>

                        <div id="accordion" className="accordion" data-step='2' data-position='left' data-intro='This section is the Request Filing Card, here we can file,view and edit request for Overtime, Undertime, Leave and Official Business.'>
                            <div className="card-header bg-light text-green-lighters">Request Filing</div>

                            <div className="card" data-step='3' data-position='left' data-intro='This section is for Overtime/Undertime/Official Business/Leave Filing Request'>
                                <div className="card-header ulliot pointer-cursor d-flex align-items-center" data-toggle="collapse" data-target="#collapseOne">
                                    <i className="fa fa-user-clock fa-fw text-blue-lighter mr-2"></i>Overtime
</div>
                                <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                    <ul className=" card-body list-group list-group-flush bg-grey-transparent-5">
                                        <li data-step='4' data-position='left' data-intro='This section is for Filing Overtime/Undertime/Official Business/Leave' className="list-group-item ulliot" data-target="#demo-xl-modal2" data-toggle="modal" onClick={() => this.setState({ display: "overtime", req_type: 'Overtime', timeStart: "", timeEnd: "", justification: "", type: "", status: "", timeStart1: "", timeEnd1: "", request_date_start: '', request_date_end: '' })}>File Overtime</li>
                                        <li data-step='5' data-position='left' data-intro='This section is for Viewing Filed Overtime/Undertime/Official Business/Leave' className="list-group-item ulliot" data-target="#demo-xl-modaltrue" data-toggle="modal" onClick={() => { this.setState({ display: "alltable", req_type: 'Overtime' }); this.requestHeader('Overtime') }}>View Filed Overtime</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card" style={{ marginTop: '-18px' }} >
                                <div className="card-header ulliut pointer-cursor d-flex align-items-center collapsed" data-toggle="collapse" data-target="#collapseTwo">
                                    <i className="fa fa-history fa-fw text-orange-lighter mr-2"></i>Undertime
</div>
                                <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                    <ul className=" card-body list-group list-group-flush bg-grey-transparent-5">
                                        <li className="list-group-item ulliut" data-target="#demo-xl-modal2" data-toggle="modal" onClick={() => this.setState({ display: "undertime", req_type: 'Undertime', timeStart: "", timeEnd: "", justification: "", type: "", status: "", timeStart1: "", timeEnd1: "", request_date_start: '', request_date_end: '' })}>File Undertime</li>
                                        <li className="list-group-item ulliut" data-target="#demo-xl-modaltrue" data-toggle="modal" onClick={() => { this.setState({ display: "alltable", req_type: 'Undertime' }); this.requestHeader('Undertime') }}>View Filed Undertime</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card" style={{ marginTop: '-18px' }}>
                                <div className="card-header ulliob pointer-cursor d-flex align-items-center collapsed" data-toggle="collapse" data-target="#collapseThree">
                                    <i className="fa fa-business-time fa-fw text-green-lighter mr-2"></i>Official Business
</div>
                                <div id="collapseThree" className="collapse" data-parent="#accordion">
                                    <ul className=" card-body list-group list-group-flush bg-grey-transparent-5">
                                        <li className="list-group-item ulliob" data-target="#demo-xl-modal2" data-toggle="modal" onClick={() => this.setState({ display: "ob", req_type: 'Official Business', timeStart: "", timeEnd: "", justification: "", type: "", status: "", timeStart1: "", timeEnd1: "", request_date_start: '', request_date_end: '' })}>File Official Business</li>
                                        <li className="list-group-item ulliob" data-target="#demo-xl-modaltrue" data-toggle="modal" onClick={() => { this.setState({ display: "alltable", req_type: 'Official Business' }); this.requestHeader('Official Business') }}>View Filed Official Business</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card" style={{ marginTop: '-18px' }}>
                                <div className="card-header ullilv pointer-cursor d-flex align-items-center collapsed" data-toggle="collapse" data-target="#collapseFour">
                                    <i className="fa fa-user-slash fa-fw text-red-lighter mr-2"></i>Leave
</div>
                                <div id="collapseFour" className="collapse" data-parent="#accordion">
                                    <ul className=" card-body list-group list-group-flush bg-grey-transparent-5">
                                        <li className="list-group-item ullilv" data-target="#demo-xl-modal2" data-toggle="modal" onClick={() => this.setState({ display: "leave", req_type: 'Leave', timeStart: "", timeEnd: "", justification: "", type: "", status: "", timeStart1: "", timeEnd1: "", request_date_start: '', request_date_end: '' })}>File Leave</li>
                                        <li className="list-group-item ullilv" data-target="#demo-xl-modaltrue" data-toggle="modal" onClick={() => { this.setState({ display: "alltable", req_type: 'Leave' }); this.requestHeader('Leave') }}>View Filed Leave</li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="widget-todolist widget-todolist-rounded m-b-10" data-step='9' data-position='left' data-intro='This section is for your Daily Activity Record (DAR)'>
                            <div className="widget-todolist-header">
                                <div className="widget-todolist-header-left">
                                    <h4 className="widget-todolist-header-title text-green-lighter">Daily Activity Record</h4>
                                </div>
                                <div className="widget-todolist-header-right" data-step='10' data-position='top' data-intro='This section of the card displays your total DAR hrs accomplished'>
                                    <div className="widget-todolist-header-total">
                                        <span className="text-green-lighter">{this.state.total_hrs_dar.toFixed(2)}</span>
                                        <small >HRS Accomplished</small>
                                    </div>
                                </div>

                            </div><hr />
                            <div className="row text-center" data-step='11' data-position='left' data-intro='This section is for indication of your status in DAR'>
                                <div className="col-sm-4  text-center">
                                    <div className="row">
                                        <div className="col-md-12"><i className="fa fa-circle text-green-lighter"></i></div>
                                        <div className="col-md-12 text-green-lighter">Finished</div>
                                    </div>

                                </div>
                                <div className="col-sm-4  text-center">
                                    <div className="row">
                                        <div className="col-md-12"><i className="fa fa-circle text-orange-lighter"></i></div>
                                        <div className="col-md-12 text-orange-lighter">Continouos</div>
                                    </div>

                                </div>
                                <div className="col-sm-4  text-center">
                                    <div className="row">
                                        <div className="col-md-12"><i className="fa fa-circle text-red-lighter"></i></div>
                                        <div className="col-md-12 text-red-lighter">Unfinished</div>
                                    </div>

                                </div>
                            </div>
                            <div className="widget-todolist-body" >
                                <div data-step='13' data-position='left' data-intro='This section of the card displays your filed DAR: Time, Type, Status, Description etc.'>
                                    {this.state.todayDar.map((val, index) => {

                                        var bColor = 'white'
                                        if (val.status == 'Finished') {
                                            bColor = '#65bf65'
                                        } else if (val.status == 'Continouos') {
                                            bColor = '#f8b553'
                                        } else if (val.status == 'Unfinished') {
                                            bColor = '#ff8481'
                                        }
                                        if (val.accomplishment_delete_id == 0) {
                                            var readOnly = ''
                                            if (moment(val.accomplishment_date_added).format('YYYY-MM-DD') != moment(new Date()).format('YYYY-MM-DD')) {
                                                readOnly = 'readOnly'
                                            }

                                            return <div style={{ cursor: 'pointer' }} className="widget-todolist-item "
                                                onClick={() => {
                                                    this.setState({
                                                        display: "dar",
                                                        readOnly: readOnly,
                                                        darview: val,
                                                        textarea: val.accomplishment_description,
                                                        timestater: val.accomplishment_date_start,
                                                        timeender: val.accomplishment_date_end,
                                                        accom_id: val.accomplishment_id,
                                                        status: val.status,
                                                        type: val.accomplishment_type,
                                                        dardelete: val.accomplishment_delete_id

                                                    })
                                                    // console.log(val.accomplishment_date_start, val.accomplishment_date_end)
                                                }}

                                                data-target="#demo-xl-previewDar" data-toggle="modal">

                                                <div className="widget-todolist-content">
                                                    <h4 className="widget-todolist-title limiter"><strong>{val.accomplishment_type} -</strong>{val.accomplishment_description}</h4>
                                                    <p className="widget-todolist-desc">Time: {val.timeSt} - {val.timeEn}</p>
                                                </div>
                                                <div className="widget-todolist-icon">
                                                    <span className="fa fa-circle" style={{ color: bColor }} ></span>
                                                </div>
                                            </div>
                                        }

                                    })}
                                </div>

                                <div className="widget-todolist-item" data-step='12' data-position='left' data-intro='This section of the card where you can file your daily DAR' data-target="#demo-xl-modal1" data-toggle="modal" onClick={() => this.setState({ display: "dar" })} >
                                    <div className="widget-todolist-input">
                                        <i className="fa fa-plus text-muted"></i>
                                    </div>
                                    <div className="widget-todolist-content">
                                        <input type="text" className="form-control" placeholder="Write your task here..." />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* ========================================side cards end===================================== */}
                </div>
                {/* ========================================content end===================================== */}

                {/* ========================================modals start===================================== */}
                <div id="demo-xl-previewDar" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 2050 }}>
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Record Activity Details</h4>
                                <button id="close" data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body bg-default">
                                {/* {this.state.darview.map((val, index) => { */}
                                <form onSubmit={this.updateDAR}>
                                    <div className="form-group m-10">
                                        <label>Time Range</label>
                                        <div class="form-row">
                                            <div class="col">
                                                <div>
                                                    <TimePicker
                                                        showSecond={false}
                                                        onChange={this.handleChangeTimeStart}
                                                        placeholder={moment(this.state.timestater).format('LT')}
                                                        format={format}
                                                        use12Hours
                                                        required
                                                    // defaultValue={this.state.timeStart1}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col">
                                                <TimePicker
                                                    showSecond={false}
                                                    format={format}
                                                    placeholder={moment(this.state.timeender).format('LT')}
                                                    use12Hours
                                                    onChange={this.handleChangeTimeEnd}
                                                    // defaultValue={this.state.timeEnd1}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group m-10">
                                        <label className="col-lg-12 col-form-label">Status:</label>
                                        <select className="form-control" value={this.state.status} onChange={this.onChangeSelectStatus} required >
                                            <option >{this.state.darview.status}</option>
                                            <option disabled>-Select Status-</option>
                                            <option >Finished</option>
                                            <option>Continouos</option>
                                            <option>Unfinished</option>
                                        </select>
                                    </div>
                                    <div className="form-group m-10">
                                        <label className="col-lg-12 col-form-label">Accomplishment Type:</label>
                                        <select className="form-control" value={this.state.type} onChange={this.onChangeSelect} required >
                                            <option>{this.state.darview.accomplishment_type}</option>
                                            <option disabled>-Select Type-</option>
                                            <option>Office Work</option>
                                            <option>Official Business</option>
                                            <option>Meeting</option>
                                            <option>Operational</option>
                                            <option>Techinical</option>
                                            <option>Training</option>
                                            <option>Development</option>
                                        </select>
                                    </div>
                                    <textarea rows={5} placeholder='Description of your activity' value={this.state.textarea} className="f-s-14 m-10" style={{ width: '96%', textIndent: "10px", textDecoration: 'none' }} name='textarea' onChange={this.textchange} required />

                                    <button type="submit" className="btttn fa fa-paper-plane pull-right bg-green-transparent-9">&nbsp;Update</button>
                                </form>
                                {this.state.dardelete == 0 &&
                                    <button className="btttn bg-red-transparent-9 fas fa-trash-alt text-white"
                                        onClick={() => { this.deleteAccom() }}>&nbsp;Delete Accomplishment</button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div id="demo-xl-modal1" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 2050 }}>
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Record Activity</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>

                            {this.state.display == "dar" &&
                                this.darfunc()
                            }
                            {this.state.loading == true &&
                                <Loader fullPage loading />
                            }
                        </div>
                    </div>
                </div>
                <div id="demo-xl-modal2" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 2050 }}>
                    <div className="modal-dialog modal-lg modal-dialog-centered" >
                        <div className="modal-content">
                            <form onSubmit={this.submitRequest}>
                                {this.state.display == "overtime" &&
                                    this.otfunc()
                                }
                                {this.state.display == "undertime" &&
                                    this.utfunc()
                                }
                                {this.state.display == "ob" &&
                                    this.obfunc()
                                }
                                {this.state.display == "leave" &&
                                    this.lvfunc()
                                }

                                <div className="modal-footer">
                                    <button className="bttnpost pull-right bg-green-lighter" type='submit'>
                                        <span className="fa fa-paper-plane"></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="demo-xl-modal3" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 1050 }}>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            {this.state.display == "attendance1" &&
                                this.attendance1func()
                            }
                        </div>
                    </div>
                </div>
                <div id="demo-xl-modal4" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 1050 }}>
                    <div className="modal-dialog modal-md modal-dialog-centered"  >
                        {this.state.display == "attendance2" &&
                            this.attendance2func()
                        }
                    </div>
                </div>
                <div id="demo-xl-modaltrue" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 2050 }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">

                            {this.alltable()}


                        </div>
                    </div>
                </div>
                <div id="demo-xl-post" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 1050 }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">

                            <div className=" panel " style={{ minHeight: 100, marginRight: '5%' }}>
                                <div className="panel-body row">
                                    <div className="col-md-1">
                                        <span className="fa fa-user-circle fa-3x text-green-transparent-7"></span>
                                    </div>
                                    <div className="col-md-11">
                                        <TextareaAutosize rows={3} placeholder='Would you like to make a announcement?' onChange={this.textchange} type="text" name="post_details" value={this.state.post_details} className="f-s-14" style={{ width: '100%', borderStyle: "none", textIndent: "10px", textDecoration: 'none' }} />
                                    </div>
                                </div>
                                <div className="panel-footer row bottomicon text-green-transparent-7" style={{ marginRight: '5%' }}>
                                    <div className="col-md-8">
                                        <span className="fa fa-image p-l-20 p-r-20 fa-2x m-t-10"></span>
                                        <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ click: !this.state.click }) }} className="fa fa-user-plus p-l-20 p-r-20 fa-2x"></span>
                                        <span className="fa fa-link p-l-20 p-r-20 fa-2x"></span>
                                        <span className="fa fa-map-marker-alt p-l-20 p-r-20 fa-2x"></span>
                                        <span className="fa fa-ellipsis-h p-l-20 p-r-20 fa-2x"></span>
                                    </div>

                                    <div className="col-md-4">

                                        <button className="bttnpost pull-right bg-green-lighter" style={{ marginRight: '-20%' }}><span className="fa fa-paper-plane"></span></button>
                                    </div>
                                </div>

                            </div>
                            {this.postSelection()}
                        </div>
                    </div>
                </div>
                <div id="demo-xl-tag" className="modal fade" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            {this.taggingfunc()}
                            <div className="modal-footer">
                                <button data-dismiss="modal" className="pull-right btttn fa fa-user-tag bg-green-transparent-9">
                                    &nbsp;Tag Employee/s
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-xl-preprof" className="modal fade" tabindex="-1" data-backdrop='static' style={{ zIndex: 2050 }}>
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Employee Details</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9">
                                    <span className="fa fa-times-circle" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ width: '100%', height: 'auto' }} className="bg-white text-center predetailsbot">
                                    <div style={{ textIndent: 50 }}>
                                        <span className="username" style={{ marginTop: 100, fontSize: 30 }}>{this.state.lname},{this.state.fname}</span>
                                    </div>

                                    <span className="username">{this.state.position}</span>
                                    <form style={{ marginTop: 40 }}>
                                        <div className="form-group row m-b-15 m-r-5" >
                                            <label className="col-form-label col-md-3">Company</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control m-b-5" placeholder={this.state.company} disabled />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-15 m-r-5" >
                                            <label className="col-form-label col-md-3">Branch</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control m-b-5" placeholder={this.state.branch} disabled />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-15 m-r-5" >
                                            <label className="col-form-label col-md-3">Department</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control m-b-5" placeholder={this.state.department} disabled />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-15 m-r-5" >
                                            <label className="col-form-label col-md-3">Email</label>
                                            <div className="col-md-9">
                                                <input type="email" className="form-control m-b-5" placeholder={this.state.email} disabled />
                                            </div>
                                        </div>
                                        <div className="form-group row m-b-15 m-r-5" >
                                            <label className="col-form-label col-md-3">Contact</label>
                                            <div className="col-md-9">
                                                <input type="text" className="form-control m-b-5" placeholder={this.state.contact} disabled />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <img style={{ marginLeft: 10 }} align="middle" className="predetails" src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + this.state.pic)} />

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-cookies" className="modal fade" tabindex="-1" data-backdrop='static' data-keyboard="false">
                    <div className="modal-dialog modal-md modal-dialog-centered ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">This website uses cookies</h4>
                                <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9">
                                    <span className="fa fa-times-circle" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>This site uses cookies to provide you with great user experience</p>
                                <button onClick={() => { localStorage.setItem("cookies", 0) }} data-dismiss="modal" className="btttn bg-green-transparent-9">Accept</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default home2;
