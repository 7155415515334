import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery'
import moment from 'moment'
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import PAGE from'./employeeDetails'

// import console = require('console');
class GovernmentDuesDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            governmenGroup: [],
            branch_name: '',
            period: '',
            governmentDetails: [],
            type: 'SSS',
            gov_dues: [],
            pag_ibig_loan:[],
            sss_loan:[]
        }

    }
    componentDidMount() {
        // return <Redirect to='/governmentDues' />
        // console.log(this.props.location.data)
        if (this.props.location.data == undefined) {
            window.location.replace("http://localhost:3000/#/governmentDues")

        } else {
            this.fetchGovernmentDuesDetails()
            this.setState({
                governmenGroup: this.props.location.data,
                branch_name: this.props.location.data.branch_name,
                period: moment(this.props.location.data.period).format('MMMM YYYY'),

            })
        }

    }
    fetchGovernmentDuesDetails = () => {

        getData("humanResource/fetchGovenmentDuesDetails", { id: this.props.location.data.government_dues_group_id })
            .then(response => {
                console.log(response)
                const govDues = response.response
                let gov_dues = []
                 let pag_ibig_loan = []
                 let sss_loan=[]
               

                for (let index = 0; index < govDues.length; index++) {
                    let gov_data = JSON.parse(govDues[index].government_dues)
                    let details = {
                        name: `${govDues[index].user_lname} ${govDues[index].user_fname}`,
                        mname: `${govDues[index].user_mname}`,
                        sss: '',
                        tota_monthly_earning: parseFloat(govDues[index].total_monthly_earnings).toFixed(2),
                        sss_contri: parseFloat(gov_data.SSS_CONTRIBUTION).toFixed(2),
                        sssEc: parseFloat(gov_data.SSS_EC).toFixed(2),
                        sssER: parseFloat(gov_data.SSS_ER).toFixed(2),
                        sssEE: parseFloat(gov_data.SSS_EE).toFixed(2),
                        hdf_no: '',
                        pag_ibig_ee: parseFloat(gov_data.PAG_IBIG_ER).toFixed(2),
                        pag_ibig_er: parseFloat(gov_data.PAG_IBIG_EE).toFixed(2),
                        phic_no: '',
                        rate: parseFloat(govDues[index].salary_rate).toFixed(2),
                        philhealth: parseFloat(gov_data.PHILHEALTH).toFixed(2),
                        philhealth_EE: parseFloat(gov_data.PHILHEALTH_EE).toFixed(2),
                        user_bday: govDues[index].user_bday,
                        sss_loan:parseFloat(govDues[index].sss_loan).toFixed(2),
                        pag_ibig_loan:parseFloat(govDues[index].pag_ibig_loan).toFixed(2),

                    }
                    if(parseFloat(govDues[index].pag_ibig_loan) !=0){
                        pag_ibig_loan.push(details)
                    }
                    if(parseFloat(govDues[index].sss_loan) !=0){
                        sss_loan.push(details)
                        // alert('hehehe')
                    }

                    gov_dues.push(details);
                }
                console.log(gov_dues)
                this.setState({ governmentDetails: response.response, gov_dues: gov_dues,pag_ibig_loan:pag_ibig_loan,sss_loan:sss_loan})


            })
    }



    render() {
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }

        return (
            <div id="content " className="content " >
                <div className="panel">
                    <div className="panel-heading">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="navmedia">
                                    <div className="navtitle panel-title">
                                        <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Government Dues</h4>
                                        <nav aria-label="breadcrumb bg-white">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                                <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Payroll Report</a></li>
                                                <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Government Dues</a></li>
                                            </ol>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                            <div className='col-md-6 '>
                                <div  class="pull-right"style={{ paddingLeft: 10 }}>
                                    <h5>Branch Name:{this.state.branch_name}</h5>
                                    <h5>Month:{this.state.period}</h5>
                                   
                                        <div style={{display:'flex',alignItems:'center'}} >
                                            <h5>Type: </h5>  <select className='form-control' onChange={(e) => { this.setState({ type: e.target.value }) }}>
                                                <option>SSS</option>
                                                <option>Philhealth</option>
                                                <option>Pag-ibig</option>
                                                <option>SSS Loan</option>
                                                <option>Pag Ibig Loan</option>


                                            </select>
                                        </div>
                                       


                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <ExcelFile element={<button className="btttn bg-green-transparent-9 text-white m-l-5">
                            <a className="fas fa-cloud-download-alt" style={{ textDecoration: "none", color: "white" }}>&nbsp;Download&nbsp;</a></button>} >
                            <ExcelSheet data={this.state.gov_dues} name="SSS Contribution">
                                <ExcelColumn label="Name of Employee" value="name" />
                                <ExcelColumn label="Middle Name" value="mname" />
                                <ExcelColumn label="SSS No." value="" />
                                <ExcelColumn label="Total" value='tota_monthly_earning' />
                                <ExcelColumn label="SSS Contribution" value='sss_contri' />
                                <ExcelColumn label="EC" value='sssEc' />
                                <ExcelColumn label="SSS ER" value='sssER' />
                                <ExcelColumn label="SSS EE" value='sssEE' />
                            </ExcelSheet>

                            <ExcelSheet data={this.state.gov_dues} name="Philhealth">
                                <ExcelColumn label="Name of Employee" value="name" />
                                <ExcelColumn label="Middle Name" value="mname" />
                                <ExcelColumn label="Birthdate" value="user_bday" />
                                <ExcelColumn label="PHIC No." value="" />
                                <ExcelColumn label="Rate" value="rate" />
                                <ExcelColumn label="ER" value='philhealth' />
                                <ExcelColumn label="EE" value='philhealth_EE' />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.gov_dues} name="Pag-Ibig">
                                <ExcelColumn label="Name of Employee" value="name" />
                                <ExcelColumn label="Middle Name" value="mname" />
                                <ExcelColumn label="HDMF No." value="" />
                                <ExcelColumn label="ER" value='pag_ibig_ee' />
                                <ExcelColumn label="EE" value='pag_ibig_er' />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.pag_ibig_loan} name="Pag-Ibig Loan">
                                <ExcelColumn label="Name of Employee" value="name" />
                                <ExcelColumn label="Middle Name" value="mname" />
                                <ExcelColumn label="HDMF No." value="" />
                                <ExcelColumn label="Pag Ibig Loan" value='pag_ibig_loan' />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.sss_loan} name="SSS Loan">
                                <ExcelColumn label="Name of Employee" value="name" />
                                <ExcelColumn label="Middle Name" value="mname" />
                                <ExcelColumn label="SSS No." value="" />
                                <ExcelColumn label="SSS Loan" value='sss_loan' />
                            </ExcelSheet>

                        </ExcelFile>
                        {/* {this.state.period != ''?
                            <button className="btttn bg-green-transparent-9 text-white m-l-5">
                            <a className="fas fa-cloud-download-alt" href={"http://localhost/beta_api/humanResource/excelGivDues/" + localStorage.getItem("u") + "/" + this.props.location.data.government_dues_group_id} style={{ textDecoration: "none", color: "white" }}>&nbsp;Download&nbsp;</a>
                        </button>
                        :undefined

                            } */}

                    </div>
                    <div className='penel-body' style={{ paddingRight: 5, paddingLeft: 5 }}>

                        <br />
                        <div className="row" >
                            <div className='col-md-12'>
                                <Scrollbars style={{ height: 300 }}>
                                    <table className="table table-bordered  table-td-valign-middle" >
                                        {this.state.type == 'SSS' &&
                                            <>
                                                <thead class='tableFixHead'>
                                                    <tr className="text-center">
                                                        <th>Employee ID</th>
                                                        <th>Name of Employee</th>
                                                        <th>Middle Name</th>
                                                        <th>SSS No.</th>
                                                        <th>Total</th>
                                                        <th>SSS Contribution</th>
                                                        <th>EC</th>
                                                        <th>ER</th>
                                                        <th>EE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.governmentDetails.map((val, index) => {
                                                        let gov_data = JSON.parse(val.government_dues)
                                                        if (JSON.parse(val.government_dues).length != 0) {
                                                            return <tr className="text-center" key={index}>
                                                                <td>{val.user_id}</td>
                                                                <td>{`${val.user_lname} ${val.user_fname}`}</td>
                                                                <td>{`${val.user_mname}`}</td>
                                                                <td></td>
                                                                <td>{parseFloat(val.total_monthly_earnings).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.SSS_CONTRIBUTION).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.SSS_EC).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.SSS_ER).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.SSS_EE).toFixed(2)}</td>
                                                            </tr>
                                                        }


                                                    })

                                                    }

                                                </tbody>
                                            </>
                                        }
                                        {this.state.type == 'Pag-ibig' &&
                                            <>
                                                <thead class='tableFixHead'>
                                                    <tr className="text-center">
                                                        <th>Employee ID</th>
                                                        <th>Name of Employee</th>
                                                        <th>Middle Name</th>
                                                        <th>HDMF No.</th>
                                                        <th>ER</th>
                                                        <th>EE</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.governmentDetails.map((val, index) => {
                                                        let gov_data = JSON.parse(val.government_dues)
                                                        if (JSON.parse(val.government_dues).length != 0) {
                                                            return <tr className="text-center" key={index}>
                                                                <td>{val.user_id}</td>
                                                                <td>{`${val.user_lname} ${val.user_fname}`}</td>
                                                                <td>{`${val.user_mname}`}</td>
                                                                <td></td>
                                                                <td>{parseFloat(gov_data.PAG_IBIG_ER).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.PAG_IBIG_EE).toFixed(2)}</td>

                                                            </tr>
                                                        }


                                                    })

                                                    }

                                                </tbody>
                                            </>
                                        }
                                        {this.state.type == 'Philhealth' &&
                                            <>
                                                <thead class='tableFixHead'>
                                                    <tr className="text-center">
                                                        <th>Employee ID</th>
                                                        <th>Name of Employee</th>
                                                        <th>Middle Name</th>
                                                        <th>Birthdate</th>
                                                        <th>PHIC NO.</th>
                                                        <th>Rate</th>
                                                        <th>ER</th>
                                                        <th>EE</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.governmentDetails.map((val, index) => {
                                                        console.log(val)
                                                        let gov_data = JSON.parse(val.government_dues)
                                                        if (JSON.parse(val.government_dues).length != 0) {
                                                            return <tr className="text-center" key={index}>
                                                                <td>{val.user_id}</td>
                                                                <td>{`${val.user_lname} ${val.user_fname}`}</td>
                                                                <td>{`${val.user_mname}`}</td>
                                                                <td>{val.user_bday}</td>
                                                                <td></td>
                                                                <td>{parseFloat(val.salary_rate).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.PHILHEALTH).toFixed(2)}</td>
                                                                <td>{parseFloat(gov_data.PHILHEALTH_EE).toFixed(2)}</td>

                                                            </tr>
                                                        }


                                                    })

                                                    }

                                                </tbody>
                                            </>
                                        }
                                        {this.state.type == 'SSS Loan' &&
                                            <>
                                                <thead class='tableFixHead'>
                                                    <tr className="text-center">
                                                        <th>Employee ID</th>
                                                        <th>Name of Employee</th>
                                                        <th>Middle Name</th>
                                                        <th>SSS No.</th>
                                                        <th>SSS Loan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.governmentDetails.map((val, index) => {
                                                        console.log(val)
                                                        let gov_data = JSON.parse(val.government_dues)
                                                        if (parseFloat(val.sss_loan) != 0 ) {
                                                            return <tr className="text-center" key={index}>
                                                                <td>{val.user_id}</td>
                                                                <td>{`${val.user_lname} ${val.user_fname}`}</td>
                                                                <td>{`${val.user_mname}`}</td>
                                                                <td></td>
                                                                <td>{parseFloat(val.sss_loan).toFixed(2)}</td>
                                                            </tr>
                                                        }


                                                    })

                                                    }

                                                </tbody>
                                            </>
                                        }
                                           {this.state.type == 'Pag Ibig Loan' &&
                                            <>
                                                <thead class='tableFixHead'>
                                                    <tr className="text-center">
                                                        <th>Employee ID</th>
                                                        <th>Name of Employee</th>
                                                        <th>Middle Name</th>
                                                        <th>HDMF No.</th>
                                                        <th>Pag Ibig Loan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.governmentDetails.map((val, index) => {
                                                        console.log(val)
                                                        let gov_data = JSON.parse(val.government_dues)
                                                        if (parseFloat(val.pag_ibig_loan) != 0 ) {
                                                            return <tr className="text-center" key={index}>
                                                                <td>{val.user_id}</td>
                                                                <td>{`${val.user_lname} ${val.user_fname}`}</td>
                                                                <td>{`${val.user_mname}`}</td>
                                                                <td></td>
                                                                <td>{parseFloat(val.pag_ibig_loan).toFixed(2)}</td>
                                                            </tr>
                                                        }


                                                    })

                                                    }

                                                </tbody>
                                            </>
                                        }




                                    </table>
                                </Scrollbars>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default GovernmentDuesDetails;