import React, { Component } from 'react';
import moment from 'moment'
import { connect } from 'react-redux'
class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hearder_employee_share: 0,
            header: 0,
            hearder2: 0,
            header_holding_tax: 0,
            header_data: {
                header: [],
                header2: [],
            },
            header_data_deduction: {
                header: [],
                header2: [],
            },
            header_data_employee_share: {
                header: [],
                header2: [],
            },
            header_data_holding_tax: {
                header: [],
                header2: [],
            },
            // header_data2:[]

        }
    }
    earnings_header = (type) => {
        // console.log(this.props.ListEmployee)
        let header = [];
        let header2 = []
        let employee_list = this.props.ListEmployee
        let basic_hours_additional_data = employee_list.find((value) => (value.basic_hours_additional != 0) && (value.basic_hours_additional != ""))
        let leave = employee_list.find((value) => (value.leave_w_pay_days != 0) && (value.leave_w_pay_days != ""))
        let overtime = employee_list.find((value) => (value.totalRegularOT != 0) && (value.totalRegularOT    != ""))
        let nightdif = employee_list.find((value) => (value.total_night_defferential_hours != 0) && (value.total_night_defferential_hours != ""))
        let rest_day = employee_list.find((value) => (value.sunday != 0) && (value.sunday != ""))
        let rest_day_ot = employee_list.find((value) => (value.sunday_ot != 0) && (value.sunday_ot != ""))
        let specialHoliday = employee_list.find((value) => (value.specialHoliday != 0) && (value.specialHoliday != ""))
        let totalSpecial_Hol_OT = employee_list.find((value) => (value.totalSpecial_Hol_OT != 0) && (value.totalSpecial_Hol_OT != ""))
        let regularHoliday = employee_list.find((value) => (value.regularHoliday != 0) && (value.regularHoliday != ""))
        let regularHoliday_amount = employee_list.find((value) => (value.totalRegualar_Hol_OT != 0) && (value.totalRegualar_Hol_OT != ""))
        let allowance_amount = employee_list.find((value) => (value.allowance_amount != 0) && (value.allowance_amount != ""))
        // let regularHoliday_amount = employee_list.find((value)=>(value.regularHoliday_amount != 0) && (value.regularHoliday_amount!=""))
        // this.setState({ hearder: 0 })

        if (basic_hours_additional_data != undefined) {
            header.push(this.header_push('ADDITIONAL BASIC ADJUSTMENT', "1", "2"))
            header2.push(this.header_push2("DAYS", "AMOUNT"))

        }
        if (leave != undefined) {
            header.push(this.header_push('LEAVE W/ PAY', "1", "2"))
            header2.push(this.header_push2("DAYS", "AMOUNT"))


        }
        if (overtime != undefined) {
            header.push(this.header_push('OVERTIME', "1", "2"))
            header2.push(this.header_push2("HRS", "AMOUNT"))

        }
        if (nightdif != undefined) {
            header.push(this.header_push('NIGHT DEFFERENTIAL', "1", "2"))
            header2.push(this.header_push2("HRS", "AMOUNT"))

        }
        if (rest_day != undefined) {
            header.push(this.header_push('REST DAY', "1", "2"))
            header2.push(this.header_push2("DAYS", "AMOUNT"))

        }
        if (rest_day_ot != undefined) {
            header.push(this.header_push('REST DAY OVERTIME', "1", "2"))
            header2.push(this.header_push2("HRS", "AMOUNT"))

        }
        if (specialHoliday != undefined) {
            header.push(this.header_push('SPECIAL HOLIDAY', "1", "2"))
            header2.push(this.header_push2("DAYS", "AMOUNT"))

        }
        if (totalSpecial_Hol_OT != undefined) {
            header.push(this.header_push('SPECIAL HOLIDAY W/ OVERTIME PER HR', "1", "2"))
            header2.push(this.header_push2("HRS", "AMOUNT"))

        }
        if (regularHoliday != undefined) {
            header.push(this.header_push('REGULAR HOLIDAY', "1", "2"))
            header2.push(this.header_push2("DAYS", "AMOUNT"))

        }
        if (regularHoliday_amount != undefined) {
            header.push(this.header_push('REGULAR HOLIDAY W/ OVERTIME PER HR', "1", "2"))
            header2.push(this.header_push2("HRS", "AMOUNT"))

        }
        // if (allowance_amount != undefined) {
        //     header.push(this.header_push('ALLOWANCE', "1", "1"))
        //     header2.push(this.header_push3("AMOUNT"))
        // }
        let all = {
            header: header,
            header2: header2,
        }
        return all
        // this.setState({ header_data: all })

    }
    earning_allowance = ()=>{
        let employee_list = this.props.ListEmployee
        let header = [];
        let header2 = []
        let allowance_amount = employee_list.find((value) => (value.allowance_amount != 0) && (value.allowance_amount != ""))
        if (allowance_amount != undefined) {
            header.push(this.header_push('ALLOWANCE', "2", "1"))
            // header2.push(this.header_push3("AMOUNT"))
        }
        let all = {
            header: header,
            header2: header2,
        }
        return all
    }
    deduction_header = () => {
        let header = [];
        let header2 = []
        let employee_list = this.props.ListEmployee
        let lateHours = employee_list.find((value) => (value.lateHours != 0) && (value.lateHours != ""))
        let basic_hours_deduction = employee_list.find((value) => (value.basic_hours_deduction != 0) && (value.basic_hours_deduction != ""))



        if (lateHours != undefined) {
            header.push(this.header_push('LATE', "1", "2"))
            header2.push(this.header_push2("HRS", "AMOUNT"))

        }
        if (basic_hours_deduction != undefined) {
            header.push(this.header_push('DEDUCTION BASIC ADJUSTMENT', "1", "2"))
            header2.push(this.header_push2("DAYS", "AMOUNT"))

        }

        let all = {
            header: header,
            header2: header2,
        }
        return all
        // this.setState({ header_data_deduction: all })

    }
    employee_share = () => {
        let header = [];
        let header2 = []
        let employee_list = this.props.ListEmployee
        let SSS_EE = employee_list.find((value) => (value.SSS_EE != 0) && (value.SSS_EE != ""))


        // this.setState({ hearder_employee_share: 0 })

        if (SSS_EE != undefined) {
            header.push(this.header_push('EMPLOYEE SHARE', "1", "3"))
            header2.push(this.header_push4("SSS", "PHILHEALTH", "PAG-IBIG"))

        }
        let all = {
            header: header,
            header2: header2,
        }
        // this.setState({ header_data_employee_share: all })
        return all

    }
    holding_tax = () => {
        let header = [];
        let employee_list = this.props.ListEmployee
        let holding_tax = employee_list.find((value) => (value.holding_tax != 0) && (value.holding_tax != ""))
        // this.setState({ header_holding_tax: 0 })



        if (holding_tax != undefined) {
            header.push(this.header_push('HOLDING TAX', "2", "1"))
            // header2.push(this.header_push3("SSS", "PHILHEALTH", "PAG-IBIG"))

        }
        let all = {
            header: header,
        }
        // this.setState({ header_data_holding_tax: all })

        return all

    }
    // componentDidMount() {


    //     const header_data = this.earnings_header()
    //     const header_data_deduction = this.deduction_header()
    //     const header_data_employee_share = this.employee_share()
    //     const header_data_holding_tax = this.holding_tax()
    // }
    header_push(name, rowSpan, colSpan) {
        let basic_amount_additional = {
            rowSpan: rowSpan,
            colSpan: colSpan,
            name: name,
        }
        return basic_amount_additional
        // header.push(basic_amount_additional)
    }
    header_push2(days, amount) {
        let data = {
            days: days,
            amount: amount,
            colSpan: "1",
        }
        return data
        // header.push(basic_amount_additional)
    }
    header_push3(amount) {
        let data = {
            amount: amount,
            colSpan: "1",
        }
        return data
        // header.push(basic_amount_additional)
    }
    header_push4(name, name2, name3) {
        let basic_amount_additional = {
            name: name,
            name2: name2,
            name3: name3,
            colSpan: "1",
        }
        return basic_amount_additional
        // header.push(basic_amount_additional)
    }
    formatNumber(num) {
        if (num != "") {
            let num2 = parseFloat(num).toFixed(2);
            return num2.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        } else {
            return undefined
        }
    }
    render() {
        // const data = this.headerDetails();
        const header_data = this.earnings_header()
        const header_data_deduction = this.deduction_header()
        const header_data_employee_share = this.employee_share()
        const header_data_holding_tax = this.holding_tax()
        const header_allowance = this.earning_allowance()
        console.log(this.props.header_data);

        // if (this.props.orientation == 'Landscape') {
        //     console.log('heheheh')
        //     var css2 = this.props.css,
        //         head2 = document.head || document.getElementsByTagName('head')[0],
        //         style2 = document.createElement('style');

        //     style2.type = 'text/css';
        //     style2.media = 'print';

        //     if (style2.styleSheet) {
        //         style2.styleSheet.cssText = css2;
        //     } else {
        //         style2.appendChild(document.createTextNode(css2));
        //     }
        //     head2.appendChild(style2)
        // }
        // ;


        let new_date = ''

        new_date = moment(new Date(new Date(this.props.second).getTime() + 5 * 24 * 60 * 60 * 1000)).format('MMM-DD-YYYY')
        let month = moment(new Date()).format('MMM')
        if (String(moment(new Date(this.props.second)).format('DD')) == "25") {
            new_date = moment(new Date(this.props.second)).endOf('month').format('MMM-DD-YYYY');
        }


        return (
            <>
                <div className='m-t-30 m-l-20 m-r-15'>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} id="heads">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <p id ='fontSize' style={{fontSize:17}}>FIRMNAME - {String(this.props.company).toUpperCase()}</p>
                                <p id ='fontSize' style={{fontSize:17}}>DEPARTMENT - {String(this.props.branch).toUpperCase()}</p>
                            </div>
                        </div>
                        <div>
                            <div>
                                {/* <p style={{fontSize:17}}>WORK PERIOD: {(moment(this.props.first).format("MMMM DD")).toUpperCase() + " TO " + (moment(this.props.second).format('MMMM DD,YYYY')).toUpperCase()}</p> */}
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        <table className="table table-td-valign-middle table-bordered "  id="mytable">
                            <thead className="text-center">
                                {/* ------------UPPER START------- */}
                                <tr>
                                    <th rowSpan="3" >NAME OF EMPLOYEE</th>
                                    <th rowSpan="1" colSpan={4 + header_data.header.reduce((count, val) => {
                                        return count += parseInt(val.colSpan)
                                    }, 0) + this.props.earning.length + header_allowance.header.reduce((count, val) => {
                                        return count += parseInt(val.colSpan)
                                    }, 0)} className="success">EARNINGS</th>
                                    <th rowSpan="1" colSpan={1 + header_data_deduction.header.reduce((count, val) => {
                                        return count += parseInt(val.colSpan)
                                    }, 0) + header_data_holding_tax.header.reduce((count, val) => {
                                        return count += parseInt(val.colSpan)
                                    }, 0) + header_data_employee_share.header.reduce((count, val) => {
                                        return count += parseInt(val.colSpan)
                                    }, 0) + this.props.deduction.length} className="warning">Deduction</th>
                                    <th rowSpan="3" colSpan="1">NET AMOUT PAID</th>
                                    <th rowSpan="3">SIGNATURE OF EMPLOYEE</th>

                                </tr>
                                {/* ------------UPPER END------- */}

                                {/* ---------MIDDLE START---------- */}
                                <tr>
                                    <th colSpan="3">REGULAR</th>
                                    {header_data.header.map((val, index) => {
                                        return <th colSpan={val.colSpan} rowSpan={val.rowSpan}>{val.name}</th>
                                    })

                                    }
                                     {header_allowance.header.map((val, index) => {
                                        return <th colSpan={val.colSpan} rowSpan={val.rowSpan}>{val.name}</th>
                                    })

                                    }
                                    
                                    {this.props.earning.map((val_earn, index) =>
                                        <th key={index} rowSpan="2">{val_earn.earning_name}</th>
                                    )}
                                    {/* <th colSpan="2">ADDITIONAL BASIC ADJUSTMENT</th>
                                    <th colSpan="2">LEAVE W/ PAY</th>
                                    <th colSpan="2">OVERTIME</th> */}
                                    {/* <th colSpan="2">NIGHT DEFFERENTIAL</th>
                                    <th colSpan="2">REST DAY</th>
                                    <th colSpan="2">REST DAY OVERTIME</th> */}
                                    {/* <th colSpan="2">SPECIAL HOLIDAY</th>
                                    <th colSpan="2">SPEACIAL HOLIDAY W/ OVERTIME PER HR</th>
                                    <th colSpan="2">REGULAR HOLIDAY</th>
                                    <th colSpan="2">REGULAR HOLIDAY W/ OVERTIME PER HR</th> */}

                                    {/* <th rowSpan="1">ALLOWANCE</th> */}
                                    <th rowSpan="2">TOTAL EARNINGS</th>
                                    {header_data_deduction.header.map((val, index) => {
                                        return <th colSpan={val.colSpan} rowSpan={val.rowSpan}>{val.name}</th>
                                    })
                                    }
                                    {/* <th rowSpan="1" colSpan="2">LATE</th>
                                    <th rowSpan="1" colSpan="2">DEDUCTION BASIC ADJUSTMENT</th> */}
                                    {header_data_employee_share.header.map((val, index) => {
                                        return <th colSpan={val.colSpan} rowSpan={val.rowSpan}>{val.name}</th>
                                    })
                                    }
                                    {this.props.deduction.map((val_deduct) =>
                                        <th rowSpan="2">{val_deduct.deduction_name}</th>
                                    )}
                                    {/* <th rowSpan="1" colSpan="3">EMPLOYEES SHARE</th> */}
                                    <th rowSpan="2" colSpan="1">TOTAL DEDUCTIONS</th>

                                    {header_data_holding_tax.header.map((val, index) => {
                                        return <th colSpan={val.colSpan} rowSpan={val.rowSpan}>{val.name}</th>
                                    })
                                    }
                                    {/* <th rowSpan="2" colSpan="1">HOLDING TAX</th> */}
                                    {/* <th rowSpan="2" colSpan="1">TOTAL DEDUCTION</th> */}
                                </tr>
                                {/* ---------MIDDLE END---------- */}

                                {/* ---------LOW START---------- */}
                                <tr>
                                    {/* REGULAR */}
                                    <th colSpan="1">RATE</th>
                                    <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th>

                                    {/* ADDITIONAL BASIC ADJUSTMENT */}
                                    {/* <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th> */}
                                    {header_data.header2.map((val2, index) => {
                                        return <>
                                            <th colSpan="1">{val2.days}</th>
                                            <th colSpan="1">{val2.amount}</th>
                                        </>
                                    })}

                                    {/* LEAVE W/ PAY */}
                                    {/* <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* OVERTIME */}
                                    {/* <th colSpan="1">HRS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* NIGHT DEFFERENTIAL */}
                                    {/* <th colSpan="1">HRS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* REST DAY */}
                                    {/* <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* REST DAY OVERTIME */}
                                    {/* <th colSpan="1">HRS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* SPECIAL HOLIDAY */}
                                    {/* <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* SPEACIAL HOLIDAY W/ OVERTIME PER HR */}
                                    {/* <th colSpan="1">HRS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* REGULAR HOLIDAY */}
                                    {/* <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* REGULAR HOLIDAY W/ OVERTIME PER HR */}
                                    {/* <th colSpan="1">HRS</th>
                                    <th colSpan="1">AMOUNT</th> */}


                                    {/* ALLOWANCE */}
                                    {/* <th colSpan="1">AMOUNT</th> */}
                                    {header_data_deduction.header2.map((val2, index) => {
                                        return <>
                                            <th colSpan="1">{val2.days}</th>
                                            <th colSpan="1">{val2.amount}</th>
                                        </>
                                    })}
                                    {/* LATE */}
                                    {/* <th colSpan="1">HRS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* DEDUCTION BASIC ADJUSTMENT */}
                                    {/* <th colSpan="1">DAYS</th>
                                    <th colSpan="1">AMOUNT</th> */}

                                    {/* EMPLOYEES SHARE */}
                                    {header_data_employee_share.header2.map((val2, index) => {
                                        return <>
                                            <th colSpan="1">{val2.name}</th>
                                            <th colSpan="1">{val2.name2}</th>
                                            <th colSpan="1">{val2.name3}</th>

                                        </>
                                    })}


                                    {/* <th colSpan="1">SSS</th>
                                    <th colSpan="1">PHILHEALTH</th>
                                    <th colSpan="1">PAG-IBIG</th> */}



                                </tr>
                                {/* ---------LOW START---------- */}

                            </thead>
                            <tbody>
                                {this.props.ListEmployee.map((val, index) => {
                                    console.log(header_data.header)
                                    return <tr>
                                        {/* NAME OF EMPLOYEE */}
                                        <td  >{val.emp_name != "" ? val.emp_name.toUpperCase() : undefined}</td>

                                        {/* REGULAR DATA*/}
                                        <td>{val.emp_monthly}</td>
                                        <td style={{textAlign:'right'}}>{this.formatNumber(val.totalDays)}</td>
                                        <td style={{textAlign:'right'}}>{this.formatNumber(val.amount2)}</td>

                                        {/* ADDITIONAL BASIC ADJUSTMENT*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "ADDITIONAL BASIC ADJUSTMENT"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.basic_hours_additional}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.basic_amount_additional)}</td>
                                            </>
                                            : undefined
                                        }


                                        {/* LEAVE W/ PAY*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "LEAVE W/ PAY"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.leave_w_pay_days}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.leave_w_pay_amount)}</td>
                                            </> : undefined
                                        }

                                        {/* OVERTIME DATA*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "OVERTIME"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.totalRegularOT}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.totalRegularOT_Rate)}</td>
                                            </> : undefined
                                        }

                                        {/* NIGHT DEFFERENTIAL DATA*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "NIGHT DEFFERENTIAL"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.total_night_defferential_hours}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.total_night_defferential_amount)}</td>
                                            </> : undefined
                                        }

                                        {/* REST DAY DATA*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "REST DAY"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{parseFloat(val.sunday / 8).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.sunday_amount)}</td>
                                            </> : undefined
                                        }

                                        {/* REST DAY OVERTIME DATA*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "REST DAY OVERTIME"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.sunday_ot}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.sunday_ot_rate)}</td>
                                            </> : undefined
                                        }

                                        {/* SPECIAL HOLIDAY DATA*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "SPECIAL HOLIDAY"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{parseFloat(val.specialHoliday/8).toFixed(2)}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.specialHoliday_amount)}</td>
                                            </> : undefined
                                        }


                                        {/*SPECIAL HOLIDAY W/ OVERTIME PER HR DATA*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "SPECIAL HOLIDAY W/ OVERTIME PER HR"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.totalSpecial_Hol_OT}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.totalSpecial_Hol_OT_Rate)}</td>
                                            </> : undefined
                                        }


                                        {/*REGULAR HOLIDAY*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "REGULAR HOLIDAY"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.regularHoliday != ""?parseFloat(val.regularHoliday/8).toFixed(2):''}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.regularHoliday_amount)}</td>
                                            </> : undefined
                                        }


                                        {/*REGULAR HOLIDAY W/ OVERTIME PER HR*/}
                                        {(header_data.header.find((val_head) => (val_head.name == "REGULAR HOLIDAY W/ OVERTIME PER HR"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.totalRegualar_Hol_OT}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.totalRegualar_Hol_OT_Rate)}</td>
                                            </> : undefined
                                        }


                                        {/*ALLOWANCE*/}
                                        {(header_allowance.header.find((val_head) => (val_head.name == "ALLOWANCE"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.allowance_amount)}</td>
                                            </> : undefined
                                        }
                                        {this.props.earning.map((valEarn, index1) => {
                                            var value = 0;
                                            {
                                                this.props.responsedata.map((valRes) => {
                                                    if (valRes.user_id == val.emp_id) {
                                                        for (let i = 0; i < valRes.data_schema['earning_deduction']['earning']['earning_name'].length; i++) {

                                                            if (valEarn.earning_name == valRes.data_schema['earning_deduction']['earning']['earning_name'][i]) {
                                                                value = valRes.data_schema['earning_deduction']['earning']['earning_amount'][i];
                                                                if (value == undefined) {
                                                                    value = 0;
                                                                }
                                                            }

                                                        }
                                                    }

                                                })
                                            }

                                            return <td colSpan="1" style={{textAlign:'right'}}>{(this.formatNumber(value))}</td>

                                        }
                                        )}

                                        {/*TOTAL EARNINGS*/}
                                        <td style={{textAlign:'right'}}>{this.formatNumber(val.total_earning)}</td>

                                        {/*LATE*/}
                                        {(header_data_deduction.header.find((val_head) => (val_head.name == "LATE"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.lateHours}</td>
                                                <td  style={{textAlign:'right'}}>{this.formatNumber(val.lateHours_Rate)}</td>
                                            </> : undefined
                                        }

                                        {/*DEDUCTION BASIC ADJUSTMENT*/}
                                        {(header_data_deduction.header.find((val_head) => (val_head.name == "DEDUCTION BASIC ADJUSTMENT"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{val.basic_hours_deduction}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.basic_amount_deduction)}</td>
                                            </> : undefined
                                        }


                                        {/*EMPLOYEE SHARE*/}
                                        {(header_data_employee_share.header.find((val_head) => (val_head.name == "EMPLOYEE SHARE"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.SSS_EE)}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.PHILHEALTH_EE)}</td>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.PAG_IBIG)}</td>
                                            </> : undefined
                                        }
                                        {this.props.deduction.map((val2) => {

                                            var matches = 0;
                                            {
                                                this.props.responsedata.map((val3) => {
                                                    if (val3.user_id === val.emp_id) {
                                                        // val3.deduction.map(element => {
                                                        for (let index = 0; index < val3.data_schema['earning_deduction']['deduction']['deduction_name'].length; index++) {
                                                            if (val3.data_schema['earning_deduction']['deduction']['deduction_name'][index] === val2.deduction_name) {
                                                                matches = val3.data_schema['earning_deduction']['deduction']['deduction_amount'][index];
                                                            }
                                                        }
                                                        // })
                                                    }
                                                }

                                                )
                                            }
                                            // if(matches == 0){
                                            // matches = 0;
                                            return <td colSpan="1" style={{textAlign:'right'}}>{this.formatNumber(matches)}</td>
                                            // }

                                        }
                                        )}


                                        {/*TOTAL DEDUCTION*/}
                                        <td style={{textAlign:'right'}}>{this.formatNumber(val.totalDeduction)}</td>

                                        {/*HOLDING TAX*/}
                                        {(header_data_holding_tax.header.find((val_head) => (val_head.name == "HOLDING TAX"))) != undefined ?
                                            <>
                                                <td style={{textAlign:'right'}}>{this.formatNumber(val.holding_tax)}</td>
                                            </> : undefined
                                        }


                                        {/*NET AMOUNT PAID*/}
                                        <td style={{textAlign:'right'}}>{this.formatNumber(val.total_net_pay)}</td>

                                        {/*SIGNITURE*/}
                                        <td></td>


                                    </tr>
                                })}
                                <tr>
                                    <td><b>TOTAL</b></td>
                                    <td></td>
                                    <td  style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                        if (value.totalDays != "") {
                                            count += parseFloat(value.totalDays)
                                        }
                                        return count
                                    }, 0))}</b></td>
                                    {/* REGULAR DATA*/}
                                    <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                        if (value.amount2 != "") {
                                            count += parseFloat(value.amount2)
                                        }
                                        return count
                                    }, 0))}</b></td>

                                    {/* ADDITIONAL BASIC ADJUSTMENT*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "ADDITIONAL BASIC ADJUSTMENT"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.basic_hours_additional != "") {
                                                    count += parseFloat(value.basic_hours_additional)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.basic_amount_additional != "") {
                                                    count += parseFloat(value.basic_amount_additional)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }
                                    {/* LEAVE W/ PAY*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "LEAVE W/ PAY"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.leave_w_pay_days != "") {
                                                    count += parseFloat(value.leave_w_pay_days)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.leave_w_pay_amount != "") {
                                                    count += parseFloat(value.leave_w_pay_amount)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }
                                    {/* OVERTIME DATA*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "OVERTIME"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.totalRegularOT != "") {
                                                    count += parseFloat(value.totalRegularOT)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.totalRegularOT_Rate != "") {
                                                    count += parseFloat(value.totalRegularOT_Rate)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }
                                    {/* NIGHT DEFFERENTIAL DATA*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "NIGHT DEFFERENTIAL"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.total_night_defferential_hours != "") {
                                                    count += parseFloat(value.total_night_defferential_hours)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.total_night_defferential_amount != "") {
                                                    count += parseFloat(value.total_night_defferential_amount)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/* REST DAY DATA*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "REST DAY"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.sunday != "") {
                                                    count += parseFloat(value.sunday / 8)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.sunday_amount != "") {
                                                    count += parseFloat(value.sunday_amount)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/* REST DAY OVERTIME DATA*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "REST DAY OVERTIME"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.sunday_ot != "") {
                                                    count += parseFloat(value.sunday_ot)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.sunday_ot_rate != "") {
                                                    count += parseFloat(value.sunday_ot_rate)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/* SPECIAL HOLIDAY DATA*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "SPECIAL HOLIDAY"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.specialHoliday != "") {
                                                    count += parseFloat(value.specialHoliday/8).toFixed(2)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.specialHoliday_amount != "") {
                                                    count += parseFloat(value.specialHoliday_amount)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/*SPECIAL HOLIDAY W/ OVERTIME PER HR DATA*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "SPECIAL HOLIDAY W/ OVERTIME PER HR"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.totalSpecial_Hol_OT != "") {
                                                    count += parseFloat(value.totalSpecial_Hol_OT)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.totalSpecial_Hol_OT_Rate != "") {
                                                    count += parseFloat(value.totalSpecial_Hol_OT_Rate)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/*REGULAR HOLIDAY*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "REGULAR HOLIDAY"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                               
                                                if (value.regularHoliday != "") {
                                                    count += parseFloat((value.regularHoliday/8))
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.regularHoliday_amount != "") {
                                                    count += parseFloat(value.regularHoliday_amount)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/*REGULAR HOLIDAY W/ OVERTIME PER HR*/}
                                    {(header_data.header.find((val_head) => (val_head.name == "REGULAR HOLIDAY W/ OVERTIME PER HR"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.totalRegualar_Hol_OT != "") {
                                                    count += parseFloat(value.totalRegualar_Hol_OT)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.totalRegualar_Hol_OT_Rate != "") {
                                                    count += parseFloat(value.totalRegualar_Hol_OT_Rate)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/*ALLOWANCE*/}
                                    {(header_allowance.header.find((val_head) => (val_head.name == "ALLOWANCE"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.allowance_amount != "") {
                                                    count += parseFloat(value.allowance_amount)
                                                }
                                                return count
                                            }, 0))}</b></td>

                                        </> : undefined
                                    }

                                    {this.props.earning.map((valEarn, index1) => {
                                        var value = 0;
                                        {
                                            this.props.responsedata.map((valRes) => {
                                                for (let i = 0; i < valRes.data_schema['earning_deduction']['earning']['earning_name'].length; i++) {

                                                    if (valEarn.earning_name == valRes.data_schema['earning_deduction']['earning']['earning_name'][i]) {
                                                        let value_num =  parseFloat(valRes.data_schema['earning_deduction']['earning']['earning_amount'][i]);
                                                        if (value_num != undefined && value_num != "") {
                                                            value += parseFloat(valRes.data_schema['earning_deduction']['earning']['earning_amount'][i]);
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                        return <td colSpan="1" style={{textAlign:'right'}}><b>{(this.formatNumber(value))}</b></td>

                                    }
                                    )}
                                    <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                        if (value.total_earning != "") {
                                            count += parseFloat(value.total_earning)
                                        }
                                        return count
                                    }, 0))}</b></td>


                                    {/*LATE*/}
                                    {(header_data_deduction.header.find((val_head) => (val_head.name == "LATE"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.lateHours != "") {
                                                    count += parseFloat(value.lateHours)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.lateHours_Rate != "") {
                                                    count += parseFloat(value.lateHours_Rate)
                                                }
                                                return count
                                            }, 0))}</b></td>

                                        </> : undefined
                                    }

                                    {/*DEDUCTION BASIC ADJUSTMENT*/}
                                    {(header_data_deduction.header.find((val_head) => (val_head.name == "DEDUCTION BASIC ADJUSTMENT"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.basic_hours_deduction != "") {
                                                    count += parseFloat(value.basic_hours_deduction)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.basic_hours_deduction != "") {
                                                    count += parseFloat(value.basic_amount_deduction)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }

                                    {/*EMPLOYEE SHARE*/}
                                    {(header_data_employee_share.header.find((val_head) => (val_head.name == "EMPLOYEE SHARE"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.SSS_EE != "") {
                                                    count += parseFloat(value.SSS_EE)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.PHILHEALTH_EE != "") {
                                                    count += parseFloat(value.PHILHEALTH_EE)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.PAG_IBIG != "") {
                                                    count += parseFloat(value.PAG_IBIG)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined
                                    }
                                    {this.props.deduction.map((val2) => {

                                        var matches = 0;
                                        {
                                            this.props.responsedata.map((val3) => {
                                                // val3.deduction.map(element => {
                                                for (let index = 0; index < val3.data_schema['earning_deduction']['deduction']['deduction_name'].length; index++) {
                                                    if (val3.data_schema['earning_deduction']['deduction']['deduction_name'][index] === val2.deduction_name) {
                                                        let value_num = parseFloat(val3.data_schema['earning_deduction']['deduction']['deduction_amount'][index]);
                                                        if (value_num != undefined && value_num != "") {
                                                            matches += parseFloat(val3.data_schema['earning_deduction']['deduction']['deduction_amount'][index])    ;
                                                        }

                                                    }
                                                }
                                                // })

                                            }

                                            )
                                        }
                                        // if(matches == 0){
                                        // matches = 0;
                                        return <td colSpan="1" style={{textAlign:'right'}}><b>{this.formatNumber(matches)}</b></td>
                                        // }

                                    }
                                    )}
                                    {/*TOTAL DEDUCTION*/}
                                    <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                        if (value.totalDeduction != "") {
                                            count += parseFloat(value.totalDeduction)
                                        }
                                        return count
                                    }, 0))}</b></td>


                                    {/*HOLDING TAX*/}
                                    {(header_data_holding_tax.header.find((val_head) => (val_head.name == "HOLDING TAX"))) != undefined ?
                                        <>
                                            <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                                if (value.holding_tax != "") {
                                                    count += parseFloat(value.holding_tax)
                                                }
                                                return count
                                            }, 0))}</b></td>
                                        </> : undefined

                                        //     (value.holding_tax != "") ? count += parseFloat(value.holding_tax) : undefined, 0))}</b></td>
                                        // </> : undefined)
                                    }
                                    {/*NET AMOUNT PAID*/}   
                                    <td style={{textAlign:'right'}}><b>{this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                        if (value.total_net_pay != "") {
                                            count += parseFloat(value.total_net_pay)
                                        }
                                        return count
                                    }, 0))}</b></td>

                                    {/*SIGNITURE*/}
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <br/>
<br/>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingRight: 40, paddingLeft: 40, justifyContent: 'space-between',marginTop:20 }}>
                        {this.props.payroll_hierarchy.map((val, index) => {

                            return <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {val.approver.map((val2, index) => {
                                    return <p  style={{fontSize:17}} key={index}><b>{String(val2.name).toUpperCase()}</b></p>
                                })

                                }

                                <p style={{ marginTop: -10,fontSize:17 }}><b>{val.title}</b></p>
                            </div>

                        })

                        }

                        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p>Louie C. Cruz</p>
                            <p style={{ marginTop: -10 }}>Checked By</p>
                        </div> */}


                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{fontSize:17}}><b>{localStorage.getItem('fullName')}</b></p>
                            <p style={{ marginTop: -10,fontSize:17 }}><b>Prepared By</b></p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p  style={{fontSize:17}}><b>{new_date}</b></p>
                            <p style={{ marginTop: -10,fontSize:17}}><b>(Date of Payment)</b></p>
                        </div>
                        <div >
                            <p  style={{fontSize:17}}><b>Net Pay per Check Issuance =     {this.formatNumber(this.props.ListEmployee.reduce((count, value) => {
                                if (value.total_net_pay != "") {
                                    count += parseFloat(value.total_net_pay)
                                }
                                return count
                            }, 0))}     </b></p>

                        </div>



                    </div>

                </div>
            </>
        );
    }
}

export default ComponentToPrint;
