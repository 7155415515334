import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import Header from '../Header_Page/index';
import Cards from '../Cards/index';

class SamplePage2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }
    componentDidMount(){
        // alert("2 success!")
    }
    render() {
        // alert("2 success")
        return (
            <div id="content" className="content">
              <Header/>
              <p>page 2</p>
            </div>
        );
    }
}
export default SamplePage2;