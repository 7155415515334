import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { connect } from 'react-redux'
// import PAGE from'./employeeDetails'

// import console = require('console');
class GeneralSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {

            newHierarchy: '',
            name_hierarchy: [],
            ADDLevel: [],
            indexVal: 0, type_details: [],
            companies: [],
            branches: [],
            branch_selected: "",
            company_selected: "",
            hierarchy_structures: [],
            selecthierarchy: "",
            selectedHierarchy: [],
            select_type: '',
            request_type: [{
                type: 'Payroll Approver'
            }]



        }
        this.textchange = this.textchange.bind(this)
        this.showBYcompanies = this.showBYcompanies.bind(this)
        this.onChangeSelectHierarchy = this.onChangeSelectHierarchy.bind(this)

    }
    requestType_Data() {
        getData("users/requestType_Data")
            .then(response => {

                console.log(response);
            })
    }
    componentDidMount() {
        this.requestType_Data();
        this.selectHierarchyStructuresPayroll()
    }
    selectHierarchyStructuresPayroll() {
        getData('humanResource/selectHierarchyStructuresPayroll', { user_id: localStorage.getItem('u') })
            .then(response => {
                this.setState({ hierarchy_structures: response.hierarchy_structures })
                this.props.dispatch({
                    type: 'Branches',
                    data: response.branches
                })
                console.log(response)
            })
    }
    AddLevel() {
        this.state.count++
        var title = ""
        var data = { title, approver: [], types: ["Payroll Approver"] }
        this.state.name_hierarchy.push(data)
        // this.setState({name_hierarchy:{}})
        // alert("name_hierarchy"+this.state.count);
        this.state.ADDLevel.push(<div><br /><div className="solid" style={{ padding: 10 }}><div className="row"><div className="col-sm-12" ><label>Set Level Title*</label></div></div><br /><br /><div className="flex-container"><input onChange={this.textchange} type="text" name="name_hierarchy" className="form-control" /><i className="fa fa-2x fa-trash-alt m-l-10" onClick={() => {

        }} style={{ color: "green", marginTop: "5px" }}></i></div><div className="row m-l-13"><button className=" bttnADDApprover pull-right"><span className="fa fa-plus fa-sm"></span></button></div><br /><div className="row"><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">OT</label></div><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">OB</label></div><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">LV</label></div><div className="col-md-3"><input type="checkbox" /><label className="m-l-15">UT</label></div></div></div></div>)
        this.setState({ refresh: true })

    }
    textchange = e => {
        if (e.target.name == "title") {

            this.state.name_hierarchy[this.state.indexVal].title = e.target.value
            this.setState({ refresh: true })
            setTimeout(() => {
                var match = false
                for (let index = 0; index < this.state.name_hierarchy.length; index++) {
                    var title = this.state.name_hierarchy[index].title
                    if (title == "") {
                        match = true
                    } if (this.state.name_hierarchy[index].approver.length == 0) {
                        match = true
                    } if (this.state.hierarchy_name == "") {
                        match = true
                    }
                }
                if (match == true) {


                } else {
                    this.setState({ modal: "modal", modalPath: "#demo-lg-modal-Review" }); this.review()
                }
            }, 100)
        } else {
            this.setState({ [e.target.name]: e.target.value });


        }
    };
    review() {

        this.state.type_details = []

        // let review = this.state.name_hierarchy.map((val)=>(val.types.filter((fil_val)=> (fil_val == this.state.type_details.map()))))
        //    let review = this.state.name_hierarchy.map((val)=>(val.types)).map((val)=>(val))
        //    let cloud_id = review.map((val)=>())
        // console.log(review);

        for (let i = 0; i < this.state.name_hierarchy.length; i++) {
            for (let k = 0; k < this.state.name_hierarchy[i].types.length; k++) {
                var match = false
                for (let l = 0; l < this.state.type_details.length; l++) {
                    if (this.state.name_hierarchy[i].types[k] == this.state.type_details[l].type) {
                        match = true
                    }

                }

                if (match == false) {
                    var type = []
                    type = { type: this.state.name_hierarchy[i].types[k] }
                    this.state.type_details.push(type);
                }

            }
        }

    }

    mapLevel() {
        var data = []
        for (let index = 0; index < this.state.ADDLevel.length; index++) {
            data.push(<div>
                <hr />
                <div >
                    <div className="form-group">
                        <label>Set Level Title</label>
                        <input onChange={this.textchange} onClick={() => { this.setState({ indexVal: index }) }} value={this.state.name_hierarchy[index].title} type="text" name="title" className="form-control" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }} >
                        {/* {this.state.req_type.map((val, index3) => {
                            return <div key={index3}    >
                                <div >
                                    <input name={val.req_type_acName + index} type="checkbox" onChange={() => {
                                        var match = false
                                        for (let k = 0; k < this.state.name_hierarchy[index].types.length; k++) {
                                            if (this.state.name_hierarchy[index].types[k] == val.req_type_details) {
                                                this.state.name_hierarchy[index].types.splice(parseInt(k), 1)
                                                match = true
                                                this.setState({ refresh: true })
                                            }
                                        }
                                        if (match == false) {
                                            this.state.name_hierarchy[index].types.push(val.req_type_details)
                                            this.setState({ refresh: true })
                                        }
                                    }} />
                                    <label className="m-l-5">{val.req_type_acName}</label>
                                </div>
                            </div>
                        })} */}
                    </div>
                    {this.state.name_hierarchy.map((val, index1) => {
                        var data_details = []
                        for (let index3 = 0; index3 < val.approver.length; index3++) {
                            var name = { name: val.approver[index3].name }
                            data_details.push(name);
                        }
                        if (index == index1)
                            return <div key={index1}>
                                {data_details.map((val2, index2) => {
                                    return <div >
                                        <div key={index2} className="flex-container m-t-10 m-l-10">
                                            <input type="text" className="form-control" value={val2.name} readOnly />
                                            <button onClick={() => {
                                                this.state.name_hierarchy[index].approver.splice(parseInt(index2), 1)
                                                this.setState({ refresh: true })
                                            }} className="btttn bg-red-transparent-9 m-l-5 fa fa-trash-alt">&nbsp;Remove&nbsp;Approver</button>

                                        </div>
                                    </div>
                                })}
                            </div>
                    })
                    }
                    <button className=" btttn fas fa-user-check bg-green-transparent-9 m-t-5" data-dismiss="modal" data-target="#demo-lg-modal-ADDApprover" data-toggle="modal" onClick={() => {
                        this.setState({ indexVal: index, name_hierarchy1: [] })
                    }}>&nbsp;Add Approver</button>

                </div>

            </div>);
        }

        return data
    }
    SaveHierarchy = (data, type) => {
        console.log(data)
      
        if (data.length != 0 && this.state.company_selected != '' && this.state.branch_selected != '') {
            let structure = []
            if(type =='Update'){
                structure = data[0].structure
            }else{
                structure = data
            }
            this.props.dispatch({
                type: 'UpdateStructure',
                data: structure,
                branch_id: this.state.branch_selected
            })
            var details = []

            if (type == "Update") {
                for (let index = 0; index < data.length; index++) {
                    var data = { id: data[index].id, structure: JSON.stringify(data[index].structure), company_id: this.state.company_selected, branch_id: this.state.branch_selected }
                    details.push(data)
                }
            } else {
                var data = { company_id: this.state.company_selected, branch_id: this.state.branch_selected, id: null, admin_id: localStorage.getItem('u'), structure: data, name: this.state.hierarchy_name }
                details.push(data)
            }
            console.log(details);
            getData("humanResource/updatePayroll", { hierarchy: details, method: type })
                .then(response => {
                    console.log(response)
                    alert('Success')
                    this.selectHierarchyStructuresPayroll()
                    this.setState({
                        company_selected: '',
                        branch_selected: '',
                        selecthierarchy: '',
                        select_type: [],
                        selectedHierarchy: [],
                        newHierarchy:''
                    })
                    // document.getElementById('select_company').value = ""
                    // document.getElementById('branch_selected').value = ""
                    // document.getElementById('structure').value = ""

                })
        }else{
            alert('Complete the details')
        }


        // getData("humanResource/updateUserHierarchy", { hierarchy: details, method: type })
        //     .then(response => {

        //         // if(response == "Success"){
        //         this.setState({ Hierarchy: false, select_type: [], newHierarchy: false, SelectHierarchy: true, selectedHierarchy: [] })

        //         this.getUser();
        //         setTimeout(() => {

        //             for (let index = 0; index < this.state.userData.length; index++) {


        //                 if (this.state.u_id == this.state.userData[index].user_id) {

        //                     this.setState({ user_request_hierarchy: JSON.parse(this.state.userData[index].user_request_hierarchy) })
        //                 }

        //             }

        //         }, 500)
        //         // }

        //     })
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, })
        setTimeout(() => { this.getBranch() }, 200)
    }
    getBranch() {
        console.log(this.state.company_selected)
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {
                this.setState({ branches: response.data, branch_selected: "select branch" })
            })
    }
    showBYbranch = e => {
        e.preventDefault();
        this.setState({ branch_selected: e.target.value })
        let details = []
        // for (let index = 0; index < this.state.employee.length; index++) {
        //     if (this.state.employee[index].branch_id == e.target.value) {
        //         details.push(this.state.employee[index])
        //     }

        // }
        this.setState({ employee_filter: details })
    }
    onChangeSelectHierarchy(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                value.push(options[i].value);
                var data = []

                if (options[i].value != "") {
                    // for (let index = 0; index < this.state.hierarchy_structures.length; index++) {
                    data.push(this.state.hierarchy_structures[options[i].value])

                    // }
                    var data_ty = []
                    for (let k = 0; k < this.state.hierarchy_structures[options[i].value].structure.length; k++) {

                        data_ty.push(this.state.hierarchy_structures[options[i].value].structure[k])

                    }
                    this.setState({ selecthierarchy: options[i].value, selectedHierarchy: data, select_type: data_ty })
                } else {
                    this.setState({ select_type: [] })
                }

            }
        }
    }

    render() {
        console.log(this.state.name_hierarchy);
        return (

            <div className="row">
                <div className="col-md-4">
                    <div className="panel-body solid">
                        <h5 style={{ color: "#bdc3c7" }}><b>Payroll Approver</b></h5>
                        <hr />
                        {/* <form> */}
                            <>
                                <div>
                                    <label>Select Company*</label>
                                    <select className="form-control" id='select_company' value={this.state.company_selected} onChange={this.showBYcompanies} required>
                                        <option ></option>
                                        {this.props.companies.map((val, index) =>
                                            <option value={val.company_id}>{val.company_name}</option>
                                        )}
                                    </select>
                                    <br />
                                    <label>Select Branch*</label>
                                    <select className="form-control" id='branch_selected' value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                        <option>--select branch--</option>
                                        <option></option>
                                        {this.state.branches.map((branch, index) =>
                                            <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                        )}
                                    </select>
                                    <br />

                                </div>

                                {this.state.newHierarchy == '' &&
                                    <>
                                        <label>Select Hierarchy Structure*</label>
                                        <select className="form-control" value={this.state.selecthierarchy} id='structure' onChange={this.onChangeSelectHierarchy}>
                                            <option></option>
                                            {this.state.hierarchy_structures.map((val, index) => {
                                                return <option value={index}>{val.name}</option>
                                            })
                                            }
                                        </select>
                                        <br />
                                        {this.state.selectedHierarchy != 0 &&
                                            this.state.request_type.map((val_type, indx) => {
                                                return <div>
                                                    <div className="row">
                                                        <div className="col-sm-12  w3-animate-left">
                                                            <p style={{ fontSize: "15px", color: "#353b48" }}><b>{val_type.type}</b></p>
                                                        </div>
                                                    </div>
                                                    <div className="row m-l-10">
                                                        <div className="col-sm-12  w3-animate-left">
                                                            <p className='m-l-20'>
                                                                <div>
                                                                    {this.state.select_type.map((val1, index1) => {
                                                                        var approver = []
                                                                        var title = ""
                                                                        var JSON_type = JSON.parse(val1.types)
                                                                        var level = ""
                                                                        for (let k = 0; k < JSON_type.length; k++) {
                                                                            if (JSON_type[k] == val_type.type) {
                                                                                approver = val1.approver
                                                                                title = val1.title
                                                                                level = index1 + 1
                                                                            }
                                                                        }
                                                                        return <div><span style={{ fontSize: "14px", color: "rgb(15, 158, 62)", marginTop: '10' }}>
                                                                            <span style={{ fontSize: "12px", color: 'black' }}>{level}</span> <b>{title}</b>
                                                                        </span>
                                                                            {approver.map((val2, index2) => {
                                                                                return <div> <br />
                                                                                    <span className='m-l-40' style={{ fontSize: "12px", color: "#353b48", marginTop: '10' }}>
                                                                                        <i className='fa fa-user-alt'></i> <b>{val2.name.toUpperCase()}</b>
                                                                                    </span>
                                                                                </div>
                                                                            })}
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        <br />
                                        <div className='pull-right'>
                                            <button type='button' className="btttn fa fa-plus bg-green-transparent-9 m-l-5 " onClick={() => {
                                                this.setState({ newHierarchy: 'New' })
                                            }}>&nbsp;New Hierarchy
                                    </button>
                                            <button type='submit' className="btttn fa fa-save bg-blue-transparent-9 m-l-5 pull-right" onClick={() => {
                                                this.SaveHierarchy(this.state.selectedHierarchy, "Update")
                                            }}>&nbsp;Save Hierarchy
                                </button>
                                        </div></>
                                }
                            </>
                        {/* </form> */}

                        {this.state.newHierarchy == 'New' &&
                            <div>
                                <label>Set Name of Hierarchy*</label><br />

                                <div className="row">
                                    <div className="col-sm-12  w3-animate-right">
                                        <input onChange={this.textchange} type="text" name="hierarchy_name" className="form-control" />
                                    </div>
                                </div>
                                <br />
                                {
                                    this.mapLevel()
                                }
                                <br />
                                <div className="pull-right m-t-10">
                                    <button className="btttn fa fa-plus bg-green-transparent-9 m-l-5" onClick={() => { this.AddLevel() }}>
                                        &nbsp;New Level
                                                                            </button>
                                    <button className="btttn bg-blue-transparent-9 fas fa-clipboard-check m-l-5" data-dismiss={this.state.modal} data-target={this.state.modalPath} data-toggle="modal" onClick={() => {
                                        var match = false;
                                        for (let index = 0; index < this.state.name_hierarchy.length; index++) {
                                            var title = this.state.name_hierarchy[index].title
                                            if (title == "") {
                                                match = true
                                            }
                                            if (this.state.name_hierarchy[index].approver.length == 0) {
                                                match = true
                                            }
                                        }
                                        if (match == true) {
                                            alert('Complete the details')

                                        } else {
                                            this.setState({ review: true }); this.review()
                                        }
                                    }}>&nbsp;Save Hierarchy</button>
                                    <button className="btttn fa fa-trash-alt bg-red-transparent-9 m-l-5" onClick={() => {
                                        this.state.ADDLevel.splice(parseInt(this.state.ADDLevel.length - 1), 1); this.state.name_hierarchy.splice(parseInt(this.state.name_hierarchy.length - 1), 1)
                                        this.setState({ refresh: true, indexVal: 0 })
                                    }}>&nbsp;Remove Level
                                                                            </button>
                                    <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none  m-l-5" onClick={() => {
                                        this.setState({ newHierarchy: false, SelectHierarchy: true, selectedHierarchy: [],selecthierarchy:'' })
                                    }}>&nbsp;Cancel
                                                                            </button>
                                </div>
                            </div>

                        }
                    </div>


                </div>
                <div className='col-md-8'>
                    <Scrollbars style={{ height: 500 }}>
                        <table class="table table-bordered ">
                            <thead className="tableFixHead ">
                                <tr>
                                    <th>Branch ID</th>
                                    <th>Company</th>
                                    <th>Branch</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.props.paroll_branch_list.map((val) =>
                                    <tr>
                                        <td>{val.branch_id}</td>
                                        <td>{val.company_name}</td>
                                        <td>{val.branch_name}</td>
                                        <td><center> <button className="btttn fas fa-eye bg-blue-transparent-9" data-target='#demo-lg-modal-Payroll' data-toggle='modal' onClick={() => {
                                            let data = []
                                            if (val.payroll_hierarchy == "") {
                                                data = []
                                            } else {
                                                data = JSON.parse(val.payroll_hierarchy)
                                            }

                                            this.props.dispatch({
                                                type: 'Branch_Hierarchy',
                                                data: data
                                            })

                                        }}>View
                                </button></center></td>

                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </Scrollbars>


                </div>
                <div id="demo-lg-modal-ADDApprover" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5><b>Select Approver</b></h5>
                                <button type="button" className="close" data-dismiss="modal"></button>
                            </div>

                            <div className="modal-body" style={{ fontSize: 12 }}>
                                <Scrollbars style={{ height: 400 }}>
                                    <table className="table table-td-valign-middle">
                                        <thead className="tableFixHead">
                                            <tr>
                                                <th>

                                                </th>
                                                <th>
                                                    Name
                                            </th>
                                                <th>
                                                    Company
                                            </th>
                                                <th>
                                                    Branch
                                            </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.props.ListEmployee.map((val, index) => {
                                                var match1 = false
                                                if (this.state.name_hierarchy.length != 0) {
                                                    for (let index = 0; index < this.state.name_hierarchy[this.state.indexVal].approver.length; index++) {
                                                        if (val.user_id == this.state.name_hierarchy[this.state.indexVal].approver[index].id) {

                                                            match1 = true;
                                                        }

                                                    }
                                                }
                                                if (match1 == false)
                                                    return <tr>
                                                        <td>
                                                            <input type="checkbox" className="checkbox" onClick={() => {
                                                                var match = false
                                                                for (let index = 0; index < this.state.name_hierarchy1.length; index++) {
                                                                    var title = this.state.name_hierarchy1[index].title
                                                                    if (title == "") {
                                                                        match = true
                                                                    } if (this.state.name_hierarchy1.length == 0) {
                                                                        match = true
                                                                    } if (this.state.hierarchy_name == "") {
                                                                        match = true
                                                                    }


                                                                }
                                                                if (match == true) {


                                                                } else {

                                                                    this.setState({ modal: "modal", modalPath: "#demo-lg-modal-Review" }); this.review()
                                                                }



                                                                this.setState({ check_status: !this.state.check_status })
                                                                var match = false;
                                                                for (let index = 0; index < this.state.name_hierarchy1.length; index++) {
                                                                    if (val.user_id == this.state.name_hierarchy1[index].id) {
                                                                        this.state.name_hierarchy1.splice(parseInt(index), 1);
                                                                        match = true;
                                                                    }

                                                                }
                                                                if (match == false) {
                                                                    var data = []
                                                                    data = { id: val.user_id, cloud_id: val.user_device_id, name: val.completename }
                                                                    this.state.name_hierarchy1.push(data)
                                                                }
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <p>{val.completename.toUpperCase()}</p>
                                                        </td>
                                                        <td>
                                                            <p>{val.company_name}</p>
                                                        </td>
                                                        <td>
                                                            <p>{val.branch_name}</p>
                                                        </td>

                                                    </tr>
                                            })}

                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn fas fa-user-check  bg-green-transparent-9 pull-right" data-dismiss="modal" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal" onClick={() => {
                                    for (let index = 0; index < this.state.name_hierarchy1.length; index++) {
                                        this.state.name_hierarchy[this.state.indexVal].approver.push(this.state.name_hierarchy1[index]);
                                        this.setState({ refresh: true })
                                    }
                                }}>Select Approver
                                </button>
                                <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" data-dismiss="modal" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal">
                                    &nbsp;Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-Review" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5><b>Hierarchy Review</b></h5>
                                <button type="button" className="close" data-dismiss="modal"></button>
                            </div>
                            {/* <div className="modal-header">
                                <div className="modal-title" id="exampleModalCenterTitle">Delete Record</div>
                            </div> */}
                            <div className="modal-body" style={{ fontSize: 12 }}>
                                <Scrollbars style={{ height: 400 }}>
                                    {this.state.type_details.map((val, index) => {

                                        return <div className="solid" key={index} style={{ padding: 10 }}>
                                            <div className="row">
                                                <div className="col-sm-12  w3-animate-left">
                                                    <p style={{ fontSize: "15px", color: "#353b48" }}><b>{val.type}</b></p>
                                                </div>
                                            </div>
                                            <div className="row m-l-10">
                                                <div className="col-sm-12  w3-animate-left">
                                                    <p className='m-l-20'>
                                                        {
                                                            this.state.name_hierarchy.map((val1, index1) => {

                                                                var approver = []
                                                                var title = ""
                                                                var JSON_type = (val1.types)
                                                                var level = 0
                                                                for (let k = 0; k < JSON_type.length; k++) {
                                                                    if (val.type == JSON_type[k]) {
                                                                        approver = val1.approver
                                                                        title = val1.title
                                                                        level = index1 + 1
                                                                    }

                                                                }
                                                                return <div>
                                                                    <div><span style={{ fontSize: "14px", color: "rgb(15, 158, 62)", marginTop: '10' }}>
                                                                        <span style={{ fontSize: "12px", color: 'black' }}></span> <b>{title}</b>
                                                                    </span>
                                                                        {approver.map((val2, index2) => {


                                                                            return <div> <br />
                                                                                <span className='m-l-40' style={{ fontSize: "12px", color: "#353b48", marginTop: '10' }}>
                                                                                    <i className='fa fa-user-alt'></i> <b>{val2.name.toUpperCase()}</b>
                                                                                </span>
                                                                            </div>

                                                                        })

                                                                        }
                                                                    </div>
                                                                </div>

                                                            })}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </Scrollbars>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn pull-right bg-green-transparent-9 fa fa-save" data-dismiss="modal" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal" onClick={() => {
                                    this.SaveHierarchy(this.state.name_hierarchy, "Insert")
                                }}>
                                    &nbsp;Save&nbsp;Hierarchy
                                </button>
                                <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal">
                                    &nbsp;Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal-Payroll" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5><b>Payroll Hierarchy</b></h5>
                                <button type="button" className="close" data-dismiss="modal"></button>
                            </div>
                            {/* <div className="modal-header">
                                <div className="modal-title" id="exampleModalCenterTitle">Delete Record</div>
                            </div> */}
                            <div className="modal-body" style={{ fontSize: 12 }}>
                                {this.state.request_type.map((val, index) => {
                                    console.log(val)
                                    return <div>
                                        <label style={{ fontSize: 13 }}>{val.type}</label>


                                        {this.props.branchHierarchy.map((val2) => {
                                            console.log(val2)
                                            return <div style={{ marginLeft: 10 }}>
                                                <label>{val2.title} :</label>
                                                {val2.approver.map((val3) => {
                                                    return <div style={{ marginLeft: 10 }} >

                                                        <label>{val3.name}</label>
                                                        <br />
                                                    </div>
                                                })}

                                            </div>
                                        })

                                        }
                                    </div>
                                })

                                }

                            </div>
                            <div className="modal-footer">

                                {/* <button className="btttn fas fa-times-circle pull-right border border-secondary border-solid text-black-lighter bg-none" aria-label="Close" data-dismiss="modal" data-target="#demo-lg-modal5" data-toggle="modal">
                                    &nbsp;Cancel
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        ListEmployee: state.Payroll.late_employees,
        companies: state.Payroll.companies,
        paroll_branch_list: state.Payroll.payroll_branches,
        branchHierarchy: state.Payroll.branchHierarchy


    }
}
export default connect(mapStateToProps)(GeneralSettings);