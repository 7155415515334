import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import Header from '../Header_Page/index';
import Cards from '../Cards/index';

class SamplePage2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           nav1: []
        };
    }
    componentDidMount(){
        // alert("2 success!")
        let data = []
        var data1 = {navi1: "sp2 Nav 1", link: "#/samplepage/", bread: "navigation1" ,title: "Title Sample Page"}
        var data2 = {navi1: "sp2 Nav 2", link: "#/samplepage2/", bread: "navigation2", title: "Title Sample Page2"}
        data.push(data1)
        data.push(data2)
        this.setState({nav1: data})
        //  console.log(data)
    }
    render() {
       

        // alert("2 success")
        return (
            <div id="content" className="content">
              <Header data = {this.state.nav1}/>
              <p>page 2</p>
            </div>
        );
    }
}
export default SamplePage2;