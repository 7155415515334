import React, { Component } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";
import Header from "./Header_Page/index";
import SamplePage from "./SamplePage/index";
import SamplePage2 from "./SamplePage2/index";
import Navigation from "../../../components/template/navigation";
import Dashboard from "./masterlist/index";
import Cards from "./Cards/index"
class Routes extends Component {
    state = {}
    render() {
        return (
            <Router>
                <div>
                    {/* <Navigation/> */}
                    <Route path="/assetmanagement/dashboard" component={Dashboard} />
                    <Route path="/assetmanagement/samplepage" component={SamplePage} />
                    <Route path="/assetmanagement/samplepage2" component={SamplePage2} />

                </div>
            </Router>
        );
    }
}
export default Routes;