import React, { Component } from 'react';
import moment from 'moment'
class ComponentToPrint extends Component {

    _payslip = () => {
        let paySlip2 = [];
        let gov_dues = []
        let holding_tax = [];
        let adjustment_additional = [];
        let adjustment_deduction = [];

        for (let index = 0; index < this.props.data.length; index++) {
            if (this.props.data[index].holding_tax != '') {
                let hold_tax = {
                    user_id: this.props.data[index].emp_id,
                    holding_tax: this.props.data[index].holding_tax
                }
                holding_tax.push(hold_tax)
            }

            let gov_dues_data = {
                user_id: this.props.data[index].emp_id,
                SSS_EE: this.props.data[index].SSS_EE,
                PHILHEALTH_EE: this.props.data[index].PHILHEALTH_EE,
                PAG_IBIG: this.props.data[index].PAG_IBIG
            }
            gov_dues.push(gov_dues_data)

            let adjustment_data_deduction = {
                user_id: this.props.data[index].emp_id,
                amount: this.props.data[index].basic_amount_deduction
                // amount : this.props.data[index].basic_amount_deduction,
                // days : this.props.data[index].basic_hours_deduction
            }
            adjustment_deduction.push(adjustment_data_deduction)

            let paySlip = []
            paySlip = {
                user_id: this.props.data[index].emp_id,
                emp_name: this.props.data[index].emp_name,
                branch: this.props.data[index].branch_name,
                total_earning: this.props.data[index].total_earning,
                allowance_amount: this.props.data[index].allowance_amount,
                company_name: this.props.data[index].company_name,
                totalDeduction: this.props.data[index].totalDeduction,
                total_net_pay: this.props.data[index].total_net_pay,
                double_book_amount: this.props.data[index].double_book_amount,
                double_book: this.props.data[index].double_book,
                jo_number: this.props.data[index].jo_number,
                jo_total_amount: this.props.data[index].jo_total_amount,
                details: []
            }


            let Basic = []
            Basic = {
                type: 'Basic',
                rate: this.props.data[index].emp_salary_rate,
                days: this.props.data[index].totalDays,
                amount: this.props.data[index].amount2
            }
            paySlip['details'].push(Basic);
            let adjustment_data_additional = {
                type: 'Basic Adjustment',
                rate: '',
                amount: this.props.data[index].basic_amount_additional,
                days: this.props.data[index].basic_hours_additional

            }
            paySlip['details'].push(adjustment_data_additional);
            let LeaveWpay = []
            LeaveWpay = {
                type: 'Leave w/ Pay',
                rate: '',
                days: this.props.data[index].leave_w_pay_days,
                amount: this.props.data[index].leave_w_pay_amount
            }
            paySlip['details'].push(LeaveWpay);

            let Special_Holiday = []
            Special_Holiday = {
                type: 'Special Holiday',
                rate: '',
                days: this.props.data[index].specialHoliday,
                amount: this.props.data[index].specialHoliday_amount
            }
            paySlip['details'].push(Special_Holiday);

            let Regular_Holiday = []
            Regular_Holiday = {
                type: 'Regular Holiday',
                rate: '',
                days: this.props.data[index].regularHoliday,
                amount: this.props.data[index].regularHoliday_amount
            }
            paySlip['details'].push(Regular_Holiday);

            let Special_Regular_Holiday_OT = []
            let special_ot = 0,
                regular_ot = 0,
                regular_ot_rate = 0,
                special_ot_rate = 0
            if (this.props.data[index].totalRegualar_Hol_OT != '') {
                regular_ot = this.props.data[index].totalRegualar_Hol_OT
                regular_ot_rate = this.props.data[index].totalRegualar_Hol_OT_Rate
            }
            if (this.props.data[index].totalSpecial_Hol_OT != '') {
                special_ot = this.props.data[index].totalSpecial_Hol_OT
                special_ot_rate = this.props.data[index].totalSpecial_Hol_OT_Rate
            }
            Special_Regular_Holiday_OT = {
                type: 'Regular and Special Holiday OT',
                rate: '',
                days: parseFloat(regular_ot) + parseFloat(special_ot),
                amount: parseFloat(regular_ot_rate) + parseFloat(special_ot_rate)
            }
            paySlip['details'].push(Special_Regular_Holiday_OT);

            let Rest_Day = []
            Rest_Day = {
                type: 'Rest Day',
                rate: '',
                days: this.props.data[index].sunday,
                amount: this.props.data[index].sunday_amount
            }
            paySlip['details'].push(Rest_Day);
            if (this.props.data[index].sunday_ot != "") {
                let Rest_Day_OT = []
                Rest_Day_OT = {
                    type: 'Rest Day OT',
                    rate: '',
                    days: this.props.data[index].sunday_ot,
                    amount: this.props.data[index].sunday_ot_rate
                }
                paySlip['details'].push(Rest_Day_OT);
            }


            let Overtime = []
            Overtime = {
                type: 'Overtime',
                rate: '',
                days: this.props.data[index].totalRegularOT,
                amount: this.props.data[index].totalRegularOT_Rate
            }
            paySlip['details'].push(Overtime);
            if (this.props.data[index].total_night_defferential_hours != "") {
                let NightDefferential = []
                NightDefferential = {
                    type: 'Night Deffential',
                    rate: '',
                    days: this.props.data[index].total_night_defferential_hours,
                    amount: this.props.data[index].total_night_defferential_amount
                }
                paySlip['details'].push(NightDefferential);
            }


            paySlip2.push(paySlip);
        }
        // console.log(paySlip2)
        return { gov_dues: gov_dues, paySlip2: paySlip2, holding_tax: holding_tax, adjustment_deduction: adjustment_deduction }
    }
    _deductions = () => {
        let deduction = []
        this.props.responsedata.map((val, index) => {
            let details = []
            details = {
                user_id: val.data_schema.user_id,
                deduction: []
            }
            let match = false
            for (let index = 0; index < val.data_schema.earning_deduction.deduction.deduction_name.length; index++) {
                match = true
                let deduction = {
                    deduction_name: val.data_schema.earning_deduction.deduction.deduction_name[index],
                    deduction_value: val.data_schema.earning_deduction.deduction.deduction_amount[index]
                }
                details.deduction.push(deduction)
            }


            deduction.push(details);
        })
        console.log(this.props.data)
        return deduction
    }
    _earnings = () => {
        let earnings = []
        this.props.responsedata.map((val, index) => {
            let details = []
            details = {
                user_id: val.data_schema.user_id,
                earning: []
            }
            let match = false
            for (let index = 0; index < val.data_schema.earning_deduction.earning.earning_name.length; index++) {
                match = true
                let earning = {
                    earning_name: val.data_schema.earning_deduction.earning.earning_name[index],
                    earning_value: val.data_schema.earning_deduction.earning.earning_amount[index]
                }
                details.earning.push(earning)
            }


            earnings.push(details);
        })
        console.log(earnings)
        return earnings
    }

    render() {
        console.log(this.props.css)
        const deduction = this._deductions()
        const payroll_details = this._payslip()
        const earning = this._earnings()
        // if (this.props.orientation == 'Portrait') {
        //     var css = this.props.css,
        //         head = document.head || document.getElementsByTagName('head')[0],
        //         style = document.createElement('style');

        //     style.type = 'text/css';
        //     style.media = 'print';

        //     if (style.styleSheet) {
        //         style.styleSheet.cssText = css;
        //     } else {
        //         style.appendChild(document.createTextNode(css));
        //     }
        //     head.appendChild(style);
        // }
        let date = (String(this.props.MonthPeriod).split('-'))[1]
        let new_date = ''
        if (date != undefined) {
            new_date = moment(new Date(new Date(date).getTime() + 5 * 24 * 60 * 60 * 1000)).format('MMM-DD-YYYY')
            let month = moment(new Date()).format('MMM')
            if (String(moment(new Date(date)).format('DD')) == "25") {
                new_date = moment(new Date(date)).endOf('month').format('MMM-DD-YYYY');
            }

        }


        return (
            <>
                <div className='p-10 ' style={{ paddingRight: 30, paddingLeft: 30 }}>
                    {payroll_details.paySlip2.map((val, index) => {
                        // console.log(    val);
                        let count_item = index + 1
                        return <> <div style={{ marginTop: 5 }}></div>
                            <div className='border_solid' style={{ fontSize: 11 }}>
                                <div style={{ display: 'flex' }}>
                                    <div className='dotted_style  p-5' style={{ display: 'flex', width: '70%', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 3 }}>
                                                <label>{val.branch}</label>
                                                <label style={{ marginTop: -8 }}>Payslip</label>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'flex-end' }}>
                                                <label>WORK PERIOD </label>
                                                <label style={{ marginTop: -8 }}>DATE OF PAYMENT</label>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'flex-end' }}>
                                                <label><i>{this.props.MonthPeriod}</i></label>
                                                <label style={{ marginTop: -8 }}>{new_date}</label>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label style={{ fontWeight: 'bold' }}><b>{val.emp_name}</b></label>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '60%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ display: 'flex', width: '30%' }}>
                                                        <label>Gross Pay</label>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                        <label>Rate</label>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                        <label>Days</label>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                        <label>Amount</label>
                                                    </div>

                                                </div>
                                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                                    {val.details.map((val1, index2) => {
                                                        let amount = parseFloat(val1.amount).toFixed(2)
                                                        if (val1.amount == "") {
                                                            amount = ""
                                                        }
                                                        let days = val1.days
                                                        if (val1.days == 0) {
                                                            days = ""
                                                        }
                                                        return <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                            <div style={{ display: 'flex', width: '30%' }}>
                                                                <label>{val1.type}</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label>{val1.rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label>{days}</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                                                            </div>
                                                        </div>
                                                    })

                                                    }
                                                    {val.allowance_amount != "" ?
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                            <div style={{ display: 'flex', width: '30%' }}>
                                                                <label>Allowance</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label></label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label></label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>
                                                                    <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(val.allowance_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                                </div>
                                                            </div>
                                                        </div> : undefined
                                                    }
                                                    {earning.map((val_earning, index_earning) => {
                                                        if (val_earning.user_id == val.user_id) {
                                                            return <div key={index_earning}>
                                                                {val_earning.earning.map((earn_data, index_earn) => {
                                                                    return <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                        <div style={{ display: 'flex', width: '30%' }}>
                                                                            <label>{earn_data.earning_name}</label>
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                            <label></label>
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                            <label></label>
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>
                                                                                <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(earn_data.earning_value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })

                                                                }

                                                            </div>
                                                        }

                                                    })}
                                                    {val.double_book != "" ?
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                            <div style={{ display: 'flex', width: '30%' }}>
                                                                <label>Double Book</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label></label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label>{val.double_book}</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>
                                                                    <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(val.double_book_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                                </div>
                                                            </div>
                                                        </div> : undefined

                                                    }
                                                    {val.jo_number != "" ?
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                            <div style={{ display: 'flex', width: '30%' }}>
                                                                <label>Number of JO</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label></label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <label>{val.jo_number}</label>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>
                                                                    <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(val.jo_total_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                                </div>
                                                            </div>
                                                        </div> : undefined

                                                    }



                                                    <br />
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                        <div style={{ display: 'flex', width: '30%' }}>
                                                            <label>Gross Pay</label>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                            <label></label>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                            <label></label>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '20%', justifyContent: 'center' }}>
                                                            <div className='border_solid_bottom ' style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>
                                                                <label style={{ display: 'flex', alignSelf: 'center' }}>{(parseFloat(val.total_earning).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ width: '30%' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ display: 'flex', width: '50%' }}>
                                                        <label>Deduction</label>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                        <label>Amount</label>
                                                    </div>


                                                </div>
                                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                                    {deduction.map((val1, index2) => {
                                                        if (val1.user_id == val.user_id)
                                                            return <div>

                                                                {payroll_details.gov_dues.map((val3, index3) => {
                                                                    if (val1.user_id == val3.user_id)
                                                                        return <><div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                            <div style={{ display: 'flex', width: '50%' }}>
                                                                                <label>SSS</label>
                                                                            </div>
                                                                            <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                                                <label>{val3.SSS_EE}</label>
                                                                            </div>

                                                                        </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                                <div style={{ display: 'flex', width: '50%' }}>
                                                                                    <label>Philhealth</label>
                                                                                </div>
                                                                                <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                                                    <label>{val3.PHILHEALTH_EE}</label>
                                                                                </div>

                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                                <div style={{ display: 'flex', width: '50%' }}>
                                                                                    <label>Pag-ibig</label>
                                                                                </div>
                                                                                <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                                                    <label>{val3.PAG_IBIG}</label>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                })}
                                                                {val1.deduction.map((val3, index3) => {

                                                                    return <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                        <div style={{ display: 'flex', width: '50%' }}>
                                                                            <label>{val3.deduction_name}</label>
                                                                        </div>
                                                                        <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                                            <label>{val3.deduction_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                                                                        </div>

                                                                    </div>
                                                                })}
                                                                {payroll_details.holding_tax.map((val3, index3) => {
                                                                    if (val1.user_id == val3.user_id)
                                                                        return <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                            <div style={{ display: 'flex', width: '50%' }}>
                                                                                <label>Holdin Tax</label>
                                                                            </div>
                                                                            <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                                                <label>{val3.holding_tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                                                                            </div>

                                                                        </div>
                                                                })}
                                                                {payroll_details.adjustment_deduction.map((val3, index3) => {
                                                                    if (val1.user_id == val3.user_id)
                                                                        return <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7 }}>
                                                                            <div style={{ display: 'flex', width: '50%' }}>
                                                                                <label>Basic Adjustment</label>
                                                                            </div>
                                                                            <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                                                                <label>{val3.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                                                                            </div>

                                                                        </div>
                                                                })}
                                                                <br />
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7, marginLeft: 10 }}>
                                                                    <div style={{ display: 'flex', width: '60%' }}>
                                                                        <label>Total Deduction</label>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '40%', justifyContent: 'center' }}>
                                                                        <div className='border_solid_bottom ' style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>
                                                                            <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(val.totalDeduction).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7, marginLeft: 10 }}>
                                                                    <div style={{ display: 'flex', width: '60%', justifyContent: 'center' }}>
                                                                        <label><b>NET PAY</b></label>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '40%', justifyContent: 'center' }}>
                                                                        <div className='border_solid_bottom_blank ' style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%', backgroundColor: 'yellow', width: '80%' }}>
                                                                            <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(val.total_net_pay).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                    })

                                                    }

                                                </div>
                                            </div>


                                        </div>





                                    </div>
                                    <div className=' p-5' style={{ display: 'flex', flexDirection: 'column', width: '30%', marginLeft: 15 }}>
                                        <div>
                                            <label>{val.emp_name}</label>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'justify' }}>
                                            <label>ACKNOWLEDGEMENT RECEIPT</label>
                                        </div>
                                        <div>
                                            <label >Received from <b><i>{val.company_name}</i></b> the amount mentioned below as full payment of salary/wages for the period.</label>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ display: 'flex', width: '60%' }}>
                                                <label><b>TAKE HOME PAY ===></b></label>
                                            </div>
                                            <div style={{ display: 'flex', width: '40%', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%', backgroundColor: 'yellow' }}>
                                                    <label style={{ display: 'flex', alignSelf: 'center' }}>{parseFloat(val.total_net_pay).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>

                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7, marginLeft: 10 }}>
                                            <div style={{ display: 'flex', width: '60%' }}>
                                                <label>Date :</label>
                                            </div>
                                            <div style={{ display: 'flex', width: '40%', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%', }}>
                                                    <label style={{ display: 'flex', alignSelf: 'center' }}>{moment(new Date()).format('MMM-DD-YYYY')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -7, marginLeft: 10 }}>
                                            <div style={{ display: 'flex', width: '60%' }}>
                                                <label>Received by :</label>
                                            </div>
                                            <div style={{ display: 'flex', width: '40%', justifyContent: 'center' }}>
                                                <div className='border_solid_bottom_blank ' style={{ display: 'flex', justifyContent: 'center', height: 10, paddingTop: 10, paddingBottom: 3, width: '100%' }}>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {(count_item % 4 == 0) ? <> <div style={{ breakAfter: "page" }}>

                            </div></> : <div></div>

                            }

                        </>

                    })}
                </div>
            </>
        );
    }
}
export default ComponentToPrint;
