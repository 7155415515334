import React, { Component } from 'react';
import {    
    HashRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";

import Customer from './customer/home'

class Routes extends Component {
    state = {  }
    render() { 
        return ( 
          <Router>
              <div>
                <Route path="/always/customer" component={Customer}/>
              </div>
          </Router>
         );
    }
}
 
export default Routes;