import React, { Component } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect
} from "react-router-dom";
import Dashboard from "./dashboard/index";
import Meter from "./dashboard/index2";
// import Meter from "./meter/index";
class RoutesWater extends Component {
    state = {}
    render() {
        return (
            <Router>
                <div>
                    <Route path="/waters/dashboard" component={Dashboard} />
                    <Route path="/waters/meter" component={Meter}/>
                </div>
            </Router>
        );
    }
}
export default RoutesWater;