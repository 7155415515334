import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import  { Redirect } from 'react-router-dom'
import { getData } from '../../api/api';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {

            filterStartDate: new Date(),
            filterEndDate: new Date(),
            filterStartDate2: new Date(),
            filterEndDate2: new Date(),
            showToTable: 'All',
            selectedBranch: "All",
            selectedEmployeeName: "",
            employeedata: [],
            employeeTable: [],
            empTableFront: [],
            dtrData: [],
            dtrToday: [],
            dtrFull: [],
            dtrDated: [],
            dtrTable: [],
            countPresent: [],
            countLate: [],
            countAbsent: [],
            branches: [],
            userData: [],
            fieldman_name: [],
            fieldma_assigned: [],
            data_accom: [],
            data_assigned: [],
            data_pending: [],
            countassigned: 0,
            countaccom: 0,
            countpending: 0,
            trigerfrom: 0,
            trigerto: 0,
            position: "",
            field_name: "",
            branch:"",
            app_id:"",
            redirect:false
        }
        this.onChangeBranch = this.onChangeBranch.bind(this);
    }

    _isMounted = true

    filterDateStart = e => {

        var from = new Date(e);
        var data = from.toISOString();
        var dataFrom = data.split("T")
        this.setState({
            filterStartDate: e, trigerfrom: 1
        });
        setTimeout(() => { this.filter_date() }, 100)

    }
    filterDateEnd = e => {
        var to = new Date(e);
        var data = to.toISOString();
        var datato = data.split("T")
        this.setState({
            filterEndDate: e, trigerto: 1
        });
        setTimeout(() => { this.filter_date() }, 100)
    }
    filterDateStart2 = e => {

        var from = new Date(e);
        var data = from.toISOString();
        var dataFrom = data.split("T")
        this.setState({
            filterStartDate2: e
        });
        setTimeout(() => { this.filter_accom(this.state.branch); }, 100)

    }
    filterDateEnd2 = e => {
        var to = new Date(e);
        var data = to.toISOString();
        var datato = data.split("T")
        console.log( data )
        this.setState({
            filterEndDate2: e
        });
        setTimeout(() => { this.filter_accom(this.state.branch); }, 100)
       
    }
    filter_date() {

        if (this.state.trigerfrom != 0 && this.state.trigerto != 0) {

            var date = new Date(this.state.filterStartDate);
            // var newdate = date.setDate(date.getDate() + 1);
            // var from = new Date(newdate);
            var data = date.toISOString();
            var dataFrom = data.split("T")

            var date2 = new Date(this.state.filterEndDate);
            // var newdate2 = date2.setDate(date2.getDate() + 1);
            // var to = new Date(newdate2);
            var data3 = date2.toISOString();
            var datato = data3.split("T")

            // console.log(dataFrom[0])
            // if(from !="" && to !=""){

           

            // }
            var data_Details_ass = [];
            var data_Details_acc = [];

            for (let i = 0; i < this.state.userData[this.state.position].assigned.length; i++) {
                var dateF = String(this.state.userData[this.state.position].assigned[i].date_filter).split(" ")

                if (dateF[0] >= dataFrom[0] && dateF[0] <= datato[0]) {

                    data_Details_ass.push(this.state.userData[this.state.position].assigned[i])


                }

            }
            for (let i = 0; i < this.state.userData[this.state.position].accomplished.length; i++) {
                var dateF = String(this.state.userData[this.state.position].accomplished[i].date_filter).split(" ")

                if (dateF[0] >= dataFrom[0] && dateF[0] <= datato[0]) {

                    data_Details_acc.push(this.state.userData[this.state.position].accomplished[i])


                }

            }


            this.setState({ data_assigned: data_Details_ass, countassigned: data_Details_ass.length })
            this.setState({ data_accom: data_Details_acc, countaccom: data_Details_acc.length })
        }


    }
    onChangeBranch(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                this.setState({branch:options[i].value})
                this.filter_accom(options[i].value);
            }
        }
        
      

    }
    filter_accom=(branchdata)=>{
        var date = new Date(this.state.filterStartDate2);
     
        var data = date.toISOString();
        var dataFrom = data.split("T")

        var date2 = new Date(this.state.filterEndDate2);
      
        var data3 = date2.toISOString();
        var datato = data3.split("T")
       var position_array = [];

         getData("users/get_accomplished_branch",{branches:branchdata,from:date,to:date2}).then(response => {
        var details = []
        console.log(response)

      
         this.setState({ fieldman_name: response.accomplishments     });
        })
     
       
    }
    branch(app_id) {
        var branch_array ={}
        branch_array = [app_id];
        localStorage.setItem('app_id',branch_array)
        getData("field_audit/getMISbranch",{branches:branch_array}).then(response => {
        
            this.setState({ branches: response.elf })
        })
    }
    user() {
        getData("users/getMISuser").then(response => {
           
            this.setState({ userData: response.elf })

        })
    }
    componentDidMount() {
        this.checkPagePrivilege();
        var selected_app_id = localStorage.getItem('selectApp_id');
        this.setState({app_id:selected_app_id})
        this.branch(selected_app_id);
        this.user();
   

    };
    checkPagePrivilege(){
        var route = "#/accomplishments";
        getData("users/checkPrivilegePage", {user_id:localStorage.getItem("u"),route:route})
            .then(response => {
                console.log(response)
                if(response == "show_404"){
                    this.setState({ redirect: true});
                }else{
                    this.setState({ redirect: false});
                }
            })
    }

    componentWillUnmount() {
       
        if (localStorage.getItem("page") !== "accomplishment") {
            this._isMounted = false
        }
    }
    specificFieldmanReport = (data) => {
        var name=data.lname+', '+data.fname;
        this.setState({field_name:name,fieldma_assigned:[],countaccom:[],countassigned:[]});
        getData("users/getDataUser",{data:data,from:this.state.filterStartDate2,to:this.state.filterEndDate2}).then(response => {
            var details_assigned=[]
            var details_accomplished =[]
                for(let i = 0;i<response.details_assigned.length;i++){
                    if(response.details_assigned[i].app_id == 8){
                        var data_assigned = [];
                        data_assigned = {jo_number:response.details_assigned[i].jo_id,status:response.details_assigned[i].accomplishment_status,date_filter:response.details_assigned[i].date_filter,branch_name:response.details_assigned[i].branch_name}
                        details_assigned.push(data_assigned);
                  
                    }
            
                }  for(let i = 0;i<response.details_accomplished.length;i++){
                    if(response.details_assigned[i].app_id == 8){
                        var data_accom = [];
                        data_accom = {jo_number:response.details_accomplished[i].jo_id,status:response.details_accomplished[i].accomplishment_status,date_filter:response.details_accomplished[i].date_filter,branch_name:response.details_accomplished[i].branch_name}
                        details_accomplished.push(data_accom);
                    }
            
                }
            this.setState({countaccom:response.details_accomplished.length,countassigned:response.details_assigned.length,data_assigned:details_assigned,data_accom:details_accomplished})
            

        })

    }
    status = (data) => {
      
        if (data == 1) {
            this.setState({ fieldma_assigned: this.state.data_assigned })
        } else if (data == 2) {
            this.setState({ fieldma_assigned: this.state.data_accom })
        }
    }
    render() {
        if(this.state.redirect == true){
            return <Redirect to = '/notFound' />
        }

        localStorage.setItem("page", "accomplishment");

        return (
            <div id="content" className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-heading">
                                <div className="panel-heading-btn"></div>
                                <h4 className="panel-title">Filter</h4>
                            </div>
                            <div className="panel-body">
                                <form className="form-horizontal form-bordered">
                                    <div className="form-group row">
                                        <label className="col-md-4 col-form-label">Date Ranges</label>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <DatePicker
                                                        selected={this.state.filterStartDate2}
                                                        selectsStart
                                                        startDate={this.state.filterStartDate2}
                                                        endDate={this.state.filterEndDate2}
                                                        onChange={this.filterDateStart2}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <DatePicker
                                                        selected={this.state.filterEndDate2}
                                                        selectsEnd
                                                        startDate={this.state.filterStartDate2}
                                                        endDate={this.state.filterEndDate2}
                                                        onChange={this.filterDateEnd2}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-md-4">Branch</label>
                                        <div className="col-md-8">
                                            <select className="form-control" onChange={this.onChangeBranch}>
                                            <option value=""></option>
                                                <option value="All">All</option>
                                                {this.state.branches.map((branch, index) =>

                                                    <option value={branch.id}>{branch.name}</option>

                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-heading">
                                <div className="panel-heading-btn"></div>
                                <h4 className="panel-title">Fieldman List&nbsp;:&nbsp;{this.state.fieldman_name.length}</h4>
                            </div>
                            <div className="panel-body">
                                <Scrollbars style={{ height: 260 }}>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Branch</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.fieldman_name.map((employee, index) =>
                                                <tr key={index}>
                                                    <th scope="row">{employee.id}</th>
                                                    <th scope="row">{employee.lname + ', ' + employee.fname}</th>
                                                    <td>
                                                        <span className="badge badge-success pull-left">{employee.branch}</span>
                                                    </td>
                                                    <td> <button onClick={() => this.specificFieldmanReport(employee)} data-target="#demo-lg-modal2" data-toggle="modal" className="btn btn-teal btn-xs"><i className="fa fa-angle-right"></i></button> </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>

                </div>
                    <div id="demo-lg-modal2" className="modal fade" tabindex="-1">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal"><i className="pci-cross pci-circle"></i></button>
                                    <h4 className="modal-title" id="myLargeModalLabel">{this.state.field_name}</h4>
                                </div>
                                <div className="modal-body">


                                    <div className="row">
                                    
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card card-primary card-colorful " onClick={() => this.status("1")}>
                                                        <div className="pad-all text-center">
                                                            <span className="text-3x text-thin">{this.state.countassigned}</span>
                                                            <p>Assigned</p>
                                                            <i className="demo-pli-shopping-bag icon-lg"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card card-success card-colorful" onClick={() => this.status("2")}>
                                                        <div className="pad-all text-center">
                                                            <span className="text-3x text-thin">{this.state.countaccom}</span>
                                                            <p>Accomplished</p>
                                                            <i className="demo-pli-shopping-bag icon-lg"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel-body">
                                        <Scrollbars style={{ height: 260 }}>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Job Order</th>
                                                        <th>Status</th>
                                                        <th>Branch</th>
                                                        <th>Date</th>

                                                    </tr>
                                                </thead>    
                                                <tbody>
                                                    {this.state.fieldma_assigned.map((employee) => {

                                                        return <tr>
                                                            <td scope="row">{employee.jo_number}</td>
                                                            <td scope="row">{employee.status}</td>
                                                            <td scope="row">{employee.branch_name   }</td>
                                                            <td scope="row">{employee.date_filter}</td>

                                                        </tr>
                                                    }


                                                    )}
                                                </tbody>
                                            </table>
                                        </Scrollbars>
                                    </div>

                                    <hr />
                                

                                </div>
                            </div>
                        </div>
                    </div>
       



                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-heading">
                                <div className="panel-heading-btn"></div>
                                <h4 className="panel-title">Attendance Report</h4>
                            </div>
                            <div className="panel-body">
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default Index;

