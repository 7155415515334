import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import pockethr from './img/pockethr.png'
import reunion from './img/reunion.svg'

class register extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {

    return (
      <div id="content" className="content">
        <img src={reunion} width='5%' height='5%' className="pull-right" style={{borderStyle: 'solid', borderRadius: 100,width: 100, height: 100, position: "absolute", borderBottomColor: "white",borderTopColor: '#5cca7a',borderLeftColor: '#5cca7a',borderRightColor: '#5cca7a' , top: 75, left: 680, backgroundColor: 'white'}}/>
        <div className="row panel " style={{ width: '80%', paddingTop: 30, marginLeft: 100, marginTop: 80, borderStyle: 'solid', borderColor: '#5cca7a' }}>

          <div className="col-md-6" >
            <form >
              <div class="form-group row">
                <div class="col-md-2" style={{ marginLeft: 60 }}>
                  <label  className="text-green-lighter">First Name:</label>
                </div>
                <div class="col-md-7">
                  <input type="text" class="form-control m-b-5 registerbox" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2" style={{ marginLeft: 60 }}>
                  <label  className="text-green-lighter">Last Name:</label>
                </div>
                <div class="col-md-7">
                  <input type="text" class="form-control m-b-5 registerbox" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2" style={{ marginLeft: 60 }}>
                  <label  className="text-green-lighter">Bio ID:</label>
                </div>
                <div class="col-md-7">
                  <input type="number" class="form-control m-b-5 registerbox" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2" style={{ marginLeft: 60 }}>
                  <label  className="text-green-lighter">Contact No:</label>
                </div>
                <div class="col-md-7">
                  <input type="number" class="form-control m-b-5 registerbox" />
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6">
              <form>
                <div class="form-group row">
                  <div class="col-md-2" style={{ marginLeft: 20 }} >
                    <label  className="text-green-lighter">Email</label>
                  </div>
                  <div class="col-md-7">
                    <input type="email" class="form-control m-b-5 registerbox" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-2"  style={{ marginLeft: 20 }}>
                    <label  className="text-green-lighter">Password</label>
                  </div>
                  <div class="col-md-7">
                    <input type="password" class="form-control m-b-5 registerbox" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-2"  style={{ marginLeft: 20 }}>
                    <label  className="text-green-lighter">Confirm Password</label>
                  </div>
                  <div class="col-md-7">
                    <input type="password" class="form-control m-b-5 registerbox" />
                  </div>
                </div>
                <center>
                  <button data-dismiss="modal" className="pull-right bttnsub" style={{marginRight: 100}}>
                    <span className="fa fa-user-tag fa-sm"></span>
                  </button>
                </center>
              </form>
          </div>


        </div>

      </div>
    );
  }
}

export default register;
