import React, { Component } from 'react';
import { getData211, getData } from "../../../../api/api";
import swal from "sweetalert/dist/sweetalert.min.js";
import { Scrollbars } from 'react-custom-scrollbars';
class Assign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: [],
            changeEmployee: [],
            categories: [],
            viewInfo: [],
            customers: [],
            selectedCustomer: [],
            oldCustomer: [],
            selectedTab: 'Unassigned',
            defaultChecked: '',
            employeeTotal: [],
            showbutton: "Assigned",
            bgcolor1: "#404040",
            bgcolor2: "white",
            txtcolor1: "white",
            txtcolor2: "black",
            searchDriver: "",
            searchCustomers: "",
            searchOld: "",
            empdetails: [],

            assignedEmployee: 0,
            unassignedEmployee: 0,
            assPer: 0,
            unPer: 0,
            empdetails: [],

        }
        this.changeArea = this.changeArea.bind(this)
        this.checkedCustomer = this.checkedCustomer.bind(this);
        this.uploadAssign = this.uploadAssign.bind(this);
        this.selectedTab = this.selectedTab.bind(this);
        this.removeAssign = this.removeAssign.bind(this);

    }
    _isMounted = true;

    employee() {
        getData211('field_audit/assignEmployee', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
            result.forEach(element => {
                if (element['category_details'] === null) {
                    element['category_details'] = "None"
                }
            });
            this.setState({ changeEmployee: result });
            this.setState({ employee: result });

            let countass = 0;
            let countun = 0;
            for (let i = 0; i < result.length; i++) {
                if (result[i].TotalCount === 0) {
                    countun++
                } else {
                    countass++
                }
            }

            this.setState({ assignedEmployee: countass });
            this.setState({ unassignedEmployee: countun });

            let employee = this.state.employee.length;

            let assPer = (countass / employee) * 100;
            let unPer = (countun / employee) * 100;

            this.setState({ assPer: Math.round(assPer) });
            this.setState({ unPer: Math.round(unPer) });

        })
    }

    customers() {
        getData211('field_audit/salesClients', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
            this.setState({ customers: result });
        })
    }

    categories() {
        getData211('field_audit/getSalesCategories').then(result => {
            this.setState({ categories: result });
        })
    }

    changeArea(e) {
        var empdetails = []
        this.setState({ viewInfo: e, selectedTab: 'Unassigned', selectedCustomer: [], showbutton: "Assigned" });
        getData211('field_audit/getPersonCustomer', e.user_id).then(result => {
            this.setState({ oldCustomer: result, showbutton: "Assigned" });
        })
        empdetails.push(e)
        this.setState({ empdetails: empdetails })
        this.setState({ bgcolor1: "#404040" });
        this.setState({ bgcolor2: "white" });
        this.setState({ txtcolor1: "white" });
        this.setState({ txtcolor2: "black" });
    }

    checkedCustomer(e) {

        let id = e.target.value;
        let checked = e.target.checked;
        let data = this.state.selectedCustomer;

        if (checked) {
            data.push(id);
            this.setState({ selectedCustomer: data });
        } else {
            var index = data.indexOf(id);
            if (index !== -1) {
                data.splice(index, 1);
                this.setState({ selectedCustomer: data });
            }
        }
    }

    uploadAssign() {
        let customer = this.state.customers;
        let selectedCustomer = this.state.selectedCustomer;
        let viewInfo = this.state.viewInfo;

        let uploadData = [];
        let updateOld = [];

        for (let i = 0; i < customer.length; i++) {
            for (let b = 0; b < selectedCustomer.length; b++) {
                if (customer[i].cust_id === selectedCustomer[b]) {
                    uploadData.push({ id: customer[i].cust_id, person: viewInfo.user_id, u_id: localStorage.getItem("u") });
                    updateOld.push(customer[i]);
                }
            }
        }


        getData211('field_audit/uploadSelectedPerson', uploadData).then(result => {
            if (result.res === "Success") {
                // var index = data.indexOf(id);
                // if(index !== -1){
                // data.splice(index, 1);
                // this.setState({selectedCustomer: data});
                // }
                let old = this.state.oldCustomer;
                for (let i = 0; i < updateOld.length; i++) {
                    old.push(updateOld[i]);
                }
                this.setState({ oldCustomer: old });
                getData211('field_audit/assignEmployee', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
                    result.forEach(element => {
                        if (element['category_details'] === null) {
                            element['category_details'] = "None"
                        }
                    });
                    this.setState({ changeEmployee: result });
                    this.setState({ employee: result });
                    let countass = 0;
                    let countun = 0;
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].TotalCount === 0) {
                            countun++
                        } else {
                            countass++
                        }
                    }

                    this.setState({ assignedEmployee: countass });
                    this.setState({ unassignedEmployee: countun });
                    let employee = this.state.employee.length;

                    let assPer = (countass / employee) * 100;
                    let unPer = (countun / employee) * 100;

                    this.setState({ assPer: Math.round(assPer) });
                    this.setState({ unPer: Math.round(unPer) });
                })
                getData211('field_audit/salesClients', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
                    this.setState({ customers: result });
                })
                var x = document.getElementsByName("customerCheckbox");
                for (let i = 0; i < x.length; i++) {
                    x[i].checked = false
                }
                this.setState({ selectedCustomer: [] });
                swal("Successfully Assigned", "", "success");
            } else {
                swal("Failed to Assign Area", "", "error");
            }
        })
    }

    removeAssign() {
        let old = this.state.oldCustomer;
        let selectedCustomer = this.state.selectedCustomer;
        let viewInfo = this.state.viewInfo;


        let removeData = [];
        let removeOld = [];

        for (let i = 0; i < old.length; i++) {
            for (let b = 0; b < selectedCustomer.length; b++) {
                if (old[i].cust_id === selectedCustomer[b]) {
                    removeData.push({ id: old[i].cust_id, person: viewInfo.user_id });
                    removeOld.push(old[i]);
                }
            }
        }

        getData211('field_audit/removeSelectedPerson', removeData).then(result => {
            if (result.res === "Success") {

                getData211('field_audit/getPersonCustomer', viewInfo.user_id).then(result => {
                    this.setState({ oldCustomer: result });
                })


                getData211('field_audit/assignEmployee', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
                    result.forEach(element => {
                        if (element['category_details'] === null) {
                            element['category_details'] = "None"
                        }
                    });
                    this.setState({ changeEmployee: result });
                    this.setState({ employee: result });
                    let countass = 0;
                    let countun = 0;
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].TotalCount === 0) {
                            countun++
                        } else {
                            countass++
                        }
                    }

                    this.setState({ assignedEmployee: countass });
                    this.setState({ unassignedEmployee: countun });
                    let employee = this.state.employee.length;

                    let assPer = (countass / employee) * 100;
                    let unPer = (countun / employee) * 100;

                    this.setState({ assPer: Math.round(assPer) });
                    this.setState({ unPer: Math.round(unPer) });
                })
                getData211('field_audit/salesClients', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
                    this.setState({ customers: result });
                })
                var x = document.getElementsByName("customerCheckbox");
                for (let i = 0; i < x.length; i++) {
                    x[i].checked = false
                }
                this.setState({ selectedCustomer: [] });
                swal("Successfully Unassign Area", "", "success");
            } else {
                swal("Failed to Assign Area", "", "error");
            }
        })

    }

    selectedTab(e) {
        if (e.target.name === "Assigned") {
            this.setState({ showbutton: "Unassigned" })
            this.setState({ selectedTab: "Assigned" })
            this.setState({ bgcolor2: "#404040" });
            this.setState({ bgcolor1: "white" });
            this.setState({ txtcolor2: "white" });
            this.setState({ txtcolor1: "black" });
            this.setState({ searchOld: "" });

        } else if (e.target.name === "Unassigned") {
            this.setState({ showbutton: "Assigned" })
            this.setState({ selectedTab: "Unassigned" })
            this.setState({ bgcolor1: "#404040" });
            this.setState({ bgcolor2: "white" });
            this.setState({ txtcolor1: "white" });
            this.setState({ txtcolor2: "black" });
            this.setState({ searchCustomers: "" });
        }
        this.setState({ selectedCustomer: [] });
        var x = document.getElementsByName("customerCheckbox");
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }


    }

    componentDidMount() {
        this.employee();
        this.categories();
        this.customers();
    }


    componentWillUnmount() {
        if (localStorage.getItem("pass") !== "assign") {
            this._isMounted = false
        }

    }

    update() {
        if (localStorage.getItem("pass") === "assign") {
            setTimeout(() => {
                this.employee();
                this.categories();
                this.customers();
                this.update();
            }, 5000);
        } else {
            clearTimeout(this.update);
        }
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    searchCustomers(event) {
        this.setState({ searchCustomers: event.target.value.substr(0, 20) });
    }
    searchOld(event) {
        this.setState({ searchOld: event.target.value.substr(0, 20) });
    }

    render() {
        localStorage.setItem("pass", "assign");
        let EmployeeSearch = this.state.changeEmployee.filter(
            (files) => {
                return files.user_fname.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    files.user_lname.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    files.user_mobile.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    files.user_jobposition.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        let CustomersSearch = this.state.customers.filter(
            (cust) => {
                return cust.cust_fname.toLowerCase().indexOf(
                    this.state.searchCustomers.toLocaleLowerCase()) !== -1 ||
                    cust.cust_lname.toLowerCase().indexOf(
                        this.state.searchCustomers.toLocaleLowerCase()) !== -1 ||
                    cust.cust_address.toLowerCase().indexOf(
                        this.state.searchCustomers.toLocaleLowerCase()) !== -1 ||
                    cust.cust_status.toLowerCase().indexOf(
                        this.state.searchCustomers.toLocaleLowerCase()) !== -1
            }
        )
        let Customersold = this.state.oldCustomer.filter(
            (cust) => {
                return cust.cust_fname.toLowerCase().indexOf(
                    this.state.searchOld.toLocaleLowerCase()) !== -1 ||
                    cust.cust_lname.toLowerCase().indexOf(
                        this.state.searchOld.toLocaleLowerCase()) !== -1 ||
                    cust.cust_address.toLowerCase().indexOf(
                        this.state.searchOld.toLocaleLowerCase()) !== -1 ||
                    cust.cust_status.toLowerCase().indexOf(
                        this.state.searchOld.toLocaleLowerCase()) !== -1
            }
        )


        return (
            <div>
                <div className="row">
                    <div className="col-xl-9">
                        <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2 border-right text-center">
                                        <div className="mb-3 text-blue" name="Total">
                                            <b style={{ fontSize: 15 }}>Total Employees <i class="fa fa-exclamation-circle pointer" name="Total" onClick={() => {
                                                let data = this.state.employee
                                                this.setState({ changeEmployee: data });
                                            }}></i></b>
                                        </div>
                                        <div>
                                            <center><h2 className="text-white"><span>{this.state.employee.length}</span></h2></center>
                                        </div>
                                    </div>
                                    <div className="col-md-10">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className=" text-green-lighter" style={{ fontSize: 15 }}>Assigned Employees <i class="fa fa-exclamation-circle pointer" name="Assigned" onClick={() => {
                                                    let data = this.state.employee
                                                    let change = [];
                                                    for (let i = 0; i < data.length; i++) {
                                                        if (data[i].TotalCount !== 0) {
                                                            change.push(data[i]);
                                                        }
                                                    }
                                                    this.setState({ changeEmployee: change });
                                                }}></i></div>
                                                <div className="m-b-5 p-b-1" style={{ fontSize: 23 }}>{this.state.assignedEmployee}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-orange" style={{ fontSize: 15 }}>Unassigned Employees <i class="fa fa-exclamation-circle pointer" name="Unassigned" onClick={() => {
                                                    let data = this.state.employee
                                                    let change = [];
                                                    for (let i = 0; i < data.length; i++) {
                                                        if (data[i].TotalCount === 0) {
                                                            change.push(data[i]);
                                                        }
                                                    }
                                                    this.setState({ changeEmployee: change });
                                                }}></i></div>
                                                <div className="m-b-5 p-b-1" style={{ fontSize: 23 }}><span>{this.state.unassignedEmployee}</span></div>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-green-lighter" style={{ width: this.state.assPer + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-orange-lighter" style={{ width: this.state.unPer + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card border-0 bg-dark text-white text-truncate mb-3">
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <b className="m-b-3" style={{ fontSize: 15 }}>Pending Areas</b>
                                        </div>
                                        <div className="m-b-1">
                                            <h2 className="text-white text-center"><span>{this.state.customers.length}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-heading">
                        <h4 className="panel-title">Employees</h4>
                        <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
                            <div className="input-group pull-right">
                                <input type="text" className="form-control"
                                    id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)}
                                />
                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>

                    <div className="panel-body">
                        <Scrollbars style={{ height: 400, width: "100%" }}>
                            <table>
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>Fullname</th>
                                        <th>Email</th>
                                        <th>Contact Details</th>
                                        <th>Position</th>
                                        <th>Assigned Count</th>
                                        <th>Change Area</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white table table-condense">
                                    {EmployeeSearch.map(data => {
                                        return <tr>
                                            <td>{data.user_fname + " " + data.user_lname}</td>
                                            <td>{data.user_email}</td>
                                            <td>{data.user_mobile}</td>
                                            <td>{data.user_jobposition}</td>
                                            <td>{data.TotalCount}</td>
                                            <td><i className="fas fa-ellipsis-v b pointer" data-target="#demo-xl-modal1" data-toggle="modal" onClick={() => this.changeArea(data)}></i></td>
                                        </tr>
                                    }
                                    )}
                                </tbody>
                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-xl-modal1" className="modal modal-message fade modal-backdrop" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop='static' focus="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {this.state.empdetails.map(data =>
                                    <h4 className="m-t-15">Select area to be assign for {data.user_fname + " " + data.user_lname}</h4>
                                )}

                                <button data-dismiss="modal" className="pull-right bttnclose bg-red">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body d-block d-md-block">
                                <div>
                                    <div className="panel-body">
                                        <div className="row m-b-20">
                                            <div className="col-xl-6">
                                                <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="text-orange" style={{ fontSize: 15 }}>Pending Areas</div>
                                                                        <div className="m-b-5 p-b-1" style={{ fontSize: 25 }}><span>{this.state.customers.length}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="text-green-lighter" style={{ fontSize: 15 }}>Assigned Areas</div>
                                                                        <div className="m-b-5 p-b-1" style={{ fontSize: 25 }}>{this.state.oldCustomer.length}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>{this.state.showbutton === "Assigned" &&
                                                    <button className="btn bg-green text-white" onClick={this.uploadAssign}>Assign</button>
                                                }</div>
                                                <div>{this.state.showbutton === "Unassigned" &&
                                                    <button className="btn bg-orange text-white" onClick={this.removeAssign}>Unassign</button>
                                                }</div>
                                            </div>
                                            <div style={{ display: 'flex',justifyContent:"space-around" }}>
                                                <div >
                                                    {this.state.selectedTab === "Unassigned" ?
                                                        <div className="form-group" >
                                                            <div className="input-group">
                                                                <input type="text" className="form-control"
                                                                    id="demo-input-search2" placeholder="Search Name" name="CustomerSearch" autocomplete="off" value={this.state.searchCustomers} onChange={this.searchCustomers.bind(this)}
                                                                />
                                                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control"
                                                                    id="demo-input-search2" placeholder="Search Name" name="CustomerSearch" autocomplete="off" value={this.state.searchOld} onChange={this.searchOld.bind(this)}
                                                                />
                                                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div style={{ display: 'flex', justifyContent:"space-around" }} className="m-t-10">
                                                    <div name="Unassigned" 
                                                        onClick={this.selectedTab}>
                                                        <a className="rounded-corner m-l-5" name="Unassigned" onClick={this.selectedTab} style={{padding: 10, cursor: "pointer", color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1, borderStyle: "solid", borderColor: "#404040" }}>Pending Areas</a>
                                                    </div>
                                                    <div name="Assigned"
                                                        onClick={this.selectedTab}>
                                                        <a className="rounded-corner m-l-5" name="Assigned" onClick={this.selectedTab}  style={{ padding: 10,cursor: "pointer", color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2, borderStyle: "solid", borderColor: "#404040"  }}>Assigned Areas</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="row m-b-20"> 
 <div className="col-md-6">
 </div>
 <div className="col-md-6">
 {this.state.selectedTab === "Unassigned" ?
 <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
 <div className="input-group pull-right">
 <input type="text" className="form-control"
 id="demo-input-search2" placeholder="Search Name" name="CustomerSearch" autocomplete="off" value={this.state.searchCustomers} onChange={this.searchCustomers.bind(this)}
 />
 <span className="input-group-addon"><i className="fa fa-search"></i></span>
 </div>
 </div>
 :
 <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
 <div className="input-group pull-right">
 <input type="text" className="form-control"
 id="demo-input-search2" placeholder="Search Name" name="CustomerSearch" autocomplete="off" value={this.state.searchOld} onChange={this.searchOld.bind(this)}
 />
 <span className="input-group-addon"><i className="fa fa-search"></i></span>
 </div>
 </div>
 }
 </div> 
 </div> */}
                                        <div >
                                        </div>
                                        {this.state.selectedTab === "Unassigned" ?
                                            <Scrollbars style={{ height: 400, width: '100%' }}>
                                                <table>
                                                    <thead className="tableFixHead">
                                                        <tr>
                                                            <th style={{ zIndex: 5 }}></th>
                                                            <th>Company name</th>
                                                            <th>Address</th>
                                                            <th>Building</th>
                                                            <th>Contact Person</th>
                                                            <th>Contact Number</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white table table-condense">
                                                        {CustomersSearch.map(data => {
                                                            if (data.cust_status === "Open") {
                                                                return <tr>
                                                                    <td><input type="checkbox" name="customerCheckbox" value={data.cust_id} onChange={this.checkedCustomer} /></td>
                                                                    <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                                    <td>{data.cust_address}</td>
                                                                    <td>{data.cust_building_name}</td>
                                                                    <td>{data.cust_contact_person}</td>
                                                                    <td>{data.cust_contact_mobilie}</td>
                                                                    <td className="text-orange">{data.cust_status}</td>
                                                                </tr>
                                                            } else {
                                                                return <tr>
                                                                    <td><input type="checkbox" name="customerCheckbox" value={data.cust_id} onChange={this.checkedCustomer} /></td>
                                                                    <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                                    <td>{data.cust_address}</td>
                                                                    <td>{data.cust_building_name}</td>
                                                                    <td>{data.cust_contact_person}</td>
                                                                    <td>{data.cust_contact_mobilie}</td>
                                                                    <td className="text-green">{data.cust_status}</td>
                                                                </tr>
                                                            }
                                                        }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Scrollbars>
                                            :
                                            <Scrollbars style={{ height: 250, width: '100%' }}>
                                                <table>
                                                    <thead className="tableFixHead">
                                                        <tr>
                                                            <th style={{ zIndex: 5 }}></th>
                                                            <th>Company name</th>
                                                            <th>Address</th>
                                                            <th>Building</th>
                                                            <th>Contact Person</th>
                                                            <th>Contact Number</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white table table-condense">
                                                        {Customersold.map(data => {
                                                            if (data.cust_status === "Open") {
                                                                return <tr>
                                                                    <td><input type="checkbox" name="customerCheckbox" value={data.cust_id} onChange={this.checkedCustomer} /></td>
                                                                    <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                                    <td>{data.cust_address}</td>
                                                                    <td>{data.cust_building_name}</td>
                                                                    <td>{data.cust_contact_person}</td>
                                                                    <td>{data.cust_contact_mobilie}</td>
                                                                    <td className="text-orange">{data.cust_status}</td>
                                                                </tr>
                                                            } else {
                                                                return <tr>
                                                                    <td><input type="checkbox" name="customerCheckbox" value={data.cust_id} onChange={this.checkedCustomer} /></td>
                                                                    <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                                    <td>{data.cust_address}</td>
                                                                    <td>{data.cust_building_name}</td>
                                                                    <td>{data.cust_contact_person}</td>
                                                                    <td>{data.cust_contact_mobilie}</td>
                                                                    <td className="text-green">{data.cust_status}</td>
                                                                </tr>
                                                            }
                                                        }
                                                        )}
                                                    </tbody>

                                                </table>

                                            </Scrollbars>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </div >
        );
    }
}

export default Assign;