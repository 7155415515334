
const initialState = {
    Listemp: [],
    deduction: [],
    earning: [],
    selected_employee_payroll: [],
    payDetails: [],
    responsedata: [],
    companies:[],
    branches:[],
    late_employees:[],
    Disburstment:[],
    payroll_branches:[],
    branchHierarchy:[],
    payrollGroup:[],
    test:[]
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GetEmployeePayroll':
            return {
                ...state,
                Listemp: action.Listemp,
                deduction: action.deduction,
                earning: action.earning,
                responsedata:action.responsedata,
                Disburstment:action.Disburstment
            }
        case 'SelectedEmployeePayroll':
            return {
                ...state,
                selected_employee_payroll: action.data

            }
        case 'ViewPayrollDetails':
            return {
                ...state,
                payDetails: action.payDetails,
                responsedata: action.responsedata

            }
        case 'UpdateDeductionEarnings':
            return {
                ...state,
                deduction: action.deduction,
                earning: action.earning,

            }
        case 'Company':
            return {
                ...state,
                companies: action.data,

        }
        case 'Branch':
            return {
                ...state,
                branches: action.data,

        }
        case 'PayrollSettingEmployee':
            return {
                ...state,
                late_employees: action.data,

        }
        case 'Branches':
            return {
                ...state,
                payroll_branches: action.data,

        }
        case 'Branch_Hierarchy':
            return {
                ...state,
                branchHierarchy: action.data,

        }
        case 'UpdateStructure':
             state.payroll_branches.map((val)=>{
                if(action.branch_id == val.branch_id){
                    val.payroll_hierarchy = JSON.stringify(action.data) 
                }
            })
            return {
                ...state,
               
        }
        case 'PayrollGroup':
            return {
                ...state,
                payrollGroup: action.data,
        }
        case 'ApproveStatus':
            state.payrollGroup.status = 'Approved'
            return {
                ...state,
                
        }
        default:
            return state;
    }

}
export default dataReducer;