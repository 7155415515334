import React, { Component } from 'react';
// import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import DPie3 from "./charts/d_pie3";
import DBar3 from "./charts/d_bar3";
import DLine3 from "./charts/d_line";
import GoogleMapReact from 'google-map-react';
import { getData } from '../../../../api/api';
import { Redirect } from 'react-router-dom'
import FImarker from '../../../../../assets/maps/users.png'
import moment from 'moment';
import Logs from "../history/index";
import Assign from "../deployment/assign";
import Client from "../clients/index";
import Accomplishments from "../accomplishments/accomplishments";
import SaleSettings from "../settings/index"
// import Sales from "../users/index"
// import pockethr from './img/pockethr.png'


const Fieldman = ({ text, name, contact, lat, lng, marker }) => <div style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }} className="tooltipparent">
    <img className="btn-social" data-tip data-for={text} alt="" style={{ maxWidth: 30, maxHeight: 30 }} src={FImarker} />
    <span className="tooltiptextbody">
        <p>
            Name:  {name}<br />
            Location: {lat + ',' + lng}<br />
            Contact: {contact}<br />
        </p>
    </span>
</div>;


class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date().toLocaleString(),
            refresh: false,
            // hours: '',
            // minutes: '',
            // seconds: '',
            searchDriver: "",
            pieGraph: [],
            barGraph: [],
            redirect: false,
            from_date: moment(new Date()).format('YYYY-MM-01'),
            to_date: moment(new Date()).format('YYYY-MM-DD'),
            userData: [],
            close_percent: 0,
            open_percent: 0,
            data_totalSales: [],
            open_accom_details: [],
            close_accom_details: [],
            date_range_to: new Date(moment(new Date()).format('YYYY-MM-DD')),
            date_range_from: new Date(moment(new Date()).format('YYYY-MM-01')),
            today_sale: 0,
            yesterday_sale: 0,
            last_month: 0,
            today_location: [],
            yesterday_location: [],
            fieldMonitoring: [],
            AttendanceToday: [],
            dateToday: new Date(),
            todaysPresent: 0,
            todaysLate: 0,
            todaysAbsent: 0,
            countTotal: 0,
            THead: [],
            timesheetData: [],
            month_location: [],
            bgcolor1: "rgb(21, 27, 38)",
            bgcolor2: "transparent",
            bgcolor3: "transparent",
            bgcolor4: "transparent",
            bgcolor5: "transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            txtcolor3: "black",
            txtcolor4: "black",
            txtcolor5: "black",
            lineGraph_val: [],
            route: "DashBoard",
            from_date: new Date(),
            to_date: new Date(),
            total_bandwith: 0,
            total_sales: 0,

            mapoption: {
                center: {
                    lat: 14.5209618,
                    lng: 121.0518853
                },
                zoom: 11,
            },

            MapLiveLocation: [],
        };
        this.from_dateChange = this.from_dateChange.bind(this);
        this.to_dateChange = this.to_dateChange.bind(this);
        this.filterDateStart = this.filterDateStart.bind(this);
        this.filterDateEnd = this.filterDateEnd.bind(this);
        this.submitDate = this.submitDate.bind(this)
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    componentDidMount() {
        this.checkPagePrivilege();
        this.getUser();
        this.getSales();

        this.getLiveLocation();
        // this.attendance_today();
    }
    getSales() {
        getData("users/get_handle_sales_data", { u_id: localStorage.getItem("u"), type: "admin", from: this.state.date_range_from, to: this.state.date_range_to })
            .then(response => {
                console.log(response);
                var open_accom_details = []
                var close_accom_details = []

                for (let index = 0; index < response.data_totalSales.length; index++) {
                    if (response.data_totalSales[index].cust_status == 'Open') {
                        open_accom_details.push(response.data_totalSales[index])
                    } else if (response.data_totalSales[index].cust_status == 'Closed') {
                        close_accom_details.push(response.data_totalSales[index])

                    }

                }
                var lineGraph_val = []
                for (let index = 0; index < response.allDates.length; index++) {
                    for (let i = 0; i < response.data_totalSales.length; i++) {
                        if (moment(new Date(response.data_totalSales[i].cust_date_added)).format('YYYY-MM-DD') == response.allDates[index]) {
                            var match = false;
                            for (let k = 0; k < lineGraph_val.length; k++) {
                                if (lineGraph_val[k].date == response.allDates[index]) {

                                    if (response.data_totalSales[i].cust_status == "Open") {
                                        lineGraph_val[k].value++;
                                    } else {
                                        lineGraph_val[k].value1++;
                                    }
                                    match = true;
                                }

                            }
                            if (match == false) {
                                var date = []
                                date = { date: response.allDates[index], value: 0, value1: 0 }
                                if (response.data_totalSales[i].cust_status == "Open") {
                                    date['value'] += 1;
                                } else {
                                    date['valu1'] += 1;
                                }
                                lineGraph_val.push(date);
                            }

                        }

                    }

                }

                var open_percent = (open_accom_details.length / response.data_totalSales.length * 100).toFixed(2)
                var close_percent = (close_accom_details.length / response.data_totalSales.length * 100).toFixed(2)
                if (open_percent > 0) {
                    this.setState({ open_percent: open_percent, close_percent: close_percent })
                } else {
                    this.setState({ open_percent: 0, close_percent: 0 })
                }
                // this.state.fieldMonitoring.push(response.fieldMonitoring);
                var timeHead = 0;
                var userData_Details = [];
                // var timesheetData = [];
                for (let index = 0; index < response.fieldMonitoring2.length; index++) {
                    userData_Details.push(response.fieldMonitoring2[index].details)
                    //  for (let i = 0; i < response.fieldMonitoring2[index].length; i++) {
                    if (timeHead < response.fieldMonitoring2[index].timesheet.length) {
                        timeHead = response.fieldMonitoring2[index].timesheet.length;
                        // }

                    }

                }

                var THead = []
                for (let index = 0; index < timeHead; index++) {
                    // console.log(timeHead);

                    if (THead.length == 0) {
                        THead.push({ head: 'Time-in' })
                    }
                    else if (THead[THead.length - 1].head == 'Time-in') {
                        THead.push({ head: 'Time-out' })
                    } else if (THead[THead.length - 1].head == 'Time-out') {
                        THead.push({ head: 'Time-in' })
                    }


                }
                this.setState({ total_bandwith: response.total_bandwith, total_sales: response.total_sales, lineGraph_val: lineGraph_val, pieGraph: response.graph_location, month_location: response.month_location, timesheetData: response.fieldMonitoring2, userData: userData_Details, THead: THead, barGraph: response.total_open, today_location: response.today_location, yesterday_location: response.yesterday_location, Graph: response.total_open, last_month: response.last_month, today_sale: response.today.length, yesterday_sale: response.yesterday.length, data_totalSales: response.data_totalSales, open_accom_details: open_accom_details, close_accom_details: close_accom_details })
            })
    }
    getUser() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                // console.log(response)
                this.setState({ userData: response.users })
            })
    }
    checkPagePrivilege() {
        var route = "#/maps";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                if (response == "show_404") {
                    this.setState({ redirect: 'checkPagePrivilege' });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    from_dateChange = e => {

        setTimeout(() => {
            this.setState({
                from_date: e
            });
            setTimeout(() => {
                this.getSales()
            }, 200)
        }, 100)

    }
    to_dateChange = e => {

        setTimeout(() => {
            this.setState({
                to_date: e
            });
            setTimeout(() => {
                this.getSales()
            }, 200)

        }, 100)
    }
    getMapOptions = (maps) => {

        return {
            streetViewControl: true,
            scaleControl: false,
            fullscreenControl: false,
            zoomControl: false,
            styles: [{
                featureType: "poi.business",
                elementType: "as",
                stylers: [{
                    visibility: "on"
                }]
            }],
            gestureHandling: "greedy",
            disableDoubleClickZoom: false,
            mapTypeControl: false,

            mapTypeId: maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_RIGHT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            },
            scaleControl: false,
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: true,
            clickableIcons: false
        };
    }

    getLiveLocation() {
        getData("tracking/getLocation", { u_id: localStorage.getItem("u"), type: "admin" }).then(response => {
            const livelocation = [];
            // console.log(response);
            for (let a = 0; a < response.length; a++) {
                livelocation.push({
                    'user_id': response[a].id,
                    'name': response[a].user_details.fname + ' ' + response[a].user_details.mname + ' ' + response[a].user_details.lname,
                    'lat': response[a].location.latitude,
                    'lng': response[a].location.longitude
                });
            }


            this.setState({ MapLiveLocation: livelocation });
        })
    }
    attendance_today(branch) {
        var data = [];
        data = {
            company_id: '',
            branch_id: '',
            dateToday: this.state.dateToday,
            user_id: localStorage.getItem("u")
        }
        getData("users/GetCurrentAttendance", data)
            .then(response => {
                this.setState({ AttendanceToday: response.details });

                var arrayD = []
                var value1 = 0;
                var Arrays = []
                var Attendance = 0;
                var countpresent = 0;
                var countlate = 0;
                var countabsent = 0;
                var branchBreakdown = []
                for (let index = 0; index < response.details.length; index++) {
                    if (response.details[index]['status'] == "PRESENT") {
                        countpresent++;
                        this.setState({ todaysPresent: countpresent });
                    }
                    if (response.details[index]['status'] == "LATE") {
                        countlate++;
                        this.setState({ todaysLate: countlate });
                    }
                    if (response.details[index]['status'] == "ABSENT") {
                        countabsent++;
                        this.setState({ todaysAbsent: countabsent });
                    }
                    this.setState({ countTotal: response.details.length });

                    for (let i = 0; i < response.details[index].details['timeSheet'].length; i++) {
                        var data = [];
                        if (response.details[index].details['timeSheet'][i]['fields'].length > value1) {
                            value1 = response.details[index].details['timeSheet'][i]['fields'].length
                            arrayD = response.details[index].details['timeSheet'][i]['fields']
                        }
                        Arrays = response.details[index].details['timeSheet'][i]['attendance'];

                    }
                }
                var dataDetaiils = [];
                for (let index = 0; index < arrayD.length; index++) {
                    var name = { fields: arrayD[index] }
                    dataDetaiils.push(name);
                    this.setState({ timeSheetHeader: dataDetaiils })
                }

                // console.log(dataDetaiils);
            });
    }
    filterDateStart = e => {
        setTimeout(() => {
            this.setState({
                date_range_from: e
            });


        }, 100)

        // console.log(this.state)
    }
    filterDateEnd = e => {
        setTimeout(() => {
            this.setState({
                date_range_to: e
            });

        }, 100)
        // console.log(this.state)
    }
    submitDate = (e) => {
        e.preventDefault();
        this.getSales()
    }
    render() {
        // console.log(response);
        if (this.state.redirect == 'checkPagePrivilege') {
            return <Redirect to='/notFound' />
        }

        let EmployeeSearch = this.state.userData.filter(
            (files) => {
                return files.completename.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.branch_name.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (
            <div>

                <div>
                    <div className="navmedia m-t-30 m-b-30">
                        <div className="navtitle panel-title">
                            {this.state.route == "DashBoard" &&
                                <div class="align-items-center ">
                                    <p class="btn btn-inverse " data-target="#demo-lg-modal1" data-toggle="modal">
                                        <i class=" "></i>
                                        <span>{moment(this.state.date_range_from).format('DD MMMM YYYY') + ' - ' + moment(this.state.date_range_to).format('DD MMMM YYYY')}</span>
                                    </p>
                                </div>
                            }
                            {this.state.route == "Client" &&
                                < h4>
                                    Company Clients
                                <span className="m-l-2">
                                        <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Client Company List" data-placement="top" data-content="The table displays the list of company which you can view each detail about the selected company." data-original-title="" title="">
                                        </i>
                                    </span>
                                </h4>
                            }
                            {this.state.route == "Accomplishments" &&
                                < h4>
                                    Employee Accomplishments
                                <span className="m-l-2">
                                        <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Employee Accomplishments" data-placement="top" data-content="The table displays the list of company which you can view each detail about the selected company." data-original-title="" title="">
                                        </i>
                                    </span>
                                </h4>
                            }
                            {this.state.route == "Assign" &&
                                < h4>
                                    Present Client Assigning
                                <span className="m-l-2">
                                        <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Present Client Assigning" data-placement="top" data-content="The table displays the list of company which you can view each detail about the selected company." data-original-title="" title="">
                                        </i>
                                    </span>
                                </h4>
                            }
                            {this.state.route == "Logs" &&
                                < h4>
                                    History Logs
                                <span className="m-l-2">
                                        <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="History Logs" data-placement="top" data-content="The table displays the list of company which you can view each detail about the selected company." data-original-title="" title="">
                                        </i>
                                    </span>
                                </h4>
                            }
                            {this.state.route == "settings" &&
                                < h4>
                                    Settings
                                <span className="m-l-2">
                                        <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Settings" data-placement="top" data-content="The table displays the list of company which you can view each detail about the selected company." data-original-title="" title="">
                                        </i>
                                    </span>
                                </h4>
                            }
                        </div>
                        <div className="navnav">
                            <div className="attendnav m-r-20" onClick={() => {
                                this.setState({
                                    route: "DashBoard",
                                    txtcolor1: "white",
                                    txtcolor2: "black",
                                    txtcolor3: "black",
                                    txtcolor4: "black",
                                    txtcolor5: "black",
                                    bgcolor1: "rgb(21, 27, 38)",
                                    bgcolor2: "transparent",
                                    bgcolor3: "transparent",
                                    bgcolor4: "transparent",
                                    bgcolor5: "transparent"
                                })
                            }}>
                                <a style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}>Dashboard</a>

                            </div>
                            <div className="attendnav" onClick={() => {
                                this.setState({
                                    route: "Client",
                                    txtcolor1: "black",
                                    txtcolor2: "white",
                                    txtcolor3: "black",
                                    txtcolor4: "black",
                                    txtcolor5: "black",
                                    bgcolor1: "transparent",
                                    bgcolor2: "rgb(21, 27, 38)",
                                    bgcolor3: "transparent",
                                    bgcolor4: "transparent",
                                    bgcolor5: "transparent"
                                })
                            }} >
                                <a style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Clients</a>
                            </div>
                            <div className="attendnav" onClick={() => {
                                this.setState({
                                    route: "Assign",
                                    txtcolor1: "black",
                                    txtcolor2: "black",
                                    txtcolor3: "white",
                                    txtcolor4: "black",
                                    txtcolor5: "black",
                                    bgcolor1: "transparent",
                                    bgcolor2: "transparent",
                                    bgcolor3: "rgb(21, 27, 38)",
                                    bgcolor4: "transparent",
                                    bgcolor5: "transparent"
                                })

                            }} >
                                <a style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Assigning</a>
                            </div>
                            <div className="attendnav" onClick={() => {
                                this.setState({
                                    route: "Logs",
                                    txtcolor1: "black",
                                    txtcolor2: "black",
                                    txtcolor3: "black",
                                    txtcolor4: "white",
                                    txtcolor5: "black",
                                    bgcolor1: "transparent",
                                    bgcolor2: "transparent",
                                    bgcolor3: "transparent",
                                    bgcolor4: "rgb(21, 27, 38)",
                                    bgcolor5: "transparent",

                                })
                            }}>
                                <a style={{ color: this.state.txtcolor4, backgroundColor: this.state.bgcolor4 }}>Logs</a>
                            </div>
                            <div className="attendnav" onClick={() => {
                                this.setState({
                                    route: "settings",
                                    txtcolor1: "black",
                                    txtcolor2: "black",
                                    txtcolor3: "black",
                                    txtcolor4: "black",
                                    txtcolor5: "white",
                                    bgcolor1: "transparent",
                                    bgcolor2: "transparent",
                                    bgcolor3: "transparent",
                                    bgcolor4: "transparent",
                                    bgcolor5: "rgb(21, 27, 38)",
                                })
                            }}>
                                <a style={{ color: this.state.txtcolor5, backgroundColor: this.state.bgcolor5 }}>Settings</a>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.route == "DashBoard" &&
                        <div>
                            <div className="row">
                                <div className="col-xl-8 row">
                                    <div className="col-md-12">
                                        <div className="card border-0 bg-dark text-white mb-3 overflow-hidden">
                                            <div className="card-body" style={{ backgroundColor: "rgb(21, 27, 38)" }}>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4">
                                                        <div className="mb-3 text-white">
                                                            <b>PRESENT TOTAL CLIENTS</b>
                                                            <span className="ml-2">
                                                                <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Total sales" data-placement="top" data-content="Total clients report for the current month with insights of open and closed sales"></i>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <h2 className="mb-0"><span className="text-white">{this.state.data_totalSales.length}</span></h2>
                                                            <div className="ml-auto"><div id="total-sales-sparkline">
                                                            </div></div>
                                                        </div>
                                                        <div className="mb-3 text-white">
                                                            <i className="fa fa-caret-up"></i> <span data-animation="number" data-value="33.21">{this.state.last_month}</span> compare to last month
									                </div>
                                                        <hr className="bg-white-transparent-2" />
                                                        <div className="row text-truncate">
                                                            <div className="col-6">
                                                                <div className="f-s-12 text-white">Open Sales</div>
                                                                <div className="row">
                                                                    <div className="col-md-6 f-s-18 m-b-5 f-w-600 p-b-1" data-animation="number">{this.state.open_accom_details.length}</div>
                                                                    <div className="col-md-6 text-right number text-orange-lighter"><span>{this.state.open_percent}&nbsp;</span>%</div>
                                                                </div>

                                                                <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                                                                    <div className="progress-bar progress-bar-striped rounded-right bg-orange" data-animation="width" data-value="55%" style={{ width: this.state.open_percent + '%' }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="f-s-12 text-white">Closed Sales</div>
                                                                <div className="row">
                                                                    <div className="col-md-6 f-s-18 m-b-5 f-w-600 p-b-1"><span data-animation="number" data-value="41.20">{this.state.close_accom_details.length}</span></div>
                                                                    <div className="number text-green-lighter col-md-6 text-right "><span>{this.state.close_percent} &nbsp;</span>%</div>
                                                                </div>

                                                                <div className="progress progress-xs rounded-lg bg-dark-darker m-b-5">
                                                                    <div className="progress-bar progress-bar-striped rounded-right bg-green" data-animation="width" data-value="55%" style={{ width: this.state.close_percent + '%' }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-8 col-lg-8">
                                                        <DLine3 lineGraph_val={this.state.lineGraph_val} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div class="widget widget-stats bg-green">
                                                    <div class="stats-info">
                                                        <h4>Total Bandwidth</h4>
                                                        <p className="text-white">{this.state.total_bandwith}&nbsp;<span>GB</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="widget widget-stats bg-blue">
                                                    <div class="stats-info">
                                                        <h4>Remaining Bandwidth</h4>
                                                        <p className="text-white">0</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="widget widget-stats bg-orange">
                                                    <div class="stats-info">
                                                        <h4>Sold Bandwith</h4>
                                                        <p className="text-white">{this.state.total_bandwith}&nbsp;<span>GB</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div class="widget widget-stats bg-red">
                                                    <div class="stats-info">
                                                        <h4>Total Sales</h4>
                                                        <p className="text-white">{this.state.total_sales}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="panel">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    Sales Personnels
                                    <span className="m-l-2">
                                                        <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Deployed employees for designated areas" data-placement="top" data-content="the table shows the list of sales personnels" data-original-title="" title="">
                                                        </i>
                                                    </span>
                                                </h4>
                                                <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
                                                    <div className="input-group pull-right">
                                                        <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                                        <span class="input-group-addon"><i className="fa fa-search"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-body">
                                                <Scrollbars style={{ height: 280, width: '100%' }}>
                                                    <table className="table table-hover" >
                                                        <thead className="tableFixHead">
                                                            <tr className="text-center">
                                                                <th className="bg-default">No.</th>
                                                                <th className="bg-default">ID</th>
                                                                <th className="bg-default">Name</th>
                                                                <th className="bg-default">Company</th>
                                                                {this.state.THead.map((val, index) => {
                                                                    return <th className="bg-default">{val.head}</th>
                                                                })}

                                                                {/* <th className="bg-default">Time-out</th> */}
                                                                <th className="bg-default">Assigned Location</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="bg-white text-center tablebordered">
                                                            {EmployeeSearch.map((val, index) => {
                                                                var data_date = []
                                                                return <tr className="f-w-700" style={{ cursor: 'pointer' }} key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{val.user_id}</td>
                                                                    <td>{val.completename}</td>
                                                                    <td>
                                                                        <div><p className="f-w-700">{val.branch_name}</p></div>

                                                                    </td>
                                                                    {this.state.THead.map((val1, index1) => {
                                                                        var timesheet = ""

                                                                        for (let index2 = 0; index2 < this.state.timesheetData.length; index2++) {

                                                                            if (val.user_id == this.state.timesheetData[index2].user_id) {

                                                                                if (this.state.timesheetData[index2].timesheet[index1] === undefined) {


                                                                                    timesheet = ""


                                                                                } else {

                                                                                    timesheet = moment(this.state.timesheetData[index2].timesheet[index1]).format('LT');

                                                                                    var time = { time_data: timesheet }
                                                                                    if (time != "") {
                                                                                        data_date.push(time);
                                                                                    }



                                                                                }

                                                                                // }

                                                                            }

                                                                        }
                                                                        return <td>{timesheet}</td>

                                                                    })
                                                                    }

                                                                    <td>


                                                                        <div className="button" style={{ position: 'absolute', marginLeft: '-25px', marginTop: 9 }}>
                                                                            <a className="aclass" onClick={() => {
                                                                                var data = { from: this.state.date_range_from, to: this.state.date_range_to, u_id: val.user_id, name: val.completename, contact: val.user_mobile, email: val.user_email, office: val.branch_name, company: val.company_name, position: val.user_jobposition, gender: val.user_gender, picture: val.user_pic }
                                                                                localStorage.setItem('sales_details', JSON.stringify(data));
                                                                                localStorage.setItem('timesheet', JSON.stringify(data_date));
                                                                                setTimeout(() => { this.setState({ route: 'Accomplishments' }) }, 100)
                                                                                // console.log(data)
                                                                            }}>
                                                                                View Details
                                                </a>
                                                                        </div></td>


                                                                </tr>
                                                            })}
                                                        </tbody>

                                                    </table> </Scrollbars>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4">
                                    <div className="col-md-12">
                                        <div className="card bg-dark  border-0 text-white mb-3">
                                            <div className="card-title text-center text-white ">Location Sales Per Month</div>
                                            <div className="card-body">
                                                <DPie3 pieGraph={this.state.pieGraph} />
                                                <div className="d-flex mb-2">

                                                    <div className="d-flex align-items-center ml-auto">
                                                        <div className="width-50 text-right pl-2 f-w-600  text-orange"><span data-animation="number">Open Sales</span></div>
                                                        <div className="width-50 text-right pl-2 f-w-600 text-green"><span data-animation="number">Closed sales</span></div>
                                                    </div>
                                                </div>
                                                {this.state.month_location.map((val, index) => {
                                                    // console.log(val)
                                                    return <div className="d-flex mb-2">
                                                        <div className="d-flex align-items-center">
                                                            <span>{val.location}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center ml-auto">
                                                            <div className="width-50 text-right pl-2 f-w-600 text-orange"><span data-animation="number">{(val.Open.length / (val.Open.length + val.Close.length) * 100).toFixed(2)}</span>%</div>
                                                            <div className="width-50 text-right pl-2 f-w-600 text-green"><span data-animation="number">{(val.Close.length / (val.Open.length + val.Close.length) * 100).toFixed(2)}</span>%</div>
                                                        </div>
                                                    </div>
                                                })}


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="panel">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">Sales Comparison Per Month</h4>
                                            </div>
                                            <div className="panel-body">
                                                <DBar3 barGraph={this.state.barGraph} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div >
                <div id="demo-lg-modal1" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Date-Range</h4>
                                <button className="bttnclose bg-red-lighter pull-right" data-dismiss="modal">
                                    <span className="fa fa-times-circle  "></span>
                                </button>
                            </div>
                            <form onSubmit={this.submitDate}>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>From date :</label>
                                            <br />
                                            <DatePicker
                                                selected={this.state.date_range_from}
                                                selectsStart
                                                startDate={this.state.date_range_from}
                                                endDate={this.state.date_range_to}
                                                onChange={this.filterDateStart}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>To date :</label>
                                            <br />
                                            <DatePicker
                                                selected={this.state.date_range_to}
                                                selectsEnd
                                                startDate={this.state.date_range_from}
                                                endDate={this.state.date_range_to}
                                                onChange={this.filterDateEnd}
                                                className="form-control"
                                            />
                                        </div>

                                    </div>
                                    <button type="submit"
                                        className="bttnsub bg-green-lighter pull-right m-t-25">
                                        <span className="fa fa-folder-plus "></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {
                    this.state.route == "Logs" &&
                    <Logs />
                }
                {
                    this.state.route == "Assign" &&
                    <Assign />
                }
                {
                    this.state.route == "Client" &&
                    <Client />
                }
                {
                    this.state.route == "Accomplishments" &&
                    <Accomplishments />
                }

                {
                    this.state.route == "settings" &&
                    <SaleSettings />
                }
            </div >
        );
    }
}

export default User;