import React, { Component } from 'react';
import {    
    HashRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";
import Validation from '.';

  class MoreRoute extends Component {
      state = {  }
      render() { 
          return ( 
              <Router>
                  <div> 
                  <Route path="/more/validation" component={Validation}/>
                  </div>
              </Router>
           );
      }
  }
   
  export default MoreRoute;