import React, { Component } from 'react'

class Counters extends Component {
  state = {
    selectedItems: [],
    selectingItems: [],
  }

  handleSelecting = selectingItems => {
    if (selectingItems.length !== this.state.selectingItems.length) {
      this.setState({ selectingItems })
    }
  }

  handleSelectionFinish = selectedItems => {
    this.setState({
      selectedItems,
      selectingItems: [],
    })

    // eslint-disable-next-line no-console
    console.log(`Finished selection ${selectedItems.length}`)
  }

  render() {
    const { selectedItems, selectingItems } = this.state

    return (
      <div>
        <p> <strong>Selecting: {' '} <span className="counter">{selectingItems.length}</span></strong></p>
        <p><strong>Selected:  {' '} <span className="counter">{selectedItems.length}</span></strong></p>
      </div>
    )
  }
}

export default Counters