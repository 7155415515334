import React, { Component } from 'react';
// import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
// import { fadeInRight } from 'react-animations';
// import Radium, { StyleRoot } from 'radium';
import { getData } from '../../../../api/api';
import GoogleMapReact from 'google-map-react';
// import pockethr from './img/pockethr.png'
// const styles = {
//     fadeInRight: {
//         animation: 'x 0.5s',
//         animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
//     }
// }
const AnyReactComponent = ({ text, date, start, end, status, details }) => <div className="tooltipparent" style={{ cursor: 'pointer' }}>
    {/* <div style={{height:'50px',wispanh:'50px',backgroundColor:'blue'}}> */}
    {/* {text} */}
    <img src={require('../../img/pointer.png')} style={{ height: '30px', wispanh: '30px' }} />
    <span className="tooltiptextbody">
        <p>
            LOCATION:  {text}<br />
            STATUS: {status}<br />
            DATE ApED: {date}<br />
            START-TIME: {start}<br />
            END-TIME: {end}
        </p>
    </span>
    {/* </div> */}
</div>;
class Accomplishments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date().toLocaleString(),
            refresh: false,
            // hours: '',
            // minutes: '',
            // seconds: '',
            from: new Date(),
            to: new Date(),
            employee_details: [],
            accom_details: [],
            open_accom_details: [],
            close_accom_details: [],
            data_map: [],
            mapoption: {
                center: {
                    lat: 13.7565,
                    lng: 121.0583
                },
                zoom: 14,
            },
            user_id: '',
            name: '',
            contact: '',
            email: '',
            office: '',
            company: '',
            position: '',
            gender: '',
            picture: '',
            displayMarkers: [{
                lat: 13.7545,
                lng: 121.0531,
                text: "BSU",
                date: "2019-11-02",
                start: "08:00 AM",
                end: "09:00 AM",
                status: "Success"
            }],
            divwispanh: "100%",
            trans: "",
            time_data: [],
            customer_details: [],
            history: [],
            modaltitle: '',
            single_customer: []
        };
    }
    componentDidMount() {
        var details = JSON.parse(localStorage.getItem('sales_details'));
        var timesheet = JSON.parse(localStorage.getItem('timesheet'));
        console.log(details);

        this.setState({ time_data: timesheet, from: new Date(details['from']), employee_details: details, user_id: details['u_id'], name: details['name'], contact: details['contact'], email: details['email'], office: details['office'], position: details['position'], company: details['company'], gender: details['gender'], picture: details['picture'] })
        this.setState({ to: new Date(details['to']) })
        this.getsalesDetails(details);

    }

    getsalesDetails(details) {

        getData("users/get_sales_data", details)
            // console.log(details)
            .then(response => {

                var open_accom_details = []
                var close_accom_details = []
                for (let index = 0; (index < response.data.length && response.data.length > 0); index++) {
                    if (response.data[index].cust_status == 'Open') {
                        open_accom_details.push(response.data[index])
                    } else if (response.data[index].cust_status == 'Closed') {
                        close_accom_details.push(response.data[index])
                    }
                }
                this.setState({ accom_details: response.data, data_map: response.data, open_accom_details: open_accom_details, close_accom_details: close_accom_details })
            })
    }
    from_dateChange = e => {
        setTimeout(() => {
            this.setState({
                from: e
            });
            setTimeout(() => {

                var data = { from: this.state.from, to: this.state.to, u_id: this.state.user_id }
                this.getsalesDetails(data)

            }, 200)
        }, 100)


    }

    to_dateChange = e => {

        setTimeout(() => {
            this.setState({
                to: e
            });
        }, 100)
        setTimeout(() => {

            var data = { from: this.state.from, to: this.state.to, u_id: this.state.user_id }
            this.getsalesDetails(data)

        }, 200)

    }
    static defaultProps = {
        center: {
            lat: 14.5995,
            lng: 120.9842

        },
        zoom: 10,
    };

    getMapOptions = (maps) => {

        return {
            streetViewControl: true,
            scaleControl: true,
            fullscreenControl: true,
            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "on"
                }]
            }],
            gestureHandling: "greedy",
            disableDoubleClickZoom: false,

            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            },

            zoomControl: true,
            clickableIcons: false
        };
    }
    get_data_customers(data) {
        var data_customer = []
        data_customer.push(data)
        // this.state.customer_details.push(data)
        this.setState({ refresh: true, customer_details: data_customer })
        getData("users/get_history_accomplishments", { cust_id: data.cust_id })
            .then(response => {
                this.setState({ history: response.data })
                // this.setState({ refresh: true })

            })

    }

    get_single_customer(data) {
        let single_customer = []
        single_customer.push(data)
        this.setState({ single_customer: single_customer })
    }

    render() {
        return (
            <div >
                <div className="row ">
                    <div className="col-md-4 border">
                        <div className=" panel">
                            <div className="panel-heading">
                                <div className="panel-title d-flex">
                                    <div className="m-t-10">
                                        <img style={{ borderRadius: "50%", width: 60, height: 60 }}
                                            src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + this.state.picture)} />
                                    </div>

                                    <div className="m-t-10 m-l-20">
                                        <h4>{this.state.name}</h4>
                                        <p>
                                            {this.state.time_data.length > 0 &&
                                                <div className="col-sm-8 text-sm-left"><p className="m-b-1"><span className="bg-green-lighter text-white rounded-corner p-l-10 p-r-10">Present</span></p></div>
                                            }
                                            {this.state.time_data.length == 0 &&
                                                <div className="col-sm-8 text-sm-left"><p className="m-b-1"><span className="bg-red-lighter text-white rounded-corner p-l-10 p-r-10">Absent</span></p></div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <hr style={{ backgroundColor: '#b6c2c9e6' }} />
                            </div>
                            <div className="panel-body">
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Position:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.position}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Gender:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.gender}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Company:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.company}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Office:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.office}</p>
                                    </div>
                                </div>
                                {this.state.time_data.map((val, index) => {
                                    var num = index + 2;
                                    var time_type = ''
                                    if (num % 2 == 0) {
                                        time_type = 'Login Time'
                                    } else {
                                        time_type = 'Logout Time'
                                    }
                                    return <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                        <div className="col-sm-4 text-sm-right"><span>{time_type}:</span> </div>
                                        <div className="col-sm-8 text-sm-left"><p className="m-b-1">{val.time_data}</p> </div>
                                    </div>
                                })
                                }
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Contact Number:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.contact}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Contact Email:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.email}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Closed Sales:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.close_accom_details.length}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right">
                                        <span>Open Sales:</span>
                                    </div>
                                    <div className="col-sm-8 text-sm-left">
                                        <p className="m-b-1">{this.state.open_accom_details.length}</p>
                                    </div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right"> <span>Total Sales:</span></div>
                                    <div className="col-sm-8 text-sm-left"> <p className="m-b-1">{this.state.accom_details.length}</p></div>
                                </div>
                                <div className="row m-b-10" style={{ lineHeight: 1.5 }}>
                                    <div className="col-sm-4 text-sm-right"> <span>Accomplishments:</span></div>
                                    <div className="col-sm-8 text-sm-left">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated  bg-green-lighter" role="progressbar" style={{ width: (this.state.accom_details.length > 0 && (this.state.close_accom_details.length / this.state.accom_details.length * 100).toFixed(2)) + "%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{this.state.accom_details.length > 0 && (this.state.close_accom_details.length / this.state.accom_details.length * 100).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="panel">
                            {/* <div className="panel-heading">
                                <h4 className="panel-title">Clients</h4>
                            </div> */}
                            <div className="panel-body">
                                <Scrollbars className="noScrollY " style={{ height: 420 }}>

                                    <table className="table">
                                        <thead className="tableFixHead text-center">
                                            <tr>
                                                <th>Status</th>
                                                <th>Company</th>
                                                <th>Contact Person</th>
                                                <th>Building</th>
                                                {/* <th>Remarks</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {this.state.data_map.map((val, index) => {
                                                var statusColor = ''
                                                if (val.cust_status == 'Open') {
                                                    statusColor = '#F79F1F'
                                                } else if (val.cust_status == 'Closed') {
                                                    statusColor = '#009432'
                                                }
                                                return <tr key={index} style={{ cursor: 'pointer' }}>
                                                    <td style={{ fontSize: '12px', color: statusColor }}>{val.cust_status}</td>
                                                    <td>{val.cust_fname}</td>
                                                    <td>
                                                        <div className="button1"><p>{val.cust_contact_person}</p></div>
                                                        <div className="button" style={{ position: 'absolute', marginLeft: 230, marginTop: "-25px" }} onClick={() => {
                                                            this.get_data_customers(val);
                                                            this.setState({modaltitle: val.cust_fname})
                                                        }}>
                                                            <a className="aclass" style={{ marginLeft: 10 }} data-target="#show-location" data-toggle="modal" >
                                                                <b>Location</b>
                                                            </a>
                                                            <a className="aclass" data-target="#demo-lg-modal34" data-toggle="modal">
                                                                <b>View details</b>
                                                            </a>
                                                          
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div className="button1"><p>{val.cust_building_name}</p></div>
                                                  <div class="button"style={{ position: 'absolute', marginLeft: 100, marginTop: "-25px" }}>
                                                  <a className="aclass " style={{ marginLeft: 10 }} data-target="#show-location" data-toggle="modal" >
                                                                <b>Location</b>
                                                            </a>
                                                       
                                                  </div>
                                                         
                                                           

                                                    </td>
                                                    {/* <td><div className="button1">
                    {moment(val.accom_aped).format('MMM p, YYYY')}
                </div>
                </td> */}
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="demo-lg-modal4" className="modal fade w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                        <h4 className="m-t-15">{this.state.modaltitle}</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                </div>

                                {this.state.single_customer.map((val) => {
                                    console.log(val)
                                    return <div className="row">
                                        <div className="col-md-4">
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Status:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>{val.cust_status}</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Address:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>{val.cust_address}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Building:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>{val.cust_building_name}</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Total Floors:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>420</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Unit:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>15</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Contact Person:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>{val.cust_contact_person}</span>
                                                </div>
                                            </div>
                                            {/* <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Person Position:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span></span>
                                                    </div>
                                                </div> */}
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Contact Number:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>{val.cust_contact_mobile}</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ lineHeight: 2 }}>
                                                <div className="col-md-4">
                                                    <label className="pull-right">Email Address:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <span>{val.cust_contact_email}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                })}

                            </div>
                            <div className="modal-body" style={{ marginTop: "-10px" }}>
                                <Scrollbars style={{ height: 230, width: '100%' }}>
                                    <table className="table table-striped">
                                        <thead className="tableFixHead">
                                            <tr>
                                                <th className="bg-white" style={{ padding: 25 }}>SALE STATUS</th>
                                                <th className="bg-white" style={{ padding: 25 }}>SALES PERSONNEL</th>
                                                <th className="bg-white" style={{ padding: 25 }}>START TIME</th>
                                                <th className="bg-white" style={{ padding: 25 }}>END TIME</th>
                                                <th className="bg-white" style={{ padding: 25 }}>REMARKS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.history.map((val, index) => {
                                                var statusColor = ''
                                                if (val.cust_status == 'Open') {
                                                    statusColor = '#F79F1F'
                                                } else if (val.cust_status == 'Closed') {
                                                    statusColor = '#009432'
                                                }
                                                return <tr>
                                                    <td><center><a className=' text-white badge ' style={{ fontSize: '12px', backgroundColor: statusColor }}> {val.cust_status}</a></center></td>
                                                    <td>{val.cust_lname + ' ' + val.cust_fname}</td>
                                                    <td>{moment(val.accom_added).format('YYYY-MM-DD')}<br /> {moment(val.accom_added).format('LT')}</td>
                                                    <td>{moment(val.accom_end).format('YYYY-MM-DD')}<br />{moment(val.accom_end).format('LT')}</td>
                                                    <td>{val.accom_remarks}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="show-location" className="modal  w3-animatee-zoom" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Location</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                                {/* <button type="button" className="close pull-right" data-dismiss="modal"><i className="fas fa-lg fa-window-close text-success"></i></button> */}
                            </div>
                            <div className="modal-body" style={{ fontSize: 12 }}>
                                <div style={{ height: '75vh', wispanh: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyAz0n7-lqY4We_24Yk8QIjVNbnPX1fG8pU" }}
                                        center={this.state.mapoption.center}
                                        zoom={this.state.mapoption.zoom}
                                        options={this.getMapOptions}
                                    >
                                        {this.state.displayMarkers.map((val, index) => {


                                            return <AnyReactComponent
                                                key={index}
                                                lat={val.lat}
                                                lng={val.lng}
                                                text={val.text}
                                                date={val.date}
                                                start={val.start}
                                                end={val.end}
                                                status={val.status}
                                                details={val}
                                            // onClick={()=>this.getDetails()}
                                            />
                                        })}
                                    </GoogleMapReact>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Accomplishments;