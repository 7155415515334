import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { connect } from 'react-redux'
// import PAGE from'./employeeDetails'

// import console = require('console');
class LateSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employee: [],
            date_selected: [],
            date_highlight: [],
            branches: [],
            companies: [],
            employee_filter: [],
            on_type: '',
            user_id: '',
            date_late_deducted: [],
            loading:true

        }
        this.changeDay = this.changeDay.bind(this)
        this.dateLateDeduction = this.dateLateDeduction.bind(this)
    }
    user = () => {
        getData("users/Get_userPayrollSetting", { u_id: localStorage.getItem("u"), type: "admin", date: new Date() })
            .then(response => {
                console.log(response)
                this.setState({ employee: response.users, employee_filter: response.users,loading:false})
                this.props.dispatch({
                    type:'PayrollSettingEmployee',
                    data: response.users,

                })

            })
    }
    componentDidMount() {
        this.setState({loading:true})
        this.user()
        this.getCompanies()

    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
                this.props.dispatch({
                    type:'Company',
                    data:response.data
                })
            })
    }
    changeDay = (e) => {
        let date_select = { date: moment(e).format('YYYY-MM-DD') }
        let match = false;
        for (let index = 0; index < this.state.date_selected.length; index++) {

            if (moment(this.state.date_selected[index].date).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD')) {
                this.state.date_selected.splice(parseInt(index), 1);
                match = true
            }
        }
        if (!match) {
            this.state.date_selected.push(date_select)
        }
        this.setState({ refresh: true })
        setTimeout(() => {
            this.date_highlight()
        }, 200)

    }
    date_highlight = () => {
        let arrayDate = []
        for (let index = 0; index < this.state.date_selected.length; index++) {
            let date = new Date(this.state.date_selected[index].date);
            // this.state.dateData.push(date);
            arrayDate.push(date);

        }
        this.setState({ date_highlight: arrayDate })
    }
    getBranch() {
        console.log(this.state.company_selected )
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {
                this.setState({ branches: response.data, branch_selected: "select branch" })
            })
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, })
        setTimeout(() => { this.getBranch() }, 200)
    }
    showBYbranch = e => {
        e.preventDefault();
        this.setState({ branch_selected: e.target.value })
        let details = []
        for (let index = 0; index < this.state.employee.length; index++) {
            if (this.state.employee[index].branch_id == e.target.value) {
                details.push(this.state.employee[index])
            }

        }
        this.setState({ employee_filter: details })
    }
    _allOn() {
        let employee = []
        for (let index = 0; index < this.state.employee_filter.length; index++) {
            employee.push(this.state.employee_filter[index].user_id)
        }
        console.log(employee);
        getData("users/on_late", { user_id: localStorage.getItem("u"), employee_filter: employee, value: 0 })
            .then(response => {
                if (response) {
                    this.state.employee_filter.map((val, index) => {
                        val.late_settings = 0
                    })
                    this.state.employee.map((val, index) => {
                        this.state.employee_filter.map((val2, index2) => {
                            if (val.user_id == val2.user_id) {
                                val.late_settings = 0
                            }

                        })
                    })
                    alert('Success')
                }
                this.setState({ refresh: true })
            })

    }
    _allOff() {
        let employee = []
        for (let index = 0; index < this.state.employee_filter.length; index++) {
            employee.push(this.state.employee_filter[index].user_id)
        }
        console.log(employee);
        getData("users/on_late", { user_id: localStorage.getItem("u"), employee_filter: employee, value: 1 })
            .then(response => {
                if (response) {
                    this.state.employee_filter.map((val, index) => {
                        val.late_settings = 1
                    })
                    this.state.employee.map((val, index) => {
                        this.state.employee_filter.map((val2, index2) => {
                            if (val.user_id == val2.user_id) {
                                val.late_settings = 1
                            }
                        })
                    })
                    alert('Success')

                }
                this.setState({ refresh: true })
            })
    }
    employeeOn = (user_id, $value) => {
        let employee = []
        employee.push(user_id)
        getData("users/on_late", { user_id: localStorage.getItem("u"), employee_filter: employee, value: $value })
            .then(response => {
                console.log(response);
                if (response) {
                    this.state.employee_filter.map((val, index) => {
                        if (user_id == val.user_id) {
                            val.late_settings = $value
                        }

                    })
                    this.state.employee.map((val, index) => {

                        if (val.user_id == user_id) {
                            val.late_settings = $value
                        }

                    })
                    alert('Success')
                }
                this.setState({ refresh: true })
            })
    }
    dateLateDeduction = (e) => {
        e.preventDefault()
        console.log(this.state.date_selected);
        getData("users/dateLateDeduction", { hr_id: localStorage.getItem("u"), date_selected: this.state.date_selected, emp_id: this.state.user_id })
            .then(response => {
                if(response){
                    console.log(response)
                    this.fetch_lateDeduction(this.state.user_id) 
                    this.setState({ date_selected: [], date_highlight: [] })
                }
            
            })
    }

    fetch_lateDeduction = (user_id) => {

        console.log(this.state.date_selected);
        getData("users/fetch_lateDeduction", { emp_id: user_id })
            .then(response => {
                this.setState({ date_late_deducted: response })
             
            })
    }
    delete_day=(id)=>{
        console.log(id);
        getData("users/delete_date_late", { id: id })
            .then(response => {
                if(response){
                    this.fetch_lateDeduction(this.state.user_id)
                }
                console.log(response);
            })
    }

    render() {
        console.log(this.state.on_type);


        return (
            <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                {this.state.loading == true &&
                    <Loader  fullPage loading />
                }
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h5 style={{ fontWeight: 'bold' }}>Late Settings</h5>
                    <div>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} data-target="#demo-lg-modal-All" onClick={() => { this.setState({ on_type: 0 }) }} data-toggle="modal" className="fas fa-check">&nbsp;&nbsp;All On</button>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} data-target="#demo-lg-modal-All" onClick={() => { this.setState({ on_type: 1 }) }} data-toggle="modal" className="fas fa-times">&nbsp;&nbsp;All Off</button>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} data-target="#filter_employee_late" data-toggle="modal" className="fas fa-sliders-h">&nbsp;&nbsp;Filter</button>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} onClick={() => { this.setState({ employee_filter: this.state.employee }) }} className="fas fa-sync-alt">&nbsp;&nbsp;Refresh</button>
                    </div>

                </div>
                <Scrollbars style={{ height: 400 }}>
                    <table class="table table-bordered " style={{ fontSize: 12, marginTop: 3 }}>
                        <thead className="tableFixHead ">
                            <tr>
                                <th>No.</th>
                                <th>Employee ID</th>
                                <th>Employee Name</th>
                                <th>Company</th>
                                <th>Branch</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.employee_filter.map((val, index) => {
                                    
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>{val.user_id}</td>
                                        <td>{val.completename}</td>
                                        <td>{val.company_name}</td>
                                        <td>{val.branch_name}</td>
                                        <td>
                                            {val.late_settings == 0 ?
                                                <button style={{ height: 23, backgroundColor: 'green', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} onClick={() => { this.employeeOn(val.user_id, 1) }} className="fas fa-check">&nbsp;&nbsp;On</button> :
                                                <>
                                                    <button style={{ height: 23, backgroundColor: 'red', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} onClick={() => { this.employeeOn(val.user_id, 0) }} className="fas fa-check">&nbsp;&nbsp;Off</button>
                                                    <button style={{ height: 23, backgroundColor: '#3498db', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} onClick={() => { this.setState({ date_selected: [], date_highlight: [], user_id: val.user_id }); this.fetch_lateDeduction(val.user_id) }} data-target="#modal_deduction" data-toggle="modal" className="fas fa-calendar">&nbsp;&nbsp;Set Late</button>
                                                </>
                                            }

                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </Scrollbars>
                <div id="modal_deduction" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <form onSubmit={this.dateLateDeduction}>
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="panel-title">Late</h4>
                                    <button id='date_close' data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9">
                                        <span className="fa fa-times-circle "></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="panel">
                                        <div className="panel-body solid" >
                                            <div>
                                                <h5>Date</h5>
                                                <Scrollbars style={{ height: 200 }}>
                                                    <table class="table table-bordered " style={{ fontSize: 12, marginTop: 3 }}>
                                                        <thead className="tableFixHead ">
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Date</th>
                                                                
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.date_late_deducted.map((val, index) => {
                                                                    return <tr>
                                                                        <td style={{color:'#000'}}>{index + 1}</td>
                                                                       
                                                                <td style={{color:'#000'}}>{val.date_implement}</td>
                                                                <td> <button type='button'style={{ height: 23, backgroundColor: '#3498db', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} onClick={() => { this.delete_day(val.payroll_settings_id) }} className="fas fa-trash-alt">&nbsp;&nbsp;Delete</button></td>

                                                                       
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Scrollbars>
                                            </div>
                                            <br/>
                                            <h5>Select Date</h5>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <DatePicker
                                                        inline
                                                        selected={this.state.startDate}
                                                        highlightDates={this.state.date_highlight}
                                                        // onChange={this.handleChange}
                                                        onChange={this.changeDay}
                                                    // onMonthChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='col-md-4'>
                                                    <Scrollbars style={{ height: 250 }}>
                                                        {
                                                            this.state.date_selected.map((val, index) => {
                                                                return <div key={index}>
                                                                    <div style={{ backgroundColor: '#3498db', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                                                                        <h4 style={{ color: '#fff', textAlign: 'center' }}>{moment(val.date).format('YYYY-MM-DD')}</h4>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </Scrollbars>
                                                </div>

                                            </div>
                                            <br />
                                            <div className="pull-right">
                                                <button className="bttnpayroll bg-green-lighter" type='submit'>
                                                    <span className="fa fa-plus "></span>
                                                </button>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="filter_employee_late" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <form onSubmit={this._filter}>
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="panel-title">Filter</h4>
                                    <button id='filter_close' data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9">
                                        <span className="fa fa-times-circle "></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="panel">
                                        <div className="panel-body solid" >

                                            <label>Select Company</label>
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" onChange={this.showBYcompanies} required>
                                                    <option ></option>
                                                    {this.state.companies.map((val, index) =>
                                                        <option value={val.company_id}>{val.company_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Branch</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                                    <option>--select branch--</option>
                                                    <option></option>
                                                    {this.state.branches.map((branch, index) =>
                                                        <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="demo-lg-modal-All" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"><label>Late Settings</label></h4>
                                {/* <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to apply the late settings to all employee?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn bg-green-transparent-9 fa fa-check m-r-5" onClick={() => this.state.on_type == 0 ? this._allOn() : this._allOff()} data-dismiss="modal" aria-label="Close" >
                                    &nbsp;Apply
                                </button>
                                <button id='delete_close' className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                    &nbsp;Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}

function mapStateToProps(state) {
   
    return {
        ListEmployee: state.Payroll.late_employees,
       


    }
}
export default connect(mapStateToProps)(LateSettings);