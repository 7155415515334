import React, { Component } from 'react';
import DatePicker from "react-datepicker";

import ReactToPrint from 'react-to-print';
import 'intro.js/introjs.css';
import moment from 'moment';
import { getData } from '../../api/api';

import ngcexpress from './img/ngcexpress.png';

class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            // <div style={{borderTopStyle: "dashed", marginTop: 955, marginLeft: 125, marginRight: 125}}>
            <div>
                {this.props.dataPass.map((data, i) => {
                    let count_item = i + 1
                    return <> <div style={{ border: "solid", width: '70%', height: 'auto', margin: 20 }}>
                        <div style={{ padding: 15 }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>
                                    <img src={ngcexpress} style={{ width: "450px", height: "70px" }} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div>
                                        <h3>Trip Ticket</h3>
                                    </div>
                                    <div>
                                        <h3>No. {data.ticket_id}</h3>
                                    </div>

                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                    <h3>Date: {moment(data.ticket_date_sched, "YYYY-MM-DD").format('YYYY-MM-DD')}</h3>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                    <h3>Driver's Name: {data.ticket_driver}</h3>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                    <div>
                                        <h3>Plate No: {data.ticket_plate_no}</h3>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-bordered" style={{ tableLayout: "fixed", width: "100%", borderStyle: "solid",borderWidth: "thick", borderColor: "black" }}>
                                <thead>
                                    <tr>
                                        <th width="40%">Location</th>
                                        <th width="15%">Time</th>
                                        <th width="20%">Passengers No.</th>
                                        <th width="35%">JGCPC Representative</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data.ticket_from}</td>
                                        {/* <td style={{wordWrap: "break-word"}}>asdasdasdasoudhasuiodfnodsijf;oadasdasdasdasoudhasuiodfnodsijf;oadasdasdasdasoudhasuiodfnodsijf;oadsjfojas;odfjaso;difjoasdijfpoiasjdfoasjdfoiiADSPFOIJASDOFASDF</td> */}
                                        <td  style={{border: "solid 1px"}}>{moment(data.ticket_departure, "LT").format('LT')}</td>
                                        <td  style={{border: "solid 1px"}}></td>
                                        <td  style={{border: "solid 1px"}}></td>
                                    </tr>
                                    <tr>
                                        <td>{data.ticket_to}</td>
                                        {/* <td style={{wordWrap: "break-word"}}>asdasdasdasoudhasuiodfnodsijf;oadsjfoasdasdasdasoudhasuiodfnodsijf;oadasdasdasdasoudhasuiodfnodsijf;oadasdasdasdasoudhasuiodfnodsijf;oadjas;odfjaso;difjoasdijfpoiasjdfoasjdfoiiADSPFOIJASDOFASDF</td> */}
                                        <td  style={{border: "solid 1px"}}>{moment(data.ticket_arrival, "LT").format('LT')}</td>
                                        <td  style={{border: "solid 1px"}}></td>
                                        <td  style={{border: "solid 1px"}}></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <h5 className="text-right m-t-10">Powered By: Pacificweb Systems</h5>

                            </div>
                        </div>

                    </div>

                        {(count_item % 4 == 0) ? <> <div style={{ breakAfter: "page" }}>

                        </div></> : <div></div>

                        }

                    </>

                }


                )}
            </div>

        );
    }
}

class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Company: [],
            company: '',
            tripSelect: '',
            ticketOptions: [],
            selectedOptions: '',
            tripVehicles: [],
            tripLocation: [],
            startDate: new Date(),
            Time1: new Date(),
            Time2: new Date(),
            tripDriver: [],
            selectedVehicle: '',
            locationFrom: '',
            locationTo: '',
            departureDriver: '',
            departureDriverID: '',
            arrivalDriver: '',
            printReady: [],


        };
        this.selectedCompany = this.selectedCompany.bind(this);
        this.selectedOptions = this.selectedOptions.bind(this);
        this.departureDriver = this.departureDriver.bind(this);
        this.addTrip = this.addTrip.bind(this);
    }

    handleChangeStart = e => {
        this.setState({
            startDate: e
        });
    }

    handleChangeTime1 = e => {
        this.setState({
            Time1: e
        });
    }
    handleChangeTime2 = e => {
        this.setState({
            Time2: e
        });
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }


    selectedCompany(e) {
        var options = e.target.options;
        var value = [];
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;

                value.push(options[i].value);
                if (options[i].value === "") {
                    this.setState({ company: '', tripSelect: '', ticketOptions: [] })
                    document.getElementsByName('tripSelect')[0].value = ''
                } else {
                    getData('field_audit/ticketOptions', options[i].value).then(result => {
                        this.setState({ ticketOptions: result })
                    })
                    this.vehicles(options[i].value);
                    this.location();
                    this.driver(options[i].value);
                    this.setState({ company: options[i].value })
                }
            }
        }
    }
    selectedOptions(e) {
        var options = e.target.options;
        var value = [];
        var data = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                data = options[i].innerHTML;
            }
        }

        this.setState({ selectedOptions: data });
    }

    departureDriver(e) {
        var options = e.target.options;
        var id = '';
        var data = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                data = options[i].innerHTML;
                id = options[i].value;
            }
        }
        this.setState({ departureDriver: data, departureDriverID: id });
    }

    vehicles(vehicles) {
        getData('field_audit/ticketVehicle', vehicles).then(result => {
            this.setState({ tripVehicles: result })
        })
    }

    location() {
        getData('field_audit/ticketLocation').then(result => {
            this.setState({ tripLocation: result })
        })
    }

    driver(driver) {
        getData('field_audit/ticketDriver', driver).then(result => {
            this.setState({ tripDriver: result })
        })
    }

    addTrip() {
        let data = {
            ticket_date_sched: moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'),
            ticket_departure: moment(this.state.Time1, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'),
            ticket_arrival: moment(this.state.Time2, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'),
            ticket_plate_no: this.state.selectedVehicle,
            ticket_from: this.state.locationFrom,
            ticket_to: this.state.locationTo,
            ticket_driver: this.state.departureDriver,
            creator_user_id: localStorage.getItem('u'),
            driver_user_id: this.state.departureDriverID,
            company_id: this.state.company

        }

        getData('field_audit/insertTicket', data).then(result => {
            let print = this.state.printReady;
            print.push(result);
            this.setState({ printReady: print })
        })
    }


    componentDidMount() {

        getData('users/getCompanies', localStorage.getItem("u")).then(response => {
            this.setState({ DATA: response.company });
            var data = [];
            for (let i = 0; i < response.company.length; i++) {
                var match = false;
                for (let index = 0; index < data.length; index++) {
                    if (data[index].companyName == response.company[i].company_name) {
                        match = true;
                    }
                }
                var data2 = [];
                if (match == false) {
                    data2 = {
                        companyName: response.company[i].company_name,
                        company_id: response.company[i].company_id
                    };
                    data.push(data2);
                }
            }
            this.setState({ Company: data });
        })
    }



    render() {
        return (
            <div className="content" id="content">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i className="far fa-building"></i>&nbsp;Ticket Generation</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Ticket&nbsp;Page</a></li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav">
                                {/* <div className="attendnav">
                                    <a href="#/department">Department</a>
                                </div>
                                <div className="attendnav">
                                    <a href="#/position">Position</a>
                                </div>
                                <div className="attendnav" >
                                    <a href="#/matrix">Salary&nbsp;Matrix</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="panel-body" style={{ fontFamily: 'arial' }}>
                        <ReactToPrint
                            trigger={() => <button className="btttn bg-green-transparent-9 fas fa-print">&nbsp;Job Order</button>}
                            content={() => this.componentRef}
                        />


                        <button className="btttn pull-left bg-green-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;Create Jay-Ow</button>

                        <div style={{ display: 'none' }}>
                            <ComponentToPrint ref={el => (this.componentRef = el)} dataPass={this.state.printReady} />

                        </div>



                        {this.state.printReady.map(data =>
                            <div style={{ border: "solid", width: '70%', height: 'auto', margin: 20 }}>
                                <div style={{ padding: 15 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div>
                                            <img src={ngcexpress} style={{ width: "450px", height: "100px" }} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div>
                                                <h3>No. {data.ticket_id}</h3>
                                            </div>
                                            <div>
                                                <h3>Trip Ticket</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                            <div>
                                                <h3>Date: {moment(data.ticket_date_sched, "YYYY-MM-DD").format('YYYY-MM-DD')}</h3>
                                            </div>
                                            <div>
                                                <h3>Driver's Name: {data.ticket_driver}</h3>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                            <div>
                                                <h3>Passengers In: ____________</h3>
                                            </div>
                                            <div>
                                                <h3>Passengers Out: ___________</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="bg-black-transparent-6 text-white" style={{ width: 50 }}>Location</th>
                                                <th className="bg-black-transparent-6 text-white" style={{ width: 25 }}>Time</th>
                                                <th className="bg-black-transparent-6 text-white" style={{ width: 25 }}>JGCPC Representative</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{data.ticket_from}</td>
                                                <td>{moment(data.ticket_departure, "LT").format('LT')}</td>
                                                <td style={{ borderBottomStyle: "solid" }}></td>
                                            </tr>
                                            <tr>
                                                <td>{data.ticket_to}</td>
                                                <td>{moment(data.ticket_arrival, "LT").format('LT')}</td>
                                                <td style={{ borderBottomStyle: "solid" }}></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        <h5 className="text-right m-t-10">Powered By: Pacificweb Systems</h5>

                                    </div>
                                </div>

                            </div>

                            // <div id="contentPrint" style={{ border: "solid", width: '800px', height: '325px', marginTop: '15px', marginLeft: '15px' }}>
                            //     <div className="col-md-12">
                            //         <div className="row">
                            //             <div className="col-md-6">
                            //                 <img src={ngcexpress} style={{ width: "350px", height: "100px" }} />
                            //             </div>
                            //             <div className="col-md-6">
                            //                 <div className="row">
                            //                     <div className="col-md-12" style={{ textAlign: 'right', margin: '5px' }}>
                            //                         <h3>No.  {data.ticket_id} </h3>
                            //                     </div>
                            //                 </div>
                            //                 <div className="row">
                            //                     <div className="col-md-12" style={{ textAlign: 'right' }}>
                            //                         <h3>Trip Ticket</h3>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>


                            //         <div className="row">
                            //             <div className="col-md-6">
                            //                 <div className="row">
                            //                     <h4>Date: {moment(data.ticket_date_sched, "YYYY-MM-DD").format('YYYY-MM-DD')}</h4>
                            //                 </div>
                            //                 <div className="row">
                            //                     <h4>Driver's Name: {data.ticket_driver}</h4>
                            //                 </div>
                            //             </div>
                            //             <div className="col-md-6">
                            //                 <div className="row">
                            //                     <h4>Passengers In : ____________</h4>
                            //                 </div>
                            //                 <div className="row">
                            //                     <h4>Passengers Out: ___________</h4>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //         <br></br>
                            //         <br></br>
                            //         <div className="row">
                            //             <div className="col-md-5">
                            //                 <div className="row">
                            //                     <h4>Location:</h4>
                            //                 </div>

                            //             </div>
                            //             <div className="col-md-3">
                            //                 <div className="row">
                            //                     <div className="col-md-12" style={{ textAlign: 'center' }}>
                            //                         <h4>Time:</h4>
                            //                     </div>
                            //                 </div>

                            //             </div>
                            //             <div className="col-md-4">
                            //                 <div className="row">
                            //                     <h4>JGCPC Representative:</h4>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //         <div className="row">
                            //             <div className="col-md-5">
                            //                 <div className="row">
                            //                     <h4>{data.ticket_from}</h4>
                            //                 </div>
                            //                 <br></br>
                            //                 <div className="row">
                            //                     <h4>{data.ticket_to}</h4>
                            //                 </div>
                            //             </div>
                            //             <div className="col-md-3">
                            //                 <div className="row">
                            //                     <div className="col-md-12" style={{ textAlign: 'center' }}>
                            //                         <h4>{moment(data.ticket_departure, "LT").format('LT')}</h4>
                            //                     </div>
                            //                 </div>
                            //                 <br></br>
                            //                 <div className="row">
                            //                     <div className="col-md-12" style={{ textAlign: 'center' }}>
                            //                         <h4>{moment(data.ticket_arrival, "LT").format('LT')}</h4>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className="col-md-4">
                            //                 <div className="row">
                            //                     <h4>___________________</h4>
                            //                 </div>
                            //                 <br></br>
                            //                 <div className="row">
                            //                     <h4>___________________</h4>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //         <div className="row">
                            //             <div className="col-md-6">

                            //             </div>
                            //             <div className="col-md-6">
                            //                 <div className="row">
                            //                     <div className="col-md-12" style={{ textAlign: 'right', margin: '5px' }}>
                            //                         <h5>Powered By: Pacificweb Systems</h5>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>

                            //     </div>

                            // </div>
                        )}
                    </div>
                    <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Delete User</h4>
                                    <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* <form onSubmit={this.submitDept}> */}
                                    {/* <form> */}
                                    <fieldset>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Company</label>
                                            <select className="form-control" onChange={this.selectedCompany} name="selectedCompany">
                                                <option value=""></option>
                                                {this.state.Company.map((val, index) =>
                                                    <option value={val.company_id} name={val.companyName}>{val.companyName}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Option</label>
                                            <select className="form-control" onChange={this.selectedOptions} name="tripSelect">
                                                <option value=""></option>
                                                {this.state.ticketOptions.map((val, index) =>
                                                    <option value={val.category_details} name={val.category_details}>{val.category_details}</option>
                                                )}
                                            </select>
                                        </div>

                                        {this.state.selectedOptions === "NGC Express Trip Ticket" ?
                                            <div>
                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    onChange={this.handleChangeStart}
                                                    className="form-control"
                                                />
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Select Vehicle Plate No.</label>
                                                    <select className="form-control" onChange={this.textchange} name="selectedVehicle">
                                                        <option value=""></option>
                                                        {this.state.tripVehicles.map((val, index) =>
                                                            <option value={val.vehicle_plate_no} name={val.vehicle_plate_no}>{val.vehicle_plate_no}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">From: </label>
                                                    <select className="form-control" onChange={this.textchange} name="locationFrom">
                                                        <option value=""></option>
                                                        {this.state.tripLocation.map((val, index) =>
                                                            <option value={val.category_details} name={val.category_details}>{val.category_details}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">To: </label>
                                                    <select className="form-control" onChange={this.textchange} name="locationTo">
                                                        <option value=""></option>
                                                        {this.state.tripLocation.map((val, index) =>
                                                            <option value={val.category_details} name={val.category_details}>{val.category_details}</option>
                                                        )}
                                                    </select>
                                                </div>

                                                <DatePicker
                                                    selected={this.state.Time1}
                                                    onChange={this.handleChangeTime1}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                />

                                                <DatePicker
                                                    selected={this.state.Time2}
                                                    onChange={this.handleChangeTime2}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                />


                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Driver's Name: </label>
                                                    <select className="form-control" onChange={this.departureDriver} name="departureDriver">
                                                        <option value=""></option>
                                                        {this.state.tripDriver.map((val, index) =>
                                                            <option value={val.user_id}>{val.user_fname + " " + val.user_lname}</option>
                                                        )}
                                                    </select>
                                                </div>

                                                <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" onClick={this.addTrip}>
                                                &nbsp;Create
                                        </button>


                                            </div>
                                            :
                                            <div> </div>
                                        }

                                      
                                    </fieldset>
                                    {/* </form> */}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Tickets;