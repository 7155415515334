import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import moment from 'moment';

class Field_assign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: [],
            employee: [],
            id: "",
            Total: "",
            startDate: new Date(),
            company: ''
        }
        this.passBranch = this.passBranch.bind(this);
        this.submitAssign = this.submitAssign.bind(this);
        this.textchange = this.textchange.bind(this);

    }


    branch() {
        getData('tracking/getMonitoringAccess').then(result => {
            this.setState({ branch: result });
        })
    }

    locationweb(data){
        getData("tracking/trackEmployeesLocationWeb", data)
        .then(result => {
            console.log(result);
            this.setState({employee: result});
            // result.forEach(element => {
            //     element['jo_assign_id'] = '0';
            //     element['jo_count'] = '0';
            //     element['jo_date_assign'] = '0000-00-00 00:00:00';
            // });
            // getData("tracking/trackAssignCount", data).then(assign =>{
            //   for(let i=0; i<result.length; i++){
            //       for(let x=0; x<assign.length; x++){
            //         if(result[i].user_id === assign[x].user_id){
            //             let dataResult = [];
            //             dataResult.push(result[i]);
            //             dataResult.forEach(element => {
            //                 element['jo_assign_id'] = assign[x].jo_assign_id;
            //                 element['jo_count'] = assign[x].jo_count;
            //                 element['jo_date_assign'] = assign[x].jo_date_assign;
            //             });
            //         }
            //       }
            //   }
            //   this.setState({employee: result});
            // })
        });
    }

    passBranch(e) {
        var options = e.target.options;
        var company = ""
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                company = options[i].value;
            }
        }
        let selectDate = moment(this.state.startDate, "YYYY-MM-DD").format('YYYY-MM-DD')

        var data = {
            'parameter': 'branch_id',
            'selection': company,
            'selected_date': selectDate
        }

        this.setState({company: company});
        this.locationweb(data);
    }

    submitAssign() {
        let id = this.state.id
        let total = this.state.Total
        let date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
        let data = {
            'user_id': id,
            'jo_count': total,
            'jo_date_assign': date,
            'branch_id': this.state.company
        }

        getData('tracking/trackEmployeesAssign', data).then(result =>{
            if(result.res === "Success"){
                let selectDate = moment(this.state.startDate, "YYYY-MM-DD").format('YYYY-MM-DD')
                var data = {
                    'parameter': 'branch_id',
                    'selection': this.state.company,
                    'selected_date': selectDate
                }
                this.locationweb(data);
                let employee = this.state.employee;
                document.getElementsByName('Total')[0].value = ''
            
                this.setState({employee: employee, Total: ""});
            }
        })


    }

    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChangeStart = e => {
        this.setState({
            startDate: e
        });
        document.getElementsByName('selectedBranch')[0].value = ''
    }


    componentDidMount() {
        this.branch();

    }

    render() {
        return (
            <div id="content" className="content">

                <DatePicker
                    selected={this.state.startDate}
                    selectsStart
                    startDate={this.state.startDate}
                    onChange={this.handleChangeStart}
                    className="form-control"
                />

                <div className="form-group">
                    <label for="exampleInputBranch">Select Branch</label>
                    <select className="form-control" onChange={this.passBranch} name="selectedBranch">
                        <option value="">----Branch----</option>
                        {this.state.branch.map((val, index) =>
                            <option value={val.b_id} name={val.b_id}>{val.branch_name}</option>
                        )}
                    </select>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Fullname</th>
                            <th>Asssigned</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.employee.map(data =>
                            <tr>
                                <td>{data.completename}</td>
                                <td>{data.jo_count}</td>
                                <td><button data-target="#demo-lg-modal" data-toggle="modal" onClick={() => {
                                    this.setState({ id: data.user_id })
                                }}>Assign</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>


                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Job Order Count</h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div class="form-group">
                                    <label>Total Jo</label>
                                    <input type="text" class="form-control" name="Total" onChange={this.textchange} />
                                </div>
                                <button className="btttn pull-left bg-green-transparent-9" onClick={this.submitAssign}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}


export default Field_assign;