import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import ReactDOM from 'react-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class jobForm2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: "",
            datehired: "",
            salary: "",

            fname: "",
            mname: "",
            lname: "",

            presentadd: "",
            permaadd: "",
            email: "",
            mobileno: "",
            phoneno: "",

            nationality: "",
            religion: "",
            marital: "",

            empbday: "",
            sex: "",
            empage: "",
            height: "",
            weight: "",

            sss: "",
            pagibig: "",
            philhealth: "",
            tin: "",

            spouse: "",
            spouseadd: "",
            spouseno: "",

            dependent: "",
            dependentbday: "",
            dependentoccu: "",

            father: "",
            fatherbday: "",
            fatheroccu: "",
            mother: "",
            motherbday: "",
            motheroccu: "",

            sibling: "",
            siblingbday: "",
            siblingage: "",
            siblingoccu: "",

            post: "",
            postcourse: "",
            postyear: "",
            college: "",
            collegecourse: "",
            collegeyear: "",
            vocational: "",
            vocationalcourse: "",
            vocationalyear: "",
            hs: "",
            hscourse: "",
            hsyear: "",

            contact: "",
            contactno: "",
            contactrelation: "",

            dependents: [],
            siblings: [],

            dependentsData: [],
            siblingsData: [],
            employeeProfile: [],
            profileDetails: [],
            indexval: 0,

            refresh: false
        }
        this.textchange = this.textchange.bind(this);
    }

    textchange = e => {
        if (
            e.target.name == "dependent"
        ) {
            this.state.dependentsData[this.state.indexval].dependent = e.target.value
        } else if (
            e.target.name == "dependentbday"
        ) {
            this.state.dependentsData[this.state.indexval].dependentbday = e.target.value
        } else if (
            e.target.name == "dependentoccu"
        ) {
            this.state.dependentsData[this.state.indexval].dependentoccu = e.target.value
        } else if (
            e.target.name == "sibling"
        ) {
            this.state.siblingsData[this.state.indexval].sibling = e.target.value
        } else if (
            e.target.name == "siblingbday"
        ) {
            this.state.siblingsData[this.state.indexval].siblingbday = e.target.value
        } else if (
            e.target.name == "siblingage"
        ) {
            this.state.siblingsData[this.state.indexval].siblingage = e.target.value
        } else if (
            e.target.name == "siblingoccu"
        ) {
            this.state.siblingsData[this.state.indexval].siblingoccu = e.target.value
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }

    }

    appenddependents() {
        var dependentsData = { dependent: "", dependentbday: "", dependentoccu: "" }
        this.state.dependentsData.push(dependentsData)
        this.state.dependents.push(<div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20"> <label className="col-form-label col-md-3">Dependent:</label><div className="col-md-9"> <input name="dependent" type="text" className="form-control m-b-5" placeholder="Employee' Dependent Name" /></div> <label className="col-form-label col-md-3">Dependent's Birthdate:</label><div className="col-md-9"> <input name="dependentbday" type="date" className="form-control m-b-5" placeholder="Select Date" /></div> <label className="col-form-label col-md-3">Occupation:</label><div className="col-md-9"> <input name="dependentoccu" type="text" className="form-control m-b-5" placeholder="Dependent's Occupation" /></div></div>)
        this.setState({ refresh: true })
    }

    adddependents() {
        console.log(this.state.dependents)
        var array = []
        for (let index = 0; index < this.state.dependents.length; index++) {
            array.push(
                <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                    <label className="col-form-label col-md-3">Dependent:</label>
                    <div className="col-md-9">
                        <input name="dependent" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 + 1 })} type="text" className="form-control m-b-5" placeholder="Employee' Dependent Name" />
                    </div>
                    <label className="col-form-label col-md-3">Dependent's Birthdate:</label>
                    <div className="col-md-9">
                        <input name="dependentbday" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 + 1 })} type="date" className="form-control m-b-5" placeholder="Select Date" />
                    </div>
                    <label className="col-form-label col-md-3">Occupation:</label>
                    <div className="col-md-9">
                        <input name="dependentoccu" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 + 1 })} type="text" className="form-control m-b-5" placeholder="Dependent's Occupation" />
                    </div>
                </div>)
        }
        return array
    }

    subdependents() {
        this.state.dependentsData.splice(parseInt(this.state.dependents.length - 1), 1)
        this.state.dependents.splice(parseInt(this.state.dependents.length - 1), 1)
        this.setState({ refresh: true })
    }

    appendsiblings() {
        var siblingsData = { sibling: "", siblingbday: "", siblingage: 0, siblingoccu: "" }
        this.state.siblingsData.push(siblingsData)
        this.state.siblings.push(<div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20"> <label className="col-form-label col-md-3">Sibling's Name:</label><div className="col-md-9"> <input name="sibling" type="text" className="form-control m-b-5" placeholder="Sibling's Full Name" required /></div> <label className="col-form-label col-md-3">Sibling's Birthdate:</label><div className="col-md-9"> <input name="siblingbday" type="date" className="form-control m-b-5" placeholder="Select Date" required /></div> <label className="col-form-label col-md-3">Sibling's Age:</label><div className="col-md-9"> <input name="siblingage" type="numbere" className="form-control m-b-5" placeholder="Sibling's Age" required /></div> <label className="col-form-label col-md-3">Sibling's Occupation:</label><div className="col-md-9"> <input name="siblingoccu" type="text" className="form-control m-b-5" placeholder="Sibling's Occupation" /></div></div>)
        this.setState({ refresh: true })

    }

    addsiblings() {
        var array = []
        for (let index = 0; index < this.state.siblings.length; index++) {
            array.push(
                <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                    <label className="col-form-label col-md-3">Sibling's Name:</label>
                    <div className="col-md-9">
                        <input name="sibling" type="text" onClick={() => this.setState({ indexval: index + 1 })} className="form-control m-b-5" placeholder="Sibling's Full Name" required />
                    </div>
                    <label className="col-form-label col-md-3">Sibling's Birthdate:</label>
                    <div className="col-md-9">
                        <input name="siblingbday" type="date" onClick={() => this.setState({ indexval: index + 1 })} className="form-control m-b-5" placeholder="Select Date" required />
                    </div>
                    <label className="col-form-label col-md-3">Sibling's Age:</label>
                    <div className="col-md-9">
                        <input name="siblingage" type="numbere" onClick={() => this.setState({ indexval: index + 1 })} className="form-control m-b-5" placeholder="Sibling's Age" required />
                    </div>
                    <label className="col-form-label col-md-3">Sibling's Occupation:</label>
                    <div className="col-md-9">
                        <input name="siblingoccu" type="text" onClick={() => this.setState({ indexval: index + 1 })} className="form-control m-b-5" placeholder="Sibling's Occupation" />
                    </div>

                </div>


            )

        }
        return array

    }

    subsiblings() {
        this.state.siblings.splice(parseInt(this.state.siblings.length - 1), 1)
        this.setState({ refresh: true })
    }

    componentWillMount() {
        var siblingsData = { sibling: "", siblingbday: "", siblingage: 0, siblingoccu: "" }
        this.state.siblingsData.push(siblingsData)

        var dependentsData = { dependent: "", dependentbday: "", dependentoccu: "" }
        this.state.dependentsData.push(dependentsData)


    }

    render() {
        console.log(this.state.profileDetails)
        // console.log(this.state.indexval)

        return (
            <div id="content" className="content" >
                <div className="row text-center " style={{ marginTop: "-40px", zIndex: 1, overflow: 'hidden', position: "fixed", width: '80%' }}>
                    <AnchorLink offset='110' href="#position" className="bg-warning" style={{ padding: 15 }}><i className="fa fa-user-tie p-r-5 fa-lg text-white"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#empname" className="bg-yellow" style={{ padding: 15 }}><i className="fa fa-user-circle text-white p-r-5 fa-lg"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#empcont" className="bg-lime" style={{ padding: 15 }}><i className="fa fa-address-book fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#nation" className="bg-green" style={{ padding: 15 }}><i className="fab fa-font-awesome-flag fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#empdet" className="bg-primary" style={{ padding: 15 }}><i className="fa fa-user-tag p-r-5 fa-lg text-white"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#idno" className="bg-info" style={{ padding: 15 }}><i className="fa fa-id-card text-white p-r-5 fa-lg"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#spouse" className="bg-pink" style={{ padding: 15 }}><i className="fa fa-heart fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#dependent" className="bg-danger" style={{ padding: 15 }}><i className="fa fa-hands-helping fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#parent" className="bg-indigo" style={{ padding: 15 }}><i className="fa fa-user-friends fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#sibling" className="bg-purple" style={{ padding: 15 }}><i className="fa fa-baby fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#educ" className="bg-secondary" style={{ padding: 15 }}><i className="fa fa-graduation-cap fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                    <AnchorLink offset='110' href="#contperson" className="bg-dark" style={{ padding: 15 }}><i className="fa fa-phone-volume fa-lg text-white p-r-5"></i><label></label></AnchorLink>
                </div><br /><br />
                <div className="row" >
                    <div className="col-sm-6">
                        <h1 className="float-left page-header">Employee Form</h1>
                    </div>
                    <div className="col-sm-6">

                    </div>
                </div>


                <form>

                    <div>
                        <section id="position">
                            <div className="row" >
                                <div className="col-md-3 bg-warning">
                                    <div className="text-center"><span className="fa fa-user-tie fa-5x text-white" style={{ marginTop: 40 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Employee Position:</label>
                                            <div className="col-md-9">
                                                <input name="position" onChange={this.textchange} value={this.state.position} type="text" className="form-control m-b-5" placeholder="Company Position" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Date Hired/Start:</label>
                                            <div className="col-md-9">
                                                <input name="datehired" onChange={this.textchange} value={this.state.datehired} type="date" className="form-control m-b-5" placeholder="Select Date" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Employee Salary:</label>
                                            <div className="col-md-9">
                                                <input name="salary" onChange={this.textchange} value={this.state.salary} type="number" className="form-control m-b-5" placeholder="Expected Salary" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="empname">
                            <div className="row" >
                                <div className="col-md-3 bg-yellow">
                                    <div className="text-center"><span className="fa fa-user-circle fa-5x text-white" style={{ marginTop: 40 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee Name</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">First Name:</label>
                                            <div className="col-md-9">
                                                <input name="fname" onChange={this.textchange} value={this.state.fname} type="text" className="form-control m-b-5" placeholder="Employee First Name" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Middle Name:</label>
                                            <div className="col-md-9">
                                                <input name="mname" onChange={this.textchange} value={this.state.mname} type="text" className="form-control m-b-5" placeholder="Employee Middle Name" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Last Name:</label>
                                            <div className="col-md-9">
                                                <input name="lname" onChange={this.textchange} value={this.state.lname} type="text" className="form-control m-b-5" placeholder="Employee Last Name" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />


                        <section id="empcont">
                            <div className="row" >
                                <div className="col-md-3 bg-lime">
                                    <div className="text-center"><span className="fa fa-address-book fa-5x text-white" style={{ marginTop: 80 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee Contact Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Email Address:</label>
                                            <div className="col-md-9">
                                                <input name="email" onChange={this.textchange} value={this.state.email} type="email" className="form-control m-b-5" placeholder="Contact Email Address" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Present Address:</label>
                                            <div className="col-md-9">
                                                <textarea name="presentadd" onChange={this.textchange} value={this.state.presentadd} type="text" className="form-control m-b-5" placeholder="Present Address" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Permanent Address:</label>
                                            <div className="col-md-9">
                                                <textarea name="permaadd" onChange={this.textchange} value={this.state.permaadd} type="text" className="form-control m-b-5" placeholder="Permanent Address" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Mobile Number:</label>
                                            <div className="col-md-9">
                                                <input name="mobileno" onChange={this.textchange} value={this.state.mobileno} type="number" className="form-control m-b-5" placeholder="Contact Mobile Number" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Phone Number:</label>
                                            <div className="col-md-9">
                                                <input name="phoneno" onChange={this.textchange} value={this.state.phoneno} type="number" className="form-control m-b-5" placeholder="Contact Phone Number" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="nation">
                            <div className="row">
                                <div className="col-md-3 bg-green">
                                    <div className="text-center"><span className="fab fa-font-awesome-flag fa-5x text-white" style={{ marginTop: 40 }}><p style={{ fontSize: 14, marginTop: 10 }}>Personal Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Nationality:</label>
                                            <div className="col-md-9">
                                                <input name="nationality" onChange={this.textchange} value={this.state.nationality} type="text" className="form-control m-b-5" placeholder="Employee Nationality" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Religion:</label>
                                            <div className="col-md-9">
                                                <input name="religion" onChange={this.textchange} value={this.state.religion} type="text" className="form-control m-b-5" placeholder="Employee Religion" />
                                            </div>
                                            <label className="col-form-label col-md-3">Marital Status:</label>
                                            <div className="col-md-9">
                                                <select name="marital" onChange={this.textchange} value={this.state.marital} className="form-control" required>
                                                    <option>--Select Current Status--</option>
                                                    <option>Married</option>
                                                    <option>Widowed</option>
                                                    <option>Separated</option>
                                                    <option>Divorced</option>
                                                    <option>Single</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="empdet">
                            <div className="row">
                                <div className="col-md-3 bg-primary">
                                    <div className="text-center"><span className="fa fa-user-tag fa-5x text-white" style={{ marginTop: 70 }}><p style={{ fontSize: 14, marginTop: 10 }}>Personal Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Birthdate:</label>
                                            <div className="col-md-9">
                                                <input name="empbday" onChange={this.textchange} value={this.state.empbday} type="date" className="form-control m-b-5" placeholder="Select Date" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Sex:</label>
                                            <div className="col-md-9">
                                                <select name="sex" onChange={this.textchange} value={this.state.sex} className="form-control m-b-5" required>
                                                    <option>--Select Employee Sex--</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select>
                                            </div>
                                            <label className="col-form-label col-md-3">Age:</label>
                                            <div className="col-md-9">
                                                <input name="empage" onChange={this.textchange} value={this.state.empage} type="number" className="form-control m-b-5" placeholder="Employee Age" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Height:</label>
                                            <div className="col-md-9">
                                                <input name="height" onChange={this.textchange} value={this.state.height} type="number" className="form-control m-b-5" placeholder="Employee Height" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Weight:</label>
                                            <div className="col-md-9">
                                                <input name="weight" onChange={this.textchange} value={this.state.weight} type="number" className="form-control m-b-5" placeholder="Employee Weight" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="idno">
                            <div className="row">
                                <div className="col-md-3 bg-info">
                                    <div className="text-center"><span className="fa fa-id-card fa-5x text-white" style={{ marginTop: 60 }}><p style={{ fontSize: 14, marginTop: 10 }}>Government IDs</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">SSS Number:</label>
                                            <div className="col-md-9">
                                                <input name="sss" onChange={this.textchange} value={this.state.sss} type="text" className="form-control m-b-5" placeholder="SSS Number" required />
                                            </div>
                                            <label className="col-form-label col-md-3">PAG-IBIG Number:</label>
                                            <div className="col-md-9">
                                                <input name="pagibig" onChange={this.textchange} value={this.state.pagibig} type="text" className="form-control m-b-5" placeholder="PAG-IBIG Number" required />
                                            </div>
                                            <label className="col-form-label col-md-3">PHILHEALTH Number:</label>
                                            <div className="col-md-9">
                                                <input name="philhealth" onChange={this.textchange} value={this.state.philhealth} type="text" className="form-control m-b-5" placeholder="PHILHEALTH Number" required />
                                            </div>
                                            <label className="col-form-label col-md-3">TIN Number:</label>
                                            <div className="col-md-9">
                                                <input name="tin" onChange={this.textchange} value={this.state.tin} type="text" className="form-control m-b-5" placeholder="TIN Number" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="spouse">
                            <div className="row">
                                <div className="col-md-3 bg-pink">
                                    <div className="text-center"><span className="fa fa-heart fa-5x text-white" style={{ marginTop: 50 }}><p style={{ fontSize: 14, marginTop: 10 }}>Spouse Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Spouse Name:</label>
                                            <div className="col-md-9">
                                                <input name="spouse" onChange={this.textchange} value={this.state.spouse} type="text" className="form-control m-b-5" placeholder="Fill up by Married Employee Only!" />
                                            </div>
                                            <label className="col-form-label col-md-3">Spouse's Present Address</label>
                                            <div className="col-md-9">
                                                <textarea name="spouseadd" onChange={this.textchange} value={this.state.spouseadd} className="form-control m-b-5" rows="2" placeholder="Fill up by Married Employee Only!"></textarea>
                                            </div>
                                            <label className="col-form-label col-md-3">Contact Number:</label>
                                            <div className="col-md-9">
                                                <input name="spouseno" onChange={this.textchange} value={this.state.spouseno} type="number" className="form-control m-b-5" placeholder="Fill up by Married Employee Only!" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="dependent">
                            <div className="row">
                                <div className="col-md-3 bg-danger">
                                    <div className="text-center"><span className="fa fa-hands-helping fa-5x text-white" style={{ marginTop: 40 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee' Dependent</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">

                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Dependent:</label>
                                            <div className="col-md-9">
                                                <input name="dependent" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} type="text" className="form-control m-b-5" placeholder="Employee' Dependent Name" />
                                            </div>
                                            <label className="col-form-label col-md-3">Dependent's Birthdate:</label>
                                            <div className="col-md-9">
                                                <input name="dependentbday" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} type="date" className="form-control m-b-5" placeholder="Select Date" />
                                            </div>
                                            <label className="col-form-label col-md-3">Occupation:</label>
                                            <div className="col-md-9">
                                                <input name="dependentoccu" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} type="text" className="form-control m-b-5" placeholder="Dependent's Occupation" />
                                            </div>
                                        </div>

                                        {this.adddependents()}
                                    </div>
                                    <div className="row m-b-20">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6">
                                            <div className=" pull-right">
                                                <button className="btttn bg-green-transparent-9 m-r-5" onClick={() => this.appenddependents()} >Increase Dependents</button>
                                                <button className="btttn bg-red-transparent-9" onClick={() => this.subdependents()}>Reduce Dependents</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="parent">
                            <div className="row">
                                <div className="col-md-3 bg-indigo">
                                    <div className="text-center"><span className="fa fa-user-friends fa-5x text-white" style={{ marginTop: 100 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee Parents Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Father's Name:</label>
                                            <div className="col-md-9">
                                                <input name="father" onChange={this.textchange} value={this.state.father} type="text" className="form-control m-b-5" placeholder="Father's Name" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Father's Birthdate:</label>
                                            <div className="col-md-9">
                                                <input name="fatherbday" onChange={this.textchange} value={this.state.fatherbday} type="date" className="form-control m-b-5" placeholder="Select Date" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Father's Occupation:</label>
                                            <div className="col-md-9">
                                                <input name="fatheroccu" onChange={this.textchange} value={this.state.fatheroccu} type="text" className="form-control m-b-30" placeholder="Father's Occupation" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Mother's Name:</label>
                                            <div className="col-md-9">
                                                <input name="mother" onChange={this.textchange} value={this.state.mother} type="text" className="form-control m-b-5" placeholder="Mother's Name" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Mother's Birthdate:</label>
                                            <div className="col-md-9">
                                                <input name="motherbday" onChange={this.textchange} value={this.state.motherbday} type="date" className="form-control m-b-5" placeholder="Select Date" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Mother's Occupation:</label>
                                            <div className="col-md-9">
                                                <input name="motheroccu" onChange={this.textchange} value={this.state.motheroccu} type="text" className="form-control m-b-5" placeholder="Mother's Occupation" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="sibling">
                            <div className="row">
                                <div className="col-md-3 bg-purple">
                                    <div className="text-center"><span className="fa fa-baby fa-5x text-white" style={{ marginTop: 50 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee Siblings Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Sibling's Name:</label>
                                            <div className="col-md-9">
                                                <input name="sibling" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} value={this.state.sibling} type="text" className="form-control m-b-5" placeholder="Sibling's Full Name" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Sibling's Birthdate:</label>
                                            <div className="col-md-9">
                                                <input name="siblingbday" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} value={this.state.siblingbday} type="date" className="form-control m-b-5" placeholder="Select Date" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Sibling's Age:</label>
                                            <div className="col-md-9">
                                                <input name="siblingage" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} value={this.state.siblingage} type="number" className="form-control m-b-5" placeholder="Sibling's Age" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Sibling's Occupation:</label>
                                            <div className="col-md-9">
                                                <input name="siblingoccu" onChange={this.textchange} onClick={() => this.setState({ indexval: 0 })} value={this.state.siblingoccu} type="text" className="form-control m-b-5" placeholder="Sibling's Occupation" />
                                            </div>
                                        </div>
                                        {this.addsiblings()}
                                    </div>
                                    <div className="row m-b-20">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6">
                                            <a className="bttnadd pull-right" onClick={() => this.subsiblings()}>
                                                <span className="fa fa-ban"></span>
                                            </a>
                                            <a className="bttnadd pull-right" onClick={() => this.appendsiblings()}>
                                                <span className="fa fa-user"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="educ">
                            <div className="row">
                                <div className="col-md-3 bg-secondary">
                                    <div className="text-center"><span className="fa fa-graduation-cap fa-5x text-white" style={{ marginTop: 290 }}><p style={{ fontSize: 14, marginTop: 10 }}>Employee Education Background</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-12 text-red">Post Graduate</label>
                                            <label className="col-form-label col-md-3">School/College Name:</label>
                                            <div className="col-md-9">
                                                <input name="post" onChange={this.textchange} value={this.state.post} type="text" className="form-control m-b-5" placeholder="Post School/College Name" />
                                            </div>
                                            <label className="col-form-label col-md-3">Course/Program:</label>
                                            <div className="col-md-9">
                                                <input name="postcourse" onChange={this.textchange} value={this.state.postcourse} type="text" className="form-control m-b-5" placeholder="Course/Program" />
                                            </div>
                                            <label className="col-form-label col-md-3">Year Attended/Graduated:</label>
                                            <div className="col-md-9">
                                                <input name="postyear" onChange={this.textchange} value={this.state.postyear} type="number" className="form-control m-b-10" placeholder="Year Attended/Graduated" />
                                            </div>

                                            <label className="col-form-label col-md-12 text-red">College</label>
                                            <label className="col-form-label col-md-3">School/College Name:</label>
                                            <div className="col-md-9">
                                                <input name="college" onChange={this.textchange} value={this.state.college} type="text" className="form-control m-b-5" placeholder="School/College Name" />
                                            </div>
                                            <label className="col-form-label col-md-3">Course/Program:</label>
                                            <div className="col-md-9">
                                                <input name="collegecourse" onChange={this.textchange} value={this.state.collegecourse} type="text" className="form-control m-b-5" placeholder="Course/Program" />
                                            </div>
                                            <label className="col-form-label col-md-3">Year Attended/Graduated:</label>
                                            <div className="col-md-9">
                                                <input name="collegeyear" onChange={this.textchange} value={this.state.collegeyear} type="number" className="form-control m-b-10" placeholder="Year Attended/Graduated" />
                                            </div>

                                            <label className="col-form-label col-md-12 text-red">Vocational</label>
                                            <label className="col-form-label col-md-3">School/College Name:</label>
                                            <div className="col-md-9">
                                                <input name="vocational" onChange={this.textchange} value={this.state.vocational} type="text" className="form-control m-b-5" placeholder="School/College Name" />
                                            </div>
                                            <label className="col-form-label col-md-3">Course/Program:</label>
                                            <div className="col-md-9">
                                                <input name="vocationalcourse" onChange={this.textchange} value={this.state.vocationalcourse} type="text" className="form-control m-b-5" placeholder="Course/Program" />
                                            </div>
                                            <label className="col-form-label col-md-3">Year Attended/Graduated:</label>
                                            <div className="col-md-9">
                                                <input name="vocationalyear" onChange={this.textchange} value={this.state.vocationalyear} type="number" className="form-control m-b-10" placeholder="Year Attended/Graduated" />
                                            </div>

                                            <label className="col-form-label col-md-12 text-red">High School</label>
                                            <label className="col-form-label col-md-3">School/College Name:</label>
                                            <div className="col-md-9">
                                                <input name="hs" onChange={this.textchange} value={this.state.hs} type="text" className="form-control m-b-5" placeholder="School/College Name" />
                                            </div>
                                            <label className="col-form-label col-md-3">Course/Program:</label>
                                            <div className="col-md-9">
                                                <input name="hscourse" onChange={this.textchange} value={this.state.hscourse} type="text" className="form-control m-b-5" placeholder="Course/Program" />
                                            </div>
                                            <label className="col-form-label col-md-3">Year Attended/Graduated:</label>
                                            <div className="col-md-9">
                                                <input name="hsyear" onChange={this.textchange} value={this.state.hsyear} type="number" className="form-control m-b-10" placeholder="Year Attended/Graduated" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <section id="contperson">
                            <div className="row">
                                <div className="col-md-3 bg-dark">
                                    <div className="text-center"><span className="fa fa-phone-volume fa-5x text-white" style={{ marginTop: 40 }}><p style={{ fontSize: 14, marginTop: 10 }}>Contact Person Details</p></span></div>
                                </div>
                                <div className="col-md-9 bg-light">
                                    <div className="row">
                                        <div className="form-group row m-b-15 m-t-20 m-l-20 m-r-20">
                                            <label className="col-form-label col-md-3">Contact Person Name:</label>
                                            <div className="col-md-9">
                                                <input name="contact" onChange={this.textchange} value={this.state.contact} type="text" className="form-control m-b-5" placeholder="Contact Person's Name" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Contact Number:</label>
                                            <div className="col-md-9">
                                                <input name="contactno" onChange={this.textchange} value={this.state.contactno} type="number" className="form-control m-b-5" placeholder="Contact Person's Number" required />
                                            </div>
                                            <label className="col-form-label col-md-3">Relationship:</label>
                                            <div className="col-md-9">
                                                <input name="contactrelation" onChange={this.textchange} value={this.state.contactrelation} type="text" className="form-control m-b-5" placeholder="Relationship w/ the Contact Person" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section><br />

                        <button className="btttn bg-green-transparent-9 pull-right m-b-10" data-target="#demo-xl-modal1" data-toggle="modal"
                            onClick={() => {
                                // if (this.state.profileDetails.length != 0) {
                                //     this.setState({ profileDetails: [] })
                                // }
                                // setTimeout(() => {
                                    var data_array = []
                                    var profileDetails = {
                                        employeeDets: {
                                            position: this.state.position,
                                            datehired: this.state.datehired,
                                            salary: this.state.salary
                                        },
                                        employeeName: {
                                            fname: this.state.fname,
                                            mname: this.state.mname,
                                            lname: this.state.lname
                                        },
                                        employeecontactDets: {
                                            email: this.state.email,
                                            presentadd: this.state.presentadd,
                                            permaadd: this.state.permaadd,
                                            mobileno: this.state.mobileno,
                                            phoneno: this.state.phoneno
                                        },
                                        personalDets: {
                                            nationality: this.state.nationality,
                                            religion: this.state.religion,
                                            marital: this.state.marital,
                                            empbday: this.state.empbday,
                                            sex: this.state.sex,
                                            empage: this.state.empage,
                                            height: this.state.height,
                                            weight: this.state.weight,
                                        },
                                        governmentID: {
                                            sss: this.state.sss,
                                            pagibig: this.state.pagibig,
                                            philhealth: this.state.philhealth,
                                            tin: this.state.tin
                                        },
                                        spouseDets: {
                                            spouse: this.state.spouse,
                                            spouseadd: this.state.spouseadd,
                                            spouseno: this.state.spouseno
                                        },
                                        dependentsDets: this.state.dependentsData,
                                        parentDets: {
                                            father: this.state.father,
                                            fatherbday: this.state.fatherbday,
                                            fatheroccu: this.state.fatheroccu,
                                            mother: this.state.mother,
                                            motherbday: this.state.motherbday,
                                            motheroccu: this.state.motheroccu
                                        },
                                        siblingsDets: this.state.siblingsData,
                                        educationDets: {
                                            post: this.state.post,
                                            postcourse: this.state.postcourse,
                                            postyear: this.state.postyear,
                                            college: this.state.college,
                                            collegecourse: this.state.collegecourse,
                                            collegeyear: this.state.collegeyear,
                                            vocational: this.state.vocational,
                                            vocationalcourse: this.state.vocationalcourse,
                                            vocationalyear: this.state.vocationalyear,
                                            hs: this.state.hs,
                                            hscourse: this.state.hscourse,
                                            hsyear: this.state.hsyear
                                        },
                                        contact: {
                                            contact: this.state.contact,
                                            contactno: this.state.contactno,
                                            contactrelation: this.state.contactrelation
                                        }


                                    }
                                    data_array.push(profileDetails)
                                    this.setState({profileDetails:data_array})
                                    // this.state.profileDetails.push(profileDetails)

                                // }, 100)

                                if (
                                    this.state.spouse == "" &&
                                    this.state.spouseadd == "" &&
                                    this.state.spouseno == "" &&
                                    this.state.sibling == "" &&
                                    this.state.siblingbday == "" &&
                                    this.state.siblingage == "" &&
                                    this.state.siblingoccu == ""
                                ) {
                                    this.setState({
                                        spouse: "N/A",
                                        spouseadd: "N/A",
                                        spouseno: 0,
                                        sibling: "N/A",
                                        siblingbday: "N/A",
                                        siblingage: 0,
                                        siblingoccu: "N/A"
                                    })
                                }
                            }}>
                            Save Details
                        </button>

                    </div>
                </form>


                <div id="demo-xl-modal1" className="modal  w3-animate-zoom" tabindex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Employee Details Preview</h4>
                                <button data-dismiss="modal" className="pull-right bttnclose">
                                    <span className="fa fa-times-circle fa-sm"></span>
                                </button>
                            </div>
                            <div className="modal-body bg-default">
                                <div className="row m-b-10">
                                    <div className="col-md-12">
                                        <div>
                                            {this.state.profileDetails.map((val,index)=>{
                                                console.log(val.employeeName.lname)
                                                return<div>
                                                    <div>
                                                        <label>Employee Name</label>
                                            <div>{`${val.employeeName.lname} ${val.employeeName.fname} ${val.employeeName.mname} `}</div>
                                                    </div>
                                                 </div>
                                            })

                                            }
                                            
                                        </div>
                                        <button className="bttnadd pull-right">
                                            <span className="fa fa-save"></span>
                                        </button>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default jobForm2;