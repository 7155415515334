import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import AmCharts from "@amcharts/amcharts4";

class DLine3 extends Component {
    state = {}


    chart() {
        const { lineGraph_val } = this.props;
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        chart.data = lineGraph_val
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        // Create axes
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.tooltipText = "{value}"
        series.strokeWidth = 2;
        series.minBulletDistance = 15;
        series.stroke = am4core.color("#e67e22");
        series.tooltip.background.fill = am4core.color("#e67e22");
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#e67e22");
        series.tooltip.label.fill = am4core.color("#fff");

        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "value1";
        series2.dataFields.dateX = "date";
        series2.tooltipText = "{value1}"
        series2.strokeWidth = 2;
        series2.minBulletDistance = 15;
        series2.stroke = am4core.color("#27ae60");
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#27ae60");
        series2.tooltip.label.fill = am4core.color("#fff");

        // Drop-shaped tooltips
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";

        series2.tooltip.background.cornerRadius = 20;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 40;
        series2.tooltip.label.minHeight = 40;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.label.textValign = "middle";

        //  series2.propertyFields.stroke = am4core.color("#fff");
        //  series2.propertyFields.fill = am4core.color("#fff");
        // Make bullets grow on hover
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");

        let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.strokeWidth = 2;
        bullet2.circle.radius = 4;
        bullet2.circle.fill = am4core.color("#fff");

        let bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;

        // Make a panning cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;
        chart.cursor.snapToSeries = series2;

        // Create vertical scrollbar and place it before the value axis
        // chart.scrollbarY = new am4core.Scrollbar();
        // chart.scrollbarY.parent = chart.leftAxesContainer;
        // chart.scrollbarY.toBack();

        // Create a horizontal scrollbar with previe and place it underneath the date axis
        // chart.scrollbarX = new am4charts.XYChartScrollbar();
        // chart.scrollbarX.series.push(series);
        // chart.scrollbarX.parent = chart.bottomAxesContainer;

        dateAxis.start = 0.79;
        dateAxis.keepSelection = true;


    }

    componentDidMount() {
        this.chart();
    }
    componentDidUpdate() {
        this.chart();
    }
    render() {

        return (
            <div id="chartdiv"></div>
        )
    }
}
export default DLine3;
