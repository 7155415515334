import React, { Component } from 'react';

// import Navigation from "../template/navigation";

class Index extends Component {
	state = {  }
	componentWillUnmount(){
		

	}
    render() { 
			return (
				<div id="content" className="content">

					<div className="row">

						<div className="col-md-7">

							<div className="row">
								<div className="col-md-12">
									<div className="panel">
										<div className="panel-heading">
											<div className="panel-heading-btn"></div>
											<h4 className="panel-title">Create Announcement</h4>
										</div>
										<div className="panel-body">
											Hello Marurupok
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="panel">
										<div className="panel-heading">
											<div className="panel-heading-btn"></div>
											<h4 className="panel-title">Newsfeed</h4>
										</div>
										<div className="panel-body">
											Panel Content Here
										</div>
									</div>
								</div>
							</div>

						</div>

						<div className="col-md-5">

							<div className="panel">
								<div className="panel-heading">
									<div className="panel-heading-btn">
									</div>
									<h4 className="panel-title">Today's Activities</h4>
								</div>
								<div className="panel-body">
									Panel Content Here
								</div>
							</div>

						</div>
					</div>
					
				</div>

			);
    }
}
 
export default Index;