import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class DBar4 extends Component {
    state = {
    }
    render() {
        const { barGraph } = this.props;
        var config = {
            "hideCredits": true,
            "type": "serial",
            "categoryField": "month",
            "colors": [
                "#67abe9",
                "#77c8e0",

            ],
            "startDuration": 1,
            "categoryAxis": {
                "gridPosition": "start"
            },
            "trendLines": [],
            "graphs": [
                {
                    "balloonText": "[[title]] of [[month]]:[[value]]",
                    "fillAlphas": 1,
                    "id": "AmGraph-1",
                    "title": "Open Sales",
                    "type": "column",
                    "valueField": "open",
                    // "labelColorField": "white",

                },
                {
                    "balloonText": "[[title]] of [[month]]:[[value]]",
                    "fillAlphas": 1,
                    "id": "AmGraph-2",
                    "title": "Closed Sales",
                    // "labelColorField": "white",
                    "type": "column",
                    "valueField": "close"
                }
            ],
            "guides": [],
            "valueAxes": [
                {
                    "id": "ValueAxis-1",
                    "stackType": "regular",
                    "title": ""
                }
            ],
            "allLabels": [],
            "balloon": {},
            "legend": {
                "enabled": true,
                "useGraphSettings": true,
                "fill": "white"

            },
            "dataProvider": barGraph
        }
        return (
            // <div id="chartdiv2" style={{ width: "100%", height: "335px" }}></div>
            <AmCharts.React
                style={{
                    width: "100%",
                    height: "180px"
                }}
                options={config}
            />
        );
    }
}

export default DBar4;