import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import Header from '../Header_Page/index';
import Cards from '../Cards/index';
import Modal from "../Modal/index";
class SamplePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: [],
            card1: [],
            target: "",
            modalsm: [],
            modalmd: [],
            modallg: [],
        };
    }

    componentDidMount() {
        let data = []
        var data1 = { navi1: "sp Nav 1", link: "#/samplepage/", bread: "navigation1", title: "Title Sample Page" }
        var data2 = { navi1: "sp Nav 2", link: "#/samplepage2/", bread: "navigation2", title: "Title Sample Page2" }
        data.push(data1)
        data.push(data2)
        this.setState({ nav1: data })

        let modalsm = {
            card: "#demo-modal-sm",
            cardname: "Card Name 1",
            cardbody: "Card Body 1",
            cardcolor: "widget widget-stats bg-orange-transparent-9",
        }
        let modalmd = {
            card: "#demo-modal-md",
            cardname: "Card Name 2",
            cardbody: "Card Body 2",
            cardcolor: "widget widget-stats bg-blue-transparent-9",
        }
        let modallg = {
            card: "#demo-modal-lg",
            cardname: "Card Name 3",
            cardbody: "Card Body 3",
            cardcolor: "widget widget-stats bg-green-transparent-9",
        }
        let datasm = [];
        let datamd = [];
        let datalg = [];
        datasm.push(modalsm);
        datamd.push(modalmd);
        datalg.push(modallg);

        this.setState({ modalsm: datasm });
        this.setState({ modalmd: datamd });
        this.setState({ modallg: datalg });
    }
    render() {
        return (
            <div id="content" className="content panel" style={{ height: '100%' }}>
                <Header data={this.state.nav1} />
                <div className="row">
                    <div className="col-md-4">
                        <Cards
                            card={this.state.modalsm}
                        />
                    </div>
                    <div className="col-md-4">
                        <Cards
                            card={this.state.modalmd}
                        />
                    </div>
                    <div className="col-md-4">
                        <Cards
                            card={this.state.modallg}
                        />
                    </div>
                </div>
                <div>
                    <button className="btttn bg-green-transparent-9 m-r-5" card={this.state.modalsm} data-toggle="modal">SMALL MODAL</button>
                    <button className="btttn bg-green-transparent-9 m-r-5" data-target="#demo-modal-md" data-toggle="modal">MEDUIM MODAL</button>
                    <button className="btttn bg-green-transparent-9 m-r-5" data-target="#demo-modal-lg" data-toggle="modal">LARGE MODAL</button>
                </div>
                <div className="panel m-t-10 " >
                    <div className="panel-body">
                        <div style={{ overflow: "scroll", height: '100%' }}>
                            <table className="table table-bordered text-center" style={{ position: "relative" }}>
                                <thead>
                                    <tr>
                                        <th rowSpan="3" colSpan="1">EMPLOYEE&nbsp;ID</th>
                                        <th rowSpan="3" colSpan="1" className="bg-green-lighter text-white" style={{ position: "sticky", zIndex: 1, left: 0 }} >NAME&nbsp;OF&nbsp;EMPLOYEE</th>
                                        <th rowSpan="3" colSpan="1">SALARY&nbsp;RATE</th>
                                        <th rowSpan="1" colSpan="20">EARNINGS</th>
                                        <th rowSpan="1" colSpan="17">DEDUCTIONS</th>
                                        <th rowSpan="3" colSpan="1">NET&nbsp;AMOUT&nbsp;PAID</th>
                                        <th rowSpan="3" colSpan="1">SIGNITURE&nbsp;OF&nbsp;EMPLOYEE</th>
                                        <th rowSpan="3" colSpan="1">ACTION</th>
                                    </tr>
                                    <tr>
                                        {/* EARNINGS START */}
                                        <th rowSpan="1" colSpan="3">REGULAR</th>
                                        <th rowSpan="1" colSpan="2">LEAVE&nbsp;WITH&nbsp;PAY</th>
                                        <th rowSpan="1" colSpan="2">OVERTIME</th>
                                        <th rowSpan="1" colSpan="2">RESTDAY</th>
                                        <th rowSpan="1" colSpan="2">REST&nbsp;DAY&nbsp;OVERTIME</th>
                                        <th rowSpan="1" colSpan="2">SPECIAL&nbsp;HOLIDAY</th>
                                        <th rowSpan="1" colSpan="2">SPEACIAL&nbsp;HOLIDAY&nbsp;W/&nbsp;OVERTIME&nbsp;PER&nbsp;HR</th>
                                        <th rowSpan="1" colSpan="2">REGULAR&nbsp;HOLIDAY</th>
                                        <th rowSpan="1" colSpan="2">REGULAR&nbsp;HOLIDAY&nbsp;W/&nbsp;OVERTIME&nbsp;PER&nbsp;HR</th>
                                        <th rowSpan="2" colSpan="1">TOTAL&nbsp;EARNINGS</th>
                                        {/* EARNINGS END   */}

                                        {/* DEDUCTION START */}
                                        <th rowSpan="1" colSpan="2">LATE</th>
                                        <th rowSpan="2" colSpan="1">1&nbsp;-&nbsp;15</th>
                                        <th rowSpan="2" colSpan="1">16&nbsp;-&nbsp;28</th>
                                        <th rowSpan="2" colSpan="1">TOTAL</th>
                                        <th rowSpan="1" colSpan="4">SOCIAL&nbsp;SECURITY&nbsp;SYSTEM</th>
                                        <th rowSpan="1" colSpan="2">PHILHEALTH</th>
                                        <th rowSpan="1" colSpan="2">PAG&nbsp;-&nbsp;IBIG</th>
                                        <th rowSpan="1" colSpan="3">EMPLOYEES&nbsp;SHARE</th>
                                        <th rowSpan="2" colSpan="1">TOTAL&nbsp;DEDUCTIONS</th>

                                        {/* DEDUCTION END */}
                                    </tr>
                                    <tr>
                                        {/* EARNINGS START */}
                                        <th rowSpan="1" colSpan="1">RATE</th>{/* REGULAR */}
                                        <th rowSpan="1" colSpan="1">DAYS</th>{/* REGULAR */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* REGULAR */}

                                        <th rowSpan="1" colSpan="1">DAYS</th>{/* LEAVE WITH PAY */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* LEAVE WITH PAY */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* OVERTIME */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* OVERTIME */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* RESTDAY */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* RESTDAY */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* REST DAY OVERTIME */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* REST DAY OVERTIME */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* SPECIAL HOLIDAY */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* SPECIAL HOLIDAY */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* SPEACIAL HOLIDAY W/ OVERTIME PER HR */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* SPEACIAL HOLIDAY W/ OVERTIME PER HR */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* REGULAR HOLIDAY */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* REGULAR HOLIDAY */}

                                        <th rowSpan="1" colSpan="1">HOURS</th>{/* REGULAR HOLIDAY W/ OVERTIME PER HR */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* REGULAR HOLIDAY W/ OVERTIME PER HR */}
                                        {/* EARNINGS END   */}
                                        {/* DEDUCTION START */}
                                        <th rowSpan="1" colSpan="1">DAYS</th>{/* LEAVE WITH PAY */}
                                        <th rowSpan="1" colSpan="1">AMOUNT</th>{/* LEAVE WITH PAY */}

                                        <th rowSpan="1" colSpan="1">SSS&nbsp;CONTRIBUTION</th>{/* SOCIAL SECURITY SYSTEM */}
                                        <th rowSpan="1" colSpan="1">EC</th>{/* SOCIAL SECURITY SYSTEM */}
                                        <th rowSpan="1" colSpan="1">ER</th>{/* SOCIAL SECURITY SYSTEM */}
                                        <th rowSpan="1" colSpan="1">EE</th>{/* SOCIAL SECURITY SYSTEM */}

                                        <th rowSpan="1" colSpan="1">ER</th>{/* PHILHEALTH */}
                                        <th rowSpan="1" colSpan="1">EE</th>{/* PHILHEALTH */}

                                        <th rowSpan="1" colSpan="1">ER</th>{/* PAG-IBIG */}
                                        <th rowSpan="1" colSpan="1">EE</th>{/* PAG-IBIG */}

                                        <th rowSpan="1" colSpan="1">SSS</th>{/* EMPLOYEES SHARE */}
                                        <th rowSpan="1" colSpan="1">PHILHEALTH</th>{/* EMPLOYEES SHARE */}
                                        <th rowSpan="1" colSpan="1">PAG&nbsp;-&nbsp;IBIG</th>{/* EMPLOYEES SHARE */}
                                        {/* DEDUCTION END */}

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td className="bg-green-lighter text-white" style={{ position: "sticky", zIndex: 1, left: 0 }} >JOHN MARCO MANALO</td>
                                        <td>25,000</td>
                                        {/* EARNINGS START   */}
                                        <td>rate</td>
                                        <td>days</td>
                                        <td>amount</td>

                                        <td>days</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>amount</td>
                                        {/* EARNINGS END   */}
                                        {/* DEDUCTION START */}
                                        <td>hours</td>
                                        <td>amount</td>

                                        <td>amount</td>

                                        <td>amount</td>

                                        <td>amount</td>

                                        <td>SSS</td>
                                        <td>EC</td>
                                        <td>ER</td>
                                        <td>EE</td>

                                        <td>ER</td>
                                        <td>EE</td>

                                        <td>ER</td>
                                        <td>EE</td>

                                        <td> </td>
                                        <td> </td>
                                        <td> </td>

                                        <td> </td>

                                        <td> </td>

                                        <td> </td>

                                        <td> </td>
                                        {/* DEDUCTION END */}

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div >

        );
    }
}
export default SamplePage;