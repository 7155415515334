import React, { Component } from 'react';
// import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import 'intro.js/introjs.css';
import GoogleMapReact from 'google-map-react';
import { getData } from '../../../../api/api';
import DBar4 from "./charts/d_bar4";
import moment from 'moment';
import { isThisMonth } from 'date-fns';
import DPie3 from "./charts/d_pie3";
import DBar3 from "./charts/d_bar3";
import DLine3 from "./charts/d_line";
import Dashboard from "../users/index"
import Logs from "../history/index"

const AnyReactComponent = ({ user_id, latlng, complete_name, user_pic, details, location_status }) => {
    var image = "";
    var image_pic = ""
    if (latlng !== null) {
        if (location_status === 'Mocking') {
            image = require('../../img/chi-gong.png');
        } else {
            image = require('../../img/electron-blue.png');
        }

        // image_pic = ("http://images.pacificweb.com.ph/pockethr/profilepic/" + user_pic)
        if (user_pic !== "") {

            image_pic = ("http://images.pacificweb.com.ph/pockethr/profilepic/" + user_pic)
        } else {
            image_pic = require('../../img/user.png')
        }
    }

    // console.log(user_pic)
    return <div className="tooltipparent" style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}>
        <div className="aligning" onClick={() => { getSingleUser(user_id, complete_name, user_pic, details) }}>
            <img src={image} style={{ maxWidth: 60, maxHeight: 60 }} className=" animated bounce" />
            <center>
                <img style={{ marginTop: 3, marginLeft: 13, width: 32, height: 32, position: 'absolute', borderRadius: 20 }} src={image_pic} className=" animated bounce" />
            </center>
        </div>

        <span className="tooltiptextbody">
            <button style={{ height: 30, width: 30, backgroundColor: '#22a6b3', marginRight: 5, cursor: 'pointer' }} onClick={() => { accomplishments(details, user_id) }}>
                <span className="fas fa-eye"></span>

            </button>

            <button style={{ height: 30, width: 30, backgroundColor: '#6ab04c', marginRight: 5, cursor: 'pointer' }} onClick={() => { playback(details, user_id) }}>
                <span className="fas fa-play-circle"></span>
            </button>

            <p>
                Name:  {complete_name}<br />
                user_id:  {user_id}
            </p>
        </span>
    </div>
}

const AnyReactComponentCoordinates = ({ company_name, cust_contact_mobile, building_name, company_address, cust_contact_person, accom_added, accom_ended, data, status, lat, lng }) => {
    var image = "";
    var image_pic = ""
    if (status === "Open") {
        image = require('../../img/white-green.png');
    } else {
        image = require('../../img/white-orange.png');
    }
    return <div className="tooltipparent" style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}>
        <img src={image} style={{ maxWidth: 30, maxHeight: 30, cursor: 'pointer' }} onClick={() => { displaySpecificAccom(accom_added, data) }} />
    </div>
}
function playback(details, user_id) {

    var data = []
    data.push(details)
    // console.log(data)
    this.setState({
        count_val: 0,
        col: "col-lg-6",
        displayMarkers: [],
        show_all_users: false,
        display_AccomPlayback: [],
        employee_details: data,
        users_data: data

    })
    getData("HumanResource/getCustomersAccomplishment", user_id)
        .then(response => {
            // console.log(response)
            if (response.length > 0)
                this.ShowMapMarkerPlayback(response);

        })
}
function getSingleUser(user_id, complete_name, user_pic, details) {

    var image = "";
    var image_pic = ""
    if (user_pic !== "") {

        image_pic = ("http://images.pacificweb.com.ph/pockethr/profilepic/" + user_pic)
    } else {
        image_pic = require('../../img/user.png')
    }
    var data = [{
        userId: user_id,
        completeName: complete_name,
        userPic: user_pic,
        detail: details
    }]
    // console.log(data);

    this.setState({
        show_user: false,
        show_accom: true,
        completeName: complete_name,
        userId: user_id,
        userPic: user_pic,
        specific_user: data

    })
}
function accomplishments(details, user_id) {
    // alert("qwer")
    console.log(details, user_id)
    var data = []
    data.push(details)
    this.setState({
        col: "col-lg-6",
        show: "Customers",
        displayMarkers: [],
        show_accom: true,
        show_all_users: false,
        display_AccomPlayback: [],
        employee_details: data,
        users_data: data

    })
    // console.log(user_id)
    getData("HumanResource/getCustomers", user_id)
        .then(response => {
            // console.log(response)
            this.setState({
                displayMarkers: response,
                accomplishment_customers: response
            })
            setTimeout(() => {
                this.handleSidebar();
            }, 200)


        })
}

function displaySpecificAccom(accom_added, data, lat, lng) {
    console.log(lat, lng)
    var details = []
    details.push(data)
    this.setState({
        mapoption: {
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            },
            zoom: 14,
        },
        time_accomplayback: moment(accom_added).format('hh:mm A'),
        specific_accom: details,
        show_accom: false,
        show_user: true
    })
}
class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            time: new Date().toLocaleString(),
            refresh: false,
            count_val: 0,
            mapoption: {
                center: {
                    lat: 13.7565,
                    lng: 121.0583
                },
                zoom: 14,
            },
            displayMarkers: [],
            display_AccomPlayback: [],
            showRewind: true,
            col: "col-lg-12",
            colshow: true,
            employee_details: [],
            show_playback: true,
            show_settingcustomer: true,
            show_settingemployee: true,
            users_data: [],
            sales_personel: "",
            stopPlayback: true,
            stopPlayback2: true,
            sidenavActive: true,
            sidenav: "theme-panel theme-panel-lg",
            hidesidenav: "theme-panel theme-panel-lg",
            show_allEMP: true,
            show_EMPaccom: false,
            temporarypassEmployees: [],
            accomplishment_customers: [],
            show_customer: true,
            data_totalSales: [],
            open_accom_details: [],
            close_accom_details: [],
            searchDriver: "",
            userData: [],
            THead: [],
            fieldMonitoring: [],
            yesterday_data: [],
            barGraph: [],
            show: "dashboard",
            titletable: "Assigned Employee",
            total_employee: "0",
            total_active: "0",
            monitoring: [],
            table_map: [],
            status_graph: [],
            time_accomplayback: "00:00 --",
            specific_accom: [],
            specific_user: [],
            show_accom: true,
            show_user: true,
            searchDriver: "",
            show_all_users: true,
            data_totalSales: [],
            dashshow: false,
            Dashshowtitle: "",
            completeName: "",
            userId: "",
            bgcolor1: "rgb(21, 27, 38)",
            bgcolor2: "transparent",
            bgcolor3: "transparent",
            bgcolor4: "transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            txtcolor3: "black",
            txtcolor4: "black",
            navbar: "navigation1",
            userPic: "",
            details: ""

        };
        playback = playback.bind(this)
        accomplishments = accomplishments.bind(this)
        displaySpecificAccom = displaySpecificAccom.bind(this)
        this.textchange = this.textchange.bind(this);
        getSingleUser = getSingleUser.bind(this)

    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
        // console.log(this.state.searchDriver)
    }

    Search_submit = (e) => {
        e.preventDefault();

        getData("HumanResource/Search_engine", { u_id: localStorage.getItem("u"), search: this.state.searchDriver })
            .then(response => {
                if (response.length > 0) {
                    var user = []
                    var customer = []
                    for (let index = 0; index < response.length; index++) {
                        if (response[index].type === "user") {
                            user.push(response[index])
                        } else {
                            customer.push(response[index])
                        }

                    }
                    this.setState({
                        users_data: user,
                        displayMarkers: customer
                    })
                } else {
                    this.setState({
                        users_data: [],
                        displayMarkers: []
                    })
                    alert('No Result')
                }

                console.log(response)
            })

    }

    ShowMapMarkerPlayback(response) {

        setTimeout(() => {
            if (this.state.count_val != (response.length)) {
                var lat = 0.0;
                var long = 0.0;
                this.state.display_AccomPlayback.push(response[this.state.count_val])
                for (let index = 0; index < response.length; index++) {
                    lat = response[this.state.count_val].lat;
                    long = response[this.state.count_val].lng;
                    var pass_accomplish = []
                    pass_accomplish.push(response[this.state.count_val]);
                    this.setState({
                        time_accomplayback: moment(response[this.state.count_val].accom_added).format('hh:mm A'),
                        specific_accom: pass_accomplish
                    })
                }

                this.setState({
                    refresh: true,
                    show_accom: false,
                    count_val: this.state.count_val + 1,
                    mapoption: {
                        center: {
                            lat: parseFloat(lat),
                            lng: parseFloat(long)
                        },
                        zoom: 18,
                    }
                })


                this.ShowMapMarkerPlayback(response);
            }
        }, 1500);
    }


    handleSidebar = () => {
        this.setState({ sidenavActive: !this.state.sidenavActive });
    }

    get_handleUsers() {
        getData("users/Get_users", { u_id: localStorage.getItem("u"), type: "admin", date: moment(this.state.date).format('YYYY-MM-DD') })
            .then(response => {
                // alert('refetch')
                if (this.state.show_all_users === true) {
                    this.setState({
                        users_data: response.customerstracking,
                        temporarypassEmployees: response.customerstracking,
                        total_employee: response.users.length,
                        total_active: response.customerstracking.length,
                        show_allEMP: true
                    })
                }
            })
    }

    GoliveMap() {
        setTimeout(() => {
            this.get_handleUsers()
            this.GoliveMap()
        }, 500000)
    }

    componentDidMount() {
        this.checkPagePrivilege();
        localStorage.setItem('maps', true);
        localStorage.setItem('removenav', true)
        this.get_handleUsers()
        this.GoliveMap();
        this.getSales();
    }
    checkPagePrivilege() {
        var route = "#/maps";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                if (response == "show_404") {
                    this.setState({ redirect: 'checkPagePrivilege' });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }

    getMapOptions = (maps) => {

        return {
            streetViewControl: true,
            scaleControl: false,
            fullscreenControl: false,
            zoomControl: false,
            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "on"
                }]
            }],
            gestureHandling: "greedy",
            disableDoubleClickZoom: false,
            mapTypeControl: false,

            mapTypeId: maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]

            },

            zoomControl: false,
            clickableIcons: false
        };
    }
    getSales() {
        getData("users/get_handle_sales_data_map", { u_id: localStorage.getItem("u"), type: "admin", from: new Date(), to: new Date() })
            .then(response => {
                console.log(response)   
                var open_accom_details = []
                var close_accom_details = []
                var yesterday_data = []
                var yesterday = moment(response.yesterday_date).format('YYYY-MM-DD')


                if (open_percent > 0) {
                    this.setState({ open_percent: open_percent, close_percent: close_percent })
                } else {
                    this.setState({ open_percent: 0, close_percent: 0 })
                }
                this.state.fieldMonitoring.push(response.fieldMonitoring);
                var timeHead = 0;
                var userData_Details = [];
                // var timesheetData = [];
                for (let index = 0; index < response.fieldMonitoring['present_field'].length; index++) {
                    // userData_Details.push(response.fieldMonitoring2[index].details)
                    //  for (let i = 0; i < response.fieldMonitoring['present_field'][index].present.length; i++) {
                    if (timeHead < response.fieldMonitoring['present_field'][index].present.length) {
                        timeHead = response.fieldMonitoring['present_field'][index].present.length;
                    }

                    // }

                }
                for (let index = 0; index < response.fieldMonitoring2.length; index++) {
                    userData_Details.push(response.fieldMonitoring2[index])

                }

                var THead = []
                for (let index = 0; index < timeHead; index++) {
                    // console.log(timeHead);

                    if (THead.length == 0) {
                        THead.push({ head: 'Time-in' })
                    }
                    else if (THead[THead.length - 1].head == 'Time-in') {
                        THead.push({ head: 'Time-out' })
                    } else if (THead[THead.length - 1].head == 'Time-out') {
                        THead.push({ head: 'Time-in' })
                    }


                }
                var Open_today = [];
                var Closed_today = [];
                var status_graph = [];
                for (let index = 0; index < response.today.length; index++) {
                    var match = false
                    for (let k = 0; k < status_graph.length; k++) {
                        if (status_graph[k].month == moment(response.today[index].cust_date_added).format('YYYY-MM-DD')) {
                            match = true
                            if (response.today[index].cust_status == 'Open') {
                                details_graph['open']++
                            } else if (response.today[index].cust_status == 'Closed') {
                                details_graph['close']++
                            }
                        }

                    }
                    if (match == false) {
                        var details_graph = { month: moment(response.today[index].cust_date_added).format('YYYY-MM-DD'), open: 0, close: 0 }
                        if (response.today[index].cust_status == 'Open') {
                            details_graph['open']++
                        } else if (response.today[index].cust_status == 'Closed') {
                            details_graph['close']++
                        }
                        status_graph.push(details_graph);
                    }


                    if (response.today[index].cust_status == 'Open') {
                        Open_today.push(response.today[index])
                    } else if (response.today[index].cust_status == 'Closed') {
                        Closed_today.push(response.today[index])
                    }

                }
                var open_percent = (Open_today.length / response.today.length * 100).toFixed(2)
                var close_percent = (Closed_today.length / response.today.length * 100).toFixed(2)
                // console.log(response.fieldMonitoring["ob_field"]);
                this.setState({ open_percent: open_percent, close_percent: close_percent })
                this.setState({ status_graph: status_graph, monitoring: response.fieldMonitoring, yesterday_data: yesterday_data, pieGraph: response.graph_location, month_location: response.month_location, timesheetData: response.fieldMonitoring['present_field'], userData: userData_Details, THead: THead, barGraph: response.total_open, today_location: response.today_location, yesterday_location: response.yesterday_location, Graph: response.total_open, last_month: response.last_month, today_sale: response.today.length, yesterday_sale: response.yesterday.length, date_range_to: response.date_range_to, date_range_from: response.date_range_from, data_totalSales: response.data_totalSales, open_accom_details: Open_today, close_accom_details: Closed_today })
            })
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    render() {
        // console.log(this.state.specific_user)

        // console.log(this.state.col)
        // console.log(this.state.displayMarkers)
        let EmployeeSearch = this.state.userData.filter(
            (files) => {
                if (files.completename === null) {

                } else {
                    return files.completename.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.branch_name.toLowerCase().indexOf(
                            this.state.searchDriver.toLocaleLowerCase()) !== -1
                }

            }
        )
        return (
            <div id="map-container-google-3" className="z-depth-1-half">
                <div style={{ height: '100vh', width: '100%', position: 'absolute' }}>

                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAz0n7-lqY4We_24Yk8QIjVNbnPX1fG8pU" }}
                        center={this.state.mapoption.center}
                        zoom={this.state.mapoption.zoom}
                        options={this.getMapOptions}
                    >

                        {this.state.users_data.map((val, index) => {
                            if (val.location_latlng !== null) {
                                var latlong = ""
                                var splitlatlng = ""
                                var lat = ""
                                var lng = ""
                                var complete_name = ""
                                latlong = String(val.location_latlng)
                                splitlatlng = latlong.split(",")
                                lat = splitlatlng[0]
                                lng = splitlatlng[1]
                                complete_name = val.user_lname + "," + val.user_fname
                                // console.log(lat)
                                return <AnyReactComponent
                                    key={index}
                                    lat={lat}
                                    lng={lng}
                                    location_status={val.location_status}
                                    user_id={val.user_id}
                                    latlng={val.location_latlng}
                                    user_pic={val.user_pic}
                                    complete_name={complete_name}
                                    details={val}
                                />
                            }
                            return null;
                        })}

                        {this.state.displayMarkers.map((val, index) => {
                            // console.log(lat)
                            return <AnyReactComponentCoordinates
                                key={index}
                                lat={val.lat}
                                lng={val.lng}
                                company_name={val.company_name}
                                Company_contact={val.Company_contact}
                                building_name={val.building_name}
                                company_address={val.company_address}
                                company_personelName={val.company_personelName}
                                date={val.date}
                                status={val.status}
                                data={val}
                            />
                        })}

                        {this.state.display_AccomPlayback.map((val, index) => {
                            // console.log(lat)
                            return <AnyReactComponentCoordinates
                                key={index}
                                lat={val.lat}
                                lng={val.lng}
                                company_name={val.company_name}
                                cust_contact_mobile={val.cust_contact_mobile}
                                building_name={val.building_name}
                                company_address={val.company_address}
                                cust_contact_person={val.cust_contact_person}
                                accom_added={val.accom_added}
                                accom_ended={val.accom_ended}
                                status={val.status}
                                data={val}
                            />
                        })}
                    </GoogleMapReact>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row-reverse" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <button className="btn bg-green-transparent-9 fas fa-home text-white m-3">
                            <a href="/" style={{ textDecoration: "none", color: "white" }}>&nbsp;Home</a>
                        </button>
                        <button className="btn bg-orange-transparent-9 fas fa-retweet text-white m-3" onClick={() => {
                            this.setState({
                                displayMarkers: [],
                                show_all_users: true,
                                display_AccomPlayback: [],
                                users_data: this.state.temporarypassEmployees,
                                mapoption: {
                                    center: {
                                        lat: 13.7565,
                                        lng: 121.0583
                                    },
                                    zoom: 14,
                                },
                                show_accom: true
                            })
                        }}>
                            &nbsp;Reset&nbsp;Selection
                        </button>
                        <button className="btn bg-blue-transparent-9 fa fa-chart-area text-white m-3">
                            <a style={{ textDecoration: "none", color: "white" }} href="javascript:;" data-click="theme-panel-expand" className="theme-collapse1-btn" onClick={() => { this.setState({ dashshow: true }) }}>&nbsp;Sales&nbsp;Dashboard</a>
                        </button>
                    </div>
                    <div >
                        <div className="aligning-2" style={{ width: '400px', height: '40px', position: 'absolute' }}>
                            <div >
                                <div className="row" style={{ width: '130px', height: '40px' }}>
                                    <div className="col-md-6" style={{ backgroundColor: 'rgba(28, 37, 45, 0.5)', padding: 2 }}>
                                        <div className="aligning-2 p-10">
                                            <span className="fas fa-user-tie text-white-transparent-9" style={{ color: 'transparent', fontSize: '12px' }}></span>
                                            <h5 className="text-white" style={{ marginLeft: 5 }}>{this.state.total_employee}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ backgroundColor: 'rgba(28, 37, 45, 0.5)', padding: 2 }}>
                                        <div className="aligning-2 p-10">
                                            <span className="fas fa-user-tie text-green-lighter" style={{ color: 'transparent', fontSize: '12px' }}></span>
                                            <h5 className="text-white" style={{ marginLeft: 5 }}>{this.state.total_active}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form id="demo-2" className="m-l-10" style={{ zIndex: 900, marginTop: '1%' }} onSubmit={this.Search_submit}>
                                <input type="search" placeholder="Search" value={this.state.searchDriver} name='searchDriver' onChange={this.textchange} />
                            </form>

                        </div>

                        {this.state.show_accom === false &&
                            <div style={{ width: '40%', height: '310px', backgroundColor: 'rgba(28, 37, 45, 0.5)', position: 'absolute', padding: 2, marginTop: '5%' }}>
                                <center>
                                    <h3 style={{ color: 'transparent', zIndex: 1020, marginTop: 7 }}>{this.state.time_accomplayback}</h3>
                                </center>
                                <div style={{ width: '100%' }}>
                                    <table>
                                        {this.state.specific_accom.map((val, index) => {
                                            // console.log(lat)
                                            return <tbody key={index}>
                                                <tr className="white">
                                                    <td>Building Name</td>
                                                    <td >{val.building_name}</td>
                                                </tr>
                                                <tr className="white">
                                                    <td >Company Address</td>
                                                    <td >{val.company_address}</td>
                                                </tr>
                                                <tr className="white">
                                                    <td >Location</td>
                                                    <td >{val.cust_location}</td>
                                                </tr>
                                                <tr className="white">
                                                    <td >Company Name</td>
                                                    <td >{val.company_name}</td>
                                                </tr>
                                                <tr className="white">
                                                    <td >Contact Person</td>
                                                    <td >{val.cust_contact_person}</td>
                                                </tr>
                                                <tr className="white">
                                                    <td >Mobile No.</td>
                                                    <td >{val.cust_contact_mobile}</td>
                                                </tr>
                                                <tr className="white">
                                                    <td >Status</td>
                                                    <td >{val.status}</td>
                                                </tr>
                                            </tbody>
                                        })}
                                    </table>
                                </div>
                            </div>
                        }
                        {/* {this.state.show_user === false &&
                            <div style={{ width: '40%', height: '100px', backgroundColor: 'rgba(28, 37, 45, 0.5)', position: 'absolute', padding: 2, marginTop: '5%' }}>
                                <center>
                                    <h3 style={{ color: 'transparent' }}>Employee Details</h3>
                                </center>
                                <table>
                                    {this.state.specific_user.map((val, index) => {
                                        return <tbody key={index}>
                                            <tr className="white">
                                                <td>Name:</td>
                                                <td>{val.completeName}</td>
                                            </tr>
                                            <tr className="white">
                                                <td>User ID</td>
                                                <td>{val.userId}</td>
                                            </tr>
                                        </tbody>
                                    })}
                                </table>
                            </div>
                        } */}
                        {this.state.show_user === false &&
                            <div style={{ width: '20%', height: "19%", position: 'absolute', padding: 2, marginTop: '5%', borderTopRightRadius: 12, borderBottomRightRadius: 12 }}>
                                {this.state.specific_user.map((val, index) => {
                                    // console.log(val)
                                    // var get_details = [];
                                    // get_details.push(val.detail)
                                    return <div className="panel panel-default" key={index}>

                                        <div className="panel-heading">
                                            <div className="panel-title">
                                                <i class="fas fa-window-close pull-right" onClick={() => { this.setState({ show_user: true }) }}></i>
                                            </div>
                                            <div className="nameflex">
                                                <div className="text-center">
                                                    {val.userPic ? <img src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.userPic)} style={{ width: 60, borderRadius: 50 }}
                                                    /> : <img src={require('../../img/user.png')} style={{ width: 60 }} />}
                                                </div>
                                                <div className="text-center m-l-10">
                                                    <h4>{val.completeName}</h4>
                                                    <span><i className="fas fa-circle text-green-lighter"></i> <label className="text-green-lighter">Active</label></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="btn-group">
                                                <button className="btn bg-green-lighter" onClick={() => { accomplishments(val.detail, val.userId) }}>
                                                    <span className="fas fa-eye text-white">Accomplishments</span>
                                                </button>
                                                <button className="btn bg-blue-lighter" onClick={() => { playback(val.detail, val.userId) }}>
                                                    <span className="fas fa-play-circle text-white">Playback</span>
                                                </button>
                                            </div>

                                            {/* <center >
                                                {val.user_pic ? <img src={("http://images.pacificweb.com.ph/pockethr/profilepic/" + val.user_pic)} style={{ width: 60 }}
                                                /> : <img src={require('../../img/user.png')} style={{ width: 70 }} />}
                                            </center>
                                            <div className="text-center">
                                                <p>{val.completeName}</p>
                                                <span><i className="fas fa-circle text-green-lighter"></i> <label className="text-green-lighter">Active</label></span>
                                            </div>
                                            <h4 className="m-b-15">Employee Details</h4>
                                            <form>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Email address</label>
                                                    <div className="col-md-9">
                                                        <input type="email" className="form-control m-b-5" placeholder="Enter email" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Email address</label>
                                                    <div className="col-md-9">
                                                        <input type="email" className="form-control m-b-5" placeholder="Enter email" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-group row m-b-15">
                                                    <label className="col-form-label col-md-3">Email address</label>
                                                    <div className="col-md-9">
                                                        <input type="email" className="form-control m-b-5" placeholder="Enter email" readOnly />
                                                    </div>
                                                </div>

                                            </form> */}
                                        </div>


                                    </div>
                                })}
                            </div>
                        }
                    </div>
                </div>

                <div className={this.state.sidenavActive ? 'theme-panel theme-panel-lg d-none d-sm-block' : 'theme-panel theme-panel-lg active d-none d-sm-block'}>
                    <a href="javascript:;" data-click="theme-panel-expand" className="theme-collapse-btn" onClick={this.handleSidebar}><i className="fa fa-desktop"></i></a>
                    {/* <a href="javascript:;" data-click="theme-panel-expand" className="theme-collapse1-btn" onClick={() => { this.setState({ dashshow: true }) }}><i className="fa fa-chart-area"></i></a> */}
                    <div className="theme-panel-content">
                        <div className="navmedia">
                            <div className="navtitle">
                                <h1 className="page-header"><i className="fas fa-map-marked-alt"></i>&nbsp;Map Dashboard</h1><br />
                            </div>
                            <div className="navnav">
                                <div name="navigation1" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "rgb(21, 27, 38)", txtcolor1: "white" })
                                        this.setState({ bgcolor2: "transparent", txtcolor2: "black" })
                                        this.setState({ bgcolor3: "transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor4: "transparent", txtcolor4: "black" })
                                    }}>
                                    <a style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }} href="javascript:;" onClick={() => { this.setState({ show: "dashboard" }) }}>DashBoard</a>
                                </div>
                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "rgb(21, 27, 38)", txtcolor2: "white" })
                                        this.setState({ bgcolor1: "transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: "transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor4: "transparent", txtcolor4: "black" })
                                    }}>
                                    <a style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }} href="javascript:;" onClick={() => { this.setState({ show: "Customers" }) }}>Customer</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation3" })
                                        this.setState({ bgcolor3: "rgb(21, 27, 38)", txtcolor3: "white" })
                                        this.setState({ bgcolor1: "transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor2: "transparent", txtcolor2: "black" })
                                        this.setState({ bgcolor4: "transparent", txtcolor4: "black" })
                                    }}>
                                    <a href="javascript:;" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }} onClick={() => { this.setState({ show: "employees" }) }}>Employees</a>
                                </div>

                            </div>
                        </div>

                        {/* Todays Dashboard Page */}
                        {this.state.show === "dashboard" &&
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="widget widget-stats bg-blue">
                                            <div class="stats-icon stats-icon-lg"><i class="fas fa-chart-line fa-fw"></i></div>
                                            <div class="stats-content">
                                                <div class="stats-title text-white">PRESENT TOTAL CLIENTS</div>
                                                <div class="stats-number text-white">{this.state.today_sale}</div>
                                                <div class="stats-desc">{this.state.yesterday_sale}&nbsp;compare to Yesterday</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="widget widget-stats bg-orange">
                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
                                            <div class="stats-content">
                                                <div class="stats-title text-white">OPEN&nbsp;SALES</div>
                                                <div class="stats-number text-white">{this.state.open_accom_details.length}</div>
                                                <div class="stats-progress progress">
                                                    <div class="progress-bar progress-bar-striped bg-orange" style={{ width: this.state.open_percent + '%' }}></div>
                                                </div>
                                                <div class="stats-desc">{this.state.open_percent}&nbsp;%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="widget widget-stats bg-green">
                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
                                            <div class="stats-content">
                                                <div class="stats-title text-white">CLOSED&nbsp;SALES</div>
                                                <div class="stats-number text-white">{this.state.close_accom_details.length}</div>
                                                <div class="stats-progress progress">
                                                    <div class="progress-bar progress-bar-striped bg-orange" style={{ width: this.state.close_percent + '%' }}></div>
                                                </div>
                                                <div class="stats-desc">{this.state.close_percent}&nbsp;%</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <DBar4 barGraph={this.state.status_graph} style={{ color: "white" }} />
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <DBar4 barGraph={this.state.status_graph} />
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <div className="navmedia">
                                            <div className="navtitle panel-title">
                                                <h4 className="panel-title">
                                                    <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="List of deployed employees' accomplishments" data-placement="top" data-content="the table shows the list of accomplishment" data-original-title="" title="" />
                                                    &nbsp;Accomplishment Table</h4>
                                            </div>
                                            <div className="navnav" >
                                                <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
                                                    <div className="input-group pull-right">
                                                        <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Scrollbars style={{ height: 200, width: '100%', overflowX: 'hidden' }}>
                                        <table className="table table-bordered" >
                                            <thead className="tableFixHead ">
                                                <tr className="text-center">
                                                    {/* <th className="bg-default">No.</th> */}
                                                    {/* <th className="bg-default">ID</th> */}
                                                    <th className="bg-default">Name</th>
                                                    {/* <th className="bg-default">Company</th> */}
                                                    {this.state.THead.map((val, index) => {
                                                        return <th className="bg-default">{val.head}</th>
                                                    })}

                                                    {/* <th className="bg-default">Time-out</th> */}

                                                    <th>Progress</th>
                                                    <th>Today Accomplishments</th>
                                                </tr>
                                            </thead>

                                            <tbody className="bg-white text-center tablebordered">
                                                {EmployeeSearch.map((val, index) => {

                                                    var data_date = []
                                                    var quota = val.total_quota

                                                    var percent = 0;
                                                    percent = parseFloat(val.no_band / quota * 100)

                                                    return <tr className="f-w-700" style={{ cursor: 'pointer' }} key={index}>
                                                        {/* <td>{index + 1}</td> */}
                                                        {/* <td>{val.user_id}</td> */}
                                                        <td>{val.completename}</td>
                                                        {/* <td><div><p className="f-w-700">{val.branch_name}</p></div></td> */}
                                                        {this.state.THead.map((val1, index1) => {
                                                            var timesheet = ""

                                                            for (let index2 = 0; index2 < this.state.timesheetData.length; index2++) {
                                                                // console.log(this.state.timesheetData[index]);

                                                                if (val.user_id == this.state.timesheetData[index2].user_id) {

                                                                    // for (let index = 0; index < this.state.timesheetData[index1].timesheet.length; index++){
                                                                    if (this.state.timesheetData[index2].timesheet[index1] === undefined) {
                                                                        timesheet = ""
                                                                    } else {
                                                                        timesheet = moment(this.state.timesheetData[index2].timesheet[index1]).format('LT');
                                                                        var time = { time_data: timesheet }
                                                                        if (time != "") {
                                                                            data_date.push(time);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            return <td>{timesheet}</td>
                                                        })
                                                        }

                                                        <td>
                                                            <div className="progress">
                                                                <div className="progress-bar progress-bar-striped progress-bar-animated  bg-green-lighter" role="progressbar" style={{ width: percent + '%' }}></div>
                                                            </div>
                                                        </td>
                                                        <td>{val.today_no_band}</td>
                                                    </tr>
                                                })}
                                            </tbody>

                                        </table>
                                    </Scrollbars>
                                </div>

                            </div>
                        }

                        {/* Customers Page */}

                        {this.state.show === "Customers" &&
                            <Scrollbars style={{ height: 500, width: '100%' }}>
                                <table className="table" >
                                    <thead className="tableFixHead" >
                                        <tr>
                                            <th style={{ backgroundColor: '#f5f6fa' }}>Company</th>
                                            <th style={{ backgroundColor: '#f5f6fa' }}>Building</th>
                                            <th style={{ backgroundColor: '#f5f6fa' }}>Address</th>
                                            <th style={{ backgroundColor: '#f5f6fa' }}>Mobile</th>
                                            <th style={{ backgroundColor: '#f5f6fa' }}>Contact Person</th>
                                            <th style={{ backgroundColor: '#f5f6fa' }}>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.accomplishment_customers.map((value, index) => {
                                            return <tr key={index}>
                                                <td width="10%">{value.company_name}</td>
                                                <td width="30%">{value.building_name}</td>
                                                <td width="30%">{value.company_address}</td>
                                                <td width="30%">{value.cust_contact_mobile}</td>
                                                <td width="30%">
                                                    <span className="button1">{value.cust_contact_person}</span>
                                                    <button className="button " >Details</button>
                                                </td>
                                                <td width="30%">{value.cust_location}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </Scrollbars>
                        }

                        {/* Employees Report Page */}

                        {this.state.show === "employees" &&
                            <div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                        <div className="widget widget-stats bg-green">
                                            <div className="stats-info">
                                                <h4>On Field</h4>
                                                <p className="text-white">{this.state.monitoring["ob_field"].length}</p>
                                            </div>
                                            <div className="stats-link" onClick={() => {
                                                this.setState({ titletable: "Assigned Employee" });
                                                this.setState({ table_map: this.state.monitoring["ob_field"] })
                                                // console.log(this.state.monitoring["ob_field"])
                                            }}>
                                                <a href="javascript:;">Sort Table&nbsp;<i className="fa fa-arrow-alt-circle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="widget widget-stats bg-blue">
                                            <div className="stats-info">
                                                <h4>Present</h4>
                                                <p className="text-white">{this.state.monitoring["present_field"].length}</p>
                                            </div>
                                            <div className="stats-link" onClick={() => {
                                                this.setState({ titletable: "Present Employee" });
                                                this.setState({ table_map: this.state.monitoring["present_field"] })
                                            }}>
                                                <a href="javascript:;">Sort Table&nbsp;<i className="fa fa-arrow-alt-circle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="widget widget-stats bg-orange">
                                            <div className="stats-info">
                                                <h4>Late</h4>
                                                <p className="text-white">{this.state.monitoring["late_field"].length}</p>
                                            </div>
                                            <div className="stats-link" onClick={() => {
                                                this.setState({ titletable: "Late Employee" });
                                                this.setState({ table_map: this.state.monitoring["late_field"] })
                                            }}>
                                                <a href="javascript:;">Sort Table&nbsp;<i className="fa fa-arrow-alt-circle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="widget widget-stats bg-red">
                                            <div className="stats-info">
                                                <h4>Absent</h4>
                                                <p className="text-white">{this.state.monitoring["absent_field"].length}</p>
                                            </div>
                                            <div className="stats-link" onClick={() => {
                                                this.setState({ titletable: "Absent Employee" });
                                                this.setState({ table_map: this.state.monitoring["absent_field"] })
                                            }}>
                                                <a href="javascript:;">Sort Table&nbsp;<i className="fa fa-arrow-alt-circle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            {this.state.titletable}
                                        </h4>
                                        <div className="form-group pull-right" style={{ marginTop: "-3%" }}>
                                            <div className="input-group pull-right">
                                                <input type="text" className="form-control" id="demo-input-search2" placeholder="Search Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        {/* {this.state.titletable === "Assigned Employee" && */}
                                        <Scrollbars style={{ height: 200, width: '100%' }}>
                                            <table className="table table-bordered" >
                                                <thead className="tableFixHead">
                                                    <tr className="text-center" >
                                                        <th>Name</th>
                                                        <th>Type</th>
                                                        {this.state.THead.map((val, index) => {
                                                            return <th key={index}>{val.head}</th>
                                                        })}
                                                        <th>Assigned Location</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white text-center tablebordered">
                                                    {this.state.table_map.map((val, index) => {
                                                        var details = []
                                                        var split = String(val.details.location_latlng)
                                                        var splitter_latlng = split.split(",");
                                                        details.push(val.details)
                                                        return <tr
                                                        // onClick={() => { this.handleSidebar() }}
                                                        >
                                                            <td onClick={() => {
                                                                // console.log(val.details.location_latlng)
                                                                this.setState({
                                                                    users_data: details,

                                                                })
                                                                setTimeout(() => {
                                                                    this.setState({
                                                                        mapoption: {
                                                                            center: {
                                                                                lat: parseFloat(splitter_latlng[0]),
                                                                                lng: parseFloat(splitter_latlng[1])
                                                                            },
                                                                            zoom: 12,
                                                                        },
                                                                    })
                                                                }, 300)
                                                            }}>{val.details["completename"]}</td>
                                                            <td>{val.att_class}</td>
                                                            {this.state.THead.map((val2, index2) => {
                                                                var date_val = ""
                                                                for (let index3 = 0; index3 < val.present.length; index3++) {
                                                                    if (index2 == index3) {
                                                                        date_val = moment(val.present[index3].date_added).format('LT')
                                                                    }

                                                                }

                                                                return <td>{date_val}</td>
                                                            })}
                                                            {/* {val.present.map((val1, index1) => {
                                                                   
                                                                 
                                                                    console.log(date_val)
                                                                    // return 
                                                                })} */}
                                                            <td>{val.details["category_details"]}</td>
                                                        </tr>
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </Scrollbars>
                                        {/* } */}
                                        {/* {this.state.titletable === "Present Employee" &&
                                            <Scrollbars style={{ height: 200, width: '100%' }}>
                                                <table className="table table-hover" >
                                                    <thead className="tableFixHead">
                                                        <tr className="text-center" >
                                                            <th>Name</th>
                                                            {this.state.THead.map((val, index) => {
                                                                return <th key={index}>{val.head}</th>
                                                            })}

                                                        </tr>
                                                    </thead>

                                                    <tbody className="bg-white text-center tablebordered">
                                                        {this.state.table_map.map((val, index) => {
                                                            // console.log(this.state.table_map)
                                                            return <tr onClick={() => { this.handleSidebar() }}>
                                                                <td>{val.details["completename"]}</td>
                                                                {val.present.map((val1, index1) => {
                                                                    // console.log(val1)
                                                                    return <td key={index1}>{moment(val1.date_added).format('LT')}</td>
                                                                })}
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>

                                                </table>
                                            </Scrollbars>
                                        } */}
                                        {/* {this.state.titletable === "Late Employee" &&
                                            <Scrollbars style={{ height: 200, width: '100%' }}>
                                                <table className="table table-hover" >
                                                    <thead className="tableFixHead">
                                                        <tr className="text-center" >
                                                            <th>Name</th>
                                                            {this.state.THead.map((val, index) => {
                                                                return <th key={index}>{val.head}</th>
                                                            })}
                                                        </tr>
                                                    </thead>

                                                    <tbody className="bg-white text-center tablebordered">
                                                        {this.state.table_map.map((val, index) => {
                                                            return <tr onClick={() => { this.handleSidebar() }}>
                                                                <td>{val.details["completename"]}</td>
                                                                {val.present.map((val1, index1) => {
                                                                    // console.log(val1)
                                                                    return <td key={index1}>{moment(val1.date_added).format('LT')}</td>
                                                                })}
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </Scrollbars>
                                        } */}
                                        {/* {this.state.titletable === "Absent Employee" &&
                                            <Scrollbars style={{ height: 200, width: '100%' }}>
                                                <table className="table table-hover" >
                                                    <thead className="tableFixHead">
                                                        <tr className="text-center" >
                                                            <th>Name</th>
                                                            {this.state.titletable != "Absent Employee" && this.state.THead.map((val, index) => {
                                                                return <th key={index}>{val.head}</th>
                                                            })}

                                                        </tr>
                                                    </thead>

                                                    <tbody className="bg-white text-center tablebordered">
                                                        {this.state.table_map.map((val, index) => {
                                                            return <tr onClick={() => { this.handleSidebar() }}>
                                                                <td>{val.details["completename"]}</td>
                                                                {this.state.titletable != "Absent Employee" && val.present.map((val1, index1) => {
                                                                    // console.log(val1)
                                                                    return <td key={index1}>{moment(val1.date_added).format('LT')}</td>
                                                                })}
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </Scrollbars>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Full Dashboard */}

                        {this.state.dashshow === true &&
                            <div className="panel panel-expand panel-inverse">
                                <div className="panel-heading" style={{ paddingBottom: 10 }}>
                                    <div className="panel-title">
                                        <p className="pull-left text-white m-t-5 f-s-20">Sales Dashboard</p>
                                        <a href="javascript:;" className="btn-circle btn-danger pull-right" onClick={() => { this.setState({ dashshow: false }) }} style={{ textDecoration: "none" }}><i className="fa fa-times text-center"></i></a>
                                    </div>
                                </div>
                                <div className="panel-body ">
                                    <Dashboard />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        );
    }
}

export default Maps;