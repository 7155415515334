import React, { Component } from 'react';
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment, { now } from 'moment';
import Header from '../Header_Page/index';
import Cards from '../Cards/index';
import noimage from '../../pocketHR/sales/clients/img/nophoto.png'

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalnav1: "#32a932",
            modalnav2: "",
            modalnav3: "",
            modalnav4: "",
            modalnav5: "",
            modalcol1: "white",
            modalcol2: "",
            modalcol3: "",
            modalcol4: "",
            modalcol5: "",
            equipments: [
                {
                    serial: '20010902-01',
                    category: 'Laptop',
                    name: 'Programmer Laptop',
                    make: 'Lenovo',
                    model: 'E470',
                    entities: 'Ben Louie Casapao',
                    status: 'Deployed'
                },
                {
                    serial: '20010904-02',
                    category: 'Monitor',
                    name: 'Monitor 21" lenovo',
                    make: 'Lenovo',
                    model: 'Lenovo ThinkVision Express',
                    entities: 'Ben Louie Casapao',
                    status: 'Deployed'
                },
            ],
            timelogs : [
                {
                    id:25,
                    datetime: '2020-01-09 07:38:57  08:38:57',
                    duration: '01:00',
                    entity: 'Ben Louie Casapao',
                    status: 'Running'
                },
            ],
            purchasedDate: now()
        };
    }

    handlePurchedDateChange = date => {
        this.setState({
            purchasedDate: date
        });
    };


    render() {
        return (
            <div id="content" className="content">
                <Header />
                <Cards />
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h5 className="panel-title"><i className="fa fa-list-alt"></i> Items Master List</h5>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="pull-right">
                                    <button className="m-r-10 btttn bg-success" data-target="#addAsset" data-toggle="modal" >Add Equipment</button>
                                </div>
                                <div className="pull-left">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search2" placeholder="Search Name" autocomplete="off" />
                                        <span class="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 m-t-15">
                                <Scrollbars style={{ height: 350 }}>
                                    <table className="table f-s-14" >
                                        <thead className="tableFixHead ">
                                            <tr>
                                                <th >Serial No.</th>
                                                <th >Category</th>
                                                <th >Asset Name</th>
                                                <th >Model</th>
                                                <th >Make</th>
                                                <th >Related Entities</th>
                                                <th >Status</th>
                                                <th></th>

                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {this.state.equipments.map(equips =>
                                                <tr>
                                                    <td>{equips.serial}</td>
                                                    <td>{equips.category}</td>
                                                    <td>{equips.name}</td>
                                                    <td>{equips.model}</td>
                                                    <td>{equips.make}</td>
                                                    <td>{equips.entities}</td>
                                                    <td>{equips.status}</td>
                                                    <td><button className="btttn bg-blue-transparent-9 fa fa-info-circle" data-target="#demo-modal-lg" data-toggle="modal" >Details</button></td>
                                                </tr>
                                            )}

                                        </tbody>

                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-modal-lg" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog  modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Programmer Laptop</h4>
                                <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        {/* <img src={noimage} /> */}
                                        <table className="m-t-10 table table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>Deployed</td>
                                                </tr>
                                                <tr>
                                                    <td>Serial Number</td>
                                                    <td>20010902-01</td>
                                                </tr>
                                                <tr>
                                                    <td>Model</td>
                                                    <td>E470</td>
                                                </tr>
                                                <tr>
                                                    <td>Make</td>
                                                    <td>Lenovo</td>
                                                </tr>
                                                <tr>
                                                    <td>Serial Number</td>
                                                    <td>20010902-01</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="flex-container  text-center m-t-10" >
                                            <div >
                                                <span onClick={() => {
                                                    this.setState({
                                                        modalnav2: "white",
                                                        modalnav3: "white",
                                                        modalnav4: "white",
                                                        modalnav5: "white",
                                                        modalcol1: 'white',
                                                        modalcol2: 'black',
                                                        modalcol3: 'black',
                                                        modalcol4: 'black',
                                                        modalcol5: 'black'
                                                    })
                                                }}>
                                                    <label style={{ backgroundColor: this.state.modalnav1, color: this.state.modalcol1, padding: 10, borderRadius: 12, transition: '0.99s' }}>Time Log</label>
                                                </span>
                                            </div>
                                            <div className="m-l-10">
                                                <span onClick={() => {

                                                    this.setState({
                                                        modalnav1: "white",
                                                        modalnav2: "#32a932",
                                                        modalnav3: "white",
                                                        modalnav4: "white",
                                                        modalnav5: "white",
                                                        modalcol1: 'black',
                                                        modalcol2: 'white',
                                                        modalcol3: 'black',
                                                        modalcol4: 'black',
                                                        modalcol5: 'black'
                                                    })
                                                }}>
                                                    <label style={{ backgroundColor: this.state.modalnav2, color: this.state.modalcol2, padding: 10, borderRadius: 12, transition: '0.99s' }}>Issues</label>
                                                </span>
                                            </div>
                                            <div className="m-l-10">
                                                <span onClick={() => {
                                                    this.setState({
                                                        modalnav1: "white",
                                                        modalnav2: "white",
                                                        modalnav3: "#32a932",
                                                        modalnav4: "white",
                                                        modalnav5: "white",
                                                        modalcol1: 'black',
                                                        modalcol2: 'black',
                                                        modalcol3: 'white',
                                                        modalcol4: 'black',
                                                        modalcol5: 'black',
                                                    })
                                                }}>
                                                    <label style={{ backgroundColor: this.state.modalnav3, color: this.state.modalcol3, padding: 10, borderRadius: 12, transition: '0.99s' }}>Tickets</label>
                                                </span>
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-blue">
                                                    <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                                                    <div className="stats-content">
                                                        <div className="stats-title text-white">Run Time</div>
                                                        <div className="stats-number text-white">12d 15hrs</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-info">
                                                    <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                                                    <div className="stats-content">
                                                        <div className="stats-title text-white">Down Time</div>
                                                        <div className="stats-number text-white">12hrs</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-success">
                                                    <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                                                    <div className="stats-content">
                                                        <div className="stats-title text-white">On Maintenance</div>
                                                        <div className="stats-number text-white">12 Times</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-warning">
                                                    <div className="stats-icon stats-icon-lg"><i className="fa fa-globe fa-fw"></i></div>
                                                    <div className="stats-content">
                                                        <div className="stats-title text-white">Age</div>
                                                        <div className="stats-number text-white">15 Days</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <Scrollbars style={{ height: 350 }}>
                                                <table className="table f-s-14" >
                                                    <thead className="tableFixHead ">
                                                        <tr>
                                                            <th >ID</th>
                                                            <th >Date & Time</th>
                                                            <th >Duration</th>
                                                            <th >Entity</th>
                                                            <th >Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {this.state.timelogs.map(logs =>
                                                            <tr>
                                                                <td>{logs.id}</td>
                                                                <td>{logs.datetime}</td>
                                                                <td>{logs.duration}</td>
                                                                <td>{logs.entity}</td>
                                                                <td>{logs.status}</td>
                                                            </tr>
                                                        )}

                                                    </tbody>

                                                </table>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>

                <div id="addAsset" className="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog  modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Add New Equipment</h4>
                                <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="asset_name">Asset Name</label>
                                            <input name="asset_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="asset_name">Category</label>
                                            <select name="" id="" className="form-control">
                                                <option value="Laptop">Laptop</option>
                                                <option value="Monitor">Monitor</option>
                                                <option value="Large Equipment">Large Equipment</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="asset_name">Supplier</label>
                                            <input name="asset_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date Purchased</label> <br />
                                            <DatePicker
                                                selected={this.state.purchasedDate}
                                                highlightDates={this.state.dateData}
                                                // onChange={this.handleChange}
                                                onChange={this.handlePurchedDateChange}
                                                className="form-control"
                                                onMonthChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="asset_name">Model</label>
                                            <input name="asset_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="asset_name">Make</label>
                                            <input name="asset_name" type="text" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <button className="btttn bg-success">Add Asset</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;