import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import pockethr from './img/pockethr.png'
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
      refresh: false,
      // hours: '',
      // minutes: '',
      // seconds: '',
    };
  }

  componentDidMount() {
    // var time = moment(this.state.time).format('LTS')
    // var splittime = time.split(":")
    // var hours = splittime[0]
    // var minutes = splittime[1]
    // var seconds = splittime[2]
    // var splitsecond = seconds.split(" ")
    // var splittedsecond = splitsecond[0]
    // alert(splittedsecond)
    localStorage.setItem("pageNotFound", false);
    this.setState({
      refresh: true,
      // hours: hours,
      // minutes: minutes,
      // seconds: splittedsecond,
    })
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );

  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: new Date().toLocaleString()
    });
  }
  render() {

    return (
      <div id="content" className="content bg-green-lighter">
        <div className="row " style={{ height: '100%', paddingBottom: '3%' }}>
          <div className="col-md-7" style={{ marginTop: 60 }}>
            <div className="text-center">
              <h1><b>Pacificweb</b> Systems <small className="text-white"> Apps</small></h1>
            </div>

            <div className="text-center " style={{ marginTop: '20%' }}>
              <div className="row m-t-20">
                <div className="col-md-12 text-left">
                  <span className="text-black" style={{ fontSize: 20 }}>
                    {moment(this.state.time).format('dddd')},&nbsp;
                  {moment(this.state.time).format('LL')}
                  </span>
                </div>
              </div>
              <div className="row">
              <div className="col-md-3 p-0">
                <div className="col-md-12 ">
                  <div className="pluses1">
                    {moment(this.state.time).format('h')}<br />
                    <span style={{ fontSize: 15, color: 'black' }}>Hours</span>
                  </div>
                </div>
              </div>

              <div className="col-md-3 p-0">
                <div className="col-md-12 ">
                  <div className="pluses1">
                    {moment(this.state.time).format('mm')}<br />
                    <span style={{ fontSize: 15, color: 'black' }}>Minutes</span>
                  </div>
                </div>
              </div>

              <div className="col-md-3 p-0">
                <div className="col-md-12 ">
                  <div className="pluses1" >
                    {moment(this.state.time).format('ss')}<br />
                    <span style={{ fontSize: 15, color: 'black' }}>Seconds</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 p-0">
                <div className="col-md-12 ">
                  <div className="pluses2">
                    {moment(this.state.time).format('A')}<br />
                    <span style={{ fontSize: 15, color: 'black' }}>Midday</span>
                  </div>
                </div>
              </div>
            </div >

          </div>
          </div>

          <div className="col-md-5 d-block d-md-sm ">
            <img src={pockethr} width='70%' height='100%' className="pull-right" />
          </div>
        </div>
        <br />


      </div>
    );
  }
}

export default Home;