import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
// import LateSettings from './late_setting';
// import GeneralSettings from './general_settings';
import AdditionalDeduction from './additional_deduction'
import DeductionAdjustment from './deduction_adjustment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const format = 'h:mm a';
// import PAGE from'./employeeDetails'

// import console = require('console');
class PayrollAdjustment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterEndDate: new Date(),
            filterStartDate: new Date(),
            companies: [],
            branches: [],
            employee: [],
            company_selected: [],
            branches: [],
            break: 0,
            percentage: 0,
            list: [],
            timestater: new Date(),
            timeender: new Date(),
            delete_id: '',
            employee_selected: [],
            switch_page: "LateSettings",
            navbar :"navigation1"

        }
        // this.textChange = this.textChange.bind()
        this._sumbit = this._sumbit.bind(this)
        this._filter = this._filter.bind(this)
        this.handleChangeTimeStart = this.handleChangeTimeStart.bind(this)
        this.handleChangeTimeEnd = this.handleChangeTimeEnd.bind(this)
    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
            })
    }
    componentDidMount() {
        this.getCompanies()
        this.fetch_attendanceSched();
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, employee: [] })
        setTimeout(() => { this.getBranch() }, 200)
    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {
                this.setState({ branches: response.data, branch_selected: "select branch" })
            })
    }
    showBYbranch = e => {


        e.preventDefault();

        this.setState({ branch_selected: e.target.value })
        this.fetchEmployee(e.target.value)
    }
    fetchEmployee(branch) {
        getData("humanResource/getUsers_branch", { branch: branch })
            .then(response => {
                console.log(response);
                this.setState({ employee: response.response })
            })
    }
    _sumbit(e) {
        e.preventDefault();
        let data = {
            timeender: moment(this.state.timeender).format('hh:mm A'),
            timestater: moment(this.state.timestater).format('hh:mm A'),
            percentage: this.state.percentage,
            employee: this.state.employee_selected,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            hr_id: localStorage.getItem("u"),
            break: this.state.break
        }
        // console.log(data);
        getData("humanResource/additional_sched", { data })
            .then((response) => {
                this.setState({ total_hours: 0, percentage: 0, employee_selected: [] })
                document.getElementsByName('break')[0].value = ''
                document.getElementsByName('percentage')[0].value = ''

                this.fetch_attendanceSched();
                $('#submit_close').click()
                // console.log(response);
            })
    }
    filterDateStart = e => {
        console.log(e)
        this.setState({
            filterStartDate: e
        });

    }
    filterDateEnd = e => {
        console.log(e)
        this.setState({
            filterEndDate: e
        });

    }
    showEmployee = (e) => {
        var options = e.target.options;
        var value = [];
        console.log(this.state.employee)
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                if (options[i].value == 'ALL') {
                    // for(let k=0;k< this.state.employee.length;k++){
                    //     value.push(this.state.employee[i].user_id);
                    // }
                    value.push("ALL")
                } else {
                    value.push(options[i].value);
                }
            }
        }
        if (value == 'ALL') {
            let emp = this.state.employee
            for (let k = 0; k < emp.length; k++) {
                value.push(emp[k].user_id);
            }
        }
        this.setState({ employee_selected: value })
    }

    fetch_attendanceSched = () => {
        getData("humanResource/get_additional_sched", { u_id: localStorage.getItem("u") })
            .then((response) => {
                this.setState({ list: response })
            })
    }
    _filter(e) {
        e.preventDefault();
        let data = {
            employee: this.state.employee_selected,
        }
        getData("humanResource/filter_additional_sched", { data })
            .then((response) => {
                $('#filter_close').click()
                this.setState({ list: response })
            })
    }
    handleChangeTimeStart(value) {
        this.setState({ timestater: value })
    }
    handleChangeTimeEnd(value) {

        this.setState({ timeender: value })
    }
    deleteRecord(id) {
        getData("humanResource/delete_duty", { id })
            .then((response) => {
                console.log(response)
                if (response == true) {
                    if (this.state.employee_selected.length != 0) {
                        let data = {
                            employee: this.state.employee_selected,
                        }
                        getData("humanResource/filter_additional_sched", { data })
                            .then((response) => {
                                $('#filter_close').click()
                                this.setState({ list: response })
                            })
                    } else {
                        this.fetch_attendanceSched();
                    }

                    $('#delete_close').click()
                }

                // this.setState({ list: response })
            })
    }
    render() {
        console.log(this.state.total_hours)
        // console.log(this.state.branch_select)
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }

        return (
            <div id="content " className="content" style={{ paddingLeft: 30, paddingRight: 20 }}>
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Payroll Adjustment</h4>
                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Basic</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Holidays</a></li>
                                        }
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav " >
                                <div name="navigation1" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor2: "transparent", txtcolor2: "black" })
                                        this.setState({ bgcolor3: "transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor4: "transparent", txtcolor4: "black" })
                                    }}>
                                    <a href="#/payroll" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}>Basic</a>
                                </div>
                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                        this.setState({ bgcolor1: " transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/holidays" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Overtime</a>
                                </div>

                                {/* <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollProfile" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Earnings/Deductions</a>
                                </div>
                                <div name="navigation2" className="attendnav"

                                >
                                    <a href="#/governmentDues" >Government&nbsp;Dues</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">

                        <AdditionalDeduction />
                      <hr/>
                        <DeductionAdjustment />


                    </div>
                </div>
                <div id="demo-lg-modal-DELETE" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete Data</h4>
                                {/* <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want delete this record?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" onClick={() => this.deleteRecord(this.state.delete_id)} data-dismiss="modal" aria-label="Close" >
                                    &nbsp;Delete
                                </button>
                                <button id='delete_close' className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="submit" data-dismiss="modal" aria-label="Close" onClick={() => this.state.deleteUser = []}>
                                    &nbsp;Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo-lg-modal11" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <form onSubmit={this._sumbit}>
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="panel-title">Additional Basic Attendance</h4>
                                    <button id='submit_close' data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9">
                                        <span className="fa fa-times-circle "></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="panel">
                                        <div className="panel-body solid" >

                                            <label>Select Company</label>
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" onChange={this.showBYcompanies} required>
                                                    <option ></option>
                                                    {this.state.companies.map((val, index) =>
                                                        <option value={val.company_id}>{val.company_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Branch</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                                    <option>--select branch--</option>
                                                    <option></option>
                                                    {this.state.branches.map((branch, index) =>
                                                        <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Employee</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%", }}>
                                                <select className="form-control" style={{ height: 150 }} onChange={this.showEmployee} multiple required>
                                                    <option value="ALL">--Select All--</option>
                                                    <option></option>

                                                    {this.state.employee.map((val, index) =>
                                                        <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`.toUpperCase()}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <br />
                                            <label>Date Range</label>
                                            <div className="row" style={{ marginLeft: "11%" }}>
                                                <div className="form-inline">

                                                    <div className="form-group m-r-10" >
                                                        <DatePicker
                                                            selected={this.state.filterStartDate}
                                                            selectsStart
                                                            startDate={this.state.filterStartDate}
                                                            endDate={this.state.filterEndDate}
                                                            onChange={this.filterDateStart}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group m-r-10" >
                                                        <DatePicker
                                                            selected={this.state.filterEndDate}
                                                            selectsEnd
                                                            startDate={this.state.filterStartDate}
                                                            endDate={this.state.filterEndDate}
                                                            onChange={this.filterDateEnd}
                                                            className="form-control"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <label>Time Range</label>
                                            <div className="row" style={{ marginLeft: "11%" }}>
                                                <div className="form-inline">

                                                    <div className="form-group m-r-10" >
                                                        <TimePicker
                                                            showSecond={false}
                                                            onChange={this.handleChangeTimeStart}
                                                            placeholder={moment(this.state.timestater).format('LT')}
                                                            format={format}
                                                            use12Hours
                                                            required
                                                        // defaultValue={this.state.timeStart1}
                                                        />
                                                    </div>
                                                    <div className="form-group m-r-10" >
                                                        <TimePicker
                                                            showSecond={false}
                                                            format={format}
                                                            placeholder={moment(this.state.timeender).format('LT')}
                                                            use12Hours
                                                            onChange={this.handleChangeTimeEnd}
                                                            // defaultValue={this.state.timeEnd1}
                                                            required
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <label>Break Time</label>
                                            <div className="row" style={{ marginLeft: "11%" }}>
                                                <div className="form-group m-r-10" >
                                                    <input type='number' className='form-control' name='break' value={this.state.break} onChange={(e) => this.setState({ break: e.target.value })} required />
                                                </div>
                                            </div>
                                            <br />
                                            <label>Differential Percentage per Hour</label>
                                            <div className="row" style={{ marginLeft: "11%" }}>
                                                <div className="form-group m-r-10" >
                                                    <div >
                                                        <input type='number' className='form-control' name='percentage' value={this.state.percentage} placeholder='example:10' onChange={(e) => this.setState({ percentage: e.target.value })} required />
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            {/* <div className="form-check form-check-inline" tooltip="Check to APPLY Government Deduction">
                                                <input type="checkbox" value="" id="checkbox-inline" className="form-check-input m-l-10" onChange={() => this.setState({ govenment_cont: !this.state.govenment_cont })} />
                                                <label className="form-check-label m-l-5" for="checkbox-inline">Government Deduction</label>
                                            </div> */}
                                            <br />
                                            <div className="pull-right">
                                                <button className="bttnpayroll bg-green-lighter" type='submit' >
                                                    <span className="fa fa-plus "></span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="demo-lg-filter" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <form onSubmit={this._filter}>
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="panel-title">Filter</h4>
                                    <button id='filter_close' data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9">
                                        <span className="fa fa-times-circle "></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="panel">
                                        <div className="panel-body solid" >

                                            <label>Select Company</label>
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" onChange={this.showBYcompanies} required>
                                                    <option ></option>
                                                    {this.state.companies.map((val, index) =>
                                                        <option value={val.company_id}>{val.company_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Branch</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                                    <option>--select branch--</option>
                                                    <option></option>
                                                    {this.state.branches.map((branch, index) =>
                                                        <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                    )}
                                                </select>
                                            </div><br />
                                            <label>Select Employee</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%", }}>
                                                <select className="form-control" style={{ height: 150 }} onChange={this.showEmployee} multiple required>
                                                    <option value="ALL">--Select All--</option>
                                                    <option></option>

                                                    {this.state.employee.map((val, index) =>
                                                        <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`.toUpperCase()}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <br />

                                            <br />
                                            {/* <div className="form-check form-check-inline" tooltip="Check to APPLY Government Deduction">
                                                <input type="checkbox" value="" id="checkbox-inline" className="form-check-input m-l-10" onChange={() => this.setState({ govenment_cont: !this.state.govenment_cont })} />
                                                <label className="form-check-label m-l-5" for="checkbox-inline">Government Deduction</label>
                                            </div> */}
                                            <br />
                                            <div className="pull-right">
                                                <button className="bttnpayroll bg-green-lighter" type='submit' >
                                                    <span className="fa fa-plus "></span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>


            </div>
        );
    }
}

export default PayrollAdjustment;