import React, { Component, Fragment } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import PAGE from'./employeeDetails'

// import console = require('console');


class EarningDeduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterEndDate: new Date(),
            filterStartDate: new Date(),
            payrollData: [],
            alert: false,
            alertWarning: false,
            branches: [],
            branch_selected: "select branch",
            payroll_data: [],
            Branch: "",
            MonthPeriod: "",
            showEmpPayroll: false,
            searchDriver: "",
            redirectpayrollProfile: false,
            companies: [],
            company_selected: "",
            listEmp: [],
            deduction: [],
            responsedata: [],
            earning: [],
            DATA: [],
            selection: false,
            route: "",
            colspan_earning: 0,
            colspan_deduction: 0,
            pay_id: 0,
            earningAmount: 0,
            deductionAmount: 0,
            govenment_cont: false,
            selectBranches: [],
            refresh: false,
            count: 0,
            philH_EE: 0,
            payroll_details_id: '',
            user_ID: '',
            bgcolor1: "#404040",
            bgcolor2: " transparent",
            txtcolor1: "white",
            txtcolor2: "black",
            navbar: "navigation1",
            firstCutoff: 0,
            employee: [],
            employee_selected: []
        }
     
    }
  
    render() {
        // console.log(this.state.govenment_cont);

        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }
        if (this.state.redirectpayrollProfile == true) {
            return <Redirect to='/payrollProfile' />
        }

       
        return (

            <div id="content " className="content " >
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                {this.state.navbar === "navigation1" &&
                                    <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Earning and Deduction</h4>
                                }
                            </div>
                         
                          

                        </div>
                    </div>
                    <div className='panel-body'>
                        <table className="table table-td-valign-middle table-bordered">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>File Name</th>
                                    <th>Date Added</th>
                                    <th>Branch</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {/* {this.state.file_details.map((val, index) => {
                                    return <tr key={index}>
                                <td>{val.upload_timesheet_id}</td>
                                <td>{val.upload_timesheet_file_name}</td>
                                        <td>{val.upload_timesheet_date_added}</td>
                                <td>{val.branch_name}</td>
                                  
                                    </tr>

                                })} */}

                            </tbody>
                        </table>
                   
                            </div>
                </div>
            </div>


        );
    }
}

export default EarningDeduction;