import React, { Component } from 'react';
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import AmCharts from "@amcharts/amcharts3-react";
// am4core.useTheme(am4themes_animated);

class DBar3 extends Component {
    state = {  
    }
   
    // chart(){
    //     am4core.useTheme(am4themes_animated);
    //         // Themes end

    //     // Create chart instance
    //     var chart = am4core.create("chartdiv2", am4charts.XYChart);


    //     // Add data
    //     chart.data = [{
    //     "year": "January",
    //     "europe": 2.5,
    //     "namerica": 2.5,
    //     }, {
    //     "year": "February",
    //     "europe": 2.6,
    //     "namerica": 2.7,
    //     }, {
    //     "year": "March",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "April",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "May",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "June",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "July",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "August",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "September",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "October",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "November",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }, {
    //     "year": "December",
    //     "europe": 2.8,
    //     "namerica": 2.9,
    //     }
    
    // ];


    //     // Create axes
    //     var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    //     categoryAxis.dataFields.category = "year";
    //     categoryAxis.renderer.grid.template.location = 0;


    //     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //     valueAxis.renderer.inside = true;
    //     valueAxis.renderer.labels.template.disabled = true;
    //     valueAxis.min = 0;
    //     valueAxis.renderer.ticks.template.stroke = am4core.color("#2ecc71");

    //     // Create series
    //     function createSeries(field, name) {
        
    //     // Set up series
    //     var series = chart.series.push(new am4charts.ColumnSeries());
    //     series.name = name;
    //     series.dataFields.valueY = field;
    //     series.dataFields.categoryX = "year";
    //     series.sequencedInterpolation = true;
        
    //     // Make it stacked
    //     series.stacked = true;
        
    //     // Configure columns
    //     series.columns.template.width = am4core.percent(60);
    //     series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
        
    //     // Add label
    //     var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    //     labelBullet.label.text = "{valueY}";
    //     labelBullet.locationY = 0.5;
        
    //     return series;
    //     }

    //     createSeries("europe", "Europe");
    //     createSeries("namerica", "North America");
 

    //     // Legend
    //     chart.legend = new am4charts.Legend();
    // }
    // componentDidMount(){
    //     this.chart();
    // }
    render() { 
        const {barGraph} = this.props;
        var config = {
            "hideCredits":true,
            "type": "serial",
            "categoryField": "month",
            "colors": [
                "#f59c1a",
                "#32a932",
            
            ],
            "startDuration": 1,
            "categoryAxis": {
                "gridPosition": "start"
            },
            "trendLines": [],
            "graphs": [
                {
                    "balloonText": "[[title]] of [[month]]:[[value]]",
                    "fillAlphas": 1,
                    "id": "AmGraph-1",
                    "title": "Open Sales",
                    "type": "column",
                    "valueField": "open"
                },
                {
                    "balloonText": "[[title]] of [[month]]:[[value]]",
                    "fillAlphas": 1,
                    "id": "AmGraph-2",
                    "title": "Closed Sales",
                    "type": "column",
                    "valueField": "close"
                }
            ],
            "guides": [],
            "valueAxes": [
                {
                    "id": "ValueAxis-1",
                    "stackType": "regular",
                    "title": ""
                }
            ],
            "allLabels": [],
            "balloon": {},
            "legend": {
                "enabled": true,
                "useGraphSettings": true
            },
            
            "dataProvider":barGraph
        }
         
        
        
        return ( 
            
            // <div id="chartdiv2" style={{ width: "100%", height: "335px" }}></div>
            <AmCharts.React
            style={{
                width: "100%",
                height: "350px"
              }}
            options={config}
            />
        );
    }
}
 
export default DBar3;