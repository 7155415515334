import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import 'intro.js/introjs.css';
import moment from 'moment';
import { getData } from '../../api/api';
import $ from "jquery";


class Position extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor1: "transparent",
            bgcolor2: "transparent",
            bgcolor4: "transparent",
            bgcolor3: "#404040",
            txtcolor1: "black",
            txtcolor2: "black",
            txtcolor3: "white",
            txtcolor4: "black",
            navbar: "navigation3",
            getUserDetails: [],
            positions: [],
            searchDriver: ""
        };
        this.textchange = this.textchange.bind(this);
        this.submitPosition = this.submitPosition.bind(this);
        this.searchDriver = this.searchDriver.bind(this);
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    getuserDetails() {
        getData("helpdesk/getUserDetails", localStorage.getItem('u')).then(res => {
            this.setState({ getUserDetails: res });
            this.getPosition(res);

        })
    }
    submitPosition(e) {
        e.preventDefault();
        let user = this.state.getUserDetails;
        var data = { position_name: this.state.position_name, position_suffix: this.state.position_suffix, position_code: this.state.position_code, company_id: user[0].company_id }
        getData("position/addPositionbyID", data).then(response => {
            // console.log(res)
            if (response.response === true) {
                alert("Job Position Added Successfully");
                this.getPosition(user);
            } else {
                alert("Failed to Add Job Position")
            }
        })
        // console.log(data)
    }
    getPosition(user) {
        // let id = this.state.getUserDetails;
        var data = { company_id: user[0].company_id }
        // console.log(data)

        getData("position/getPositionbyID", data).then(res => {
            // console.log(res)
            this.setState({ positions: res })
        })
    }
    componentDidMount() {
        this.getuserDetails();
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    render() {
        let PositionSearch = this.state.positions.filter(
            (files) => {
                return files.userjob_position.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (
            <div className="content" id="content">

                <div className="panel" style={{ boxShadow: " 0px 0px 10px 0px rgba(140,140,140,0.50)" }}>
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                {this.state.navbar === "navigation1" &&
                                    <h4 className="page-header"><i className="fas fa-users"></i>&nbsp;Master List</h4>
                                }
                                {this.state.navbar === "navigation2" &&
                                    <h4 className="page-header"><i className="far fa-building"></i>&nbsp;Department List</h4>
                                }
                                {this.state.navbar === "navigation3" &&
                                    <h4 className="page-header"><i className="fas fa-user-tag"></i>&nbsp;Position</h4>
                                }
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        {this.state.navbar === "navigation1" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Master&nbsp;List</a></li>
                                        }
                                        {this.state.navbar === "navigation2" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Department</a></li>
                                        }
                                        {this.state.navbar === "navigation3" &&
                                            <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Position</a></li>
                                        }

                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav">
                                <div className="attendnav">
                                    <a href="#/department">Department</a>
                                </div>
                                <div className="attendnav">
                                    <a href="#/position">Position</a>
                                </div>
                                <div className="attendnav" >
                                    <a href="#/matrix">Salary&nbsp;Matrix</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-green-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;New Position</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search" placeholder="Search Position" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Scrollbars style={{ height: 350 }}>
                            <table className="table table-bordered">
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>Position ID</th>
                                        <th>Position Name</th>
                                        <th>Position Suffix</th>
                                        <th>Position Code</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {PositionSearch.map(data => {
                                        return <tr>
                                            <td>{data.position_id}</td>
                                            <td>{data.userjob_position}</td>
                                            <td>{data.position_suffix}</td>
                                            <td>{data.position_code}</td>
                                        </tr>
                                    })}
                                </tbody>

                            </table>
                        </Scrollbars>
                    </div>
                </div>
                <div id="demo-lg-modal" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"></h4>
                                <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.submitPosition}>
                                    <fieldset>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Position Name</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="position_name" placeholder="Name" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Position Suffix</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="position_suffix" placeholder="Suffix" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">Position Code</label>
                                            <input type="text" className="form-control" onChange={this.textchange} name="position_code" placeholder="Code" required />
                                        </div>
                                        <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                            &nbsp;Create
                                    </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Position;