import React, { Component } from 'react';
import { getData211 } from "../../../../api/api";
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Logs from "../history/index";

import Assign from "../deployment/assign";
// import Client from "../clients/index";
import Dashboard from "../users/index";
import noimage from '../clients/img/nophoto.png';
class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchDriver: "",
            users: [],
            bgcolor1: "#ededed",
            bgcolor2: "#404040",
            bgcolor3: "#ededed",
            bgcolor4: "#ededed",
            txtcolor1: "black",
            txtcolor2: "white",
            txtcolor3: "black",
            txtcolor4: "black",
            route: "Client",
            client: [],
            solopic: [],
            multipic: [],
            closingdata: [],
            closing: 0,
            dateclosed: "",
            itemsold: 0,
            contract: "",
            closingdetails: []
        }
        // this.setState.client = client;
        this.changeSoloPic = this.changeSoloPic.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }
    _isMounted = true;

    users() {
        let pass = {
            'u_id': localStorage.getItem("u")
        }
        getData211("field_audit/getCustomers", pass).then(result => {
            // console.log(pass)
            this.setState({ users: result });
        })
    }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    componentDidMount() {
        this.users();
    }

    componentWillUnmount() {
        if (localStorage.getItem("pass") !== "clients") {
            this._isMounted = false
        }

    }
    update() {
        if (localStorage.getItem("pass") === "clients") {
            setTimeout(() => {
                this.users();
                this.update();
            }, 5000);
        } else {
            clearTimeout(this.update);
        }
    }
    getSingleClient(data) {
        // console.log(data);
        for (let i = 0; i < data.length; i++) {

        }
        let client = [];
        client.push(data);
        this.setState({ client: client, closing: 0 })
        let solo = [];
        let pictures = [];

        for (let i = 0; i < 1; i++) {
            solo.push(data.images[i]);
        }

        for (let i = 0; i < data.images.length; i++) {
            pictures.push(data.images[i]);
        }

        this.setState({ solopic: solo });
        this.setState({ multipic: pictures });

    }
    changeSoloPic(e) {
        let data = this.state.multipic;
        let solo = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].cust_image_id === e.target.id) {
                solo.push(data[i]);
            }
        }
        this.setState({ solopic: solo })
    }
    updateStatus(data) {
        // console.log(data)
        getData211('users/updateCustomerStatus', { cust_id: data.cust_id })
            .then((response) => {
                if (response.response == true) {
                    alert("Closed Succesfully")
                    this.users();
                }
            })
    }
    passData(data) {

        this.setState({ closingdata: data, closing: 0 })
    }
    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ type: options[i].value })
            }
        }
    }
    handleChange = date => {
        // console.log(date)
        this.setState({
            startDate: date
        });

    }
    filterDateStart = e => {
        setTimeout(() => {
            this.setState({
                from_date: e
            });

        // console.log(this.state)
    })}
    formSubmit = (e) => {
        e.preventDefault();
        // console.log(this.itemsold.value)
        // console.log(this.contract.value)

        var details = {
            dateclosed: this.state.startDate,
            itemsold: this.itemsold.value,
            contract: this.contract.value
        }
        this.setState({ closingdetails: details })

    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    changeSoloPic(e) {
        let data = this.state.multipic;
        let solo = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].cust_image_id === e.target.id) {
                solo.push(data[i]);
            }
        }
        this.setState({ solopic: solo })
    }
    updateStatus(data) {
        // console.log(data)
        getData211('users/updateCustomerStatus', { cust_id: data.cust_id })
            .then((response) => {
                if (response.response == true) {
                    alert("Closed Succesfully")
                    this.users();
                }
            })
    }
    passData(data) {
        
        this.setState({ closingdata: data, closing: 0 })
    }
    onChangeSelect(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                // console.log(options[i].value)
                this.setState({ type: options[i].value })
            }
        }
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    render() {
        localStorage.setItem("pass", "clients");
        let CustomerSearch = this.state.users.filter(
            (files) => {
                return files.cust_fname.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.cust_lname.toLowerCase().indexOf(
                        this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.cust_status.toLowerCase().indexOf(
                            this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.cust_address.toLowerCase().indexOf(
                                this.state.searchDriver.toLocaleLowerCase()) !== -1 || files.cust_location.toLowerCase().indexOf(
                                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (

            <div>
                {this.state.route === "Client" &&
                    <div>

                        <div className="row">
                            <div className="col-md-6">
                            </div>
                            <div className="form-group pull-right col-md-6">
                                <div className="input-group pull-right" style={{ width: 310 }}>
                                    <input type="text" className="form-control" id="demo-input-search2" placeholder="Search" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                    <span class="input-group-addon"><i className="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="panel">
                            <div className="panel-heading">
                                {/* <h4 className="panel-title">
 Company Clients
 <span className="m-l-2">
 <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Client Company List" data-placement="top" data-content="The table displays the list of company which you can view each detail about the selected company." data-original-title="" title="">
 </i>
 </span>
 </h4> */}
                            </div>
                            <div className="panel-body">
                                <Scrollbars style={{ height: 350, width: '100%' }}>
                                    <table className="table table-hover">
                                        <thead className="tableFixHead">
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Company Address</th>
                                                <th>Location</th>
                                                <th>Date Added</th>
                                                <th>Status</th>
                                                <th style={{ zIndex: 2 }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {CustomerSearch.map(data => {
                                                if (data.cust_status === "Open") {
                                                    return <tr>
                                                        <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                        <td>{data.cust_address}</td>
                                                        <td>{data.cust_location}</td>
                                                        <td>{moment(data.cust_date_added).format('LL')}</td>
                                                        <td className="text-orange">{data.cust_status}</td>
                                                        <td><div class="btn-group m-r-5 m-b-5">
                                                            <a href="#" data-toggle="dropdown" class="btn btn-default   "><b class="fas fa-ellipsis-v"></b></a>
                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                <a href="javascript:;" class="dropdown-item" data-target="#demo-xl-modal1" data-toggle="modal" onClick={() => { this.getSingleClient(data) }}>Details</a>

                                                                <a href="javascript:;" class="dropdown-item" data-target="#modal-details" data-toggle="modal" onClick={() => { this.passData(data) }}>Sale Details</a>

                                                            </div>
                                                        </div></td>
                                                    </tr>
                                                } else if (data.cust_status === "Closed") {
                                                    return <tr>
                                                        <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                        <td>{data.cust_address}</td>
                                                        <td>{data.cust_location}</td>
                                                        <td>{moment(data.cust_date_added).format('LL')}</td>
                                                        <td className="text-green">{data.cust_status}</td>
                                                        <td>
                                                            <div class="btn-group m-r-5 m-b-5">
                                                                <a href="#" data-toggle="dropdown" class="btn btn-default   "><b class="fas fa-ellipsis-v"></b></a>
                                                                <div class="dropdown-menu dropdown-menu-right">
                                                                    <a href="javascript:;" class="dropdown-item" data-target="#demo-xl-modal1" data-toggle="modal" onClick={() => { this.getSingleClient(data) }}>Details</a>
                                                                    <a href="javascript:;" class="dropdown-item" data-target="#modal-details" data-toggle="modal" onClick={() => { this.passData(data) }}>Sale Details</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            })}


                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>
                        <div id="modal-details" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="page-header">Closed Sale Details</h4>
                                        <button data-dismiss="modal" className="pull-right bttnclose bg-red">
                                            <span className="fa fa-times-circle fa-sm" ></span>
                                        </button>
                                    </div>
                                    <div className="modal-body bg-default">

                                        <div>
                                            {this.state.closingdata["cust_status"] === 'Open' ?
                                                <div>
                                                    {this.state.closing === 0 &&
                                                        <div>
                                                            <p>Are you sure you want to "close" this transaction?</p>
                                                            <div className="pull-right">
                                                                <button className="btn bg-green text-white m-r-5" type="button" onClick={() => { this.updateStatus(this.state.closingdata); this.setState({ closing: 1 }) }}><span>Close Status</span></button>
                                                                <button className="btn border border-solid" type="button" data-dismiss="modal"><span>Cancel</span></button>
                                                            </div>

                                                        </div>
                                                    }

                                                    {this.state.closing === 1 &&
                                                        <form onSubmit={this.formSubmit}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p style={{ fontSize: "12px", color: "#353b48" }}>Select Date Closed</p>
                                                                    <DatePicker
                                                                        style={{ width: '100%' }}
                                                                        selected={this.state.startDate}
                                                                        onChange={this.handleChange}
                                                                        name="date"
                                                                        className="form-control"
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p style={{ fontSize: "12px", color: "#353b48" }}>Item Sold</p>

                                                                    <input onChange={this.textchange} name="itemsold" type="text" ref={(input) => this.itemsold = input} name="itemsold" id="exampleZip" className="form-control" required />
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p style={{ fontSize: "12px", color: "#353b48" }}>Contract Number</p>
                                                                    <input onChange={this.textchange} name="contact" type="text" ref={(input) => this.contract = input} name="contract" id="exampleZip" className="form-control" required />
                                                                </div>

                                                            </div>
                                                            <button type="submit" className="btn bg-green-lighter text-white pull-right" onClick={() => {

                                                            }}>Submit</button>
                                                        </form>
                                                        // <form>
                                                        //     <div class="form-group">
                                                        //         <label>Select date closed:</label><br/>
                                                        //         <DatePicker
                                                        //             // style={{ width: '100px' }}
                                                        //             selected={this.state.startDate}
                                                        //             onChange={this.handleChange}
                                                        //             className="form-control"
                                                        //             required
                                                        //         />
                                                        //     </div>
                                                        //     <div class="form-group">
                                                        //         <label>Item sold:</label>
                                                        //         <input class="form-control" type="text" ref={(input) => this.itemsold = input} name="itemsold" required />
                                                        //     </div>
                                                        //     <div class="form-group">
                                                        //         <label>Contact number:</label>
                                                        //         <input type="number" class="form-control" ref={(input) => this.contract = input} name="contract" />
                                                        //     </div>
                                                        //     <button type="submit" className="btn bg-green-lighter text-white">Submit</button>
                                                        // </form>
                                                    }


                                                </div>
                                                : <div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <p style={{ fontSize: "12px", color: "#353b48" }}>Select Date Closed</p>
                                                            <DatePicker
                                                                style={{ width: '100%' }}
                                                                selected={this.state.startDate}
                                                                onChange={this.handleChange}
                                                                className="form-control"
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p style={{ fontSize: "12px", color: "#353b48" }}>Item Sold</p>
                                                            <input type="text" name="contact" id="exampleZip" className="form-control" readOnly value={this.state.itemsold} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <p style={{ fontSize: "12px", color: "#353b48" }}>Contract Modal</p>
                                                            <input type="text" name="contact" id="exampleZip" className="form-control" readOnly value={this.state.contract} />
                                                        </div>
                                                    </div>
                                                </div>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="demo-xl-modal1" className="modal modal-message fade" tabindex="-1" role="dialog" aria-hidden="true" focus="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="m-t-15" >Company Background</h5>
                                        <button data-dismiss="modal" className="bttnclose bg-red">
                                            <span className="fa fa-times-circle fa-sm"></span>
                                        </button>
                                    </div>

                                    {this.state.client.map(data => {
                                        if (data.cust_status === "Open") {
                                            if (data.images.length !== 0) {
                                                return <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-6 border-right">
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Company Name:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_fname}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Status:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5 className="text-orange">{data.cust_status}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Street Address</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_address}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Location</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_location}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Street Address:</h5>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Company Floor Location:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Location:</h5>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Date Closed:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5></h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Contact Person:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_contact_person}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Contact Person Position:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_contact_position}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Contact Number:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_contact_mobile}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Contact Email:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_contact_email}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Added by:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.user_fname + " " + data.user_lname}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Date Closed:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5></h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Contact Person:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_contact_person}</h5>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            } else {
                                                return <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-6 border-right">
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Company Name:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_fname}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Status:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5 className="text-orange">{data.cust_status}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Street Address</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_address}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Location</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_location}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Building:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5>{data.cust_building_name}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row m-5">
                                                                <div className="col-md-4">
                                                                    <h5 className="pull-right">Company Floor Location:</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <h5></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Added by:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.user_fname + " " + data.user_lname}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <div className="row">
                                                                    <div class="superbox" style={{ overflowX: "auto", height: 400 }}>
                                                                        {this.state.multipic.map(multi =>
                                                                            <div class="superbox-list m-5">
                                                                                <img className="pointer" id={multi.cust_image_id} src={"http://images.pacificweb.com.ph/pockethr/sales/" + multi.cust_image_path} style={{ height: 100, width: 80 }} onClick={this.changeSoloPic}></img>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                {this.state.solopic.map(img =>
                                                                    <img src={"http://images.pacificweb.com.ph/pockethr/sales/" + img.cust_image_path} style={{ height: 400, width: 500 }} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }

                                        } else if (data.cust_status === "Closed") {
                                            return <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6 border-right">
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Company Name:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_fname}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Status:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5 className="text-green">{data.cust_status}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Street Address:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_address}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Location:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_location}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Building:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_building_name}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Company Floor Location:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5></h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Date Added:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{moment(data.cust_date_added).format('LL')}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Date Closed:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5></h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Contact Person:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_contact_person}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Contact Person Position:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_contact_position}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Contact Number:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_contact_mobile}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Contact Email:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.cust_contact_email}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row m-5">
                                                            <div className="col-md-4">
                                                                <h5 className="pull-right">Added by:</h5>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5>{data.user_fname + " " + data.user_lname}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <div className="row">
                                                                    {/* <div class="superbox" style={{overflowX: "auto", height: 400}}>
 {this.state.multipic.map(multi =>
 <div class="superbox-list m-5">
 <img className="pointer" id={multi.cust_image_id} src={"http://images.pacificweb.com.ph/pockethr/sales/"+multi.cust_image_path } style={{ height: 100, width: 80 }} onClick={this.changeSoloPic}></img>
 </div>
 )}
 </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                {/* {this.state.solopic.map(img =>
 <img src={"http://images.pacificweb.com.ph/pockethr/sales/"+img.cust_image_path} style={{ height: 400, width: 500 }}></img>
 )} */}
                                                                <img src={noimage} style={{ height: 400, width: 500 }}></img>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <div className="row">
                                                                        {/* <div class="superbox" style={{overflowX: "auto", height: 400}}>
 {this.state.multipic.map(multi =>
 <div class="superbox-list m-5">
 <img className="pointer" id={multi.cust_image_id} src={"http://images.pacificweb.com.ph/pockethr/sales/"+multi.cust_image_path } style={{ height: 100, width: 80 }} onClick={this.changeSoloPic}></img>
 </div>
 )}
 </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    {/* {this.state.solopic.map(img =>
 <img src={"http://images.pacificweb.com.ph/pockethr/sales/"+img.cust_image_path} style={{ height: 400, width: 500 }}></img>
 )} */}
                                                                    <img src={noimage} style={{ height: 400, width: 500 }}></img>

                                                                </div>
                                                            </div>

                                                    </div>
                                                </div>

                                                </div>
                                            }

                                            </div>
                                        }
                                    }



                                    )}
                                </div>


                            </div>
                        </div>

                    </div>}
                {this.state.route === "Logs" &&
                    <Logs />
                }
                {this.state.route === "Assign" &&
                    <Assign />
                }
                {this.state.route === "DashBoard" &&
                    <Dashboard />
                }
            </div>
        );
    }
}

export default Clients;