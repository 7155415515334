import React, { Component } from 'react';
import { getData211 } from "../../../../api/api";
// import GoogleMapReact from 'google-map-react';
// import { Scrollbar } from '@amcharts/amcharts4/core';
import { Scrollbars } from 'react-custom-scrollbars';
// import { Scrollbar } from 'react-custom-scrollbars';
import moment from 'moment';
// import meterGreen from "../img/pockethr.png";

// const AnyReactComponent = ({ text, jo, metercondition, meternumber, premisescondition, reading, sealcolor, sealcondition, sealnumber }) => <div>
// <img className="btn-social" data-tip data-for={text} alt="" style={{width: 35, height: 35}} src={meterGreen}/>

// </div>;

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            employee: [],
            // showPerson: [],
            search: '',
            showDetails: [],
            personDetails: [],
            bgcolor1: "#ededed",
            bgcolor2: "#ededed",
            bgcolor3: "#ededed",
            bgcolor4: "#404040",
            txtcolor1: "black",
            txtcolor2: "black",
            txtcolor3: "black",
            txtcolor4: "white",
            show: "true",
            try: "",
            searchhide: false,
            searchShow: true,
            allData: [],
            selectedMonths: '',
            selectedYears: '',
            months: [],
            years: [],
            dataHistory: [],
            historyMain: [],
            historyData: [],
            currentDate: "",
        }
        this.textchange = this.textchange.bind(this);
        // this.viewHistory = this.viewHistory.bind(this);

    }
    _isMounted = true;

    salesHistory() {
        getData211('field_audit/monthlyHistory', { u_id: localStorage.getItem("u"), type: "admin" }).then(result => {
            this.setState({ dataHistory: result });
            let allData = []
            for (let i = 0; i < result.length; i++) {
                for (let b = 0; b < result[i].data.length; b++) {
                    allData.push(result[i].data[b]);
                }
            }
            const sortedActivities = allData.slice().sort((a, b) => b.accom_added - a.accom_added)



            this.setState({ allData: sortedActivities });

        })
    }
    getCurrentDate() {
        getData211('field_audit/getCurrentDate').then(result => {
            let results = result;
            let split = results.split(" ");

            this.setState({ selectedMonths: split[0], selectedYears: split[2] })
            const months = [
                { 'id': "01", 'title': "January" },
                { 'id': "02", 'title': "February" },
                { 'id': "03", 'title': "March" },
                { 'id': "04", 'title': "April" },
                { 'id': "05", 'title': "May" },
                { 'id': "06", 'title': "June" },
                { 'id': "07", 'title': "July" },
                { 'id': "08", 'title': "August" },
                { 'id': "09", 'title': "September" },
                { 'id': "10", 'title': "October" },
                { 'id': "11", 'title': "November" },
                { 'id': "12", 'title': "December" },
            ];
            var currentYear = new Date().getFullYear(), years = [];

            let startYear = startYear || 2019;
            while (startYear <= currentYear) {
                years.push(startYear++);
            }
            years.reverse();

            this.setState({ years: years });
            this.setState({ months: months });
            this.setState({ currentDate: result });
        })
    }

    textchange = e => {
        let data = e.target.value;
        let split = data.split(" ");
        this.setState({ [e.target.name]: split[1] });

        if (split[0] === "1") {
            if (this.state.selectedMonths === split[1]) {
            } else {
                let data = [];
                for (let i = 0; i < this.state.months.length; i++) {
                    if (this.state.months[i].title === split[1]) {
                        data = this.state.months[i];
                    }
                }
                let save = this.state.selectedYears + "-" + data.id;
                getData211('field_audit/selectedHistory', { u_id: localStorage.getItem("u"), type: "admin", data: save }).then(result => {
                    this.setState({ dataHistory: result.reverse() });
                    let allData = []
                    for (let i = 0; i < result.length; i++) {
                        for (let b = 0; b < result[i].data.length; b++) {
                            allData.push(result[i].data[b]);
                        }
                    }
                    const sortedActivities = allData.slice().sort((a, b) => b.accom_added - a.accom_added)

                    this.setState({ allData: sortedActivities.reverse() });
                })
            }
        } else {
            if (this.state.selectedYears === split[1]) {
            } else {
                let data = [];
                for (let i = 0; i < this.state.months.length; i++) {
                    if (this.state.months[i].title === this.state.selectedMonths) {
                        data = this.state.months[i];
                    }
                }
                let save = split[1] + "-" + data.id;
                getData211('field_audit/selectedHistory', { u_id: localStorage.getItem("u"), type: "admin", data: save }).then(result => {
                    this.setState({ dataHistory: result.reverse() });
                    let allData = []
                    for (let i = 0; i < result.length; i++) {
                        for (let b = 0; b < result[i].data.length; b++) {
                            allData.push(result[i].data[b]);
                        }
                    }
                    const sortedActivities = allData.slice().sort((a, b) => b.accom_added - a.accom_added)

                    this.setState({ allData: sortedActivities.reverse() });
                })
            }
        }
    }

    viewHistory = e => {
        getData211('field_audit/salesCustomers', e).then(result => {
            this.setState({ historyMain: result.main });
            this.setState({ historyData: result.data });
        })
    }

    updateSearch(event) {
        this.setState({ search: event.target.value.substr(0, 20) });

        if (event.target.value === '') {
            this.setState({ searchhide: false, searchShow: true });
        } else {
            this.setState({ searchhide: true, searchShow: false });
        }
    }


    componentDidMount() {
        this.salesHistory();
        this.getCurrentDate();
    }

    componentWillUnmount() {
        if (localStorage.getItem("pass") !== "history") {
            this._isMounted = false
        }

    }

    update() {
        if (localStorage.getItem("pass") === "history") {
            setTimeout(() => {
                this.salesHistory();
                this.update();
            }, 5000);
        } else {
            clearTimeout(this.update);
        }
    }



    static defaultProps = {
        center: {
            lat: 14.5995,
            lng: 120.9842

        },
        zoom: 10,
    };


    render() {

        localStorage.setItem("pass", "history");
        var hidden = {
            display: this.state.searchhide ? "none" : "block"
        }
        var hidden2 = {
            display: this.state.searchShow ? "none" : "block"
        }
        let allData = this.state.allData.filter(
            (data) => {
                if (data.cust_fname === null || data.cust_lname === null) {
                    return data.accom_status.toLowerCase().indexOf(
                        this.state.search.toLowerCase()) !== -1
                } else {
                    return data.accom_status.toLowerCase().indexOf(
                        this.state.search.toLowerCase()) !== -1 || data.cust_fname.toLowerCase().indexOf(
                            this.state.search.toLowerCase()) !== -1 || data.cust_lname.toLowerCase().indexOf(
                                this.state.search.toLowerCase()) !== -1 || data.accom_type.toLowerCase().indexOf(
                                    this.state.search.toLowerCase()) !== -1 || data.cust_address.toLowerCase().indexOf(
                                        this.state.search.toLowerCase()) !== -1;
                }

            }
        )

        return (
            <div>
                <div className="row">
                    <div className="col-md-8">
                        <div>
                            <div className="flex-container m-t-10">
                                <form className="form-inline">
                                    <div className="form-group row m-b-10">
                                        <label class="col-form-label col-md-3">Select</label>
                                        <div className="col-md-8">
                                            <select className="form-control" onChange={this.textchange} name="selectedMonths">
                                                <option value={"1 " + this.state.selectedMonths}>{this.state.selectedMonths}</option>
                                                {this.state.months.map(months =>
                                                    <option value={"1 " + months.title}>{months.title}</option>
                                                )}
                                            </select>
                                        </div>

                                    </div>

                                    <select className="form-control m-r-10 m-b-10" onChange={this.textchange} name="selectedYears">
                                        <option value={"2 " + this.state.selectedYears}>{this.state.selectedYears}</option>
                                        {this.state.years.map(years =>
                                            <option value={"2 " + years}>{years}</option>
                                        )}
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="form-group pull-right col-md-4">
                        <div className="input-group pull-right">
                            <input type="text" className="form-control" placeholder="Search History" autocomplete="off" value={this.state.search} onChange={this.updateSearch.bind(this)} />
                            <span className="input-group-addon"><i className="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
                <div style={hidden}>
                    <Scrollbars style={{ height: 400, width: '100%' }}>
                        {this.state.dataHistory.map(data => {
                            let date = moment(this.state.currentDate).format("L");
                            let split = date.split("/");
                            let today = split[2] + "-" + split[0] + "-" + split[1];
                            let yesterday = split[2] + "-" + split[0] + "-" + (split[1] - 1);

                            if (today === data.title) {
                                return <div>
                                    <div className="panel">
                                        <div className="panel-heading border-bottom">
                                            <h4 className="panel-title">Today - {moment(data.title).format('dddd - LL')}</h4>
                                        </div>
                                        <div className="panel-body text-center">

                                            {data.data.map(item => {
                                                if (item.accom_status === "Open") {
                                                    return <div className="row m-b-12">
                                                        <div className="col-md-1"><span className="pull-left">{moment(item.accom_added).format('LT')}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_fname + " " + item.cust_lname}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_address}</span></div>
                                                        <div className="col-md-2"><span>{item.accom_type}</span></div>
                                                        <div className="col-md-2"><span className="text-orange">{item.accom_status}</span></div>
                                                        <div className="col-md-1" onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                    </div>
                                                } else if (item.accom_status === "Closed") {
                                                    return <div onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal" className="row m-b-10">
                                                        <div className="col-md-1"><span className="pull-left">{moment(item.accom_added).format('LT')}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_fname + " " + item.cust_lname}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_address}</span></div>
                                                        <div className="col-md-2"><span>{item.accom_type}</span></div>
                                                        <div className="col-md-2"><span className="text-green">{item.accom_status}</span></div>
                                                        <div className="col-md-1" onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                    </div>
                                                }
                                            }
                                            )}

                                        </div>
                                    </div>
                                </div>
                            } else if (yesterday === data.title) {
                                return <div>
                                    <div className="panel">
                                        <div className="panel-heading border-bottom">
                                            <h4 className="panel-title">Yesterday - {moment(data.title).format('dddd - LL')}</h4>
                                        </div>
                                        <div className="panel-body text-center">

                                            {data.data.map(item => {
                                                if (item.accom_status === "Open") {
                                                    return <div className="row m-b-12">
                                                        <div className="col-md-1"><span className="pull-left">{moment(item.accom_added).format('LT')}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_fname + " " + item.cust_lname}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_address}</span></div>
                                                        <div className="col-md-2"><span>{item.accom_type}</span></div>
                                                        <div className="col-md-2"><span className="text-orange">{item.accom_status}</span></div>
                                                        <div className="col-md-1" onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                    </div>
                                                } else if (item.accom_status === "Closed") {
                                                    return <div onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal" className="row m-b-10">
                                                        <div className="col-md-1"><span className="pull-left">{moment(item.accom_added).format('LT')}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_fname + " " + item.cust_lname}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_address}</span></div>
                                                        <div className="col-md-2"><span>{item.accom_type}</span></div>
                                                        <div className="col-md-2"><span className="text-green">{item.accom_status}</span></div>
                                                        <div className="col-md-1" onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                    </div>
                                                }
                                            }
                                            )}

                                        </div>
                                    </div>
                                </div>
                            } else {
                                return <div>
                                    <div className="panel">
                                        <div className="panel-heading border-bottom">
                                            <h4 className="panel-title">{moment(data.title).format('dddd - LL')}</h4>
                                        </div>
                                        <div className="panel-body text-center">

                                            {data.data.map(item => {
                                                if (item.accom_status === "Open") {
                                                    return <div className="row m-b-12">
                                                        <div className="col-md-1"><span className="pull-left">{moment(item.accom_added).format('LT')}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_fname + " " + item.cust_lname}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_address}</span></div>
                                                        <div className="col-md-2"><span>{item.accom_type}</span></div>
                                                        <div className="col-md-2"><span className="text-orange">{item.accom_status}</span></div>
                                                        <div className="col-md-1" onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                    </div>
                                                } else if (item.accom_status === "Closed") {
                                                    return <div onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal" className="row m-b-10">
                                                        <div className="col-md-1"><span className="pull-left">{moment(item.accom_added).format('LT')}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_fname + " " + item.cust_lname}</span></div>
                                                        <div className="col-md-3"><span className="pull-left">{item.cust_address}</span></div>
                                                        <div className="col-md-2"><span>{item.accom_type}</span></div>
                                                        <div className="col-md-2"><span className="text-green">{item.accom_status}</span></div>
                                                        <div className="col-md-1" onClick={() => this.viewHistory(item.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                    </div>
                                                }
                                            }
                                            )}

                                        </div>
                                    </div>
                                </div>
                            }
                        }

                        )}</Scrollbars>

                </div>


                <div style={hidden2}>
                    <div className="panel">
                        <div className="panel-heading border-bottom">
                            <h4 className="panel-title">Found {allData.length} search result for '{this.state.search}'</h4>
                        </div>
                        <div className="panel-body">
                            <Scrollbars style={{ height: 400, width: '100%' }}>
                                <table className="table">
                                    <thead hidden>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody className="table-valign-middle">
                                        {allData.map(data => {
                                            if (data.accom_status === "Closed") {
                                                return <tr>
                                                    <td>{moment(data.accom_added).format('MMM D YYYY')}</td>
                                                    <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                    <td>{data.cust_address}</td>
                                                    <td>{data.accom_type}</td>
                                                    <td className="text-green">{data.accom_status}</td>
                                                    <td onClick={() => this.viewHistory(data.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></td>
                                                </tr>
                                                // <div className="row tablehover" onClick={() => this.viewHistory(data.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal">
                                                // <div className="col-md-2 m-b-10 text-center"><span className="pull-left">{moment(data.accom_added).format('MMM d YYYY')}</span></div>
                                                // <div className="col-md-4 m-b-10"><span className="pull-left">{data.cust_fname + " " + data.cust_lname}</span></div>
                                                // <div className="col-md-3 m-b-10"><span>{data.accom_type}</span></div>
                                                // <div className="col-md-2 m-b-10"><span className="bg-green text-white" style={{ borderRadius: 12 }}>{data.accom_status}</span></div>
                                                // <div className="col-md-1" onClick={() => this.viewHistory(data.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>
                                                // </div>
                                            } else if (data.accom_status === "Open") {
                                                return <tr>
                                                    <td>{moment(data.accom_added).format('MMM D YYYY')}</td>
                                                    <td>{data.cust_fname + " " + data.cust_lname}</td>
                                                    <td>{data.cust_address}</td>
                                                    <td>{data.accom_type}</td>
                                                    <td className="text-orange">{data.accom_status}</td>
                                                    <td onClick={() => this.viewHistory(data.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></td>
                                                </tr>
                                                // <div className="row tablehover" >
                                                // <div className="col-md-2 m-b-10 text-center"><span className="pull-left">{moment(data.accom_added).format('MMM d YYYY')}</span></div>
                                                // <div className="col-md-4 m-b-10"><span className="pull-left">{data.cust_fname + " " + data.cust_lname}</span></div>
                                                // <div className="col-md-3 m-b-10"><span>{data.accom_type}</span></div>
                                                // <div className="col-md-2 m-b-10"><span className=" bg-orange text-white" style={{ borderRadius: 12 }}>{data.accom_status}</span></div>
                                                // <div className="col-md-1" onClick={() => this.viewHistory(data.cust_id)} data-target="#demo-xl-modal1" data-toggle="modal"><i class="fas fa-ellipsis-v b"></i></div>

                                                // </div>
                                            }
                                        }

                                        )}
                                    </tbody>
                                </table>
                            </Scrollbars>
                        </div>
                    </div>

                </div>


                <div id="demo-xl-modal1" className="modal fade" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-xl">

                        <div className="modal-content">
                            {this.state.historyMain.map(view =>
                                <div className="modal-header">
                                    <h4 className="m-t-15">{view.fname}</h4>
                                    <button data-dismiss="modal" className="pull-right bttnclose bg-red-lighter">
                                        <span className="fa fa-times-circle fa-sm"></span>
                                    </button>
                                </div>
                            )}
                            <div className="modal-body">

                                {this.state.historyMain.map(view => {
                                    if (view.status === "Open") {
                                        return <div className="row">
                                            <div className="col-md-4">
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Status:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span style={{ color: "orange" }}>{view.status}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Address:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.address}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Building:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.cust_building_name}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Total Floors:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Unit:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Contact Person:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.cust_contact_person}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Person Position:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.cust_contact_position}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Contact Number:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.mobile}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Email:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    } else if (view.status === "Closed") {
                                        return <div className="row">
                                            <div className="col-md-4">
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Status:</label>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <span style={{ color: "green" }}>{view.status}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Address:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.address}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Building:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.cust_building_name}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Total Floors:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Unit:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Contact Person:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.cust_contact_person}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Person Position:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.cust_contact_position}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Contact Number:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.mobile}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ lineHeight: 2 }}>
                                                    <div className="col-md-4">
                                                        <label className="pull-right">Email:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <span>{view.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                }
                                )}

                            </div>
                            <div className="modal-body" style={{ marginTop: "-10px" }}>
                                <Scrollbars style={{ height: 230, width: '100%' }}>
                                    <table className="table table-striped">
                                        <thead className="tableFixHead">
                                            <tr>
                                                <th className="bg-white" style={{ padding: 25 }}>SALE STATUS</th>
                                                <th className="bg-white" style={{ padding: 25 }}>SALES PERSONNEL</th>
                                                <th className="bg-white" style={{ padding: 25 }}>DATE</th>
                                                <th className="bg-white" style={{ padding: 25 }}>START TIME</th>
                                                <th className="bg-white" style={{ padding: 25 }}>END TIME</th>
                                                <th className="bg-white" style={{ padding: 25 }}>REMARKS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.historyData.map(data => {
                                                if (data.status === "Open") {
                                                    return <tr>
                                                        <td style={{ color: "orange" }}>{data.status}</td>
                                                        <td>{data.f_fname + " " + data.f_lname}</td>
                                                        <td>{moment(data.added).format('LL')}</td>
                                                        <td>{moment(data.added).format('LT')}</td>
                                                        <td>{moment(data.ended).format('LT')}</td>
                                                        <td>{data.remarks}</td>
                                                    </tr>
                                                } else if (data.status === "Closed") {
                                                    return <tr>
                                                        <td style={{ color: "green" }}>{data.status}</td>
                                                        <td>{data.f_fname + " " + data.f_lname}</td>
                                                        <td>{moment(data.added).format('LL')}</td>
                                                        <td>{moment(data.added).format('LT')}</td>
                                                        <td>{moment(data.ended).format('LT')}</td>
                                                        <td>{data.remarks}</td>
                                                    </tr>
                                                }

                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default History;