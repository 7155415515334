
import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import DPie2 from "./charts/d_pie1";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import $, { get } from "jquery";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

class Field_dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            branch: [],
            company: '',
            employee: [],
            id: "",
            Total: 0,
            Accomplishments: 0,
            branch_id: "",
            datepicked: new Date(),
            recieved: 0,
            assigned: 0,
            delivered: 0,
            undelivered: 0,
            undeliveredremarks: 0,
            fieldman: [],
            searchDriver: "",
            pieGraph: [],
            unassigned: 0,
            data: "",
            user_id: "",
            parameter: "",
            jo_soa: 0,
            jo_dn: 0,
            jo_ncr: 0,
            jo_nac: 0,
            jo_osb: 0,
            jo_osn: 0,
            jo_meco: 0,
            jo_aubd: 0,
            jo_soa_reout: 0,
            jo_dn_reout: 0,
            jo_count: 0,
            complete_name: "",
            jo_date_assign: new Date(),
            loading: false,
            total_fieldman: 0,
            assigned_fieldman: 0,
            employee_accomplishments: [],
            modal: ""

        }
        this.onChangeSelectBranch = this.onChangeSelectBranch.bind(this);
        this.datepicked = this.datepicked.bind(this);
        this.onSubmitAssign = this.onSubmitAssign.bind(this);
        this.textchange = this.textchange.bind(this);

    }
    branch() {
        var data = {
            user_id: localStorage.getItem("u")
        }
        console.log(data)
        getData('tracking/getMonitoringAccess', data).then(result => {
            console.log(result)
            this.setState({ branch: result });
        })
    }
    componentDidMount() {
        this.branch();
    }
    onChangeSelectBranch(e) {
        var options = e.target.options;
        var value = '';
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value = options[i].value;
                this.setState({ branch_id: options[i].value })
            }
        }
    }
    datepicked(e) {
        setTimeout(() => {
            this.setState({ datepicked: e });
        }, 100)
    }
    // display() {
    //     var data = {
    //         parameter: "branch_id",
    //         selection: this.state.branch_id,
    //         datefilter: moment(this.state.datepicked).format('YYYY-MM-DD')
    //     }
    //     getData('tracking/trackEmployeesLocation').then(result => {
    //     })
    // }
    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }
    getDateAssign(data) {
        this.setState({
            user_id: data.user_id,
            branch_id: data.branch_id,
            jo_soa: data.jo_soa,
            jo_dn: data.jo_dn,
            jo_ncr: data.jo_ncr,
            jo_nac: data.jo_nac,
            jo_osb: data.jo_osb,
            jo_osn: data.jo_osn,
            jo_meco: data.jo_meco,
            jo_aubd: data.jo_aubd,
            jo_soa_reout: data.jo_soa_reout,
            jo_dn_reout: data.jo_dn_reout,
            jo_count: data.jo_count,
            complete_name: data.completename,
            date_added: data.location_date_added
        })
    }
    onSubmitAssign(e) {
        e.preventDefault();
        var date = new Date;
        var data = {
            user_id: this.state.user_id,
            // branch_id: "branch_id",
            jo_date_added: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            // jo_date_assign: moment(this.state.date_added).format('YYYY-MM-DD HH:mm:ss'),
            jo_date_assign: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            branch_id: this.state.branch_id,
            jo_soa: this.state.jo_soa,
            jo_dn: this.state.jo_dn,
            jo_ncr: this.state.jo_ncr,
            jo_nac: this.state.jo_nac,
            jo_osb: this.state.jo_osb,
            jo_osn: this.state.jo_osn,
            jo_meco: this.state.jo_meco,
            jo_aubd: this.state.jo_aubd,
            jo_soa_reout: this.state.jo_soa_reout,
            jo_dn_reout: this.state.jo_dn_reout,
            jo_count: (
                parseInt(this.state.jo_soa) +
                parseInt(this.state.jo_dn) +
                parseInt(this.state.jo_ncr) +
                parseInt(this.state.jo_nac) +
                parseInt(this.state.jo_osb) +
                parseInt(this.state.jo_osn) +
                parseInt(this.state.jo_meco) +
                parseInt(this.state.jo_aubd) +
                parseInt(this.state.jo_soa_reout) +
                parseInt(this.state.jo_dn_reout)
            )
        }
        this.setState({ loading: true })
        getData('tracking/logAssignCount', data, date).then(result => {
            this.setState({ loading: true })
            if (result.res == "Success") {
                NotificationManager.success('Job Order Added Successfully', 'Success', 5000, true)
                this.displaydata()
                $("#hide-assign").click();
                this.setState({ loading: false })

            } else {
                NotificationManager.error('Job Order Added Failed', 'Failed', 5000, true)
                this.setState({ loading: false })
            }
        })
    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    displaydata() {
        this.setState({ loading: true })
        var data = {
            parameter: "branch_id",
            selection: this.state.branch_id,
            date: moment(this.state.datepicked).format('YYYY-MM-DD')
        }
        getData('tracking/trackEmployeesLocation', data).then(result => {
            console.log(result)
            var undelivered = result.fieldman.reduce((count, value) => {
                if (parseInt(value.jo_accom) > parseInt(value.jo_count)) {
                    return count = 0
                } else if  (parseInt(value.jo_accom) < parseInt(value.jo_count)){
                    return count += parseInt(value.jo_count) - parseInt(value.jo_accom)
                }
            }, 0);
            var delivered = result.fieldman.reduce((count, value) => {
                return count += parseInt(value.jo_accom)
            }, 0);
            var unassigned = parseInt(result.joborder) - result.fieldman.reduce((count, value) => {
                return count += parseInt(value.jo_count)
            }, 0);
            var unassigned_value = 0
            if (unassigned <= 0) {
                unassigned_value = 0
            } else {
                unassigned_value = unassigned
            }
            this.setState({
                loading: false,
                recieved: result.joborder,
                fieldman: result.fieldman,
                pieGraph:
                    [
                        { title: "Delivered", value: delivered },
                        { title: "Undelivered", value: undelivered >= 0 ? undelivered : 0 },
                        { title: "Unassigned", value: unassigned_value }
                    ],
                unassigned: unassigned_value,
            })

            // ==================count of jo start======================
            var total_fieldman = result.fieldman.reduce((count, value) => {
                // if (parseInt(value.jo_count) != 0 && parseInt(value.jo_count) != "NaN" ) {
                return count += parseInt(1)
                // }
            }, 0);
            var assigned_fieldman = result.fieldman.reduce((count, value) => {
                if (parseInt(value.jo_count) != 0 || parseInt(value.jo_accom) != 0 && parseInt(value.jo_count) != "NaN") {
                    count += parseInt(1)
                } return count
            }, 0);

            this.setState({ total_fieldman: total_fieldman, assigned_fieldman: assigned_fieldman })



            // ==================count of jo end========================
        })
    }
    trackaccom(data) {
        getData('tracking/trackAccomplishments', { user_id: data.user_id, date: moment(this.state.datepicked).format('YYYY-MM-DD') }).then(result => {
            this.setState({ employee_accomplishments: result.accomplishment })
            console.log(result)
        })
    }
    render() {
        let EmployeeSearch = this.state.fieldman.filter(
            (files) => {
                return files.completename.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )
        return (

            <div id="content" className="content">
                <NotificationContainer />
                <div className="panel">
                    <div className="panel-heading">

                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="widget widget-stats bg-lime">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">Assigned&nbsp;Fieldman</h4>
                                                <p>{this.state.assigned_fieldman}&nbsp;&#47;&nbsp;{this.state.total_fieldman}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="widget widget-stats bg-purple">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">Date&nbsp;Generated</h4>
                                                <p>{moment(this.state.datepicked).format('LL')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="widget widget-stats bg-blue">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">TOTAL<br />RECEIVED</h4>
                                                <p>{this.state.recieved}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="widget widget-stats bg-aqua">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">TOTAL<br />ASSIGNED</h4>
                                                <p>{this.state.fieldman.reduce((count, value) => {
                                                    return count += parseInt(value.jo_count)
                                                }, 0)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="widget widget-stats bg-grey">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">TOTAL<br />UNASSIGNED</h4>
                                                {/* <p>{parseInt(this.state.recieved) - this.state.fieldman.reduce((count, value) => {
                                                    return count += parseInt(value.jo_count)
                                                }, 0)}</p> */}
                                                <p>{this.state.unassigned}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="widget widget-stats bg-green">
                                            <div className="stats-info text-white" >
                                                <h4 className="text-white">TOTAL<br />DELIVERED</h4>
                                                <p>{this.state.fieldman.reduce((count, value) => {
                                                    return count += parseInt(value.jo_accom)
                                                }, 0)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="widget widget-stats bg-orange">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">TOTAL <br /> UNDELIVERED</h4>
                                                {/* <p>{this.state.fieldman.reduce((count, value) => {
                                                    console.log(value)
                                                    let assigned = 0
                                                    if (parseInt(value.jo_accom) >= parseInt(value.jo_count)) {
                                                        return count = 0 
                                                    } else if (parseInt(value.jo_count) > parseInt(value.jo_accom)) {
                                                        return count += parseInt(value.jo_count) - parseInt(value.jo_accom)
                                                    }
                                                }, 0)}</p> */}
                                                <p>{
                                                    (this.state.fieldman.reduce((count, value) => {
                                                        return count += parseInt(value.jo_count)
                                                    }, 0) - (this.state.fieldman.reduce((count, value) => {
                                                        return count += parseInt(value.jo_accom)
                                                    }, 0))) >= 0 ? (this.state.fieldman.reduce((count, value) => {
                                                        return count += parseInt(value.jo_count)
                                                    }, 0) - (this.state.fieldman.reduce((count, value) => {
                                                        return count += parseInt(value.jo_accom)
                                                    }, 0))) : 0
                                                }</p>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="widget widget-stats bg-orange">
                                            <div className="stats-info text-white">
                                                <h4 className="text-white">UNDELIVERED<br />W/OUT&nbsp;REMARKS</h4>
                                                <p>{this.state.fieldman.reduce((count, value) => {
                                                    if(parseInt(value.jo_accom) > parseInt(value.jo_count)){
                                                        return count = 0
                                                    } else {
                                                        return count += parseInt(value.jo_count) - parseInt(value.jo_accom) 
                                                    }
                                                }, 0)}</p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="widget widget-stats bg-none">
                                            <div className="stats-info text-white">
                                                <DPie2 pieGraph={this.state.pieGraph} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <button data-target="#generate-table" data-toggle="modal" className="btttn text-white bg-green-transparent-9 pull-left fas fa-plus-circle">&nbsp;Generate</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search2" placeholder="Search Name" autoComplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Scrollbars style={{ height: 450 }}>
                                    <table className="table table-td-valign-middle table-bordered" style={{ position: "relative" }}>
                                        <thead className="tableFixHead ">
                                            <tr className="text-center">
                                                <th className="bg-green-lighter text-white" style={{ position: "sticky", zIndex: 2, left: 0 }}>Employee&nbsp;Name</th>
                                                <th>SOA</th>
                                                <th>DN</th>
                                                <th>NCR</th>
                                                <th>NAC</th>
                                                <th>OSB</th>
                                                <th>OSN</th>
                                                <th>MECO&nbsp;LETTERS</th>
                                                <th>AUBD</th>
                                                <th>RE-OUT&nbsp;SOA</th>
                                                <th>RE-OUT&nbsp;DN</th>
                                                <th>TOTAL</th>
                                                <th>DELIVERED</th>
                                                <th>UNDERLIVERED</th>
                                                <th>UNDERLIVERED&nbsp;W/&nbsp;REMARKS</th>
                                                <th>UNDERLIVERED&nbsp;W/OUT&nbsp;REMARKS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {EmployeeSearch.map((data, index) => {
                                                return <tr key={index}
                                                    className="text-center"
                                                    data-target="#update-jo" data-toggle="modal"
                                                    onClick={() => {
                                                        this.getDateAssign(data);
                                                        this.trackaccom(data);
                                                        this.setState({ modal: "assigning" })
                                                    }}>
                                                    <td className="bg-green-lighter text-white" style={{ position: "sticky", zIndex: 1, left: 0 }}>{data.completename}</td>
                                                    <td>{data.jo_soa}</td>
                                                    <td>{data.jo_dn}</td>
                                                    <td>{data.jo_ncr}</td>
                                                    <td>{data.jo_nac}</td>
                                                    <td>{data.jo_osb}</td>
                                                    <td>{data.jo_osn}</td>
                                                    <td>{data.jo_meco}</td>
                                                    <td>{data.jo_aubd}</td>
                                                    <td>{data.jo_soa_reout}</td>
                                                    <td>{data.jo_dn_reout}</td>
                                                    <td>{data.jo_count}</td>
                                                    <td>{data.jo_accom}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="generate-table" className="modal fade" tabindex="-1" data-backdrop='static' data-keyboard="false">
                    <div className="modal-dialog modal-md modal-dialog-centered ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Generate Table</h4>
                                <button id="hide-assign" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9">
                                    <span className="fa fa-times-circle" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div class="form-group row m-b-15">
                                    <label class="col-form-label col-md-3">Select Date</label>
                                    <div class="col-md-9">
                                        <DatePicker
                                            selected={this.state.datepicked}
                                            selectsStart
                                            startDate={this.state.datepicked}
                                            onChange={this.datepicked}
                                            className="form-control"
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                            placeholderText="Select Date"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row m-b-15">
                                    <label class="col-form-label col-md-3">Select Branch</label>
                                    <div class="col-md-9">
                                        <select className="form-control" onChange={this.onChangeSelectBranch} name="selectedBranch">
                                            <option value="">-Select -</option>
                                            {this.state.branch.map((val, index) => {
                                                return <option value={val.b_id} name={val.b_id}>{val.branch_name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <button className="btttn bg-green-transparent-9" onClick={() => { this.displaydata() }}>Submit</button>
                            </div>
                        </div>
                    </div>
                    {this.state.loading == true &&
                        <Loader fullPage loading />
                    }
                </div>
                <div id="update-jo" className="modal fade" tabindex="-1" data-backdrop='static' data-keyboard="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="page-header">Assigning</h4>
                                <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9">
                                    <span className="fa fa-times-circle" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="navmedia">
                                    <div className="navtitle panel-title">
                                        <h4 className="page-header"><i className="fas fa-street-view"></i>&nbsp;{this.state.complete_name}</h4>
                                    </div>
                                    <div className="navnav">
                                        <div className="attendnav">
                                            <a href="javascript:;" onClick={() => { this.setState({ modal: "assigning" }) }}>Assigning</a>
                                        </div>
                                        {/* <div className="attendnav">
                                            <a href="javascript:;" onClick={() => { this.setState({ modal: "dashboard" }) }}>Dashboard</a>
                                        </div> */}
                                        <div className="attendnav" >
                                            <a href="javascript:;" onClick={() => { this.setState({ modal: "accomplishment" }) }}>Accomplishment</a>
                                        </div>
                                    </div>
                                </div>
                                {this.state.modal == "assigning" &&
                                    <div>
                                        <form className="row" onSubmit={this.onSubmitAssign}>
                                            <div className="col-md-6">
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">SOA</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_soa" value={this.state.jo_soa} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">DN</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_dn" value={this.state.jo_dn} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">NCR</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_ncr" value={this.state.jo_ncr} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">NAC</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_nac" value={this.state.jo_nac} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">OSB</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_osb" value={this.state.jo_osb} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">OSN</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_osn" value={this.state.jo_osn} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">MECO</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_meco" value={this.state.jo_meco} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">AUBD</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_aubd" value={this.state.jo_aubd} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">RE-OUT SOA</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_soa_reout" value={this.state.jo_soa_reout} className="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-group row m-b-15">
                                                    <label class="col-form-label col-md-3">RE-OUT DN</label>
                                                    <div class="col-md-9">
                                                        <input onChange={this.textchange} type="number" name="jo_dn_reout" value={this.state.jo_dn_reout} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button type="submit" className=" pull-right btttn bg-green-transparent-9 text-white">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                }
                                {this.state.modal == "accomplishment" &&
                                    <div>
                                        <Scrollbars style={{ height: 300 }}>
                                            <table className="table table-td-valign-middle table-bordered">
                                                <thead className="tableFixHead ">
                                                    <tr className="text-center">
                                                        <th>Meter Number</th>
                                                        <th>Field Findings</th>
                                                        <th>Time Accomplishments</th>
                                                        <th>Battery Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.employee_accomplishments.map((data, index) => {
                                                        return <tr className="text-center">
                                                            <td>{data.meter_number}</td>
                                                            <td>{data.accom_findings.toUpperCase()}</td>
                                                            <td>{moment(data.date_accom).format('LT')}</td>
                                                            <td>{data.accom_remarks = "" ? "--%" : data.accom_remarks}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </Scrollbars>
                                    </div>
                                }
                                {/* {this.state.modal == "dashboard" &&
                                    <div className="row">
                                        <div className="col-md-6">
                                        <DClusteredBar/>

                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                    {this.state.loading == true &&
                        <Loader fullPage loading />
                    }
                </div>

            </div>
        );
    }
}


export default Field_dashboard;