import React, { Component } from 'react';
import { getData } from '../../../api/api';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from "jquery";

class Customer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customers: [],
            companies: [],
            searchDriver: "",
            custType : "residential"
        }
        this.searchDriver = this.searchDriver.bind(this);
        this.onChangeSelectType = this.onChangeSelectType.bind(this)
    }

    getCustomer() {
        getData("customer/selectcustomer", { u_id: localStorage.getItem("u") }).then(result => {
            this.setState({
                customers: result
            });
        });
    }

    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
            })
    }

    onChangeSelectType = (e) =>{
        this.setState({
            custType : e.target.value
        });

        console.log(this.state)
    }

    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
        console.log(this.state)
        
    }

    addNewCustomer = e => {
        e.preventDefault();
        const custData = {
            custName : this.state.custName,
            contactNum : this.state.contactNum,
            custType : this.state.custType,
            street : this.state.street,
            brgy : this.state.brgy,
            city : this.state.city,
            custType: this.state.custType,
            province : this.state.province,
            country : this.state.country,
            zipCode: this.state.zip
        };
        
        getData('customer/addCustomer', custData).then(result => {
            // console.log(result);
            
            if(result.includes("Success")){
                this.getCustomer();
                $("#close").click();
            }
        })
    }

    componentDidMount() {
        this.getCustomer();
        this.getCompanies();
    }

    searchDriver(event) {
        this.setState({ searchDriver: event.target.value.substr(0, 20) });
    }

    render() {

        let customerSearch = this.state.customers.filter(
            (files) => {
                return files.cust_fname.toLowerCase().indexOf(
                    this.state.searchDriver.toLocaleLowerCase()) !== -1
            }
        )

        return (
            <div className="content" id="content">
                <div className="panel m-t-10 " >
                    <div className="panel-heading">
                        <div class="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i class="fas fa-coins"></i>&nbsp;Customers</h4>

                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Always</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Customers</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btttn pull-left bg-green-transparent-9 fas fa-plus-circle" data-target="#demo-lg-modal" data-toggle="modal">&nbsp;New&nbsp;Customer</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group pull-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control width-200" id="demo-input-search" placeholder="Search Customer Name" autocomplete="off" value={this.state.searchDriver} onChange={this.searchDriver.bind(this)} />
                                        <span className="input-group-addon"><i className="fa fa-search"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Scrollbars style={{ height: 350 }}>
                            <table className="table table-bordered">
                                <thead className="tableFixHead">
                                    <tr>
                                        <th>Customer ID</th>
                                        <th>Customer Name</th>
                                        <th>Customer Type</th>
                                        <th>Customer Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerSearch.map(data => {
                                        return <tr data-target="#demo-lg-modal-2" data-toggle="modal">
                                            <td>{data.cust_id}</td>
                                            <td>{data.cust_fname}</td>
                                            <td>{data.cust_type}</td>
                                            <td>{data.street}, {data.barangay}, {data.city}, {data.province}, {data.country}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Scrollbars>

                        <div id="demo-lg-modal" className="modal w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Add Customer</h4>
                                        <button id="closeModal" data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" type="button">
                                        </button>
                                        <button id="close" data-dismiss="modal" hidden className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" type="button">
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={this.addNewCustomer}>
                                            <fieldset>
                                                {/* <div className="form-group">
                                                    <label for="exampleInputPassword1">Customer Type</label>
                                                    <select className="form-c]]ontrol" onChange={this.onChangeSelectBranch}>
                                                        <option disabled>--select branch--</option>
                                                        {this.state.branches.map((val1, index) =>
                                                            <option value={val1.branch_id}>{val1.bname}</option>
                                                        )}
                                                    </select>
                                                </div> */}
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Customer Name</label>
                                                    <input type="text" className="form-control" onChange={this.textchange} name="custName" placeholder="Name" required />
                                                </div>

                                                <div className="form-group">
                                                    <label for="exampleInputPassword1">Customer Type</label>
                                                    <select className="form-control" onChange={this.onChangeSelectType} name="custType">
                                                        <option disabled>--select customer type--</option>
                                                        <option value="residential">Residential</option>
                                                        <option value="commercial">Commercial</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Customer Contact Number</label>
                                                    <input type="text" className="form-control" onChange={this.textchange} name="contactNum" placeholder="Name" required />
                                                </div>

                                                <label className="f-s-13 f-w-500" for="exampleInputPassword1">Customer Address</label>
                                                <div className="row">
                                                    <div className="col-md-4">        
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">House No./Street/Subdivision</label>
                                                            <input type="text" className="form-control" onChange={this.textchange} name="street" placeholder="Street" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">        
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">Barangay</label>
                                                            <input type="text" className="form-control" onChange={this.textchange} name="brgy" placeholder="Barangay" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">        
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">City</label>
                                                            <input type="text" className="form-control" onChange={this.textchange} name="city" placeholder="City" required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-5">        
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">Province</label>
                                                            <input type="text" className="form-control" onChange={this.textchange} name="province" placeholder="Province" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">        
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">Country</label>
                                                            <input type="text" className="form-control" onChange={this.textchange} name="country" placeholder="Country" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">        
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">Zip Code</label>
                                                            <input type="text" className="form-control" onChange={this.textchange} name="zip" placeholder="Zip Code" required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className="btttn pull-right bg-green-transparent-9 fa fa-plus-circle m-r-5" type="submit">
                                                    &nbsp;Create
                                                </button>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            
            </div>
        );
    }
}

export default Customer;