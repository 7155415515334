import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect, Link } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery'
import moment from 'moment'
import GovernmentDuesDetails from './governmentDuesDetails'
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import PAGE from'./employeeDetails'

// import console = require('console');
class GovernmentDues extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterEndDate: new Date(),
            filterStartDate: new Date(),
            companies: [],
            branches: [],
            employee: [],
            company_selected: [],
            branch_selected: '',
            employee_selected: [],
            governmentDetails: []
        }
        this.onSubmit = this.onSubmit.bind(this)

    }
    componentDidMount = () => {
        this.getCompanies()
        this.fetchGovernDetails()
    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
            })
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, employee: [] })
        setTimeout(() => { this.getBranch() }, 200)

    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {

                this.setState({ branches: response.data, branch_selected: "Select Branch" })

            })
    }
    showBYbranch = e => {
        e.preventDefault();
        this.setState({ branch_selected: e.target.value })
        this.fetchEmployee(e.target.value)
    }
    fetchEmployee(branch) {
        getData("humanResource/getUsers_branch", { branch: branch })
            .then(response => {
                console.log(response);
                this.setState({ employee: response.response })
            })
    }
    showEmployee = (e) => {
        var options = e.target.options;
        var value = [];

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {

                if (options[i].value == 'ALL') {
                    for (let i = 0; i < this.state.employee.length; i++) {
                        value.push(this.state.employee[i].user_id);
                    }
                    // value = this.state.employee
                } else {
                    value.push(options[i].value);

                }
            }
        }
        console.log(value)

        this.setState({ employee_selected: value })
    }
    onSubmit = (e) => {
        e.preventDefault()
        let data = {
            employee_selected: this.state.employee_selected,
            branch_selected: this.state.branch_selected,
            filterEndDate: this.state.filterEndDate,
            filterStartDate: this.state.filterStartDate,
            user_id: localStorage.getItem('u')

        }

        $('#modal-close').click()
        getData("humanResource/generateGovernmentDues", { data: data })
            .then(response => {
                console.log(response)
                
                if (response.response == true) {

                    alert('Government Dues successfuly generated.')
                    this.fetchGovernDetails()
                    this.setState({
                        employee_selected: [],
                        branch_selected: [],
                        company_selected: []
                    })
                }
            })
    }
    filterDateStart = e => {
        this.setState({
            filterStartDate: e
        });

    }
    filterDateEnd = e => {
        this.setState({
            filterEndDate: e
        });

    }
    fetchGovernDetails = () => {

        getData("humanResource/fetchGovenmentGroup", { user_id: localStorage.getItem('u') })
            .then(response => {
                this.setState({ governmentDetails: response.response })
                //   console.log(response.response);
            })
    }
    governmentdetails = (val) => {
        alert('hehehe')
        return <Link to={{
            pathname: '#/governmentDuesdetails',
            data: val
        }} />
    }


    render() {
        // console.log(this.state.branch_select)
        if (this.state.redirect == true) {
            return <Redirect to='/notFound' />
        }

        return (
            <div id="content " className="content " >
                <div className="panel">
                    <div className="panel-heading">
                        <div className="navmedia">
                            <div className="navtitle panel-title">
                                <h4 className="page-header"><i class="fas fa-hand-holding-usd"></i>&nbsp;Government Dues</h4>
                                <nav aria-label="breadcrumb bg-white">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>PocketHR</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Payroll Report</a></li>
                                        <li className="breadcrumb-item"><a href="JAVASCRIPT:;" style={{ textDecoration: 'none', cursor: 'default' }}>Government Dues</a></li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="navnav" >
                                <div name="navigation1" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/payroll" style={{ color: this.state.txtcolor1, backgroundColor: this.state.bgcolor1 }}>Payroll</a>
                                </div>
                                <div name="navigation2" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation2" })
                                        this.setState({ bgcolor2: "#404040", txtcolor2: "white" })
                                        this.setState({ bgcolor1: " transparent", txtcolor1: "black" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                    }}
                                >
                                    <a href="#/holidays" style={{ color: this.state.txtcolor2, backgroundColor: this.state.bgcolor2 }}>Holidays</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollProfile" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Earnings/Deductions</a>
                                </div>
                                <div name="navigation3" className="attendnav"
                                    onClick={() => {
                                        this.setState({ navbar: "navigation1" })
                                        this.setState({ bgcolor1: "#404040", txtcolor1: "white" })
                                        this.setState({ bgcolor3: " transparent", txtcolor3: "black" })
                                        this.setState({ bgcolor2: " transparent", txtcolor2: "black" })
                                    }}
                                >
                                    <a target="blank" href="#/payrollSettings" style={{ color: this.state.txtcolor3, backgroundColor: this.state.bgcolor3 }}>Payroll&nbsp;Settings</a>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className='penel-body' style={{ paddingRight: 5, paddingLeft: 5 }}>
                        <div>
                            <button className="btttn bg-green-transparent-9  m-b-10 fas fa-plus-circle" data-target="#demo-lg-modal11" data-toggle="modal" onClick={() => { this.setState({ showBranch: 0 }) }}>&nbsp;Create Government Dues</button>
                        </div>
                        <div className="row" >
                            <div className='col-md-12'>
                                <Scrollbars style={{ height: 330 }}>
                                    <table className="table tableFixhead table-bordered  table-td-valign-middle" >
                                        <thead>
                                            <tr className="text-center">
                                                <th>No.</th>
                                                <th>Government Dues ID</th>
                                                <th>Month</th>
                                                <th>Branch</th>
                                                <th style={{ zIndex: 1, width: "auto" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.governmentDetails.map((val, index) => {
                                                return <tr>
                                                    <td>{this.state.governmentDetails.length - index + 1}</td>
                                                    <td>{val.government_dues_group_id}</td>
                                                    <td>{moment(val.period).format('MMMM YYYY')}</td>
                                                    <td>{val.branch_name}</td>
                                                    <td><Link to={{
                                                        pathname: '/governmentDuesdetails',
                                                        data: val
                                                    }}><button className="btttn bg-blue-transparent-9  fa fa-info-circle" >&nbsp;Information</button></Link></td>
                                                </tr>

                                            })

                                            }

                                        </tbody>

                                    </table>
                                </Scrollbars>
                            </div>

                        </div>

                    </div>
                </div>
                <div id="demo-lg-modal11" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop='static'>
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="panel-title">Generate Government     Dues</h4>
                                <button id='modal-close' data-dismiss="modal" className="pull-right bttnclose bg-red-transparent-9">
                                    <span className="fa fa-times-circle "></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="panel">
                                    <form onSubmit={this.onSubmit}>
                                        <div className="panel">
                                            <div className="panel-body solid">
                                                <label>Date Range</label>
                                                <div className="row" style={{ marginLeft: "11%" }}>
                                                    <div className="form-inline">

                                                        <div className="form-group m-r-10" >
                                                            <DatePicker
                                                                selected={this.state.filterStartDate}
                                                                selectsStart
                                                                startDate={this.state.filterStartDate}
                                                                endDate={this.state.filterEndDate}
                                                                onChange={this.filterDateStart}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="form-group m-r-10" >
                                                            <DatePicker
                                                                selected={this.state.filterEndDate}
                                                                selectsEnd
                                                                startDate={this.state.filterStartDate}
                                                                endDate={this.state.filterEndDate}
                                                                onChange={this.filterDateEnd}
                                                                className="form-control"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <br />
                                                <label>Select Company</label>
                                                <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                    <select className="form-control" onChange={this.showBYcompanies} value={this.state.company_selected} required>
                                                        <option ></option>
                                                        {this.state.companies.map((val, index) =>
                                                            <option value={val.company_id}>{val.company_name}</option>
                                                        )}
                                                    </select>
                                                </div><br />
                                                <label>Select Branch</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                                    <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                                        <option>--select branch--</option>
                                                        <option></option>
                                                        {this.state.branches.map((branch, index) =>
                                                            <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                        )}
                                                    </select>
                                                </div><br />
                                                <label>Select Employee</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%", }}>
                                                    <select className="form-control" style={{ height: 150 }} onChange={this.showEmployee} multiple required>
                                                        <option value="ALL">--Select All--</option>
                                                        <option></option>

                                                        {this.state.employee.map((val, index) =>
                                                            <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <br />
                                                <div className="pull-right">
                                                    <button className="bttnpayroll bg-green-lighter" type='submit'>
                                                        <span className="fa fa-plus "></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GovernmentDues;