import React, { Component } from 'react';
import logo from './logo.svg';
import "react-datepicker/dist/react-datepicker.css";

import {
  HashRouter as Router,
  Route,
  Redirect
} from "react-router-dom";

import { getData,loginurl } from './components/api/api';
import Cards from "./components/template/Cards/index";
import Modal from "./components/template/Modal/index";
import Navigation from "./components/template/navigation";
import SamplePage from "./components/template/SamplePage/index";
import SamplePage2 from "./components/template/SamplePage2/index";
import Header from "./components/apps/users/Header/index";
import Dashboard from "./components/dashboard";
import Attendance from "./components/humanresource/attendance";
import Accomplishments from "./components/humanresource/accomplishment";
import Matrix from "./components/apps/company_settings/matrix";
import Reports from "./components/reports/";
import MayniladRoute from "./components/apps/maynilad/Routes"
import Waters from './components/apps/waters/Routes'
import AppDashboards from "./components/apps/appsDashboard";
import Upload from "./components/apps/mis/upload/index";
import MISRoute from "./components/apps/mis/Routes";
import Assign from "./components/apps/mis/assign/index";
import ControlPage from './components/controls/routes';
import AddNavigation from './components/controls/add_navigation/index';
import Apps from '../src/components/apps/appsDashboard/apps/index';
import AppHomePage from '../src/components/apps/AppHomePage/index';
import NotFound from '../src/components/notfound/index';
import Users from './components/apps/users/index';
// import Holidays from './components/apps/payrollReport/holidays/index';
// import EmployeeDetails from './components/apps/users/employeeDetails'
import Payroll from './components/apps/payrollReport/index'
import Holidays from './components/apps/payrollReport/holidays'
import EarningDeduction from './components/apps/payrollReport/earningDeduction'
import PayrollProfile from './components/apps/payrollReport/payrollprofile'
import Contribution from './components/apps/payrollReport/contribution'
import Attendance2 from './components/humanresource/attendance/index2'
// import Home from './components/apps/pocketHR/index'
import PocketHrRoutes from './components/apps/pocketHR/Routes'
import Homepage from './components/apps/pocketHR/home3'
import myProfile from './components/myProfile/index'
import FleetRoute from "./components/apps/fleet/routes";
import MoreRoute from './components/apps/more/routes';
import Sales from './components/apps/pocketHR/sales/Route'
import Map from './components/apps/pocketHR/sales/maps/index'
import AssetManagement from './components/apps/assetsManagement/Routes';
import HeaderMain from "./components/template/Header/index";
import Admin from "./components/helpdesk/admin";
import Customer from "./components/helpdesk/customer";
import Scrumboard from "./components/helpdesk/scrumboard";
import Retro from "./components/apps/payrollReport/retro";
import Department from "./components/apps/company_settings/department";
import Position from "./components/apps/company_settings/position";
import UploadEmployee from "./components/apps/pocketHR/uploademployee";
import Cookies from "./components/cookies/index";
import $ from "jquery";
import UploadFieldmanData from './components/apps/pocketHR/uploadTimesheet/upload';
import jobDescription from "./components/apps/company_settings/jobDescription";
import Monitoring from './components/apps/pocketHR/mon_attendance2';
import GovernmentDues from './components/apps/payrollReport/governmentDues'
import GovernmentDuesDetails from './components/apps/payrollReport/governmentDuesDetails'
import Privacy from './components/apps/pocketHR/privacy/privacy'
import Tickets from './components/apps/pocketHR/tickets'
// import Additional_Basic from './components/apps/payrollReport/additonal_basic'
import PayrollSettings from './components/apps/payrollReport/setting/payroll_settings'
import Field_dashboard from './components/apps/pocketHR/field/field_dashboard'
import Field_assign from './components/apps/pocketHR/field/field_assign(2)'
import PayrollAdjustment from './components/apps/payrollReport/payroll_adjustment/payrollAdjustment'
import Adjustment from './components/apps/payrollReport/payroll_adjustment/additional_deduction'
import DetailsAdjustment from './components/apps/payrollReport/payroll_adjustment/details_adjustment'
import PayrollDetails from './components/apps/payrollReport/payrollDetails'
import PayrollEmployeeList from './components/apps/payrollReport/payrollEmployeeList'

import Always from './components/apps/always/Routes';
import Analyze from './components/apps/anlz/Routes';
import Adding_Employee from './components/apps/users/adding';
import addingFieldman from './components/apps/users/adding_fieldman';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loginData: {
        user_id: localStorage.getItem("u"),
        app_id: 0,

      },

    }
  }

  getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  getUrlParam = (parameter, defaultvalue) => {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = this.getUrlVars()[parameter];
    }
    return urlparameter;
  }

  Loggedin = () => {

    getData("auth/authenticateuser", this.state.loginData).then(result => {
      // console.log(result);
      // if(result.res === "Error"){
      //   if(result.data === "No App Access"){
      //     window.location.replace("http://accounts.pacificweb.com.ph/?goto="+ window.location + "&app="+this.state.loginData.app_id + "&appdata=false");
      //   }else{
      //     window.location.replace("http://accounts.pacificweb.com.ph/?goto="+ window.location + "&app="+this.state.loginData.app_id);
      //   }
      // }else{

      this.setState({ login: result.data })
      // }


    })
  }

  componentDidMount() {
    // localStorage.setItem("u","5TnhYMjU7kHy67M")
    // localStorage.setItem('selectApp_id',0)
    // localStorage.setItem('pageNotFound',false);
    // localStorage.setItem("red","#65bf65")
    // localStorage.setItem("title","Sale Dashboard")
    localStorage.setItem("bgcolor1", "#404040");
    localStorage.setItem("bgcolor2", "#ededed");
    localStorage.setItem("bgcolor3", "#ededed");
    localStorage.setItem("bgcolor4", "#ededed");
    localStorage.setItem("bgcolor5", "#ededed");
    localStorage.setItem("txtcolor1", "white");
    localStorage.setItem("txtcolor2", "black");
    localStorage.setItem("txtcolor3", "black");
    localStorage.setItem("txtcolor4", "black");
    localStorage.setItem("txtcolor5", "black");
    localStorage.setItem("navbar", "navigation1");


    localStorage.setItem('login_state', 0)
    if (typeof localStorage.getItem("u") === "object") {
      if (typeof this.getUrlVars()["u"] !== "undefined") {
        localStorage.setItem("u", this.getUrlVars()['u'])
      } else {
        console.log(localStorage.getItem("u"))
        localStorage.setItem("cookies", 1);
        window.location.replace(loginurl+ "?goto="+ window.location + "&app="+this.state.loginData.app_id); 
      }
    }
    // else{
    //   this.Loggedin();
    // }
  }


  render() {
    console.log(this.state.loginData)
    if ((window.location.href.split('/'))[4] === 'maps') {
      return (
        <Router>
          <Route path="/maps/" component={Map} />
        </Router>
      )
    } else if ((window.location.href.split('/'))[4] === 'assetmanagement') {
      return (
        <Router>
          <Route path="/assetmanagement/" component={AssetManagement} />
        </Router>
      )
    } else {
      return (
        <div className="App">
          <Router>
            <div id="page-container" class="fade page-header-fixed page-sidebar-fixed page-with-two-sidebar">

              <Navigation />

              <Route exact path="/" component={Homepage} />
              <Route path="/retro/" component={Retro} />
              <Route path="/dtr" refresh component={Attendance} />
              <Route path="/attend" refresh component={Attendance2} />
              <Route path="/accomplishments" refresh component={Accomplishments} />
              <Route path="/matrix" refresh component={Matrix} />
              <Route path="/reports" component={Reports} />
              <Route path="/maynilad/" component={MayniladRoute} />
              <Route path="/waters/" component={Waters} />
              <Route path="/apps" component={AppDashboards} />
              <Route path="/mis/" component={MISRoute} />
              <Route path="/upload/" component={Upload} />
              <Route path="/assign/" component={Assign} />
              <Route path="/controller/" component={ControlPage} />
              <Route path="/addNavigation/" component={AddNavigation} />
              <Route path="/appDash/" component={Apps} />
              <Route path="/homePage/" component={AppHomePage} />
              <Route path="/notFound/" component={NotFound} />
              <Route path="/users/" component={Users} />
              {/* <Route path="/employeeDetails/" component={EmployeeDetails}/> */}
              <Route path="/payroll/" component={Payroll} />
              <Route path="/holidays/" component={Holidays} />
              <Route path="/payrollProfile/" component={PayrollProfile} />
              <Route path="/contribution/" component={Contribution} />
              <Route path="/pocketHR/" component={PocketHrRoutes} />
              <Route path="/myProfile/" component={myProfile} />
              <Route path="/customers/" component={Sales} />
              <Route path="/header/" component={Header} />
              <Route path="/cards/" component={Cards} />
              <Route path="/assetmanagement/" component={AssetManagement} />
              <Route path="/modal/" component={Modal} />
              <Route path="/SamplePage/" component={SamplePage} />
              <Route path="/SamplePage2/" component={SamplePage2} />
              <Route path="/headermain/" component={HeaderMain} />
              <Route path="/helpdesk/" component={Customer} />
              <Route path="/helpdeskadmin/" component={Admin} />
              <Route path="/scrumboard/" component={Scrumboard} />
              <Route path="/department/" component={Department} />
              <Route path="/position/" component={Position} />
              <Route path="/uploademployee/" component={UploadEmployee} />
              <Route path="/uploadFieldmanData/" component={UploadFieldmanData} />
              <Route path="/earningDeduction/" component={EarningDeduction} />
              <Route path="/fleet/" component={FleetRoute} />
              <Route path="/more/" component={MoreRoute} />
              <Route path="/jobDescription/" component={jobDescription} />
              <Route path="/Monitoring/" component={Monitoring} />
              <Route path="/governmentDues/" component={GovernmentDues} />
              <Route path="/governmentDuesdetails/" component={GovernmentDuesDetails} />
              <Route path="/privacy/" component={Privacy} />
              <Route path="/tickets/" component={Tickets} />
              <Route path="/payrollSettings/" component={PayrollSettings} />
              <Route path="/field_dashboard/" component={Field_dashboard} />
              <Route path="/field_assign/" component={Field_assign} />
              <Route path="/payrollAdjustment/" component={PayrollAdjustment} />
              {/* <Route path="/payrollEmployeeList/" component={ Adjustment}/> */}
              {/* <Route path={"/DetailsAdjustment/:id/:type"} component={ DetailsAdjustment}/>
              <Route path={"/payrollDetails/"} component={ PayrollDetails}/>
              <Route path={"/payrollEmployeeList/"} component={ PayrollEmployeeList}/> */}
              <Route path={"/DetailsAdjustment/:id/:type"} component={DetailsAdjustment} />
              <Route path={"/payrollDetails/"} component={PayrollDetails} />
              <Route path={"/payrollEmployeeList/:date_from/:date_to/:company/:branch/:branch_id"} component={PayrollEmployeeList} />

              <Route path="/always/" component={Always} />
              <Route path="/anlz/" component={Analyze} />
              <Route path="/adding/" component={Adding_Employee} />
              <Route path="/addingfieldman/" component={addingFieldman} />
              {/* <Route path="/payrollDesign/" component={PayrollDesign} /> */}

            </div>
          </Router>

        </div>
      );
    }

  }
}

export default App;
