import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import $ from 'jquery';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import UploadTimesheet from './uploadTimesheet'
import UploadDoubleBook from './UploadDoubleBook'
import UploadPieces from './uploadPieces'



class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: 'Upload Timesheet'
        };
    }

    render() {

        return (

            <div id="content " className="content">
                <div style={{ justifyContent: 'space-around' }}>

                    <button className='btttn bg-green' onClick ={()=>this.setState({show:'Upload Timesheet'})}> Timesheet</button>
                    <button className='btttn bg-green ' style={{marginLeft:5}}  onClick ={()=>this.setState({show:'Upload Double Book'})}> Double Book</button>
                    <button className='btttn bg-green ' style={{marginLeft:5}}  onClick ={()=>this.setState({show:'Job Order per Piece'})}> Job Order per Piece</button>

                </div>
                {this.state.show == "Upload Timesheet" ?
                    <UploadTimesheet />
                    :
                    <div>
                    </div>
                }
                {this.state.show == "Upload Double Book" ?
                    <UploadDoubleBook />
                    :
                    <div>
                    </div>
                }
                  {this.state.show == "Job Order per Piece" ?
                    <UploadPieces />
                    :
                    <div>
                    </div>
                }

            </div>

        );
    }
}

export default Upload;