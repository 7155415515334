import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
      refresh: false,
      click: false,
      post_details: "",
      company: true,
      branch: true,
      company_details: [],
      select: [],
      refresh: false,
      DATA: [],
      branch_details: [],
      user: true,
      selectBranch: [],
      employee_details: [],
      selectEmployee: [],
      newsFeed: [],
      branch1: true,
      comment1:false,
      post_id:"",
      comment:"",
      CommentData:[],
      countCom : "",
      InComment:false,
      post_ID:"",
      insertTRUE:false,
      countCommentArray : [],
      commentEn:0,
      showmore:1,
      show:false,
      post_id_D:"",
      DaysPresent:0,
      DaysAbsent:0
    };
    // this.insertComment = this.insertComment.bind(this);
  }

  componentDidMount() {
    // this.fetchCountComment();
    this.fetch_newsFeed();
    this.fetchCompany();

    localStorage.setItem("pageNotFound", false);
    this.setState({ refresh: true })
    
     
      setInterval(()=>{ 
       
        if(this.state.post_ID != ""){
          // alert(this.state.post_ID);5
        this.fetchComments(this.state.post_ID)
      }},2000)
    
    
    this.intervalID = setInterval(
      () => this.tick(),
    
      1000
    );

  }
  componentWillUnmount() {
    clearInterval(this.intervalID);

  }
  // CountAttendance(){
  //   getData("humanResource/CountAttendance",{u_id: localStorage.getItem("u") })
  //   .then(response => {
  //     console.log(response);
     

  //   })
  // }
  tick() {
    this.setState({
      time: new Date().toLocaleString()
    });
  }
  textchange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.state.post_details == "") {
      this.setState({ select: [], select: [], selectEmployee: [], company: true, branch: true, branch1: true, user: true })
    }
  };
  textchange2 = e => {
    this.setState({ [e.target.name]: e.target.value });
  
  };
  fetchCompany() {
    getData("users/getCompanies", localStorage.getItem("u"))
      .then(response => {
        // console.log(response.company)
        this.setState({ DATA: response.company });
        var data = [];
        for (let i = 0; i < response.company.length; i++) {
          var match = false;
          for (let index = 0; index < data.length; index++) {
            if (data[index].company_name == response.company[i].company_name) {
              match = true;
            }
          }
          var data2 = [];
          if (match == false) {
            data2 = { company_name: response.company[i].company_name, company_id: response.company[i].company_id };
            data.push(data2);
          }
        }
        this.setState({ company_details: data });
      });
  }
  fetchBranch() {

    var data = [];
    for (let i = 0; i < this.state.select.length; i++) {
      for (let index = 0; index < this.state.DATA.length; index++) {
        if (this.state.select[i].company_id == this.state.DATA[index].company_id) {
          var match = false;
          for (let k = 0; k < data.length; k++) {
            if (data[k].branch_id == this.state.DATA[index].branch_id) {
              match = true;
            }
          }
          var data2 = [];
          if (match == false) {
            data2 = { branch_name: this.state.DATA[index].branch_name, branch_id: this.state.DATA[index].branch_id, company_name: this.state.select[i].company_name, company_id: this.state.select[i].company_id };
            data.push(data2);
          }
        }

      }

    }
    this.setState({ branch_details: data });

  }
  fetchUser() {

    getData("users/get_User_by_branch", { branch_id: this.state.selectBranch, company: this.state.select, u_id: localStorage.getItem("u") })
      .then(response => {
        console.log(response);
        this.setState({ employee_details: response.user_name })

      })
  }
  post() {
    this.setState({showMore:1,newsFeed:[],countCommentArray:[]})
    
    getData("humanResource/postNewsFedd", { company: this.state.select, branch: this.state.selectBranch, employee: this.state.selectEmployee, handle: this.state.DATA, post_details: this.state.post_details, u_id: localStorage.getItem("u") })
      .then(response => {
       
        if (response.success == "success") {
    
          setTimeout(()=>{
            this.fetch_newsFeed();
          },500)
         
          this.setState({ select: [], select: [], selectEmployee: [], post_details: "", company: true, branch: true, user: true })
        }
      })
  }
  fetch_newsFeed() {
   
    getData("humanResource/getNewsFedd", { u_id: localStorage.getItem("u"),showMore:this.state.showmore})
      .then(response => {
        console.log(response);
        var countComment = []
        this.setState({DaysPresent:response.DaysPresent,DaysAbsent:response.DaysAbsent})
        for (let index = 0; index < response.commentEn.length; index++) {
            this.setState({commentEn:response.commentEn[index].comment_enable})
          
        }
        for (let index = 0; index < response.data.length; index++) {
          var dataArr ={post_id:response.data[index].post_id,countComment:response.data[index].countComment};

         this.state.countCommentArray.push(dataArr)
        }
        var dataDetails = [];
       
        if(this.state.showmore != 1 ){

        }
         for (let index = 0; index < response.data.length; index++) {
          this.state.newsFeed.push(response.data[index]);
           
         }
       
        if( response.data.length >= 10){
            this.setState({show:true})
        } 
        if(response.data.length == 0){
          console.log(response.data.length )
           this.setState({show:false})
        }
        // this.setState({countCommentArray:countComment})
      
        // this.state.newsFeed.push(response.data)
       
      })
  }
  insertComment=(e)=>{
    e.preventDefault();
    // alert("hehehe");
    var post = this.state.post_id
    getData("communication/insertComment", { u_id: localStorage.getItem("u"),comment:this.state.comment,post_id:post })
    .then(response => {
      
      if(response.success == "success"){
       
        this.setState({InComment:true,CommentData:response.data,countCom:response.data.length,insertTrue: true,comment:""})
        for (let index = 0; index < this.state.countCommentArray.length; index++) {
          if( this.state.countCommentArray[index].post_id == post){
            this.state.countCommentArray[index].countComment = response.data.length;
          }
          
        }

      }
     
    })
  }
  fetchComments=(post_id)=>{
    
      getData("communication/fetch_Comments",{post_id:post_id,commentCount:this.state.CommentData.length})
      .then(response => {
  
        if( response.data.length != 0){
          for (let index = 0; index < this.state.countCommentArray.length; index++) {
            if( this.state.countCommentArray[index].post_id == post_id){
              this.state.countCommentArray[index].countComment = response.data.length;
            }
            
          }
            this.setState({CommentData:response.data,post_id_D:post_id})
        }
      }) 
  }

  showMore(){
    this.state.showmore++;
    this.fetch_newsFeed()
  }

  render() {

    // console.log(this.state.CommentData);
    // console.log(this.state.employee_details);
    return (
      <div id="content" className="content">
        <h4 className="App-clock">{this.state.time} </h4>
        <div className='row'>
         
          <div className="col-md-8">
          {this.state.commentEn == 1 &&
              <div class="panel panel-default">
              <div class="panel-heading panelHeading" style={{ backgroundColor: "#f1f2f6" }}>
                <h6 class="panel-title" >Create Post</h6>
              </div>
              <div className="section ">
                <div className="section_div">
                  <img style={{ borderRadius: "50%", width: "50" }} className=" section_div_img" src="http://beta.pacificweb.com.ph/assets/user_profile/lester.jpg" />
                  <textarea onClick={() => { this.setState({ click: true }) }} onChange={this.textchange} type="text" name="post_details" value={this.state.post_details} className="section_div_textarea textarea input" placeholder="What's in your mind"></textarea>
                  <hr className="HR"></hr>
                  {this.state.post_details != "" &&
                    <div>
                      <h5><i className="fa fa-newspaper">&nbsp;</i>News Feed</h5>




                      <div className="row">


                        <div className="col-md-6">
                          <h6><input type="checkbox" onClick={() => { this.setState({ user: true, company: !this.state.company, branch: true, branch1: true, branch_details: [], select: [], employee_details: [] }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Company</h6>
                          {!this.state.company &&
                            <div>
                              <div className="panel-body solid">
                                <label>Select Company</label>
                                <Scrollbars style={{ height: 120 }}>
                                  <table width="100%">
                                    <tbody>
                                      {this.state.company_details.map((val, index) => {
                                        return <tr key={index}>
                                          <td width="10%"><input type="checkbox" onClick={() => {
                                            var match = false;
                                            this.setState({ branch1: !this.state.branch1, user: true })
                                            for (let i = 0; i < this.state.select.length; i++) {
                                              if (this.state.select[i].company_id == val.company_id) {
                                                this.state.select.splice(parseInt(i), 1)
                                                match = true;
                                                setTimeout(() => {
                                                  this.fetchUser()
                                                }, 100)
                                              }

                                            }

                                            for (let k = 0; k < this.state.branch_details.length; k++) {
                                              if (val.company_id == this.state.branch_details[k].company_id) {
                                                this.state.branch_details.splice(parseInt(k), 1)
                                                k--;

                                              }

                                            }
                                            for (let j = 0; j < this.state.employee_details.length; j++) {
                                              if (val.company_id == this.state.employee_details[j].company_id) {
                                                this.state.employee_details.splice(parseInt(j), 1)
                                                j--;



                                              }


                                            }


                                            if (match == false) {
                                              var details = { company_id: val.company_id, company_name: val.company_name }
                                              this.state.select.push(details);
                                              this.fetchBranch();
                                              setTimeout(() => {
                                                this.fetchUser()
                                              }, 100)
                                            }



                                            this.setState({ refresh: true })
                                          }} /></td>
                                          <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.company_name}</p></td>
                                        </tr>
                                      })

                                      }


                                    </tbody>
                                  </table>
                                </Scrollbars>
                              </div>
                              <div style={{ marginTop: 10 }}>
                                {!this.state.branch1 &&
                                  <h6><input type="checkbox" onClick={() => { this.setState({ branch: !this.state.branch }) }} /><i style={{ marginLeft: 10 }} className="">&nbsp;</i>Branch</h6>
                                }

                              </div>
                              {!this.state.branch &&
                                <div className="panel-body solid">
                                  <label>Select Branch</label>
                                  <Scrollbars style={{ height: 120 }}>
                                    <table width="100%">
                                      <tbody>
                                        {this.state.select.map((val1, index1) => {
                                          return <div>
                                            <p style={{ color: "black" }}>{val1.company_name}</p>
                                            {this.state.branch_details.map((val, index) => {
                                              if (val1.company_id == val.company_id)
                                                return <div>
                                                  <tr key={index}>
                                                    <td width="10%"><input type="checkbox" onClick={() => {

                                                      var match = false;
                                                      for (let i = 0; i < this.state.selectBranch.length; i++) {
                                                        if (this.state.selectBranch[i].branch_id == val.branch_id) {
                                                          this.state.selectBranch.splice(parseInt(i), 1)
                                                          match = true;
                                                          setTimeout(() => {
                                                            this.fetchUser()
                                                          }, 100)
                                                        }

                                                      }
                                                      var count = 0
                                                      for (let j = 0; j < this.state.employee_details.length; j++) {
                                                        if (val.branch_id == this.state.employee_details[j].branch_id) {
                                                          this.state.employee_details.splice(parseInt(j), 1)
                                                          j--;
                                                          this.setState({ refresh: true })


                                                        }
                                                        count++

                                                      }


                                                      if (match == false) {
                                                        var details = { branch_id: val.branch_id, branch_name: val.branch_name, company_id: val.company_id }
                                                        this.state.selectBranch.push(details);
                                                        setTimeout(() => {
                                                          this.fetchUser()
                                                        }, 100)

                                                      }

                                                      this.setState({ refresh: true })
                                                    }} /></td>
                                                    <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.branch_name}</p></td>
                                                  </tr>
                                                </div>
                                            })
                                            }
                                          </div>
                                        })}



                                        {/* <tr >
                                                           <td width="10%"><input type="checkbox"/></td>
                                                           <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;Camanava</p></td>
                                                       </tr> */}
                                      </tbody>
                                    </table>
                                  </Scrollbars>
                                </div>
                              }
                            </div>
                          }

                        </div>


                        <div className="col-md-6">
                          {!this.state.branch1 &&
                            <div >
                              <h6><input type="checkbox" onClick={() => { this.setState({ user: !this.state.user, selectEmployee: [] }) }} /><i className=""></i>&nbsp;&nbsp;&nbsp;Employee</h6>
                            </div>
                          }
                          {(!this.state.user) &&
                            <div className="panel-body solid">
                              <label>Select Employee</label>
                              <Scrollbars style={{ height: 335 }}>
                                <table width="100%">
                                  <tbody>
                                    {this.state.select.map((val1, index) => {
                                      return <div>

                                        <p style={{ color: "black" }}>{val1.company_name}</p>

                                        {this.state.employee_details.map((val, index) => {
                                          if (val1.company_id == val.company_id)
                                            return <tr key={index}>
                                              <td width="10%"><input type="checkbox" onClick={() => {
                                                var match = false;
                                                for (let i = 0; i < this.state.selectEmployee.length; i++) {
                                                  if (this.state.selectEmployee[i].user_id == val.user_id) {
                                                    this.state.selectEmployee.splice(parseInt(i), 1)
                                                    match = true;

                                                  }

                                                }

                                                if (match == false) {
                                                  var details = { company_id: val.company_id, company_name: val.company_name, branch_id: val.branch_id, user_id: val.user_id }
                                                  this.state.selectEmployee.push(details);

                                                }
                                                console.log(this.state.selectEmployee);
                                                // console.log(this.state.branch_details);


                                                this.setState({ refresh: true })
                                              }} /></td>
                                              <td width="90%"><p style={{ color: "#2d3436", fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{val.COMPLETENAME}</p></td>
                                            </tr>
                                        })

                                        }
                                      </div>
                                    })}



                                  </tbody>
                                </table>
                              </Scrollbars>
                            </div>
                          }



                        </div>

                      </div>
                      <hr className="HR"></hr>
                      <button className="form-control" style={{ backgroundColor: "#0f9e3e", color: "white", cursor: "pointer" }} onClick={() => this.post()}><center>Post</center></button>


                    </div>
                  }

                </div>
              </div>
              {/* <div class="panel-body">
                          
                        </div> */}
            </div>
          }
            
            {this.state.newsFeed.map((val, index) => {
              var countCom = "";
              var text = val.post_details.replace('"','')
              var text1 = text.replace('"','')
              // console.log(text1);
              // if(this.state.InComment == false){
              //   if(val.countComment != 0){
              //     this.state.countCom=val.countComment
              //   }else{
              //     this.state.countCom=""
              //   }
               
           
              // }
              return <div class="panel panel-default">
                  <div class="panel-heading">
                    <h1 class="panel-title text-color"><img style={{ borderRadius: "50%", width: "5%" }} src="../assets/img/user/user.jpg" />&nbsp;&nbsp;{val.user_fname + ' ' + val.user_mname + ' ' + val.user_lname}<span style={{ color: "#95a5a6", fontSize: 11, marginLeft: 10 }}>{val.date_ago}</span><a className="tool pull-right" data-tip={"Shared to : " + val.Shared} tabindex="1"><i className="fa fa-users"></i></a></h1>
                  </div>
                <div className="panel-body">
                  {/* <p className="text-justify-item"> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: this.htmlDecode(val.post_details) }} /> */}
                  
                    <p
            className="Features"
            dangerouslySetInnerHTML={{ __html: val.post_details }}
          />
                  {/* </p> */}
                  {this.state.countCommentArray.map((valcom,indexcom)=>{
                   
                    if(val.post_id == valcom.post_id)
                       return <div >
                       <a href="javascript:;" onClick={()=>{
                       var dataVal=val.post_id
                      
                     
                           if(!this.state.comment1){
                             this.setState({post_ID:val.post_id})
                           }else{
                             this.setState({post_ID:""})
                           }
                           if(this.state.post_id == val.post_id){
                             this.setState({post_id:""})
                           }else{
                             this.setState({post_id:val.post_id})
                           }
                       
                         this.setState({comment1:!this.state.comment1});this.fetchComments(val.post_id)}}><h6 className="pull-right"><i className="far fa-lg fa-comments "></i>&nbsp;{valcom.countComment}&nbsp;&nbsp;Comment</h6></a>
                     </div>
                  })}
                 
                  <br />
                  {(val.post_id == this.state.post_id) &&
                    <div>
                    <hr />
                    <div style={{ paddingLeft: 50, paddingRight: 50, paddingBottom: 10 }}>
                     
                    
                      <br/>
                      <Scrollbars style={{height:200}} autoHideTimeout={1000}>
                          {this.state.CommentData.map((val1,index1)=>{
                          
                          if(val.post_id == val1.post_id)
                          return <div key ={index1} className="flex-d">
                            <img align="middle" style={{ borderRadius: "50%", width: 13,height:13 }} src="../assets/img/user/user.jpg" />
                            <p class="text-color m-l-5 " >{val1.user_fname + ' ' + val1.user_mname + ' ' + val1.user_lname}&nbsp;&nbsp;<span style={{color:"black"}}>{val1.comment_details}</span>&nbsp;<span style={{ color: "#95a5a6", fontSize: 11}} className="m-l-5">{val1.time_post}</span></p>
                          </div>
                        })

                        }
                      </Scrollbars>
                      <br/>
                   
                      <form className="form"  onSubmit={this.insertComment}>
                      <input className="input" placeholder="Write a comment.." name="comment" value = {this.state.comment} onChange={this.textchange2} required/>
                      <button type="submit" ><i className="fa fa-3x fa-arrow-alt-circle-right" ></i></button>
                      </form>
                    
                      
                     
                      
                    </div>
                  </div>
                  }
                  
                 
                </div>
              </div>
            })

            }{this.state.show == true &&
              <button  className="btn btn-block btn-success" onClick={()=>{this.showMore()}}>Show more</button>
            }
            <br/>
          </div>
          
          <div className="col-md-4">
            <div className="panel ">
              <div className="flex-d">
                <div className='group' style={{ marginLeft: 26 }}>
                  <center><h1>{this.state.DaysPresent}</h1></center>
                  <p><i className="fa fa-circle" style={{ color: "green" }}>&nbsp;</i>Days Present</p>
                </div>
                <div className='group' style={{ marginLeft: 26 }}>
                  <center><h1>0</h1></center>
                  <p><i className="fa fa-circle" style={{ color: "yellow" }}>&nbsp;</i>Days Live</p>
                </div>
                <div className='group' style={{ marginLeft: 26 }}>
                  <center><h1>{this.state.DaysAbsent}</h1></center>
                  <p><i className="fa fa-circle" style={{ color: "red" }}>&nbsp;</i>Days Absent</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Home;