import React, { Component } from 'react';
import Maynilad from "./assets/maynilad-logo.png"
import PocketHR from "./assets/iconPocket.png"
import PIC211 from "./assets/211-2.png"
import Water from "./assets/water.png"
// import Redirect from "react-router-dom"

import swal from 'sweetalert';
class AppsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maynilad_status: "OPEN",
            hr_status: "OPEN",
            Two11_status: "CLOSE",
            m_s_disabledButton:false,
            m_u_disabledButton:false,
            m_o_disabledButton:false,

            p_s_disabledButton:false,
            p_u_disabledButton:false,
            p_o_disabledButton:false,

            t_s_disabledButton:false,
            t_u_disabledButton:false,
            t_o_disabledButton:false,

            w_s_disabledButton:false,
            w_u_disabledButton:false,
            w_o_disabledButton:false,
        };

    }
    componentWillMount(){
        var app_id = JSON.parse(localStorage.getItem('app_id_priv'))
        console.log(app_id);
        this.setState({m_o_disabledButton:true})
        this.setState({p_o_disabledButton:true})
        this.setState({t_o_disabledButton:true})
        this.setState({w_o_disabledButton:true})
    }
   onclick(type,app){
       if(type == "Subscribe" ){
           if(app == "1"){
            this.setState({m_u_disabledButton:true,m_o_disabledButton:true, m_s_disabledButton:false})
           }else if(app == "2"){
            this.setState({p_u_disabledButton:true,p_o_disabledButton:true, p_s_disabledButton:false})
           }else if(app == "3"){
            this.setState({t_o_disabledButton:true,t_u_disabledButton:true, t_s_disabledButton:false})
           }else if(app == "4"){
            this.setState({w_u_disabledButton:true,w_o_disabledButton:true, w_s_disabledButton:false})
           }
           
       }if(type == "Unsubscribe" ){
        if(app == "1"){
         this.setState({m_u_disabledButton:false,m_o_disabledButton:false, m_s_disabledButton:true})
        }else if(app == "2"){
         this.setState({p_u_disabledButton:false,p_o_disabledButton:false, p_s_disabledButton:true})
        }else if(app == "3"){
         this.setState({t_o_disabledButton:false,t_u_disabledButton:false, t_s_disabledButton:true})
        }else if(app == "4"){
         this.setState({w_u_disabledButton:false,w_o_disabledButton:false, w_s_disabledButton:true})
        }
        if(type = "OPEN"){
        // return <Redirect to = "/maynilad"/>

        }
        
    }
    alert(type);
   }
    render() {
        return (
            <div id="content" className="content">
                <div className="row">
                    <div className="col-md-3">
                        <div className="panel panel-colorful" >
                            <div className="pad-all text-center">
                                <p className="text-color">MAYNILAD</p>
                                <div>
                                    <img src={Maynilad} style={{ width: "60%", height: "60%", paddingBottom:10 }} />
                                </div>
                                <div className="btn-group btn-group-justified ">
                                {this.state.m_s_disabledButton == true &&
                               <button className="btn btn-xs btn-info"  onClick={()=>this.onclick("Subscribe","1")}>Subscribe</button>
                                }
                                {this.state.m_u_disabledButton == true &&
                                  <button className="btn btn-xs btn-info "   onClick={()=>this.onclick("Unsubscribe","1")}>Unsubscribe</button>
                                }
                                {this.state.m_o_disabledButton == true &&
                                <a className="btn btn-xs btn-info" href="#/waters/dashboard">OPEN</a>   
                                }
                               
                                
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="panel  panel-colorful"  >
                            <div className="pad-all text-center">
                                <p className="text-color">POCKET HR</p>
                                <div style={{ paddingTop: 20 }}>
                                    <img src={PocketHR} style={{ width: "45%", height: "45%", paddingBottom: 15 }} />
                                </div>
                                <div className="btn-group btn-group-justified">
                                {this.state.p_s_disabledButton == true &&
                               <button className="btn btn-xs btn-info" onClick={()=>this.onclick("Subscribe","2")}>Subscribe</button>
                                }
                                {this.state.p_u_disabledButton == true &&
                                   <button className="btn btn-xs btn-info " onClick={()=>this.onclick("Unsubscribe","2")}>Unsubscribe</button>
                                }
                                {this.state.p_o_disabledButton == true &&
                                 <a className="btn btn-xs btn-info" href="#/mis/dashboard">OPEN</a>   
                                }
                               
                                
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="panel  panel-colorful" >
                            <div className="pad-all text-center">
                                <p className="text-color">211</p>
                                <div >
                                    <img src={PIC211} style={{ width: "45%", height: "45%", paddingBottom: 15 }} />
                                </div>
                                <div className="btn-group btn-group-justified">
                                {this.state.t_s_disabledButton == true &&
                                <button className="btn btn-xs btn-info" onClick={()=>this.onclick("Subscribe","3")} >Subscribe</button>
                                }
                                {this.state.t_u_disabledButton == true &&
                                   <button className="btn btn-xs btn-info " onClick={()=>this.onclick("Unsubscribe","3")}>Unsubscribe</button>
                                }
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="panel  panel-colorful" >
                            <div className="pad-all text-center">
                                <p className="text-color">Water</p>
                                <div >
                                    <img src={Water} style={{ width: "45%", height: "100%", paddingBottom: 20,paddingTop:15 }} />
                                </div>
                                <div className="btn-group btn-group-justified">
                                {this.state.w_s_disabledButton == true &&
                                 <button className="btn btn-xs btn-info"  onClick={()=>this.onclick("Subscribe","4")}>Subscribe</button>
                                }
                                {this.state.w_u_disabledButton == true &&
                                  <button className="btn btn-xs btn-info "  onClick={()=>this.onclick("Unsubscribe","4")}>Unsubscribe</button>
                                }
                                {this.state.w_o_disabledButton == true &&
                                <a className="btn btn-xs btn-info" href="#/mis/dashboard">OPEN</a>   
                                }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                      <div className="col-md-3">
                        <div className="panel  panel-colorful" >
                            <div className="pad-all text-center">
                                <p className="text-color">Water</p>
                                <div >
                                    <img src={Water} style={{ width: "45%", height: "100%", paddingBottom: 20,paddingTop:15 }} />
                                </div>
                                <div className="btn-group btn-group-justified">
                                {this.state.w_s_disabledButton == true &&
                                 <button className="btn btn-xs btn-info"  onClick={()=>this.onclick("Subscribe","4")}>Subscribe</button>
                                }
                                {this.state.w_u_disabledButton == true &&
                                  <button className="btn btn-xs btn-info "  onClick={()=>this.onclick("Unsubscribe","4")}>Unsubscribe</button>
                                }
                                {this.state.w_o_disabledButton == true &&
                                <a className="btn btn-xs btn-info" href="#/mis/dashboard">OPEN</a>   
                                }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        );
    }
}

export default AppsDashboard;