import React, { Component } from 'react';
import { getData } from '../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
// import ReactExport from "react-data-export";
import readXlsxFile from 'read-excel-file';


class Contribution extends Component {
    constructor(props){
        super(props);
        this.state = {
          file:[],
		

        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

 

      handleChange(e){
        readXlsxFile(e.target.files[0]).then((rows) => {
               this.setState({file:rows})
          })
      }
      onFormSubmit=()=>{
        getData("humanResource/uploadSSScontri",{u_id:localStorage.getItem("u"),data:this.state.file})
        .then(response => {
               console.log(response);
        })
      } 

    render() { 
      
        return ( 
             <div id="content" className="content">
                  {/* <div>
                    <div>
                     <table >
                    <tbody>
                        <tr className="pull-right">
                        <a  tooltip="Navigation" className="a_nav li_nav" ><i  className="fa fa-2x fa-list-ul" style={{ color: "#009432", marginRight: "5%" }}  onClick={() => this.onclick_tab(1)}></i></a>
                        <a  tooltip="Applications" className="a_nav li_nav"><i  className="fa fa-2x fa-cubes" style={{ color: "#009432", marginRight: "5%" }}  onClick={() => this.onclick_tab(2)}></i></a>
                        </tr>
                    </tbody>
                </table>
                </div>
                </div> */}
                {/* start of table */}
                <div className="col-md-12">
                        <div className="panel">
                            <div className="panel-body text-center">
                                <h4>GOVERNMENT CONTRIBUTIONS</h4>
                            <button className="pull-right btn-sm btn-success" data-target="#demo-lg-modal" data-toggle="modal" tooltip="UPLOAD"><i className=" fa fa-lg fa-upload"></i>UPLOAD FILE</button>
                            </div>
                        </div>
                        <div className="panel">
                            <div className="panel-body">
                               
                                <Scrollbars style={{ height: 360 }}>
                                    <table class="table table-bordered">
                                        <thead className="text-center">
                                            <tr>
                                                <th colSpan="54"className="info">NEW SSS CONTRIBUTION TABLE</th>
                                            </tr>
                                            <tr>
                                                <th colspan="4" rowSpan="3">RANGE OF COMPENSATION</th>
                                                <th colSpan="50" className="text-center">EMPLOYED</th>
                                            </tr>
                                            <tr>
                                                <th rowSpan="2" colSpan="3">MONTHLY SALARY</th>
                                                <th colSpan="10" className="info">SSS CONTRIBUTIONS</th>
                                                <th colSpan="1">EC CONTRIBUTIONS</th>
                                                <th colSpan="10">TOTAL CONTRIBUTIONS</th>
                                            </tr>
                                            <tr>
                                                {/* SSS CONTRIBUTION START */}
                                                <th colSpan="2"className="info">ER</th>
                                                <th colSpan="2"className="info">EE</th>
                                                <th colSpan="6"className="info">TOTAL</th>
                                                {/* SSS CONTRIBUTION END */}

                                                {/* EC CONTRIBUTIONS START */}
                                                <th colSpan="1">ER</th>
                                                {/* EC CONTRIBUTIONS END */}

                                                {/* TOTAL CONTRIBUTIONS START */}
                                                <th colSpan="3">ER</th>
                                                <th colSpan="3">EE</th>
                                                <th colSpan="4">TOTAL</th>
                                                {/* TOTAL CONTRIBUTIONS END */}
                                            </tr>
                                            
                                        </thead>
                                        {/* <Scrollbars style={{ height: 390 }}> */}
                                        <tbody className="text-center">
                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>

                                           <tr>
                                               <td colSpan="2">0</td>
                                               <td colSpan="2">2250</td>
                                                {/* ========  */}
                                               <td colSpan="3">2000</td>
                                                {/* ========= */}
                                                <td colSpan="2">160.00</td>
                                                <td colSpan="2">80.00</td>
                                                <td colSpan="6">240.00</td>
                                                {/* ========= */}
                                                <td colSpan="1">10.00</td>
                                                {/* ========= */}
                                                <td colSpan="3">170.00</td>
                                                <td colSpan="3">80.00</td>
                                                <td colSpan="4">250.00</td>
                                           </tr>
                                        </tbody>

                                    </table>
                                </Scrollbars>

                                {/* </div> */}


                            </div>

                        </div>
                                         
                    <div id="demo-lg-modal" className="modal fade" tabindex="-1">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="panel-title">Upload</h4>
                                    <button type="button" className="close pull-right" data-dismiss="modal"><i class="fas fa-lg fa-window-close text-success"></i></button>
                                </div>
                                <div className="modal-body">
                               
									
										<div className="panel-heading">
											<h3 className="panel-title">Please Select File</h3>
										</div>
										<div className="panel-body">
											<label for="file">Upload SSS Contibution Summary Excel File</label>
											<input type="file" className="form-control" name="dupload" id="dupload"  onChange={this.handleChange}  />
										</div>
											<button className="btn btn-xs btn-success pull-right" onClick={()=>this.onFormSubmit()}>UPLOAD</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* end of table */}
             </div>
         );
    }
}
 
export default Contribution;