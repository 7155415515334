import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import moment from 'moment';
import $ from 'jquery';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

class UploadDoubleBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: [],
            companies: [],
            company_selected: '',
            branches: [],
            branch_selected: 'select branch',
            loading: false,
            file_details: []
        };
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this)


    }
    onChange(e) {
        // console.log( e.target.files[0])
        this.setState({ file: e.target.files });
    }

    onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.setState({ loading: true })
        const formData = new FormData();
        for (let i = 0; i < this.state.file.length; i++) {
            formData.append('file' + i, this.state.file[i])
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        console.log(formData)
        axios.post("http://api.pacificweb.com.ph/humanResource/uploadTimesheet/" + this.state.branch_selected + "/" + this.state.company_selected + "/" + localStorage.getItem("u") + "/" + "?key=PocketHR@20190208&type=web", formData)
            .then((response) => {
                console.log(response)
                this.setState({ loading: false })
                if (response.data != true) {
                    alert('Success.')
                    $('#close_modal').click()
                } else {
                    alert('Wrong File!')

                }
                console.log(response);
                // if(response == true){
                // }
                // $('#AddCamera_dismiss').click()
                //         swal({
                //             title: "Success!",
                //             text: "Image successfully uploaded",
                //             icon: "success",
                //             button: "Ok",
                //         });


                //         this.setState({ image_name: response.data })
            }).catch((error) => {
            });

    }
    componentDidMount() {
        this.fetchFileUpload()
        var data = localStorage.getItem("")
        // this.checkPagePrivilege();
        var date = new Date();

        // this.getEmp();

        this.getCompanies();


    }
    checkPagePrivilege() {
        var route = "#/uploadTimesheet";
        getData("users/checkPrivilegePage", { user_id: localStorage.getItem("u"), route: route })

            .then(response => {
                //
                if (response == "show_404") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({ redirect: false });
                }
            })
    }
    getCompanies() {

        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })

            .then(response => {
                console.log(response.data);
                this.setState({ companies: response.data })


            })
    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value })
        setTimeout(() => { this.getBranch() }, 200)

    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {
                this.setState({ branches: response.data, branch_selected: "select branch" })
            })
    }
    showBYbranch = e => {
        e.preventDefault();
        this.setState({ branch_selected: e.target.value })
    }
    fetchFileUpload = () => {

        getData("humanResource/fetchTimesheetFile", { user_id: localStorage.getItem("u"),type:"Doublebook" })
            .then(response => {
                this.setState({ file_details: response })
            })
    }
    render() {

        return (

            <div >
                {this.state.loading == true &&
                    <Loader fullPage loading />
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button data-target='#demo-lg-modal' data-toggle='modal' className="btttn bg-green pull-right"><p>Upload Double Book</p></button>
                </div>
                <br />
                <div className="panel">
               < div className='panel-heading'> 
                       <h3>
                       Double Booking
                        </h3> 
                    </div>
                    <div className='panel-body'>
                        <table className="table table-td-valign-middle table-bordered">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>File Name</th>
                                    <th>Date Added</th>
                                    <th>Branch</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.file_details.map((val, index) => {
                                    return <tr key={index}>
                                <td>{val.upload_timesheet_id}</td>
                                <td>{val.upload_timesheet_file_name}</td>
                                        <td>{val.upload_timesheet_date_added}</td>
                                <td>{val.branch_name}</td>
                                  
                                    </tr>


                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="demo-lg-modal" className="modal w3-animate-zoom" tabindex="-1" data-backdrop="static">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Upload Double Book</h4>
                                <button id='close_modal' data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle" />
                            </div>
                            <div className="modal-body">
                                <form className="form-group m-r-10" onSubmit={this.onFormSubmit}>
                                    <input type="file" name="timeSheet" className="form-control" onChange={this.onChange} multiple />
                                    <hr />
                                    <label>Select Company</label>
                                    <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                        <select className="form-control" onChange={this.showBYcompanies} required>
                                            <option ></option>
                                            {this.state.companies.map((val, index) =>
                                                <option value={val.company_id}>{val.company_name}</option>
                                            )}
                                        </select>
                                    </div><br />
                                    <label>Select Branch</label>;
                                            <div className="row" style={{ marginLeft: "11%", marginRight: "11%", width: "81%" }}>
                                        <select className="form-control" value={this.state.branch_selected} onChange={this.showBYbranch} required>
                                            <option>--select branch--</option>
                                            <option></option>
                                            {this.state.branches.map((branch, index) =>
                                                <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                            )}
                                        </select>
                                    </div><br />

                                    <div className="pull-right">
                                        <button type='submit' className="btttn bg-green-transparent-9 fas fa-save m-15">
                                            &nbsp;Upload
                                            </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        );
    }
}

export default UploadDoubleBook;