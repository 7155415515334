import React, { Component } from "react";
// import { getData } from '../../api/api';
// import { getData } from '../api/api'
import TextareaAutosize from "react-autosize-textarea";
import { getData,apiurl } from "../api/api";
import DatePicker from "react-datepicker";
import { Scrollbars } from "react-custom-scrollbars";
import axios, { post } from "axios";
import introJs from "intro.js";
import "intro.js/introjs.css";
import moment from "moment";
import $ from "jquery";
import swal from "sweetalert/dist/sweetalert.min.js";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide1: 0,
      reply: 0,
      categories: [],
      h_subject: "",
      h_type: "",
      h_description: "",
      h_upload: [],
      helpDeskData: [],
      helpDetails: [],
      userData: [],
      display: [],
      replybtn: 1,
      replies: [],
      getUserDetails: [],
      replyDescription: "",
      h_single: [],
    };
    this.textchange = this.textchange.bind(this);
    this.submitHelp = this.submitHelp.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.submitReply = this.submitReply.bind(this);
    this.openCompose = this.openCompose.bind(this);
    this.uploadSingle = this.uploadSingle.bind(this);
    this.submitSingle = this.submitSingle.bind(this);
  }
  categories() {
    getData("helpdesk/getHelpCategories").then((res) => {
      this.setState({ categories: res });
    });
  }

  helpDeskData() {
    getData("helpdesk/getHelpDeskUser", localStorage.getItem("u")).then(
      (res) => {
        this.setState({ helpDeskData: res });
      }
    );
  }
  getSingleHelp(help) {
    this.setState({ replyDescription: "" });
    let information = [help];
    var data = { user_id: information[0].help_id };
    getData("helpdesk/getReplies", data).then((res) => {
      this.setState({ replies: res.response });
    });
    this.setState({
      hide1: 1,
      replybtn: 1,
      display: information,
    });
  }
  textchange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitSingle(e) {
    // getData("helpdesk/uploadSingleFile", this.state.h_single).then(result => {
    //     console.log(result);
    // })
    const data = new FormData();
    for (var x = 0; x < this.state.h_single.length; x++) {
      data.append("file[]", this.state.h_single[x]);
    }
    axios({
      method: "post",
      // url: 'http://beta.api.pacificweb.com.ph/helpdesk/submitHelp/',
      url: apiurl+ "helpdesk/uploadSingleFile/",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    }).then((result) => {
      console.log(result);
    });
  }

  submitHelp(e) {
    if (
      this.state.h_subject === "" ||
      this.state.h_type === "" ||
      this.state.h_description === ""
    ) {
    } else {
      const data = new FormData();
      for (var x = 0; x < this.state.h_upload.length; x++) {
        data.append("file[]", this.state.h_upload[x]);
      }
      data.set("subject", this.state.h_subject);
      data.set("type", this.state.h_type);
      data.set("description", this.state.h_description);
      data.set("user", localStorage.getItem("u"));

      axios({
        method: "post",
        // url: 'http://beta.api.pacificweb.com.ph/helpdesk/submitHelp/',
        url: apiurl+ "helpdesk/submitHelp/",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then((response) => {
        console.log(response);
        // if(response.data.res === "Success"){
        swal("Successfully reply", "", "success");
        this.setState({
          h_subject: "",
          h_type: "",
          h_description: "",
          h_upload: [],
        });
        let value = document.getElementsByName("h_upload");
        value[0].value = "";
        this.helpDeskData();
        $("#closeModal").click();
        // }else{
        //     swal("Failed to connect to server!", "", "error");
        // }
      });
      this.helpDeskData();
    }
  }

  uploadImage(e) {
    this.setState({ h_upload: e.target.files });
  }
  uploadSingle(e) {
    this.setState({ h_single: e.target.files });
  }

  componentDidMount() {
    this.categories();
    this.helpDeskData();
    this.getUserDetails();
  }

  getUserDetails() {
    getData("helpdesk/getUserDetails", localStorage.getItem("u")).then(
      (res) => {
        this.setState({ getUserDetails: res });
      }
    );
  }
  viewHelpDetails(e) {
    let data = [];
    data.push(e);
    this.setState({ viewHelpDetails: data });
  }

  submitReply() {
    if (this.state.replyDescription !== "") {
      let details = this.state.display;
      let user = this.state.getUserDetails;

      var data = {
        id: details[0].help_id,
        user: user[0].user_id,
        property: "user",
        description: this.state.replyDescription,
      };
      let replyDes = document.getElementsByName("replyDescription");

      getData("HelpDesk/submitReply", data).then((res) => {
        swal("Successfully reply", "", "success");
        var data = { user_id: details[0].help_id };
        getData("helpdesk/getReplies", data).then((res) => {
          this.setState({ replies: res.response });
        });
        replyDes[0].value = "";
        this.setState({ replyDescription: "" });
      });
    }
  }

  openCompose() {
    this.setState({
      h_subject: "",
      h_type: "",
      h_description: "",
      h_upload: [],
    });

    let value = document.getElementsByName("h_upload");
    value[0].value = "";
  }

  render() {
    return (
      <div id="content" className="content">
        {this.state.hide1 == 0 && (
          <div className="panel">
            <div
              className="panel-heading"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h4 className="panel-title">Help Desk Ticketing System</h4>
              <button
                className="btttn fas fa-plus-circle bg-green-transparent-9 text-white"
                onClick={this.openCompose}
                data-target="#demo-modal-compose"
                data-toggle="modal"
              >
                &nbsp;Compose
              </button>
            </div>
            <div className="panel-body">
              <div className="table-responsive">
                <table className="table table-invoice table-bordered table-td-valign-middle">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Date&nbsp;Issued</th>
                      <th className="text-center">Assigned&nbsp;To</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.helpDeskData.map((help) => {
                      var handler = "";
                      if (help.handler_fname != 0) {
                        handler = help.handler_fname;
                      }
                      return (
                        <tr className="text-center">
                          <td>{help.help_id}</td>
                          <td>{help.help_subject}</td>
                          <td>{moment(help.help_date_added).format("LL")}</td>
                          <td>
                            {help.handler_fname}&nbsp;{help.handler_lname}
                          </td>
                          <td>{help.help_status}</td>
                          <td>
                            {" "}
                            <button
                              className=" btttn fas fa-info-circle bg-blue-transparent-9 text-white"
                              onClick={() => {
                                this.getSingleHelp(help);
                              }}
                            >
                              &nbsp;Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {this.state.hide1 == 1 && (
          <div>
            <div className="panel">
              {this.state.display.map((help) => {
                return (
                  <div className="panel-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <p>Subject: {help.help_subject}</p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div>
                            <p className="far fa-user-circle">
                              &nbsp;{help.person_fname}&nbsp;{help.person_lname}
                            </p>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <p>Status : {help.help_status}</p>
                          </div>
                          <div>
                            <p className="far fa-calendar-alt">
                              &nbsp;{moment(help.help_date_added).format("ll")}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="far fa-user-circle">
                            &nbsp;Assigned&nbsp;to :&nbsp;
                            {help.handler_fname}&nbsp;{help.handler_lname}
                          </p>
                        </div>
                      </div>
                    </div>
                    <TextareaAutosize
                      className="border-0"
                      rows={4}
                      style={{
                        width: "100%",
                        textIndent: "10px",
                        textDecoration: "none",
                        outline: 0,
                      }}
                      required
                      placeholder={help.help_description}
                      readOnly
                    />
                    <div className="btn-group">
                      <button
                        className="btttn bg-green-transparent-9 text-white fas fa-reply m-2"
                        onClick={() => {
                          this.setState({ reply: 1 });
                        }}
                      >
                        &nbsp;Reply
                      </button>
                      <button
                        className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-2"
                        onClick={() => {
                          this.setState({ hide1: 0, reply: 0 });
                        }}
                      >
                        &nbsp;Back
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            {this.state.replies.map((val, index) => {
              return (
                <div className="panel">
                  <div className="panel-body">
                    <form>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <p className="far fa-user-circle">
                              &nbsp;{val.user_fname}&nbsp;{val.user_lname}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <p className="far fa-calendar-alt">
                              {moment(val.reply_added).format("lll")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <TextareaAutosize
                        value={val.reply_description}
                        className="border-0"
                        rows={4}
                        style={{
                          width: "100%",
                          textIndent: "10px",
                          textDecoration: "none",
                          outline: 0,
                        }}
                        readOnly
                      />
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {this.state.reply == 1 && (
          <div className="panel">
            <div className="panel-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  {this.state.display.map((val) => (
                    <div>
                      <p className="fas fa-atom">
                        &nbsp;Replying to the {val.help_subject} subject
                      </p>
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  {this.state.display.map((val) => (
                    <div>
                      <p className="far fa-user-circle">
                        &nbsp;Assigned&nbsp;to :&nbsp;{val.handler_fname}&nbsp;
                        {val.handler_lname}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <hr />
              <TextareaAutosize
                className="border-0"
                rows={4}
                style={{
                  width: "100%",
                  textIndent: "10px",
                  textDecoration: "none",
                  outline: 0,
                }}
                required
                placeholder="reply"
                name="replyDescription"
                onChange={this.textchange}
              />
              <div className="btn-group">
                {this.state.display.map((val) => (
                  <button
                    className="btttn bg-green-transparent-9 text-white fas fa-check-circle m-2"
                    onClick={this.submitReply}
                  >
                    &nbsp;Send
                  </button>
                ))}
                <button
                  className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-2"
                  onClick={() => {
                    this.setState({ reply: 0 });
                  }}
                >
                  &nbsp;Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div
          id="demo-modal-compose"
          className="modal fade"
          tabindex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4>Ticket Form</h4>
                <button
                  id="closeModal"
                  data-dismiss="modal"
                  className="pull-right btttn bg-red-transparent-9 fa fa-times-circle"
                ></button>
              </div>
              <div className="modal-body">
                <div className="panel">
                  <div className="panel-body">
                    <form>
                      <div class="form-group">
                        <label>Subject:</label>
                        <input
                          className="form-control m-b-5"
                          value={this.state.h_subject}
                          style={{ width: "100%" }}
                          placeholder="Brief Summary of the question or issue"
                          name="h_subject"
                          onChange={this.textchange}
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label>Type:</label>
                        <select
                          className="default-select2 form-control"
                          style={{ width: "100%" }}
                          value={this.state.h_type}
                          required
                          name="h_type"
                          onChange={this.textchange}
                        >
                          <option disabled value="" selected>
                            -Select Category-
                          </option>
                          {this.state.categories.map((cat) => (
                            <option value={cat.category_details}>
                              {cat.category_details}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="form-group">
                        <label>Upload:</label>
                        <input
                          className="form-control"
                          type="file"
                          multiple
                          id="fileToUpload"
                          name="h_upload"
                          onChange={this.uploadImage}
                        ></input>
                      </div>
                      <div class="form-group">
                        <label>Description:</label>
                        <TextareaAutosize
                          value={this.state.h_description}
                          rows={4}
                          style={{
                            border: "solid",
                            borderWidth: 1,
                            borderColor: "#bec6ce",
                            width: "100%",
                            textIndent: "10px",
                            textDecoration: "none",
                            outline: 0,
                            borderRadius: 5,
                          }}
                          required
                          placeholder="Detailed account of the question or issue"
                          name="h_description"
                          onChange={this.textchange}
                        />
                      </div>
                    </form>
                    <button
                      type="submit"
                      className="btttn bg-green-transparent-9 fas fa-check-circle"
                      onClick={this.submitHelp}
                    >
                      &nbsp;Submit
                    </button>
                    {/* <form>
                                        <div class="form-group">
                                                <label>Upload Single:</label>
                                                <input className="form-control" type="file" id="fileSingle" name="h_single" onChange={this.uploadSingle} ></input>
                                            </div>

                                        </form>
                                        
                                            <button type="submit" className="btttn bg-green-transparent-9 fas fa-check-circle" onClick={this.submitSingle}>&nbsp;Submit</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="demo-modal-reply" className="modal fade" tabindex="-1" data-backdrop="static">
                        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4>Reply Form</h4>
                                    <button data-dismiss="modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="panel">
                                        <div className="panel-body">
                                            {this.state.display.map((val) =>
                                                <form onSubmit={this.submitReply}>
                                                    <div class="form-group">
                                                        <label>Subject:</label>
                                                        <input className="form-control m-b-5" style={{ width: "100%" }} value={val.help_subject} readOnly />
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Type:</label>
                                                        <select className="default-select2 form-control" style={{ width: "100%" }} disabled>
                                                            <option disabled selected>{val.help_type}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Description:</label>
                                                        <TextareaAutosize rows={4} style={{ border: "solid", borderWidth: 1, borderColor: "#bec6ce", width: '100%', textIndent: "10px", textDecoration: 'none', outline: 0, borderRadius: 5 }} required
                                                            placeholder='Detailed account of the question or issue' name="reply" onChange={this.replyChange} />
                                                    </div>
                                                    <button type="submit" className="btttn bg-green-transparent-9 fas fa-check-circle">&nbsp;Submit</button>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
      </div>
    );
  }
}
export default Customer;
