const titles = [
    {   ba:'cas',
        title:'02000770'
    },
    {   ba:'cas',
        title:'02000771'
    },
    {   ba:'cas',
        title:'02000772'
    },
    {   ba:'cas',
        title:'02000773'
    },
    {   ba:'cas',
        title:'02000774'
    },
    {   ba:'cas',
        title:'02000775'
    },
    {   ba:'cas',
        title:'02000776'
    },
    {   ba:'cas',
        title:'02000777'
    },
    {   ba:'cas',
        title:'02000778'
    },
    

  ]
  
  export default titles.map((item, index) => ({
    year: index + 1,
    title: item.title,
    brancharea: item.ba,
  }))