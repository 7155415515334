import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const format = 'h:mm a';
// import PAGE from'./employeeDetails'

// import console = require('console');
class General_Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

    }

    render() {
        return (
            <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10, minHeight: 400 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h5 style={{ fontWeight: 'bold' }}>General Settings</h5>
                    <div>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} data-target="#demo-lg-modal-All" onClick={() => { this.setState({ on_type: 0 }) }} data-toggle="modal" className="fas fa-check">&nbsp;&nbsp;All On</button>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} data-target="#demo-lg-modal-All" onClick={() => { this.setState({ on_type: 1 }) }} data-toggle="modal" className="fas fa-times">&nbsp;&nbsp;All Off</button>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} data-target="#filter_employee_late" data-toggle="modal" className="fas fa-sliders-h">&nbsp;&nbsp;Filter</button>
                        <button style={{ height: 23, backgroundColor: '#34495e', padding: 5, cursor: 'pointer', marginRight: 5 }} onClick={() => { this.setState({ employee_filter: this.state.employee }) }} className="fas fa-sync-alt">&nbsp;&nbsp;Refresh</button>
                    </div>

                </div>
                <div className='row, m-t-5' style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <div className='col-md-4' style={{borderWidth: 1, borderColor: '#bdc3c7', borderStyle: 'solid', justifyContent: 'center', paddingTop: 10,height:150,paddingBottom:10,paddingRight:10,paddingLeft:10}}>
                        <h5><b>Select Company</b></h5>
                        <div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-4' style={{ borderWidth: 1, borderColor: '#bdc3c7', borderStyle: 'solid', justifyContent: 'center', paddingTop: 10,height:150,paddingBottom:10,paddingRight:10,paddingLeft:10}}>
                        <h5><b>Select Branch</b></h5>
                        <div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-4' style={{ borderWidth: 1, borderColor: '#bdc3c7', borderStyle: 'solid', justifyContent: 'center', paddingTop: 10,height:150,paddingBottom:10,paddingRight:10,paddingLeft:10}}>
                        <h5><b>Select Employee</b></h5>
                        <div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                            <div >
                                <input type='checkbox' style={{ marginRight: 10 }} />
                                <label>Green Zone</label>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


        );
    }
}


export default General_Settings;