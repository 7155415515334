import React, { Component } from 'react';
import {    
    HashRouter as Router,
    Route,
    Redirect
  } from "react-router-dom";
import Driver from './driver';
import User from './user';
import Incident from './incident';
import Vehicles from './vehicles';

class Routes extends Component {
    state = {  }
    render() { 
        return ( 
          <Router>
              <div>
                <Route path="/fleet/driver" component={Driver}/>
                <Route path="/fleet/user" component={User}/>
                <Route path="/fleet/incident" component={Incident}/>
                <Route path="/fleet/vehicles" component={Vehicles}/>
              </div>
          </Router>
         );
    }
}
 
export default Routes;