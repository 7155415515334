import React, { Component } from 'react';
// import Geocode from "react-geocode";
import readXlsxFile from 'read-excel-file'

// Geocode.setApiKey("AIzaSyAz0n7-lqY4We_24Yk8QIjVNbnPX1fG8pU");
// Geocode.setLanguage("en");
// Geocode.setRegion("ph");
// Geocode.enableDebug();

class Validation extends Component {
    constructor(props){
        super(props);
        this.state = {
            latlngFile: [],
        }
        this.latlngFile = this.latlngFile.bind(this);
        this.submit = this.submit.bind(this);
    }

    latlngFile(e){
        let data = [];
          readXlsxFile(e.target.files[0]).then((row) =>{
           for(let i=1; i<row.length; i++){
               data.push({'ACCOUNT NAME': row[i][0], 'ADDRESS': row[i][1], 'METER': row[i][2], 'SERIAL': row[i][3], 'Status': row[i][4],
               'Coordinates': row[i][5],'Corrected Street': row[i][6],'CORRECTED BRGY.': row[i][7],'Corrected DISTRICT': row[i][8]});
           }
           this.setState({latlngFile: data});
        })
    }

    submit(e){
        console.log(this.state.latlngFile.length)

        let data = this.state.latlngFile;
        let value = [];
        for(let i=0; i<data.length; i++){
            value.push(data[i].Coordinates);
        }


        //  Geocode.fromLatLng(10.7102225,122.5671491).then(
        //         response => {
        //             console.log(response);
        //           const address = response.results[0].address_components;
        //         //   console.log(address);
        //         },
        //         error => {
        //           console.error(error);
        //         }
        //       );


        for(let i=0; i<value.length; i++){
            console.log()
            // Geocode.fromLatLng(10.7102225,122.5671491).then(
            //     response => {
            //         console.log(response);
            //       const address = response.results[0].address_components;
            //       console.log(address);
            //     },
            //     error => {
            //       console.error(error);
            //     }
            //   );
        }
    }

    addressFile(){
        // Geocode.fromAddress("DON FRANCISCO VIL, BRGY. MARCELO H. DEL PILAR, JARO").then(
        //     response => {
        //     const { lat, lng } = response.results[0].geometry.location;
        //     console.log(lat, lng);
        //     },
        //     error => {
        //     console.error(error);
        //     }
        // );
    }

    render() { 
        return ( 
            <div id="content" className="content">
              <input type="file" id="input" onChange={this.latlngFile}/>
                <button onClick={this.submit}>Submit</button>
            </div>
         );
    }
}
 
export default Validation;