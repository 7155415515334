import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div className="pointer">
   {text}
</div>;



class Deployment extends Component {
    constructor(props){
        super(props);
        this.state ={
        }
    
    }

    static defaultProps = {
        center: {
          lat: 14.5995,
          lng: 120.9842
          
        },
        zoom: 10,
      };

    getMapOptions = (maps: Maps) => {

        return {
            streetViewControl: true,
            scaleControl: true,
            fullscreenControl: true,
            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "on"
                }]
            }],
            gestureHandling: "greedy",
            disableDoubleClickZoom: false,
    
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            },
    
            zoomControl: true,
            clickableIcons: false
        };
    }

    renderMarkers(map, maps) {
        let marker = new maps.Marker({
          position: {lat: 14.5995, lng: 120.9842},
          map,
          title: 'Hello World!',
          draggable: true
        });

        console.log(marker)
      }


    render() { 
        return ( 
            <div className="content" id="content">
                <div style={{ height: '90vh', width: '100%', marginTop: 55 }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAz0n7-lqY4We_24Yk8QIjVNbnPX1fG8pU" }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        options={this.getMapOptions}
                        onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
                        >

                        {/* <AnyReactComponent
                            lat={14.599}
                            lng={120.9842}
                            text={"Hello"}

                            /> */}

                    </GoogleMapReact>
                </div>
            </div>
         );
    }
}
 
export default Deployment;