import React, { Component } from 'react';
import { getData } from '../../../api/api';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import { Redirect, Link } from 'react-router-dom'
import ReactExport from "react-data-export";
import $ from 'jquery';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import TextareaAutosize from 'react-autosize-textarea';
// import PAGE from'./employeeDetails'

// import console = require('console');
class LateSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date_from: new Date,
            date_to: new Date,
            companies: [],
            branches: [],
            employee: [],
            employee_selected: [],
            adjustments: [],
            buttonType: '',
            amount: '',
            hours: '',
            company_selected: '',
            branch_selected: '',
            justification: ''
        }
        this._onsubmit = this._onsubmit.bind(this)
        this.textChange = this.textChange.bind(this)
        this.showEmployee = this.showEmployee.bind(this)
    }
    _onsubmit = (e) => {
        e.preventDefault()
       
        let user = this.state.employee.filter((val) => (val.user_id == this.state.employee_selected[0]));
        let level_hierarchy = [];
        let hierarchy_id 
        if (user.length != 0) {
            let hierarchy = JSON.parse(user[0]['user_request_hierarchy'])
            hierarchy_id = user[0]['hierarchy_id']

            for (let index = 0; index < hierarchy.length; index++) {
                if( (hierarchy[index].types).includes('Additonal Basic Adjustment')){
                    level_hierarchy.push(hierarchy[index].approver)
                }
            }
        }
        let details = {
            branch: this.state.branch_selected,
            company: this.state.company_selected,
            amount: this.state.amount,
            hours: this.state.hours,
            date_from: moment(this.state.date_from).format('Y-MM-DD'),
            date_to: moment(this.state.date_to).format('Y-MM-DD'),
            employee_selected: this.state.employee_selected,
            justification: this.state.justification,
            level_hierarchy:level_hierarchy,
            hierarchy: JSON.parse(user[0]['user_request_hierarchy']),
            hierarchy_id:hierarchy_id
        }

        getData("humanResource/insertAdditionalAdjustment", { u_id: localStorage.getItem("u"), details: details, type: "Additonal Basic Adjustment" })
            .then(response => {

                if (response.res == true) {
                    $('#close_modal').click();

                    alert('Success!')
                    // document.getElementById('justification').value = '';
                    // document.getElementById('amount').value = '';
                    // document.getElementById('hours').value = '';

                    this.setState({ adjustments: response.data, justification: '', amount: '', hours: '' })

                }
            })

        console.log(details);
    }
    _edit = (e) => {
        e.preventDefault()
        alert('hehehe')

    }
    showBYcompanies = e => {
        e.preventDefault();
        this.setState({ company_selected: e.target.value, employee: [] })
        setTimeout(() => { this.getBranch() }, 200)
    }
    getCompanies() {
        getData("users/getCompany_by_priv", { user_id: localStorage.getItem("u") })
            .then(response => {
                this.setState({ companies: response.data })
            })
    }
    componentDidMount = () => {
        this.getCompanies();
        this.fetchAdjustment();
    }
    showBYbranch = e => {
        e.preventDefault();
        this.setState({ branch_selected: e.target.value })
        this.fetchEmployee(e.target.value)
    }
    getBranch() {
        getData("users/getBrachData_by_priv", { user_id: localStorage.getItem("u"), company_id: this.state.company_selected })
            .then(response => {
                this.setState({ branches: response.data, branch_selected: "select branch" })
            })
    }
    fetchEmployee(branch) {
        getData("humanResource/getUsers_branch", { branch: branch })
            .then(response => {
                console.log(response);
                this.setState({ employee: response.response })
            })
    }
    showEmployee = (e) => {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                if (options[i].value == 'ALL') {
                    // for(let i=0;i< this.state.employee.length;i++){
                    //     value.push(this.state.employee[i].user_id);
                    // }
                    value.push("ALL")
                } else {
                    value.push(options[i].value);
                }
            }
        }
        // console.log(value)
        this.setState({ employee_selected: value })
    }
    fetchAdjustment = () => {

        getData("humanResource/fetch_adjustment", { user_id: localStorage.getItem("u"), type: "Additonal Basic Adjustment", })
            .then(response => {
                this.setState({ adjustments: response })
                // console.log(response);
                // this.setState({ employee: response.response })
            })
    }
    textChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    render() {
        return (
            <div >
                {this.state.loading == true &&
                    <Loader loading />
                }
                <div className='row m-b-15'>
                    {/* <div className="col-md-8">
                        <h4 className="panel-title col-md-6">Payroll | Additonal Basic Adjustment Summary</h4>
                    </div> */}
                    <div className="col-md-12">
                        <button className="btttn bg-green-transparent-9 fas fa-plus-circle pull-left" data-target="#demo-lg-modal-additional" data-toggle="modal"
                            onClick={() => {
                                this.setState({
                                    transaction_type: "Deduction", Transact_name:
                                        this.state.Deduct_name, deductionSelect: false, createDeduct: true, buttonType: 'Add'
                                })
                            }}>
                            &nbsp;Additional&nbsp;Adjustment
                            </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Scrollbars style={{ height: 250 }}>
                            <table className="table table-bordered" style={{ fontSize: 12 }}  >
                                <thead className="tableFixHead ">
                                    <th>No</th>
                                    <th>Adjustment ID</th>
                                    <th>Employee</th>
                                    <th>Hours</th>
                                    <th>Amount</th>
                                    <th>Date From</th>
                                    <th>Date To</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {this.state.adjustments.map((val, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{val.req_id}</td>
                                            <td>{`${val.user_lname.toUpperCase()} ${val.user_fname.toUpperCase()}`}</td>
                                            <td>{val.req_hours_approved}</td>
                                            <td>{val.req_quantity}</td>
                                            <td>{moment(val.req_date_start).format('Y-MM-DD')}</td>
                                            <td>{moment(val.req_date_end).format('Y-MM-DD')}</td>
                                            <td>{val.status}</td>
                                            <td>
                                                {/* <a  onClick={() => {this.selectAdjustment(val.transaction_id) }} href='#/DetailsAdjustment'> */}
                                                <Link to={'/DetailsAdjustment/' + val.req_id + '/' + 'Additonal Basic Adjustment'}><button style={{ height: 23, backgroundColor: '#3498db', padding: 5, cursor: 'pointer', zIndex: '1', marginRight: 5 }} className="fas fa-eye">&nbsp;&nbsp;View</button> </Link>
                                                {/* </a> */}
                                                {/* <button className="btttn bg-green-transparent-9 fa fa-edit m-r-5" onClick={() => {
                                                this._edit(val)
                                            }} data-target="#demo-lg-modal-additional" data-toggle="modal">&nbsp;Edit</button>
                                            <button className="btttn bg-red-transparent-9 fa fa-trash-alt m-r-5" data-target="#demo-lg-modal-DELETE" data-toggle="modal">&nbsp;Delete</button> */}
                                            </td>
                                        </tr>
                                    })

                                    }


                                </tbody>
                            </table>
                        </Scrollbars>
                    </div>

                </div>


                {/* </div>
                    </div>
                </div> */}
                <div id="demo-lg-modal-additional" className="modal  w3-animate-zoom" tabindex="-1" data-backdrop="static" data-keyboard="false">
                    <form onSubmit={this.state.buttonType == 'Add' ? this._onsubmit : this._edit}>
                        <div className="modal-dialog modal-md" >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Additional Basic Adjustment</h4>
                                    <button data-dismiss="modal" id="close_modal" className="pull-right btttn bg-red-transparent-9 fa fa-times-circle">
                                    </button>
                                </div>
                                <div className="modal-body" >
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <div className="row">
                                            <div class="form-group row m-b-15">
                                                <div class="col-md-6">
                                                    <label class="col-form-label col-md-3">Amout:</label>
                                                    <input style={{ width: 170 }} name="amount" id="amount" value={this.state.amount} onChange={this.textChange} type='number' className='form-control' required></input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="col-form-label col-md-3">Hours:</label>
                                                    <input style={{ width: 170 }} name="hours" id="hours" value={this.state.hours} onChange={this.textChange} type='number' className='form-control' required></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="form-group row m-b-15">

                                                <div class="col-md-6">
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <label>Date&nbsp;Start:</label>
                                                        <DatePicker
                                                            selected={this.state.date_from}
                                                            startDate={this.state.date_to}
                                                            className="form-control"
                                                            onChange={(event) => this.setState({ date_from: event })}
                                                        />
                                                    </div>

                                                </div>
                                                <div class="col-md-6">
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <label>Date&nbsp;End:</label>
                                                        <DatePicker
                                                            selected={this.state.date_to}
                                                            startDate={this.state.date_to}
                                                            className="form-control"
                                                            onChange={(event) => this.setState({ date_to: event })}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.buttonType == 'Add' ?
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ width: '80%' }}>

                                                    <label>Select Company</label>
                                                    <select className="form-control" style={{ width: '100%' }} onChange={this.showBYcompanies} required>
                                                        <option ></option>
                                                        {this.state.companies.map((val, index) =>
                                                            <option value={val.company_id}>{val.company_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ width: '80%' }}>
                                                    <label>Select Branch</label>
                                                    <select className="form-control" style={{ width: '100%' }} onChange={this.showBYbranch} required>
                                                        <option ></option>
                                                        {this.state.branches.map((val, index) =>
                                                            <option value={val.branch_id}>{val.branch_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ width: '80%' }}>
                                                    <label>Select Employee</label>
                                                    <select className="form-control" onChange={this.showEmployee} required>
                                                        {/* <option value="ALL">--Select All--</option> */}
                                                        <option></option>
                                                        {this.state.employee.map((val, index) =>
                                                            <option value={val.user_id} key={index}>{`${val.user_lname}, ${val.user_fname} ${val.user_mname}`.toUpperCase()}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ width: '80%' }}>
                                                    <label>Justification</label>
                                                    <textarea className='form-control' placeholder='Type....' onChange={this.textChange} name='justification' id='justification' value={this.state.justification}>

                                                    </textarea>
                                                </div>
                                            </div>
                                        </div> : undefined
                                    }

                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btttn bg-green-transparent-9 fa fa-save m-r-5"  >
                                        &nbsp;Submit
                                </button>
                                    <button className="btttn fas fa-times-circle border border-secondary border-solid text-black-lighter bg-none m-r-5" type="button" data-dismiss="modal" aria-label="Close">
                                        &nbsp;Cancel
                                </button>

                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            </div>



        );
    }
}


export default LateSettings;