import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Scrollbars } from 'react-custom-scrollbars';
import axios, { post } from 'axios';
import 'intro.js/introjs.css';
import moment from 'moment';
import { getData } from '../../api/api';
import $ from "jquery";


class addingFieldman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_fname: "",
            user_mname: "",
            user_lname: "",
            user_suffix: "",
            company_id: 0,
            b_id: 0,
            userjob_position: ''
        };
        this.textchange = this.textchange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);

    }
    textchange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onFormSubmit(e) {
        e.preventDefault();
        var data = {
            dept_id: 2,
            app_id: 7,
            username: this.state.username,
            firstname: this.state.user_fname,
            lastname: this.state.user_lname,
            middlename: this.state.user_mname,
            suffixname: this.state.user_suffix,
            company_id: this.state.company_id,
            b_id: this.state.b_id,
            userjob_position: this.state.userjob_position,
        }
        console.log(data)
        getData("users/addingMeterReader", data)
            .then(result => {
                console.log(result)
                let user = result;
                if (user.res === "wrong") {
                    alert('wrong')
                } if (user.res === "success") {
                    alert('right')

                }
            });
        ;
    }
    render() {

        return (
            <div className="content" id="content">
                <div className="panel">
                    <div className="panel-body">
                        <form onSubmit={this.onFormSubmit}>
                            <fieldset>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">First Name *</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="text" placeholder="First Name" name="user_fname" className="form-control" required />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Middle Name</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="text" placeholder="Middle Name" name="user_mname" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Last Name *</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="text" placeholder="Last Name" name="user_lname" className="form-control" required />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Suffix Name</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="text" placeholder="Suffix Name" name="user_suffix" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Username&nbsp;*</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="text" name="username" placeholder="username" className="form-control" required />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Company ID&nbsp;*</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="number" name="company_id" placeholder="company id" className="form-control" required />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Branch ID&nbsp;*</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="number" name="b_id" placeholder="Branch ID" className="form-control" required />
                                    </div>
                                </div>
                                <div className="form-group row m-b-10">
                                    <label className="col-lg-3 text-lg-right col-form-label">Position&nbsp;*</label>
                                    <div className="col-lg-9">
                                        <input onChange={this.textchange} type="text" name="userjob_position" placeholder="Position" className="form-control" required />
                                    </div>
                                </div>
                                <button className="btn bg-green text-white m-l-5" type="submit" >Create</button>

                            </fieldset>
                        </form>
                    </div>
                </div>

            </div>
        );
    }
}
export default addingFieldman;